import React, { Component } from 'react';
import PropTypes from 'prop-types';

import autosize from 'autosize';

class TextArea extends Component {
  componentDidMount() {
    if (this.props.autoSize) {
      autosize(this.inputRef);
    }
  }

  componentDidUpdate() {
    autosize.update(this.inputRef);
  }

  componentWillUnmount() {
    autosize.destroy(this.inputRef);
  }

  // Kann von Parent aufgerufen werden
  // sodass TextArea sich verhält wie normaler Input
  focus() {
    this.inputRef.focus();
  }

  render() {
    const { autoSize, ...rest } = this.props;

    return (
      <textarea ref={e => (this.inputRef = e)} {...rest} />
    );
  }
}

TextArea.propTypes = {
  autoSize: PropTypes.bool,
};

export default TextArea;

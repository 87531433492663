import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';

import DatePicker from './DatePickerComponent';

class FormDatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: this.props.value,
      min: this.props.min && Date.parse(this.props.min),
      max: this.props.max && Date.parse(this.props.max),
    };
  }

  handleChange = (dateString) => {
    this.setState({
      date: dateString,
    });

    const { eventName } = this.props;
    if (eventName) {
      document.dispatchEvent(new CustomEvent(
        eventName, { detail: { value: dateString } },
      ));
    }
  };

  render() {
    const props = omit(this.props, 'value');
    return (
      <DatePicker
        {...props}
        value={this.state.date}
        onChange={this.handleChange}
        disabledDate={moment => {
          if (this.state.min && moment < this.state.min) return true;
          return this.state.max && moment > this.state.max;
        }}
      />
    );
  }
}

FormDatePicker.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  small: PropTypes.bool,
  min: PropTypes.string,
  max: PropTypes.string,
  eventName: PropTypes.string,
};

export default FormDatePicker;

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import TodoStatusBadge from './TodoStatusBadge';
import TodoCommentsBadge from './TodoCommentsBadge';

import Icon from '../shared/Icon';

class TodoStickyHeader extends Component {
  renderContent() {
    return (
      <div className="container">
        <div className="row align-items-center">
          <div className="col">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 d-flex align-items-center">
                <div>
                  <TodoStatusBadge todo={this.props.todo} />
                </div>
                <div>
                  <div className="todo-sticky-header-text">
                    <a href="#top">
                      {this.props.todo.text}
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <a href="#top" data-turbo="false" className="btn btn-outline-secondary rounded-pill">
                  <Icon name="arrow-up" /> nach oben
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return ReactDOM.createPortal(
      this.renderContent(),
      document.querySelector('.project-sticky-header'),
    );
  }
}

TodoStickyHeader.propTypes = {
  todo: PropTypes.object,
};

export default TodoStickyHeader;

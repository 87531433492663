import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AceEditor from 'react-ace';

import 'brace/mode/json';
import 'brace/theme/github';
import 'brace/ext/language_tools';

class JsonEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      valid: true,
      parsed: this.safelyGetParsed(this.props.value),
      value: this.props.value,
    };

    this.onEditorChange = this.onEditorChange.bind(this);
  }

  onEditorChange(value) {
    this.setState({ value });

    const parsed = this.safelyGetParsed(value);

    if (parsed) {
      this.setState({ valid: true, parsed });
      if (this.props.onChange) {
        this.props.onChange(value, parsed);
      }
    } else {
      this.setState({ valid: false, parsed: null });
    }
  }

  safelyGetParsed(json) {
    try {
      return JSON.parse(json);
    } catch (e) {
      return null;
    }
  }

  hasError() {
    if (!this.state.valid) {
      return false;
    }

    return !this.state.valid;
  }

  renderValid() {
    if (!this.state.valid) {
      return (
        <div style={{ padding: 3 }}>
          <span className="label bg-danger">
            Syntax ungültig
          </span>
        </div>
      );
    }

    return null;
  }

  render() {
    return (
      <div>
        <AceEditor
          mode="json"
          theme="github"
          tabSize={2}
          width="100%"
          ref="editor"
          className="ace-editor-border"
          onChange={this.onEditorChange}
          name="code_editor"
          maxLines={Infinity}
          minLines={this.props.minLines}
          value={this.state.value}
          editorProps={{
            $blockScrolling: Infinity,
          }}
        />
        {this.renderValid()}
      </div>
    );
  }
}

JsonEditor.propTypes = {
  fieldName: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  errors: PropTypes.array,
  minLines: PropTypes.number,
};

JsonEditor.defaultProps = {
  minLines: 5,
};


export default JsonEditor;

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['cancellationListId', 'submit'];

  connect() {
    this.toggleSubmit();
  }

  toggleSubmit() {
    this.submitTarget.disabled = !this.cancellationListIdTargets.find(radio => radio.checked);
  }
}

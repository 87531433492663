import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';

import ConfirmModal from '../shared/ConfirmModal';
import { deleteDataviewSection } from '../../helpers/api';

import DataviewSectionModal from './DataviewSectionModal';
import DragHandle from '../shared/DragHandle';

const DataviewSection = (props) => {
  const classNames = ['section-item'];

  if (props.isSelected) {
    classNames.push('section-item-selected');
  }

  return (
    <a
      className={classNames.join(' ')}
      ref={props.provided.innerRef}
      key={props.section.id}
      href="#"
      onClick={(e) => props.onSectionSelected(e, props.section)}
      {...props.provided.draggableProps}>
      <DragHandle dragHandleProps={props.provided.dragHandleProps} />
      <div className="section-title-container">
        <div className="section-title">
          {props.section.title}
        </div>
      </div>
      <DataviewSectionModal
        visible
        title="Abschnitt bearbeiten"
        dataview_id={props.dataview.id}
        initialSection={props.section}
        onSaved={props.onSectionUpdated}>
        {onClick => (
          <button className="btn btn-link btn-compact" onClick={onClick}>
            <Icon name="pencil" />
          </button>
        )}
      </DataviewSectionModal>
      <ConfirmModal
        visible
        target={onClick => (
          <button
            type="button"
            className="btn btn-compact btn-link"
            onClick={onClick}>
            <Icon name="trash" />
          </button>
        )}
        title="Abschnitt löschen"
        cancelText="abbrechen"
        confirmText="löschen"
        confirmStyle="danger"
        onConfirm={() => deleteDataviewSection(props.section)}
        onConfirmed={() => props.onSectionDeleted(props.section)}>
        <p>
          Bist du sicher, dass du den Abschnitt löschen willst?
        </p>
        <p>
          Alle enthaltenen Elemente werden dabei ebenfalls gelöscht.
        </p>
      </ConfirmModal>
    </a>
  );
};

DataviewSection.propTypes = {
  dataview: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
  onSectionDeleted: PropTypes.func.isRequired,
  onSectionUpdated: PropTypes.func.isRequired,
  onSectionSelected: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  provided: PropTypes.object.isRequired,
};

export default DataviewSection;

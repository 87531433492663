import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../shared/Icon';

const RenameWorkflowEffectForm = ({ effect }) => (
  <div className="fire-effect-item">
    <div className="fire-effect-icon">
      <Icon name="text" />
    </div>
    <div className="fire-effect-content">
      <div className="fire-effect-title">
        Workflow wird umbenannt zu <strong>{effect.title}</strong>
      </div>
    </div>
  </div>
);

RenameWorkflowEffectForm.propTypes = {
  effect: PropTypes.object.isRequired,
};

export default RenameWorkflowEffectForm;

import { Controller } from '@hotwired/stimulus';
import AutoNumeric from 'autonumeric';
import { get } from '@rails/request.js';
import { autoNumericOptionsEuro } from '../config/auto_numeric_options';
import { flashError } from '../helpers/flash_helper';

export default class extends Controller {
  static targets = ['name', 'priceField', 'customPrice', 'priceInfo'];

  static values = { pricingMissing: Boolean };

  connect() {
    this.pricingMissingValue = !this.#customPriceSet() && this.data.get('pricing-missing');
    document.addEventListener('changeAssignmentProduct', this.changeProduct);
    this.#setPriceInfo();
  }

  disconnect() {
    document.removeEventListener('changeAssignmentProduct', this.changeProduct);
  }

  changePrice(event) {
    this.customPriceTarget.value = event.target.value !== '';
    this.#setPriceInfo();
  }

  changeProduct = (event) => {
    this.#setProduct(event.detail.id);
  };

  #setProductPrice(field, newValue) {
    if (this.#customPriceSet()) return;
    AutoNumeric.set(this.priceFieldTarget, newValue, autoNumericOptionsEuro);
  }

  #setProduct(id) {
    if (!id) {
      this.#setProductPrice(this.priceFieldTarget, '');
      this.pricingMissingValue = false;
      this.#setPriceInfo();
      return;
    }

    const company_price_path = this.data.get('company-price-url');
    get(`${company_price_path}?product_id=${id}`)
      .then(response => response.json)
      .then(data => {
        this.#setProductPrice(this.priceFieldTarget, data.price);
        this.pricingMissingValue = data.pricing_missing;
        this.nameTarget.value = data.name;
      })
      .then(() => this.#setPriceInfo())
      .catch(_error => flashError('Preis konnte nicht abgerufen werden.'));
  }

  #setPriceInfo() {
    this.priceInfoTarget.textContent = this.customPriceTarget.value === 'true' ?
      'Preis wurde manuell eingetragen und wird nicht aus Produkt übernommen.' :
      this.pricingMissingValue ? 'Preis für Produkt ist nicht beim Fachpartner hinterlegt' : '';
  }

  #customPriceSet() {
    return this.customPriceTarget.value === 'true';
  }
}

import React from 'react';
import PropTypes from 'prop-types';

const StringChoiceDataPointInput = ({ dataPoint, onChange, value, saving, autoFocus }) => {
  const handleChange = (e) => {
    const newValue = e.target.value ? parseInt(e.target.value) : null
    onChange(newValue);
  };

  const optionTags = dataPoint.data_point_options.map((option, i) => (
    <option key={i} value={option.id}>{option.string_value}</option>
  ));

  return (
    <select
      className="form-control"
      autoFocus={autoFocus}
      disabled={saving}
      value={value || ''}
      onChange={handleChange}>
      <option value="">Bitte auswählen</option>
      {optionTags}
    </select>
  );

};

StringChoiceDataPointInput.propTypes = {
  dataPoint: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  autoFocus: PropTypes.bool,
  value: PropTypes.number,
};

export default StringChoiceDataPointInput;

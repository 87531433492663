/* global document */
import autosize from 'autosize';

global.autosize = autosize;

global.initAutosize = () => {
  autosize(document.querySelectorAll('textarea[data-autosize]'));
};

document.addEventListener('turbo:load', global.initAutosize)
document.addEventListener('turbo:render', global.initAutosize)

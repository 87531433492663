import React from 'react';

import PropTypes from 'prop-types';

import OperatorSelector from '../../OperatorSelector';

import ProductSelector from '../../../Products/ProductSelector';

import BaseCondition from '../BaseCondition';

class RequestedProductCondition extends React.Component {
  handleChangeOperator = ({ value }) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      operator: value,
    }));
  };

  handleProductChange = (product_id) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      integer_operand_1: product_id,
    }));
  };

  renderOperand1() {
    return (
      <div style={{ flexBasis: 200, marginLeft: 8 }}>
        <ProductSelector
          value={this.props.condition.integer_operand_1}
          organizationSlug={this.props.organizationSlug}
          onChange={this.handleProductChange}
          size="small"
        />
      </div>
    );
  }

  renderOperator() {
    if (!this.props.condition.integer_operand_1) return null;

    return (
      <div style={{ flexBasis: 200, marginLeft: 8 }}>
        <OperatorSelector
          operators={this.props.condition.filter_condition_type.operators}
          onChange={this.handleChangeOperator}
          value={this.props.condition.operator}
        />
      </div>
    );
  }

  render() {
    return (
      <BaseCondition {...this.props}>
        {this.renderOperand1()}
        {this.renderOperator()}
      </BaseCondition>
    );
  }
}

RequestedProductCondition.propTypes = {
  condition: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default RequestedProductCondition;

import React, { Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import DataPointOptionSelectorDropdown from './DataPointOptionSelectorDropdown';
import Label from '../shared/Label';

class DataPointOptionMultiSelector extends Component {
  handleAdd = (option) => {
    const newChoice = {
      id: null,
      data_point_option_id: option.id,
      data_point_option: {
        string_value: option.string_value,
      },
    };

    this.props.onAdd(newChoice);
  }

  renderChoices() {
    return this.props.data_point_choices.map(choice => (
      <Label
        key={choice.data_point_option_id}
        title={choice.data_point_option.string_value}
        data={choice}
        removable
        onRemove={this.props.onRemove}
      />
    ));
  }

  render() {
    const excludedOptionIds = this.props.data_point_choices.map(t => t.data_point_option_id);
    const options = this.props.options.filter((o) => !excludedOptionIds.includes(o.id));

    return (
      <Fragment>
        <div className="multi-label-select">
          {this.renderChoices()}
        </div>
        <DataPointOptionSelectorDropdown
          buttonText={this.props.data_point_choices.length ? 'Option' : 'Option hinzufügen'}
          onAdd={this.handleAdd}
          options={options}
        />
      </Fragment>
    );
  }
}

DataPointOptionMultiSelector.propTypes = {
  data_point_choices: PropTypes.array,
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default DataPointOptionMultiSelector;

import React from 'react';
import PropTypes from 'prop-types';

class AddProjectNoteEffectForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleOptionalChange = this.handleOptionalChange.bind(this);
  }

  handleChange(e) {
    this.props.onChange(Object.assign({}, this.props.record, {
      default_name: e.target.value,
    }));
  }

  handleOptionalChange() {
    this.props.onChange(Object.assign({}, this.props.record, {
      optional: !this.props.record.optional,
    }));
  }

  handleKeyDown(e) {
    // cmd + enter
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      this.props.onSave(e);
    }
  }

  render() {
    return (
      <div className="mb-3">
        <label className="form-label">Name der Notiz</label>
        <input
          autoFocus
          type="text"
          className="form-control"
          placeholder="Optional"
          value={this.props.record.default_name || ''}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
        />
        <div className="form-check">
          <input
            type="checkbox"
            checked={this.props.record.optional}
            onChange={this.handleOptionalChange}
            id="optional"
            className="form-check-input"
          />
          <label htmlFor="optional" className="form-check-label">
            Optional
            <br /><small className="text-muted">
              Wähle diese Option, wenn die Eingabe der Notiz optional sein soll
            </small>
          </label>
        </div>
      </div>
    );
  }
}

AddProjectNoteEffectForm.propTypes = {
  record: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func,
};

export default AddProjectNoteEffectForm;

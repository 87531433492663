import moment from 'moment';

const pluralize = (count, singular, plural) => {
  if (count === 0 || count > 1) {
    return plural;
  }

  return singular;
};

const pluralizeWithCount = (count, singular, plural) => (
  `${count} ${pluralize(count, singular, plural)}`
);

// reordering function for beautiful drag and drop
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// Erzeugt aus einer Anzahl an Minuten einen String der Form:
// 5d 3h 20m
const durationString = (totalMinutes = 0) => {
  const duration = moment.duration(totalMinutes, 'minutes');

  const days = Math.floor(duration.asDays());
  const hours = Math.floor(duration.subtract(days, 'days').asHours());
  const minutes = Math.floor(duration.subtract(hours, 'hours').asMinutes());

  const str = [];

  if (days > 0) {
    str.push(`${days}d`);
  }

  if (hours > 0) {
    str.push(`${hours}h`);
  }

  str.push(`${minutes}m`);

  return str.join(' ');
};

const generateId = () => {
  return Math.random().toString(36).substring(2) + Date.now().toString(36);
};

const searchText = (text, term) => {
  if (!term) return true;
  if (!text) return false;

  return term.replace(/ +(?= )/g, '').split(' ').every((part) => {
    return text.toLowerCase().includes(part.toLowerCase());
  });
};

const metaOrCtrlKey = (event) => {
  // userAgentData only works in secure contexts (HTTPS)
  return (navigator.userAgentData.platform?.match(/macos/i) && event.metaKey) || event.ctrlKey;
};

const convertInlineStyleToCssVariables = (styles) => {
  let cssVariables = {};

  for(let key in styles) {
    if (styles[key] === null) {
      continue;
    }

    cssVariables[`--${key}`] = styles[key];
  }

  return cssVariables;
};

export {
  pluralize,
  pluralizeWithCount,
  reorder,
  durationString,
  generateId,
  searchText,
  metaOrCtrlKey,
  convertInlineStyleToCssVariables,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

const LOCALE = 'de';

const getCurrentMonth = () => {
  const currentYear = moment().year();
  const currentMonth = moment().locale(LOCALE).month() + 1;
  return `${currentYear}-${currentMonth}`;
};

class MonthSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.initialValue,
    };
  }

  handleChange = (e) => {
    if (this.props.onChange) {
      this.setState({ value: e.target.value });
      this.props.onChange(e.target.value);
    }
  };

  handleCurrentMonthClick = (e) => {
    e.preventDefault();

    const value = getCurrentMonth();

    this.setState({ value });
    this.props.onChange(value);
  };

  renderEmptyOption() {
    return (
      <option value="">{this.props.placeholder}</option>
    );
  }

  renderOptions() {
    const firstYear = this.props.firstYear || moment().year() - 1;

    const monthNames = [
      'Jan',
      'Feb',
      'Mär',
      'Apr',
      'Mai',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Okt',
      'Nov',
      'Dez',
    ];

    return Array(this.props.years).fill().map((_v, y) => {
      return (
        <optgroup key={`month-group-${y}`} label={firstYear + y}>
          {Array(12).fill().map((_v2, m) => {
            return (
              <option key={`month-${y}-${m}`} value={`${firstYear + y}-${m + 1}`}>
                {monthNames[m]} {firstYear + y}
              </option>
            );
          })}
        </optgroup>
      );
    });
  }

  render() {
    /* eslint-disable no-unused-vars, prefer-const */
    const { initialValue, placeholder, ...rest } = this.props;
    /* eslint-enable */

    return (
      <div>
        <div>
          <select
            {...rest}
            defaultValue={this.state.value}
            onChange={this.handleChange}>
            {this.renderEmptyOption()}
            {this.renderOptions()}
          </select>
        </div>
        <div className="current-month-quick-select">
          <a href="#" onClick={this.handleCurrentMonthClick}>Aktueller Monat</a>
        </div>
      </div>
    );
  }
}

MonthSelector.propTypes = {
  years: PropTypes.number,
  firstYear: PropTypes.number,
  onChange: PropTypes.func,
  initialValue: PropTypes.string,
  placeholder: PropTypes.string,
};

MonthSelector.defaultProps = {
  years: 3,
};

export default MonthSelector;

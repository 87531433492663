import { Controller } from '@hotwired/stimulus';
import * as bootstrap from 'bootstrap';

const ReactRailsUJS = require('react_ujs');

export default class extends Controller {
  static targets = ['modal'];

  connect() {
    const modal = new bootstrap.Modal(this.modalTarget);
    modal.show();
    this.modalTarget.addEventListener('hidden.bs.modal', (_event) => {
      ReactRailsUJS.handleUnmount();
      bootstrap.Modal.getInstance(this.modalTarget).dispose();
      if (this.element.parentElement) this.element.parentElement.removeAttribute('src');
      this.modalTarget.remove();
    });
    ReactRailsUJS.handleMount();
  }

  // eslint-disable-next-line class-methods-use-this
  disconnect() {
    ReactRailsUJS.handleUnmount();
  }

  hideModal() {
    bootstrap.Modal.getInstance(this.modalTarget).hide();
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.hideModal();
    }
  }

  closeWithKeyboard(e) {
    if (e.code === 'Escape') {
      this.hideModal();
    }
  }
}

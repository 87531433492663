import IntegerDataPointDisplay from './DataPointDisplay/IntegerDataPointDisplay';
import BooleanDataPointDisplay from './DataPointDisplay/BooleanDataPointDisplay';
import FloatDataPointDisplay from './DataPointDisplay/FloatDataPointDisplay';
import StringDataPointDisplay from './DataPointDisplay/StringDataPointDisplay';
import StringChoiceDataPointDisplay from './DataPointDisplay/StringChoiceDataPointDisplay';
import MultiStringChoiceDataPointDisplay from './DataPointDisplay/MultiStringChoiceDataPointDisplay';
import TextDataPointDisplay from './DataPointDisplay/TextDataPointDisplay';
import DecimalDataPointDisplay from './DataPointDisplay/DecimalDataPointDisplay';
import MoneyDataPointDisplay from './DataPointDisplay/MoneyDataPointDisplay';
import DateTimeDataPointDisplay from './DataPointDisplay/DateTimeDataPointDisplay';
import DateDataPointDisplay from './DataPointDisplay/DateDataPointDisplay';
import WeekDataPointDisplay from './DataPointDisplay/WeekDataPointDisplay';
import MonthDataPointDisplay from './DataPointDisplay/MonthDataPointDisplay';
import ListDataPointDisplay from './DataPointDisplay/ListDataPointDisplay';
import DocumentDataPointDisplay from './DataPointDisplay/DocumentDataPointDisplay';
import UserDataPointDisplay from './DataPointDisplay/UserDataPointDisplay';

export default {
  IntegerDataPointDisplay,
  BooleanDataPointDisplay,
  FloatDataPointDisplay,
  StringDataPointDisplay,
  StringChoiceDataPointDisplay,
  MultiStringChoiceDataPointDisplay,
  TextDataPointDisplay,
  DecimalDataPointDisplay,
  MoneyDataPointDisplay,
  DateTimeDataPointDisplay,
  DateDataPointDisplay,
  WeekDataPointDisplay,
  MonthDataPointDisplay,
  ListDataPointDisplay,
  DocumentDataPointDisplay,
  UserDataPointDisplay,
};

import React from 'react';
import PropTypes from 'prop-types';

class StartVoiceAgentEffectForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      agentTypes: [],
    };
  }

  componentDidMount() {
    this.loadAgentTypes();
  }

  loadAgentTypes = () => {
    return fetch('/voice_agent_types.json', {
      credentials: 'include',
    }).then((response) => {
      return response.json();
    }).then(({ voice_agent_types }) => {
      this.setState({
        agentTypes: voice_agent_types,
      });
    });
  };

  handleVoiceAgentChange = (e) => {
    const newVoiceAgent = e.target.value;

    this.props.onChange(Object.assign({}, this.props.record, {
      voice_agent_type: newVoiceAgent,
    }));
  };

  render() {
    return (
      <div className="mb-3">
        <label className="form-label">Telefonagent starten</label>
        <div className="col-sm-8 mt-1 mb-1">
          <select value={this.props.record.voice_agent_type} onChange={this.handleVoiceAgentChange} className="form-select">
            <option value="">Bitte auswählen</option>
            {this.state.agentTypes.map((type) => (
              <option key={type.name} value={type.name}>{type.title}</option>
            ))}
          </select>
        </div>
      </div>
    );
  }
}

StartVoiceAgentEffectForm.propTypes = {
  record: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default StartVoiceAgentEffectForm;

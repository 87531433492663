/* global fetch */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { debounce } from 'lodash';

import Select, { components } from 'react-select';
import Icon from '../shared/Icon';

import { defaultStyles, smallStyles } from '../../styles/select_styles';

const getOptions = (organizationSlug, searchTerm, callback) => {
  const url = `/${organizationSlug}/contacts.json?q=${encodeURIComponent(searchTerm)}`;

  return fetch(url, {
    credentials: 'include',
  }).then((response) => {
    return response.json();
  }).then((options) => {
    callback(options.contacts);
  });
};

const getOptionsLazy = debounce(getOptions, 200);

const filterOption = (option, searchTerm) => {
  if (!searchTerm) return true;

  return searchTerm.replace(/ +(?= )/g, '').split(' ').every((term) => {
    return option.data.search_text.toLowerCase().includes(term.toLowerCase());
  });
};

const ReferenceBadge = (props) => {
  if (props.data.is_reference_customer) {
    return (
      <Icon name="badge-check" style={{ marginRight: 6 }} color="#8bc53f"/>
    );
  }

  return null;
};

const Option = props => (
  <components.Option {...props}>
    <ReferenceBadge {...props} />
    {props.data.display_name}
  </components.Option>
);


const SingleValue = props => (
  <components.SingleValue {...props}>
    <ReferenceBadge {...props} />
    {props.data.display_name}
  </components.SingleValue>
);

class ContactSelectorAsync extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      options: [],
      value: null,
    };

    this.loadOptions = this.loadOptions.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.loadInitialOption();
  }

  loadOptions(searchTerm = '') {
    this.setState({ isLoading: true });

    getOptionsLazy(this.props.organizationSlug, searchTerm, (options) => {
      this.setState({
        options, isLoading: false,
      });
    });
  }

  loadInitialOption() {
    if (this.props.value) {
      const url = `/contacts/${this.props.value}.json`;

      fetch(url, {
        credentials: 'include',
      }).then((response) => {
        return response.json();
      }).then((result) => {
        this.setState({ value: result.contact });
      });
    }
  }


  handleFocus() {
    this.loadOptions();
  }

  handleInputChange(searchTerm, { action }) {
    // Löst mehrfach aus (mit verschiedenen actions)
    // Daher optionen nur neuladen, wenn sich 'input-change' gefeuert wurde
    if (action === 'input-change') {
      this.loadOptions(searchTerm);
    }
  }

  handleChange(value) {
    this.setState({ value });

    if (this.props.onChange) {
      if (value) {
        this.props.onChange(value.id);
      } else {
        this.props.onChange(null);
      }
    }
  }

  render() {
    return (
      <Select
        components={{
          Option,
          SingleValue,
        }}
        isLoading={this.state.isLoading}
        onFocus={this.handleFocus}
        isClearable
        onInputChange={this.handleInputChange}
        backspaceRemovesValue={false}
        blurInputOnSelect
        options={this.state.options}
        menuPlacement="auto"
        onChange={this.handleChange}
        filterOption={filterOption}
        styles={this.props.size === 'small' ? smallStyles : defaultStyles}
        value={this.state.value}
        placeholder={this.props.placeholder || 'Kontakt auswählen'}
        loadingPlaceholder="Laden..."
        noOptionsMessage={() => 'Keine Ergebnisse'}
        loadingMessage={() => 'Laden...'}
        DropdownIndicator={() => null}
      />
    );
  }
}

ContactSelectorAsync.propTypes = {
  onChange: PropTypes.func,
  organizationSlug: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.number,
};


export default ContactSelectorAsync;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import ConfirmModal from '../shared/ConfirmModal';

import TimeAgo from '../shared/TimeAgo';

import { deleteAsync } from '../../helpers/rails_helper';

class ContactNote extends Component {
  handleDelete = () => {
    deleteAsync(`/contact_notes/${this.props.contactNote.id}`).then((result) => {
      this.props.onDeleted(this.props.contactNote);
    });
  }

  renderCreator() {
    if (!this.props.contactNote.creator) return null;

    return (
      <span className="fullname_with_avatar">
        <span className="avatar-link-with-fullname avatar-wrapper avatar-wrapper-tiny">
          <img className="avatar avatar-tiny" src={this.props.contactNote.creator.avatar.url} />
        </span>
        {this.props.contactNote.creator.fullname}
      </span>
    );
  }

  renderNoteAt() {
    if (!this.props.contactNote.note_at) return null;

    return (
      <TimeAgo
        datetime={this.props.contactNote.note_at}
        locale="de"
      />
    );
  }

  render() {
    return (
      <div className="row gutter-xs contact-item-row">
        <div className="col-sm-12 contact-note">
          <div className="note-title">
            {this.props.contactNote.note_type_human}
            <div className="contact-note-buttons">
              <ConfirmModal
                target={onClick => (
                  <button className="btn btn-link btn-sm" onClick={onClick}>
                    <Icon name="trash" />
                  </button>
                  )}
                title="Aufgabe löschen"
                cancelText="abbrechen"
                confirmText="löschen"
                confirmStyle="danger"
                visible={this.props.deletable}
                onConfirmed={this.handleDelete}>
                  Bist du sicher, dass du die Notiz löschen willst?
              </ConfirmModal>
            </div>
          </div>
          {this.props.contactNote.body}
          <div className="contact-note-info">
            {this.renderNoteAt()}
            <span className="float-end">
              {this.renderCreator()}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

ContactNote.propTypes = {
  contactNote: PropTypes.object.isRequired,
  deletable: PropTypes.bool.isRequired,
  onDeleted: PropTypes.func.isRequired,
};

export default ContactNote;

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import AssigneeSelector from './AssigneeSelector';

class AssigneeSelect extends Component {
  constructor(props) {
    super(props);

    let responsibleParam = '';

    if (props.value) {
      responsibleParam = props.value.responsible_param;
    }

    this.state = {
      value: props.value,
      responsibleParam,
    };
  }

  handleChange = (newValue) => {
    let responsibleParam = '';

    if (newValue) {
      responsibleParam = newValue.responsible_param;
    }

    this.setState({ responsibleParam });
  }

  render() {
    return (
      <Fragment>
        <input
          type="hidden"
          name={this.props.name}
          value={this.state.responsibleParam}
        />
        <AssigneeSelector
          value={this.state.value}
          optionsUrl={this.props.optionsUrl}
          onChange={this.handleChange}
        />
      </Fragment>
    );
  }
}

AssigneeSelect.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  isDisabled: PropTypes.bool,
  size: PropTypes.string,
  hideDropdownIndicator: PropTypes.bool,
  optionsUrl: PropTypes.string.isRequired,
};

AssigneeSelect.defaultProps = {
  excludeUserIds: [],
  placeholder: 'Rolle oder Team',
};

export default AssigneeSelect;

import React, { Component } from 'react';
import UploaderProvider from '../Uploader/UploaderProvider';
import NewEmailForm from './NewEmailForm';

class NewEmail extends Component {
  render() {
    return (
      <UploaderProvider>
        <NewEmailForm {...this.props} />
      </UploaderProvider>
    );
  }
}

export default NewEmail;

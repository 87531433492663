import React, { Component } from "react";

import Icon from './Icon';

class FileBrowser extends Component {
  render() {
    return (
      <Icon name="rotate-right" spin />
    );
  }
}

export default FileBrowser;

import React from 'react';
import PropTypes from 'prop-types';

class LogicalConnectorSelector extends React.Component {
  handleChange = (e, value) => {
    e.preventDefault();

    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  renderTitle() {
    if (this.props.value === 'or') {
      return this.props.orText;
    } else if (this.props.value === 'and') {
      return this.props.andText;
    }
    return null;
  }

  render() {
    return (
      <div className="dropdown" style={{ display: 'inline-block' }}>
        <button className="btn btn-light btn-xs dropdown-toggle" type="button"data-bs-toggle="dropdown" style={{ fontWeight: 'bold', marginTop: -2 }}>
          {this.renderTitle()} <span className="caret" />
        </button>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
          <li><a href="#" className="dropdown-item" onClick={e => this.handleChange(e, 'and')}>{this.props.andText}</a></li>
          <li><a href="#" className="dropdown-item" onClick={e => this.handleChange(e, 'or')}>{this.props.orText}</a></li>
        </ul>
      </div>
    );
  }
}

LogicalConnectorSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  andText: PropTypes.string,
  orText: PropTypes.string,
};

LogicalConnectorSelector.defaultProps = {
  value: 'and',
  andText: 'allen',
  orText: 'einem',
};

export default LogicalConnectorSelector;

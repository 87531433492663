import React from 'react';
import PropTypes from 'prop-types';

import NoDataPointDisplay from './NoDataPointDisplay';

const StringChoiceDataPointDisplay = ({ dataPoint }) => {
  if (!dataPoint || !dataPoint.option) return <NoDataPointDisplay />;

  return dataPoint.option.string_value;
};

StringChoiceDataPointDisplay.propTypes = {
  dataPoint: PropTypes.object,
};

export default StringChoiceDataPointDisplay;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dropdown from '../shared/Dropdown';
import TodoPreview from '../TodoPreviews/TodoPreview';

import { getAsync } from '../../helpers/rails_helper';

class TodoText extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      hoverEnabled: true,
      todoPreview: null,
      editing: false,
    };
  }

  loadPreview = () => {
    return getAsync(`/todos/${this.props.id}/preview.json`).then((result) => {
      this.setState({
        todoPreview: result.todo,
      });
    });
  };

  hideDropDown = () => {
    this.setState({
      dropdownOpen: false,
      editing: false,
      todoPreview: null,
    });
  };

  handleMouseEnter = () => {
    // Vorschaufenster nur auf Desktops anzeigen
    if (window.innerWidth < 1024) return;

    if (this.state.hoverEnabled) {
      if (this.leaveTimer) clearTimeout(this.leaveTimer);

      if (!this.state.todoPreview) {
        this.enterTimer = setTimeout(() => {
          this.loadPreview().then(() => {
            this.setState({
              dropdownOpen: true,
              finished: false,
            });
          });
        }, 250);
      }
    }
  };

  handleMouseLeave = () => {
    if (this.enterTimer) clearTimeout(this.enterTimer);

    if (!this.state.editing) {
      this.leaveTimer = setTimeout(this.hideDropDown, 200);
    }
  };

  handleClose = () => {
    this.hideDropDown();
  };

  handleMouseDown = () => {
    this.hideDropDown();
    this.setState({ hoverEnabled: false, dropdownOpen: false });
    if (this.enterTimer) clearTimeout(this.enterTimer);
  };

  handleMouseUp = () => {
    this.setState({ hoverEnabled: true });
  };

  handleEdit = () => {
    this.setState({ editing: true });
  };

  handleFinish = () => {
    this.setState({ editing: false, finished: true });
  };

  handleNewCommentSaved = (newComment) => {
    const newRecentComments = [...(this.state.todoPreview.recent_comments || []), newComment];

    if (newRecentComments.length > 2) {
      newRecentComments.shift();
    }

    this.setState({
      todoPreview: Object.assign({}, this.state.todoPreview, {
        recent_comments: newRecentComments,
        comments_count: this.state.todoPreview.comments_count + 1,
      }),
    });
  };

  renderTarget = (ref) => {
    return (
      <a
        href={`/todos/${this.props.id}`}
        className="todo-sorting-handle"
        ref={ref}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}>
        {this.props.text}
      </a>
    );
  };

  render() {
    const blanketStyle = {
      pointerEvents: (this.state.editing || this.state.finished) ? 'initial' : 'none',
      backgroundColor: '#000',
      transition: 'opacity 200ms ease-in-out',
      opacity: this.state.editing ? 0.7 : 0,
    };

    return (
      <div className="todo-sub-text">
        <Dropdown
          style={{ marginLeft: 50 }}
          // hideBlanket
          blanketStyle={blanketStyle}
          placement="top-start"
          isOpen={this.state.dropdownOpen}
          onClose={this.handleClose}
          target={this.renderTarget}>
          <div
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            style={{ width: 395 }}>
            <TodoPreview
              editing={this.state.editing}
              todo={this.state.todoPreview}
              onEdit={this.handleEdit}
              onFinish={this.handleFinish}
              commentable={this.props.commentable}
              onNewCommentSaved={this.handleNewCommentSaved}
            />
          </div>
        </Dropdown>
      </div>
    );
  }
}

TodoText.propTypes = {
  text: PropTypes.string,
  commentable: PropTypes.bool.isRequired,
  id: PropTypes.number,
};

export default TodoText;

import React from 'react';
import PropTypes from 'prop-types';

import MonthSelector from '../../shared/MonthSelector';

const MonthDataPointInput = ({ onChange, value, saving }) => {
  const handleChange = (v) => {
    onChange(v);
  };

  return (
    <MonthSelector
      disabled={saving}
      className="form-control"
      placeholder="Bitte auswählen"
      value={value}
      onChange={handleChange}
    />
  );
};

MonthDataPointInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  saving: PropTypes.bool,
};

export default MonthDataPointInput;

import React from 'react';
import PropTypes from 'prop-types';

import WeekSelector from '../../shared/WeekSelector';

const WeekDataPointInput = ({ onChange, value, saving }) => {
  const handleChange = (v) => {
    onChange(v);
  };

  return (
    <WeekSelector
      disabled={saving}
      className="form-control"
      placeholder="Bitte auswählen"
      value={value}
      onChange={handleChange}
    />
  );
};

WeekDataPointInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  saving: PropTypes.bool,
};

export default WeekDataPointInput;

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Modal from '../shared/Modal';

class InfoModal extends Component {
  constructor(props) {
    super(props);

    this.handleOpenModalClick = this.handleOpenModalClick.bind(this);
    this.handleModalClosed = this.handleModalClosed.bind(this);
    this.handleCloseClick = this.handleCloseClick.bind(this);

    this.state = {
      modalVisible: false,
    };
  }

  handleOpenModalClick(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (this.props.onOpen) {
      this.props.onOpen();
    }

    this.setState({ modalVisible: true });
  }

  handleCloseClick() {
    this.setState({
      modalVisible: false,
    });
  }

  handleModalClosed() {
    this.setState({
      saving: false,
    });

    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  renderTarget() {
    if (this.props.target) {
      return this.props.target(this.handleOpenModalClick);
    }

    return (
      <button>
        Modal öffnen
      </button>
    );
  }

  render() {
    if (!this.props.visible) return null;

    const confirmClass = `btn-${this.props.closeStyle}`;
    const loadingClass = this.state.saving ? 'btn-loading' : '';

    return (
      <Fragment>
        {this.renderTarget()}
        <Modal
          visible={this.state.modalVisible}
          onClose={this.handlecloseClick}
          onClosed={this.handleModalClosed}>
          <div className="react-modal-header">
            <h4>{this.props.title}</h4>
          </div>
          <div className="react-modal-content">
            {this.props.children}
          </div>

          <div className="react-modal-footer">
            <div className="text-muted" />
            <div>
              <button
                className={['btn', confirmClass, loadingClass].join(' ')}
                onClick={this.handleCloseClick}>
                {this.props.closeText}
              </button>
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

InfoModal.propTypes = {
  target: PropTypes.func,
  title: PropTypes.string,
  closeStyle: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.node,
  closeText: PropTypes.string,
  visible: PropTypes.bool,
};

InfoModal.defaultProps = {
  title: 'Info',
  closeStyle: 'default',
  children: '',
  closeText: 'Schließen',
  visible: true,
};

export default InfoModal;

/* global window, URLSearchParams */
import { Turbo } from "@hotwired/turbo-rails"

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ContactSelectorDropdown from './ContactSelectorDropdown';

import Icon from '../shared/Icon';

class ContactFilter extends Component {
  handleContactSelected = (contact) => {
    const newId = contact.id;
    const urlParams = new URLSearchParams(window.location.search);
    const contactsString = urlParams.get(this.props.queryParam) || '';
    const contacts = (contactsString === '') ? [] : contactsString.split('.');

    if (!contacts.includes(newId)) {
      contacts.push(newId);
    }

    if (this.props.multi) {
      urlParams.set(this.props.queryParam, contacts.join('.'));
    } else {
      urlParams.set(this.props.queryParam, contact.id);
    }

    const path = `${window.location.pathname}?${urlParams.toString()}`;
    Turbo.visit(path);
  }

  renderButton = (ref, instance) => {
    return (
      <button ref={ref} className="btn btn-sm btn-light dropdown-toggle" onClick={instance.toggleDropDown}>
        <Icon name="id-card" /> {this.props.text} <span className="caret" />
      </button>
    );
  }

  render() {
    return (
      <ContactSelectorDropdown
        target={this.renderButton}
        onAdd={this.handleContactSelected}
        optionsUrl={this.props.optionsUrl}
        placement={this.props.dropdownPlacement}
        withInstallationCompany={this.props.withInstallationCompany}
      />
    );
  }
}

ContactFilter.propTypes = {
  optionsUrl: PropTypes.string.isRequired,
  text: PropTypes.string,
  multi: PropTypes.bool,
  queryParam: PropTypes.string,
  dropdownPlacement: PropTypes.string,
  withInstallationCompany: PropTypes.bool,
};

ContactFilter.defaultProps = {
  text: '',
  multi: false,
  queryParam: "c",
  withInstallationCompany: false,
};

export default ContactFilter;
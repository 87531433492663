import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ResponsibleBadge from './ResponsibleBadge';
import RoleBadge from './RoleBadge';
import DueBadge from './DueBadge';
import PriorityIcon from './PriorityIcon';
import SubTodosBadge from './SubTodosBadge';
import Icon from '../shared/Icon';
import TodoText from './TodoText';
import TodoCommentsBadge from './TodoCommentsBadge';

import { subscribe, unsubscribe } from '../../helpers/actioncable_helper';

class WorkflowTodo extends Component {
  constructor(props) {
    super(props);

    this.state = { todo: props.todo };
  }

  componentDidMount() {
    this.channel = subscribe({
      channel: 'TodoChangesChannel',
      todo_id: this.props.todo.id,
    }, (data) => {
      if (data.action === 'update') {
        this.setState({
          todo: data.todo,
        });
      }
    });
  }

  componentWillUnmount() {
    unsubscribe(this.channel);
  }

  render() {
    const classNames = ['todo-sub-todo'];

    const badgeStyle = { marginRight: 4 };

    if (this.state.todo.active) {
      classNames.push('sub-todo-active');
    } else {
      classNames.push('sub-todo-inactive');
    }

    if (this.state.todo.complete) {
      classNames.push('sub-todo-complete');
    }

    let firedIcon = null;

    if (this.state.todo.fired_action) {
      firedIcon = <Icon name={this.state.todo.fired_action.icon} />;
    }

    const bulletClassNames = ['workflow-todo-bullet'];

    if (!this.state.todo.complete && this.state.todo.active_triggers_count) {
      bulletClassNames.push('workflow-todo-bullet-active-triggers');
    }

    return (
      <div className="sub-todo-container workflow-todo-container" id={`item_${this.state.todo.id}`}>
        <div className={classNames.join(' ')}>
          <div className="workflow-todo-bullet-container">
            <div className={bulletClassNames.join(' ')}>
              {firedIcon}
            </div>
          </div>
          <TodoText {...this.state.todo} commentable={this.props.commentable} />

          <div className="todo-meta todo-badges-sm">
            <ResponsibleBadge todo={this.state.todo} style={badgeStyle} />
            <RoleBadge todo={this.state.todo} style={badgeStyle} />
            <DueBadge todo={this.state.todo} style={badgeStyle} />
            <SubTodosBadge todo={this.state.todo} style={badgeStyle} />
            <TodoCommentsBadge todo={this.state.todo} style={badgeStyle} showText={false} />
            <PriorityIcon todo={this.state.todo} style={badgeStyle} />
          </div>
        </div>
      </div>
    );
  }
}

WorkflowTodo.propTypes = {
  todo: PropTypes.object.isRequired,
  commentable: PropTypes.bool.isRequired,
};

export default WorkflowTodo;

/* eslint-disable no-multi-spaces */
import React from 'react';
import PropTypes from 'prop-types';

import { defaultStyles, smallStyles } from '../../styles/select_styles';

import { getAsync } from '../../helpers/rails_helper';

import Select, { components } from 'react-select';

const filterOption = (option, inputValue) => {
  if (!inputValue) return true;
  return inputValue.replace(/ +(?= )/g, '').split(' ').every(term => (
    option.data.title.toLowerCase().includes(term.toLowerCase())
  ));
};

const prepareOptions = (rawOptions, allowedTypes) => {
  const returnOptions = [];

  rawOptions.forEach((o) => {
    const nestedOptions = o.data_point_templates.filter((dpt) => {
      return !allowedTypes || allowedTypes.includes(dpt.data_point_type);
    });

    if (nestedOptions.length) {
      returnOptions.push({
        label: o.title,
        options: nestedOptions,
      });
    }
  });

  return returnOptions;
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      {props.data.is_deprecated ? (
        <span className="me-1 badge bg-warning" style={{ verticalAlign: 'text-top' }}>veraltet</span>
      ) : null}
      <span title={props.data.title}>
        {props.data.title}
      </span>
    </components.Option>
  );
};

const SingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      {props.data.is_deprecated ? (
        <span className="me-1 badge bg-warning" style={{ verticalAlign: 'text-top' }}>veraltet</span>
      ) : null}
      <span title={props.data.title}>
        {props.data.title}
      </span>
    </components.SingleValue>
  );
};

class DataPointTemplateSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      optionsLoaded: false,
    };
  }

  loadOptions = () => {
    getAsync(this.props.optionsUrl).then((result) => {
      this.setState({
        options: prepareOptions(result.data_schemas, this.props.allowedTypes),
        optionsLoaded: true,
      });
    });
  }

  handleFocus = () => {
    if (!this.state.optionsLoaded) {
      this.loadOptions();
    }
  }

  render() {
    return (
      <Select
        components={{
          Option,
          SingleValue,
        }}
        isClearable={this.props.isClearable}
        openMenuOnFocus
        value={this.props.value}
        onChange={this.props.onChange}
        options={this.state.options}
        onFocus={this.handleFocus}
        filterOption={filterOption}
        placeholder={this.props.placeholder}
        loadingPlaceholder="Laden..."
        noOptionsMessage={() => 'Keine Ergebnisse'}
        onBlur={this.handleBlur}
        onKeyDown={this.handleKeyDown}
        backspaceRemovesValue={false}
        blurInputOnSelect
        menuPlacement="auto"
        styles={this.props.size === 'small' ? smallStyles : defaultStyles}
        onInputChange={this.handleInputChange}
        defaultOptions
      />
    );
  }
}

DataPointTemplateSelector.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  optionsUrl: PropTypes.string.isRequired,
  size: PropTypes.string,
  allowedTypes: PropTypes.array,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
};

DataPointTemplateSelector.defaultProps = {
  isClearable: false,
  placeholder: "Bitte auswählen",
};

export default DataPointTemplateSelector;

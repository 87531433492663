import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { searchText } from '../../helpers/helpers';

const InstallationCompanyOption = props => (
  <components.Option {...props}>
    <span className="fullname_with_avatar">
      <span className="avatar-wrapper avatar-wrapper-mini avatar-link-with-fullname">
        <img src={props.data.avatar.thumb.url} alt={props.data.display_name} className="avatar avatar-mini" />
      </span>
      {props.data.display_name}
    </span>
  </components.Option>
);

const filterCompanyOption = (option, searchTerm) => {
  return searchText(option.data.display_name.toLowerCase(), searchTerm);
};

InstallationCompanyOption.propTypes = {
  data: PropTypes.object.isRequired,
};

export default InstallationCompanyOption;
export { filterCompanyOption };

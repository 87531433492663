/* eslint-disable camelcase */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Uploads from '../Uploader/Uploads';

class UploadManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploads: [],
      queueDone: true,
      uploadQueueHidden: true,
    };
  }

  getChildContext() {
    return {
      uploadFiles: this.uploadFiles,
    };
  }

  componentDidMount() {
    this.uploadQueue = this.context.uploader.createQueue();

    this.uploadQueue.addListener('onUploadsChanged', this.handleUploadsChanged);
    this.uploadQueue.addListener('onQueueDone', this.handleUploadsDone);
    this.uploadQueue.addListener('onUploadDone', this.props.onFileAdded);
  }

  handleUploadsChanged = (uploads) => {
    this.setState({ uploads });
  };

  handleUploadsDone = () => {
    this.setState(prevState => ({
      queueDone: true,
      uploadsFailed: prevState.uploads.some(u => u.status === 'failed'),
    }));

    if (this.state.uploads.every(u => u.status === 'stored')) {
      this.doneTimer = setTimeout(() => {
        this.setState({
          queueDone: true,
          uploadQueueHidden: true,
        });
        setTimeout(() => {
          this.uploadQueue.clearQueue();
          this.setState({
            uploadsFailed: false,
          });
        }, 500);
      }, 3000);
    }
  };

  handleHideUploadQueue = () => {
    this.setState({
      uploadQueueHidden: true,
      uploadsFailed: false,
    });

    setTimeout(() => {
      this.uploadQueue.clearQueue();
    }, 500);
  };

  uploadFiles = (files, { attachable_typed_id }) => {
    this.cancelDoneTimer();

    this.uploadQueue.addFiles(files, {
      fields: {
        attachable_typed_id,
      },
    });

    this.setState({
      queueDone: false,
      uploadQueueHidden: false,
    });
  };

  cancelDoneTimer = () => {
    if (this.doneTimer) {
      clearTimeout(this.doneTimer);
    }
  };

  render() {
    return (
      <>
        {this.props.children}
        <Uploads
          uploads={this.state.uploads}
          onHide={this.handleHideUploadQueue}
          hideQueueAvailable={this.state.queueDone && this.state.uploadsFailed}
          hidden={this.state.uploadQueueHidden}
        />
      </>
    );
  }
}

UploadManager.propTypes = {
  children: PropTypes.element.isRequired,
  onFileAdded: PropTypes.func.isRequired,
};

UploadManager.contextTypes = {
  uploader: PropTypes.object,
};

UploadManager.childContextTypes = {
  uploadFiles: PropTypes.func,
};

export default UploadManager;

import PropTypes from 'prop-types';
import moment from 'moment';
import React, { useState } from 'react';
import DatePickerComponent from '../DatePickers/DatePickerComponent';

function DateAcceptor(props) {
  const hideStartDatetime = props.booked || props.rangeStart === props.rangeEnd;
  const [startDatetime, setStartDatetime] = useState(hideStartDatetime ? props.startDatetime : '');
  const [endDatetime, setEndDatetime] = useState(props.isCompany ? '' : props.endDatetime);

  const handleStartDatetimeChange = (value) => {
    if (!value) {
      setStartDatetime('');
      setEndDatetime('');
      return;
    }
    setStartDatetime(value);

    if (!props.isCompany) {
      setEndDatetime(value);
    } else if (
      endDatetime &&
      endDatetime.isValid() &&
      (endDatetime.diff(value, 'days') > 6 || endDatetime.isBefore(value))
    ) {
      setEndDatetime('');
    }
  };

  const buttonText = `Termin${(hideStartDatetime) ? ` für den ${moment(props.startDatetime).format('DD.MM.yyyy')} ` : ' '}bestätigen`;

  return (
    <div>
      <div className={`mb-2 ${hideStartDatetime ? 'd-none' : ''}`}>
        <label htmlFor="start_datetime" className="form-label">Termin taggenau terminieren:</label>
        <DatePickerComponent
          placeholder="Startdatum auswählen"
          name="start_datetime"
          minDate={props.rangeStart}
          maxDate={props.rangeEnd}
          defaultPickerValue={moment(startDatetime || props.rangeStart)}
          blockedDays={[0, 6]}
          value={startDatetime}
          onChange={handleStartDatetimeChange}
        />
      </div>
      <div className={`mb-2 ${props.isCompany ? '' : 'd-none'}`}>
        <label htmlFor="start_datetime" className="form-label">Enddatum festlegen:</label>
        <DatePickerComponent
          placeholder="Enddatum auswählen"
          name="end_datetime"
          minDate={startDatetime}
          maxDate={moment(startDatetime).add(6, 'd')}
          defaultPickerValue={moment(endDatetime || startDatetime || moment(props.rangeStart))}
          blockedDays={[0, 6]}
          disabled={!startDatetime}
          value={endDatetime}
          onChange={setEndDatetime}
        />
      </div>
      <input
        className="btn btn-success"
        type="submit"
        value={buttonText}
        title="Für den Teilnehmer akzeptieren"
        disabled={!startDatetime || !endDatetime}
      />
    </div>
  );
}

DateAcceptor.defaultProps = {
  startDatetime: '',
  endDatetime: '',
  rangeStart: '',
  rangeEnd: '',
};

DateAcceptor.propTypes = {
  booked: PropTypes.bool.isRequired,
  isCompany: PropTypes.bool.isRequired,
  startDatetime: PropTypes.string,
  endDatetime: PropTypes.string,
  rangeStart: PropTypes.string,
  rangeEnd: PropTypes.string,
};

export default DateAcceptor;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import HiddenInputWrapper from '../shared/HiddenInputWrapper';
import SupplierSelectorAsync from './SupplierSelectorAsync';

class SupplierSelect extends Component {
  render() {
    const { value, name, ...rest } = this.props;
    return (
      <HiddenInputWrapper
        {...rest}
        comp={SupplierSelectorAsync}
        name={name}
        value={value}
      />
    );
  }
}

SupplierSelect.propTypes = {
  value: PropTypes.number,
  name: PropTypes.string,
};

export default SupplierSelect;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Normalize from './Normalize';
import Call from './Call';

import Icon from '../shared/Icon';

class PhoneNumber extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phoneNumber: props.phoneNumber,
    };
  }

  handleChange = (phoneNumber) => {
    this.setState({ phoneNumber });
  }

  shouldShowTooltip = () => {
    return (this.state.phoneNumber.label_to_show || '').length > 15;
  }

  renderNumberMobile() {
    return (
      <span className="d-xs d-sm-none">
        <a href={`tel:${this.state.phoneNumber.text}`}>
          {this.state.phoneNumber.text}
        </a>
      </span>
    );
  }

  renderNumberDesktop() {
    return (
      <span className="d-none d-sm-inline">
        {this.state.phoneNumber.text}
      </span>
    );
  }

  renderIcon() {
    if (this.state.phoneNumber.phone_number_type === 'mobile') {
      return <Icon name="mobile" />;
    }

    return <Icon name="phone" />;
  }

  render() {
    return (
      <div className="row gutter-xs contact-item-row" ref={r => (this.element = r)}>
        <div className="col-sm-3 contact-item-label">
          <p className="text-muted">
            <span title={this.state.phoneNumber.label_to_show}
              data-bs-toggle={this.shouldShowTooltip() ? 'tooltip' : ''}>
              {this.state.phoneNumber.label_to_show}
            </span>
          </p>
        </div>
        <div className="col-sm-9">
          <span className="text-muted">{this.renderIcon()} </span>
          {this.renderNumberDesktop()}
          {this.renderNumberMobile()}
          <Normalize
            hidden={!!this.state.phoneNumber.text_normalized}
            contactId={this.props.contactId}
            countries={this.props.countries}
            phoneNumber={this.state.phoneNumber}
            onChange={this.handleChange}
          />
          <Call
            hidden={!this.state.phoneNumber.text_normalized || !this.props.phoneAccounts}
            phoneIds={this.props.phoneAccounts}
            phoneNumber={this.state.phoneNumber}
            phoneCreationPath={this.props.phoneCreationPath}
          />
        </div>
      </div>

    );
  }
}

PhoneNumber.propTypes = {
  phoneNumber: PropTypes.object,
  phoneAccounts: PropTypes.object,
  contactId: PropTypes.number.isRequired,
  countries: PropTypes.array.isRequired,
  phoneCreationPath: PropTypes.string,
};

export default PhoneNumber;

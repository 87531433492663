import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';

import { smallStyles } from '../../styles/select_styles';

const alteredSmallStyles = {
  ...smallStyles,
  menu: (provided, { isFocused }) => ({
    ...smallStyles.menu(provided, { isFocused }),
    minWidth: 320,
    right: 0,
  }),
};

class EmailSignatureSelector extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(newValue) {
    if (newValue) {
      this.props.onChange(newValue.value);
    } else {
      this.props.onChange();
    }
  }

  render() {
    const options = this.props.options.map((o) => {
      return { value: o.id, label: o.name_with_country };
    });

    return (
      <Select
        openMenuOnFocus
        onBlur={this.props.onCancel}
        defaultMenuIsOpen
        blurInputOnSelect
        menuPlacement="auto"
        onChange={this.handleChange}
        handleInputChange={this.handleInputChange}
        loadOptions={this.getOptions}
        autoFocus
        options={options}
        placeholder="Signatur auswählen"
        loadingPlaceholder="Laden..."
        noOptionsMessage={() => 'Keine Ergebnisse'}
        loadingMessage={() => 'Laden...'}
        styles={alteredSmallStyles}
      />
    );
  }
}

EmailSignatureSelector.propTypes = {
  options: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default EmailSignatureSelector;

import React from 'react';
import { components } from 'react-select';
import { searchText } from '../../helpers/helpers';

const ContactOption = props => (
  <components.Option {...props}>
    <span className="fullname_with_avatar">
      {props.data.display_name}
    </span>
  </components.Option>
);

const filterContactOption = (option, searchTerm) => {
  let text = option.data.full_name.toLowerCase();

  return searchText(text, searchTerm);
};

export default ContactOption;
export { filterContactOption };

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['senderData'];

  type_changed(e) {
    if (e.target.value === "user") {
      this.senderDataTarget.classList.add('d-none')
    } else {
      this.senderDataTarget.classList.remove('d-none')
    }
  }
}

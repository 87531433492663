import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import Tooltip from '../shared/Tooltip';
import ConfirmModal from '../shared/ConfirmModal';

import { deleteTodoActivation } from '../../helpers/api';

const TodoActivation = (props) => {
  return (
    <div className="effect-template" key={props.todo.id}>
      <div className="effect-template-title">
        Aufgabe aktivieren: {props.todo.text}{' '}
        {props.todo_activation.transfer_priority ? (
          <Tooltip title="Priorität wird übernommen">
            <Icon name="info-circle" color="var(--bs-secondary-color)" />
          </Tooltip>
        ) : null}
      </div>
      <div className="effect-template-buttons">
        <ConfirmModal
          visible={props.editable}
          target={onClick => (
            <Tooltip title="Folgeaufgabe entfernen">
              <button
                type="button"
                className="btn btn-compact btn-link"
                onClick={onClick}>
                <Icon name="trash" />
              </button>
            </Tooltip>
          )}
          title="Aktion löschen"
          cancelText="abbrechen"
          confirmText="löschen"
          confirmStyle="danger"
          onOpen={props.onModalOpen}
          onClose={props.onModalClose}
          onConfirm={() => deleteTodoActivation(props.todo_activation)}
          onConfirmed={() => props.onDeleted(props.todo_activation)}>
          Bist du sicher, dass du die Aufgabenaktivierung löschen willst?
        </ConfirmModal>
      </div>
    </div>
  );
};

TodoActivation.propTypes = {
  todo: PropTypes.object.isRequired,
  todo_activation: PropTypes.object.isRequired,
  onDeleted: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  onModalOpen: PropTypes.func,
  onModalClose: PropTypes.func,
};

export default TodoActivation;

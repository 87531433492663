import React from 'react';
import PropTypes from 'prop-types';

import DataviewSectionForm from './DataviewSectionForm';
import RemoteFormModal from '../shared/RemoteFormModal';

import dataviewSectionValidator from '../../validators/dataview_section_validator';
import { saveDataviewSection } from '../../helpers/api';

const DataviewSectionModal = props => (
  <RemoteFormModal
    {...props}
    titleNew="Neuer Abschnitt"
    titleEdit="Abschnitt bearbeiten"
    validator={dataviewSectionValidator}
    form={DataviewSectionForm}
    initialRecord={props.initialSection}
    saveHandler={saveDataviewSection}
    allowOverflow
  />
);

DataviewSectionModal.propTypes = {
  initialSection: PropTypes.object.isRequired,
};

export default DataviewSectionModal;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import RoleTemplateModal from './RoleTemplateModal';
import ProjectRoleModal from './ProjectRoleModal';
import RoleTemplate from './RoleTemplate';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { updateAsync } from '../../helpers/rails_helper';
import { reorder } from '../../helpers/helpers';

const getListStyle = isDraggingOver => ({
  // background: isDraggingOver ? '#eee' : 'transparent',
  // borderRadius: 4,
  // padding: 4,
  // borderRadius: 6,
  // width: '100%',
});

class WorkflowTemplateRoles extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };

    this.handleRoleUpdated = this.handleRoleUpdated.bind(this);
    this.handleRoleDeleted = this.handleRoleDeleted.bind(this);
    this.handleRoleAdded = this.handleRoleAdded.bind(this);

    this.handleDragEnd = this.handleDragEnd.bind(this);
  }

  handleRoleUpdated(data) {
    const roles = this.props.roles.map(role => (
      (role.id === data.role.id) ? data.role : role
    ));

    this.props.onChange(roles);
  }

  handleRoleDeleted(deletedRole) {
    const roles = this.props.roles.filter(role => (
      (role.id !== deletedRole.id)
    ));

    this.props.onChange(roles);
  }

  handleRoleAdded(data) {
    const roles = [...this.props.roles, data.role];

    this.props.onChange(roles);
  }

  handleDragEnd(result) {
    // dropped outside the list
    if (!result.destination) return false;

    const roles = reorder(
      this.props.roles,
      result.source.index,
      result.destination.index,
    );

    this.setState({
      savingOrder: true,
      orderSaved: false,
    });

    this.props.onChange(roles);

    return updateAsync(`/roles/${result.draggableId}/sort.json`, {
      position: result.destination.index + 1,
    }, 'PATCH').then(() => {
      this.setState({
        savingOrder: false,
        orderSaved: true,
      });
    });
  }

  renderRoles() {
    return this.props.roles.map((role, i) => (
      <RoleTemplate
        key={`role-template-${role.id}`}
        index={i}
        editable={this.props.editable}
        role={role}
        onRoleUpdated={this.handleRoleUpdated}
        onRoleDeleted={this.handleRoleDeleted}
        organizationSlug={this.props.organizationSlug}
      />
    ));
  }

  renderDesc() {
    if (this.props.roles.length) return null;

    return (
      <p className="text-muted">
        Verwende Rollen, um Aufgaben in Projekten schnell bestimmten Personen zuzuweisen. Z.B. Marketing, Buchhaltung, etc.
      </p>
    );
  }

  render() {
    return (
      <div className="row">
        <div className="col-sm-12">
          <h3 className="mb-3">Rollen</h3>

          <DragDropContext onDragEnd={this.handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}>
                  {this.renderRoles()}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {this.renderDesc()}

          <RoleTemplateModal
            organizationSlug={this.props.organizationSlug}
            visible={this.props.editable}
            title="Neue Rolle"
            onSaved={this.handleRoleAdded}
            initialRole={{
              name: '',
              workflow_id: this.props.workflowId,
            }}>
            {onClick => (
              <button className="btn btn-success btn-compact" onClick={onClick}>
                Neue Rolle
              </button>
            )}
          </RoleTemplateModal>
          {' '}
          <ProjectRoleModal
            organizationSlug={this.props.organizationSlug}
            visible={this.props.editable}
            title="Projektrolle hinzufügen"
            onSaved={this.handleRoleAdded}
            initialRole={{
              name: '',
              workflow_id: this.props.workflowId,
            }}>
            {onClick => (
              <button className="btn btn-success btn-compact" onClick={onClick}>
                Projektrolle hinzufügen
              </button>
            )}
          </ProjectRoleModal>
        </div>
      </div>
    );
  }
}

WorkflowTemplateRoles.propTypes = {
  onChange: PropTypes.func.isRequired,
  workflowId: PropTypes.number.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  roles: PropTypes.array.isRequired,
  editable: PropTypes.bool,
};

export default WorkflowTemplateRoles;

/* eslint-disable no-multi-spaces */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import DataPointTemplateSelector from './DataPointTemplateSelector';

class DataPointTemplateSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.initialValue,
    };
  }

  handleChange = (option) => {
    this.setState({ value: option });

    if (this.props.onChange) {
      this.props.onChange(option);
    }
  }

  render() {
    const value = (this.state.value && this.state.value.id) || '';

    return (
      <Fragment>
        <input type="hidden" name={this.props.name} value={value} />
        <DataPointTemplateSelector
          isClearable={this.props.isClearable}
          onChange={this.handleChange}
          value={this.state.value}
          optionsUrl={this.props.optionsUrl}
          allowedTypes={this.props.allowedTypes}
        />
      </Fragment>
    );
  }
}

DataPointTemplateSelect.propTypes = {
  initialValue: PropTypes.object,
  optionsUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  allowedTypes: PropTypes.array,
  onChange: PropTypes.func,
  isClearable: PropTypes.bool,
};

export default DataPointTemplateSelect;

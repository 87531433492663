import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AsyncSelect from 'react-select/async';

import { components } from 'react-select';

const stylesDefault = {
  control: (provided, { isFocused }) => ({
    ...provided,
    // borderColor: isFocused ? '#ccc' : '#ccc',
    borderColor: isFocused ? '#2f7dd5' : '#ccc',
    boxShadow: isFocused ? '0 0 0 0.2rem #b9d4ec' : 'none',

  }),
  // menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
  option: (provided, { isFocused }) => ({
    ...provided,
    padding: '5px 8px',
    backgroundColor: isFocused ? '#2f7dd5' : 'white',
    color: isFocused ? 'white' : '#333',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
};

const stylesSmall = {
  control: (provided, { isFocused }) => ({
    ...provided,
    // borderColor: isFocused ? '#ccc' : '#ccc',

    borderColor: isFocused ? '#2f7dd5' : '#ccc',
    boxShadow: isFocused ? '0 0 0 0.2rem #b9d4ec' : 'none',
    height: 30,
    minHeight: 30,
    borderRadius: 4,
    fontSize: 13,
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    padding: '5px 8px',
    backgroundColor: isFocused ? '#2f7dd5' : 'white',
    color: isFocused ? 'white' : '#333',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    width: 35,
    height: 30,
  }),
  menu: (provided, { isFocused }) => ({
    ...provided,
    minWidth: 320,
    right: 0,
  }),
};

const NoComp = () => {
  return null;
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      {props.data.title}
    </components.Option>
  );
};

const SingleValue = (props) => (
  <components.SingleValue {...props}>
    {props.data.title}
  </components.SingleValue>
);

class ShortMessageTemplateSelector extends Component {
  constructor(props) {
    super(props);

    this.getOptions = this.getOptions.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  getOptions(searchTerm) {
    let url = `${this.props.optionsUrl}?q=${encodeURIComponent(searchTerm)}`;

    if (this.props.contactId && this.props.projectId) {
      url = `${this.props.optionsUrl}?contact_id=${this.props.contactId}&project_id=${this.props.projectId}&q=${encodeURIComponent(searchTerm)}`;
    }

    return fetch(url, {
      credentials: 'include',
    }).then((response) => {
      return response.json();
    }).then((options) => {
      return options.short_message_templates;
    });
  }

  handleBlur() {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  render() {
    return (
      <AsyncSelect
        components={{
          Option,
          SingleValue,
          DropdownIndicator: this.props.hideDropdownIndicator ? NoComp : components.DropdownIndicator,
          IndicatorSeparator: this.props.hideDropdownIndicator ? NoComp : components.IndicatorSeparator,
        }}
        defaultValue={this.props.value}
        openMenuOnFocus
        onBlur={this.handleBlur}
        onKeyDown={this.handleKeyDown}
        backspaceRemovesValue={false}
        blurInputOnSelect
        menuPlacement="auto"
        onChange={this.props.onChange}
        handleInputChange={this.handleInputChange}
        loadOptions={this.getOptions}
        autoFocus={this.props.autoFocus}
        styles={this.props.size === 'small' ? stylesSmall : stylesDefault}
        placeholder={this.props.placeholder}
        loadingPlaceholder="Laden..."
        noOptionsMessage={() => 'Keine Ergebnisse'}
        loadingMessage={() => 'Laden...'}
        defaultOptions
      />
    );
  }
}

ShortMessageTemplateSelector.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  size: PropTypes.string,
  hideDropdownIndicator: PropTypes.bool,
  optionsUrl: PropTypes.string.isRequired,
  contactId: PropTypes.number,
  projectId: PropTypes.number,
  onBlur: PropTypes.func,
  autoFocus: PropTypes.bool.isRequired,
};

ShortMessageTemplateSelector.defaultProps = {
  placeholder: 'Vorlage auswählen',
  autoFocus: true,
};

export default ShortMessageTemplateSelector;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CheckTodo from './CheckTodo';

class CheckTodos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      todos: props.todos,
      parentTodo: props.parentTodo,
    };
  }

  renderTodos() {
    return this.state.todos.map((todo) => {
      return (
        <CheckTodo
          key={todo.id}
          initialTodo={todo}
          completable={this.props.mayCompleteAllTodos}
          commentable={this.props.mayCommentTodos}
          currentUserId={this.props.currentUserId}
        />
      );
    });
  }

  render() {
    const parentType = this.props.parentTodo ? 'Todo' : 'Workflow';
    const parentId = this.props.parentTodo ? this.props.parentTodo.id : this.props.workflowId;
    const url = `/todos/sort?parent_type=${parentType}&parent_id=${parentId}`;

    if (!this.state.todos.length) return null;

    return (
      <div className="todo-sub-todos">
        <div ref={e => (this.sortableContainer = e)} data-update-url={url}>
          {this.renderTodos()}
        </div>
      </div>
    );
  }
}

CheckTodos.propTypes = {
  todos: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  workflowId: PropTypes.number.isRequired,
  parentTodo: PropTypes.object,
  mayCompleteAllTodos: PropTypes.bool.isRequired,
  mayCommentTodos: PropTypes.bool.isRequired,
  currentUserId: PropTypes.number.isRequired,
};

export default CheckTodos;

import { defaultStyles } from '../styles/select_styles';

const emailSelectStyles = {
  control: (provided, { isFocused }) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    minHeight: 0,
    alignItems: 'start',
    borderRadius: 4,
    fontSize: 14,
    background: 'transparent',
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    ...defaultStyles.option(provided, { isFocused }),
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    width: 35,
    height: 30,
  }),
  menu: (provided, { isFocused }) => ({
    ...provided,
    ...defaultStyles.menu(provided, { isFocused }),
  }),
  placeholder: (provided, { isFocused }) => ({
    ...provided,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  multiValue: (provided, { isFocused }) => ({
    ...provided,
    backgroundColor: isFocused ? 'var(--email-address-selected-bg)' : 'var(--email-address-bg)',
    color: isFocused ? '#fff' : 'var(--bs-body-color)',
    borderRadius: 4,
    overflow: 'hidden',
    '.avatar-wrapper': {
      marginBottom: 0,
    },
  }),
  multiValueLabel: (provided, { isFocused }) => ({
    ...provided,
    color: isFocused ? '#fff' : 'var(--bs-body-color)',
  }),
  multiValueRemove: (provided, { isFocused }) => ({
    ...provided,
    'backgroundColor': isFocused ? 'var(--email-address-selected-bg)' : 'var(--email-address-remove-bg)',
    '&:hover': {
      backgroundColor: 'var(--email-address-remove-hover-bg)',
      color: 'var(--bs-body-color)',
    },
  }),
};

export default emailSelectStyles;

import React from 'react';
import PropTypes from 'prop-types';

import TriggerForm from './TriggerForm';
import RemoteFormModal from '../shared/RemoteFormModal';

import triggerValidator from '../../validators/trigger_validator';
import { saveTrigger } from '../../helpers/api';

const TriggerModal = props => (
  <RemoteFormModal {...props}
    titleNew="Trigger hinzufügen"
    titleEdit="Trigger bearbeiten"
    validator={triggerValidator}
    form={TriggerForm}
    initialRecord={props.initialTodoActivation}
    saveHandler={saveTrigger}
  />
);

TriggerModal.propTypes = {
  initialTodoActivation: PropTypes.object.isRequired,
};

export default TriggerModal;

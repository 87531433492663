import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import SearchResultLink from './SearchResultLink';

import ProductAvailabilityIcon from '../Products/ProductAvailabilityIcon';

const SearchResultProduct = (props) => {
  const iconClass = props.active ? 'active' : '';

  const label = props.archived ? (
    <span className="search-result-product-archived">archiviert</span>
  ) : null;

  return (
    <SearchResultLink {...props}>
      <div>
        <Icon name="shopping-cart" />
      </div>
      <div>
        <div className="search-result-product">
          <div>
            <Icon name="circle" className={`product-circle ${iconClass}`} style={{ marginRight: 2 }} />
            <ProductAvailabilityIcon status={props.availability_status} />{' '}
            {props.name} {props.model}
          </div>
          <div>
            <div style={{ display: 'flex' }}>
              <div style={{ flexGrow: 1 }}>
                <span className="text-monospace">{props.article_number} </span>
                {props.manufacturer_name}, {props.category_name}
              </div>
              <div style={{ textAlign: 'right' }}>
                {label}
              </div>
            </div>
          </div>
        </div>
      </div>
    </SearchResultLink>
  );
};

SearchResultProduct.propTypes = {
  name: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  model: PropTypes.string.isRequired,
  manufacturer_name: PropTypes.string.isRequired,
  category_name: PropTypes.string.isRequired,
  article_number: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default SearchResultProduct;

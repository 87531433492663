import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import SupplierSelectorAsync from '../Suppliers/SupplierSelectorAsync';
import ProductSelector from '../Products/ProductSelector';
import IntegerInput from '../shared/IntegerInput';
import DatePicker from '../DatePickers/DatePickerComponent';
import HiddenInputWrapper from '../shared/HiddenInputWrapper';

class OrderRequest extends Component {
  constructor(props) {
    super(props);

    this.handleRemove = this.handleRemove.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      amount: this.props.orderRequest.amount,
      product_id: this.props.orderRequest.product_id,
      amountValid: !!this.props.orderRequest.amount,
    };
  }

  handleRemove(e) {
    e.preventDefault();
    this.props.onRemove(this.props.index);
  }

  handleChange(field, value) {
    this.setState({
      [field]: value,
      [`${field}Valid`]: !!value,
    });
  }

  renderDelete() {
    if (this.props.deletable) {
      return (
        <a href="#" onClick={this.handleRemove}>
          <Icon name="trash" />
        </a>
      );
    }

    return null;
  }

  render() {
    return (
      <div style={{ margin: '8px 0px', display: 'flex' }}>
        <div style={{ flexGrow: 1, display: 'flex' }}>
          <div style={{ width: '10%', paddingRight: 8 }}>
            <div className="mb-3">
              <HiddenInputWrapper
                name="order_requests[list][][amount]"
                comp={IntegerInput}
                minValue={1}
                placeholder="Anzahl"
                className={`form-control ${this.state.amountValid ? '' : 'is-invalid'}`}
                value={this.state.amount}
                onChange={v => this.handleChange('amount', v)}
              />
            </div>
          </div>
          <div style={{ width: '50%', paddingRight: 8 }}>
            <div>
              <HiddenInputWrapper
                name="order_requests[list][][product_id]"
                comp={ProductSelector}
                placeholder="Produkt"
                onChange={v => this.handleChange('productId', v)}
                value={this.state.product_id}
                hideInactive
                organizationSlug={this.props.organizationSlug} />
            </div>
          </div>
          <div style={{ width: '20%', paddingRight: 8 }}>
            <HiddenInputWrapper
              name="order_requests[list][][suggested_supplier_id]"
              comp={SupplierSelectorAsync}
              placeholder="Lieferant"
              onChange={v => this.handleChange('supplierId', v)}
              organizationSlug={this.props.organizationSlug} />
          </div>
          <div style={{ width: '15%', paddingRight: 8 }}>
            <HiddenInputWrapper
              name="order_requests[list][][requested_delivery_at]"
              comp={DatePicker}
              onChange={v => this.handleChange('requestedDeliveryAt', v)}
              placeholder="Datum" />
          </div>
          <div style={{ flexGrow: 1, padding: '7px 10px' }}>
            {this.renderDelete()}
          </div>
        </div>
      </div>
    );
  }
}

OrderRequest.propTypes = {
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  deletable: PropTypes.bool,
  orderRequest: PropTypes.object.isRequired,
};

export default OrderRequest;

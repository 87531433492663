import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { searchText } from '../../helpers/helpers';

const UserOption = props => (
  <components.Option {...props}>
    <span className="fullname_with_avatar">
      <span className="avatar-wrapper avatar-wrapper-mini avatar-link-with-fullname">
        <img src={props.data.avatar.thumb.url} alt={props.data.fullname} className="avatar avatar-mini" />
      </span>
      {props.data.fullname}
    </span>
    {props.data.status_unavailable ? (
      <span
        className="selector-user-unavailable"
        title={`${props.data.status_emoji} ${props.data.status_message}`}>
        nicht verfügbar
      </span>
    ) : ''}
  </components.Option>
);

const filterUserOption = (option, searchTerm) => {
  let text = option.data.fullname.toLowerCase();

  if (option.data.bio) {
    text = `${text} ${option.data.bio.toLowerCase()}`;
  }

  return searchText(text, searchTerm);
};

UserOption.propTypes = {
  data: PropTypes.object.isRequired,
};

export default UserOption;
export { filterUserOption };

/* global window */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import UserSelector from '../Users/UserSelector';

import Tooltip from '../shared/Tooltip';
import Dropdown from '../shared/Dropdown';

class NewMembership extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      selectedUserId: null,
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown(event) {
    if (event.keyCode === 27) {
      this.setState({ dropdownOpen: false });
    }
  }

  handleClick = (e) => {
    e.preventDefault();
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  handleClose = () => {
    this.setState({ dropdownOpen: false });
  }

  handleUserChange = (selectedUser) => {
    this.setState({
      selectedUserId: selectedUser && selectedUser.id,
    });
  }

  handleSaveClick = (e) => {
    e.preventDefault();

    if (this.state.selectedUserId) {
      this.props.onAdd(this.state.selectedUserId);
    }

    this.setState({
      dropdownOpen: false,
    });
  }

  renderTarget = (ref) => {
    return (
      <Tooltip title="Benutzer hinzufügen" placement="top-end">
        <a className="add-user-link d-print-none" href="" ref={ref}
          onClick={this.handleClick}>
          <Icon name="user-plus" />
        </a>
      </Tooltip>
    );
  }

  render() {
    if (!this.props.visible) return null;

    return (
      <Dropdown
        placement="bottom-end"
        isOpen={this.state.dropdownOpen}
        onClose={this.handleClose}
        target={this.renderTarget}>
        <div className="add-user-dropdown">
          <h4 className="mt-3">Benutzer hinzufügen</h4>
          <p className="text-muted">
            Gib weiteren Teammitgliedern Zugriff auf dieses Projekt.
          </p>
          <div style={{ marginBottom: 11 }}>
            <UserSelector
              optionsUrl={`/${this.props.organizationSlug}/users.json`}
              excludeUserIds={this.props.existingMemberships.map(m => m.user_id)}
              onChange={this.handleUserChange} />
          </div>
          <p>
            <button onClick={this.handleSaveClick} disabled={!this.state.selectedUserId} className="btn btn-primary btn-sm">
              Speichern
            </button>
          </p>
        </div>
      </Dropdown>
    );
  }
}

NewMembership.propTypes = {
  visible: PropTypes.bool,
  onAdd: PropTypes.func.isRequired,
  existingMemberships: PropTypes.array,
  organizationSlug: PropTypes.string.isRequired,
};

export default NewMembership;

import React from 'react';

import PropTypes from 'prop-types';

import OperatorSelector from '../../OperatorSelector';

import CategorySelector from '../../../Categories/CategorySelector';

import BaseCondition from '../BaseCondition';

class ProductCategoryCondition extends React.Component {
  handleChangeOperator = ({ value }) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      operator: value,
    }));
  };

  handleCategoryChange = (category) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      category,
      integer_operand_1: category ? category.id : null,
    }));
  };

  renderOperand1() {
    return (
      <div style={{ flexBasis: 200, marginLeft: 8 }}>
        <CategorySelector
          name="Produktkategorie"
          value={this.props.condition.category}
          organizationSlug={this.props.organizationSlug}
          onChange={this.handleCategoryChange}
          size="small"
        />
      </div>
    );
  }

  renderOperator() {
    return (
      <div style={{ flexBasis: 200, marginLeft: 8 }}>
        <OperatorSelector
          operators={this.props.condition.filter_condition_type.operators}
          onChange={this.handleChangeOperator}
          value={this.props.condition.operator}
        />
      </div>
    );
  }

  render() {
    return (
      <BaseCondition {...this.props}>
        {this.renderOperator()}
        {this.renderOperand1()}
      </BaseCondition>
    );
  }
}

ProductCategoryCondition.propTypes = {
  condition: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default ProductCategoryCondition;

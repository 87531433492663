import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import TimeAgo from '../shared/TimeAgo';

const TimeTrigger = ({ action, trigger }) => {
  const [timeIsUp, setTimeIsUp] = useState(false);

  useEffect(() => {
    const diff = Date.parse(trigger.trigger_at) - (new Date()).getTime();

    // only set a timeout if the trigger is less then a day away
    if (diff < 1000 * 3600 * 24) {
      const timer = setTimeout(() => {
        setTimeIsUp(true);
      }, diff);
  
      return () => clearTimeout(timer);
    }
  }, [trigger.trigger_at]);

  const desc = timeIsUp ? 'wird jetzt ausgelöst.' : (
    <>
      wird <TimeAgo datetime={trigger.trigger_at} /> ausgelöst.
    </>
  );

  return (
    <div className="trigger-details">
      <div className="trigger-details-icon">
        <Icon name={action.icon} />
      </div>
      <div className="trigger-details-text">
        <span className="trigger-details-action">{action.name}</span> {desc}
      </div>
    </div>
  );
};

TimeTrigger.propTypes = {
  action: PropTypes.object.isRequired,
  trigger: PropTypes.object.isRequired,
};

export default TimeTrigger;

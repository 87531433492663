import React, { Component, Fragment } from 'react';

import PropTypes from 'prop-types';

import Label from '../shared/Label';
import TagGroupSelector from './TagGroupSelector';

import HiddenInputWrapper from '../shared/HiddenInputWrapper';

class TagGroupMultiSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tagGroups: this.props.tag_groups,
    };
  }

  handleAdd = (tagGroup) => {
    if (!this.state.tagGroups.find(t => t.id === tagGroup.id)) {
      this.setState({
        tagGroups: [...this.state.tagGroups, tagGroup],
      });
    }
  };

  handleRemove = (tagGroup) => {
    this.setState({
      tagGroups: this.state.tagGroups.filter(t => (t.id !== tagGroup.id)),
    });
  };

  renderTags() {
    return this.state.tagGroups.map(tagGroup => (
      <HiddenInputWrapper
        name={`${this.props.name}[]`}
        title={tagGroup.title}
        data={tagGroup}
        value={tagGroup.id}
        comp={Label}
        key={tagGroup.id}
        removable
        onRemove={this.handleRemove} />
    ));
  }

  renderEmptyHiddenInput() {
    return (
      <input type="hidden" name={`${this.props.name}[]`} value="" />
    );
  }

  render() {
    return (
      <Fragment>
        {this.renderEmptyHiddenInput()}
        {this.renderTags()}
        <div style={{ width: 250, marginTop: 10 }}>
          <TagGroupSelector
            excludedTagGroupIds={this.state.tagGroups.map(t => t.id)}
            buttonText={this.state.tagGroups.length ? 'Gruppe' : 'Gruppe hinzufügen'}
            onChange={this.handleAdd}
            optionsUrl={this.props.optionsUrl}
          />
        </div>
      </Fragment>
    );
  }
}

TagGroupMultiSelect.propTypes = {
  tag_groups: PropTypes.array,
  optionsUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default TagGroupMultiSelect;

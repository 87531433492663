/* eslint-disable consistent-return */
import React from 'react';
import PropTypes from 'prop-types';

import SearchResultGlobal from './SearchResultGlobal';
import SearchResultProject from './SearchResultProject';
import SearchResultUser from './SearchResultUser';
import SearchResultContact from './SearchResultContact';
import SearchResultWiki from './SearchResultWiki';
import SearchResultProduct from './SearchResultProduct';
import SearchResultOffer from './SearchResultOffer';
import SearchResultInstallationCompany from './SearchResultInstallationCompany';

const getComp = (type) => {
  if (type === 'user') {
    return SearchResultUser;
  } else if (type === 'project') {
    return SearchResultProject;
  } else if (type === 'contact') {
    return SearchResultContact;
  } else if (type === 'global') {
    return SearchResultGlobal;
  } else if (type === 'wiki') {
    return SearchResultWiki;
  } else if (type === 'product') {
    return SearchResultProduct;
  } else if (type === 'offer') {
    return SearchResultOffer;
  } else if (type === 'installation_company') {
    return SearchResultInstallationCompany;
  }
};

const SearchResult = (props) => {
  const Comp = getComp(props.result.search_result_type);

  return (
    <Comp {...props.result}
      selected={props.selected}
      onMouseOver={props.onMouseOver}
      index={props.index}
      resultsCount={props.resultsCount}
    />
  );
};

SearchResult.propTypes = {
  result: PropTypes.object,
  selected: PropTypes.bool,
  index: PropTypes.number.isRequired,
  resultsCount: PropTypes.number,
};

export default SearchResult;

import React from 'react';
import PropTypes from 'prop-types';

import { isNumber } from 'lodash';

import CurrencySelect from './CurrencySelect';

import DatePicker from "../DatePickers/DatePickerComponent";

import NumericInput from '../shared/NumericInput';

import {
  autoNumericOptionsEuro,
  autoNumericOptionsPercentageMargin,
} from '../../config/auto_numeric_options';

class PricesForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prePurchasePriceValue: props.prePurchasePriceValue,
      prePurchasePriceCurrency: props.prePurchasePriceCurrency,
      purchasePriceValue: props.purchasePriceValue,
      purchasePriceCurrency: props.purchasePriceCurrency,
      manualMargin: props.manualMargin,
      manualPriceValue: props.manualPriceValue,
      manualMarginChecked: isNumber(props.manualMargin),
      manualPriceChecked: isNumber(props.manualPriceValue),
      purchasePriceUpdatedAt: props.purchasePriceUpdatedAt,
    };
  }

  getActiveMargin() {
    if (this.state.manualMarginChecked) {
      return this.state.manualMargin;
    }

    return this.props.categoryMargin;
  }

  // Berechnet den Wechselkurs von einer Währung (fromCurrency) in eine
  // andere Währung (toCurrency).
  // Verwendet dafür die in den countries hinterlegten "exchange_rate_to_base"
  getExchangeRate(fromCurrency, toCurrency) {
    const fromExchangeRateToBase =
      this.props.countries.find(c => c.currency === fromCurrency).exchange_rate_to_base;
    const toExchangeRateToBase =
      this.props.countries.find(c => c.currency === toCurrency).exchange_rate_to_base;

    return (1 / fromExchangeRateToBase) * toExchangeRateToBase;
  }

  // Gibt den resultierenden VK-Preis in der Zielwährung (country.currency) zurück
  // Brücksichtigt dabei
  // - Manuellen Preis
  // - Kategorie Marge
  // - Manuelle Marge
  // - Wechselkurse zwischen EK- und VK-Währungen
  getPrice() {
    if (this.state.manualPriceChecked && isNumber(this.state.manualPriceValue)) {
      return this.state.manualPriceValue;
    }

    let priceInPurchaseCurrency = 0.0;

    if (this.state.manualMarginChecked && isNumber(this.state.manualMargin)) {
      priceInPurchaseCurrency =
        this.state.purchasePriceValue * (1 + (this.state.manualMargin / 100));
    } else if (isNumber(this.props.categoryMargin)) {
      priceInPurchaseCurrency =
        this.state.purchasePriceValue * (1 + (this.props.categoryMargin / 100));
    }

    const exchangeRate =
      this.getExchangeRate(this.state.purchasePriceCurrency, this.props.country.currency);

    return priceInPurchaseCurrency * exchangeRate;
  }

  handlePurchasePriceChange = (e, rawValue) => {
    this.setState({
      purchasePriceValue: rawValue,
    });
  }

  handlePrePurchasePriceChange = (e, rawValue) => {
    this.setState({
      prePurchasePriceValue: rawValue,
    });
  }

  handlePurchasePriceUpdatedAtChange = (newValue) => {
    this.setState({
      purchasePriceUpdatedAt: (newValue ? newValue : ""),
    });
  }

  handleManualMarginChange = (e, rawValue) => {
    this.setState({
      manualMargin: rawValue,
    });
  }

  handleManualPriceChange = (e, rawValue) => {
    this.setState({
      manualPriceValue: rawValue,
    });
  }

  toggleManualMarginChecked = () => {
    this.setState({
      manualMarginChecked: !this.state.manualMarginChecked,
    });

    this.setState({
      manualMarginChecked: !this.state.manualMarginChecked,
      manualMargin: this.state.manualMarginChecked ? '' : this.props.categoryMargin,
    });
  }

  toggleManualPriceChecked = () => {
    const currentPrice = this.getPrice();

    this.setState({
      manualPriceChecked: !this.state.manualPriceChecked,
      manualPriceValue: this.state.manualPriceChecked ? '' : currentPrice,
    });
  }

  handleCurrencySelect = (currency) => {
    this.setState({
      purchasePriceCurrency: currency,
    });
  }

  handlePreCurrencySelect = (currency) => {
    this.setState({
      prePurchasePriceCurrency: currency,
    });
  }

  renderMarginField() {
    if (this.state.manualPriceChecked) {
      return null;
    }

    return (
      <div className="mb-3">
        <label className="form-label">Aufschlag {this.state.manualMarginChecked ? '(manuell)' : 'aus Kategorie' }</label>
        <div className="input-group">
          <NumericInput
            type="text"
            disabled={!this.state.manualMarginChecked || !this.props.allowEditPrice}
            value={this.getActiveMargin()}
            className="form-control"
            onChange={this.handleManualMarginChange}
            options={autoNumericOptionsPercentageMargin}
          />
          <span className="input-group-text">%</span>
        </div>
        <input
          type="hidden"
          value={this.state.manualMarginChecked ? this.state.manualMargin : ''}
          name={`${this.props.baseFieldName}[manual_margin]`}
        />
        <div className="form-check mt-1">
          <input
            disabled={!this.props.allowEditPrice}
            type="checkbox"
            checked={this.state.manualMarginChecked}
            onChange={this.toggleManualMarginChecked}
            className="form-check-input"
            id="manual_margin_checked"
          />
          <label htmlFor="manual_margin_checked" className="form-check-label fw-normal">Manuell</label>
        </div>
      </div>
    );
  }

  renderPrePurchasPriceInput() {
    if (!this.props.allowSeePrePurchasePrice && !this.props.allowEditPrePurchasePrice) {
      return null;
    }

    return (
      <div className="mb-3">
        <label className="form-label">EK (netto-netto)</label>
        <div className="input-group">
          <NumericInput
            disabled={!this.props.allowEditPrePurchasePrice}
            type="text"
            value={this.state.prePurchasePriceValue}
            className="form-control"
            onChange={this.handlePrePurchasePriceChange}
            options={autoNumericOptionsEuro}
          />
          <CurrencySelect
            disabled={!this.props.allowEditPrePurchasePrice}
            currencies={this.props.countries.map(c => c.currency)}
            value={this.state.prePurchasePriceCurrency}
            onChange={this.handlePreCurrencySelect}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-sm-6">
            {this.renderPrePurchasPriceInput()}
            <input
              type="hidden"
              value={this.state.prePurchasePriceValue}
              name={`${this.props.baseFieldName}[pre_purchase_price_value]`}
            />
            <input
              type="hidden"
              value={this.state.prePurchasePriceCurrency}
              name={`${this.props.baseFieldName}[pre_purchase_price_currency]`}
            />
            <div className="mb-3">
              <label className="form-label">EK (netto)</label>
              <div className="input-group">
                <NumericInput
                  type="text"
                  value={this.state.purchasePriceValue}
                  className="form-control"
                  onChange={this.handlePurchasePriceChange}
                  options={autoNumericOptionsEuro}
                  disabled={!this.props.allowEditPurchasePrice}
                />
                <CurrencySelect
                  disabled={!this.props.allowEditPurchasePrice}
                  currencies={this.props.countries.map(c => c.currency)}
                  value={this.state.purchasePriceCurrency}
                  onChange={this.handleCurrencySelect}
                />
              </div>
              <input
                type="hidden"
                value={this.state.purchasePriceValue}
                name={`${this.props.baseFieldName}[purchase_price_value]`}
              />
              <input
                type="hidden"
                value={this.state.purchasePriceCurrency}
                name={`${this.props.baseFieldName}[purchase_price_currency]`}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">EK zuletzt geändert</label>
              <DatePicker
                value={this.state.purchasePriceUpdatedAt}
                onChange={this.handlePurchasePriceUpdatedAtChange}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Lieferant</label>
              <input
                type="text"
                className="form-control"
                placeholder="Lieferant eintragen"
                defaultValue={this.props.purchasePriceSupplier}
                name={`${this.props.baseFieldName}[purchase_price_supplier]`}
              />
              <small className="form-text text-muted">
                Lieferant, auf den sich der EK bezieht
              </small>
            </div>

            <div className="mb-3">
              <label className="form-label">Artikelnummer des Lieferanten</label>
              <input
                type="text"
                className="form-control"
                placeholder="Artikelnummer eintragen"
                defaultValue={this.props.purchasePriceSupplierItemNumber}
                name={`${this.props.baseFieldName}[purchase_price_supplier_item_number]`}
              />
              <small className="form-text text-muted">
                Externe Artikelnummer des Produkts beim Lieferanten
              </small>
            </div>

            <div className="mb-3">
              <label className="form-label">VK netto</label>
              <div className="input-group">
                <NumericInput
                  type="text"
                  disabled={!this.state.manualPriceChecked || !this.props.allowEditPrice}
                  value={this.getPrice()}
                  className="form-control"
                  onChange={this.handleManualPriceChange}
                  options={autoNumericOptionsEuro}
                  />
                <span className="input-group-text">{this.props.priceCurrency}</span>
              </div>
              <div className="form-check mt-1">
                <input
                  disabled={!this.props.allowEditPrice}
                  type="checkbox"
                  checked={this.state.manualPriceChecked}
                  onChange={this.toggleManualPriceChecked}
                  className="form-check-input"
                  id="manual_price_checked"
                />
                <label htmlFor="manual_price_checked" className="form-check-label fw-normal">Manuell</label>
              </div>
            </div>

            <input
              type="hidden"
              value={this.state.manualPriceValue}
              name={`${this.props.baseFieldName}[manual_price_value]`}
            />

            <input
              type="hidden"
              value={this.state.purchasePriceUpdatedAt}
              name={`${this.props.baseFieldName}[purchase_price_updated_at]`}
            />

          </div>
          <div className="col-sm-6">
            {this.renderMarginField()}
          </div>
        </div>
      </div>
    );
  }
}

PricesForm.propTypes = {
  countries: PropTypes.array.isRequired,
  country: PropTypes.object.isRequired,
  manualMargin: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(['']),
  ]),
  manualPriceValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(['']),
  ]),
  prePurchasePriceValue: PropTypes.number,
  prePurchasePriceCurrency: PropTypes.string,
  purchasePriceValue: PropTypes.number,
  purchasePriceCurrency: PropTypes.string,
  purchasePriceSupplier: PropTypes.string,
  purchasePriceSupplierItemNumber: PropTypes.string,
  purchasePriceUpdatedAt: PropTypes.string,
  categoryMargin: PropTypes.number,
  priceCurrency: PropTypes.string,
  baseFieldName: PropTypes.string.isRequired,

  allowSeePrePurchasePrice: PropTypes.bool.isRequired,
  allowEditPrePurchasePrice: PropTypes.bool.isRequired,
  allowEditPurchasePrice: PropTypes.bool.isRequired,
  allowEditPrice: PropTypes.bool.isRequired,
};

PricesForm.defaultProps = {
  prePurchasePriceValue: '0,00',
  purchasePriceValue: '0,00',
  manualMargin: '',
  manualPriceValue: '',
  purchasePriceUpdatedAt: '',
};

export default PricesForm;

import React from 'react';
import PropTypes from 'prop-types';

import HiddenInputWrapper from './HiddenInputWrapper';
import JsonEditor from './JsonEditor';

const JsonEditorField = (props) => {
  const { value, name, ...rest } = props;

  return (
    <HiddenInputWrapper
      {...rest}
      comp={JsonEditor}
      name={name}
      value={value}
    />
  );
};

JsonEditorField.propTypes = {
  value: PropTypes.number,
  name: PropTypes.string,
};

export default JsonEditorField;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import Upload from './Upload';

class Uploads extends Component {
  handleHide = (e) => {
    e.preventDefault();
    this.props.onHide();
  };

  renderUploads() {
    return this.props.uploads.map(upload => (
      <Upload upload={upload} key={upload.temp_id} />
    ));
  }

  renderStatus() {
    if (this.props.uploads.every(u => u.status === 'stored')) {
      return 'Alle Uploads abgeschlossen';
    }

    if (this.props.uploads.some(u => u.status === 'failed')) {
      return 'Einige Uploads fehlgeschlagen';
    }

    return 'Dateien hochladen...';
  }

  renderHideButton() {
    if (this.props.hideQueueAvailable) {
      return (
        <div className="float-end">
          <a href="#" onClick={this.handleHide}>
            <Icon name="times" />
          </a>
        </div>
      );
    }

    return null;
  }

  render() {
    const classes = ['uploads-container'];

    if (this.props.hidden) {
      classes.push('uploads-container-hidden');
    }

    return (
      <div className={classes.join(' ')}>
        <div className="container">
          <div className="row">
            <div className="col-sm-6 offset-sm-6 col-md-5 offset-md-7">
              <div className="uploads">
                <div className="uploads-border" />
                <div className="uploads-content">
                  <h5>
                    {this.renderHideButton()}
                    {this.renderStatus()}
                  </h5>
                  {this.renderUploads()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Uploads.propTypes = {
  uploads: PropTypes.array.isRequired,
  onHide: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
  hideQueueAvailable: PropTypes.bool,
};

export default Uploads;

import React, { Component, Fragment } from 'react';

import PropTypes from 'prop-types';

import Label from '../shared/Label';
import ZipCodeAreaSelector from './ZipCodeAreaSelector';

import HiddenInputWrapper from '../shared/HiddenInputWrapper';

class ZipCodeAreaMultiSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      zipCodeAreas: this.props.zipCodeAreas,
    };
  }

  handleAdd = (zipCodeArea) => {
    this.setState({
      zipCodeAreas: [...this.state.zipCodeAreas, zipCodeArea],
    });
  }

  handleRemove = (zipCodeArea) => {
    this.setState({
      zipCodeAreas: this.state.zipCodeAreas.filter(z => (z.id !== zipCodeArea.id)),
    });
  }

  renderZipCodeAreas() {
    return this.state.zipCodeAreas.map(zipCodeArea => (
      <HiddenInputWrapper
        name={`${this.props.name}[]`}
        title={zipCodeArea.name}
        color="#777"
        textColor="#fff"
        data={zipCodeArea}
        value={zipCodeArea.id}
        comp={Label}
        key={zipCodeArea.id}
        removable
        onRemove={this.handleRemove} />
    ));
  }

  renderEmptyHiddenInput() {
    return (
      <input type="hidden" name={`${this.props.name}[]`} value="" />
    );
  }

  renderSeperator = () => {
    if (this.state.zipCodeAreas.length) {
      return (
        <span className="seperator">|</span>
      );
    }
    return null;
  }

  render() {
    const selectedZipCodeAreaIds = this.state.zipCodeAreas.map(z => z.id);
    const zipCodeAreaOptions = this.props.zipCodeAreaOptions.filter(z => !selectedZipCodeAreaIds.includes(z.id));

    return (
      <Fragment>
        {this.renderEmptyHiddenInput()}
        {this.renderZipCodeAreas()}
        {this.renderSeperator()}
        <ZipCodeAreaSelector
          excludedZipCodeAreaIds={this.state.zipCodeAreas.map(t => t.id)}
          buttonText={this.state.zipCodeAreas.length ? 'Gebiet' : 'Gebiet hinzufügen'}
          onAdd={this.handleAdd}
          options={zipCodeAreaOptions} />
      </Fragment>
    );
  }
}

ZipCodeAreaMultiSelect.propTypes = {
  zipCodeAreas: PropTypes.array,
  zipCodeAreaOptions: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
};

export default ZipCodeAreaMultiSelect;

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Popper from 'popper.js';

const Menu = (props) => {
  const { loading, ...rest } = props;
  const className = loading ? 'box-loading' : '';

  return (
    <div
      className={className}
      {...rest}
    />
  );
};

Menu.propTypes = {
  loading: PropTypes.bool,
};

const Blanket = (props) => {
  const { style, ...rest } = props;

  return (
    <div
      style={Object.assign({
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
        position: 'fixed',
        zIndex: 1000,
      }, style)}
      {...rest}
    />
  );
};

Blanket.propTypes = {
  style: PropTypes.object,
};

// const Dropdown = ({ children, isOpen, target, onClose }) => (
class Dropdown extends Component {
  componentDidMount() {
    this.popperInstance = new Popper(this.button, this.popper, {
      placement: this.props.placement,
      modifiers: {
      },
    });
  }

  componentDidUpdate() {
    this.popperInstance.scheduleUpdate();
  }

  componentWillUnmount() {
    this.popperInstance.destroy();
  }

  getButtonRef = (node) => {
    this.button = node;
  }

  renderContent() {
    if (!this.props.isOpen) return null;
    return (
      <Fragment>
        <Menu loading={this.props.loading}>
          {this.props.children}
        </Menu>
        {this.renderArrow()}
      </Fragment>
    );
  }

  renderArrow() {
    if (!this.props.showArrow) return null;

    return (
      <span className="arrow" x-arrow="" />
    );
  }

  render() {
    const { isOpen, onClose, hideBlanket, target } = this.props;

    return (
      <Fragment>
        {target(this.getButtonRef)}
        <div ref={e => (this.popper = e)} className={isOpen ? 'popper-dropdown' : ''} style={this.props.style} onBlur={this.props.onBlur}>
          {this.renderContent()}
        </div>
        {(isOpen && !hideBlanket) ? <Blanket onClick={onClose} style={this.props.blanketStyle} /> : null}
      </Fragment>
    );
  }
}

Dropdown.propTypes = {
  isOpen: PropTypes.bool,
  target: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.node,
  placement: PropTypes.string,
  loading: PropTypes.bool,
  hideBlanket: PropTypes.bool,
  showArrow: PropTypes.bool,
  style: PropTypes.object,
  blanketStyle: PropTypes.object,
  onBlur: PropTypes.func,
};

Dropdown.defaultProps = {
  placement: 'bottom-start', // https://popper.js.org/popper-documentation.html#Popper.placements
  hideBlanket: false,
  blanketStyle: {},
  showArrow: true,
};

export default Dropdown;

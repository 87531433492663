import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Droppable } from 'react-beautiful-dnd';

import Icon from '../shared/Icon';

class FolderTreeSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      foldersVisible: props.foldersVisible,
      foldersLoaded: false,
    };
  }

  handleCaretClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!this.state.foldersLoaded && !this.state.foldersVisible) {
      this.props.loadChildren(this.props.folderable.typed_id);
    }

    this.setState(prevState => ({
      foldersVisible: !prevState.foldersVisible,
      foldersLoaded: true,
    }));
  };

  handleFolderableSelect = (e) => {
    e.preventDefault();
    this.props.onFolderableSelect(this.props.folderable.typed_id);
  };

  renderSubFolders() {
    if (!this.state.foldersVisible) return null;

    return this.props.getSubFolderables(this.props.folderable.typed_id).map(subFolder => (
      <FolderTreeSection
        selectedFolderableTypedId={this.props.selectedFolderableTypedId}
        onFolderableSelect={this.props.onFolderableSelect}
        key={`folder-tree-${subFolder.id}`}
        depth={this.props.depth + 1}
        folderable={subFolder}
        getSubFolderables={this.props.getSubFolderables}
        loadChildren={this.props.loadChildren}
      />
    ));
  }

  render() {
    const folderIcon = (this.props.selectedFolderableTypedId === this.props.folderable.typed_id) ? 'folder-open' : 'folder';
    const droppableId = `${this.props.folderable.typed_id}-Tree`;

    return (
      <div className="folder-tree-section">
        <Droppable droppableId={droppableId} isCombineEnabled>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              className={
                snapshot.isDraggingOver && snapshot.draggingOverWith !== this.props.folderable.typed_id ? 'dragging-over' : ''
              }>
              <div
                className="folder-tree-item"
                onClick={this.handleFolderableSelect}
                style={{ paddingLeft: 20 * this.props.depth }}>
                <span className="folder-tree-caret" onClick={this.handleCaretClick}>
                  <Icon name={'caret-right'} className={this.state.foldersVisible ? 'open' : ''} />
                </span>
                <Icon name={folderIcon} color={this.props.folderable.color} style={{ fontSize: 16 }} /> {this.props.folderable.name}
              </div>
            </div>
          )}
        </Droppable>

        <div className="folder-tree-subfolders">
          {this.renderSubFolders()}
        </div>
      </div>
    );
  }
}

FolderTreeSection.propTypes = {
  folderable: PropTypes.object.isRequired,
  getSubFolderables: PropTypes.func.isRequired,
  depth: PropTypes.number.isRequired,
  onFolderableSelect: PropTypes.func.isRequired,
  foldersVisible: PropTypes.bool,
  selectedFolderableTypedId: PropTypes.string.isRequired,
  loadChildren: PropTypes.func.isRequired,
};

FolderTreeSection.defaultProps = {
  foldersVisible: false,
};

export default FolderTreeSection;

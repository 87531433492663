import React, { Component } from 'react';
import PropTypes from 'prop-types';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import Dropdown from '../shared/Dropdown';

const german = {
  search: 'Suche',
  notfound: 'Kein Emoji gefunden',
  skintext: 'Wähle die Hautfarbe',
  categories: {
    search: 'Suchergebnisse',
    recent: 'Oft gesucht',
    people: 'Smileys & Personen',
    nature: 'Tiere & Natur',
    foods: 'Essen & Trinken',
    activity: 'Aktivität',
    places: 'Reisen & Orte',
    objects: 'Objekte',
    symbols: 'Symbole',
    flags: 'Flaggen',
    custom: 'Sonder',
  },
};

const buttonStyle = {
  overflow: 'hidden',
  padding: 0,
  height: 34,
  width: 40,
};

const emojiStyle = {
  fontSize: 23,
};

const placeholderEmoji = '😀';

class EmojiPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropDownVisible: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.toggleDropDown = this.toggleDropDown.bind(this);
    this.handleEmojiSelect = this.handleEmojiSelect.bind(this);
  }

  handleEmojiSelect(value) {
    this.toggleDropDown();
    if (this.props.onChange) {
      this.props.onChange(value.native);
    }
  }

  handleClick(e) {
    e.preventDefault();
    this.toggleDropDown();
  }

  toggleDropDown() {
    this.setState({ dropDownVisible: !this.state.dropDownVisible });
  }

  renderEmoji() {
    if (this.props.emoji) {
      return <div style={emojiStyle}>{this.props.emoji}</div>;
    }
    return <div style={emojiStyle} className="greyed-out-emoji">{placeholderEmoji}</div>;
  }

  renderButton = (ref) => {
    return (
      <a ref={ref} className="btn btn-light" onClick={this.handleClick} style={buttonStyle}>
        {this.renderEmoji()}
      </a>
    );
  };

  render() {
    const theme = localStorage.getItem('theme') || 'light';

    return (
      <Dropdown
        placement={this.props.placement}
        isOpen={this.state.dropDownVisible}
        onClose={this.toggleDropDown}
        target={this.renderButton}>
        <Picker
          native
          data={data}
          locale="de"
          onEmojiSelect={this.handleEmojiSelect}
          theme={theme}
        />
      </Dropdown>
    );
  }
}

EmojiPicker.propTypes = {
  placement: PropTypes.string,
  emoji: PropTypes.string,
  onChange: PropTypes.func,
};

EmojiPicker.defaultProps = {
  placement: 'bottom-start',
};

export default EmojiPicker;

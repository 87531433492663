/* global fetch */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Select, { components } from 'react-select';

import { defaultStyles, smallStyles } from '../../styles/select_styles';

import { searchText } from '../../helpers/helpers';

const filterProjectTypes = (option, searchTerm) => {
  return searchText(option.data.title, searchTerm);
};


const Option = props => (
  <components.Option {...props}>
    {props.data.title}
  </components.Option>
);


const SingleValue = props => (
  <components.SingleValue {...props}>
    {props.data.title}
  </components.SingleValue>
);

class ProjectTypeSelectorAsync extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      options: [],
    };
  }

  loadOptions = () => {
    this.setState({ isLoading: true });

    return fetch(this.props.optionsUrl, {
      credentials: 'include',
    }).then((response) => {
      return response.json();
    }).then((options) => {
      this.setState({
        options: options.project_types,
        isLoading: false,
      });
    });
  }

  handleFocus = () => {
    this.loadOptions();
  }

  handleChange = (value) => {
    this.setState({ value });

    if (this.props.onChange) {
      if (value) {
        this.props.onChange(value);
      } else {
        this.props.onChange(null);
      }
    }
  }

  render() {
    return (
      <Select
        components={{
          Option,
          SingleValue,
          DropdownIndicator: this.state.isLoading ? null : components.DropdownIndicator,
        }}
        isLoading={this.state.isLoading}
        onFocus={this.handleFocus}
        backspaceRemovesValue={false}
        blurInputOnSelect
        options={this.state.options}
        menuPlacement="auto"
        onChange={this.handleChange}
        filterOption={filterProjectTypes}
        styles={this.props.size === 'small' ? smallStyles : defaultStyles}
        value={this.props.value}
        placeholder={this.props.placeholder || 'Typ auswählen'}
        loadingPlaceholder="Laden..."
        noOptionsMessage={() => 'Keine Ergebnisse'}
        loadingMessage={() => 'Laden...'}
      />
    );
  }
}

ProjectTypeSelectorAsync.propTypes = {
  onChange: PropTypes.func,
  optionsUrl: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  size: PropTypes.string,
};


export default ProjectTypeSelectorAsync;

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { postAsync, deleteAsync } from '../../helpers/rails_helper';

import Icon from '../shared/Icon';

import { subscribe, unsubscribe } from '../../helpers/actioncable_helper';

class TodoSubscription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subscription: props.subscription,
      parentSubscription: props.parentSubscription,
    };
  }

  componentDidMount() {
    this.subscriptionChangesChannel = subscribe({
      channel: 'SubscribableSubscriptionChangesChannel',
      subscribable_type: 'Todo',
      subscribable_id: this.props.todo.id,
      organization_id: this.props.organizationId,
    }, ({ action, subscription }) => {
      if (action === 'new') {
        this.setState({ subscription });
      } else if (action === 'delete') {
        this.setState({ subscription: null });
      }
    });

    this.parentSubscriptionChangesChannel = subscribe({
      channel: 'SubscribableSubscriptionChangesChannel',
      subscribable_type: 'Project',
      subscribable_id: this.props.projectId,
      organization_id: this.props.organizationId,
    }, ({ action, subscription }) => {
      if (action === 'new') {
        this.setState({ parentSubscription: subscription });
      } else if (action === 'delete') {
        this.setState({ parentSubscription: null });
      }
    });
  }

  componentWillUnmount() {
    unsubscribe(this.subscriptionChangesChannel);
    unsubscribe(this.parentSubscriptionChangesChannel);
  }

  handleIgnoreClick = () => {
    postAsync('/subscriptions', {
      subscription: {
        subscribable_type: 'Todo',
        subscribable_id: this.props.todo.id,
        ignore: true,
      },
    }).then(({ subscription }) => {
      this.setState({ subscription });
    });
  }

  handleSubscribeClick = () => {
    postAsync('/subscriptions', {
      subscription: {
        subscribable_type: 'Todo',
        subscribable_id: this.props.todo.id,
      },
    }).then(({ subscription }) => {
      this.setState({ subscription });
    });
  }

  handleUnsubscribeClick = () => {
    deleteAsync(`/subscriptions/${this.state.subscription.id}`).then(() => {
      this.setState({ subscription: null });
    });
  }

  renderButton() {
    if (this.state.subscription && this.state.subscription.ignore) {
      return (
        <button className="btn btn-sm btn-secondary" onClick={this.handleUnsubscribeClick}>
          <Icon name="bell-slash" /> Ignoriert
        </button>
      );
    } else if (this.state.subscription) {
      return (
        <button className="btn btn-sm btn-secondary" onClick={this.handleUnsubscribeClick}>
          <Icon name="bell" /> Abonniert
        </button>
      );
    } else if (this.state.parentSubscription) {
      return (
        <button className="btn btn-sm btn-outline-secondary" onClick={this.handleIgnoreClick}>
          <Icon name="bell-slash" /> Ignorieren
        </button>
      );
    }

    return (
      <button className="btn btn-sm btn-outline-secondary" onClick={this.handleSubscribeClick}>
        <Icon name="bell" /> Abonnieren
      </button>
    );
  }

  renderDesc() {
    if (this.state.subscription && this.state.subscription.ignore) {
      return 'Du ignorierst diese Aufgabe.';
    } else if (this.state.subscription) {
      return 'Du erhältst Benachrichtigungen.';
    } else if (this.state.parentSubscription) {
      return 'Du erhältst Benachrichtigungen, da du das Projekt abonniert hast.';
    }

    return 'Lass dich über Aktivitäten in dieser Aufgabe benachrichtigen.';
  }

  render() {
    return (
      <Fragment>
        <p>{this.renderButton()}</p>
        <p className="todo-notifications-info">{this.renderDesc()}</p>
      </Fragment>
    );
  }
}

TodoSubscription.propTypes = {
  todo: PropTypes.object,
  subscription: PropTypes.object,
  parentSubscription: PropTypes.object,
  organizationId: PropTypes.number,
  projectId: PropTypes.number,
};

export default TodoSubscription;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import SearchResultLink from './SearchResultLink';

const getStyle = (projectState) => {
  const color = projectState.color || '#aaa';
  const textColor = projectState.text_color || '#fff';

  return {
    fontWeight: 'bold',
    color: textColor,
    border: `1px solid ${color}`,
    backgroundColor: color,
    padding: '.1em .3em',
    margin: '0 4px 0 0',
  };
};

const SearchResultProject = (props) => {
  let state = '';

  if (props.project_state) {
    state = (
      <span className="badge" style={getStyle(props.project_state)}>
        {props.project_state.state}
      </span>
    );
  }

  let type = '';

  if (props.project_type) {
    type = (
      <span className="badge" style={getStyle({ text_color: '#fff', color: '#ccc' })}>
        {props.project_type.title}
      </span>
    );
  }

  let client = '';

  if (props.client) {
    client = (
      <Fragment>
        {props.client.display_name}
        <span className="search-result-project-seperator">/</span>
      </Fragment>
    );
  }

  let archived = '';
  if (props.archived) {
    archived = (
      <span className="badge" style={getStyle({ text_color: '#fff', color: '#999' })}>
        archiviert
      </span>
    );
  }

  return (
    <SearchResultLink {...props}>
      <div>
        <Icon name="road" />
      </div>
      <div>
        <div className="search-result-project">
          <div>
            <img className="organization-image tiny"
              alt={props.organization.name}
              src={props.organization.image} />
            <span className="search-result-project-seperator">/</span>
            {client}
            {props.title}
          </div>
          <div>
            {type}
            {state}
            {archived}
          </div>
        </div>
      </div>
    </SearchResultLink>
  );
};

SearchResultProject.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  selected: PropTypes.bool,
};

export default SearchResultProject;

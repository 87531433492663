import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../shared/Icon';
import ResponsibleSelector from '../../shared/ResponsibleSelector';

class SetRoleEffectForm extends React.Component {
  handleChange = (responsible) => {
    this.props.onChange({
      responsible_gid: responsible && responsible.gid,
      responsible,
    });
  };

  isOptional = () => {
    return this.props.effect.options && this.props.effect.options.optional;
  };

  render() {
    return (
      <div className="fire-effect-item">
        <div className="fire-effect-icon">
          <Icon name="user" />
        </div>
        <div className="fire-effect-content">
          <div className="fire-effect-title">
            {/*TODO: Does this still work?*/}
            <Icon name="circle" color={this.props.effect.role_template.color} /> Rolle <strong>{this.props.effect.role_template.name}</strong> setzen
            {this.isOptional() ? (
              <span className="text-muted"> (optional)</span>
            ) : null}
          </div>
          <div className="fire-effect-inputs">
            <div className="row">
              <div className="col-sm-8">
                <ResponsibleSelector
                  optionsUrl={`/${this.props.organizationSlug}/responsible_options.json`}
                  onChange={this.handleChange}
                  value={this.props.value.responsible}
                  showRoles={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SetRoleEffectForm.propTypes = {
  effect: PropTypes.object.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  value: PropTypes.object,
};

export default SetRoleEffectForm;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ActionTemplateSelector from './ActionTemplateSelector';
import TodoTemplateSelector from './TodoTemplateSelector';
import TagSelectorDropdown from '../Tags/TagSelectorDropdown';
import TagGroupSelector from '../Tags/TagGroupSelector';
import WorkflowTemplateSelector from '../WorkflowTemplates/WorkflowTemplateSelector';
import RoleTemplateSelector from '../shared/RoleTemplateSelector';
import DataPointTemplateSelector from '../shared/DataPointTemplateSelector';
import ProjectRoleSelector from '../ProjectRoles/ProjectRoleSelector';

import ColumnTypeSelector from './ColumnTypeSelector';
import InstallationAppointmentSelect from './InstallationAppointmentSelect';

class DataviewItemElement extends Component {
  handleParameterRecordChange = (newRecord) => {
    this.props.onChange({
      ...this.props.element,
      parameter_record: newRecord,
      integer_parameter: newRecord.id,
    });
  };

  renderParamSelector() {
    if (!this.props.element.param) return null;

    switch (this.props.element.param.type) {
      case 'DataPointTemplate':
        return (
          <DataPointTemplateSelector
            onChange={this.handleParameterRecordChange}
            optionsUrl={`/${this.props.organizationSlug}/data_point_template_options.json`}
            value={this.props.element.parameter_record}
          />
        );
      case 'Action':
        return (
          <ActionTemplateSelector
            optionsUrl={`/dataview_sections/${this.props.item.dataview_section_id}/action_template_options.json`}
            onChange={this.handleParameterRecordChange}
            value={this.props.element.parameter_record}
          />
        );
      case 'Todo':
        return (
          <TodoTemplateSelector
            optionsUrl={`/dataview_sections/${this.props.item.dataview_section_id}/todo_template_options.json`}
            onChange={this.handleParameterRecordChange}
            value={this.props.element.parameter_record}
          />
        );
      case 'Tag':
        return (
          <TagSelectorDropdown
            optionsUrl={`/dataview_sections/${this.props.item.dataview_section_id}/tag_options.json`}
            onChange={this.handleParameterRecordChange}
            value={this.props.element.parameter_record}
          />
        );
      case 'TagGroup':
        return (
          <TagGroupSelector
            optionsUrl={`/${this.props.organizationSlug}/tag_groups.json`}
            onChange={this.handleParameterRecordChange}
            value={this.props.element.parameter_record}
          />
        );
      case 'WorkflowTemplate':
        return (
          <WorkflowTemplateSelector
            optionsUrl={`/dataview_sections/${this.props.item.dataview_section_id}/workflow_template_options.json`}
            value={this.props.element.parameter_record}
            onChange={this.handleParameterRecordChange}
          />
        );
      case 'Role':
        return (
          <RoleTemplateSelector
            optionsUrl={`/dataview_sections/${this.props.item.dataview_section_id}/role_template_options.json`}
            onChange={this.handleParameterRecordChange}
            value={this.props.element.parameter_record}
          />
        );
      case 'ProjectRole':
        return (
          <ProjectRoleSelector
            optionsUrl={`/${this.props.organizationSlug}/project_roles.json`}
            onChange={this.handleParameterRecordChange}
            value={this.props.element.parameter_record}
          />
        );
      case 'CraftAppointmentType':
        return (
          <InstallationAppointmentSelect
            organizationSlug={this.props.organizationSlug}
            craftAppointmentType={this.props.element.parameter_record}
            onChange={this.handleParameterRecordChange}
          />
        );
      default:
        return null;
    }
  }

  render() {
    return (
      <div className="mb-2">
        <div className="col-sm-6">
          <ColumnTypeSelector
            value={this.props.element.name}
            onChange={this.props.onChange}
            viewerClassName={this.props.element.viewer_class_name}
          />
        </div>
        <div className="col-sm-6">
          {this.renderParamSelector()}
        </div>
      </div>
    );
  }
}

DataviewItemElement.propTypes = {
  element: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DataviewItemElement;

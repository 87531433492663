import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ContactTypeSelector from "./ContactTypeSelector";
import ContactPersonForm from "./ContactPersonForm";
import ContactCompanyForm from "./ContactCompanyForm";
import ContactSpousesForm from "./ContactSpousesForm";

class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props
    }
  }

  handleContactChange = (name, event) => {
    const value = event.target.value;
    this.setState(prevState => ({
      contact: {
        ...prevState.contact, [name]: value
      }
    }));
  }

  render() {
    return (<div>
      <div className="row mb-3">
        <div className="col-sm-3">
          <h5 className="light form-label">Art des Kontakts</h5>
        </div>
        <ContactTypeSelector
          contactType={this.state.contact.contact_type}
          onChange={this.handleContactChange}
          objectName={this.props.objectName}
        />
      </div>
      <div className="row mb-3">
        <div className="col-sm-3">
          <h5 className="light form-label">Name</h5>
        </div>
        <div className="col-sm-9 name-form-col">
          {(this.state.contact.contact_type === 'person') &&
            <ContactPersonForm
              contact={this.state.contact}
              onChange={this.handleContactChange}
              objectName={this.props.objectName}
            />
          }
          {this.state.contact.contact_type === 'company' &&
            <ContactCompanyForm
              contact={this.state.contact}
              onChange={this.handleContactChange}
              objectName={this.props.objectName}
            />
          }
          {this.state.contact.contact_type === 'spouses' &&
            <ContactSpousesForm
              contact={this.state.contact}
              onChange={this.handleContactChange}
              objectName={this.props.objectName}
            />
          }
        </div>
      </div>
    </div>)
  }
}

ContactForm.propTypes = {
  contact: PropTypes.object.isRequired,
  objectName: PropTypes.string,
};

ContactForm.defaultProps = {
  objectName: "contact"
};

export default ContactForm;
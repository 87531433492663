import React from 'react';
import PropTypes from 'prop-types';

import DataPointTemplateSelector from '../../shared/DataPointTemplateSelector';

class DeleteDataPointEffectForm extends React.Component {
  handleDataPointTemplateChange = (newDataPointTemplate) => {
    console.log(newDataPointTemplate);

    this.props.onChange(Object.assign({}, this.props.record, {
      // options: Object.assign({}, this.props.record.options, {
      //   data_point_template_id: newDataPointTemplate.id,
      //   // field: newField && newField.attribute,
      //   // content: newField && newField.type,
      // }),
      data_point_template_id: newDataPointTemplate.id,
      data_point_template: newDataPointTemplate,
    }));
  }

  render() {
    return (
      <React.Fragment>
        <div className="mb-3">
          <label className="form-label">Datenpunkt</label>
          <DataPointTemplateSelector
            value={this.props.record.data_point_template}
            onChange={this.handleDataPointTemplateChange}
            optionsUrl={`/${this.props.organizationSlug}/data_point_template_options.json`}
          />
        </div>
      </React.Fragment>
    );
  }
}

DeleteDataPointEffectForm.propTypes = {
  record: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};


export default DeleteDataPointEffectForm;

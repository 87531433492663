import React from 'react';
import PropTypes from 'prop-types';

import HiddenInputWrapper from '../shared/HiddenInputWrapper';
import GroupMultiSelector from './GroupMultiSelector';

const GroupMultiSelect = (props) => {
  const { value, name, ...rest } = props;

  return (
    <HiddenInputWrapper
      {...rest}
      comp={GroupMultiSelector}
      name={name}
      value={value}
    />
  );
};

GroupMultiSelect.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
};

export default GroupMultiSelect;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../shared/Icon';
import Avatar from '../shared/Avatar';
import TimeAgo from '../shared/TimeAgo';
import MembershipItem from './MembershipItem';
import ConfirmModal from '../shared/ConfirmModal';

const Item = (props) => {
  return (
    <div style={{ display: 'flex', padding: '3px 0' }}>
      {props.icon ? (
        <div style={{ width: 24, textAlign: 'center' }}>
          <Icon name={props.icon} />
        </div>
      ) : null}
      <div style={{ flex: 1, flexGrow: 1 }}>
        {props.children}
      </div>
    </div>
  );
};

Item.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.node,
};

Item.defaultProps = {
  icon: null,
  children: null,
};

class MembershipDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
    };
  }

  handleDeleteClick = () => {
    this.props.onDelete(this.props.membership);
  };

  handleMakeManagerClick = (e) => {
    e.preventDefault();
    this.props.onUpdate(Object.assign({}, this.props.membership, {
      manager: true,
    }));

    this.setState({ saving: true });
  };

  handleRemoveManagerClick = (e) => {
    e.preventDefault();
    this.props.onUpdate(Object.assign({}, this.props.membership, {
      manager: false,
    }));

    this.setState({ saving: true });
  };

  infoItems() {
    const items = [];

    if (this.props.membership.installation_companies
      && this.props.membership.installation_companies.length) {
      items.push(
        <MembershipItem icon="wrench" key="installation-company">
          {this.props.membership.installation_companies.map((company) => {
            return (
              <span key={company.id}>
                <a target="_blank" href={`/installation_companies/${company.id}`}>
                  {company.name}
                </a><br />
              </span>
            );
          })}
        </MembershipItem>,
      );
    }

    if (this.props.membership.responsible_for_active_todo) {
      if (this.props.membership.due && Date.parse(this.props.membership.due < new Date())) {
        items.push(
          <MembershipItem icon="check" key="reponsible">
            Verantwortlich für überfällige Aufgabe
          </MembershipItem>,
        );
      } else {
        items.push(
          <MembershipItem icon="check" key="reponsible">
            Verantwortlich für aktive Aufgabe
          </MembershipItem>,
        );
      }
    }

    if (this.props.membership.last_activity_at) {
      items.push(
        <MembershipItem icon="rss" key="activity">
          Letzte Aktivität <TimeAgo datetime={this.props.membership.last_activity_at} locale="de" />
        </MembershipItem>,
      );
    }

    if (this.props.membership.manager) {
      items.push(
        <MembershipItem icon="user-tie" key="manager">
          Projektmanager
          {this.props.editable ? (
            <span style={{ marginLeft: 8 }}>
              <a href="" onClick={this.handleRemoveManagerClick}>entfernen</a>
            </span>
          ) : null}
        </MembershipItem>,
      );
    }

    return items;
  }

  actionItems() {
    const items = [];

    if (!this.props.membership.manager && this.props.editable) {
      items.push(
        <MembershipItem icon="user-tie" key="manager">
          <a href="" onClick={this.handleMakeManagerClick} disabled={this.state.saving}>
            Zum Manager ernennen
          </a>
        </MembershipItem>,
      );
    }

    if (this.props.editable && !this.props.membership.responsible_for_active_todo) {
      items.push(
        <MembershipItem icon="trash" key="trash">
          <ConfirmModal
            target={onClick => (
              <a href="" onClick={onClick} disabled={this.state.saving}>
                Vom Projekt entfernen
              </a>
            )}
            title="Benutzer entfernen"
            cancelText="abbrechen"
            confirmText="entfernen"
            confirmStyle="danger"
            onConfirmed={this.handleDeleteClick}>
            Bist du sichern, dass du {this.props.membership.user.fullname} aus diesem Projekt entfernen möchtest?
          </ConfirmModal>
        </MembershipItem>,
      );
    } else if (this.props.membership.responsible_for_active_todo) {
      items.push(
        <MembershipItem icon="minus-circle" key="cannot-remove">
          Benutzer kann nicht entfernt werden, da noch Aufgaben offen sind.
        </MembershipItem>,
      );
    }

    return items;
  }

  renderInfo() {
    const items = this.infoItems();

    if (items.length) {
      return (
        <div className="project-membership-section">
          {items}
        </div>
      );
    }

    return null;
  }

  renderActions() {
    const items = this.actionItems();

    if (items.length) {
      return (
        <div className="project-membership-section">
          {items}
        </div>
      );
    }

    return null;
  }

  render() {
    return (
      <div className="project-membership-details">
        <div className="project-membership-details-head">
          <div>
            <a href={`/users/${this.props.membership.user.id}`}>
              <Avatar account={this.props.membership.user} size="medium" />
            </a>
          </div>
          <div>
            <div>
              <a href={`/users/${this.props.membership.user.id}`}>
                {this.props.membership.user.fullname}
              </a>
            </div>
            <div className="text-muted">
              {this.props.membership.user.bio}
            </div>
          </div>
        </div>

        {this.renderInfo()}
        {this.renderActions()}
      </div>
    );
  }
}

MembershipDetails.propTypes = {
  membership: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
};

export default MembershipDetails;

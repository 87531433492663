import React from 'react';
import PropTypes from 'prop-types';

import { pluralizeWithCount } from '../../helpers/helpers';
import Icon from '../shared/Icon';
import Tooltip from '../shared/Tooltip';

const TodoCommentsBadge = ({ todo, style }) => {
  const text = pluralizeWithCount(todo.comments_count, 'Kommentar', 'Kommentare');

  if (todo.comments_count) {
    return (
      <Tooltip title={text} placement="top">
        <div className="todo-badge todo-badge-default" style={style}>
          <Icon name="comments" /> {todo.comments_count}
        </div>
      </Tooltip>
    );
  }

  return null;
};


TodoCommentsBadge.propTypes = {
  todo: PropTypes.object.isRequired,
  style: PropTypes.object,
};

export default TodoCommentsBadge;

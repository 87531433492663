import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../shared/Icon';
import UserSelector from '../../Users/UserSelector';

class AddWorkflowEffectForm extends React.Component {
  rolesToBeAssigned = () => {
    if (this.props.effect.options && this.props.effect.options.role_template_ids) {
      return this.props.effect.workflow_template.roles.filter(r => (
        this.props.effect.options.role_template_ids.includes(r.id)
      ));
    }

    return this.props.effect.workflow_template.roles;
  }

  handleChange = (role, user) => {
    const roleAssignments = (this.props.value &&
      this.props.value.role_assignments_attributes
      && this.props.value.role_assignments_attributes) || [];

    let newRoleAssignements;

    if (roleAssignments.find(r => r.role_id === role.id)) {
      newRoleAssignements = roleAssignments.map((r) => {
        return (r.role_id !== role.id) ? r : Object.assign({}, r, {
          user_id: user && user.id,
        });
      });
    } else {
      newRoleAssignements = [...roleAssignments, {
        role_id: role.id,
        user_id: user && user.id,
      }];
    }

    const newParams = Object.assign({}, this.props.value, {
      role_assignments_attributes: newRoleAssignements,
    });

    this.props.onChange(newParams);
  }

  renderRoleInputs() {
    return this.rolesToBeAssigned().map((role) => {
      return (
        <div key={role.id} style={{ marginBottom: 8 }}>
          <div style={{ marginBottom: 4 }}>
            <Icon name="circle" color={role.color} /> Rolle <strong>{role.name}</strong> setzen:
          </div>
          <UserSelector
            optionsUrl={`/${this.props.organizationSlug}/users.json`}
            onChange={v => this.handleChange(role, v)}
          />
        </div>
      );
    });
  }

  render() {
    return (
      <div className="fire-effect-item">
        <div className="fire-effect-icon">
          <Icon name="random" />
        </div>
        <div className="fire-effect-content">
          <div className="fire-effect-title">
            Workflow <strong>{this.props.effect.workflow_template.title}</strong> wird hinzugefügt
          </div>
          <div className="fire-effect-inputs">
            <div className="row">
              <div className="col-sm-8">
                {this.renderRoleInputs()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AddWorkflowEffectForm.propTypes = {
  value: PropTypes.object,
  effect: PropTypes.object.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AddWorkflowEffectForm;

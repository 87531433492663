import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextArea from '../shared/TextArea';
import Icon from '../shared/Icon';

import { postAsync } from '../../helpers/rails_helper';

class TodoPreviewCommentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newComment: '',
    };
  }

  getSendButtonStyle = () => {
    const style = {};

    if (!this.state.newComment.trim()) {
      style.color = 'var(--bs-tertiary-color)';
      style.pointerEvents = 'none';
    }

    return style;
  };

  saveComment = () => {
    this.setState({ saving: true });

    postAsync('/comments', {
      comment: {
        commentable_type: 'Todo',
        commentable_id: this.props.todo.id,
        body: this.state.newComment.trim(),
      },
    }).then(({ comment }) => {
      this.setState({ saving: false, newComment: '' });
      this.props.onNewCommentSaved(comment);
      this.props.onFinish();
    });
  };

  handleSendClick = (e) => {
    e.preventDefault();
    this.saveComment();
  };

  handleKeyDown = (e) => {
    // cmd + enter
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      this.saveComment();
    }
  };

  handleChange = (e) => {
    this.setState({
      newComment: e.target.value,
    });
  };

  renderSendButton() {
    if (this.state.saving) return null;

    return (
      <a href="" onClick={this.handleSendClick} style={this.getSendButtonStyle()}>
        <Icon name="arrow-alt-circle-right" />
      </a>
    );
  }

  renderSavingIndicator() {
    if (!this.state.saving) return null;

    return (
      <Icon name="circle-notch" spin color="var(--bs-tertiary-color)" />
    );
  }

  render() {
    if (!this.props.visible) return null;

    return (
      <div style={{ display: 'flex', marginTop: 4 }}>
        <div style={{ flexGrow: 1 }}>
          <TextArea
            disabled={this.state.saving}
            autoSize
            placeholder="Kommentieren..."
            onFocus={this.props.onEdit}
            className="form-control"
            rows="1"
            style={{ maxHeight: 100, resize: 'none' }}
            value={this.state.newComment}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
          />
        </div>
        <div style={{ fontSize: 24, marginLeft: 6, alignSelf: 'flex-end', marginBottom: 6 }}>
          {this.renderSendButton()}
          {this.renderSavingIndicator()}
        </div>
      </div>
    );
  }
}

TodoPreviewCommentForm.propTypes = {
  todo: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  onNewCommentSaved: PropTypes.func.isRequired,
};

export default TodoPreviewCommentForm;

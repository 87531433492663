import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Select, { components } from 'react-select';

import { debounce } from 'lodash';

import { smallStyles, defaultStyles } from '../../styles/select_styles';

import Icon from '../shared/Icon';

import ProductAvailabilityIcon from '../Products/ProductAvailabilityIcon';

import { searchText } from '../../helpers/helpers';

const getOptions = (organizationSlug, hideInactive, productType, searchTerm, crafts, callback) => {
  const url = new URL(`/${organizationSlug}/products.json?q=${encodeURIComponent(searchTerm)}`, window.location.origin);

  const params = new URLSearchParams({
    q: searchTerm,
    a: hideInactive ? 't' : undefined,
  });

  if (productType) {
    params.append('product_type', productType);
  }

  if (crafts && crafts.length > 0) {
    crafts.forEach(craft => params.append('craft[]', craft));
  }

  url.search = params.toString();

  return fetch(url, {
    credentials: 'include',
  }).then((response) => {
    return response.json();
  }).then((options) => {
    callback(options.products);
  });
};

const getOptionsLazy = debounce(getOptions, 200);

const filterOption = (option, searchTerm) => {
  const str = `${option.data.name} ${option.data.model} ${option.data.manufacturer_name} ${option.data.article_number}`;

  return searchText(str, searchTerm);
};

const Option = (props) => {
  const subStyle = {
    color: props.isFocused ? '#fff' : '#777',
    fontSize: '0.9em',
  };

  const iconClass = props.data.active ? 'active' : '';

  return (
    <components.Option {...props}>
      <Icon name="circle" className={`product-circle ${iconClass}`} style={{ marginRight: 2 }} />
      <ProductAvailabilityIcon status={props.data.availability_status} />{' '}
      <span title={`${props.data.name} ${props.data.model}`}>{props.data.name} {props.data.model}</span><br />
      <span style={subStyle}>
        <span className="text-monospace">{props.data.article_number}</span><br />
        {props.data.manufacturer_name}, Kataloge: {props.data.catalogs.map(c => c.name).join(', ')}, Land: {props.data.country_codes.join(', ')}
      </span>
    </components.Option>
  );
};

Option.propTypes = {
  data: PropTypes.object,
  isFocused: PropTypes.bool,
};

const SingleValue = props => (
  <components.SingleValue {...props}>
    <span title={`${props.data.manufacturer_name} ${props.data.name} ${props.data.model}`}>
      {props.data.manufacturer_name} {props.data.name} {props.data.model}
    </span>
  </components.SingleValue>
);

SingleValue.propTypes = {
  data: PropTypes.object,
};

class ProductSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      options: [],
      value: null,
      hasError: true,
    };

    this.loadOptions = this.loadOptions.bind(this);
    this.loadInitialOption = this.loadInitialOption.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.loadInitialOption();

    const baseStyle = (this.props.size === 'small') ? smallStyles : defaultStyles;

    this.styles = {
      ...baseStyle,
      control: (provided, state) => ({
        ...baseStyle.control(provided, state),
        borderColor: (!this.state.value && !this.state.isLoading) ? '#a94442' : baseStyle.control(provided, state).borderColor,
      }),

      option: (provided, state) => ({
        ...baseStyle.option(provided, state),
      }),
    };
  }

  loadInitialOption() {
    if (this.props.value) {
      const url = `/products/${this.props.value}.json`;

      return fetch(url, {
        credentials: 'include',
      }).then((response) => {
        return response.json();
      }).then((result) => {
        this.setState({ value: result.product, isLoading: false });
      });
    }

    return null;
  }

  loadOptions(searchTerm = '') {
    this.setState({ isLoading: true });

    getOptionsLazy(
      this.props.organizationSlug,
      this.props.hideInactive,
      this.props.productType,
      searchTerm,
      this.props.crafts,
      (options) => {
        this.setState({
          options, isLoading: false,
        });
      });
  }

  handleFocus() {
    this.loadOptions();
  }

  handleInputChange(searchTerm, { action }) {
    // Löst mehrfach aus (mit verschiedenen actions)
    // Daher optionen nur neuladen, wenn sich 'input-change' gefeuert wurde
    if (action === 'input-change') {
      this.loadOptions(searchTerm);
    }
  }

  handleChange(value) {
    this.setState({ value });

    if (this.props.onChange) {
      if (value) {
        this.props.onChange(value.id);
      } else {
        this.props.onChange(null);
      }
    }
  }

  render() {
    return (
      <Select
        components={{
          Option,
          SingleValue,
        }}
        isLoading={this.state.isLoading}
        onFocus={this.handleFocus}
        isClearable
        onInputChange={this.handleInputChange}
        backspaceRemovesValue={false}
        blurInputOnSelect
        options={this.state.options}
        menuPlacement="auto"
        onChange={this.handleChange}
        filterOption={filterOption}
        styles={this.styles}
        value={this.state.value}
        defaultOptions
        placeholder={this.props.placeholder}
        loadingPlaceholder="Laden..."
        noOptionsMessage={() => 'Keine Ergebnisse'}
        loadingMessage={() => 'Laden...'}
      />
    );
  }
}

ProductSelector.propTypes = {
  onChange: PropTypes.func,
  organizationSlug: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.number,
  hideInactive: PropTypes.bool,
  productType: PropTypes.string,
  size: PropTypes.string,
  crafts: PropTypes.arrayOf(PropTypes.number),
};

ProductSelector.defaultProps = {
  placeholder: 'Produkt auswählen',
  hideInactive: false,
};

export default ProductSelector;

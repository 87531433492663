/* global $ */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';

const SwitchLayoutButton = ({ layout, onClick }) => (
  <button
    className="new-folder-button-desktop d-none d-sm-inline"
    type="button"
    title="Layout wechseln"
    onClick={onClick}
  >
    <Icon fixedWidth name={layout === 'list' ? 'grid' : 'list'} />
  </button>
);

SwitchLayoutButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  layout: PropTypes.string.isRequired,
};

export default SwitchLayoutButton;

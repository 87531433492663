import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../shared/Icon';
import { postAsync, deleteAsync } from '../../helpers/rails_helper';
import { subscribe, unsubscribe } from '../../helpers/actioncable_helper';

import Tooltip from '../shared/Tooltip';

class Star extends Component {
  constructor(props) {
    super(props);
    this.state = { starId: this.props.initialStarId };
  }

  componentDidMount() {
    this.channel = subscribe({
      channel: 'ProjectStarChangesChannel',
      project_id: this.props.projectId,
    }, (data) => {
      if (data.action === 'new') {
        this.setState({ starId: data.star_id });
      } else if (data.action === 'delete') {
        this.setState({ starId: null });
      }
    });
  }
  componentWillUnmount() {
    unsubscribe(this.channel);
  }

  handleClick = (e) => {
    e.preventDefault();

    if (this.state.starId) {
      deleteAsync(`/stars/${this.state.starId}`).then(() => {
        this.setState({ starId: null });
      });
    } else {
      postAsync(`/projects/${this.props.projectId}/stars`).then((newStar) => {
        this.setState({ starId: newStar.id });
      });
    }
  }

  render() {
    const title = this.state.starId ? 'Nicht mehr favorisieren' : 'Favorisiere das Projekt für schnellen Zugriff';

    return (
      <Tooltip placement="top-end" title={title}>
        <a href=""
          onClick={this.handleClick}
          className={this.state.starId ? 'starred' : ''}>
          <Icon name="star" light={!this.state.starId} />
        </a>
      </Tooltip>
    );
  }
}

Star.propTypes = {
  initialStarId: PropTypes.number,
  projectId: PropTypes.number,
};

export default Star;

/* eslint-disable quote-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Icon from '../shared/Icon';
import Dropdown from '../shared/Dropdown';

import { dropdownStyles } from '../../styles/select_styles';

const styles = dropdownStyles;

class SelectorDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropDownVisible: false,
      options: [],
      inputValue: '',
    };
  }

  loadOptions = () => {
    this.setState({ isLoading: true });

    return fetch(this.props.optionsUrl, {
      credentials: 'include',
    }).then((response) => {
      return response.json();
    }).then((result) => {
      this.setState({
        isLoading: false,
        options: result[this.props.entitiesName],
      });
    });
  };

  handleFocus = () => {
    this.loadOptions();
  };

  handleChange = (tag) => {
    if (this.props.onAdd) {
      this.props.onAdd(tag);
    }
  };

  toggleDropDown = (e) => {
    e.preventDefault();
    this.setState({ dropDownVisible: !this.state.dropDownVisible });
  };

  handleBlur = () => {
    this.setState({ dropDownVisible: false });
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      this.toggleDropDown(e);
    }
  };

  renderAddButton = (ref) => {
    return (
      <button ref={ref} className="btn btn-light btn-label" onClick={this.toggleDropDown}>
        <Icon name="plus" /> {this.props.buttonText}
      </button>
    );
  };

  renderTarget = (ref) => {
    if (this.props.target) {
      return this.props.target(ref, this);
    }

    return this.renderAddButton(ref);
  };

  render() {
    return (
      <Dropdown
        isOpen={this.state.dropDownVisible}
        onClose={this.toggleDropDown}
        target={this.renderTarget}
        placement={this.props.placement}
      >
        <Select
          autoFocus
          components={{
            Option: this.props.optionComponent,
            DropdownIndicator: null,
            IndicatorSeparator: null,
          }}
          openMenuOnFocus
          clearable={false}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
          backspaceRemovesValue={false}
          blurInputOnSelect
          menuPlacement="auto"
          menuIsOpen
          onFocus={this.loadOptions}
          filterOption={this.props.filterOption}
          onChange={this.handleChange}
          handleInputChange={this.handleInputChange}
          options={this.state.options}
          defaultOptions
          styles={styles}
          placeholder={this.props.placeholder}
          loadingPlaceholder="Laden..."
          noOptionsMessage={() => 'Keine Ergebnisse'}
          loadingMessage={() => 'Laden...'}
        />
      </Dropdown>
    );
  }
}

SelectorDropdown.propTypes = {
  optionComponent: PropTypes.func.isRequired,
  filterOption: PropTypes.func.isRequired,
  onAdd: PropTypes.func,
  optionsUrl: PropTypes.string.isRequired,
  entitiesName: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  target: PropTypes.func,
  placement: PropTypes.string,
  placeholder: PropTypes.string,
};

SelectorDropdown.defaultProps = {
  buttonText: 'Auswählen',
  placeholder: 'Option auswählen',
};

export default SelectorDropdown;

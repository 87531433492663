import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../shared/Icon';

const NavButtons = ({ onNext, onPrevious, currentPosition, totalCount }) => {
  if (!onNext || !onPrevious) return null;

  const handleNextClick = (e) => {
    e.preventDefault();
    onNext();
  };

  const handlePreviousClick = (e) => {
    e.preventDefault();
    onPrevious();
  };

  const previousAvailable = currentPosition > 0;
  const nextAvailable = currentPosition < totalCount - 1;

  return (
    <div className="document-navigation-container">
      <div className="document-position-container">
        {currentPosition + 1} von {totalCount}
      </div>      
      <div className="document-button-container">
        <button disabled={!previousAvailable} className="btn btn-secondary btn-lg rounded-pill" onClick={handlePreviousClick}>
          <Icon name="chevron-left" /> zurück
        </button>
        <button disabled={!nextAvailable} className="btn btn-secondary btn-lg rounded-pill" onClick={handleNextClick}>
          weiter <Icon name="chevron-right" />
        </button>
      </div>
    </div>
  );
};

NavButtons.propTypes = {
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  currentPosition: PropTypes.number,
  totalCount: PropTypes.number,
};

export default NavButtons;

import React from 'react';
import PropTypes from 'prop-types';
import FilterComponent from '../shared/FilterComponent';
import InstallationCompanyOption, { filterCompanyOption } from './InstallationCompanyOption';

const InstallationCompanyFilter = (props) => {
  return (<FilterComponent
    {...props}
    iconName="wrench"
    entitiesName="installation_companies"
    optionComponent={InstallationCompanyOption}
    filterOption={filterCompanyOption}
    placeholder="Fachpartner auswählen"
  />);
};

InstallationCompanyFilter.propTypes = {
  optionsUrl: PropTypes.string.isRequired,
  text: PropTypes.string,
  multi: PropTypes.bool,
  queryParam: PropTypes.string,
  dropdownPlacement: PropTypes.string,
};

InstallationCompanyFilter.defaultProps = {
  text: '',
  multi: false,
  queryParam: 'c',
};

export default InstallationCompanyFilter;

import React from 'react';
import NoDataPointDisplay from './NoDataPointDisplay';

export default ({ value }) => {
  if (value && value.trim()) {
    // return value.replace(/(?:\r\n|\r|\n)/g, '<br>');

    return value.split('\n').map((item, key) => {
      return (
        <span key={key}>
          {item}<br />
        </span>
      );
    });
  }

  return <NoDataPointDisplay />;
};

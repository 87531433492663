import React, { Component } from 'react';
import UploaderProvider from '../Uploader/UploaderProvider';
import NewCommentForm from './NewCommentForm';

class NewComment extends Component {
  render() {
    return (
      <UploaderProvider>
        <NewCommentForm {...this.props} />
      </UploaderProvider>
    );
  }
}

export default NewComment;

import React from 'react';
import PropTypes from 'prop-types';

const FullnameWithAvatar = ({ model, size }) => {
  return (
    <span className="fullname_with_avatar">
      <span className={`avatar-link-with-fullname avatar-wrapper avatar-wrapper-${size}`}>
        <img className={`avatar avatar-${size}`} alt={model.display_name} src={model.avatar.thumb.url} />
      </span>
      {model.display_name}
    </span>
  );
};

FullnameWithAvatar.propTypes = {
  model: PropTypes.object.isRequired,
  size: PropTypes.string,
};

FullnameWithAvatar.defaultProps = {
  size: 'mini',
};

export default FullnameWithAvatar;

import React from 'react';
import PropTypes from 'prop-types';

import NoDataPointDisplay from './NoDataPointDisplay';

import Icon from '../../shared/Icon';

import FullnameWithAvatar from '../../shared/FullNameWithAvatar';

const UserDataPointDisplay = ({ dataPoint }) => {
  if (!dataPoint || !dataPoint.user) return <NoDataPointDisplay />;

  return (
    <a href={`/users/${dataPoint.user.id}`}>
      <FullnameWithAvatar model={dataPoint.user} />
    </a>
  );
};

UserDataPointDisplay.propTypes = {
  dataPoint: PropTypes.object,
};

export default UserDataPointDisplay;

import React from 'react';
import NoDataPointDisplay from './NoDataPointDisplay';

import AttachmentPreviewer from '../../Attachments/AttachmentPreviewer';

export default ({ dataPoint }) => {
  if (!dataPoint?.value?.length) return <NoDataPointDisplay />;

  const renderAttachments = (openPreview) => (
    dataPoint.value.map((attachment) => (
      <button
        className="data-point-document"
        key={attachment.id}
        onClick={e => openPreview(attachment)}>
        <img
          className={attachment.document.has_preview ? '' : 'without-preview'}
          src={attachment.document.thumb}
          alt={attachment.document.name}
        />
      </button>
    ))
  );

  return (
    <div className="data-point-documents-container">
      <AttachmentPreviewer attachments={dataPoint.value}>
        {openPreview => renderAttachments(openPreview)}
      </AttachmentPreviewer>
    </div>
  );
};

import AutoNumeric from 'autonumeric';

const autoNumericOptionsEuro = {
  digitGroupSeparator: '.',
  decimalCharacter: ',',
  decimalCharacterAlternative: '.',
  currencySymbol: '',
  currencySymbolPlacement: AutoNumeric.options.currencySymbolPlacement.suffix,
  // Rundungsmethode A 'Round-Half-Up Asymmetric' funktioniert wie javascript Math.round()
  // 0,4 -> 0
  // 0,5 -> 1
  // -0,4 -> 0
  // -0,5 -> 0 (!)
  // -0,6 -> 1
  // Kann man hier ausprobieren: https://codepen.io/AnotherLinuxUser/pen/JyBGpz?editors=1010
  // Die vorher benutzte Methode U Round Up "Round-Away-From-Zero" rundet immer auf (bzw. negative Zahlen ab)
  roundingMethod: 'A',
  showWarnings: false,
  modifyValueOnWheel: false,
  emptyInputBehavior: 'null',
};

const autoNumericOptionsPercentageMargin = {
  digitGroupSeparator: '',
  decimalCharacter: ',',
  decimalCharacterAlternative: '.',
  currencySymbol: '',
  currencySymbolPlacement: AutoNumeric.options.currencySymbolPlacement.suffix,
  roundingMethod: 'A',
  minimumValue: 0,
  showWarnings: false,
  modifyValueOnWheel: false,
};

const autoNumericOptionsDuration = {
  digitGroupSeparator: '',
  decimalCharacter: ',',
  decimalCharacterAlternative: '.',
  currencySymbol: '',
  currencySymbolPlacement: AutoNumeric.options.currencySymbolPlacement.suffix,
  roundingMethod: 'A',
  minimumValue: 0,
  showWarnings: false,
  allowDecimalPadding: false,
  modifyValueOnWheel: false,
  decimalPlaces: 0,
};

const autoNumericOptionsInteger = {
  digitGroupSeparator: '.',
  decimalCharacter: ',',
  decimalCharacterAlternative: '.',
  currencySymbol: '',
  currencySymbolPlacement: AutoNumeric.options.currencySymbolPlacement.suffix,
  roundingMethod: 'A',
  minimumValue: 0,
  showWarnings: false,
  allowDecimalPadding: false,
  modifyValueOnWheel: false,
  decimalPlaces: 0,
  emptyInputBehavior: 'null',
};

const autoNumericOptionsDecimal = {
  digitGroupSeparator: '.',
  decimalCharacter: ',',
  decimalCharacterAlternative: '.',
  currencySymbol: '',
  currencySymbolPlacement: AutoNumeric.options.currencySymbolPlacement.suffix,
  roundingMethod: 'A',
  minimumValue: 0,
  showWarnings: false,
  allowDecimalPadding: false,
  modifyValueOnWheel: false,
  decimalPlaces: 2,
  emptyInputBehavior: 'null',
};

const autoNumericOptionsFloat = {
  digitGroupSeparator: '.',
  decimalCharacter: ',',
  decimalCharacterAlternative: '.',
  alwaysAllowDecimalCharacter: true,
  // roundingMethod: 'A',
  // minimumValue: 0,
  // showWarnings: false,
  allowDecimalPadding: false,
  // modifyValueOnWheel: false,
  decimalPlaces: 10,
  emptyInputBehavior: 'null',
};

export {
  autoNumericOptionsEuro,
  autoNumericOptionsPercentageMargin,
  autoNumericOptionsDuration,
  autoNumericOptionsInteger,
  autoNumericOptionsDecimal,
  autoNumericOptionsFloat,
};

import React from 'react';
import PropTypes from 'prop-types';
import ProjectStateSelect from './ProjectStateSelect';

class ProjectStateSelectAsync extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
    };
  }

  componentDidMount() {
    this.loadOptions();
  }

  loadOptions() {
    const url = `${this.props.options_url}?grouped=1`;

    return fetch(url, {
      credentials: 'include',
    }).then((response) => {
      return response.json();
    }).then((options) => {
      this.setState(() => ({
        options: options.project_states,
      }));
    });
  }

  render() {
    const { options_url, value, ...rest } = this.props;

    return (
      <ProjectStateSelect
        projectState={value}
        projectStateOptions={this.state.options}
        {...rest}
      />
    );
  }
}

ProjectStateSelectAsync.propTypes = {
  options_url: PropTypes.string.isRequired,
};

ProjectStateSelectAsync.defaultProps = {
  options_url: '/wegatech/project_states',
};


export default ProjectStateSelectAsync;

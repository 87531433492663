import React from 'react';

const WaveformIcon = () => (
  <div className="waveform-icon-container">
    <div className="waveform-icon">
      <div className="wave-bar" />
      <div className="wave-bar" />
      <div className="wave-bar" />
      <div className="wave-bar" />
    </div>
  </div>
);

export default WaveformIcon;

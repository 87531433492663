let observers = [];

const startObservers = () => {
  document.querySelectorAll('[data-spy-on]').forEach((element) => {
    if (element) {
      const intersectingClass = element.dataset.intersectingClass || 'intersecting';
      const notIntersectingClass = element.dataset.intersectingClass || 'not-intersecting';
      let threshold = 0.2;

      if (element.dataset.spyThreshold) {
        threshold = parseFloat(element.dataset.spyThreshold);
      }

      let rootElement = null; // Defaults to viewport

      if (element.dataset.rootElementSelector) {
        rootElement = document.querySelector(element.dataset.rootElementSelector);
      }

      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting === true) {
          element.classList.add(intersectingClass);
          element.classList.remove(notIntersectingClass);
        } else {
          element.classList.remove(intersectingClass);
          element.classList.add(notIntersectingClass);
        }
      }, {
        root: rootElement,
        threshold: [threshold],
      });

      const elementToSpyOn = document.querySelector(element.dataset.spyOn);

      if (elementToSpyOn) {
        observer.observe(elementToSpyOn);
        observers.push(observer);
      }
    }
  });
};

const stopObservers = () => {
  observers.forEach((observer) => {
    observer.disconnect();
  });

  observers = [];
};

document.addEventListener('turbo:load', startObservers);
document.addEventListener('turbo:visit', stopObservers);

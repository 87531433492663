/* eslint-disable quote-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Icon from '../shared/Icon';
import TagOption from './TagOption';
import Dropdown from '../shared/Dropdown';
import { dropdownStyles } from '../../styles/select_styles';

import { searchText } from '../../helpers/helpers';

const filterTags = (option, searchTerm) => {
  let title = `${option.data.title} ${option.data.desc}`;

  if (option.data.group) {
    title += option.data.group.title;
  }

  return searchText(title, searchTerm);
};

class TagSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropDownVisible: false,
    };
  }

  handleChange = (tag) => {
    if (this.props.onAdd) {
      this.props.onAdd(tag);
    }
  };

  toggleDropDown = (e) => {
    e.preventDefault();

    const visible = !this.state.dropDownVisible;

    if (this.props.onOpen) {
      this.props.onOpen();
    }

    this.setState({ dropDownVisible: visible });
  };

  handleBlur = () => {
    this.setState({ dropDownVisible: false });

    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      this.toggleDropDown(e);
    }
  };

  renderTarget = (ref, instance) => {
    if (this.props.target) {
      return this.props.target(ref, this);
    }

    return (
      <button ref={ref} className="btn btn-outline-secondary btn-label" onClick={this.toggleDropDown}>
        <Icon name="plus" /> {this.props.buttonText}
      </button>
    );
  };

  render() {
    return (
      <Dropdown
        ref={n => (this.dropdown = n)}
        isOpen={this.state.dropDownVisible}
        onClose={this.toggleDropDown}
        target={this.renderTarget}>
        <Select
          autoFocus
          components={{
            Option: TagOption,
            DropdownIndicator: null,
            IndicatorSeparator: null,
          }}
          openMenuOnFocus
          clearable={false}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
          backspaceRemovesValue={false}
          blurInputOnSelect
          menuPlacement="auto"
          menuIsOpen
          filterOption={filterTags}
          onChange={this.handleChange}
          options={this.props.options}
          defaultOptions
          styles={dropdownStyles}
          placeholder="Tag hinzufügen"
          noOptionsMessage={() => 'Keine Ergebnisse'}
        />
      </Dropdown>
    );
  }
}

TagSelector.propTypes = {
  onAdd: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  // excludedTagIds: PropTypes.array,
  options: PropTypes.array.isRequired,
  buttonText: PropTypes.string,
  target: PropTypes.func,
};

TagSelector.defaultProps = {
  buttonText: 'Tag',
};

export default TagSelector;

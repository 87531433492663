import React from 'react';
import PropTypes from 'prop-types';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import DataviewItem from './DataviewItem';
import DataviewItemModal from './DataviewItemModal';

import { reorder } from '../../helpers/helpers';
import { flashNotice } from '../../helpers/flash_helper';
import { updateAsync } from '../../helpers/rails_helper';

import Icon from '../shared/Icon';

const DataviewSectionDetails = (props) => {
  if (!props.section) return null;

  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) return false;

    const items = reorder(
      props.section.dataview_items,
      result.source.index,
      result.destination.index,
    );

    const newSection = Object.assign({}, props.section, {
      dataview_items: items,
    });

    props.onChange(newSection);

    return updateAsync(`/dataview_items/${result.draggableId}/sort.json`, {
      position: result.destination.index + 1,
    }, 'PATCH').then(() => {
      flashNotice('Reihenfolge aktualisiert.');
    });
  };

  const handleItemAdded = (data) => {
    const newSection = Object.assign({}, props.section, {
      dataview_items: [...props.section.dataview_items, data.dataview_item],
    });

    props.onChange(newSection);

    flashNotice('Element wurde gespeichert.');
  };

  const handleDeleted = (item) => {
    const newSection = Object.assign({}, props.section, {
      dataview_items: props.section.dataview_items.filter(i => (i.id !== item.id)),
    });

    props.onChange(newSection);

    flashNotice('Element wurde gelöscht.');
  };

  const handleUpdated = (item) => {
    const newSection = Object.assign({}, props.section, {
      dataview_items: props.section.dataview_items.map((i) => ((i.id !== item.id) ? i : item)),
    });

    props.onChange(newSection);

    flashNotice('Element wurde aktualisiert.');
  };

  const renderItems = () => {
    return props.section.dataview_items.map((item, i) => (
      <Draggable key={item.id} draggableId={item.id.toString()} index={i}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} key={item.id} {...provided.draggableProps}>
            <DataviewItem
              base_viewer_class_name={props.base_viewer_class_name}
              organizationSlug={props.organizationSlug}
              provided={provided}
              snapshot={snapshot}
              item={item}
              onDeleted={(deletedItem) => handleDeleted(deletedItem)}
              onUpdated={(updatedItem) => handleUpdated(updatedItem)}
            />
          </div>
        )}
      </Draggable>
    ));
  };

  return (
    <div>
      <h4>Elemente</h4>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              // style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              {renderItems()}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <DataviewItemModal
        visible
        title="Neues Element"
        organizationSlug={props.organizationSlug}
        onSaved={handleItemAdded}
        initialItem={{
          title: '',
          dataview_section_id: props.section.id,
          dataview_item_elements: [{
            viewer_class_name: props.base_viewer_class_name,
          }],
        }}>
        {onClick => (
          <button className="btn btn-link" onClick={onClick}>
            <Icon name="plus" fixedWidth />
            Element hinzufügen
          </button>
        )}
      </DataviewItemModal>
    </div>
  );
};

DataviewSectionDetails.propTypes = {
  section: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  base_viewer_class_name: PropTypes.string,
  organizationSlug: PropTypes.string.isRequired,
};

export default DataviewSectionDetails;

import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';

const priorities = [
  { value: 3, color: '#d9534f', title: 'hoch', title_long: 'Hohe Priorität' },
  { value: 2, color: '#ffcb17', title: 'mittel', title_long: 'Mittlere Priorität' },
  { value: 1, color: '#a2cc21', title: 'niedrig', title_long: 'Niedrige Priorität' },
];

const getPriority = (value) => {
  return priorities.find(p => p.value === value);
};

const TodoTemplatePriority = ({ todo }) => {
  const priority = getPriority(todo.priority);

  if (!priority) return null;

  return (
    <div className="workflow-template-todo-info">
      <Icon name="exclamation-circle" color={priority.color} /> {priority.title_long}
    </div>
  );
};

TodoTemplatePriority.propTypes = {
  todo: PropTypes.object.isRequired,
};

export default TodoTemplatePriority;

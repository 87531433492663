import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TimeAgo from '../shared/TimeAgo';

function DataPointHistory(props) {
  useEffect(() => {
    const handleClickOutsideHistory = (event) => {
      props.onClickOutside(event.target);
    };

    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        props.hideHistory();
      }
    };

    document.addEventListener('click', handleClickOutsideHistory);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('click', handleClickOutsideHistory);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const renderActivities = () => {
    if (!props.activitiesLoaded) {
      return <div className="p-2 text-muted">Historie wird geladen...</div>;
    }

    if (props.activities.length === 0) {
      return <div className="p-2">Bisher keine Historie</div>;
    }

    return (
      <table className="table datapoint-history text-start m-0">
        <tbody>
          {props.activities.map((activity) => (
            <tr key={activity.id}>
              <td>
                <div className="d-flex">
                  <span dangerouslySetInnerHTML={{ __html: activity.text }} />
                  <span className="text-muted text-nowrap ps-3" style={{ marginLeft: 'auto' }}>
                    <TimeAgo datetime={activity.datetime} />
                  </span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return renderActivities();
}

DataPointHistory.propTypes = {
  activities: PropTypes.array,
  activitiesLoaded: PropTypes.bool.isRequired,
  hideHistory: PropTypes.func.isRequired,
  onClickOutside: PropTypes.func.isRequired,
};

DataPointHistory.defaultProps = {
  activities: [],
};

export default DataPointHistory;

import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';

const ProductAvailabilityIcon = ({ status }) => {
  let availabilityIcon = 'exclamation-circle';

  if (status === 'unavailable') {
    availabilityIcon = 'times-question-circle';
  } else if (status === 'available') {
    availabilityIcon = 'check-circle';
  }

  let availabilityColor = '#dd9d49';
  if (status === 'unavailable') {
    availabilityColor = '#d9534f';
  } else if (status === 'available') {
    availabilityColor = '#a2cc21';
  }

  return (
    <Icon name={availabilityIcon} color={availabilityColor} />
  );
};

ProductAvailabilityIcon.propTypes = {
  status: PropTypes.string.isRequired,
};

export default ProductAvailabilityIcon;

import React from 'react';
import PropTypes from 'prop-types';

import OperatorSelector from '../../OperatorSelector';
import WorkflowTemplateSelector from '../../../WorkflowTemplates/WorkflowTemplateSelector';

import BaseCondition from '../BaseCondition';

class TemplateCondition extends React.Component {
  handleChangeOperator = ({ value }) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      operator: value,
    }));
  };

  handleWorkflowTemplateChange = (workflow_template) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      integer_operand_1: workflow_template.id,
      workflow_template,
    }));
  };

  render() {
    return (
      <BaseCondition {...this.props}>
        <div style={{ flexBasis: 200, marginLeft: 8 }}>
          <OperatorSelector
            operators={this.props.condition.filter_condition_type.operators}
            onChange={this.handleChangeOperator}
            value={this.props.condition.operator}
          />
        </div>
        <div style={{ flexBasis: 200, marginLeft: 8 }}>
          <WorkflowTemplateSelector
            value={this.props.condition.workflow_template}
            optionsUrl={`/${this.props.organizationSlug}/workflow_templates.json`}
            onChange={this.handleWorkflowTemplateChange}
            size="small"
            isClearable={false}
          />
        </div>
      </BaseCondition>
    );
  }
}

TemplateCondition.propTypes = {
  condition: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default TemplateCondition;

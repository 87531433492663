/* global document, CustomEvent */
import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '../shared/Icon';
import TextArea from '../shared/TextArea';
import { postAsync } from '../../helpers/rails_helper';

import ComposeDropzone from './ComposeDropzone';
import ComposeAttachments from './ComposeAttachments';

import Tooltip from '../shared/Tooltip';

import MiniFileBrowser from '../FileBrowser/MiniFileBrowser';

class NewCommentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      attachments: [],
      fileBrowserOpen: false,
    };
  }

  getDropzoneRef = (node) => {
    this.dropzoneRef = node;
  }

  handleUploadsFinished = (uploads) => {
    // Nur Uploads hinzufügen, die noch nicht in der Liste sind
    this.setState((prevState) => ({
      attachments: [
        ...prevState.attachments,
        ...uploads.filter(u => !prevState.attachments.find(u2 => u2.document_id === u.document_id)),
      ],
    }));
  }

  handleOpenFileBrowser = (e) => {
    e.preventDefault();
    this.setState({ fileBrowserOpen: true });
  }

  handleCloseFileBrowser = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ fileBrowserOpen: false });
  }

  handleTextChange = (e) => {
    e.preventDefault();

    this.setState({
      value: e.target.value,
      saving: false,
    });
  }

  handleKeyDown = (e) => {
    // cmd + enter
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      this.handelSave(e);
    }
  }

  handleChooseFileClick = (e) => {
    e.preventDefault();
    this.dropzoneRef.open();
  }

  handleSelectFiles = (attachments) => {
    // Bereits hinzugefügte Dateien entfernen (nicht erneut hinzufügen)
    this.setState((prevState) => ({
      fileBrowserOpen: false,
      attachments: [
        ...prevState.attachments,
        ...attachments.filter(f => !prevState.attachments.find(f2 => f2.document_id === f.document_id)),
      ],
    }));
  }

  handelSave = (e) => {
    e.preventDefault();
    this.props.onSend();

    this.setState({ saving: true });

    postAsync('/comments/', {
      comment: {
        commentable_type: this.props.commentable_type,
        commentable_id: this.props.commentable_id,
        body: this.state.value,
        attachments_attributes: this.state.attachments.map((attachment) => {
          return {
            document_sgid: attachment.document.sgid,
          };
        }),
      },
    }).then((result) => {
      const event = new CustomEvent('todo:activities:new', {
        detail: {
          todoId: this.props.commentable_id,
          activities: result.activities,
        },
      });

      document.dispatchEvent(event);

      this.setState({
        saving: false,
        value: '',
        attachments: [],
      });

      this.props.onSent();
    }).catch((error) => {
      console.log(error);
      alert('Fehler beim Speichern des Kommentars. Bitte versuche es noch einmal');
      this.setState({
        saving: false,
      });
      this.props.onError();
    });
  }

  handleRemoveAttachment = (attachment) => {
    this.setState((prevState) => ({
      attachments: prevState.attachments.filter(a => a.document_id !== attachment.document_id),
    }));
  }

  render() {
    const btnDisabled = (this.state.value.trim() || this.state.attachments.length) ? '' : 'disabled';

    return (
      <ComposeDropzone onUploadsFinished={this.handleUploadsFinished} getDropzoneRef={this.getDropzoneRef}>
        <div>
          <div style={{ marginBottom: -5 }}>
            <TextArea
              autoSize
              autoFocus={this.props.shouldAutoFocus}
              onFocus={() => this.props.onFocus()}
              onBlur={() => this.props.onBlur()}
              onKeyDown={this.handleKeyDown}
              value={this.state.value}
              rows={3}
              placeholder="Schreibe einen Kommentar..."
              disabled={this.state.saving}
              onChange={this.handleTextChange} />
          </div>
          <ComposeAttachments
            onRemove={this.handleRemoveAttachment}
            attachments={this.state.attachments}
            style={{ borderTop: '1px dashed #ccc', padding: 4 }}
          />
          <div className="compose-footer">
            <div className="compose-footer-info">
              <div style={{ flexGrow: 1 }}>
                <span className="d-none d-lg-inline">Dateien hierher ziehen oder </span>
                <a href="#" onClick={this.handleChooseFileClick}>
                  <span className="d-none d-lg-inline">hochladen</span>
                  <span className="d-lg-none">Dateien hochladen</span>
                </a>
              </div>
              <div className="d-flex">
                <Tooltip title="Dateien hochladen" placement="top">
                  <a href="" className="compose-footer-icon-button" onClick={this.handleChooseFileClick}>
                    <Icon name="cloud-upload" />
                  </a>
                </Tooltip>
                <Tooltip title="Dokumente einfügen" placement="top">
                  <a href="" className="compose-footer-icon-button" onClick={this.handleOpenFileBrowser}>
                    <Icon name="folder" />
                  </a>
                </Tooltip>
                <MiniFileBrowser
                  project_id={this.props.project_id}
                  visible={this.state.fileBrowserOpen}
                  onClose={this.handleCloseFileBrowser}
                  onSelect={this.handleSelectFiles}
                />
              </div>
            </div>
            <div>
              <button
                className={`btn btn-sm btn-success ${btnDisabled}`}
                disabled={!!btnDisabled}
                onClick={this.handelSave}>
                Kommentieren
              </button>
            </div>
          </div>
        </div>
      </ComposeDropzone>
    );
  }
}

NewCommentForm.propTypes = {
  project_id: PropTypes.number.isRequired,
  commentable_id: PropTypes.number.isRequired,
  commentable_type: PropTypes.string.isRequired,
  onSend: PropTypes.func.isRequired,
  onSent: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  shouldAutoFocus: PropTypes.bool,
};

export default NewCommentForm;

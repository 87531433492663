import React from 'react';
import PropTypes from 'prop-types';

import AffiliateSaleSelector from '../../shared/AffiliateSaleSelector';

class AffiliateSaleEffectForm extends React.Component {
  handleChange = (sale) => {
    this.props.onChange(Object.assign({}, this.props.record, {
      affiliate_sale: sale,
      affiliate_sale_id: sale && sale.id,
    }));
  };


  render() {
    return (
      <div className="mb-3">
        <label className="form-label" htmlFor="affiliate-selector">Affiliate Sale</label>
        <div style={{ marginBottom: 6 }} id="affiliate-selector">
          <AffiliateSaleSelector
            value={this.props.record.affiliate_sale}
            onChange={this.handleChange}
            optionsUrl={`/${this.props.organizationSlug}/affiliate_sales.json`}
          />
        </div>
      </div>
    );
  }
}

AffiliateSaleEffectForm.propTypes = {
  record: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default AffiliateSaleEffectForm;

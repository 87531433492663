import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TodoText from './TodoText';
import ResponsibleBadge from './ResponsibleBadge';
import RoleBadge from './RoleBadge';
import DueBadge from './DueBadge';
import PriorityIcon from './PriorityIcon';
import SubTodosBadge from './SubTodosBadge';
import Icon from '../shared/Icon';
import CheckButton from './CheckButton';
import SubTodoForm from './SubTodoForm';
import TodoCommentsBadge from './TodoCommentsBadge';

import ConfirmModal from '../shared/ConfirmModal';

import { Draggable } from 'react-beautiful-dnd';

import { updateAsync, deleteAsync } from '../../helpers/rails_helper.js';
import { subscribe, unsubscribe } from '../../helpers/actioncable_helper';

class SubTodo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      todo: props.initialTodo,
      editing: false,
      saving: false,
    };
  }

  componentDidMount() {
    this.channel = subscribe({
      channel: 'TodoChangesChannel',
      todo_id: this.state.todo.id,
    }, ({ action, todo }) => {
      if (action === 'update') {
        this.setState({ todo });
      } else if (action === 'delete') {
        this.props.onRemove(this.state.todo);
      }
    });
  }

  componentWillUnmount() {
    if (this.channel) {
      unsubscribe(this.channel);
    }
  }

  handleEditClick = (e) => {
    e.preventDefault();
    this.setState({ editing: true });
  }

  handleCancelEdit = () => {
    this.setState({ editing: false });
  }

  handleDelete = () => {
    deleteAsync(`/todos/${this.state.todo.id}`).then(() => {
      this.props.onRemove(this.state.todo);
    });
  }

  handleSave = (todo) => {
    this.setState({ saving: true });

    let responsible = '';

    if (todo.role) {
      responsible = `role-${todo.role.id}`;
    } else if (todo.user) {
      responsible = `user-${todo.user.id}`;
    }

    updateAsync(`/todos/${todo.id}`, {
      todo: {
        text: todo.text,
        desc: todo.desc,
        priority: todo.priority,
        due: todo.due || '',
        responsible_gid: todo.responsible?.gid || null,
      },
    }).then((result) => {
      this.setState({ editing: false, saving: false });
      this.handleUpdateTodo(result.todo);
    });
  }

  handleUpdateTodo = (todo) => {
    this.setState({ todo });
  }

  handleTodoChange = (todo) => {
    this.setState({ todo });
  }

  handleComplete = () => {
    this.setState({
      todo: Object.assign({}, this.state.todo, { complete: true }),
      saving: true,
    });

    updateAsync(`/todos/${this.state.todo.id}/complete`).then((result) => {
      this.setState({ saving: false });
      this.handleUpdateTodo(result.todo);
    });
  }

  handleReset = () => {
    this.setState({
      todo: Object.assign({}, this.state.todo, { complete: false }),
      saving: true,
    });

    updateAsync(`/todos/${this.state.todo.id}/reopen`).then((result) => {
      this.setState({ saving: false });
      this.handleUpdateTodo(result.todo);
    });
  }

  mayCompleteTodo = () => {
    return this.props.completable || this.state.todo.user_id === this.props.currentUserId;
  }

  mayEditDeleteTodo = () => {
    return this.props.editable
    || this.state.todo.user_id === this.props.currentUserId
    || this.state.todo.creator_id === this.props.currentUserId;
  }

  renderEditButton() {
    if (!this.mayEditDeleteTodo()) return null;

    return (
      <a href="" className="sub-todo-edit-button" onClick={this.handleEditClick}>
        <Icon name="pencil" />
      </a>
    );
  }

  renderTodo() {
    let completeClass = '';

    const todo = this.state.todo;

    if (todo.complete) {
      completeClass = 'sub-todo-complete';
    }

    const badgeStyle = { marginRight: 4 };

    return (
      <Draggable
        key={this.state.todo.id}
        draggableId={this.state.todo.id.toString()}
        index={this.props.index}
        isDragDisabled={!this.props.sortable}>
        {(provided, snapshot) => (
          <div className={`todo-sub-todo ${completeClass}`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            // style={getItemStyle(
            //   snapshot.isDragging,
            //   provided.draggableProps.style,
            // )}
          >
            <div className="sub-todo-hover-extension" />
            <div className="sub-todo-actions-container hidden-xs">
              <div className="sub-todo-actions">
                <ConfirmModal
                  target={onClick => (
                    <a href="" className="sub-todo-delete-button" onClick={onClick}>
                      <Icon name="trash" />
                    </a>
                  )}
                  title="Aufgabe löschen"
                  cancelText="abbrechen"
                  confirmText="löschen"
                  confirmStyle="danger"
                  visible={this.mayEditDeleteTodo()}
                  onConfirmed={this.handleDelete}>
                    Bist du sicher, dass du die Aufgabe löschen willst?
                </ConfirmModal>
                {this.renderEditButton()}
              </div>
            </div>

            <div className="sub-todo-check-button-container">
              <CheckButton
                todo={todo}
                completable={this.mayCompleteTodo()}
                onComplete={this.handleComplete}
                onReset={this.handleReset}
                saving={this.state.saving} />
            </div>

            <TodoText {...todo} commentable={this.props.commentable} />

            <div className="todo-meta todo-badges-sm">
              <ResponsibleBadge todo={todo} style={badgeStyle} />
              <RoleBadge todo={todo} style={badgeStyle} />
              <DueBadge todo={todo} style={badgeStyle} />
              <SubTodosBadge todo={todo} style={badgeStyle} />
              <TodoCommentsBadge todo={todo} style={badgeStyle} />
              <PriorityIcon todo={todo} />
            </div>
          </div>
        )}
      </Draggable>
    );
  }

  renderForm() {
    return (
      <SubTodoForm
        todo={this.state.todo}
        onChange={this.handleTodoChange}
        onCancel={this.handleCancelEdit}
        saving={this.state.saving}
        responsibleOptionsPath={this.props.responsibleOptionsPath}
        onSave={this.handleSave} />
    );
  }

  render() {
    const url = `/todos/${this.state.todo.id}/sort`;

    return (
      <div className="sub-todo-container position-relative" data-url={url}>
        {this.state.editing ?
          this.renderForm() :
          this.renderTodo()}
      </div>
    );
  }
}

SubTodo.propTypes = {
  initialTodo: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  responsibleOptionsPath: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  deletable: PropTypes.bool.isRequired,
  sortable: PropTypes.bool.isRequired,
  completable: PropTypes.bool.isRequired,
  commentable: PropTypes.bool.isRequired,
  currentUserId: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default SubTodo;

import React from 'react';
import PropTypes from 'prop-types';

class RenameWorkflowEffectForm extends React.Component {
  handleChange = (e) => {
    this.props.onChange(Object.assign({}, this.props.record, {
      title: e.target.value,
    }));
  };

  handleKeyDown = (e) => {
    // cmd + enter
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      this.props.onSave(e);
    }
  };

  render() {
    return (
      <div className="mb-3">
        <label className="form-label">Neuer Name</label>
        <input
          autoFocus
          type="text"
          className="form-control"
          placeholder="Bitte eintragen"
          value={this.props.record.title || ''}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
        />
      </div>
    );
  }
}

RenameWorkflowEffectForm.propTypes = {
  record: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func,
};

export default RenameWorkflowEffectForm;

import React from 'react';
import PropTypes from 'prop-types';

import SubTodoTemplateForm from './SubTodoTemplateForm';
import RemoteFormModal from '../shared/RemoteFormModal';

import todoTemplateValidator from '../../validators/todo_template_validator';
import { saveTodo } from '../../helpers/api';

const SubTodoTemplateModal = props => (
  <RemoteFormModal {...props}
    titleNew="Neue Unteraufgabe"
    titleEdit="Unteraufgabe bearbeiten"
    validator={todoTemplateValidator}
    form={SubTodoTemplateForm}
    initialRecord={props.initialSubTodo}
    saveHandler={saveTodo}
    workflowType={props.workflowType}
  />
);

SubTodoTemplateModal.propTypes = {
  initialSubTodo: PropTypes.object.isRequired,
  workflowType: PropTypes.string.isRequired,
};

export default SubTodoTemplateModal;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

import { smallStyles, defaultStyles } from '../../styles/select_styles';
import { searchText } from '../../helpers/helpers';

const filterOption = (option, searchTerm) => {
  return searchText(option.data.name, searchTerm);
};

const NoComp = () => {
  return null;
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      {props.data.name}
    </components.Option>
  );
};

const SingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      {props.data.name}
    </components.SingleValue>
  );
};

class AssignmentSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
    };
  }

  handleFocus = (e) => {
    this.loadOptions();
  };

  loadOptions() {
    return fetch(this.props.optionsUrl, {
      credentials: 'include',
    }).then((response) => {
      return response.json();
    }).then((result) => {
      this.setState({
        options: result.assignments.filter((a) => a.type === this.props.assigneeType),
      });
    });
  }

  render() {
    return (
      <Select
        components={{
          Option,
          SingleValue,
          DropdownIndicator: this.props.hideDropdownIndicator ? NoComp : components.DropdownIndicator,
          IndicatorSeparator: this.props.hideDropdownIndicator ? NoComp : components.IndicatorSeparator,
        }}
        isDisabled={this.props.isDisabled}
        defaultValue={this.props.value}
        isClearable
        filterOption={filterOption}
        getValue={options => options.responsible_param}
        openMenuOnFocus
        onFocus={this.handleFocus}
        onKeyDown={this.handleKeyDown}
        backspaceRemovesValue={false}
        blurInputOnSelect
        menuPlacement="auto"
        onChange={this.props.onChange}
        handleInputChange={this.handleInputChange}
        options={this.state.options}
        defaultOptions
        styles={this.props.size === 'small' ? smallStyles : defaultStyles}
        placeholder={this.props.placeholder || 'Zuteilung auswählen'}
        loadingPlaceholder="Laden..."
        noOptionsMessage={() => 'Keine Ergebnisse'}
        loadingMessage={() => 'Laden...'}
      />
    );
  }
}

AssignmentSelector.propTypes = {
  assigneeType: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  isDisabled: PropTypes.bool,
  size: PropTypes.string,
  hideDropdownIndicator: PropTypes.bool,
  optionsUrl: PropTypes.string.isRequired,
};

export default AssignmentSelector;

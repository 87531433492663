import { Controller } from '@hotwired/stimulus';
import moment from 'moment';

export default class extends Controller {
  static targets = ['type', 'startDate', 'endDate', 'submit'];

  connect() {
    this.adjustEndDate();
  }

  changeType() {
    this.adjustEndDate();
  }

  changeStart() {
    this.adjustEndDate();
  }

  adjustEndDate() {
    const startDateValue = this.startDateTarget.value;
    if (!startDateValue) {
      this.endDateTarget.value = '';
      this.endDateTarget.disabled = true;
      this.submitTarget.disabled = true;
      return;
    }
    this.endDateTarget.disabled = false;
    this.submitTarget.disabled = false;

    let newEndDateMin;
    newEndDateMin = moment(startDateValue).add(1, 'days').format('YYYY-MM-DD');
    this.endDateTarget.min = newEndDateMin;
    if (this.endDateTarget.value && this.endDateTarget.value < newEndDateMin) {
      this.endDateTarget.value = newEndDateMin;
    }
  }
}

import React, { Component, Fragment } from 'react';

import SearchSuggestions from './SearchSuggestions';
import { debounce } from 'lodash';
import { getAsync } from '../../helpers/rails_helper';
import $ from 'jquery';

const placeholder = {
  long: 'Suche nach Projekten, Kontakten, Emails, Telefonnummern...',
  short: 'Suche',
};

const hideMenuItems = () => {
  $('.search-hidable').fadeOut("fast", function() {
    $('.search-hidable').attr("style", "display: none !important");
  });
};

const showMenuItems = () => {
  $('.search-hidable').fadeIn('fast');
};

const getSearchResults = (searchTerm, signal, callback) => {
  const url = `/search?q=${encodeURIComponent(searchTerm)}`;

  return getAsync(url, signal).then(response => {
    if (searchTerm) {
      const extendedResults = [{
        search_result_type: 'global',
        href: url,
        term: searchTerm,
      }, ...response.results];

      callback(extendedResults);
    } else {
      callback([]);
    }
  });
};

const getSearchResultsLazy = debounce(getSearchResults, 200);

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      placeholder: placeholder.short,
      term: '',
      loading: false,
      suggestionsVisible: false,
      results: [],
      abortController: null,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleFocus() {
    this.setState({
      placeholder: placeholder.long,
      suggestionsVisible: true,
    });
    hideMenuItems();
  }

  handleBlur() {
    this.setState({
      placeholder: placeholder.short,
    });

    // Suchergebniss mit Verzögerung ausblenden,
    // da sonst ein Klick auf den Link (Suchergebnis)
    // nicht greift.
    setTimeout(() => {
      this.setState({
        suggestionsVisible: false,
      });
    }, 200);

    showMenuItems();
  }

  handleInputChange(e) {
    if (this.state.abortController) this.state.abortController.abort()
    const abortController = new AbortController();

    const term = e.target.value;

    if (term.trim().length < 3 || term.trim() === this.state.term.trim()) {
      this.setState({
        term,
        abortController,
      });
      return;
    }

    this.setState({
      term,
      loading: true,
      abortController,
    });

    getSearchResultsLazy(term, abortController.signal, results => {
      this.setState({
        results,
        loading: false,
        abortController: null,
      });
    })?.catch(_e => {});
  }

  handleKeyDown(e) {
    if (e.keyCode === 27) { // ESC
      this.inputRef.blur();
    }

    if (e.keyCode === 38) { // Arrow up
      e.preventDefault();
    }

    if (e.keyCode === 40) { // Arrow down
      e.preventDefault();
    }
  }

  render() {
    return (
      <Fragment>
        <form
          className="navbar-form navbar-left"
          role="search"
          action="/search"
          acceptCharset="UTF-8" method="get">
          <input
            id="search-input"
            type="text"
            className="nav-bar-search-input input-sm form-control-sm form-control"
            name="q"
            ref={node => this.inputRef = node}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onChange={this.handleInputChange}
            onKeyDown={this.handleKeyDown}
            value={this.state.term}
            placeholder={this.state.placeholder}
            autoComplete="off"
            style={{top: "9px"}}
          />
        </form>
        <SearchSuggestions results={this.state.results} visible={this.state.suggestionsVisible} />
      </Fragment>

    );
  }
}

export default Search;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../shared/Icon';

const StartVoiceAgentEffectForm = ({ effect, value = {}, onChange }) => {
  const style = {
    display: 'flex',
  };

  if (value.do_not_start) {
    style.textDecoration = 'line-through';
    style.color = '#888';
  }

  return (
    <div className="fire-effect-item">
      <div className="fire-effect-icon">
        <Icon name="waveform-lines" />
      </div>
      <div className="fire-effect-content">
        <div className="fire-effect-title">
          <div style={style}>
            <div style={{ flexGrow: 1 }}>
              <div>{effect.type_human}: {effect.title }</div>
              <div className="text-muted fw-normal">
                <small>
                  {effect.desc}
                </small>
              </div>
            </div>
            <div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  aria-checked={!value.do_not_start}
                  checked={!value.do_not_start}
                  onClick={() => onChange({ do_not_start: !value.do_not_start })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

StartVoiceAgentEffectForm.propTypes = {
  effect: PropTypes.object.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default StartVoiceAgentEffectForm;

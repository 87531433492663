import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../shared/Icon';

class SetAutomaticDataPointEffectForm extends React.Component {
  render() {
    return (
      <div className="fire-effect-item">
        <div className="fire-effect-icon">
          <Icon name="database" />
        </div>
        <div className="fire-effect-content">
          <div className="fire-effect-title">
            {this.props.effect.data_point_template.title}
          </div>
          <div className="fire-effect-inputs">
            <div className="text-muted">Wird automatisch gesetzt</div>
          </div>
        </div>
      </div>
    );
  }
}

SetAutomaticDataPointEffectForm.propTypes = {
  effect: PropTypes.object.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};


export default SetAutomaticDataPointEffectForm;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import TimeAgo from '../shared/TimeAgo';

class TodoPreviewFacts extends Component {
  shouldRender() {
    return (
      this.props.todo.role
      || !this.props.todo.recent_comments
      || !this.props.todo.recent_comments.length
      || this.props.todo.user
      || (this.props.todo.due && !this.props.todo.complete)
    );
  }

  renderDue() {
    if (this.props.todo.due) {
      return (
        <div className="todo-preview-fact">
          <div className="todo-preview-fact-icon">
            <Icon name="clock" />
          </div>
          <div className="todo-preview-fact-content">
            <TimeAgo datetime={this.props.todo.due} /> fällig
          </div>
        </div>
      );
    }

    return null;
  }

  renderNoComments() {
    if (this.props.todo.recent_comments && this.props.todo.recent_comments.length) return null;

    return (
      <div className="todo-preview-fact">
        <div className="todo-preview-fact-icon">
          <Icon name="comment" />
        </div>
        <div className="todo-preview-fact-content">
          {this.props.commentable ? 'Bisher keine Kommentare' : 'Keine Kommentare'}
        </div>
      </div>
    );
  }

  renderResponsible() {
    if (!this.props.todo.role && !this.props.todo.user) return null;
    const text = [];

    if (this.props.todo.role) text.push(this.props.todo.role.name);
    if (this.props.todo.user) text.push(this.props.todo.user.fullname);

    return (
      <div className="todo-preview-fact">
        <div className="todo-preview-fact-icon">
          <Icon name="user" />
        </div>
        <div className="todo-preview-fact-content">
          Verantwortlich: {text.join(' - ')}
        </div>
      </div>
    );
  }

  render() {
    if (!this.shouldRender()) return null;

    return (
      <div className="todo-preview-facts">
        {this.renderNoComments()}
        {this.renderResponsible()}
        {this.renderDue()}
      </div>
    );
  }
}

TodoPreviewFacts.propTypes = {
  todo: PropTypes.object.isRequired,
  commentable: PropTypes.bool.isRequired,
};

export default TodoPreviewFacts;

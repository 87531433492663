import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import ResponsibleSelector from '../shared/ResponsibleSelector';

class ResponsibleSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      todo: props.todo,
      responsible: props.todo.role || props.todo.responsible,
    };
  }

  render() {
    return (
      <Fragment>
        <input
          type="hidden"
          name={this.props.name}
          value={this.state.responsible ? this.state.responsible.gid : ""}
        />
        <ResponsibleSelector
          value={this.state.todo.role || this.state.todo.responsible || null}
          optionsUrl={this.props.optionsUrl}
          onChange={(responsible) => this.setState({ responsible })}
          suggestionContext={`responsible-todo-template-id-${this.props.todo.todo_template_id}`}
        />
      </Fragment>
    );
  }
}

ResponsibleSelect.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  todo: PropTypes.object,
  isDisabled: PropTypes.bool,
  size: PropTypes.string,
  hideDropdownIndicator: PropTypes.bool,
  optionsUrl: PropTypes.string.isRequired,
};

ResponsibleSelect.defaultProps = {
  excludeUserIds: [],
  placeholder: 'Rolle oder Benutzer',
};

export default ResponsibleSelect;

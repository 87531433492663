import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import Button from '../shared/Button';
import PrioritySelect from '../Workflows/PrioritySelect';

import { subscribe, unsubscribe } from '../../helpers/actioncable_helper';

import { updateAsync } from '../../helpers/rails_helper';

class TodoPriority extends Component {
  constructor(props) {
    super(props);

    this.state = {
      todo: props.todo,
      changedTodo: props.todo,
      editing: false,
      saving: false,
    };
  }

  componentDidMount() {
    this.channel = subscribe({
      channel: 'TodoChangesChannel',
      todo_id: this.state.todo.id,
    }, ({ action, todo }) => {
      if (action === 'update') {
        this.setState({ todo, editing: false, saving: false });
      }
    });
  }

  componentWillUnmount() {
    if (this.channel) {
      unsubscribe(this.channel);
    }
  }

  handleChange = (newValue) => {
    this.setState({
      changedTodo: Object.assign({}, this.state.changedTodo, {
        priority: newValue,
      }),
    });
  }

  handleSaveClick = (e) => {
    e.preventDefault();
    this.setState({ saving: true });

    updateAsync(`/todos/${this.state.todo.id}/set_priority.json`, {
      todo: {
        priority: this.state.changedTodo.priority,
      },
    }, 'PATCH').then((result) => {
      this.setState({
        editing: false,
        saving: false,
        todo: result.todo,
      });
    });
  }

  toggleEdit = (e) => {
    e.preventDefault();
    this.setState({ editing: !this.state.editing });
  }

  renderEditButton() {
    if (this.props.mayEdit) {
      return (
        <a href="" className="todo-toggle-responsible-link" onClick={this.toggleEdit}>
          <Icon name="cog" />
        </a>
      );
    }

    return null;
  }

  renderPriority() {
    if (this.state.todo.priority) {
      const title = this.state.todo.priority_title;
      const color = this.state.todo.priority_color;

      return (
        <Fragment>
          <Icon name="exclamation-circle" color={color} style={{
            verticalAlign: 'middle',
            fontSize: 18,
            marginBottom: 3,
          }} /> {title}
        </Fragment>
      );
    }

    return 'Keine Priorität eingetragen';
  }

  renderForm() {
    return (
      <Fragment>
        <div style={{ marginBottom: 10 }}>
          <PrioritySelect
            value={this.state.changedTodo.priority}
            onChange={this.handleChange}
          />
        </div>
        <p>
          <Button loading={this.state.saving} className="btn btn-sm btn-primary" onClick={this.handleSaveClick}>
            Speichern
          </Button>{' '}
          <button className="btn btn-sm btn-link" onClick={this.toggleEdit}>
            abbrechen
          </button>
        </p>
        <hr />
      </Fragment>
    );
  }

  render() {
    if (this.state.todo.complete) {
      return null;
    }

    return (
      <div>
        <div className="todo-side-headline">Priorität
          {this.renderEditButton()}
        </div>
        <div>
          {this.state.editing ? (
            this.renderForm()
          ) : (
            <p className="text-muted">
              {this.renderPriority()}
            </p>
          )}
        </div>
        <hr />
      </div>
    );
  }
}

TodoPriority.propTypes = {
  todo: PropTypes.object.isRequired,
  mayEdit: PropTypes.bool,
};

export default TodoPriority;

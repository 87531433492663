import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import Modal from '../shared/Modal';

import { reorder } from '../../helpers/helpers';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

class CatalogMultiSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      catalogs: props.catalogs,
      modalOpen: false,
    };

    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleOpenAddModal = this.handleOpenAddModal.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
  }

  availableCatalogOptions() {
    const ids = this.state.catalogs.map(h => h.id);

    return this.props.catalogOptions.filter((option) => {
      return !ids.includes(option.id);
    });
  }

  handleRemoveClick(e, i) {
    e.preventDefault();

    this.setState({
      catalogs: [...this.state.catalogs.slice(0, i), ...this.state.catalogs.slice(i + 1)],
    });
  }


  handleOpenAddModal(e) {
    e.preventDefault();
    this.setState({
      modalOpen: true,
    });
  }

  handleModalClose(e) {
    e.preventDefault();

    this.setState({
      modalOpen: false,
    });
  }

  handleAddClick(e, catalog) {
    e.preventDefault();

    this.handleModalClose(e);

    this.setState({
      catalogs: [...this.state.catalogs, catalog],
    });
  }

  handleDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const cats = reorder(
      this.state.catalogs,
      result.source.index,
      result.destination.index,
    );

    this.setState({
      catalogs: cats,
    });
  }

  renderCatalogs() {
    return this.state.catalogs.map((catalog, i) => (
      <Draggable key={`draggable-${catalog.id}`} draggableId={catalog.id.toString()} index={i}>
        {(provided, snapshot) => (
          <div key={catalog.id}
            ref={provided.innerRef}
            {...provided.draggableProps}>
            <div className="catalog-item" {...provided.dragHandleProps}>
              <div className="catalog-content">
                {catalog.name}
              </div>
              <div className="catalog-action">
                <a href="" onClick={e => this.handleRemoveClick(e, i)}>
                  <Icon name="trash" />
                </a>
              </div>
            </div>
          </div>
        )}
      </Draggable>
    ));
  }

  renderOptions() {
    const options = this.availableCatalogOptions();

    if (options.length === 0) {
      return (
        <p>
          Keine weiteren Kataloge vorhanden
        </p>
      );
    }

    return this.availableCatalogOptions().map((catalog) => {
      return (
        <div key={catalog.id} className="catalog-item">
          <div className="catalog-content">
            <div>{catalog.title}</div>
            {catalog.name}
          </div>
          <div className="catalog-action">
            <a href="" onClick={e => this.handleAddClick(e, catalog)} className="btn btn-primary btn-compact btn-sm">
              auswählen
            </a>
          </div>
        </div>
      );
    });
  }

  renderModal() {
    return (
      <Modal visible={this.state.modalOpen} onClose={this.handleModalClose} size="medium">
        <div className="react-modal-header">
          <h4>
            Katalog hinzufügen
          </h4>
        </div>
        <div className="react-modal-content">
          {this.renderOptions()}
        </div>
        <div className="react-modal-footer">
          <div className="text-muted" />
          <div>
            <button
              className="btn btn-light"
              onClick={this.handleModalClose}>Abbrechen</button>{' '}
          </div>
        </div>
      </Modal>
    );
  }

  renderAddText() {
    if (this.state.catalogs.length) {
      return 'Katalog hinzufügen';
    }

    return this.props.triggerTitle;
  }

  renderBlankInputField() {
    return !this.state.catalogs.length && (
      <input name={this.props.name} type="hidden" value="" />
    );
  }

  renderHiddenFields() {
    return this.state.catalogs.map((catalog, i) => {
      const hiddenFields = [<input name={this.props.name} type="hidden" value={catalog.id} key={0} />]

      if (this.props.optionalPositionName) {
        hiddenFields.push(<input name={this.props.optionalPositionName} type="hidden" value={i} key={1} />)
      }

      return (
        <div key={i}>
          {hiddenFields}
        </div>
      );
    });
  }

  render() {
    return (
      <div>
        <div>
          <DragDropContext onDragEnd={this.handleDragEnd}>
            <Droppable droppableId="catalog-ids">
              {(provided, snapshot) => (
                <div ref={provided.innerRef}
                  // style={getListStyle(snapshot.isDraggingOver)}
                  >
                  {this.renderCatalogs()}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        {this.renderBlankInputField()}
        {this.renderHiddenFields()}
        <div className="catalog-add">
          <a href="" onClick={this.handleOpenAddModal}>
            {this.renderAddText()}
          </a>
        </div>
        {this.renderModal()}
      </div>
    );
  }
}

CatalogMultiSelect.propTypes = {
  catalogs: PropTypes.array,
  catalogOptions: PropTypes.array,
  name: PropTypes.string.isRequired,
  optionalPositionName: PropTypes.string,
  triggerTitle: PropTypes.string,
};

export default CatalogMultiSelect;

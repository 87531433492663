import React, { Component } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import Icon from '../shared/Icon';
import TimeAgo from '../shared/TimeAgo';

import { subscribe, unsubscribe } from '../../helpers/actioncable_helper';

class LastActivity extends Component {
  constructor(props) {
    super(props);
    this.state = { lastActivityAt: props.lastActivityAt };
  }

  componentDidMount() {
    this.channel = subscribe({
      channel: 'ProjectLastActivityChannel',
      project_id: this.props.projectId,
    }, (data) => {
      this.setState({ lastActivityAt: data.last_activity_at });
    });
  }

  componentWillUnmount() {
    unsubscribe(this.channel);
  }

  render() {
    if (this.state.lastActivityAt) {
      const title = moment(this.state.lastActivityAt).format('DD.MM.YYYY HH:mm [Uhr]');

      return (
        <span className="additional-project-info">
          <a href={this.props.url} className="link-with-icon" title={title}>
            <Icon name="rss" />
            <span>
              Letzte Aktivität{' '}
              <TimeAgo
                datetime={this.state.lastActivityAt}
                locale="de"
              />
            </span>
          </a>
        </span>
      );
    }

    return null;
  }
}

LastActivity.propTypes = {
  lastActivityAt: PropTypes.string,
  url: PropTypes.string,
  projectId: PropTypes.number,
};

export default LastActivity;

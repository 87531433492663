/* global window */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import Tooltip from '../shared/Tooltip';
import Button from '../shared/Button';
import ResponsibleSelector from '../shared/ResponsibleSelector';
import FullnameWithAvatar from '../shared/FullNameWithAvatar';
import { updateAsync } from '../../helpers/rails_helper';
import { subscribe, unsubscribe } from '../../helpers/actioncable_helper';

class Role extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      role: this.props.initialRole,
      editedRole: this.props.initialRole,
      saving: false,
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);

    this.channel = subscribe({
      channel: 'RoleChangesChannel',
      role_id: this.state.role.id,
    }, (data) => {
      if (data.action === 'changed') {
        this.handleUpdateRole(data.role);
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    unsubscribe(this.channel);
  }

  toggleEdit = (e) => {
    e.preventDefault();

    if (this.state.editing) {
      this.setState({ editing: false, editedRole: this.state.role });
    } else {
      this.setState({ editing: true, editedRole: this.state.role });
    }
  };

  handleUpdateRole = (role) => {
    this.setState({ role, editing: false, saving: false, editedRole: this.state.role });
  };

  handleKeyDown(event) {
    if (event.keyCode === 27) {
      this.setState({ editing: false });
    }
  }

  handleSaveClick = (e) => {
    e.preventDefault();

    this.setState({ saving: true });

    updateAsync(`/roles/${this.state.editedRole.id}`, {
      role: {
        responsible_gid: this.state.editedRole.responsible_gid,
      },
    }).then(({ role }) => {
      this.handleUpdateRole(role);
    });
  };

  handleResponsibleChange = (responsible) => {
    this.setState({
      editedRole: {
        ...this.state.role,
        responsible_gid: responsible?.gid || null,
        responsible,
      },
    });
  };

  renderResponsible() {
    if (this.state.role.responsible && !this.state.editing) {
      return (
        <Tooltip title={this.state.role.responsible.bio} placement="top" enabled={!!this.state.role.responsible.bio}>
          <a href={this.state.role.responsible.path}>
            <FullnameWithAvatar model={this.state.role.responsible} size="tiny" />
          </a>
        </Tooltip>
      );
    }

    return null;
  }

  renderCog() {
    if (this.props.editable) {
      return (
        <span className="float-end">
          <a href="" onClick={this.toggleEdit} className="toggle-edit-role">
            <Icon name="cog" />
          </a>
        </span>
      );
    }

    return null;
  }

  renderForm() {
    if (this.state.editing) {
      const classNames = ['btn btn-sm btn-primary'];

      if (this.state.saving) {
        classNames.push('btn-loading');
      }

      return (
        <div>
          <div style={{ margin: '8px 0' }}>
            <ResponsibleSelector
              optionsUrl={`/workflows/${this.props.workflowId}/responsible_options.json`}
              isDisabled={this.state.saving}
              value={this.state.editedRole.responsible}
              placeholder="Nutzer wählen..."
              suggestionContext={`role-template-id-${this.props.initialRole.role_template_id}`}
              onChange={this.handleResponsibleChange}
              showRoles={false} />
          </div>
          <p>
            <Button
              disabled={this.state.saving}
              className="btn btn-primary"
              loading={this.state.saving}
              onClick={this.handleSaveClick}>
              Speichern
            </Button>{' '}
            <button disabled={this.state.saving} className="btn btn-link" onClick={this.toggleEdit}>
              abbrechen
            </button>
          </p>
        </div>
      );
    }

    return null;
  }

  render() {
    return (
      <div className="role">
        {this.renderCog()}
        <div className="show-role">
          <div className="dot"><Icon name="circle" color={this.state.role.color} /></div>
          <div className="text-muted">
            {this.state.role.name}
            {this.state.role.project_role_id ? (
              <span title="Projektrolle" style={{ fontWeight: 500, color: 'var(--bs-tertiary-color', fontSize: 11 }}> PR</span>
            ) : ''}
            <div style={{ marginTop: 3 }}>
              {this.renderResponsible()}
            </div>
          </div>
        </div>
        {this.renderForm()}
      </div>
    );
  }
}

Role.propTypes = {
  initialRole: PropTypes.object,
  editable: PropTypes.bool,
  organizationSlug: PropTypes.string.isRequired,
};

Role.defaultProps = {
  role: {},
};

export default Role;


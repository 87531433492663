import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../shared/Icon';
import ComposeDropzone from '../../Todos/ComposeDropzone';
import ComposeAttachments from '../../Todos/ComposeAttachments';

import MiniFileBrowser from '../../FileBrowser/MiniFileBrowser';

class DocumentDataPointInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      attachments: props.dataPoint.value || [],
      attachmentsToBeDeleted: [],
      browserOpen: false,
    };
  }

  getDropzoneRef = (node) => {
    this.dropzoneRef = node;
  };

  handleChange = () => {
    // build attachments_attributes (via nested attributes)
    // for attachments association of DataPoint::DocumentDataPoint
    const attachmentsAttributes = [
      ...this.state.attachments.map((attachment) => ({
        id: attachment.id || '',
        document_sgid: attachment.document.sgid,
      })),
      ...this.state.attachmentsToBeDeleted.map((attachment) => ({
        id: attachment.id,
        _destroy: true,
      })),
    ];

    this.props.onChange(attachmentsAttributes);
  };

  handleUploadsFinished = (uploads) => {
    // Nur Uploads hinzufügen, die noch nicht in der Liste sind
    this.setState((prevState) => ({
      attachments: [
        ...prevState.attachments,
        ...uploads.filter(u => !prevState.attachments.find(u2 => u2.document_id === u.document_id)),
      ],
    }), () => {
      this.handleChange();
    });
  };

  handleChooseFileClick = (e) => {
    e.preventDefault();
    this.dropzoneRef.open();
  };

  handleOpenFileBrowser = (e) => {
    e.preventDefault();
    this.setState({ browserOpen: true });
  };

  handleCloseFileBrowser = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ browserOpen: false });
  };

  handleSelectAttachments = (attachments) => {
    // filter out attachments already present in the list
    // create new object with empty id, so that rails creates new records
    this.setState((prevState) => ({
      browserOpen: false,
      attachments: [
        ...prevState.attachments,
        ...attachments
          .filter(f => !prevState.attachments.find(f2 => f2.document_id === f.document_id))
          .map((a) => Object.assign({}, { ...a }, { id: null })),
      ],
    }), () => {
      this.handleChange();
    });
  };

  handleRemoveAttachment = (attachment) => {
    this.setState((prevState) => {
      const newState = {
        attachments: prevState.attachments.filter(a => a.document_id !== attachment.document_id),
      };

      // Remember attachment to be destroyed on the server
      // if it was saved before, thus having an ID.
      if (attachment.id) {
        newState.attachmentsToBeDeleted = [...prevState.attachmentsToBeDeleted, attachment];
      }

      return newState;
    }, () => {
      this.handleChange();
    });
  };

  render() {
    return (
      <ComposeDropzone
        onUploadsFinished={this.handleUploadsFinished}
        getDropzoneRef={this.getDropzoneRef}>
        <ComposeAttachments
          onRemove={this.handleRemoveAttachment}
          attachments={this.state.attachments}
          className="document-data-point-files"
        />

        <div>
          <button className="btn btn-link me-3" onClick={this.handleChooseFileClick}>
            <Icon name="cloud-upload" /> hochladen
          </button>
          <button className="btn btn-link" onClick={this.handleOpenFileBrowser}>
            <Icon name="folder" /> auswählen
          </button>
        </div>
        <MiniFileBrowser
          project_id={this.props.dataHolderId}
          visible={this.state.browserOpen}
          onClose={this.handleCloseFileBrowser}
          onSelect={this.handleSelectAttachments}
        />
      </ComposeDropzone>
    );
  }
}

DocumentDataPointInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  autoFocus: PropTypes.bool,
  value: PropTypes.array,
};

export default DocumentDataPointInput;

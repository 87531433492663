import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '../shared/Icon';

import { getAsync } from '../../helpers/rails_helper';

import Tooltip from '../shared/Tooltip';
import Dropdown from '../shared/Dropdown';

const PinnedNotes = (props) => {
  const [notes, setNotes] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const loadNotes = () => {
    getAsync(`/projects/${props.projectId}/notes.json?pinned=1`).then((result) => {
      setNotes(result.notes);
    });
  };

  useEffect(() => {
    loadNotes();

    document.addEventListener('project:pinned_notes:changed', loadNotes);

    return () => {
      document.removeEventListener('project:pinned_notes:changed', loadNotes);
    };
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    setDropdownOpen(!dropdownOpen);
  };

  const handleClose = () => {
    setDropdownOpen(false);
  };

  const iconColor = notes.length ? 'var(--bs-danger)' : '';

  const button = (ref) => (
    <Tooltip title="Wichtige Notizen" placement="top-end">
      <a className="pin" onClick={handleClick} ref={ref}>
        <Icon name="location-exclamation" light={!notes.length} color={iconColor} />
      </a>
    </Tooltip>
  );

  return (
    <Fragment>
      <Dropdown isOpen={dropdownOpen} onClose={handleClose} target={button} placement="bottom-end">
        {notes.length ? (
          <div className="pinned-notes">
            {notes.map((note) => (
              <div className="pinned-note" key={note.id}>
                <h4 className="title">{note.name}</h4>
                <div>{note.body}</div>
              </div>
            ))}
            <div>
              <a href={`/projects/${props.projectId}/notes`} className="btn btn-link">
                Notizen bearbeiten
              </a>
            </div>
          </div>
        ) : (
          <div className="pinned-notes-hint">
            <h4>Wichtige Notizen</h4>
            <p className="text-muted">
              Hier kannst du wichtige Notizen anpinnen, damit du oder alle im Projekt
              sie schnell sehen können.
            </p>
            <a href={`/projects/${props.projectId}/notes`} className="btn btn-primary">
              Notiz anpinnen
            </a>
          </div>
        )}
      </Dropdown>
    </Fragment>
  );
};

PinnedNotes.propTypes = {
  projectId: PropTypes.number,
};

export default PinnedNotes;

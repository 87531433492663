import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import SearchResultLink from './SearchResultLink';

const ReferenceBadge = ({ is_reference_customer }) => {
  if (is_reference_customer) {
    return (
      <Icon name="badge-check" style={{ marginRight: 6 }} color="#8bc53f" />
    );
  }

  return null;
};

const SearchResultContact = (props) => {
  const icon = props.contact_type === 'company' ? 'building' : 'user';

  return (
    <SearchResultLink {...props}>
      <div>
        <Icon name="id-card" />
      </div>
      <div>
        <div className="search-result-contact">
          <div>
            <ReferenceBadge is_reference_customer={props.is_reference_customer} />
            <Icon name={icon} /> {props.display_name}
          </div>
          <div>
            {props.client_number}
          </div>
        </div>
      </div>
    </SearchResultLink>
  );
};

SearchResultContact.propTypes = {
  display_name: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  contact_type: PropTypes.string
};

export default SearchResultContact;

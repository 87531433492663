import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

const quickDates = () => ([
  { name: '1T', title: '1 Tag', date: moment().add(1, 'days').startOf('day').format() },
  { name: '2T', title: '2 Tage', date: moment().add(2, 'days').startOf('day').format() },
  { name: '3T', title: '3 Tage', date: moment().add(3, 'days').startOf('day').format() },
  { name: '1W', title: '1 Woche', date: moment().add(1, 'week').startOf('day').format() },
  { name: '2W', title: '2 Wochen', date: moment().add(2, 'weeks').startOf('day').format() },
  { name: '1M', title: '1 Monat', date: moment().add(1, 'month').startOf('day').format() },
  { name: '3M', title: '3 Monate', date: moment().add(3, 'month').startOf('day').format() },
]);

const QuickDelaySelector = ({ onChange }) => {
  const handleSelect = (e, d) => {
    e.preventDefault();

    if (onChange) {
      onChange(d);
    }
  };

  return (
    <div className="quick-delay-options">
      {quickDates().map(d => (
        <a key={d.name} href="#" title={d.title} onClick={e => handleSelect(e, d)}>{d.name}</a>
      ))}
    </div>
  );
};

QuickDelaySelector.propTypes = {
  onChange: PropTypes.func,
};

export default QuickDelaySelector;

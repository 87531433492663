import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';

import RoleTemplateModal from './RoleTemplateModal';

import ConfirmModal from '../shared/ConfirmModal';
import InfoModal from '../shared/InfoModal';

import { deleteRole } from '../../helpers/api';

import { Draggable } from 'react-beautiful-dnd';

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  marginBottom: 10,
  // padding: grid,
  // margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  backgroundColor: isDragging ? 'var(--bs-secondary-bg)' : 'var(--bs-body-bg)',

  // borderRadius: '4px',
  // styles we need to apply on draggables
  ...draggableStyle,
});

const RoleTemplate = (props) => {
  let deleteButton;

  if (props.role.deletable) {
    deleteButton = (
      <ConfirmModal
        visible={props.editable}
        target={onClick => (
          <button
            type="button"
            className="btn btn-compact btn-link"
            onClick={onClick}>
            <Icon name="trash" />
          </button>
        )}
        title="Rolle löschen"
        cancelText="abbrechen"
        confirmText="löschen"
        confirmStyle="danger"
        onConfirm={() => deleteRole(props.role)}
        onConfirmed={() => props.onRoleDeleted(props.role)}>
        <p>
          Bist du sicher, dass du die Rolle <strong>{props.role.name}</strong> löschen willst?
        </p>
        <p>
          Die Rolle wird aus alle verknüpften Aufgaben entfernt.
        </p>
      </ConfirmModal>
    );
  } else {
    deleteButton = (
      <InfoModal
        visible={props.editable}
        target={onClick => (
          <button
            type="button"
            className="btn btn-compact btn-link"
            onClick={onClick}>
            <Icon name="trash" />
          </button>
        )}
        title="Rolle kann nicht gelöscht werden">
        <p>
          Die Rolle <strong>{props.role.name}</strong> kann nicht gelöscht werden, da sie noch von Workflows verwendet wird,
          die aus dieser Vorlage erstellt wurden.
        </p>
        <p>
          Dupliziere den Workflow, um eine frische Vorlage zu erstellen und die Rollen
          neu zu definieren.
        </p>
      </InfoModal>
    );
  }

  return (
    <Draggable
      key={props.role.id}
      draggableId={props.role.id.toString()}
      index={props.index}
      isDragDisabled={!props.editable}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} className="role-template"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
          )}>
          <div className="role-template-head">
            <div className="role-template-title">
              <Icon name="circle" color={props.role.color} /> {props.role.name}
              {' '}{props.role.project_role_id ? (
                <span className="badge bg-secondary" title="Projektrolle" style={{ fontSize: 10, verticalAlign: 2 }}>PR</span>
              ) : null}
              {props.role.assignment ? (
                <Icon
                  name="user"
                  title={`Automatische Zuteilung: ${props.role.assignment.name}`}
                  style={{ color: '#777', marginLeft: 5 }}
                />
              ) : null}
            </div>
            <div className="role-template-buttons">
              <RoleTemplateModal
                organizationSlug={props.organizationSlug}
                visible={props.editable}
                title="Rolle bearbeiten"
                workflow_id={props.role.workflowId}
                initialRole={props.role}
                onSaved={props.onRoleUpdated}>
                {onClick => (
                  <button className="btn btn-link btn-compact" onClick={onClick}>
                    <Icon name="pencil" />
                  </button>
                )}
              </RoleTemplateModal>
              {deleteButton}
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

RoleTemplate.propTypes = {
  role: PropTypes.object.isRequired,
  onRoleUpdated: PropTypes.func.isRequired,
  onRoleDeleted: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  organizationSlug: PropTypes.string.isRequired,
};

export default RoleTemplate;

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import PhoneSystem from "./PhoneSystem"
import { updateAsync } from "../../helpers/rails_helper";
import Icon from "../shared/Icon";

class Call extends Component {
  handleCallClick = (e, account_id) => {
    e.preventDefault();

    updateAsync(`/phone_numbers/${this.props.phoneNumber.id}/call`, {
      account_id,
    }, 'PUT').then((result) => {
      if (result.error) {
        alert(result.error);
      }
    });
  }

  renderPhones = () => {
    const phoneIds = this.props.phoneIds;
    if (phoneIds && Object.keys(phoneIds).length > 0) {
      return (
        <div className="dropdown-menu">
          {
            Object.entries(phoneIds).map(([phone_system, accounts], i) => {
                return (
                  <Fragment key={phone_system}>
                    {i > 1 && <hr className="dropdown-divider"/>}
                    <span className="dropdown-header">Kunden mit {phone_system} anrufen</span>
                    <PhoneSystem
                      accounts={accounts}
                      numberId={this.props.phoneNumber.id}
                      onCallClick={this.handleCallClick}
                    />
                  </Fragment>
                )
              }
            )
          }
        </div>
      )
    } else {
      return (
        <div className="dropdown-menu p-2 text-muted" style={{ minWidth: "12rem" }}>
          <p className="mb-0">
            Bitte füge deinem Profil ein Telefon hinzu.&nbsp;
            { this.props.phoneCreationPath &&
              <a href={this.props.phoneCreationPath}>
                <Icon name="external-link"/>
              </a>
            }
          </p>
        </div>
      )
    }
  }

  render() {
    if (this.props.hidden) return null;

    return (
      <div className="btn-group d-none d-sm-inline" style={{ marginLeft: 8 }}>
        <button type="button" className="btn btn-xs btn-success dropdown-toggle" data-bs-toggle="dropdown">
          Anrufen
        </button>
        {this.renderPhones()}
      </div>
    );
  }
}

Call.propTypes = {
  phoneIds: PropTypes.object,
  hidden: PropTypes.bool,
  phoneNumber: PropTypes.object.isRequired,
  phoneCreationPath: PropTypes.string,
};

export default Call;

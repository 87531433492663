import React from 'react';
import PropTypes from 'prop-types';

const DataviewSectionForm = (props) => {
  const handleChange = (newSection) => {
    if (props.onChange) {
      props.onChange(newSection);
    }
  };

  const handleTitleChange = (e) => {
    e.preventDefault();

    handleChange(
      Object.assign({}, props.record, { title: e.target.value }),
    );
  };

  const handleKeyDown = (e) => {
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) { // CMD + Enter
      if (props.onSave) {
        props.onSave(e);
      }
    }
  };

  return (
    <div className="mb-3">
      <label className="form-label">Name</label>
      <input
        autoFocus
        className="form-control"
        placeholder="Bitte eingeben"
        autoComplete="off"
        type="text"
        value={props.record.title}
        onChange={handleTitleChange}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

DataviewSectionForm.propTypes = {
  record: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
};

export default DataviewSectionForm;

import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import WorkflowTodo from './WorkflowTodo';

const WorkflowTodos = (props) => {
  const [inactiveVisible, setInactiveVisible] = useState(false);

  const toggleVisible = (e) => {
    e.preventDefault();
    setInactiveVisible(!inactiveVisible);
  };

  const getTodos = () => {
    if (inactiveVisible) return props.todos;

    return props.todos.filter(t => t.active && !t.complete);
  };

  const getHiddenCount = () => {
    return props.todos.filter(t => !t.active || t.complete).length;
  };

  const hiddenText = () => {
    const count = getHiddenCount();

    if (count === 1) {
      return '1 inaktive Aufgabe';
    }

    return `${count} inaktive Aufgaben`;
  };

  const showHiddenButton = () => {
    if (inactiveVisible || getHiddenCount() === 0) return null;

    return (
      <div className="hidden-todos">
        <div className="hidden-todos-ellipsis">
          <div />
          <div />
          <div />
        </div>
        <div>
          <a href="#" onClick={toggleVisible}>{hiddenText()}</a>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <div className="todo-sub-todos workflow-todos">
        {getTodos().map((t) => {
          return <WorkflowTodo todo={t} key={t.id} commentable={props.mayCommentTodos} />;
        })}
        {showHiddenButton()}
      </div>
    </Fragment>
  );
};

WorkflowTodos.propTypes = {
  mayCommentTodos: PropTypes.bool.isRequired,
  todos: PropTypes.array,
};

export default WorkflowTodos;

import React from 'react';

import { components } from 'react-select';

import Icon from '../shared/Icon';

const SingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      <span title={props.data.name}>
        <Icon name="circle" color={props.data.color} /> {props.data.name}
      </span>
    </components.SingleValue>
  );
};

export default SingleValue;

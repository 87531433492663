/* global Math */
const roundFileSize = (fileSize) => {
  if (fileSize > 10) {
    return Math.round(fileSize);
  }

  return Math.round(fileSize * 100) / 100;
};

const FileSize = ({ bytes }) => {
  if (bytes < 1024) return `${bytes} B`;

  const kilobytes = bytes / 1024;

  if (kilobytes < 1024) return `${roundFileSize(kilobytes)} KB`;

  const megabytes = kilobytes / 1024;

  if (megabytes < 1024) return `${roundFileSize(megabytes)} MB`;

  const gigabytes = megabytes / 1024;

  return `${roundFileSize(gigabytes)} GB`;
};

export default FileSize;


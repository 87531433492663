/* eslint-disable class-methods-use-this */

// eslint-disable-next-line import/no-extraneous-dependencies
import 'regenerator-runtime/runtime';
import { Controller } from '@hotwired/stimulus';
import ReactRailsUJS from 'react_ujs';
import { put } from '@rails/request.js';
import { start } from '../helpers/timeago';

export default class extends Controller {
  static targets = ['orderRow', 'checkbox', 'field', 'submit', 'submitWrapper'];

  static values = {
    projectId: Number,
    orderRequestIds: Array,
  };

  connect() {
    start();
    ReactRailsUJS.handleMount();
    this.orderRequestIds = this.orderRequestIdsValue;
    this.toggleOrderRequests();
  }

  disconnect() {
    ReactRailsUJS.handleUnmount();
  }

  toggleOrderRequests() {
    const checkedIds = new Set(
      this.checkboxTargets
        .filter(target => target.checked)
        .map(checkbox => checkbox.dataset.orderRequestId),
    );

    this.orderRequestIds = Array.from(checkedIds);

    this.fieldTargets.forEach(field => {
      // eslint-disable-next-line no-param-reassign
      field.disabled = !checkedIds.has(field.value);
    });

    this.submitTarget.disabled = checkedIds.size === 0;
    this.submitWrapperTarget.title = checkedIds.size === 0 ? 'Eine Bestellanforderung muss ausgewählt sein' : '';
  }

  allocate(event) {
    event.preventDefault();

    if (this.orderRequestIds.length === 0) {
      alert('Es muss mindestens eine Bestellanforderung ausgewählt sein');
      return;
    }

    if (!confirm(`Möchten Sie die Bestellanforderungen wirklich der Bestellung #${event.currentTarget.dataset.orderNumber} zuweisen?`)) {
      return;
    }

    if (this.isLoading) return;
    this.isLoading = true;

    this.orderRowTargets.forEach(orderRow => {
      if (orderRow === event.currentTarget) {
        orderRow.querySelector('.spinner-border').classList.remove('d-none');
      } else {
        orderRow.classList.add('opacity-50');
      }
    });

    const params = new URLSearchParams();
    this.orderRequestIds.forEach(orderRequestId => {
      params.append('order_request_ids[]', orderRequestId);
    });
    params.append('project_id', this.projectIdValue);

    this.#allocate(event.currentTarget.dataset.orderAllocateOrderRequestsPath, params);
  }

  #allocate = async function (orderAllocateOrderRequestsPath, params) {
    const response = await put(`${orderAllocateOrderRequestsPath}?${params.toString()}`, {
      responseKind: 'turbo-stream',
    });

    this.isLoading = false;
    this.orderRowTargets.forEach(orderRow => {
      orderRow.querySelector('.spinner-border').classList.remove('d-none');
    });

    if (response.ok) {
      const orderOrderRequestsModal = document.getElementById('project-order-requests-modal');
      if (orderOrderRequestsModal) {
        orderOrderRequestsModal.dispatchEvent(new Event('close'));
      }
    } else {
      response.text.then(t => alert(JSON.parse(t).errors));
      window.location.reload();
    }
  };
}

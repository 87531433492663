import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import SubTodoTemplateModal from './SubTodoTemplateModal';

import ConfirmModal from '../shared/ConfirmModal';
import Icon from '../shared/Icon';

import { deleteTodo } from '../../helpers/api';

import { Draggable } from 'react-beautiful-dnd';

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  marginTop: 5,
  marginBottom: 0,
  // padding: grid,
  // margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  backgroundColor: isDragging ? 'var(--bs-tertiary-bg)' : 'unset',

  // borderRadius: '4px',
  // styles we need to apply on draggables
  ...draggableStyle,
});

class SubTodoTemplate extends PureComponent {
  render() {
    return (
      <Draggable
        key={this.props.todo.id}
        draggableId={`subtodo-${this.props.todo.id}`}
        index={this.props.index}
        // isDragDisabled={!this.props.editable || this.state.modalOpen}
      >
        {(provided, snapshot) => (
          <div ref={provided.innerRef} className="sub-todo-template"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
            )}>
            {this.props.todo.role ? (
              <div className="sub-todo-template-role">
                <Icon name="circle" color={this.props.todo.role.color} />
              </div>
            ) : null}

            <div className="sub-todo-template-title">
              {this.props.todo.text}
            </div>

            <div className="sub-todo-template-buttons">
              <SubTodoTemplateModal
                organizationSlug={this.props.organizationSlug}
                visible={this.props.editable}
                workflowType={this.props.workflowType}
                title="Unteraufgabe bearbeiten"
                initialSubTodo={this.props.todo}
                onSaved={data => this.props.onTodoUpdated(data.todo)}>
                {onClick => (
                  <button className="btn btn-link btn-compact" onClick={onClick}>
                    <Icon name="pencil" />
                  </button>
                )}
              </SubTodoTemplateModal>
  
              <ConfirmModal
                target={onClick => (
                  <button
                    type="button"
                    className="btn btn-compact btn-link"
                    onClick={onClick}>
                    <Icon name="trash" />
                  </button>
                )}
                title="Unteraufgabe löschen"
                cancelText="abbrechen"
                confirmText="löschen"
                confirmStyle="danger"
                visible={this.props.editable}
                onOpen={() => this.setState({ modalOpen: true })}
                onClose={() => this.setState({ modalOpen: false })}
                onConfirm={() => deleteTodo(this.props.todo)}
                onConfirmed={() => this.props.onTodoDeleted(this.props.todo)}>
                  Bist du sicher, dass du die Unteraufgabe <strong>{this.props.todo.text}</strong> löschen willst?
              </ConfirmModal>
            </div>
          </div>
        )}
      </Draggable>
    );
  }
}

SubTodoTemplate.propTypes = {
  todo: PropTypes.object.isRequired,
  onTodoDeleted: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  workflowType: PropTypes.string.isRequired,
  onTodoUpdated: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default SubTodoTemplate;

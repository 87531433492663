import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import SearchResultLink from './SearchResultLink';

const SearchResultWiki = (props) => {
  return (
    <SearchResultLink {...props}>
      <div>
        <Icon name="book-open" />
      </div>
      <div>
        <div>
          {props.title}
        </div>
      </div>
    </SearchResultLink>
  );
};

SearchResultWiki.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default SearchResultWiki;

import React from 'react';

import { components } from 'react-select';

import Icon from '../shared/Icon';

const SingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      <Icon name="circle" color={props.data.color} /> {props.data.title} {props.data.desc ? `(${props.data.desc})` : ''}
    </components.SingleValue>
  );
};

export default SingleValue;

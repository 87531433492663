import React from 'react';
import PropTypes from 'prop-types';

const Attachment = ({ attachment, onClick, size }) => {
  const handleClick = (e) => {
    e.preventDefault();
    onClick();
  };

  return (
    <div className={`document document-responsive document-${size}`}>
      {attachment.document.has_preview ? (
        <a href="" onClick={handleClick}>
          <img src={attachment.document.thumb} alt="" />
        </a>
      ) : (
        <a href="" title={attachment.document.name} onClick={handleClick}>
          <div className="no-image-content">
            <div>
              <img src={attachment.document.thumb} alt="" />
              <span>
                <br />
                {attachment.document.name}
              </span>
            </div>
          </div>
        </a>
      )}
    </div>
  );
};

Attachment.propTypes = {
  attachment: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.string,
};

Attachment.defaultProps = {
  size: 'responsive',
};

export default Attachment;

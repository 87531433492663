import Validator from './validator';

const roleTemplateValidator = new Validator({
  rules: [
    { field: 'name', validator: 'presence', message: 'Bitte Name der Rolle ausfüllen' },
    { field: 'color', validator: 'presence', message: 'Bitte Farbe ausfüllen' },
  ],
});

export default roleTemplateValidator;

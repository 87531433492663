import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../shared/Icon';
import Dropdown from '../shared/Dropdown';
import DataPointHistory from './DataPointHistory';

class DataPointIconHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activities: [],
      activitiesLoaded: false,
      dataPoint: props.dataPoint,
      dropdownOpen: false,
    };

    this.dropdownRef = React.createRef();

    this.hideHistory = this.hideHistory.bind(this);
    this.handleClickOutsideHistory = this.handleClickOutsideHistory.bind(this);
  }

  loadHistory() {
    if (!this.state.dataPoint?.id) {
      this.setState({
        activitiesLoaded: true,
      });

      return;
    }

    fetch(`/data_points/${this.state.dataPoint.id}/activities.json`).then((response) => {
      return response.json();
    }).then((data) => {
      this.setState({
        activities: data.activities,
        activitiesLoaded: true,
      });
    });
  }
  handleHistoryClick = () => {
    if (!this.state.activitiesLoaded) this.loadHistory();

    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  hideHistory = () => {
    this.setState(() => ({
      dropdownOpen: false,
    }));
  };

  handleClickOutsideHistory = (target) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(target)) {
      this.hideHistory();
    }
  };

  renderTarget = (ref) => {
    // todo: replace with icon + fix placement
    return (
      <button
        className={'btn btn-link btn-history datapoint-actions-hover p-0 ps-1'}
        ref={ref}
        onClick={this.handleHistoryClick}
        onMouseLeave={this.handleMouseLeave}
      >
        <Icon name="clock-rotate-left" className={this.props.iconClassName} regular />
      </button>
    );
  };

  render = () => {
    const blanketStyle = {
      pointerEvents: (this.state.editing || this.state.finished) ? 'initial' : 'none',
      transition: 'opacity 200ms ease-in-out',
      opacity: 0.7,
    };

    return (
      <Dropdown
        blanketStyle={blanketStyle}
        placement="bottom-end"
        isOpen={this.state.dropdownOpen}
        target={this.renderTarget}>
        <div
          style={{
            overflow: 'hidden',
            borderRadius: 5,
          }}>
          <div
            ref={this.dropdownRef}
            style={{
              maxWidth: 495,
              maxHeight: 200,
              overflow: 'auto',
            }}>
            <DataPointHistory
              activitiesLoaded={this.state.activitiesLoaded}
              activities={this.state.activities}
              hideHistory={this.hideHistory}
              onClickOutside={this.handleClickOutsideHistory}
            />
          </div>
        </div>
      </Dropdown>
    );
  };
}

DataPointIconHistory.propTypes = {
  dataPoint: PropTypes.object.isRequired,
  iconClassName: PropTypes.string,
};

DataPointIconHistory.defaultProps = {
  iconClassName: '',
};

export default DataPointIconHistory;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

const contactTypes = [
  { name: "person", displayName: "Privatperson" },
  { name: "company", displayName: "Firma" },
  { name: "spouses", displayName: "Eheleute" },
]

class ContactTypeSelector extends Component {
  render() {
    const contactTypeCheckboxes = contactTypes.map((contactType, key) => {
      const id = `contact_type_${contactType.name}`
      return (
        <div key={key} className="form-check form-check-inline">
          <input
            type="radio"
            name={`${this.props.objectName}[contact_type]`}
            value={contactType.name}
            id={id}
            checked={this.props.contactType === contactType.name}
            onChange={(e) => this.props.onChange('contact_type', e)}
            className="form-check-input"
          />
          <label className="form-check-label" htmlFor={id}>{contactType.displayName}</label>
        </div>
      )
    });
    return (
      <div className="col-sm-9 col-md-5 col-xs-7 pt-1">
        {contactTypeCheckboxes}
      </div>
    )
  }
}

ContactTypeSelector.propTypes = {
  contactType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  objectName: PropTypes.string,
}

export default ContactTypeSelector;

/* global window, document, history */
import jQuery from 'jquery';

/**
 * jQuery Plugin: Sticky Bootstrap Tabs
 *
 * @author Aidan Lister <aidan@php.net>
 * @author Rocco Howard <rocco@hnh.digital>
 */
(function ($) {
  $.fn.stickyTabs = function (options) {
    const context = this;

    const settings = $.extend({
      getHashCallback: hash => hash,
      selectorAttribute: 'href',
      backToTop: false,
      showParentTabs: false,
      showTabUsingClickTrigger: false,
      initialTab: $('li.active > a', context),
    }, options);

    // Search upwards to select all parent tabs.
    const showParentTabs = function (hash) {
      const parentHash = $(`a[${settings.selectorAttribute}="${hash}"]`).parents('.tab-pane').attr('id');
      if (parentHash !== undefined) {
        $(`a[${settings.selectorAttribute}="#${parentHash}"]`).tab('show');
        showParentTabs(`#${parentHash}`);
      }
    };

    const backToTop = function () {
      if (settings.backToTop === true) {
        window.scrollTo(0, 0);
      }
    };

    // Show the tab corresponding with the hash in the URL, or the first tab.
    const showTabFromHash = function () {
      const hash = settings.selectorAttribute === 'href' ? window.location.hash : window.location.hash.substring(1);
      if (hash !== '') {
        const selector = hash ? `a[${settings.selectorAttribute}="${hash}"]` : settings.initialTab;
        if (settings.showParentTabs === true) {
          showParentTabs(hash);
        }
        if (settings.showTabUsingClickTrigger === true) {
          $(selector, context).trigger('click');
        } else {
          $(selector, context).tab('show');
        }
        setTimeout(backToTop, 1);
      }
    };

    // We use pushState if it's available so the page won't jump, otherwise a shim.
    const changeHash = function (hash) {
      if (history && history.pushState) {
        history.pushState(null, null, `${window.location.pathname}${window.location.search}#${hash}`);
      } else {
        const scrollV = document.body.scrollTop;
        const scrollH = document.body.scrollLeft;
        window.location.hash = hash;
        document.body.scrollTop = scrollV;
        document.body.scrollLeft = scrollH;
      }
    };


    // Set the correct tab when the page loads
    $(document).ready(showTabFromHash);

    // Set the correct tab when a user uses their back/forward button
    $(window).on('hashchange', showTabFromHash);

    // Change the URL when tabs are clicked
    $('a', context).on('click', function () {
      const hash = this.href.split('#')[1];
      if (typeof hash !== 'undefined' && hash !== '') {
        const adjustedhash = settings.getHashCallback(hash, this);
        changeHash(adjustedhash);
        setTimeout(backToTop, 1);
      }
    });

    return this;
  };
}(jQuery));

document.addEventListener('turbo:load', () => {
  jQuery('.nav-tabs-sticky').stickyTabs();
})
document.addEventListener('turbo:render', () => {
  jQuery('.nav-tabs-sticky').stickyTabs();
})

/* eslint-disable no-param-reassign */

/*
Github-Style Hovercards

Adds the functionality to create Github-Style Hovercards, which
are Popovers appearing on hover over a DOM-element. Simply add the
attribute 'data-hovercard-url' to any HTML element. The attribute
should contain a URL which will return the HTML content for the
Popover.

The Hovercard may contain interactive elements (Buttons, etc.)

Styling the Popover

The basic styling (position, border, arrow) of
the Hovercard is done via this Plugin. Everything else should be
included in the returned body of the hovercard-url. You can use
the 'hovercard' and 'hovercard-section' classes for a basic start.

Be aware that no Rails layout should be rendered.
*/

import Popper from 'popper.js';

import csrf_token from './csrf_token';

const loadHoverCardHtml = (url) => {
  return fetch(url, {
    method: 'GET',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/html',
      'Accept': 'application/html',
      'X-CSRF-Token': csrf_token(),
    },
    credentials: 'same-origin',
  }).then((result) => {
    if (result.ok) {
      return result.text();
    }

    throw new Error('Error while loading hovercard content');
  });
};

const createHovercardElement = (contentHtml) => {
  const popperContent = document.createElement('div');
  popperContent.classList.add('popper-dropdown');
  // popperContent.style.padding = '8px';

  popperContent.innerHTML = `${contentHtml}<span class="arrow" x-arrow=""></span>`;

  return popperContent;
};

const initHovercardForElement = (element) => {
  let popperInstance;
  let hidingTimer;
  let popperContent;
  let loadingCancelled;

  // mark elements hovercard to be visible
  // so that it is not initiated again
  element.dataset.hovercardVisible = true;

  const handleMouseOut = () => {
    loadingCancelled = true;

    // Delay hiding the hover of split second
    hidingTimer = setTimeout(() => {
      if (popperInstance) {
        document.body.removeChild(popperContent);
        popperInstance.destroy();
        popperInstance = null;
      }
      element.removeEventListener('mouseout', handleMouseOut);
      delete element.dataset.hovercardVisible;
    }, 200);
  };

  element.addEventListener('mouseout', handleMouseOut);

  element.addEventListener('mouseover', () => {
    if (hidingTimer) clearTimeout(hidingTimer);
  });

  loadHoverCardHtml(element.dataset.hovercardUrl).then((htmlString) => {
    if (loadingCancelled) return;

    popperContent = createHovercardElement(htmlString);

    popperContent.addEventListener('mouseover', () => {
      if (hidingTimer) clearTimeout(hidingTimer);
    });

    popperContent.addEventListener('mouseout', handleMouseOut);

    document.body.appendChild(popperContent);

    popperInstance = new Popper(element, popperContent, {
      placement: element.dataset.hovercardPlacement || 'top-start',
      modifiers: {
        flip: {
          enabled: true,
        },
      },
    });
  });
};

const handleMouseOver = (e) => {
  let element;

  // use event target if it has a hovercard URL
  // otherwise try to find closest parent with URL
  if (e.target.dataset.hovercardUrl) {
    element = e.target;
  } else {
    element = e.target.closest('[data-hovercard-url]');
  }

  if (!element) return;

  // don't create hovercard if already visible
  if (element.dataset.hovercardVisible) return;

  // delay hovercard loading for a moment
  const loadingTimer = setTimeout(() => {
    initHovercardForElement(element);
  }, 400);

  // cancel loading timer on mouseout
  const handleMouseOut = () => {
    clearTimeout(loadingTimer);
  };

  element.addEventListener('mouseout', handleMouseOut, { once: true });
};

document.addEventListener('mouseover', handleMouseOver);

/* global fetch */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { defaultStyles, smallStyles } from '../../styles/select_styles';
import ProjectStateOption from './ProjectStateOption';
import SingleProjectStateValue from './SingleProjectStateValue';

import Select, { components } from 'react-select';

import { searchText } from '../../helpers/helpers';

const filterTags = (option, searchTerm) => {
  return searchText(option.data.state, searchTerm);
};

class ProjectStateSelectorDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      options: [],
      loading: false,
    };
  }

  loadOptions = () => {
    this.setState({ loading: true });

    fetch(this.props.optionsUrl, {
      credentials: 'include',
    }).then(response => response.json()).then((data) => {
      this.setState({
        options: data.project_states,
        loading: false,
      });
    });
  }

  handleFocus = () => {
    if (!this.state.options.length) {
      this.loadOptions();
    }
  }

  handleChange = (data) => {
    this.setState({
      value: data,
    });

    if (this.props.onChange) {
      this.props.onChange(data);
    }
  }

  render() {
    return (
      <Select
        components={{
          Option: ProjectStateOption,
          SingleValue: SingleProjectStateValue,
          DropdownIndicator: this.state.loading ? null : components.DropdownIndicator,
        }}
        value={this.state.value}
        openMenuOnFocus
        backspaceRemovesValue={false}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        filterOption={filterTags}
        defaultOptions
        options={this.state.options}
        isLoading={this.state.loading}
        placeholder="Status auswählen"
        loadingPlaceholder="Laden..."
        noOptionsMessage={() => 'Keine Ergebnisse'}
        loadingMessage={() => 'Laden...'}
        styles={this.props.size === 'small' ? smallStyles : defaultStyles}
      />
    );
  }
}

ProjectStateSelectorDropdown.propTypes = {
  optionsUrl: PropTypes.string.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func,
  size: PropTypes.string,
};

export default ProjectStateSelectorDropdown;

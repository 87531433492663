import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';

import AreaMap, { styles } from './AreaMap';

class AreaEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      areas: [],
    };
  }

  handleAreaAdded = (newArea) => {
    this.setState(prevState => ({
      areas: [...prevState.areas, newArea],
    }));
  }

  handleAreaRemoved = (area) => {
    if (area.id) {
      this.setState(prevState => ({
        areas: prevState.areas.map(a => ((a !== area) ? a : ({
          ...a,
          deleted: true,
        }))),
      }));
    } else {
      this.setState(prevState => ({
        areas: prevState.areas.filter(a => a !== area),
      }));
    }

    area.mapPolygon.setMap(null);
  }

  handleAreaChanged = (area) => {
    this.setState(prevState => ({
      areas: prevState.areas.map(a => ((a === area) ? area : a)),
    }));
  }

  handleMouseOver = (polygon) => {
    polygon.setOptions({
      fillColor: styles.POLYGON_FILL_COLOR_HOVER,
      fillOpacity: styles.POLYGON_FILL_OPACITY_HOVER,
    });
  };

  handleMouseOut = (polygon) => {
    polygon.setOptions({
      fillColor: styles.POLYGON_FILL_COLOR,
      fillOpacity: styles.POLYGON_FILL_OPACITY,
    });
  };

  renderInputs() {
    return this.state.areas.map((area) => {
      const points = area.mapPolygon.getPath().getArray();

      const values = [...points, points[0]].map((latLng) => {
        return `${latLng.lng()} ${latLng.lat()}`;
      }).join(', ');

      return (
        <Fragment key={area.id}>
          {area.persisted ? (
            <input
              type="hidden"
              name={`${this.props.areas_field}[${area.id}][id]`}
              value={area.id}
            />
          ) : ''}
          {area.deleted ? (
            <input
              type="hidden"
              name={`${this.props.areas_field}[${area.id}][_destroy]`}
              value="true"
            />
          ) : (
            <input
              type="hidden"
              name={`${this.props.areas_field}[${area.id}][polygon]`}
              value={`POLYGON((${values}))`}
            />
          )}
        </Fragment>
      );
    });
  }

  render() {
    return (
      <div className="row">
        <div className="col-sm-3">
          <h4>{this.props.title}</h4>
          <p>
            {this.props.desc}
          </p>
          <ul className="list-group mb-3">
            {this.state.areas.filter(a => !a.deleted).map((area, i) => {
              return (
                <li key={area.id} className="list-group-item area-list-item" onMouseOver={() => this.handleMouseOver(area.mapPolygon)} onMouseOut={() => this.handleMouseOut(area.mapPolygon)}>
                  <span className="float-end">
                    <button className="btn btn-compact btn-link" onClick={(e) => { e.preventDefault(); this.handleAreaRemoved(area); }}>
                      <Icon name="trash" title="Gebiet entfernen" />
                    </button>
                  </span>
                  <strong>Gebiet {i + 1}</strong>
                </li>
              );
            })}
          </ul>
          {this.renderInputs()}
          <button className="btn btn-primary" type="submit" disabled={this.state.areas.length === 0}>
            Speichern
          </button>
          <a href={this.props.cancel_link} className="btn btn-link">Abbrechen</a>
        </div>
        <div className="col-sm-9">
          <AreaMap
            editable
            initial_areas={this.props.initial_areas}
            postal_addresses={this.props.postal_addresses}
            areaChanged={this.handleAreaChanged}
            areaAdded={this.handleAreaAdded}
            areaRemoved={this.handleAreaRemoved}
          />
        </div>
      </div>
    );
  }
}

AreaEditor.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  cancel_link: PropTypes.string.isRequired,
  areas_field: PropTypes.string.isRequired,
  initial_areas: PropTypes.array,
  postal_addresses: PropTypes.array,
};

export default AreaEditor;

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import UserSelector from './UserSelector';

class UserSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      userId: props.user ? props.user.id : null,
    };
  }

  handleChange = (newUser) => {
    const id = newUser ? newUser.id : "";
    this.props.onChange(id, newUser)
    this.setState({
      user: newUser,
      userId: newUser ? id : null,
    });
  }

  render() {
    const { user, name, ...rest } = this.props;

    return (
      <Fragment>
        <input type="hidden" name={name} value={this.state.userId || ''}/>
        <UserSelector {...rest} value={this.state.user} onChange={this.handleChange}/>
      </Fragment>
    );
  }
}

UserSelect.propTypes = {
  user: PropTypes.object,
  name: PropTypes.string,
  onChange: PropTypes.func
};

UserSelect.defaultProps = {
  onChange: () => {}
};

export default UserSelect;
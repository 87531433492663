import React, { Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import Icon from '../shared/Icon';

import { defaultStyles } from '../../styles/select_styles';

import Select, { components } from 'react-select';

const filterOption = (option, searchTerm) => {
  if (!searchTerm) return true;

  return searchTerm.replace(/ +(?= )/g,'').split(' ').every((term) => {
    return option.data.state.toLowerCase().includes(term.toLowerCase());
  });
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <Icon name="circle" color={props.data.color} /> {props.data.state}
    </components.Option>
  );
};

const SingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      <Icon name="circle" color={props.data.color} /> {props.data.state}
    </components.SingleValue>
  );
};

class ProjectStateSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projectState: this.props.projectState,
    };
  }

  handleChange = (projectState) => {
    this.setState({ projectState });

    if (this.props.onChange) {
      this.props.onChange(projectState);
    }
  }

  renderHiddenInputField() {
    if (this.props.name) {
      return (
        <input
          type="hidden"
          name={this.props.name}
          value={this.state.projectState ? this.state.projectState.id : ''}
        />
      );
    }

    return null;
  }

  render() {
    return (
      <Fragment>
        {this.renderHiddenInputField()}
        <Select
          components={{ Option, SingleValue }}
          isClearable
          isSearchable
          onChange={this.handleChange}
          value={this.state.projectState}
          styles={defaultStyles}
          placeholder={this.props.placeholder}
          filterOption={filterOption}
          noOptionsMessage={() => 'Keine Ergebnisse'}
          options={this.props.projectStateOptions}
        />
      </Fragment>
    );
  }
}

ProjectStateSelect.propTypes = {
  projectState: PropTypes.object,
  projectStateOptions: PropTypes.array.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

ProjectStateSelect.defaultProps = {
  placeholder: 'Status auswählen...',
};

export default ProjectStateSelect;

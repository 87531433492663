import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getAsync, updateAsync } from '../../helpers/rails_helper';
import { subscribe, unsubscribe } from '../../helpers/actioncable_helper';

import ActionDropdown from './ActionDropdown';
import FireActionForm from './FireActionForm';
import DelayForm from './DelayForm';
import ActiveTriggers from './ActiveTriggers';

class ActionButtons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      todo: null,
      roles: [],
      params: {},
      loading: true,
      loadingEffects: false,
      saving: false,
      actionSelected: null,
      delayFormVisible: false,
    };
  }

  componentDidMount() {
    this.loadData();

    this.channel = subscribe({
      channel: 'TodoChangesChannel',
      todo_id: this.props.todoId,
    }, ({ action, todo }) => {
      if (action === 'update') {
        this.setState({
          todo,
        });
      }
    });
  }

  componentWillUnmount() {
    if (this.channel) unsubscribe(this.channel);
  }

  getDefaultParamsForAction = (action) => {
    const effects_attributes = {};

    action.effects.forEach((effect) => {
      if (effect.type === 'Effects::SetRoleEffect') {
        const role = this.state.roles.find(r => r.role_template_id === effect.role_template_id);

        effects_attributes[effect.id] = {
          responsible_gid: role && role.responsible?.gid,
          responsible: role && role.responsible,
        };
      } else if (effect.type === 'Effects::SetDataPointEffect') {
        if (effect.data_point) {
          effects_attributes[effect.id] = {
            content: effect.data_point.value,
            status: effect.data_point.status,
          };
        }
      } else if (['Effects::EmailEffect', 'Effects::ShortMessageEffect'].includes(effect.type)) {
        effects_attributes[effect.id] = {
          do_not_send: false,
        };
      } else if (effect.type === 'Effects::AddProjectNoteEffect') {
        effects_attributes[effect.id] = {
          note_name: effect.default_name,
        };
      }
    });

    return ({
      effects_attributes,
    });
  };

  loadData = () => {
    getAsync(`/workflows/${this.props.workflowId}/roles.json`).then((result) => {
      return getAsync(`/todos/${this.props.todoId}.json`).then((result2) => {
        return Object.assign({}, result, result2);
      });
    }).then((combinedResult) => {
      this.setState({
        loading: false,
        undoing: false,
        todo: combinedResult.todo,
        roles: combinedResult.roles,
        delayFormVisible: false,
        actionSelected: null,
      });
    });
  };

  handleParamsChange = (newParams) => {
    this.setState({ params: newParams });
  };

  handleUndoAction = () => {
    this.setState({ undoing: true });

    updateAsync(`/todos/${this.state.todo.id}/reopen.json`, {}, 'PUT').then((result) => {
      const event = new CustomEvent('todo:activities:new', {
        detail: {
          todoId: this.state.todo.id,
          activities: result.new_activities || [],
        },
      });

      document.dispatchEvent(event);

      this.setState({
        undoing: false,
        todo: result.todo,
        delayFormVisible: false,
        actionSelected: null,
      });
    }).catch((errorResponse) => {
      this.setState({ undoing: false }, () => {
        alert(errorResponse.errors.join('\n'));
      });
    });
  };

  handleActionSelected = (action) => {
    this.setState({
      loadingEffects: true,
    });

    getAsync(`/actions/${action.id}/effects.json`).then(({ effects }) => {
      const newAction = { ...action, effects };

      const params = this.getDefaultParamsForAction(newAction);

      this.setState({
        loading: false,
        loadingEffects: false,
        actionSelected: newAction,
        params,
      });
    });
  };

  handleDelayClick = (e) => {
    e.preventDefault();
    this.setState({
      delayFormVisible: true,
    });
  };

  handleCancelDelayClick = (e) => {
    e.preventDefault();
    this.setState({
      delayFormVisible: false,
    });
  };

  handleCancel = () => {
    this.setState({
      actionSelected: null,
      params: {},
    });
  };

  handleDelay = (values) => {
    this.setState({ saving: true });

    updateAsync(`/todos/${this.state.todo.id}/delay.json`, {
      todo: {
        due: values.due,
      },
      delay_reason: values.reason,
    }, 'PATCH').then((result) => {
      const event = new CustomEvent('todo:activities:new', {
        detail: {
          todoId: this.state.todo.id,
          activities: result.new_activities || [],
        },
      });

      document.dispatchEvent(event);

      this.setState({
        saving: false,
        todo: result.todo,
        delayFormVisible: false,
        actionSelected: null,
      });
    }).catch((errorResponse) => {
      this.setState({ saving: false }, () => {
        alert(errorResponse.errors.join('\n'));
      });
    });
  };

  handleFire = () => {
    const effectsAttributes = {};

    this.setState({ saving: true });

    Object.entries(this.state.params.effects_attributes || []).forEach((a, i) => {
      effectsAttributes[i] = {
        ...a[1],
        id: a[0],
      };
    });

    updateAsync(`/actions/${this.state.actionSelected.id}/fire.json`, {
      action_params: {
        effects_attributes: effectsAttributes,
      },
    }, 'PATCH').then((result) => {
      const event = new CustomEvent('todo:activities:new', {
        detail: {
          todoId: this.state.todo.id,
          activities: result.new_activities || [],
        },
      });

      document.dispatchEvent(event);

      this.setState({
        saving: false,
        todo: result.todo,
        delayFormVisible: false,
        actionSelected: null,
      });
    }).catch((errorResponse) => {
      this.setState({ saving: false }, () => {
        // Catch internal server errors where no error messages are returned.
        if (errorResponse?.errors) {
          alert(errorResponse.errors.join('\n'));
        } else {
          alert("Fehler beim Auslösen der Aktion");
        }
      });
    });
  };

  getFireableActions = () => {
    if (!this.state.todo) return [];

    if (this.state.todo.complete) return [];

    if (this.state.todo.completable) {
      return this.state.todo.actions;
    }

    return this.state.todo.actions.filter(a => !a.complete_todo);
  };

  getActiveTriggers = () => {
    if (!this.state.todo || !this.state.todo.active) return [];

    return this.getFireableActions().filter(a => a.triggers.length).map((action) => {
      return action.triggers.filter(t => !t.triggered_at && !t.cancelled_at);
    }).flat();
  };

  shouldShowActionSelection = () => {
    if (this.state.loading
      || this.state.actionSelected
      || this.state.delayFormVisible
      || !this.state.todo.active
      || this.state.todo.complete
      || !this.props.mayFireActions
    ) return false;

    return true;
  };

  renderInactive() {
    if (this.state.loading) return null;
    if (this.state.todo.active || this.state.todo.complete) return null;

    return (
      <div className="actions-container-section">
        Diese Aufgabe ist noch nicht aktiv.
      </div>
    );
  }

  renderLoading() {
    if (!this.state.loading) return null;

    return (
      <div className="actions-container-section">
        Laden...
      </div>
    );
  }

  renderActionFired() {
    if (this.state.loading || this.state.saving) return null;
    if (!this.state.todo.complete) return null;

    return (
      <div className="actions-container-section actions-container-section-fired">
        <div className="fired-title">
          Diese Aufgabe wurde bereits beantwortet.
        </div>
        {(this.state.todo.resettable && this.props.mayFireActions) ? (
          <div className="undo-action">
            <button
              type="button"
              className={`btn btn-light ${this.state.undoing ? 'btn-loading' : ''}`}
              disabled={this.state.undoing}
              onClick={this.handleUndoAction}>
              rückgängig
            </button>
          </div>
        ) : null}
      </div>
    );
  }

  renderIncompletable() {
    if (this.state.loading) return null;
    if (this.state.todo.completable || this.state.delayFormVisible) return null;

    return (
      <div className="actions-container-section">
        Diese Aufgabe kann erst abgeschlossen werden, wenn alle Unteraufgaben erledigt sind.
      </div>
    );
  }

  renderUnauthorized() {
    if (this.props.mayFireActions) return null;

    return (
      <div className="p-3 text-muted">
        Du hast keine Berechtigung diese Aufgabe abzuschließen.
      </div>
    );
  }

  renderActionSelection() {
    if (!this.shouldShowActionSelection()) return null;

    return (
      <div className="actions-container-section">
        <div>
          <ActionDropdown
            actions={this.getFireableActions()}
            onChange={this.handleActionSelected}
            loadingEffects={this.state.loadingEffects}
            className={this.getActiveTriggers().length ? 'btn-secondary' : 'btn-primary'}
          />
          {(this.state.todo.delayable && this.props.mayFireActions) ? (
            <span style={{ marginLeft: 10 }}>
              oder <a href="#" onClick={this.handleDelayClick}>vertagen</a>
            </span>
          ) : null}
        </div>
      </div>
    );
  }

  renderActionForm() {
    if (!this.state.actionSelected) return null;

    return (
      <FireActionForm
        projectId={this.props.projectId}
        action={this.state.actionSelected}
        roles={this.state.roles}
        params={this.state.params}
        onChange={this.handleParamsChange}
        onCancel={this.handleCancel}
        onFire={this.handleFire}
        organizationSlug={this.props.organizationSlug}
        saving={this.state.saving}
      />
    );
  }

  renderDelayForm() {
    if (!this.state.delayFormVisible) return null;

    return (
      <DelayForm
        onCancel={this.handleCancelDelayClick}
        onDelay={this.handleDelay}
        reasonRequired={this.state.todo.delay_reason_required}
        saving={this.state.saving}
        due={this.state.todo.due}
      />
    );
  }

  render() {
    const classNames = ['actions-container'];

    const activeTriggers = this.getActiveTriggers();

    if (activeTriggers.length) classNames.push('actions-container-active-triggers');

    return (
      <div className={classNames.join(' ')}>
        <ActiveTriggers triggers={this.getActiveTriggers()} actions={this.getFireableActions()} />
        {this.renderIncompletable()}
        {this.renderLoading()}
        {this.renderInactive()}
        {this.renderUnauthorized()}
        {this.renderActionFired()}
        {this.renderActionSelection()}
        {this.renderActionForm()}
        {this.renderDelayForm()}
      </div>
    );
  }
}

ActionButtons.propTypes = {
  projectId: PropTypes.number.isRequired,
  todoId: PropTypes.number.isRequired,
  workflowId: PropTypes.number.isRequired,
  mayFireActions: PropTypes.bool.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default ActionButtons;

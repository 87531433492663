/* global google */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

const styles = {
  POLYGON_FILL_COLOR: '#0066CC',
  POLYGON_FILL_OPACITY: 0.1,
  POLYGON_STROKE_COLOR: '#0066CC',
  POLYGON_STROKE_WEIGHT: 0,
  POLYGON_STROKE_OPACITY: 1,

  POLYGON_FILL_COLOR_HOVER: '#0066CC',
  POLYGON_FILL_OPACITY_HOVER: 0.2,
  POLYGON_STROKE_COLOR_HOVER: '#0066CC',
  POLYGON_STROKE_WEIGHT_HOVER: 2,
  POLYGON_STROKE_OPACITY_HOVER: 1,
};

const mergedPolygonStyles = {
  // strokeColor: styles.POLYGON_STROKE_COLOR,
  strokeOpacity: 0,
  // strokeWeight: styles.POLYGON_STROKE_WEIGHT,
  fillColor: styles.POLYGON_FILL_COLOR,
  fillOpacity: 0.2,
};

class Polygon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      polygon: null,
      drawingComplete: false,
    };
  }

  componentDidMount() {
    if (this.props.map && !this.state.drawingComplete) {
      this.redrawOverlays();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (this.props.map && prevProps.polygon !== this.props.polygon) {
      this.redrawOverlays();
    }
  }

  componentWillUnmount() {
    if (this.state.polygon) {
      console.log('clean up polygin');
      this.state.polygon.setMap(null);
    }
  }

  redrawOverlays = () => {
    const points = [];

    this.props.polygon.forEach((point, i) => {
      points[i] = new google.maps.LatLng(point.lat, point.lon);
    });

    const mapPolygon = new google.maps.Polygon({
      points,
      paths: points,
      ...mergedPolygonStyles,
      map: this.props.map,
    });

    if (this.state.polygon) {
      this.state.polygon.setMap(null);
    }

    this.setState({
      polygon: mapPolygon,
      drawingComplete: true,
    });
  };

  render() {
    return (
      <React.Fragment />
    );
  }
}

Polygon.propTypes = {
  map: PropTypes.object,
  polygon: PropTypes.array.isRequired,
};

export default Polygon;

/* eslint-disable no-param-reassign */

import { Controller } from '@hotwired/stimulus';
import { get, put } from '@rails/request.js';
import ReactRailsUJS from 'react_ujs';

export default class extends Controller {
  static targets = [
    'requestInitButton',
    'assignInitButton',
    'tableCell',
    'rowButton',
    'rowSpacer',
    'requestCheckbox',
    'assignCheckbox',
    'retrieveCheckbox',
    'cancelButton',
    'requestConfirmButton',
    'assignConfirmButton',
    'assignConfirmComponent',
    'reactRoot',
    'text',
  ];

  static values = {
    projectRequestProductsPath: String,
    newOrderRequestAllocationPath: String,
    newOrderRequestRetrievalPath: String,
    newOrderRequestConfirmationPath: String,
    projectId: String,
  };

  connect() {
    this.onChangeRequested();
    this.onChangeAssigned();
    ReactRailsUJS.handleMount();
  }

  // eslint-disable-next-line class-methods-use-this
  disconnect() {
    ReactRailsUJS.handleUnmount();
  }

  initRequest() {
    this.changeMode('request');
    this.requestConfirmButtonTargets.forEach(el => { el.disabled = !this.#checkIfRequested(); });
    this.textTarget.textContent = 'Markierte anforden:';
  }

  onChangeRequested() {
    this.requestConfirmButtonTargets.forEach(el => { el.disabled = !this.#checkIfRequested(); });
  }

  onChangeAssigned() {
    if (this.hasAssignConfirmButtonTarget) {
      this.assignConfirmButtonTarget.disabled = !this.#checkIfAssigned();
    }
  }

  cancel() {
    this.changeMode('default');
    this.textTarget.textContent = 'Aktionen:';
  }

  changeMode(mode) {
    this.rowButtonTargets.concat(this.initButtons).forEach(el => {
      el.hidden = (mode !== 'default');
    });
    this.rowSpacerTargets.concat(this.cancelButtonTargets).forEach(el => {
      el.hidden = (mode === 'default');
    });
    this.tableCellTargets.forEach(el => {
      if (mode === 'default') {
        el.classList.remove('table-secondary');
      } else {
        el.classList.add('table-secondary');
      }
    });
    this.requestCheckboxTargets.concat(this.requestConfirmButtonTargets).forEach(el => {
      el.hidden = (mode !== 'request');
    });
    this.assignCheckboxTargets.concat(this.assignConfirmComponentTargets).forEach(el => {
      el.hidden = (mode !== 'assign');
    });
  }

  requestProducts(event) {
    const params = new URLSearchParams();
    // params.append("project_id", this.projectIdValue)

    if (event.currentTarget.dataset.orderRequestId) {
      params.append('order_request_ids[]', event.currentTarget.dataset.orderRequestId);
    } else {
      this.#getRequested().forEach(el => {
        params.append('order_request_ids[]', el.id);
      });
    }

    put(`${this.projectRequestProductsPathValue}?${params.toString()}`, {
      responseKind: 'turbo-stream',
    });
  }

  #checkIfRequested() {
    return this.#getRequested().length;
  }

  #getRequested() {
    // eslint-disable-next-line max-len
    return this.requestCheckboxTargets.filter(requestCheckboxTarget => requestCheckboxTarget.checked);
  }

  #checkIfAssigned() {
    return this.#getAssigned().length;
  }

  #getAssigned() {
    return this.assignCheckboxTargets.filter(assignCheckboxTarget => assignCheckboxTarget.checked);
  }

  visitNewOrderRequestAllocationPath(event) {
    const params = new URLSearchParams();

    params.append('project_id', this.projectIdValue);
    if (event.currentTarget.dataset.orderRequestId) {
      params.append('order_request_ids[]', event.currentTarget.dataset.orderRequestId);
    } else {
      this.#getAssigned().forEach(el => {
        params.append('order_request_ids[]', el.id);
      });
    }

    get(`${this.newOrderRequestAllocationPathValue}?${params.toString()}`, {
      responseKind: 'turbo-stream',
    });
  }

  visitNewOrderRequestRetrievalPath(event) {
    event.preventDefault();

    const params = new URLSearchParams();

    params.append('project_id', this.projectIdValue);
    if (event.currentTarget.dataset.orderRequestId) {
      params.append('order_request_ids[]', event.currentTarget.dataset.orderRequestId);
    }

    get(`${this.newOrderRequestRetrievalPathValue}?${params.toString()}`, {
      responseKind: 'turbo-stream',
    });
  }

  visitNewOrderRequestConfirmationPath(event) {
    event.preventDefault();

    const params = new URLSearchParams();

    params.append('project_id', this.projectIdValue);
    if (event.currentTarget.dataset.orderRequestId) {
      params.append('order_request_ids[]', event.currentTarget.dataset.orderRequestId);
    }

    get(`${this.newOrderRequestConfirmationPathValue}?${params.toString()}`, {
      responseKind: 'turbo-stream',
    });
  }

  get initButtons() {
    const initButtons = [];
    if (this.hasRequestInitButtonTarget) {
      initButtons.push(this.requestInitButtonTarget);
    }
    if (this.hasAssignInitButtonTarget) {
      initButtons.push(this.assignInitButtonTarget);
    }
    return initButtons;
  }
}

import React from 'react';
import PropTypes from 'prop-types';

import RoleTemplateForm from './RoleTemplateForm';
import RemoteFormModal from '../shared/RemoteFormModal';

import roleTemplateValidator from '../../validators/role_template_validator';
import { saveRole } from '../../helpers/api';

const RoleTemplateModal = props => (
  <RemoteFormModal {...props}
    titleNew="Neue Rolle"
    titleEdit="Rolle bearbeiten"
    validator={roleTemplateValidator}
    form={RoleTemplateForm}
    initialRecord={props.initialRole}
    saveHandler={saveRole}
    allowOverflow
  />
);

RoleTemplateModal.propTypes = {
  initialRole: PropTypes.object.isRequired,
};

export default RoleTemplateModal;

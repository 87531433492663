import React from 'react';
import PropTypes from 'prop-types';

const ComposeErrors = ({ errors }) => {
  if (!errors.length) return null;

  return (
    <div className="alert alert-danger" role="alert" style={{
      marginBottom: 0,
      borderRadius: 0,
      border: '0px'
    }}>
      {errors.map((e) => {
        return (
          <div style={{ padding: '3px 9' }}>{e}</div>
        );
      })}
    </div>
  );
};

ComposeErrors.propTypes = {
  errors: PropTypes.array,
};

export default ComposeErrors;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TimeAgo from '../shared/TimeAgo';

import moment from 'moment';

import Avatar from '../shared/Avatar';
import Icon from '../shared/Icon';

import AttachmentGallery from '../Attachments/AttachmentGallery';

import ConfirmModal from '../shared/ConfirmModal';
import EditCommentModal from '../Comments/EditCommentModal';

import { deleteComment, updateComment } from '../../helpers/api';

class TodoCommentActivity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      attachmentForPreview: null,
    };
  }

  handleDeleteComment = () => {
    deleteComment(this.props.activity.comment).then(({ comment }) => {
      this.props.onChange(Object.assign({}, this.props.activity, {
        comment,
      }));
    });
  }

  handleUpdateComment = (newComment) => {
    return updateComment({
      id: newComment.id,
      body: newComment.body,
    }).then(({ comment }) => {
      this.props.onChange(Object.assign({}, this.props.activity, {
        comment,
      }));
    });
  }

  handleAttachmentsChanged = (newAttachments) => {
    this.setState({ previewVisible: false });
    this.props.onChange(Object.assign({}, this.props.activity, {
      comment: Object.assign({}, this.props.activity.comment, {
        attachments: newAttachments,
      }),
    }));
  }

  mayEditOrDelete = () => {
    return (
      this.props.managerOrAdmin
      || this.props.activity.comment.user_id === this.props.userId
      || this.props.activity.comment.owner_id === this.props.userId
    );
  }

  renderButtons() {
    if (this.props.activity.comment.deleted || !this.mayEditOrDelete()) return null;

    return (
      <div className="comment-buttons">
        <div className="btn-group d-none d-sm-block">
          <EditCommentModal
            comment={this.props.activity.comment}
            onSave={this.handleUpdateComment}
            target={onClick => (
              <a href="#" className="btn btn-light btn-xs" onClick={onClick}>
                <Icon name="pencil" />
              </a>
            )} />
          <ConfirmModal
            target={onClick => (
              <a href="#" className="btn btn-light btn-xs" onClick={onClick}>
                <Icon name="trash" />
              </a>
            )}
            title="Kommentar löschen"
            cancelText="abbrechen"
            confirmText="löschen"
            confirmStyle="danger"
            onConfirmed={this.handleDeleteComment}>
            Bist du sicher, dass du den Kommentar löschen willst?
          </ConfirmModal>
        </div>
      </div>
    );
  }

  render() {
    const animated = this.props.activity.initial ? '' : 'todo-activity-animated';

    return (
      <div className={`todo-activity-feed-item todo-activity ${animated}`}>
        <div className="comment">
          <div className="comment-avatar" data-hovercard-url={`/users/${this.props.activity.comment.user.id}/hovercard`}>
            <Avatar account={this.props.activity.comment.user} size="medium" />
          </div>
          <div className="line" />
          <div className="line-short" />
          <div className="content">
            <div className="comment-info">
              <Avatar account={this.props.activity.comment.user} size="inter" />
              <div className="comment-commenter">
                <span data-hovercard-url={`/users/${this.props.activity.comment.user.id}/hovercard`}>
                  {this.props.activity.comment.user.fullname}
                </span> <TimeAgo
                  title={moment(this.props.activity.comment.created_at).format('DD.MM.YYYY HH:mm [Uhr]')}
                  datetime={this.props.activity.comment.created_at}
                  locale="de"
                />
                {this.props.activity.comment.edited_at ? (
                  <span> (bearbeitet)</span>
                ) : null}
              </div>
              {this.renderButtons()}
            </div>

            {this.props.activity.comment.deleted ? (
              <div className="comment-body">
                <p style={{ color: '#999' }}><i>Kommentar wurde gelöscht.</i></p>
              </div>
            ) : (
              <div
                className="comment-body"
                dangerouslySetInnerHTML={{
                __html: this.props.activity.comment.body_formatted,
                }}
              />
            )}
            <AttachmentGallery
              userId={this.props.userId}
              managerOrAdmin={this.props.managerOrAdmin}
              attachments={this.props.activity.comment.attachments}
              onChange={this.handleAttachmentsChanged}
            />
          </div>
        </div>
      </div>
    );
  }
}

TodoCommentActivity.propTypes = {
  activity: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  userId: PropTypes.number.isRequired,
  managerOrAdmin: PropTypes.bool.isRequired,
};

export default TodoCommentActivity;

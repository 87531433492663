/* global FormData */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SubTodo from './SubTodo';
import NewSubTodo from './NewSubTodo';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { subscribe, unsubscribe } from '../../helpers/actioncable_helper';

import { reorder } from '../../helpers/helpers';

import { updateAsync } from '../../helpers/rails_helper';

class SubTodos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      todos: props.todos,
      parentTodo: props.parentTodo,
    };
  }

  componentDidMount() {
    if (this.state.parentTodo) {
      this.todoChangesChannel = subscribe({
        channel: 'TodoChangesChannel',
        todo_id: this.state.parentTodo.id,
      }, ({ todo }) => {
        this.setState({ parentTodo: todo });
      });
    }

    if (this.state.parentTodo) {
      this.newTodoChannel = subscribe({
        channel: 'NewSubTodoChannel',
        parent_todo_id: this.props.parentTodo.id,
      }, ({ todo }) => {
        this.handleAdd(todo);
      });
    } else {
      this.newTodoChannel = subscribe({
        channel: 'NewWorkflowTodoChannel',
        workflow_id: this.props.workflowId,
      }, ({ todo }) => {
        this.handleAdd(todo);
      });
    }
  }

  componentWillUnmount() {
    if (this.todoChangesChannel) {
      unsubscribe(this.todoChangesChannel);
    }

    if (this.newTodoChannel) {
      unsubscribe(this.newTodoChannel);
    }
  }

  handleAdd = (todo) => {
    if (!this.state.todos.some(t => t.id === todo.id)) {
      this.setState({
        todos: [...this.state.todos, todo],
      });
    }
  };

  handleRemove = (todo) => {
    this.setState({
      todos: this.state.todos.filter(t => t.id !== todo.id),
    });
  };

  handleDragEnd = (result) => {
    if (result.source.index !== result.destination.index) {
      const data = new FormData();
      data.append('position', result.destination.index + 1);

      updateAsync(`/todos/${result.draggableId}/sort`, Object.fromEntries(data), "PATCH")

      const todos = reorder(
        this.state.todos,
        result.source.index,
        result.destination.index,
      );

      this.setState({ todos });
    }
  };

  shouldShowNew = () => {
    return this.props.mayAddTodos && (!this.state.parentTodo || !this.state.parentTodo.complete);
  };

  renderTodos() {
    return this.state.todos.map((todo, i) => {
      return (
        <SubTodo
          key={todo.id}
          initialTodo={todo}
          index={i}
          onRemove={this.handleRemove}
          editable={this.props.mayEditTodos}
          sortable={this.props.maySortTodos}
          deletable={this.props.mayEditTodos}
          completable={this.props.mayCompleteAllTodos}
          commentable={this.props.mayCommentTodos}
          responsibleOptionsPath={this.props.responsibleOptionsPath}
          currentUserId={this.props.currentUserId}
        />
      );
    });
  }

  renderNew() {
    if (this.shouldShowNew()) {
      return (
        <NewSubTodo
          first={!this.state.todos.length}
          onAdd={this.handleAdd}
          workflowId={this.props.workflowId}
          responsibleOptionsPath={this.props.responsibleOptionsPath}
          parentTodo={this.props.parentTodo}
        />
      );
    }

    return null;
  }

  render() {
    if (!this.shouldShowNew() && !this.state.todos.length) return null;
    if (this.props.parentTodo && this.props.parentTodo.triggers_count) return null;

    return (
      <div className="todo-sub-todos">
        <DragDropContext onDragEnd={this.handleDragEnd}>
          <Droppable droppableId="droppable" isDragDisabled>
            {(provided, snapshot) => (
              <div ref={provided.innerRef}>
                {this.renderTodos()}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {this.renderNew()}
      </div>
    );
  }
}

SubTodos.propTypes = {
  todos: PropTypes.array.isRequired,
  workflowId: PropTypes.number.isRequired,
  parentTodo: PropTypes.object,
  mayAddTodos: PropTypes.bool,
  mayEditTodos: PropTypes.bool,
  maySortTodos: PropTypes.bool,
  mayCompleteAllTodos: PropTypes.bool,
  mayCommentTodos: PropTypes.bool,
  currentUserId: PropTypes.number.isRequired,
  responsibleOptionsPath: PropTypes.string.isRequired,
};

export default SubTodos;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Role from './Role';

class Roles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inactiveVisible: false,
    };
  }

  getInactiveRoles = () => {
    return this.props.roles.filter((r) => {
      return this.props.todos.filter((t) => {
        return t.role_id === r.id;
      }).every((t) => {
        return !t.active || t.complete;
      });
    });
  };

  getActiveRoles = () => {
    return this.props.roles.filter((r) => {
      return this.props.todos.some((t) => {
        return t.active && !t.complete && t.role_id === r.id;
      });
    });
  };

  getRolesToDisplay = () => {
    if (this.state.inactiveVisible) return this.props.roles;

    return this.getActiveRoles();
  };

  toggleInactive = (e) => {
    e.preventDefault();

    this.setState({
      inactiveVisible: true,
    });
  };

  renderRoles() {
    return this.getRolesToDisplay().map((r) => {
      return (
        <Role
          organizationSlug={this.props.organizationSlug}
          initialRole={r}
          key={r.id}
          editable={this.props.mayEditRoles}
          workflowId={this.props.workflowId} />
      );
    });
  }

  renderButtonText = (count) => {
    if (count === 1) {
      return '1 inaktive Rolle';
    }

    return `${count} inaktive Rollen`;
  };

  renderInactiveButton() {
    if (this.state.inactiveVisible) return null;
    if (!this.props.roles.length) return null;

    const inactiveRolesCount = this.getInactiveRoles().length;

    if (inactiveRolesCount > 0) {
      return (
        <div className="hidden-roles">
          <a href="#" onClick={this.toggleInactive}>
            {this.renderButtonText(inactiveRolesCount)}
          </a>
        </div>
      );
    }

    return null;
  }

  render() {
    if (!this.props.roles.length) return null;

    return (
      <div className="workflow-side d-none d-md-block">
        <div className="workflow-side-container">
          <div className="roles-list">
            {this.renderRoles()}
            {this.renderInactiveButton()}
          </div>
        </div>
      </div>
    );
  }
}


Roles.propTypes = {
  roles: PropTypes.array,
  todos: PropTypes.array,
  mayEditRoles: PropTypes.bool,
  organizationSlug: PropTypes.string.isRequired,
  workflowId: PropTypes.number.isRequired,
};

Roles.defaultProps = {
  roles: [],
};

export default Roles;

import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import SearchResultLink from './SearchResultLink';

const SearchResultUser = (props) => {
  return (
    <SearchResultLink {...props}>
      <div>
        <Icon name="user" />
      </div>
      <div>
        <div>
          <img src={props.avatar}
            alt={props.fullname}
            className="avatar avatar-small" /> {props.fullname}
        </div>
      </div>
    </SearchResultLink>
  );
};

SearchResultUser.propTypes = {
  fullname: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  selected: PropTypes.bool,
};

export default SearchResultUser;

import React from 'react';
import PropTypes from 'prop-types';

import EffectTemplateForm from './EffectTemplateForm';
import RemoteFormModal from '../shared/RemoteFormModal';

import effectTemplateValidator from '../../validators/effect_template_validator';
import { saveEffect } from '../../helpers/api';

const EffectTemplateModal = props => (
  <RemoteFormModal
    {...props}
    allowOverflow={false}
    titleNew="Neuer Effekt"
    titleEdit="Effekt bearbeiten"
    validator={effectTemplateValidator}
    form={EffectTemplateForm}
    initialRecord={props.initialEffect}
    saveHandler={saveEffect}
  />
);

EffectTemplateModal.propTypes = {
  initialEffect: PropTypes.object.isRequired,
};

export default EffectTemplateModal;

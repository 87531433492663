import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { subscribe, unsubscribe } from '../../helpers/actioncable_helper';

class TodoBadge extends Component {
  constructor(props) {
    super(props);
    this.state = { count: props.count };
  }

  componentDidMount() {
    this.todosCountChangesChannel = subscribe({
      channel: 'UserOverdueTodosCountChannel',
    }, ({ count }) => {
      this.setState({ count });
    });

    global.s = this.todosCountChangesChannel;
  }

  componentWillUnmount() {
    unsubscribe(this.todosCountChangesChannel);
  }

  render() {
    if (this.state.count > 0) {
      return (
        <span className="badge rounded-pill bg-danger">
          {this.state.count}
        </span>
      );
    }

    return null;
  }
}

TodoBadge.propTypes = {
  count: PropTypes.number.isRequired,
};

export default TodoBadge;

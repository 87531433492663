/* global document */
/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/webpacker and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import $ from "expose-loader?exposes=$,jQuery!jquery";

// Turbo vor ReactRailsUJS laden
import "@hotwired/turbo-rails"

// Theme Switcher (Light/Dark Model)
import "../initializers/theme_switcher";

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

const application = Application.start()
// Configure Stimulus development experience
application.debug = process.env.NODE_ENV !== "production"
window.Stimulus = application

const context = require.context("../controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

import "../initializers/react_rails_ujs"

// https://github.com/rails/rails/tree/master/actionview/app/assets/javascripts
import Rails from 'rails-ujs'
Rails.start()

require("@popperjs/core")

import { Dropdown, Tooltip, Popover, Collapse, Modal } from "bootstrap"

require("../stylesheets/custom/variables.scss")

require("../stylesheets/application.scss")

import '../initializers/auto_numeric';
import '../initializers/sticky_tabs';

import '../initializers/sortable';
import '../initializers/intersection_spy';
import '../initializers/timeago';
import '../initializers/due';
import '../initializers/autosize';
import '../initializers/avatar_upload';
import '../initializers/projects';
import '../initializers/dynamic_fields';
import '../initializers/disable_buttons';
import '../initializers/menu';
import '../initializers/contacts';
import '../initializers/offers';
import '../initializers/trix';
import '../initializers/maps';
import '../initializers/hovercards';

import '../helpers/flash_helper';

import 'chartkick';
import 'chart.js';

import './pagy.js.erb'

// Bilder importieren
import '../images/index.js.erb';

// Stylesheets importieren
import '../stylesheets/index';

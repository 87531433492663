import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Modal from '../shared/Modal';
import TextArea from '../shared/TextArea';

class EditCommentModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      saving: false,
      body: props.comment.body,
    };
  }

  handleClick = (e) => {
    e.preventDefault();
    this.setState({ visible: true });
  }

  handleClose = () => {
    this.setState({
      visible: false,
    });
  }

  handleClosed = () => {
    this.setState({
      body: this.props.comment.body,
      saving: false,
    });
  }

  handleSaveClick = () => {
    if (!this.state.body.trim()) return false;

    this.setState({ saving: true });

    return this.props.onSave({
      id: this.props.comment.id,
      body: this.state.body,
    }).then(() => {
      this.setState({
        visible: false,
      });
    });
  }

  handleKeyDown= (e) => {
    // cmd + enter
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      this.handleSaveClick();
    }
  }

  handleBodyChange = (e) => {
    this.setState({ body: e.target.value });
  }

  renderSaveButton() {
    return (
      <button
        disabled={!this.state.body.trim()}
        className={[
          'btn btn-primary',
          (this.state.saving ? 'btn-loading' : ''),
        ].join(' ')}
        onClick={this.handleSaveClick}>
        Speichern
      </button>
    );
  }

  render() {
    return (
      <Fragment>
        {this.props.target(this.handleClick)}
        <Modal
          onClose={this.handleClose}
          onClosed={this.handleClosed}
          visible={this.state.visible}>
          <div className="react-modal-header">
            <h4>Kommentar bearbeiten</h4>
          </div>
          <div className="react-modal-content">
            <TextArea
              className="form-control"
              autoFocus
              autoSize
              rows={3}
              value={this.state.body}
              onChange={this.handleBodyChange}
              onKeyDown={this.handleKeyDown}
            />
          </div>

          <div className="react-modal-footer">
            <div className="text-muted" />
            <div>
              <button
                className="btn btn-light"
                onClick={this.handleClose}
                disabled={this.state.saving}>
                Abbrechen
              </button>{' '}
              {this.renderSaveButton()}
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

EditCommentModal.propTypes = {
  comment: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  target: PropTypes.func.isRequired,
};

export default EditCommentModal;

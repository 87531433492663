import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Popper from 'popper.js';

class Tooltip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    this.popperInstance = new Popper(this.target, this.popper, {
      placement: this.props.placement,
    });
  }

  componentDidUpdate() {
    if (this.popperInstance) {
      this.popperInstance.scheduleUpdate();
    }
  }

  componentWillUnmount() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
    }
  }

  handleMouseEnter = () => {
    this.setState({ isOpen: true });
  };

  handleMouseOut = () => {
    this.setState({ isOpen: false });
  };

  renderPopper() {
    return (
      <div>
        {this.props.title}
      </div>
    );
  }

  render() {
    const popperStyle = {
      opacity: (this.state.isOpen && this.props.enabled) ? 1 : 0,
    };

    if (!this.props.enabled) {
      popperStyle.display = 'none';
    }

    popperStyle.maxWidth = '70vw';
    popperStyle.whiteSpace = 'normal';

    return (
      <div style={{ display: 'inline-block' }} onMouseOver={this.handleMouseEnter} onMouseOut={this.handleMouseOut}>
        <div style={{ display: 'inline-block' }} ref={e => (this.target = e)}>
          {this.props.children}
        </div>
        <span ref={e => (this.popper = e)} className={this.props.className} style={popperStyle}>
          {this.props.title}
          <div className="arrow" x-arrow="" />
        </span>
      </div>
    );
  }
}

Tooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placement: PropTypes.string,
  className: PropTypes.string,
  enabled: PropTypes.bool,
  children: PropTypes.node,
};

Tooltip.defaultProps = {
  placement: 'bottom',
  className: 'popper-tooltip',
  enabled: true,
};

export default Tooltip;

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';

import styles from '../../styles/select_styles';

class CreatableSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: props.options.split('|', -1).map(o => ({
        value: o,
        label: o,
      })),
    };

    if (props.value) {
      this.state.value = {
        value: props.value,
        label: props.value,
      };
    }
  }

  handleChange = (newValue, actionMeta) => {
    this.setState({
      value: newValue,
    });

    if (this.props.onChange) {
      if (newValue) {
        this.props.onChange(newValue.value);
      } else {
        this.props.onChange('');
      }
    }
  };

  handleInputChange = (inputValue, actionMeta) => {

  }

  formatCreateLabel(value) {
    return (
      <Fragment>
        <strong>{value}</strong> erstellen
      </Fragment>
    );
  }

  render() {
    return (
      <CreatableSelect
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
        }}
        isClearable
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        options={this.state.options}
        value={this.state.value}
        styles={styles.defaultStyles}
        formatCreateLabel={this.formatCreateLabel}
        placeholder={this.props.placeholder || 'auswählen'}
      />
    );
  }
}

CreatableSelector.propTypes = {
  options: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

export default CreatableSelector;

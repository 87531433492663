import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '../shared/Modal';
import TimeAgo from '../shared/TimeAgo';
import Icon from '../shared/Icon';

import DocumentPreview from './DocumentPreview';

import NavButtons from './NavButtons';

class AttachmentModal extends Component {
  handleCloseClick = (e) => {
    e.preventDefault();
    this.props.onClose();
  };

  render() {
    if (!this.props.attachment) return null;

    const file_url = this.props.attachment.document.file_url;

    return (
      <Modal
        size="full"
        mobileSize="full"
        visible={this.props.visible}
        onClosed={this.props.onClosed}
        onClose={this.props.onClose}>
        <div className="attachment-modal">
          <div className="document-preview">
            <div className="document-mobile-header d-md-none">
              <button type="button" className="btn-close btn-close-white attachment-close" onClick={this.handleCloseClick} aria-label="Close" />
              <h5 className="document-title">{this.props.attachment.document.name}</h5>
            </div>
            <DocumentPreview document={this.props.attachment.document} />
            <div className="mobile-download d-md-none">
              {file_url && (
                <a href={file_url} target="_blank" rel="noreferrer" >
                  <Icon name="eye" /> Ansehen
                </a>)}
              {' '}
              {this.props.attachment.document.download_url && (
                <a href={this.props.attachment.document.download_url} target="_blank" rel="noreferrer">
                  <Icon name="download" /> Download
                </a>)}
            </div>
          </div>
          <div className="document-side">
            <div className="document-info">
              <button type="button" className="btn-close attachment-close" onClick={this.handleCloseClick} aria-label="Close" />
              <h5 className="document-title">{this.props.attachment.document.name}</h5>
              <div className="document-info-content">
                <div className="text-muted">
                  <ul className="fa-ul user-info-ul">
                    {this.props.attachment.created_at && (
                      <li>
                        <i className="fa-li fas fa-clock" />
                        <TimeAgo
                          datetime={this.props.attachment.created_at}
                          locale="de"
                        /> hochgeladen
                      </li>)}

                    {this.props.attachment.owner && (
                      <li>
                        <i className="fa-li fas fa-user" />von {this.props.attachment.owner.fullname}
                      </li>
                    )}
                    {this.props.attachment.document.file_size_human && (
                      <li>
                        <i className="fa-li fas fa-database" />{this.props.attachment.document.file_size_human}
                      </li>
                    )}
                  </ul>
                </div>

                <p>
                  {this.props.attachment.document.download_url && (
                    <a href={this.props.attachment.document.download_url} className="btn btn-primary">
                      <Icon name="download" /> Download
                    </a>
                  )}
                </p>
                <p>
                  {file_url && (
                    <a href={file_url} target="_blank" rel="noreferrer">
                      In neuem Tab öffnen <Icon name="arrow-up-right-from-square" />
                    </a>
                  )}{' '}
                </p>
              </div>
            </div>
            <NavButtons
              onNext={this.props.onNext}
              onPrevious={this.props.onPrevious}
              currentPosition={this.props.currentPosition}
              totalCount={this.props.totalCount}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

AttachmentModal.propTypes = {
  visible: PropTypes.bool,
  attachment: PropTypes.object,
  onClose: PropTypes.func,
  onClosed: PropTypes.func,
  onDeleted: PropTypes.func,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  currentPosition: PropTypes.number,
  totalCount: PropTypes.number,
};

export default AttachmentModal;

import React from 'react';
import PropTypes from 'prop-types';

import NumericInput from '../../shared/NumericInput';

import { autoNumericOptionsInteger } from '../../../config/auto_numeric_options';

const IntegerDataPointInput = ({ onChange, onSave, value, saving, autoFocus }) => {
  const handleChange = (e, v) => {
    onChange(v);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && onSave) {
      onSave();
    }
  };

  return (
    <NumericInput
      value={value}
      disabled={saving}
      className="form-control"
      onChange={handleChange}
      options={autoNumericOptionsInteger}
      onKeyDown={handleKeyDown}
      autoFocus={autoFocus}
      placeholder="Bitte ausfüllen"
    />
  );
};

IntegerDataPointInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  saving: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

export default IntegerDataPointInput;

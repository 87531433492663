import React from 'react';
import PropTypes from 'prop-types';

import DataPointTemplateSelector from '../../shared/DataPointTemplateSelector';
import DataPointOptionSelector from '../DataPointOptionSelector';

class SetAutomaticDataPointEffectForm extends React.Component {
  handleDataPointTemplateChange = (newDataPointTemplate) => {
    this.props.onChange(Object.assign({}, this.props.record, {
      data_point_template_id: newDataPointTemplate.id,
      data_point_template: newDataPointTemplate,
    }));
  };

  render() {
    return (
      <>
        <div className="mb-3">
          <label className="form-label">Datenpunkt</label>
          <DataPointTemplateSelector
            value={this.props.record.data_point_template}
            onChange={this.handleDataPointTemplateChange}
            allowedTypes={[
              'DataPoint::DateDataPoint',
              'DataPoint::DateTimeDataPoint',
            ]}
            optionsUrl={`/${this.props.organizationSlug}/data_point_template_options.json`}
          />
        </div>
      </>
    );
  }
}

SetAutomaticDataPointEffectForm.propTypes = {
  record: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default SetAutomaticDataPointEffectForm;

import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';

import ResponsibleSelector from '../shared/ResponsibleSelector';
import DueSelector from './DueSelector';

import DataPointTemplateSelector from '../shared/DataPointTemplateSelector';
import CraftSelector from '../Crafts/CraftSelector';
import CraftAppointmentTypeSelector from '../AppointmentType/CraftAppointmentTypeSelector';

const offsetTranslation = {
  default: 'hours_to_due',
  datapoint: 'due_data_point_offset',
  craft_appointment_type: 'due_craft_appointment_type_offset',
};

class SubTodoTemplateForm extends Component {
  constructor(props) {
    super(props);

    let choice;
    if (this.props.record.due_data_point_template_id) {
      choice = 'datapoint';
    } else if (this.props.record.due_craft_appointment_type_id) {
      choice = 'craft_appointment_type';
    } else if (this.props.record.hours_to_due) {
      choice = 'default';
    } else {
      choice = 'none';
    }

    const craftAppointmentType = this.props.record.due_craft_appointment_type;

    this.state = {
      dueChoice: choice,
      craft: craftAppointmentType?.craft,
      dueCraftAppointmentType: craftAppointmentType,
      dueOffset: offsetTranslation[choice] ? this.props.record[offsetTranslation[choice]] : null,
    };
  }

  changeDueOffset = (value, dueChoice) => {
    if (dueChoice === 'none') return;

    const offset = offsetTranslation[dueChoice];

    this.handleChange(
      Object.assign({}, this.props.record, {
        [offset]: value || 0,
      }),
    );
  };

  handleChange = (newTodo) => {
    if (this.props.onChange) {
      this.props.onChange(newTodo);
    }
  };

  handleTextChange = (e) => {
    e.preventDefault();

    this.handleChange(
      Object.assign({}, this.props.record, { text: e.target.value }),
    );
  };

  handleRoleChange = (role) => {
    this.handleChange(
      Object.assign({}, this.props.record, {
        role,
        responsible_gid: role?.gid,
      }),
    );
  };

  handleDueChoiceChange = (choice) => {
    // todo: find out why assign is not working normally here
    this.handleChange(
      Object.assign(this.props.record, {
        hours_to_due: 0,
        due_craft_appointment_type_offset: 0,
        due_data_point_offset: 0,
        due_data_point_template: choice === 'datapoint' ? this.state.dueDatapointTemplate : null,
        due_data_point_template_id: choice === 'datapoint' ? (this.state.dueDatapointTemplate && this.state.dueDatapointTemplate.id) : null,
        due_craft_appointment_type: choice === 'craft_appointment_type' ? this.state.dueCraftAppointmentType : null,
        due_craft_appointment_type_id: choice === 'craft_appointment_type' ? (this.state.dueCraftAppointmentType?.value) : null,
      }),
    );
    this.setState({ dueChoice: choice });
    this.changeDueOffset(this.state.dueOffset, choice);
  };

  handleDueDataPointTemplateChange = (data_point_template) => {
    this.handleChange(
      Object.assign({}, this.props.record, {
        due_data_point_template: data_point_template,
        due_data_point_template_id: data_point_template && data_point_template.id,
      }),
    );
  };

  handleCraftChange = (craft) => {
    this.setState({ craft, dueCraftAppointmentType: '' });
    this.handleChange(
      Object.assign({}, this.props.record, {
        due_craft_appointment_type: null,
        due_craft_appointment_type_id: null,
      }),
    );
  };

  handleDueCraftAppointmentTypeChange = (craft_appointment_type) => {
    this.setState({ dueCraftAppointmentType: craft_appointment_type });
    this.handleChange(
      Object.assign({}, this.props.record, {
        due_craft_appointment_type: craft_appointment_type,
        due_craft_appointment_type_id: craft_appointment_type?.id,
      }),
    );
  };

  handleDueOffsetChange = (value) => {
    this.setState({ dueOffset: value });
    this.changeDueOffset(value, this.state.dueChoice);
  };

  handleKeyDown = (e) => {
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) { // CMD + Enter
      if (this.props.onSave) {
        this.props.onSave(e);
      }
    }
  };

  renderReasonRequired() {
    if (!this.props.record.allow_delay) return null;

    return (
      <div className="form-check">
        <input
          type="checkbox"
          checked={this.props.record.delay_reason_required}
          onChange={e => this.handleCheckboxChange(e, 'delay_reason_required')}
          id="delay_reason_required"
          className="form-check-input"
        />
        <label htmlFor="delay_reason_required" className="form-check-label">
          Grund erforderlich
          <br /><small className="text-muted">
            Beim Vertagen muss eine Begründung angegeben werden.
          </small>
        </label>
      </div>
    );
  }

  renderDelayAllowed() {
    return (
      <div className="form-check">
        <input
          type="checkbox"
          checked={this.props.record.allow_delay}
          onChange={e => this.handleCheckboxChange(e, 'allow_delay')}
          id="allow_delay"
          className="form-check-input"
        />
        <label htmlFor="allow_delay" className="form-check-label">
          Vertagen erlauben
          <br /><small className="text-muted">
            Benutzer können die Aufgabe vertagen bzw. die Fälligkeit verändern.
          </small>
        </label>
      </div>
    );
  }

  renderOptions() {
    if (this.props.workflowType !== 'workflow') return null;

    return (
      <>
        <div className="form-check">
          <input
            type="checkbox"
            checked={this.props.record.initially_active}
            onChange={e => this.handleCheckboxChange(e, 'initially_active')}
            id="initially_active"
            className="form-check-input"
          />
          <label htmlFor="initially_active" className="form-check-label">
            Zu Beginn aktiv
            <br /><small className="text-muted">
              Die Aufgabe direkt nach Erstellung des Workflows aktivieren.
            </small>
          </label>
        </div>

        <div className="form-check">
          <input
            type="checkbox"
            checked={this.props.record.synchronize_todos}
            onChange={e => this.handleCheckboxChange(e, 'synchronize_todos')}
            id="synchronize_todos"
            className="form-check-input"
          />
          <label htmlFor="synchronize_todos" className="form-check-label">
            Synchronisieren
            <br /><small className="text-muted">
              Diese Aufgabe erst dann aktivieren, wenn alle Vorgängeraufgaben abgeschlossen wurden.
            </small>
          </label>
        </div>

        {this.renderDelayAllowed()}
        {this.renderReasonRequired()}
      </>
    );
  }

  renderDueChoice() {
    return (
      <div className="col-12 mb-3">
        <div className="btn-group w-100" role="group">
          <input
            type="radio"
            id="none"
            className="btn-check col-4"
            autoComplete="off"
            checked={this.state.dueChoice === 'none'}
            onChange={() => this.handleDueChoiceChange('none')}
          />
          <label className="btn btn-outline-primary" htmlFor="none">
            Keine
          </label>

          <input
            type="radio"
            id="default"
            className="btn-check col-4"
            autoComplete="off"
            checked={this.state.dueChoice === 'default'}
            onChange={() => this.handleDueChoiceChange('default')}
          />
          <label className="btn btn-outline-primary" htmlFor="default">
            Standard
          </label>

          <input
            type="radio"
            id="datapoint"
            className="btn-check"
            autoComplete="off"
            checked={this.state.dueChoice === 'datapoint'}
            onChange={() => this.handleDueChoiceChange('datapoint')}
          />
          <label className="btn btn-outline-primary" htmlFor="datapoint">
            aus Datenpunkt
          </label>

          <input
            type="radio"
            id="craft_appointment_type"
            className="btn-check"
            autoComplete="off"
            checked={this.state.dueChoice === 'craft_appointment_type'}
            onChange={() => this.handleDueChoiceChange('craft_appointment_type')}
          />
          <label className="btn btn-outline-primary" htmlFor="craft_appointment_type">
            aus Installationstermin
          </label>
        </div>
      </div>
    );
  }

  renderDueDatapoint() {
    return (
      <div className="mb-3 col-12">
        <DataPointTemplateSelector
          isClearable
          value={this.props.record.due_data_point_template}
          onChange={this.handleDueDataPointTemplateChange}
          allowedTypes={['DataPoint::DateDataPoint', 'DataPoint::DateTimeDataPoint']}
          optionsUrl={`/${this.props.organizationSlug}/data_point_template_options.json`}
          placeholder="Datenpunkt"
        />
      </div>
    );
  }

  renderDueAppointmentType() {
    return (
      <div className="col-12 mb-3 ms-0 row">
        <div className="col-6 ps-0 pe-1">
          <CraftSelector
            organizationSlug={this.props.organizationSlug}
            value={this.state.craft}
            name={'craft_id'}
            onChange={this.handleCraftChange}
          />
        </div>
        <div className="col-6 ps-1 pe-0">
          <CraftAppointmentTypeSelector
            value={this.state.dueCraftAppointmentType}
            onChange={this.handleDueCraftAppointmentTypeChange}
            organizationSlug={this.props.organizationSlug}
            craftId={this.state.craft && this.state.craft.value}
            name="craft_appointment_type_id"
          />
        </div>
      </div>
    );
  }

  renderDueOffset() {
    if (this.state.dueChoice === 'none') return null;

    const dueChoiceTranslation = this.state.dueChoice === 'craft_appointment_type' ? 'Termin' : 'Datenpunkt';
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ flexBasis: '100%', flexGrow: 0 }}>
          <div className="mb-3">
            <label className="form-label">Verschiebung der Fälligkeit</label>
            <DueSelector
              value={this.state.dueOffset}
              onChange={this.handleDueOffsetChange}
              placeholder="Bitte angeben"
              dueChoice={this.state.dueChoice}
              directionEnabled={this.state.dueChoice !== 'default'}
              forwardText={`nach ${dueChoiceTranslation}`}
              backwardText={`vor ${dueChoiceTranslation}`}
            />
          </div>
        </div>
      </div>
    );
  }

  renderProjectDue() {
    if (this.props.workflowType !== 'workflow') return null;

    return (
      <div>
        <div className="mb-3">
          <label className="form-label">Automatische Fälligkeit</label>
          {this.renderDueChoice()}
        </div>
      </div>
    );
  }

  renderDueDesc() {
    if (this.props.workflowType !== 'workflow' || this.state.dueChoice === 'none') return null;

    return (
      <div style={{ marginTop: -12 }}>
        <small className="form-text">
          Die Standardfälligkeit wird verwendet, wenn die Fälligkeit nicht anderweitig (aus den Projektdaten oder
          manuell
          beim Auslösen einer Aktion) gesetzt wurde.
        </small>
      </div>
    );
  }

  render() {
    return (
      <>
        <div className="mb-3">
          <input
            autoFocus
            className="form-control form-control-lg todo-text-input"
            placeholder="Titel"
            autoComplete="off"
            type="text"
            value={this.props.record.text}
            onChange={this.handleTextChange}
            onKeyDown={this.handleKeyDown}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ flexBasis: '42%', flexGrow: 0, paddingRight: 10 }}>
            <div className="mb-3">
              <label className="form-label">Verantwortlich</label>
              <ResponsibleSelector
                value={this.props.record.role}
                onChange={this.handleRoleChange}
                placeholder="Rolle auswählen"
                optionsUrl={`/workflows/${this.props.record.workflow_id}/responsible_options.json`}
              />
            </div>
          </div>
        </div>

        {this.renderProjectDue()}

        <div>
          {this.state.dueChoice === 'datapoint' && this.renderDueDatapoint()}
          {this.state.dueChoice === 'craft_appointment_type' && this.renderDueAppointmentType()}
        </div>

        {this.renderDueOffset()}
        {this.renderDueDesc()}
        {this.renderOptions()}
      </>
    );
  }
}

SubTodoTemplateForm.propTypes = {
  record: PropTypes.object.isRequired,
  workflowType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  organizationSlug: PropTypes.string.isRequired,
};

export default SubTodoTemplateForm;

/* global document */

import $ from 'jquery';

$(document).on('click', 'button[data-disable-on-click], a[data-disable-on-click]', function (e) {
  const button = $(this);
  button.data('html-was', $(this).html());

  if (!button.hasClass("disabled")) {
    if (button.data('disable') !== '' && button.data('disable') !== true) {
      button.html(`<i class="fas fa-rotate-right fa-spin"></i> ${button.data("disable-on-click")}`);
    } else {
      button.html('<i class="fas fa-rotate-right fa-spin"></i>');
    }
    button.addClass('disabled');
  }
});

$(document).on('ajax:success ajax:error', 'form', function (e) {
  $(this).find(`button[data-disable-on-click], a[data-disable-on-click][data-submit-form='${$(this).attr('id')}']`).each(function () {
    $(this).html($(this).data('html-was'));
    $(this).removeClass('disabled');
  });
});

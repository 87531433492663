const defaultStyles = {
  control: (provided, { isFocused }) => ({
    ...provided,
    color: 'var(--bs-body-color)',
    borderColor: isFocused ? 'var(--bs-primary)' : 'var(--bs-border-color)',
    boxShadow: isFocused ? '0 0 0 0.2rem rgba(13, 110, 253, 0.25)' : 'none',
    '&:hover': {
      borderColor: isFocused ? 'var(--bs-primary)' : 'var(--bs-border-color)',
    },
    backgroundColor: 'var(--bs-body-bg)',
    height: 34,
    minHeight: 34,
  }),
  input: (provided, { isFocused }) => ({
    ...provided,
    color: 'var(--bs-body-color)',
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    padding: '5px 8px',
    backgroundColor: isFocused ? 'var(--bs-primary)' : 'var(--bs-body-bg)',
    color: isFocused ? 'white' : 'var(--bs-body-color)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: 'var(--bs-tertiary-color)',
    '&:hover': {
      color: 'var(--bs-body-color)',
    },
    padding: 6,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: 'var(--bs-tertiary-color)',
    '&:hover': {
      color: 'var(--bs-body-color)',
    },
    padding: 6,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: 'var(--bs-border-color)',
  }),
  menu: (provided, { isFocused }) => ({
    ...provided,
    backgroundColor: 'var(--bs-body-bg)',
    boxShadow: '0 0 0 1px var(--bs-border-color), 3px 13px 18px hsla(0, 0%, 0%, 0.2)',
    zIndex: 3,
    overflow: 'hidden',
  }),
  menuList: (provided, { isFocused }) => ({
    ...provided,
    marginRight: -1,
  }),
  placeholder: (provided, { isFocused }) => ({
    ...provided,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  singleValue: (provided, { isFocused }) => ({
    ...provided,
    color: 'var(--bs-body-color)',
  }),
  valueContainer: (provided, { isFocused }) => ({
    ...provided,
  }),
};

const smallStyles = {
  control: (provided, { isFocused }) => ({
    ...defaultStyles.control(provided, { isFocused }),
    height: 30,
    minHeight: 30,
    borderRadius: 4,
    fontSize: 13,
  }),
  input: (provided, { isFocused }) => ({
    ...defaultStyles.input(provided, { isFocused }),
  }),
  option: (provided, { isFocused }) => ({
    ...defaultStyles.option(provided, { isFocused }),
    padding: '5px 8px',
  }),
  clearIndicator: (provided, { isFocused }) => ({
    ...defaultStyles.clearIndicator(provided, { isFocused }),
    padding: 4,
  }),
  dropdownIndicator: (provided, { isFocused }) => ({
    ...defaultStyles.dropdownIndicator(provided, { isFocused }),
    padding: '2px 4px',
  }),
  indicatorSeparator: (provided, { isFocused }) => ({
    ...defaultStyles.indicatorSeparator(provided, { isFocused }),
  }),
  menu: (provided, { isFocused }) => ({
    ...defaultStyles.menu(provided, { isFocused }),
    minWidth: 240,
    zIndex: 3,
  }),
  menuList: (provided, { isFocused }) => ({
    ...defaultStyles.menuList(provided, { isFocused }),
  }),
  placeholder: (provided, { isFocused }) => ({
    ...defaultStyles.placeholder(provided, { isFocused }),
  }),
  singleValue: (provided, { isFocused }) => ({
    ...defaultStyles.singleValue(provided, { isFocused }),
  }),
  valueContainer: (provided, { isFocused }) => ({
    ...defaultStyles.valueContainer(provided, { isFocused }),
    padding: '0px 6px',
    marginTop: -1,
  }),
};

const dropdownStyles = {
  ...defaultStyles,
  control: (provided, { isFocused }) => ({
    ...defaultStyles.control(provided, { isFocused }),
    minWidth: 240,
    margin: 5,
  }),
  menu: (provided, { isFocused }) => ({
    ...defaultStyles.menu(provided, { isFocused }),
    maxHeight: 300,
  }),
  menuList: (provided, { isFocused }) => ({
    ...defaultStyles.menuList(provided, { isFocused }),
    maxHeight: 300,
  }),
  valueContainer: (provided, { isFocused }) => ({
    ...defaultStyles.valueContainer(provided, { isFocused }),
    padding: '0px 8px',
  }),
};

export { defaultStyles, smallStyles, dropdownStyles };

export default { defaultStyles, smallStyles, dropdownStyles };

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import ConfirmModal from '../shared/ConfirmModal';

import DataviewItemModal from './DataviewItemModal';

import DragHandle from '../shared/DragHandle';

import { deleteDataviewItem } from '../../helpers/api';

const getParameterText = (item) => {
  const lastElement = item.dataview_item_elements.at(-1);
  const type = lastElement.param?.type;

  if (!type) return null;

  if (type === 'Tag') {
    return lastElement.parameter_record?.title;
  } else if (type === 'TagGroup') {
    return lastElement.parameter_record?.title;
  } else if (type === 'DataPointTemplate') {
    return lastElement.parameter_record?.title;
  } else if (type === 'ProjectRole') {
    return lastElement.parameter_record?.name;
  } else if (type === 'WorkflowTemplate') {
    return lastElement.parameter_record?.title;
  } else if (type === 'Role') {
    return lastElement.parameter_record?.name;
  } else if (type === 'Todo') {
    return lastElement.parameter_record?.text;
  } else if (type === 'Action') {
    return lastElement.parameter_record?.name;
  }

  return null;
};

class DataviewItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      item: props.item,
      removedElements: [],
    };
  }

  handleTitleChange = (e) => {
    const title = e.target.value;

    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        title,
      },
    }));
  };

  handleItemUpdated = (data) => {
    this.props.onUpdated(data.dataview_item);
  };

  renderPath() {
    return this.props.item.dataview_item_elements.map(e => e.label).join(' • ');
  }

  renderParameterRecord() {
    const text = getParameterText(this.props.item);

    if (text) {
      return `: ${text}`;
    }

    return null;
  }

  render() {
    return (
      <div className="pb-2">
        <div className="dataview-item">
          <DragHandle dragHandleProps={this.props.provided.dragHandleProps} />
          <div className="flex-grow-1 d-flex align-items-center">
            <div>
              <div>
                {this.props.item.title}
              </div>
              <div className="text-muted">
                <small>
                  {this.renderPath()}{this.renderParameterRecord()}
                </small>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <DataviewItemModal
              visible
              title="Element bearbeiten"
              organizationSlug={this.props.organizationSlug}
              onSaved={this.handleItemUpdated}
              initialItem={this.props.item}>
              {onClick => (
                <button
                  type="button"
                  className="btn btn-compact btn-link"
                  onClick={onClick}>
                  <Icon name="pencil" />
                </button>
              )}
            </DataviewItemModal>
            <ConfirmModal
              visible
              target={onClick => (
                <button
                  type="button"
                  className="btn btn-compact btn-link"
                  onClick={onClick}>
                  <Icon name="trash" />
                </button>
              )}
              title="Element löschen"
              cancelText="abbrechen"
              confirmText="löschen"
              confirmStyle="danger"
              onConfirm={() => deleteDataviewItem(this.props.item)}
              onConfirmed={() => this.props.onDeleted(this.props.item)}>
              <p>
                Bist du sicher, dass du das Element löschen willst?
              </p>
            </ConfirmModal>
          </div>
        </div>
      </div>
    );
  }
}

DataviewItem.propTypes = {
  onDeleted: PropTypes.func.isRequired,
  onUpdated: PropTypes.func.isRequired,
  item: PropTypes.object,
  provided: PropTypes.object,
  organizationSlug: PropTypes.string.isRequired,
};

export default DataviewItem;

/* global google */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Marker from '../GoogleMaps/Marker';
import InfoWindow from '../GoogleMaps/InfoWindow';

const styles = {
  POLYGON_FILL_COLOR: '#0066CC',
  POLYGON_FILL_OPACITY: 0.0,
  POLYGON_STROKE_COLOR: '#0066CC',
  POLYGON_STROKE_WEIGHT: 0,
  POLYGON_STROKE_OPACITY: 1,

  POLYGON_FILL_COLOR_HOVER: '#0066CC',
  POLYGON_FILL_OPACITY_HOVER: 0.2,
  POLYGON_STROKE_COLOR_HOVER: '#0066CC',
  POLYGON_STROKE_WEIGHT_HOVER: 2,
  POLYGON_STROKE_OPACITY_HOVER: 1,
};

const polygonDefaultStyles = {
  strokeColor: styles.POLYGON_STROKE_COLOR,
  strokeOpacity: styles.POLYGON_STROKE_OPACITY,
  strokeWeight: styles.POLYGON_STROKE_WEIGHT,
  fillColor: styles.POLYGON_FILL_COLOR,
  fillOpacity: styles.POLYGON_FILL_OPACITY,
};

const polygonHoverStyles = {
  strokeColor: styles.POLYGON_STROKE_COLOR_HOVER,
  strokeOpacity: styles.POLYGON_STROKE_OPACITY_HOVER,
  strokeWeight: styles.POLYGON_STROKE_WEIGHT_HOVER,
  fillColor: styles.POLYGON_FILL_COLOR_HOVER,
  fillOpacity: styles.POLYGON_FILL_OPACITY_HOVER,
};

class CompanyPolygon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      polygons: [],
      markers: [],
      drawingComplete: false,
    };
  }

  componentDidMount() {
    if (this.props.map && !this.state.drawingComplete) {
      this.drawOverlays();
    }
  }

  componentDidUpdate() {
    if (this.props.map && !this.state.drawingComplete) {
      this.drawOverlays();
    }
  }

  // TODO: Typo!
  componentWillUnmound() {
    this.state.polygons.forEach((polygon) => {
      polygon.setMap(null);
    });

    this.state.markers.forEach((marker) => {
      marker.setMap(null);
    });
  }

  drawOverlays = () => {
    const newPolygons = [];

    this.props.company.areas.forEach((area) => {
      const points = [];

      area.polygon.forEach((point, i) => {
        points[i] = new google.maps.LatLng(point.lat, point.lon);
      });

      const mapPolygon = new google.maps.Polygon(Object.assign({
        id: area.id,
        points,
        paths: points,
        map: this.props.map,
      }, this.props.alwaysVisible ? polygonHoverStyles : polygonDefaultStyles));
      newPolygons.push(mapPolygon);
    });

    this.setState({
      polygons: newPolygons,
      drawingComplete: true,
    });
  };

  handleMouseOver = () => {
    this.state.polygons.forEach((p) => {
      p.setOptions(polygonHoverStyles);
    });
  };

  handleMouseOut = () => {
    this.state.polygons.forEach((p) => {
      p.setOptions(polygonDefaultStyles);
    });
  };

  renderMarkers = () => {
    if (!this.props.company.contact) {
      return null;
    }
    let contactInformation = '';
    if (this.props.company.contact) {
      contactInformation =
        (<React.Fragment>
          <br />
          <a href={`/contacts/${this.props.company.contact.id}`}>
            {this.props.company.contact.fullname}
          </a>
        </React.Fragment>);
    }

    return this.props.company.contact.postal_addresses.map(postalAddress => (
      <Marker
        key={postalAddress.id}
        map={this.props.map}
        lat={postalAddress.lat}
        lon={postalAddress.lon}
        title={this.props.company.display_name}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
      >
        {marker => (
          <InfoWindow map={this.props.map} marker={marker}>
            <div>
              <h4>{this.props.company.display_name}</h4>
              <p>
                <span className="text-muted">{this.props.company.crafts.map(c => c.name).join(', ')}</span>
                {contactInformation}
              </p>
              <p className="text-muted">
                <a href={`/installation_companies/${this.props.company.id}`} target="_blank" className="btn-link" rel="noreferrer">
                  <span className="ic-icon"><i className="fas fa-external-link" /> </span>
                  Details
                </a>
              </p>
            </div>
          </InfoWindow>
        )}
      </Marker>
    ));
  };

  render() {
    return (
      <React.Fragment>
        {this.renderMarkers()}
      </React.Fragment>
    );
  }
}

CompanyPolygon.propTypes = {
  map: PropTypes.object,
  company: PropTypes.object.isRequired,
  alwaysVisible: PropTypes.bool.isRequired,
};

CompanyPolygon.defaultProps = {
  alwaysVisible: false,
};

export default CompanyPolygon;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ContactNameForm extends Component {
  contactFieldName(attribute) {
    return `${this.props.objectName}[${attribute}]`
  }

  contactAttribute(attribute, contact = this.props.contact) {
    return contact[attribute] || ''
  }

  render() {
    return (
      <div className="row mb-3 gutter-xs">
        <div className="col-md-5 col-xs-7 compact-input-group">
          <input
            type="text"
            name={this.contactFieldName('org_name')}
            className="form-control"
            placeholder="Firma"
            value={this.contactAttribute('org_name')}
            onChange={(e) => this.props.onChange('org_name', e)}
          />
          <input
            type="text"
            name={this.contactFieldName('org_title')}
            className="form-control"
            placeholder="Position"
            value={this.contactAttribute('org_title')}
            onChange={(e) => this.props.onChange('org_title', e)}          />
        </div>
      </div>
    )
  }
}

ContactNameForm.propTypes = {
  contact: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  objectName: PropTypes.string,
}

export default ContactNameForm;
import React from 'react';

import PropTypes from 'prop-types';

import OperatorSelector from '../../OperatorSelector';

import DataPointTemplateSelector from '../../../shared/DataPointTemplateSelector';

import BaseCondition from '../BaseCondition';

const textFieldStyle = {
  height: 30,
  borderRadius: 4,
  fontSize: 13,
  borderColor: '#ccc',
};

class StringDataPointCondition extends React.Component {
  handleChangeOperator = ({ value }) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      operator: value,
    }));
  };

  handleDataPointTemplateChange = (template) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      integer_operand_1: template.id,
      integer_operand_2: null,
      operator: null,
      data_point_template: template,
    }));
  };

  handleStringOperandChange = (e) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      string_operand_2: e.target.value,
    }));
  };

  shouldRenderTextInput = () => {
    return ['is', 'is_not'].includes(this.props.condition.operator)
  };

  renderOperand1() {
    return (
      <div style={{ flexBasis: 200, marginLeft: 8 }}>
        <DataPointTemplateSelector
          value={this.props.condition.data_point_template}
          optionsUrl={`/${this.props.organizationSlug}/data_point_template_options.json`}
          onChange={this.handleDataPointTemplateChange}
          allowedTypes={[
            'DataPoint::StringDataPoint',
          ]}
          size="small"
        />
      </div>
    );
  }

  renderOperator() {
    if (!this.props.condition.data_point_template) return null;

    return (
      <div style={{ flexBasis: 200, marginLeft: 8 }}>
        <OperatorSelector
          operators={this.props.condition.filter_condition_type.operators}
          onChange={this.handleChangeOperator}
          value={this.props.condition.operator}
        />
      </div>
    );
  }

  renderOperand2() {
    if (this.shouldRenderTextInput()) {
      return (
        <div style={{ flexBasis: 200, marginLeft: 8 }}>
          <input
            value={this.props.condition.string_operand_2 || ''}
            onChange={this.handleStringOperandChange}
            className="form-control form-control-sm"
            placeholder="Bitte eintragen"
            style={textFieldStyle}
          />
        </div>
      );
    }

    return null;
  }

  render() {
    return (
      <BaseCondition {...this.props}>
        {this.renderOperand1()}
        {this.renderOperator()}
        {this.renderOperand2()}
      </BaseCondition>
    );
  }
}

StringDataPointCondition.propTypes = {
  condition: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default StringDataPointCondition;

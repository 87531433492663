import React from 'react';

import PropTypes from 'prop-types';

import OperatorSelector from '../../OperatorSelector';
import ProjectTypeSelector from '../../../ProjectTypes/ProjectTypeSelector';
import BaseCondition from '../BaseCondition';

class TypeCondition extends React.Component {
  handleChangeOperator = ({ value }) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      operator: value,
    }));
  };

  handleProjectTypeChange = (project_type) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      integer_operand_1: project_type.id,
      project_type,
    }));
  };

  renderOperand1() {
    return (
      <div style={{ flexBasis: 200, marginLeft: 8 }}>
        <ProjectTypeSelector
          value={this.props.condition.project_type}
          optionsUrl={`/${this.props.organizationSlug}/project_types.json`}
          onChange={this.handleProjectTypeChange}
          size="small"
        />
      </div>
    );
  }

  renderOperator() {
    return (
      <div style={{ flexBasis: 200, marginLeft: 8 }}>
        <OperatorSelector
          operators={this.props.condition.filter_condition_type.operators}
          onChange={this.handleChangeOperator}
          value={this.props.condition.operator}
        />
      </div>
    );
  }

  render() {
    return (
      <BaseCondition {...this.props}>
        {this.renderOperator()}
        {this.renderOperand1()}
      </BaseCondition>
    );
  }
}

TypeCondition.propTypes = {
  condition: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default TypeCondition;

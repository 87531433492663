import React from 'react';
import PropTypes from 'prop-types';

const ProjectStateBadge = ({ projectState, title }) => {
  if (!projectState) return null;

  const style = {
    fontWeight: 500,
    color: projectState.text_color,
    border: `1px solid ${projectState.color}`,
    backgroundColor: projectState.color,
  };

  return (
    <span title={title} className="action-project-state-label" style={style}>
      {projectState.state}
    </span>
  );
};

ProjectStateBadge.propTypes = {
  projectState: PropTypes.object,
  title: PropTypes.string,
};

export default ProjectStateBadge;


import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['startDatetime', 'endDatetime', 'appointmentTypeId', 'submit', 'startDate', 'endDate', 'appointmentId', 'allocate', 'loadingSpinner'];

  static values = { freeInstallationAppointmentsPath: String };

  connect() {
    this.toggleSubmit();
  }

  startDatetimeChanged() {
    if (this.startDatetimeTarget.value) {
      this.endDatetimeTarget.min = this.startDatetimeTarget.value;
      if (!this.endDatetimeTarget.value || this.startDatetimeTarget.value > this.endDatetimeTarget.value) {
        this.endDatetimeTarget.value = this.startDatetimeTarget.value;
      }
    } else {
      this.endDatetimeTarget.value = null;
      this.endDatetimeTarget.min = null;
    }

    this.toggleSubmit();
  }

  toggleSubmit() {
    this.submitTarget.disabled = !this.startDatetimeTarget.value || !this.endDatetimeTarget.value;
  }
}

import React from 'react';
import PropTypes from 'prop-types';

const CurrencySelect = ({ value, currencies, onChange, disabled }) => {
  const handleChange = (e, currency) => {
    e.preventDefault();
    onChange(currency);
  };

  const optionItems = currencies.map(currency => (
    <li key={currency}>
      <a href="#" onClick={e => handleChange(e, currency)} className="dropdown-item">{currency}</a>
    </li>
  ));

  return (
    <>
      <button
        disabled={disabled}
        type="button"
        className="btn btn-light dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {value} <span className="caret" />
      </button>
      <ul className="dropdown-menu dropdown-menu-end">
        {optionItems}
      </ul>
    </>
  );
};

CurrencySelect.propTypes = {
  value: PropTypes.string,
  currencies: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default CurrencySelect;

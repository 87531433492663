import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

const icons = [
  'check',
  'times',
  'exclamation-circle',
  'question-circle',
  'arrow-left',
  'arrow-right',
  'phone',
  'phone-slash',
];

class IconSelector extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e, icon) {
    e.preventDefault();
    this.props.onChange(icon);
  }

  renderIcons() {
    return icons.map((icon) => {
      const classNames = ['icon-option'];
      const selected = (this.props.value === icon);

      if (selected) {
        classNames.push('icon-option-selected');
      }

      return (
        <button key={icon} className={classNames.join(' ')} onClick={e => this.handleChange(e, icon)}>
          <Icon name={icon} color={selected ? this.props.color : '#999'} />
        </button>
      );
    });
  }

  render() {
    return (
      <div className="icon-selector">
        {this.renderIcons()}
      </div>
    );
  }
}

IconSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  color: PropTypes.string,
};

IconSelector.defaultProps = {
  color: '#777',
};

export { icons };

export default IconSelector;

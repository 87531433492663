import React from 'react';
import PropTypes from 'prop-types';
import DatetimePicker from "../../DatePickers/DatetimePickerComponent";


const DateTimeDataPointInput = ({ value, onChange, saving }) => {
  const handleChange = (date) => {
    onChange(date);
  };

  return (
    <DatetimePicker
      value={value}
      isDisabled={saving}
      onChange={handleChange}
      placeholder="Zeitpunkt auswählen"
    />
  );
};
DateTimeDataPointInput.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default DateTimeDataPointInput;

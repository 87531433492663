import React, { Component } from 'react';
import PropTypes from 'prop-types';

import NumericInput from '../shared/NumericInput';

const timeIntervals = [
  { code: 'minutes', title: 'Minuten', titleSingle: 'Minute', minutesFactor: 1 },
  { code: 'hours', title: 'Stunden', titleSingle: 'Stunde', minutesFactor: 60 },
  { code: 'days', title: 'Tage', titleSingle: 'Tag', minutesFactor: 60 * 24 },
  { code: 'weeks', title: 'Wochen', titleSingle: 'Woche', minutesFactor: 60 * 24 * 7 },
  { code: 'months', title: 'Monate', titleSingle: 'Monat', minutesFactor: 60 * 24 * 30 },
];

const getIntervalByMinutesValue = (minutes) => {
  if (!minutes) return timeIntervals[0];

  const minutesAbs = Math.abs(minutes);

  for (let i = timeIntervals.length - 1; i > 0; i -= 1) {
    if (!(minutesAbs % timeIntervals[i].minutesFactor)) {
      return timeIntervals[i];
    }
  }

  return timeIntervals[0];
};

const getInputValueByMinutesValue = (minutes) => {
  if (minutes === null) return null;

  const minutesAbs = Math.abs(minutes);

  const interval = getIntervalByMinutesValue(minutesAbs);

  if (interval) {
    return minutesAbs / interval.minutesFactor;
  }

  return null;
};

const timeInWords = (minutes) => {
  const interval = getIntervalByMinutesValue(minutes);
  const value = minutes / interval.minutesFactor;
  const preposition = (value >= 0 ? 'nach' : 'vor');

  return `${Math.abs(value)} ${(value === 1) ? interval.titleSingle : interval.title} ${preposition}`;
};

// Ein- und Ausgabe dieser Komponente ist immer in Minuten
// Die anderen Intervalle (Stunden, Tage, Woche, Monate) werden nur intern verwendet
class DurationSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeInterval: getIntervalByMinutesValue(props.value),
      inputValue: getInputValueByMinutesValue(props.value), // Stunden
      direction: (props.value && props.value < 0) ? 'backward' : 'forward',
    };
  }

  handleChange = () => {
    let newminutesToDue = null;

    const factor = this.state.direction === 'forward' ? 1 : -1;

    if (this.state.inputValue !== null) {
      newminutesToDue = factor * this.state.inputValue * this.state.timeInterval.minutesFactor;
    }

    if (this.props.onChange) {
      this.props.onChange(newminutesToDue);
    }
  };

  handleInputChange = (e, value) => {
    this.setState({ inputValue: value }, () => {
      this.handleChange();
    });
  };

  handleChangeTimeInterval = (e, timeInterval) => {
    e.preventDefault();
    this.setState({ timeInterval }, () => {
      this.handleChange();
    });
  };

  handleDirectionChange = (e, direction) => {
    e.preventDefault();
    this.setState({ direction }, () => {
      this.handleChange();
    });
  };

  renderDirection() {
    if (!this.props.directionEnabled) return null;

    return (
      <>
        <button style={{ marginLeft: -1 }} type="button" className="btn btn-light dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {this.state.direction === 'forward' ? this.props.forwardText : this.props.backwardText} <span className="caret" />
        </button>
        <div className="dropdown-menu dropdown-menu-end">
          <a href="#" onClick={e => this.handleDirectionChange(e, 'forward')} className="dropdown-item">{this.props.forwardText}</a>
          <a href="#" onClick={e => this.handleDirectionChange(e, 'backward')} className="dropdown-item">{this.props.backwardText}</a>
        </div>
      </>
    );
  }

  render() {
    const buttonStyle = {};

    if (this.props.directionEnabled) {
      buttonStyle.borderRadius = 0;
      buttonStyle.marginLeft = -1;
    }

    return (
      <div className="input-group">
        <NumericInput
          className="form-control"
          placeholder={this.props.placeholder}
          value={this.state.inputValue}
          onChange={this.handleInputChange}
          options={{
            minimumValue: 0,
            decimalPlacesRawValue: 0,
            decimalPlaces: 0,
            digitGroupSeparator: '',
            emptyInputBehavior: 'null',
          }}
        />
        <button style={buttonStyle} type="button" className="btn btn-light dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {this.state.timeInterval.title} <span className="caret" />
        </button>
        <div className="dropdown-menu dropdown-menu-end">
          {timeIntervals.map(t => (
            <button
              type="button"
              key={t.code}
              onClick={e => this.handleChangeTimeInterval(e, t)}
              className="dropdown-item">
              {t.title}
            </button>
          ))}
        </div>
        {this.renderDirection()}
      </div>
    );
  }
}

DurationSelector.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  forwardText: PropTypes.string,
  backwardText: PropTypes.string,
  directionEnabled: PropTypes.bool,
};

DurationSelector.defaultProps = {
  placeholder: 'Nach Aktivierung',
  forwardText: 'nach Datenpunkt',
  backwardText: 'vor Datenpunkt',
  directionEnabled: false,
};

export default DurationSelector;
export { timeInWords };

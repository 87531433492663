import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getAsync } from '../../helpers/rails_helper';

class EmailSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emails: [],
      loading: true,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    getAsync(this.props.optionsUrl).then((result) => {
      const filtered = result.emails
        .filter(e => !this.props.onlyVerfied || e.verified_for_delivery)
        .sort((a, b) => {
          if (a.preferred_for_sending) {
            return -1;
          } else {
            return 1;
          }
        });

      this.setState({
        emails: filtered,
        loading: false,
      });

      if (filtered && filtered.length) {
        this.props.onChange(filtered[0].address);
      } else {
        this.props.onChange('');
      }
    });
  }

  handleChange(e) {
    e.preventDefault();
    this.props.onChange(e.target.value);
  }

  render() {
    if (this.state.loading) return 'laden...';

    if (!this.state.emails.length) {
      return <span className="text-danger">{this.props.noResultText}</span>;
    }

    const options = this.state.emails.map((e) => {
      return (
        <option key={`email-${e.id}`} value={e.address}>{e.address}</option>
      );
    });

    return (
      <span className="compose-phone-number-select">
        <select onChange={this.handleChange}>{options}</select>
      </span>
    );
  }
}

EmailSelect.propTypes = {
  onlyVerfied: PropTypes.bool,
  optionsUrl: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  noResultText: PropTypes.string,
};

export default EmailSelect;

/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react';
import NoDataPointDisplay from './NoDataPointDisplay';

export default ({ value }) => {
  if (!value) {
    return <NoDataPointDisplay />;
  }

  const parts = value.split('-');

  const monthNames = [
    'Jan',
    'Feb',
    'Mär',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dez',
  ];

  return `${monthNames[parseInt(parts[1], 10) - 1]} ${parts[0]}`;
};

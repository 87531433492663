import React from 'react';

import PropTypes from 'prop-types';
import Select from 'react-select';

import OperatorSelector from '../../OperatorSelector';
import BaseCondition from '../BaseCondition';

import { smallStyles } from '../../../../styles/select_styles';

const options = [
  { value: 'fixed', label: 'Angebot' },
  { value: 'estimate', label: 'Vorläufiges Angebot' },
  { value: 'supplement', label: 'Nachtragsangebot' },
  { value: 'calc_only_system', label: 'Planung' },
];

class TypeCondition extends React.Component {
  handleChangeOperator = ({ value }) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      operator: value,
    }));
  };

  handleOfferTypeChange = (offer_type) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      string_operand_1: offer_type.value,
    }));
  };

  renderOperand1() {
    const currentValue = options.find(o => o.value === this.props.condition.string_operand_1) || '';

    return (
      <div style={{ flexBasis: 200, marginLeft: 8 }}>
        <Select
          options={options}
          styles={smallStyles}
          value={currentValue}
          onChange={this.handleOfferTypeChange}
          placeholder="Bitte auswählen"
        />
      </div>
    );
  }

  renderOperator() {
    return (
      <div style={{ flexBasis: 200, marginLeft: 8 }}>
        <OperatorSelector
          operators={this.props.condition.filter_condition_type.operators}
          onChange={this.handleChangeOperator}
          value={this.props.condition.operator}
        />
      </div>
    );
  }

  render() {
    return (
      <BaseCondition {...this.props}>
        {this.renderOperator()}
        {this.renderOperand1()}
      </BaseCondition>
    );
  }
}

TypeCondition.propTypes = {
  condition: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default TypeCondition;

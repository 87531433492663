import React from 'react';
import PropTypes from 'prop-types';

import Icon from './Icon';

// Teilweise von Bootstrap 4:
// https://codepen.io/emdeoh/full/WNNojyz
const fullColorSet = [
  '#bbbbbb', '#5b9cf9', '#965df0', '#9c7ed1', '#e275a9', '#fb6f6b', '#fda768', '#fed566', '#6fc386', '#bed96d', '#6bdab9', '#64c0cd', '#90ddf4',
  '#777777', '#2f7dd5', '#6413eb', '#6e42bc', '#d43582', '#d9534f', '#fc7f2f', '#f0ad4e', '#2fa74e', '#a2cc21', '#2cc99a', '#20a2b6', '#5bc0de',
  '#454545', '#0b4ba8', '#440da0', '#4a2d80', '#912459', '#972926', '#ab561e', '#c68c39', '#1f7235', '#89ad1c', '#1d8969', '#156e7c', '#2a7c94',  
];

// Mittlere Reihe
const smallColorSet = fullColorSet.slice(13, 26);

const defaultColor = fullColorSet[22]; // #a2cc21

const ColorSelector = ({ value, onChange, colorSet }) => {
  const handleChange = (e, color) => {
    e.preventDefault();
    if (onChange) {
      onChange(color);
    }
  };

  return (
    <div className="color-selector">
      {(colorSet === 'full' ? fullColorSet : smallColorSet).map(color => (
        <button
          className={`color-selector-button ${color === value ? 'active' : ''}`}
          key={`color-${color}`}
          onClick={e => handleChange(e, color)}>
          <Icon name="circle" color={color} />
        </button>
      ))}
    </div>
  );
};

ColorSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  colorSet: PropTypes.string,
};

ColorSelector.defaultProps = {
  colorSet: 'full',
};

export default ColorSelector;

export { defaultColor };

/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import Tooltip from '../shared/Tooltip';

import { postAsync, deleteAsync } from '../../helpers/rails_helper';

import { subscribe, unsubscribe } from '../../helpers/actioncable_helper';

let timer;

// Verzögerung in Millisekunden bis "Speichern..." angezeigt wird
const delay = 200;

const clearTimer = () => {
  if (timer) {
    clearTimeout(timer);
  }
};

class ProjectSubscription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subscription: props.subscription,
      loading: false,
    };
  }

  componentDidMount() {
    this.subscriptionChangesChannel = subscribe({
      channel: 'SubscribableSubscriptionChangesChannel',
      subscribable_type: 'Project',
      subscribable_id: this.props.projectId,
      organization_id: this.props.organizationId,
    }, (data) => {
      if (data.action === 'new' && data.subscription.user_id === this.props.currentUserId) {
        this.handleSubscribed(data.subscription);
      } else if (data.action === 'delete' && data.subscription.user_id === this.props.currentUserId) {
        this.handleUnsubscribed();
      }
    });
  }

  componentWillUnmount() {
    unsubscribe(this.subscriptionChangesChannel);
  }

  subscribe = () => {
    postAsync('/subscriptions/', {
      subscription: {
        subscribable_type: 'Project',
        subscribable_id: this.props.projectId,
      },
    }).then(({ subscription }) => {
      this.handleSubscribed(subscription);
    });
  };

  unsubscribe = () => {
    deleteAsync(`/subscriptions/${this.state.subscription.id}`).then(() => {
      this.handleUnsubscribed();
    });
  };

  handleSubscribed = (subscription) => {
    clearTimer();
    this.setState({ subscription, loading: false });
  };

  handleUnsubscribed = () => {
    clearTimer();
    this.setState({ subscription: null, loading: false });
  };


  handleSubscribeClick = (e) => {
    e.preventDefault();
    timer = setTimeout(() => {
      this.setState({ loading: true });
    }, delay);
    this.subscribe();
  };

  handleUnsubscribeClick = (e) => {
    e.preventDefault();
    timer = setTimeout(() => {
      this.setState({ loading: true });
    }, delay);
    this.unsubscribe();
  };

  render() {
    if (this.state.subscription) {
      let toolTip = 'Du erhältst Benachrichtigungen zu diesem Projekt.';

      if (this.state.subscription.silence_inactive_projects) {
        toolTip = (
          <div>
            <div>
              Du erhältst Benachrichtigungen,
            </div>
            <div>
              wenn du für eine aktive Aufgabe verantwortlich bist.
            </div>
          </div>
        );
      }

      return (
        <Tooltip placement="top-end" title={toolTip}>
          <a
            className="subscribe-link subscribed"
            onClick={this.handleUnsubscribeClick}>
            <Icon name="bell" className="" />
          </a>
        </Tooltip>
      );
    }

    return (
      <Tooltip placement="top-end" title="Lass dich über Aktivitäten in diesem Projekt benachrichtigen.">
        <a
          className="subscribe-link"
          onClick={this.handleSubscribeClick}>
          <Icon name="bell" light />
        </a>
      </Tooltip>
    );
  }
}

ProjectSubscription.propTypes = {
  projectId: PropTypes.number.isRequired,
  organizationId: PropTypes.number.isRequired,
  subscription: PropTypes.object,
  currentUserId: PropTypes.number.isRequired,
};

export default ProjectSubscription;

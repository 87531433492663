import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RangePickerComponent from '../DatePickers/RangePickerComponent';
import moment from 'moment';
import { Turbo } from "@hotwired/turbo-rails"

class DateRangeFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      range: this.props.range,
      open: undefined
    }
  }

  handleChange = (range) => {
    this.setState({ range, open: true })
  }

  handleOkClick = () => {
    const range = this.state.range;
    let url = new URL(window.location.href)
    let params = new URLSearchParams(url.search);
    if (range) {
      range[0]? params.set("rs", range[0].format('DD.MM.YYYY')) : params.delete("rs")
      range[1]? params.set("re", range[1].format('DD.MM.YYYY')) : params.delete("re")
    }
    else {
      params.delete("rs")
      params.delete("re")
    }

    Turbo.visit(`/${this.props.queryPath}?${params.toString()}`)
  }

  handleBlur = () => {
    this.setState({ open: undefined })
  }

  getPresetRanges() {
    return {
      'Heute': [moment(), moment()],
      'Diese Woche': [moment().startOf('week').add(1, 'days'), moment().endOf('week').add(1, 'days')],
      'Letzte Woche': [moment().startOf('week').add(1, 'days').subtract(1, 'weeks'), moment().endOf('week').add(1, 'days').subtract(1, 'weeks')],
      'Diesen Monat': [moment().startOf('month'), moment().endOf('month')],
      'Letzten Monat': [moment().startOf('month').subtract(1, 'month'), moment().endOf('month').subtract(1, 'month')],
    }
  }

  render() {
    return(
      <RangePickerComponent
        onChange={this.handleChange}
        onOkClick={this.handleOkClick}
        range={this.state.range}
        name={this.props.name}
        ranges={this.getPresetRanges()}
        classNames={`h-100 ${this.props.small? "form-control-sm" : ""}`}
        style={this.props.style}
        placeholders={this.props.placeholders}
        onBlur={this.handleBlur}
        open={this.state.open}
      />
    )
  }
}

DateRangeFilter.propTypes = {
  range: PropTypes.array,
  name: PropTypes.string,
  queryPath: PropTypes.string,
  small: PropTypes.bool,
  placeholders: PropTypes.array,
  style: PropTypes.object,
};

DateRangeFilter.defaultProps = {
  small: false,
  name: "",
  queryPath: "",
};

export default DateRangeFilter;
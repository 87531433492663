import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Draggable } from 'react-beautiful-dnd';

import Icon from '../shared/Icon';
import ConfirmModal from '../shared/ConfirmModal';
import Tooltip from '../shared/Tooltip';

import TodoTemplateModal from './TodoTemplateModal';

import { deleteTodo } from '../../helpers/api';

const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // padding: grid,
  // margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'var(--bs-secondary-bg)' : null,

  // borderRadius: '4px',

  // styles we need to apply on draggables
  ...draggableStyle,
});

class TodoTemplate extends PureComponent {
  render() {
    return (
      <Draggable
        draggableId={this.props.todo.id.toString()}
        isDragDisabled={!this.props.editable}
        index={this.props.index}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef}
            data-selected={this.props.selected}
            className="todo-template"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onClick={() => this.props.onTodoSelected(this.props.todo)}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
            )}>
            <div className="todo-template-head">
              <div className={`todo-template-title ${this.props.todo.active ? 'todo-template-title-active' : ''}`}>
                {this.props.todo.text}
              </div>
              <div className="todo-template-buttons">
                <TodoTemplateModal
                  organizationSlug={this.props.organizationSlug}
                  visible={this.props.editable && !this.props.sorting}
                  workflowType={this.props.workflowType}
                  title="Aufgabe bearbeiten"
                  initialTodo={this.props.todo}
                  onSaved={data => this.props.onTodoUpdated(data.todo)}>
                  {onClick => (
                    <Tooltip title="Aufgabe bearbeiten">
                      <button className="btn btn-link btn-compact" onClick={onClick}>
                        <Icon name="pencil" />
                      </button>
                    </Tooltip>
                  )}
                </TodoTemplateModal>
                <ConfirmModal
                  visible={this.props.editable && !this.props.sorting}
                  target={onClick => (
                    <Tooltip title="Aufgabe löschen">
                      <button
                        type="button"
                        className="btn btn-compact btn-link"
                        onClick={onClick}>
                        <Icon name="trash" />
                      </button>
                    </Tooltip>
                  )}
                  title={`${this.props.workflowType === 'checklist' ? 'Antwort' : 'Aktion'} löschen`}
                  cancelText="abbrechen"
                  confirmText="löschen"
                  confirmStyle="danger"
                  onConfirm={() => deleteTodo(this.props.todo)}
                  onConfirmed={() => this.props.onTodoDeleted(this.props.todo)}>
                    Bist du sicher, dass du die Aufgabe <strong>{this.props.todo.text}</strong> löschen willst?
                </ConfirmModal>
              </div>
            </div>
          </div>
        )}
      </Draggable>
    );
  }
}

TodoTemplate.propTypes = {
  todo: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  onTodoUpdated: PropTypes.func.isRequired,
  onTodoDeleted: PropTypes.func.isRequired,
  onTodoSelected: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  workflowType: PropTypes.string.isRequired,
  selected: PropTypes.bool,
};

export default TodoTemplate;

import React from 'react';

import PropTypes from 'prop-types';

import OperatorSelector from '../../OperatorSelector';

import ProjectStateSelectorDropdown from '../../../ProjectStates/ProjectStateSelectorDropdown';

import BaseCondition from '../BaseCondition';

class StateCondition extends React.Component {
  handleChangeOperator = ({ value }) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      operator: value,
    }));
  };

  handleProjectStateChange = (project_state) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      integer_operand_1: project_state.id,
      project_state,
    }));
  };

  renderOperand1() {
    return (
      <div style={{ flexBasis: 200, marginLeft: 8 }}>
        <ProjectStateSelectorDropdown
          value={this.props.condition.project_state}
          optionsUrl={`/${this.props.organizationSlug}/project_states.json`}
          onChange={this.handleProjectStateChange}
          size="small"
        />
      </div>
    );
  }

  renderOperator() {
    return (
      <div style={{ flexBasis: 200, marginLeft: 8 }}>
        <OperatorSelector
          operators={this.props.condition.filter_condition_type.operators}
          onChange={this.handleChangeOperator}
          value={this.props.condition.operator}
        />
      </div>
    );
  }

  render() {
    return (
      <BaseCondition {...this.props}>
        {this.renderOperator()}
        {this.renderOperand1()}
      </BaseCondition>
    );
  }
}

StateCondition.propTypes = {
  condition: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default StateCondition;

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import NumericInput from './NumericInput';

import { autoNumericOptionsDecimal } from '../../config/auto_numeric_options';

class NumericField extends Component {
  constructor(props) {
    super(props);

    this.options = { ...autoNumericOptionsDecimal };

    if (props.minimumValue) {
      this.options.minimumValue = props.minimumValue;
    }

    if (props.maximumValue) {
      this.options.maximumValue = props.maximumValue;
    }

    if (props.decimalPlaces) {
      this.options.decimalPlaces = props.decimalPlaces;
    }

    this.state = {
      value: props.value,
    };
  }

  handleChange = (_, value) => {
    this.setState({
      value,
    });
  };

  getCurrentValueAsString = () => {
    if (this.state.value) {
      return this.state.value.toString();
    }

    return '';
  };

  render() {
    return (
      <Fragment>
        <NumericInput
          className={this.props.className}
          placeholder={this.props.placeholder}
          style={this.props.style}
          disabled={this.props.disabled}
          name={this.props.name}
          value={this.state.value}
          onChange={this.handleChange}
          options={this.options}
        />
        <input type="hidden" value={this.getCurrentValueAsString()} />
      </Fragment>
    );
  }
}

NumericField.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  style: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  minimumValue: PropTypes.number,
  maximumValue: PropTypes.number,
  decimalPlaces: PropTypes.number,
};

NumericField.defaultProps = {
  value: '',
  placeholder: null,
  className: null,
  style: null,
  disabled: null,
  minimumValue: null,
  maximumValue: null,
  decimalPlaces: 0,
};

export default NumericField;

import { Turbo } from '@hotwired/turbo-rails';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SelectorDropdown from '../shared/SelectorDropdown';
import Icon from '../shared/Icon';

class FilterComponent extends Component {
  handleOptionSelected = (option) => {
    const newId = option.id;
    const urlParams = new URLSearchParams(window.location.search);
    const optionsString = urlParams.get(this.props.queryParam) || '';
    const options = (optionsString === '') ? [] : optionsString.split('.');

    if (!options.includes(newId)) {
      options.push(newId);
    }

    if (this.props.multi) {
      urlParams.set(this.props.queryParam, options.join('.'));
    } else {
      urlParams.set(this.props.queryParam, option.id);
    }


    const path = `${window.location.pathname}?${urlParams.toString()}`;

    Turbo.visit(path);
  };

  renderButton = (ref, instance) => {
    return (
      <button ref={ref} className="form-control-sm btn-sm btn btn-light dropdown-toggle" onClick={instance.toggleDropDown}>
        <Icon name={this.props.iconName} /> {this.props.text} <span className="caret" />
      </button>
    );
  }

  render() {
    return (
      <SelectorDropdown
        {...this.props}
        target={this.renderButton}
        onAdd={this.handleOptionSelected}
        optionsUrl={this.props.optionsUrl}
        placement={this.props.dropdownPlacement}
      />
    );
  }
}

FilterComponent.propTypes = {
  optionsUrl: PropTypes.string.isRequired,
  entitiesName: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  text: PropTypes.string,
  multi: PropTypes.bool,
  queryParam: PropTypes.string,
  dropdownPlacement: PropTypes.string,
};

FilterComponent.defaultProps = {
  text: '',
  multi: false,
  queryParam: 'u',
};

export default FilterComponent;

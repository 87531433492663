/* eslint-disable no-multi-spaces */
const de = {
  name: 'de',
  steps: [
    [[0, 'seconds'],    'vor wenigen Sekunden',     'in wenigen Sekunden'],
    [[1, 'minute'],     'vor 1 Minute',             'in 1 Minute'],
    [[2, 'minutes'],    'vor %minutes Minuten',     'in %minutes Minuten'],
    [[1, 'hour'],       'vor einer Stunde',         'in einer Stunde'],
    [[2, 'hour'],       'vor %hours Stunden',       'in %hours Stunden'],
    [[1, 'days'],       'gestern',                  'morgen'],
    [[2, 'days'],       'vor %days Tagen',          'in %days Tagen'],
    [[1, 'month'],      'vor einem Monat',          'in einem Monat'],
    [[2, 'months'],     'vor %months Monaten',      'in %months Monaten'],
    [[1, 'year'],       'vor einem Jahr',           'in einem Jahren'],
    [[2, 'year'],       'vor %years Jahren',        'in %years Jahren'],
  ],
};

export default de;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Cookies from 'js-cookie';

import Modal from '../shared/Modal';
import Icon from '../shared/Icon';

import FolderRaw from './FolderRaw';
import File from './File';

import { getAsync } from '../../helpers/rails_helper';
import SwitchLayoutButton from './SwitchLayoutButton';

const getInitialState = () => ({
  selectedAttachments: [],
  folders: [],
  files: [],
  loadingFolders: true,
  loadingFiles: true,
  folderRoute: [],
  layout: Cookies.get('mini-file-browser-layout') || 'list',
});

class MiniFileBrowser extends Component {
  constructor(props) {
    super(props);

    this.state = getInitialState();
  }

  // Ausgewählten Ordner zurücksetzen, wenn nicht sichtbar
  // um bei erneutem Aufruf wieder Root zu rendern
  static getDerivedStateFromProps(props, state) {
    if (!props.visible) {
      state.folderRoute = [];
      state.selectedAttachments = [];
    }
    return state;
  }

  componentDidMount() {
    if (this.props.visible) {
      this.loadData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.visible && this.props.visible) {
      setTimeout(() => {
        this.loadData();
      }, 200);
    } else if (prevState.folderRoute.length !== this.state.folderRoute.length) {
      this.loadData();
    } else if (prevProps.visible && !this.props.visible) {
      setTimeout(() => {
        this.setState(getInitialState());
      }, 200);
    }
  }

  handleFolderClick = (e, folder) => {
    e.preventDefault();
    this.setState({
      folderRoute: [...this.state.folderRoute, folder],
    });
  };

  handleFileClick = (e, file) => {
    e.preventDefault();

    const selected = this.state.selectedAttachments.find(f => f.id === file.id);

    if (selected) {
      this.setState({
        selectedAttachments: this.state.selectedAttachments.filter(f => f.id !== file.id),
      });
    } else {
      this.setState({
        selectedAttachments: [...this.state.selectedAttachments, file],
      });
    }
  };

  handleReturnToParent = (e) => {
    e.preventDefault();

    const newFolderRoute = [...this.state.folderRoute];

    newFolderRoute.pop();

    this.setState({
      folderRoute: newFolderRoute,
    });
  };

  handleDone = (e) => {
    e.preventDefault();
    if (this.props.onSelect) {
      this.props.onSelect(this.state.selectedAttachments);
    }
  };

  handleLayoutSwitch = () => {
    const newLayout = (this.state.layout === 'list' ? 'grid' : 'list');

    this.setState((prevSate) => ({
      layout: newLayout,
    }));

    Cookies.set('mini-file-browser-layout', newLayout, {
      expires: 365,
      path: '/',
      secure: false,
    });
  };

  loadData() {
    this.setState({
      loadingFolders: true,
      loadingFiles: true,
    });

    let foldersPath = `/folderables/${this.props.project_id}-Project/folders.json`;
    let attachmentsPath = `/folderables/${this.props.project_id}-Project/attachments.json`;

    if (this.state.folderRoute.length) {
      const currentFolder = this.state.folderRoute[this.state.folderRoute.length - 1];
      foldersPath = `/folderables/${currentFolder.id}-Folder/folders.json`;
      attachmentsPath = `/folderables/${currentFolder.id}-Folder/attachments.json`;
    }

    getAsync(foldersPath).then((result) => {
      this.setState({
        folders: result.folders,
        loadingFolders: false,
      });
    });

    getAsync(attachmentsPath).then((result) => {
      this.setState({
        files: result.attachments,
        loadingFiles: false,
      });
    });
  }

  renderFolders() {
    return this.state.folders.map((folder) => {
      return (
        <div className="col-sm-6 col-md-4 col-folder" key={`folder-${folder.id}`}>
          <FolderRaw
            folder={folder}
            onClick={e => this.handleFolderClick(e, folder)}
          />
        </div>
      );
    });
  }

  renderFiles() {
    if (this.state.files.length) {
      return this.state.files.map((file) => {
        return (
          <div className="draggable-file" key={`file-${file.id}`}>
            <File
              {...file}
              selectable
              selected={!!this.state.selectedAttachments.find(f => f.id === file.id)}
              onClick={e => this.handleFileClick(e, file)}
            />
          </div>
        );
      });
    } else if (!this.state.loadingFiles) {
      return this.renderNoFiles();
    }

    return null;
  }

  renderNoFiles() {
    return (
      <div className="no-files-in-folder">
        <Icon name="ban" className="no-file-icon" />
        <p className="head">Keine Dateien in diesem Ordner</p>
      </div>
    );
  }

  renderBackButton() {
    if (this.state.folderRoute.length) {
      return (
        <a className="mini-file-browser-back-button" href="" onClick={this.handleReturnToParent}>
          <Icon name="arrow-left"></Icon>
        </a>
      );
    }

    return null;
  }

  renderTitle() {
    if (this.state.folderRoute.length) {
      return this.state.folderRoute[this.state.folderRoute.length - 1].name;
    }
    return 'Dokumente einfügen';
  }

  renderBreadCrumbs() {
    return this.state.folderRoute.map((f) => {
      return (
        <span style={{ display: 'inline-block', margin: 5 }}>
          {f.name}
        </span>
      );
    });
  }

  renderFilesSelectedCount() {
    if (this.state.selectedAttachments.length === 1) {
      return (
        <span>
          1 <span className="d-none d-sm-inline">Datei</span> ausgewählt
        </span>
      );
    } else if (this.state.selectedAttachments.length > 1) {
      return (
        <span>
          {this.state.selectedAttachments.length} <span className="d-none d-sm-inline">Dateien</span> ausgewählt
        </span>
      );
    }
    return (
      <span>
        Keine <span className="d-none d-sm-inline">Dateien</span> ausgewählt
      </span>
    );
  }

  render() {
    const loadingClass = (this.state.loadingFolders || this.state.loadingFiles) ? 'box-loading' : '';

    return (
      <Modal size="large" visible={this.props.visible} onClose={this.props.onClose}>
        <div className="react-modal-header align-items-center">
          <div className="flex-grow-1">
            <h4>
              {this.renderBackButton()}
              {this.renderTitle()}
            </h4>
          </div>
          <div>
            <SwitchLayoutButton
              onClick={this.handleLayoutSwitch}
              layout={this.state.layout}
            />
          </div>
        </div>
        <div className={`react-modal-content mini-file-browser-content ${loadingClass}`}>
          <div className="filebrowser mini-file-browser">
            <div className="row row-folder-area">
              {this.renderFolders()}
            </div>
            <div className="file-area">
              <div className={`file-area-${this.state.layout}`}>
                {this.renderFiles()}
              </div>
            </div>
          </div>
        </div>
        <div className="react-modal-footer">
          <div className="text-muted">
            {this.renderFilesSelectedCount()}
          </div>
          <div>
            <button
              className="btn btn-light"
              onClick={this.props.onClose}>Abbrechen</button>{' '}
            <button
              className="btn btn-primary"
              disabled={!this.state.selectedAttachments.length}
              onClick={this.handleDone}>
              Einfügen
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

MiniFileBrowser.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  project_id: PropTypes.number.isRequired,
  onSelect: PropTypes.func,
};

export default MiniFileBrowser;

import React from 'react';
import PropTypes from 'prop-types';

import ConditionComps from './ConditionComps';

import LogicalConnectorSelector from './LogicalConnectorSelector';

import { generateId } from '../../helpers/helpers';

const getDefaultCondition = () => {
  return {
    id: generateId(),
    operator: 'is',
    value: null,
    type: 'NullCondition', // Mit einer leeren Bedingung initialisieren
  };
};

const getDefaultConditionGroup = () => {
  return {
    id: generateId(),
    conditions: [getDefaultCondition()],
    conditions_to_be_removed: [],
    logical_connector: 'and',
  };
};

class ConditionGroup extends React.Component {
  handleChangeCondition = (updatedCondition) => {
    this.props.onChange(Object.assign({}, this.props.conditionGroup, {
      conditions: this.props.conditionGroup.conditions.map((c) => {
        return (c.id === updatedCondition.id ? updatedCondition : c);
      }),
    }));
  };

  handleLogicalConnectorChange = (newValue) => {
    this.props.onChange(Object.assign({}, this.props.conditionGroup, {
      logical_connector: newValue,
    }));
  };

  handleAddCondition = (values = {}) => {
    this.props.onChange(Object.assign({}, this.props.conditionGroup, {
      conditions: [...this.props.conditionGroup.conditions, Object.assign({}, getDefaultCondition(), values)],
    }));
  };

  handleRemoveCondition = (condition) => {
    if (this.props.conditionGroup.conditions.length > 1) {
      const alteredConditionGroup = Object.assign({}, this.props.conditionGroup, {
        conditions: this.props.conditionGroup.conditions.filter(c => c.id !== condition.id),
      });

      if (condition.persisted) {
        alteredConditionGroup.conditions_to_be_removed =
          [...(this.props.conditionGroup.conditions_to_be_removed || []), condition];
      }

      this.props.onChange(alteredConditionGroup);
    } else {
      this.props.onRemoveConditionGroup(this.props.conditionGroup);
    }
  };

  handleReplaceCondition = (oldCondition, newConditionValues) => {
    const newCondition = Object.assign({}, getDefaultCondition(), newConditionValues);

    const alteredConditionGroup = Object.assign({}, this.props.conditionGroup, {
      conditions: [
        ...this.props.conditionGroup.conditions.filter(c => c.id !== oldCondition.id),
        newCondition,
      ],
    });

    if (oldCondition.persisted) {
      alteredConditionGroup.conditions_to_be_removed =
        [...(this.props.conditionGroup.conditions_to_be_removed || []), oldCondition];
    }

    this.props.onChange(alteredConditionGroup);
  };

  renderConditions() {
    return this.props.conditionGroup.conditions.map((condition) => {
      const Comp = ConditionComps[condition.type];

      return (
        <Comp
          key={condition.id}
          condition={condition}
          onChange={this.handleChangeCondition}
          onRemove={this.handleRemoveCondition}
          onAddCondition={this.handleAddCondition}
          onReplaceCondition={this.handleReplaceCondition}
          isSingle={this.props.conditionGroup.conditions.length === 1}
          optionsUrl={this.props.optionsUrl}
          organizationSlug={this.props.organizationSlug}
        />
      );
    });
  }

  renderTitle() {
    if (this.props.conditionGroup.conditions.length < 2) return null;

    const parts = this.props.conditionGroupTitleMulti.split('%');

    return (
      <div style={{ color: 'var(--bs-secondary-color)', fontWeight: 500, fontSize: 13 }}>
        {parts[0]} <LogicalConnectorSelector
          value={this.props.conditionGroup.logical_connector}
          onChange={this.handleLogicalConnectorChange}
          andText={this.props.conditionGroupAndText}
          orText={this.props.conditionGroupOrText}
        /> {parts[2]}
      </div>
    );
  }

  render() {
    return (
      <div
        style={{
          border: '1px solid var(--bs-border-color)',
          borderRadius: 4,
          padding: '4px 4px',
          backgroundColor: 'var(--bs-secondary-bg)',
          marginBottom: 8,
          marginTop: 4 }}>
        {this.renderTitle()}
        {this.renderConditions()}
      </div>
    );
  }
}

ConditionGroup.propTypes = {
  conditionGroup: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemoveConditionGroup: PropTypes.func.isRequired,
  optionsUrl: PropTypes.string.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  conditionGroupAndText: PropTypes.string,
  conditionGroupOrText: PropTypes.string,
  conditionGroupTitleMulti: PropTypes.string,
};

ConditionGroup.defaultProps = {
  conditionGroupTitleMulti: 'Entspricht %logicalConnector% der folgenden Kriterien:',
};

export default ConditionGroup;

export { getDefaultConditionGroup };

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TextArea from '../shared/TextArea';
import RichTextArea from '../shared/RichTextArea';
import Icon from '../shared/Icon';
import Tooltip from '../shared/Tooltip';

import NewEmailTemplateSection from './NewEmailTemplateSection';
import NewEmailSignature from './NewEmailSignature';

import MiniFileBrowser from '../FileBrowser/MiniFileBrowser';

import ComposeDropzone from './ComposeDropzone';
import ComposeAttachments from './ComposeAttachments';
import ComposeErrors from './ComposeErrors';

import EmailSelect from '../Emails/EmailSelect';
import EmailMultiSelect from '../Emails/EmailMultiSelect';

import { postAsync } from '../../helpers/rails_helper';

class NewEmailForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      attachments: [],
      errors: [],
      fileBrowserOpen: false,
      to: [],
      cc: [],
      bcc: [],
      rich_body: '',
      subject: '',
      email_template_id: null,
      sender_type: 'user',
      sender_name: '',
      sender_email: '',
      personalSignatureId: null,
      organizationSignatureId: null,
    };
  }

  canSend = () => {
    return (
      this.state.sender_email && this.state.to.length &&  this.state.subject.trim() && (
        this.state.rich_body.trim() || this.state.attachments.length
      )
    );
  };

  getDropzoneRef = (node) => {
    this.dropzoneRef = node;
  };

  handleChooseFileClick = (e) => {
    e.preventDefault();
    this.dropzoneRef.open();
  };

  handleUploadsFinished = (uploads) => {
    // Nur Uploads hinzufügen, die noch nicht in der Liste sind
    this.setState((prevState) => ({
      attachments: [
        ...prevState.attachments,
        ...uploads.filter(u => !prevState.attachments.find(u2 => u2.document_id === u.document_id)),
      ],
    }));
  };

  handleOpenFileBrowser = (e) => {
    e.preventDefault();
    this.setState({ fileBrowserOpen: true });
  };

  handleCloseFileBrowser = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ fileBrowserOpen: false });
  };

  handleSelectAttachments = (attachments) => {
    // Bereits hinzugefügte Dateien entfernen (nicht erneut hinzufügen)
    this.setState((prevState) => ({
      fileBrowserOpen: false,
      attachments: [
        ...prevState.attachments,
        ...attachments.filter(a => !prevState.attachments.find(a2 => a2.document_id === a.document_id)),
      ],
    }));
  };

  handleRemoveAttachment = (attachment) => {
    this.setState((prevState) => ({
      attachments: prevState.attachments.filter(a => a.document_id !== attachment.document_id),
    }));
  };

  handleBodyChange = (value) => {
    this.setState({ rich_body: value });
  };

  handleSubjectChange = (e) => {
    e.preventDefault();

    this.setState({ subject: e.target.value });
  };

  handleTemplateChange = (template) => {
    this.setState((prevState) => ({
      email_template_id: template.id,
      rich_body: template.rich_body,
      subject: template.subject,
      sender_type: template.sender_type,
      sender_name: template.sender_name,
      sender_email: template.sender_type === 'anonymous' ? template.sender_email : prevState.sender_email,
      personalSignatureId: template.sender_type === 'anonymous' ? null : prevState.personalSignatureId,
      organizationSignatureId: template.email_signature_id || prevState.organizationSignatureId,
    }));
  };

  handleClearTemplate = (e) => {
    e.preventDefault();

    this.setState(() => ({
      email_template_id: null,
      body: '',
      subject: '',
      sender_type: 'user',
      sender_name: '',
      sender_email: '',
    }));
  };

  handleEmailToChange = (value) => {
    this.setState({ to: value });
  };

  handleEmailCcChange = (value) => {
    this.setState({ cc: value });
  };

  handleEmailBccChange = (value) => {
    this.setState({ bcc: value });
  };

  handleEmailFromChange = (value) => {
    this.setState({ sender_email: value });
  };

  handleSendClick = (e) => {
    e.preventDefault();

    this.props.onSend();

    postAsync('/email_messages/', {
      email_message: {
        email_template_id: this.state.email_template_id,
        from: this.state.sender_email,
        subject: this.state.subject,
        body: this.state.rich_body,
        attachments_attributes: this.state.attachments.map((attachment) => {
          return {
            document_sgid: attachment.document.sgid,
          };
        }),
        email_message_recipients_attributes: [
          ...this.state.to.map(to => ({
            ...to,
            email_field: 'to',
          })),
          ...this.state.cc.map(to => ({
            ...to,
            email_field: 'cc',
          })),
          ...this.state.bcc.map(to => ({
            ...to,
            email_field: 'bcc',
          })),
        ],
        project_id: this.props.project_id,
        emailable_type: 'Todo',
        emailable_id: this.props.todo_id,
        user_signature_id: this.state.personalSignatureId,
        organization_signature_id: this.state.organizationSignatureId,
      },
    }).then(({ errors, activities }) => {
      if (errors) {
        this.setState({ errors });
        this.props.onError();
      } else {
        this.setState({ errors: [] });
        this.props.onSent();

        const event = new CustomEvent('todo:activities:new', {
          detail: {
            todoId: this.props.todo_id,
            activities,
          },
        });

        document.dispatchEvent(event);
      }
    }).catch(({ errors }) => {
      this.setState({ errors });
      this.props.onError();
    });
  };

  handlePersonalSignatureChange = (newValue) => {
    this.setState({ personalSignatureId: newValue });
  };

  handleOrganizationSignatureChange = (newValue) => {
    this.setState({ organizationSignatureId: newValue });
  };

  renderSignatures() {
    return (
      <div style={{ padding: '8px' }}>
        <div style={{ borderTop: '1px solid var(--bs-border-color);', paddingTop: 8 }}>
          <NewEmailSignature
            optionsUrl={this.props.personalSignaturesUrl}
            value={this.state.personalSignatureId}
            placeholder="Persönliche Signatur hinzufügen"
            onChange={this.handlePersonalSignatureChange}
            visible={this.state.sender_type !== 'anonymous'}
            editable
          />
          <NewEmailSignature
            optionsUrl={this.props.organizationSignaturesUrl}
            value={this.state.organizationSignatureId}
            placeholder="Firmensignatur hinzufügen"
            onChange={this.handleOrganizationSignatureChange}
            visible
            editable={this.state.sender_type === 'user'}
          />
        </div>
      </div>
    );
  }

  renderFrom() {
    if (this.state.sender_type === 'user') {
      return (
        <EmailSelect
          onlyVerfied
          noResultText="Es sind keine für den Versand von Emails verifizierten Email-Adressen in deinem Kontakt hinterlegt."
          optionsUrl={this.props.fromEmailsUrl}
          onChange={this.handleEmailFromChange}
        />
      );
    }

    return (
      <span>
        {this.state.sender_name} ({this.state.sender_email})
      </span>
    );
  }

  render() {
    return (
      <ComposeDropzone
        onUploadsFinished={this.handleUploadsFinished}
        getDropzoneRef={this.getDropzoneRef}>
        <NewEmailTemplateSection
          handleTemplateChange={this.handleTemplateChange}
          handleClearTemplate={this.handleClearTemplate}
          emailTemplatesOptionsUrl={this.props.emailTemplatesOptionsUrl}
          contact_id={this.props.contact_id}
          project_id={this.props.project_id}
          sender_type={this.state.sender_type}
        />
        <div>
          <div className="compose-section">
            <div className="compose-section-content">
              <EmailMultiSelect
                value={this.state.to}
                preText="An"
                preselectPreferredClientEmail
                noResultText="Keine Email-Adressen für Kunden hinterlegt"
                optionsUrl={this.props.emailsUrl}
                onChange={this.handleEmailToChange} />
            </div>
          </div>

          <div className="compose-section">
            <div className="compose-section-content">
              <EmailMultiSelect
                value={this.state.cc}
                preText="Kopie"
                noResultText="Keine Email-Adressen für Kunden hinterlegt"
                optionsUrl={this.props.emailsUrl}
                onChange={this.handleEmailCcChange}
              />
            </div>
          </div>

          <div className="compose-section">
            <div className="compose-section-content">
              <EmailMultiSelect
                value={this.state.bcc}
                preText="Blindkopie"
                noResultText="Keine Email-Adressen für Kunden hinterlegt"
                optionsUrl={this.props.emailsUrl}
                onChange={this.handleEmailBccChange}
              />
            </div>
          </div>

          <div className="compose-section">
            <div className="compose-section-content compose-email-from">
              <div>Von </div>
              <div style={{ flexGrow: 1 }}>
                {this.renderFrom()}
              </div>
            </div>
          </div>

          <div className="compose-section">
            <div className="compose-email-subject">
              <input
                type="text"
                placeholder="Betreff"
                disabled={this.state.sender_type === 'anonymous'}
                value={this.state.subject}
                onFocus={() => this.props.onFocus()}
                onBlur={() => this.props.onBlur()}
                onChange={this.handleSubjectChange}
              />
            </div>
          </div>

          <div>
            <RichTextArea
              placeholder="Emailtext"
              disabled={this.state.sender_type === 'anonymous'}
              onFocus={() => this.props.onFocus()}
              onBlur={() => this.props.onBlur()}
              value={this.state.rich_body}
              onChange={this.handleBodyChange}
            />
          </div>

          {this.renderSignatures()}

          <ComposeAttachments
            onRemove={this.handleRemoveAttachment}
            attachments={this.state.attachments}
          />

          <ComposeErrors errors={this.state.errors} />

          <div className="compose-footer">
            <div className="compose-footer-info">
              <div style={{ flexGrow: 1 }}>
                <span className="d-none d-lg-inline">Dateien hierher ziehen oder </span>
                <a href="#" onClick={this.handleChooseFileClick}>
                  <span className="d-none d-lg-inline">hochladen</span>
                  <span className="d-lg-none">Dateien hochladen</span>
                </a>
              </div>
              <div>
                <Tooltip title="Dateien hochladen" placement="top">
                  <a href="" className="compose-footer-icon-button" onClick={this.handleChooseFileClick}>
                    <Icon name="cloud-upload" />
                  </a>
                </Tooltip>
                <Tooltip title="Dokumente einfügen" placement="top">
                  <a href="" className="compose-footer-icon-button" onClick={this.handleOpenFileBrowser}>
                    <Icon name="folder" />
                  </a>
                </Tooltip>
                <MiniFileBrowser
                  project_id={this.props.project_id}
                  visible={this.state.fileBrowserOpen}
                  onClose={this.handleCloseFileBrowser}
                  onSelect={this.handleSelectAttachments}
                />
              </div>
            </div>
            <div className="compose-footer-action">
              <button
                type="button"
                disabled={!this.canSend()}
                onClick={this.handleSendClick}
                className="btn btn-sm btn-success">
                Senden
              </button>
            </div>
          </div>
        </div>
      </ComposeDropzone>
    );
  }
}

NewEmailForm.contextTypes = {
  uploader: PropTypes.object,
};

NewEmailForm.propTypes = {
  emailTemplatesOptionsUrl: PropTypes.string.isRequired,
  todo_id: PropTypes.number.isRequired,
  contact_id: PropTypes.number.isRequired,
  project_id: PropTypes.number.isRequired,
  fromEmailsUrl: PropTypes.string.isRequired,
  emailsUrl: PropTypes.string.isRequired,
  shouldAutoFocus: PropTypes.bool,
  personalSignaturesUrl: PropTypes.string.isRequired,
  organizationSignaturesUrl: PropTypes.string.isRequired,
  onSend: PropTypes.func.isRequired,
  onSent: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

NewEmailForm.defaultProps = {
  shouldAutoFocus: false,
};

export default NewEmailForm;

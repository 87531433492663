import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  static targets = [
    // 'form',// TODO: check that this is still needed
    'invoiceAddress',
    'objectAddress',
    'standardAddress',
    'errorMessage',
    'markedForDestruction'
  ];

  changeInvoiceObjectAddress(event) {
    this.ensureOnlyOneObjectAndOneInvoiceAddressIsChecked(event.target);
  }

  changeStandardAddress(event) {
    this.ensureOneDistinctChecked(this.standardAddressTargets, event.target);
  }

  ensureOneDistinctChecked(targets, target) {
    let count = targets.filter((v,i) => v.checked && !this.isMarkedForDestruction(i)).length;
    if (count === 0) {
      targets[0].checked = true;
    }
    if (count > 1) {
      targets.forEach((el, i) => {
        if (this.isMarkedForDestruction(i)) {
          el.checked = false;
          return;
        }
        if (target !== el) {
          el.checked = false;
        }
      });
    }
  }

  ensureOnlyOneObjectAndOneInvoiceAddressIsChecked(target) {
    this.ensureOneDistinctChecked(this.invoiceAddressTargets, target);
    this.ensureOneDistinctChecked(this.objectAddressTargets, target);
  }

  isMarkedForDestruction(i) {
    if (!this.hasMarkedForDestructionTarget) {
      return false;
    }
    return this.markedForDestructionTargets[i].value === 'true';
  }

  submit(event) {
    let errors = [];
    if (this.hasInvoiceAddressTarget || this.hasObjectAddressTarget) {
      this.ensureOnlyOneObjectAndOneInvoiceAddressIsChecked();
      this.invoiceAddressTargets.forEach((el, i) => {
        if (this.isMarkedForDestruction(i)) {
          return;
        }
        if (!el.checked && !this.objectAddressTargets[i].checked) {
          errors.push('Bitte alle Adressen entfernen, die weder Rechnungs- noch Objektadresse sind');
        }
      });
    }
    if (this.hasStandardAddressTarget) {
      this.ensureOnlyOneStandardAddressIsChecked();
    }
    // ensure unique error messages
    errors = [...new Set(errors.filter(v => !!v))];

    if (errors.length > 0) {
      event.preventDefault();
      setTimeout(()=> {
        // enable button again
        // TODO: fix this in better/nicer way
        if (event.submitter?.disabled) {
          event.submitter.disabled = false;
        }
      }, 100)

      this.errorMessageTarget.textContent = errors.join('. ');
      this.errorMessageTarget.classList.remove('d-none');
    } else {
      this.errorMessageTarget.classList.add('d-none');
    }
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import { render } from '../../helpers/timeago';

class TimeAgo extends Component {
  constructor(props) {
    super(props);
    this.domNode = React.createRef();
  }

  componentDidMount() {
    render(this.domNode.current);
  }

  componentDidUpdate() {
    render(this.domNode.current);
  }

  render() {
    const { datetime, ...rest } = this.props;
    const title = moment(datetime).format('DD.MM.YYYY HH:mm [Uhr]');

    return (
      <time ref={this.domNode} className="timeago" {...rest} data-datetime={datetime} data-locale="de" title={title}>
        {this.props.datetime}
      </time>
    );
  }
}

TimeAgo.propTypes = {
  datetime: PropTypes.string,
  locale: PropTypes.string,
  title: PropTypes.string,
};

export default TimeAgo;

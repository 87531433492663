/* eslint-disable quote-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../shared/Icon';
import Select, { components } from 'react-select';
import Dropdown from '../shared/Dropdown';
import { smallStyles, defaultStyles } from '../../styles/select_styles';

import { searchText } from '../../helpers/helpers';

const filterAreas = (option, searchTerm) => {
  return searchText(option.data.name, searchTerm);
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      {props.data.name}
    </components.Option>
  );
};

Option.propTypes = {
  data: PropTypes.object,
};

class ZipCodeAreaSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropDownVisible: false,
    };
  }

  handleChange = (tag) => {
    if (this.props.onAdd) {
      this.props.onAdd(tag);
    }
  }

  toggleDropDown = (e) => {
    e.preventDefault();

    const visible = !this.state.dropDownVisible;

    if (this.props.onOpen) {
      this.props.onOpen();
    }

    this.setState({ dropDownVisible: visible });
  }

  handleBlur = () => {
    this.setState({ dropDownVisible: false });
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      this.toggleDropDown(e);
    }
  }

  renderTarget = (ref, instance) => {
    if (this.props.target) {
      return this.props.target(ref, this);
    }

    return (
      <button ref={ref} className="btn btn-light btn-label" onClick={this.toggleDropDown}>
        <Icon name="plus" /> {this.props.buttonText}
      </button>
    );
  }

  render() {
    return (
      <Dropdown
        ref={n => (this.dropdown = n)}
        isOpen={this.state.dropDownVisible}
        onClose={this.toggleDropDown}
        target={this.renderTarget}>
        <Select
          autoFocus
          components={{
            Option,
            DropdownIndicator: null,
            IndicatorSeparator: null,
          }}
          openMenuOnFocus
          clearable={false}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
          backspaceRemovesValue={false}
          blurInputOnSelect
          menuPlacement="auto"
          menuIsOpen
          filterOption={filterAreas}
          onChange={this.handleChange}
          options={this.props.options}
          defaultOptions
          styles={defaultStyles}
          placeholder="Tag hinzufügen"
          noOptionsMessage={() => 'Keine Ergebnisse'}
        />
      </Dropdown>
    );
  }
}

ZipCodeAreaSelector.propTypes = {
  onAdd: PropTypes.func,
  onOpen: PropTypes.func,
  // excludedTagIds: PropTypes.array,
  options: PropTypes.array.isRequired,
  buttonText: PropTypes.string,
  target: PropTypes.func,
};

ZipCodeAreaSelector.defaultProps = {
  buttonText: 'Gebiet',
};

export default ZipCodeAreaSelector;

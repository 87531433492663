import React, { Component, Fragment } from 'react';

import PropTypes from 'prop-types';

import Label from '../shared/Label';
import ProjectTypeButtonSelector from './ProjectTypeButtonSelector';

import HiddenInputWrapper from '../shared/HiddenInputWrapper';

class ProjectTypeMultiSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projectTypes: this.props.projectTypes,
    };
  }

  handleAdd = (projectType) => {
    this.setState({
      projectTypes: [...this.state.projectTypes, projectType],
    });
  }

  handleRemove = (projectType) => {
    this.setState({
      projectTypes: this.state.projectTypes.filter(s => (s.id !== projectType.id)),
    });
  }

  renderProjectTypes() {
    return this.state.projectTypes.map(projectType => (
      <HiddenInputWrapper
        name={`${this.props.name}[]`}
        title={projectType.title}
        data={projectType}
        color="#aaa"
        textColor="#fff"
        value={projectType.id}
        comp={Label}
        key={projectType.id}
        removable
        onRemove={this.handleRemove} />
    ));
  }

  renderEmptyHiddenInput() {
    return (
      <input type="hidden" name={`${this.props.name}[]`} value="" />
    );
  }

  renderSeperator = () => {
    if (this.state.projectTypes.length) {
      return (
        <span className="seperator">|</span>
      );
    }
    return null;
  }

  render() {
    const selectedIds = this.state.projectTypes.map(s => s.id);
    const projectTypesOptions = this.props.projectTypeOptions.filter(s => !selectedIds.includes(s.id));

    return (
      <Fragment>
        {this.renderEmptyHiddenInput()}
        {this.renderProjectTypes()}
        {this.renderSeperator()}
        <ProjectTypeButtonSelector
          excludedProjectTypeIds={this.state.projectTypes.map(t => t.id)}
          buttonText={this.state.projectTypes.length ? 'Typ' : 'Typ hinzufügen'}
          onAdd={this.handleAdd}
          options={projectTypesOptions} />
      </Fragment>
    );
  }
}

ProjectTypeMultiSelect.propTypes = {
  projectTypes: PropTypes.array,
  projectTypeOptions: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
};

export default ProjectTypeMultiSelect;

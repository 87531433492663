/* global window */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../shared/Tooltip';
import Dropdown from '../shared/Dropdown';

import MembershipDetails from './MembershipDetails';

class ProjectMembership extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown(event) {
    if (event.keyCode === 27) {
      this.setState({ dropdownOpen: false });
    }
  }

  handleClick = (e) => {
    e.preventDefault();
    this.setState({ dropdownOpen: true });
  };

  handleClose = () => {
    this.setState({ dropdownOpen: false });
  };

  handleUpdate = (membership) => {
    this.props.onUpdate(membership);
  };

  renderTarget = (ref) => {
    const { fullname, bio, avatar } = this.props.membership.user;
    const title = bio ? `${fullname} (${bio})` : fullname;

    const classNames = ['avatar-wrapper avatar-wrapper-project'];

    if (this.props.membership.responsible_for_active_todo) {
      classNames.push('avatar-responsible');
    }

    if (this.props.membership.due && Date.parse(this.props.membership.due) < new Date()) {
      classNames.push('avatar-responsible-overdue');
    }

    const image = (
      <img
        alt={fullname}
        src={avatar.thumb.url}
        className="avatar avatar-project" />
    );

    const link = (
      <a href="" ref={ref} onClick={this.handleClick} className="project-membership-link">
        {image}
      </a>
    );

    return (
      <Tooltip placement="top-end" title={title}>
        <span className={classNames.join(' ')}>
          {link}
        </span>
      </Tooltip>
    );
  };

  render() {
    const { dropdownOpen } = this.state;

    return (
      <div className="project-membership">
        <Dropdown
          target={this.renderTarget}
          placement="bottom-end"
          isOpen={dropdownOpen}
          onClose={this.handleClose}>
          <MembershipDetails
            editable={this.props.editable}
            membership={this.props.membership}
            onDelete={this.props.onDelete}
            onUpdate={this.handleUpdate} />
        </Dropdown>
      </div>
    );
  }
}

ProjectMembership.propTypes = {
  membership: PropTypes.object,
  onClick: PropTypes.func,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  editable: PropTypes.bool,
};

export default ProjectMembership;

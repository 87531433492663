import React from 'react';
import PropTypes from 'prop-types';

import EditorModal from './EditorModal';
import ConfirmModal from '../shared/ConfirmModal';

import { deleteWorkflowEditorship } from '../../helpers/api';

class WorkflowTemplateEditorships extends React.Component {
  handleEditorshipDeleted = (editorship) => {
    const newEditorships = this.props.workflow_editorships.filter(e => e.id !== editorship.id);
    this.props.onChange(newEditorships);
  }

  handleEditorshipAdded = (editorship) => {
    const newEditorships = [...this.props.workflow_editorships, editorship];
    this.props.onChange(newEditorships);
  }

  renderEditors() {
    return this.props.workflow_editorships.map(editorship => (
      <div className="workflow-editorship" key={editorship.id}>
        <div className="workflow-editorship-user">
          <img src={editorship.user.avatar.thumb.url} className="avatar avatar-small" />
          {editorship.user.fullname}
        </div>
        <div className="workflow-editorship-buttons">
          <ConfirmModal
            visible={this.props.editable}
            target={onClick => (
              <button
                type="button"
                className="btn btn-compact btn-link"
                onClick={onClick}>
                entfernen
              </button>
            )}
            title="Aktion löschen"
            cancelText="abbrechen"
            confirmText="löschen"
            confirmStyle="danger"
            onConfirm={() => deleteWorkflowEditorship(editorship)}
            onConfirmed={() => this.handleEditorshipDeleted(editorship)}>
              Bist du sicher, dass <strong>{editorship.user.fullname}</strong> entfernen möchtest?
          </ConfirmModal>
        </div>
      </div>
    ));
  }

  render() {
    return (
      <div>
        <p className="text-muted">
          {this.props.editable ? (
            ' Füge Benutzer hinzu, um ihnen Bearbeitungszugriff auf die Vorlage zu geben.'
          ) : null}
        </p>
        <p className="text-muted">
          {this.props.workflow_editorships.length ? (
            ' Diese Personen dürfen die Vorlage bearbeiten:'
          ) : null}
        </p>
        <div style={{ marginBottom: 5 }}>
          {this.renderEditors()}
        </div>
        <p>
          <EditorModal
            creatorId={this.props.creatorId}
            editorIds={this.props.editorIds}
            organizationSlug={this.props.organizationSlug}
            visible={this.props.editable}
            title="Neue Rolle"
            onSaved={this.handleEditorshipAdded}
            initialWorkflowEditorship={{
              user_id: '',
              workflow_id: this.props.workflowId,
            }}>
            {onClick => (
              <button className="btn btn-success btn-compact" onClick={onClick}>
                Benutzer hinzufügen
              </button>
            )}
          </EditorModal>
        </p>
      </div>
    );
  }
}

WorkflowTemplateEditorships.propTypes = {
  creatorId: PropTypes.number,
  editorIds: PropTypes.array,
  workflow_editorships: PropTypes.array.isRequired,
  editable: PropTypes.bool,
  workflowId: PropTypes.number.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default WorkflowTemplateEditorships;

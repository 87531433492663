import React, { Component, Fragment } from 'react';

import PropTypes from 'prop-types';

import Label from '../shared/Label';
import ProjectStateSelector from './ProjectStateSelector';

import HiddenInputWrapper from '../shared/HiddenInputWrapper';

class ProjectStateMultiSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projectStates: this.props.projectStates,
    };
  }

  handleAdd = (projectState) => {
    this.setState({
      projectStates: [...this.state.projectStates, projectState],
    });
  }

  handleRemove = (projectState) => {
    this.setState({
      projectStates: this.state.projectStates.filter(s => (s.id !== projectState.id)),
    });
  }

  renderProjectStates() {
    return this.state.projectStates.map(projectState => (
      <HiddenInputWrapper
        name={`${this.props.name}[]`}
        title={projectState.state}
        data={projectState}
        color={projectState.color}
        textColor={projectState.text_color}
        value={projectState.id}
        comp={Label}
        key={projectState.id}
        removable
        onRemove={this.handleRemove} />
    ));
  }

  renderEmptyHiddenInput() {
    return (
      <input type="hidden" name={`${this.props.name}[]`} value="" />
    );
  }

  renderSeperator = () => {
    if (this.state.projectStates.length) {
      return (
        <span className="seperator">|</span>
      );
    }
    return null;
  }

  render() {
    const selectedIds = this.state.projectStates.map(s => s.id);
    const projectStatesOptions = this.props.projectStateOptions.filter(s => !selectedIds.includes(s.id));

    return (
      <Fragment>
        {this.renderEmptyHiddenInput()}
        {this.renderProjectStates()}
        {this.renderSeperator()}
        <ProjectStateSelector
          excludedProjectStateIds={this.state.projectStates.map(t => t.id)}
          buttonText={this.state.projectStates.length ? 'Status' : 'Status hinzufügen'}
          onAdd={this.handleAdd}
          options={projectStatesOptions} />
      </Fragment>
    );
  }
}

ProjectStateMultiSelect.propTypes = {
  projectStates: PropTypes.array,
  projectStateOptions: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
};

export default ProjectStateMultiSelect;

/* global document */

import $ from 'jquery'

const initColorOverdue = () => {
  $('[data-behavior="color-overdue"]').each((i, element) => {
    const due = $(element).data('due')
    const date = new Date(due)

    if (date < new Date()) {
      $(element).addClass('overdue')
    }
  })
}
document.addEventListener('turbo:load', initColorOverdue)
document.addEventListener('turbo:render', initColorOverdue)

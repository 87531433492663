import React from 'react';
import PropTypes from 'prop-types';

import ActionTemplateForm from './ActionTemplateForm';
import RemoteFormModal from '../shared/RemoteFormModal';

import actionTemplateValidator from '../../validators/action_template_validator';
import { saveAction } from '../../helpers/api';

const ActionTemplateModal = props => (
  <RemoteFormModal {...props}
    allowOverflow
    titleNew={`Neue ${props.workflowType === 'checklist' ? 'Antwort' : 'Aktion'}`}
    titleEdit={`${props.workflowType === 'checklist' ? 'Antwort' : 'Aktion'} bearbeiten`}
    validator={actionTemplateValidator}
    form={ActionTemplateForm}
    initialRecord={props.initialAction}
    saveHandler={saveAction}
  />
);

ActionTemplateModal.propTypes = {
  initialAction: PropTypes.object.isRequired,
};

export default ActionTemplateModal;

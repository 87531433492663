/* global google */
import React, { useEffect, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import PropTypes from 'prop-types';

const InfoWindow = (props) => {
  let infowindow;
  const [drawingComplete, setDrawingComplete] = useState(false);

  const openWindow = () => {
    const content = renderToStaticMarkup(props.children);

    infowindow.setContent(content);

    infowindow.open({
      anchor: props.marker,
      map: props.map,
      shouldFocus: false,
    });
  };

  const drawOverlays = () => {
    infowindow = new google.maps.InfoWindow({

    });

    props.marker.addListener('click', () => {
      openWindow();
    });

    if (props.isOpen) {
      openWindow();
    }

    setDrawingComplete(true);
  };

  useEffect(() => {
    if (props.map && props.marker && !drawingComplete) {
      drawOverlays();
    }

    // Cleanup
    return () => {
      if (infowindow) {
        infowindow.setMap(null);
      }
    };
  }, [props.map, props.marker]);

  return (
    <React.Fragment />
  );
};

InfoWindow.propTypes = {
  map: PropTypes.object.isRequired,
  marker: PropTypes.object.isRequired,
  children: PropTypes.node,
  isOpen: PropTypes.bool,
};

export default InfoWindow;

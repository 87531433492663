import React from 'react';
import NoDataPointDisplay from './NoDataPointDisplay';

export default ({ value }) => {
  if (value !== '' && value !== null) {
    return parseFloat(value).toLocaleString('de', {
      maximumFractionDigits: 2,
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'code',
    });
  }

  return <NoDataPointDisplay />;
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SubTodoForm from './SubTodoForm';

import { postAsync } from '../../helpers/rails_helper.js';

const emptyTodo = {
  text: '',
  desc: '',
};

class NewSubTodo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formOpen: false,
      saving: false,
    };
  }

  handleSave = (todo) => {
    this.setState({ saving: true });

    postAsync(`/workflows/${this.props.workflowId}/todos`, {
      todo: {
        text: todo.text,
        desc: todo.desc,
        due: todo.due,
        priority: todo.priority,
        responsible_gid: todo.responsible?.gid || null,
        parent_todo_id: (this.props.parentTodo && this.props.parentTodo.id) || null,
      },
    }).then((result) => {
      this.setState({ formOpen: false, saving: false });
      this.props.onAdd(result.todo);
    });
  }

  handleAddClick = (e) => {
    e.preventDefault();
    this.setState({
      formOpen: true,
    });
  }

  handleCancel = () => {
    this.setState({
      formOpen: false,
    });
  }

  render() {
    if (this.state.formOpen) {
      return (
        <SubTodoForm
          todo={emptyTodo}
          onSave={this.handleSave}
          saving={this.state.saving}
          responsibleOptionsPath={this.props.responsibleOptionsPath}
          onCancel={this.handleCancel} />
      );
    }

    const classNames = ['add-sub-todo'];

    if (this.props.first) {
      classNames.push('add-first-sub-todo');
    }

    return (
      <div className="todo-sub-todos-add">
        <button
          onClick={this.handleAddClick}
          className={classNames.join(' ')}>
          Aufgabe hinzufügen
        </button>
      </div>
    );
  }
}

NewSubTodo.propTypes = {
  onAdd: PropTypes.func.isRequired,
  parentTodo: PropTypes.object,
  workflowId: PropTypes.number.isRequired,
  first: PropTypes.bool,
  responsibleOptionsPath: PropTypes.string.isRequired,
};

export default NewSubTodo;

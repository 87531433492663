import React from 'react';
import PropTypes from 'prop-types';

import Icon from './Icon';

const DragHandle = ({ dragHandleProps }) => {
  return (
    <div {...dragHandleProps} className="draghandle">
      <Icon name="grip-dots-vertical" />
    </div>
  );
};

DragHandle.propTypes = {
  dragHandleProps: PropTypes.object.isRequired,
};

export default DragHandle;

/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Avatar from '../shared/Avatar';

const values = [0, 2, 5, 8];

class TeamWeightsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      team_memberships: props.team_memberships,
    };
  }

  getTotalWeights() {
    return this.state.team_memberships.reduce((a, b) => a + b.weight, 0);
  }

  getPercentage(t) {
    const total = this.getTotalWeights();

    if (total > 0) {
      return ((t.weight / this.getTotalWeights()) * 100).toFixed();
    }

    return ((1.0 / this.state.team_memberships.length) * 100).toFixed();
  }

  handleChange(value, team_membership) {
    const newTeamMemberships = this.state.team_memberships.map(t => (
      (t.id === team_membership.id) ? Object.assign({}, t, { weight: value }) : t
    ));

    this.setState({
      team_memberships: newTeamMemberships,
    });
  }

  renderInputs(t, index) {
    return values.map((v, i) => {
      return (
        <td key={v} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
          <input
            type="radio"
            name={`team[team_memberships_attributes][${index}][weight]`}
            value={v}
            checked={t.weight === v}
            onChange={() => this.handleChange(v, t)}
          />
          <input
            name={`team[team_memberships_attributes][${index}][id]`}
            type="hidden"
            value={t.id}
          />
        </td>
      );
    });
  }

  renderPercentage(t) {
    const p = this.getPercentage(t);
    return (p !== 'NaN') ? `${p}%` : '-';
  }

  renderRows() {
    return this.state.team_memberships.map((t, index) => {
      return (
        <tr key={t.id}>
          <td>
            <Avatar account={t.user} />
            <span style={{ marginLeft: 8 }}>
              {t.user.fullname}
            </span>
          </td>
          {this.renderInputs(t, index)}
          <td className="text-muted" style={{ verticalAlign: 'middle' }}>
            {this.renderPercentage(t)}
          </td>
        </tr>
      );
    });
  }

  render() {
    return (
      <table className="table" style={{ width: 'unset' }}>
        <tbody>
          <tr>
            <th>Gewichtung</th>
            <th style={{ textAlign: 'center' }}>keine</th>
            <th style={{ textAlign: 'center' }}>gering</th>
            <th style={{ textAlign: 'center' }}>mittel</th>
            <th style={{ textAlign: 'center' }}>hoch</th>
            <th />
          </tr>
          {this.renderRows()}
        </tbody>
      </table>
    );
  }
}

TeamWeightsForm.propTypes = {
  team_memberships: PropTypes.array.isRequired,
};

export default TeamWeightsForm;

import React from 'react';
import PropTypes from 'prop-types';

import DataviewItemElement from './DataviewItemElement';

const DataviewItemForm = (props) => {
  const handleChange = (newSection) => {
    if (props.onChange) {
      props.onChange(newSection);
    }
  };

  const handleTitleChange = (e) => {
    e.preventDefault();

    handleChange(
      Object.assign({}, props.record, { title: e.target.value }),
    );
  };

  const handleKeyDown = (e) => {
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) { // CMD + Enter
      if (props.onSave) {
        props.onSave(e);
      }
    }
  };

  const handleElementChange = (element, i) => {
    const newElements = [...props.record.dataview_item_elements];

    // Update existing element
    newElements[i] = {
      ...newElements[i],
      label: element.label,
      name: element.name,
      param: element.param,
      parameter_record: element.parameter_record,
      integer_parameter: element.integer_parameter,
    };

    // remove all existing following elements (mark for destruction)
    const elementsToBeRemoved = [
      ...(props.record.dataview_item_elements_attributes || []).filter(e => !!e._destroy),
      ...newElements.splice(i + 1)
        .filter(e => !!e.id).map((e) => ({
          ...e,
          _destroy: true,
        })),
    ];

    // Add next element, if the new element defines a nested viewer class
    if (element.next_viewer_class_name) {
      newElements[i + 1] = {
        viewer_class_name: element.next_viewer_class_name,
        new: true,
      };
    }

    // construct new default title, if not already changed by user
    const defaultTitle = props.record.dataview_item_elements.map((e) => e.label).filter(l => !!l).join(' - ');
    const hasDefaultTitle = (props.record.title === defaultTitle);
    let newTitle = props.record.title;

    if (hasDefaultTitle || !newTitle) {
      newTitle = newElements.map((e) => e.label).filter(l => !!l).join(' - ');
    }

    handleChange(
      Object.assign({}, props.record, {
        title: newTitle,
        dataview_item_elements: newElements,
        dataview_item_elements_attributes: [
          ...newElements,
          ...elementsToBeRemoved,
        ],
      }),
    );
  };

  const handleHideTimeChange = (e) => {
    handleChange(
      Object.assign({}, props.record, {
        hide_time: e.target.checked,
      }),
    );
  };

  const renderOptions = () => {
    // Get last element in list
    const finalElement = props.record.dataview_item_elements.slice(-1)[0];

    if (finalElement.param?.type === 'DataPointTemplate'
      && finalElement.parameter_record?.data_point_type === 'DataPoint::DateTimeDataPoint') {
      return (
        <div className="form-check form-check-inline">
          <label className="form-check-label">
            <input
              className="form-check-input"
              type="checkbox"
              checked={!!props.record.hide_time}
              onChange={handleHideTimeChange}
            />
            Uhrzeit ausblenden
          </label>
        </div>
      );
    }

    return null;
  };


  const renderElements = () => {
    return props.record.dataview_item_elements.map((element, i) => (
      <DataviewItemElement
        onChange={(e) => handleElementChange(e, i)}
        item={props.record}
        organizationSlug={props.organizationSlug}
        element={element}
        key={i}
      />
    ));
  };

  return (
    <div>
      <div className="mb-3">
        <label className="form-label">Name</label>
        <input
          autoFocus
          className="form-control"
          placeholder="Bitte eingeben"
          autoComplete="off"
          type="text"
          value={props.record.title}
          onChange={handleTitleChange}
          onKeyDown={handleKeyDown}
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Pfad</label>
        {renderElements()}
      </div>
      <div className="mb-3">
        {renderOptions()}
      </div>
    </div>
  );
};

DataviewItemForm.propTypes = {
  record: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  organizationSlug: PropTypes.string.isRequired,
};

export default DataviewItemForm;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AsyncSelect from 'react-select/async';

import { components } from 'react-select';

import { defaultStyles, smallStyles } from '../../styles/select_styles';

const NoComp = () => {
  return null;
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      {props.data.title}
    </components.Option>
  );
};

const SingleValue = (props) => (
  <components.SingleValue {...props}>
    {props.data.title}
  </components.SingleValue>
);

class OfferSelector extends Component {
  constructor(props) {
    super(props);

    this.getOptions = this.getOptions.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  getOptions(searchTerm) {
    const url = new URL(this.props.optionsUrl);
    url.searchParams.append('q', encodeURIComponent(searchTerm));
    if (this.props.status) {
      url.searchParams.append('status', this.props.status);
    }

    return fetch(url, {
      credentials: 'include',
    }).then((response) => {
      console.log(response);
      return response.json();
    }).then((options) => {
      console.log(options);
      return options.offers;
    });
  }

  handleBlur() {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  render() {
    return (
      <AsyncSelect
        components={{
          Option,
          SingleValue,
          DropdownIndicator: this.props.hideDropdownIndicator ?
            NoComp : components.DropdownIndicator,
          IndicatorSeparator: this.props.hideDropdownIndicator ?
            NoComp : components.IndicatorSeparator,
        }}
        defaultValue={this.props.value}
        openMenuOnFocus
        onBlur={this.handleBlur}
        onKeyDown={this.handleKeyDown}
        backspaceRemovesValue={false}
        blurInputOnSelect
        menuPlacement="auto"
        onChange={this.props.onChange}
        handleInputChange={this.handleInputChange}
        loadOptions={this.getOptions}
        defaultOptions
        autoFocus={this.props.autoFocus}
        styles={this.props.size === 'small' ? smallStyles : defaultStyles}
        placeholder={this.props.placeholder}
        loadingPlaceholder="Laden..."
        noOptionsMessage={() => 'Keine Ergebnisse'}
        loadingMessage={() => 'Laden...'}
        isClearable
      />
    );
  }
}

OfferSelector.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  size: PropTypes.string,
  hideDropdownIndicator: PropTypes.bool,
  optionsUrl: PropTypes.string.isRequired,
  status: PropTypes.string,
  onBlur: PropTypes.func,
  autoFocus: PropTypes.bool.isRequired,
};

OfferSelector.defaultProps = {
  placeholder: 'Angebot auswählen',
  autoFocus: true,
};

export default OfferSelector;

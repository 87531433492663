import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import ProjectTypeSelector from './ProjectTypeSelector';

class ProjectTypeSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  handleChange = (v) => {
    this.setState({ value: v });
  }

  render() {
    const { name, ...rest } = this.props;

    return (
      <Fragment>
        <input
          type="hidden"
          name={name}
          value={(this.state.value && this.state.value.id) || ''} />
        <ProjectTypeSelector {...rest} onChange={this.handleChange} value={this.state.value} />
      </Fragment>
    );
  }
}

ProjectTypeSelect.propTypes = {
  value: PropTypes.number,
  name: PropTypes.string,
};

export default ProjectTypeSelect;

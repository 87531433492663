import { Controller } from '@hotwired/stimulus';

import { getAsync } from '../helpers/rails_helper';

export default class extends Controller {
  connect() {
    const countUrl = document.querySelector('[data-count-url]').dataset.countUrl;

    getAsync(countUrl).then((result) => {
      ['mine', 'responsible', 'starred', 'all', 'without_state'].forEach((name) => {
        const element = this.element.querySelector(`[data-project-count="${name}"]`);
        if (element) {
          element.innerHTML = result[name];
          element.classList.remove('badge-loading');
          element.classList.add('badge-appear');
        }
      });

      result.project_states.forEach((state) => {
        const element = this.element.querySelector(`[data-project-count="state-${state.id}"]`);

        // elements for deprecated project_states may not be present
        if (element) {
          element.innerHTML = state.count;
          element.classList.remove('badge-loading');
          element.classList.add('badge-appear');
        }
      });
    });
  }

  disconnect() {
  }
}

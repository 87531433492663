/* global fetch */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import { postAsync } from '../../helpers/rails_helper';

import { smallStyles, defaultStyles } from '../../styles/select_styles';

import UserOption from './UserOption';

const NoComp = () => {
  return null;
};

const SingleValue = props => (
  <components.SingleValue {...props}>
    <span className="fullname_with_avatar">
      <span className="avatar-wrapper avatar-wrapper-mini avatar-link-with-fullname">
        <img src={props.data.avatar.thumb.url} alt={props.data.fullname} className="avatar avatar-mini" />
      </span>
      {props.data.fullname}
    </span>
  </components.SingleValue>
);

class UserSelector extends Component {
  getGroupedOptions({ suggestions = [], users = [] }) {
    const ret = [];

    if (suggestions) {
      ret.push({
        label: 'Vorschläge',
        options: suggestions.filter(u => !this.props.excludeUserIds.includes(u.id)),
      });
    }

    // filter out users whose ID is in exclude list
    // filter out users who are already in suggestions list
    if (users) {
      ret.push({
        label: 'Benutzer',
        options: users
          .filter(u => !this.props.excludeUserIds.includes(u.id))
          .filter(u => !suggestions.find(u2 => u.id === u2.id)),
      });
    }

    return ret;
  };

  getOptions = (searchTerm) => {
    const url = `${this.props.optionsUrl}?q=${encodeURIComponent(searchTerm)}&suggestion_context=${this.props.suggestionContext || 'UserSelector'}&without_companies=1`;

    return fetch(url, {
      credentials: 'include',
    }).then((response) => {
      return response.json();
    }).then((options) => {
      return this.getGroupedOptions(options);
    });
  }

  handleChange = (value) => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }

    if (value) {
      postAsync('/selections', {
        context: this.props.suggestionContext || 'UserSelector',
        to_be_deleted_at: this.props.suggestionLifetime,
        selectable_type: 'User',
        selectable_id: value.id,
      }).then(() => {
        console.log('success');
      }).catch(() => {
        console.log('error');
      });
    }
  }

  render() {
    const styles = this.props.size === 'small' ? smallStyles : defaultStyles
    styles.display = "inline-block"
    return (
      <AsyncSelect
        components={{
          Option: UserOption,
          SingleValue,
          DropdownIndicator: this.props.hideDropdownIndicator ? NoComp : components.DropdownIndicator,
          IndicatorSeparator: this.props.hideDropdownIndicator ? NoComp : components.IndicatorSeparator,
        }}
        isDisabled={this.props.isDisabled}
        defaultValue={this.props.value}
        isClearable
        getValue={options => options.responsible_param}
        openMenuOnFocus
        onBlur={this.handleBlur}
        onKeyDown={this.handleKeyDown}
        backspaceRemovesValue={false}
        blurInputOnSelect
        menuPlacement="auto"
        onChange={this.handleChange}
        handleInputChange={this.handleInputChange}
        loadOptions={this.getOptions}
        defaultOptions
        styles={this.props.size === 'small' ? smallStyles : defaultStyles}
        classNames={this.props.size === 'small' && 'form-control-sm' }
        placeholder={this.props.placeholder || "Benutzer wählen"}
        loadingPlaceholder="Laden..."
        noOptionsMessage={() => 'Keine Ergebnisse'}
        loadingMessage={() => 'Laden...'}
      />
    );
  }
}

UserSelector.propTypes = {
  onChange: PropTypes.func,
  excludeUserIds: PropTypes.array,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  isDisabled: PropTypes.bool,
  size: PropTypes.string,
  hideDropdownIndicator: PropTypes.bool,
  optionsUrl: PropTypes.string.isRequired,
  suggestionContext: PropTypes.string,
};

UserSelector.defaultProps = {
  excludeUserIds: [],
};

export default UserSelector;

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Modal from './Modal';

class ConfirmModal extends Component {
  constructor(props) {
    super(props);

    this.handleOpenModalClick = this.handleOpenModalClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleModalClosed = this.handleModalClosed.bind(this);
    this.handleConfirmClick = this.handleConfirmClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.state = {
      modalVisible: false,
    };
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown(e) {
    if (this.state.modalVisible && e.keyCode === 13) { // Enter
      this.handleConfirmClick(e);
    }
  }

  handleOpenModalClick(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (this.props.onOpen) {
      this.props.onOpen();
    }

    this.setState({ modalVisible: true });
  }

  handleCancelClick() {
    this.setState({
      modalVisible: false,
    });
  }

  handleModalClosed() {
    this.setState({
      saving: false,
    });

    if (this.props.onCancel) {
      this.props.onCancel();
    }

    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  handleConfirmClick(e) {
    e.preventDefault();

    this.setState({
      saving: true,
    });

    if (this.props.onConfirm) {
      this.props.onConfirm().then(() => {
        this.setState({ modalVisible: false });
        setTimeout(() => {
          if (this.props.onConfirmed) {
            this.props.onConfirmed();
          }
        }, 200);
      });
    } else {
      this.setState({ modalVisible: false });
      setTimeout(() => {
        if (this.props.onConfirmed) {
          this.props.onConfirmed();
        }
      }, 200);
    }
  }

  renderTarget() {
    if (this.props.target) {
      return this.props.target(this.handleOpenModalClick);
    }

    return (
      <button type="button">Modal öffnen</button>
    );
  }

  render() {
    if (!this.props.visible) return null;

    const confirmClass = `btn-${this.props.confirmStyle}`;
    const loadingClass = this.state.saving ? 'btn-loading' : '';

    return (
      <>
        {this.renderTarget()}
        <Modal
          visible={this.state.modalVisible}
          onClose={this.handleCancelClick}
          onClosed={this.handleModalClosed}>
          <div className="react-modal-header">
            <h4>{this.props.title}</h4>
          </div>
          <div className="react-modal-content">
            {this.props.children}
          </div>

          <div className="react-modal-footer">
            <div className="text-muted" />
            <div>
              <button
                type="button"
                className="btn btn-light"
                onClick={this.handleCancelClick}
                disabled={this.state.saving}>
                {this.props.cancelText}
              </button>{' '}
              <button
                type="button"
                className={['btn', confirmClass, loadingClass].join(' ')}
                onClick={this.handleConfirmClick}>
                {this.props.confirmText}
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

ConfirmModal.propTypes = {
  target: PropTypes.func,
  title: PropTypes.string,
  confirmStyle: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onConfirmed: PropTypes.func,
  onCancel: PropTypes.func,
  children: PropTypes.node,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  visible: PropTypes.bool,
};

ConfirmModal.defaultProps = {
  title: 'Bist du sicher?',
  confirmStyle: 'success',
  children: 'Bist du sicher, dass du fortfahren willst?',
  confirmText: 'Ja',
  cancelText: 'Nein',
  visible: true,
};

export default ConfirmModal;

/* global document */
/* eslint-disable prefer-arrow-callback */

import $ from 'jquery';

$(document).on('click', '.email-preferred-for-sending', function (e) {
  $('.email-preferred-for-sending').not(this).prop('checked', false);
  $(this).prop('checked', true);
});

$(document).on('click', 'a.add-link', function (e) {
  e.preventDefault();
  $(e.target).parents('div').find('form').removeClass('hidden').show();
  $(e.target).parents('div').find('form').find('input[type=text]').first().focus();
  $(e.target).hide();
  return false;
});

$(document).on('click', 'a#extended-contact-fields-link', (e) => {
  e.preventDefault();
  $('div#extended-contact-fields').removeClass('d-none');
  $('div#extended-contact-fields-link-container').addClass('d-none');
});

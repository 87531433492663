import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../shared/Icon';
import DatetimePicker from "../../DatePickers/DatetimePickerComponent";

const SetDueEffectForm = ({ value, onChange }) => (
  <div className="fire-effect-item">
    <div className="fire-effect-icon">
      <Icon name="alarm-clock" />
    </div>
    <div className="fire-effect-content">
      <div className="fire-effect-title">
        Fälligkeit der Folgeaufgaben setzen
      </div>
      <div className="fire-effect-inputs">
        <div className="row">
          <div className="col-sm-6">
            <DatetimePicker
              onChange={v => onChange({ due: v })}
              value={value && value.due}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

SetDueEffectForm.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default SetDueEffectForm;

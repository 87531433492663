// Adapted from Bootstrap Docs Page

/*!
 * Color mode toggler for Bootstrap's docs (https://getbootstrap.com/)
 * Copyright 2011-2024 The Bootstrap Authors
 * Licensed under the Creative Commons Attribution 3.0 Unported License.
 */

const themeColorLight = '#e9ecef';
const themeColorDark = '#343a40';

const getStoredTheme = () => localStorage.getItem('theme');
const setStoredTheme = theme => localStorage.setItem('theme', theme);

const getPreferredTheme = () => {
  const storedTheme = getStoredTheme();
  if (storedTheme) {
    return storedTheme;
  }

  return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
};

const setTheme = theme => {
  if (theme === 'auto') {
    document.documentElement.setAttribute('data-bs-theme', (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'));
  } else {
    document.documentElement.setAttribute('data-bs-theme', theme);
  }
};

const showActiveTheme = (theme) => {
  const btnsToActive = document.querySelectorAll(`[data-bs-theme-value="${theme}"]`);

  let themeColor = themeColorLight;

  if (theme === 'auto') {
    themeColor = (window.matchMedia('(prefers-color-scheme: dark)').matches ? themeColorDark : themeColorLight);
  } else if (theme === 'dark') {
    themeColor = themeColorDark;
  }

  document.querySelector('meta[name="theme-color"]').setAttribute('content', themeColor);

  document.querySelectorAll('[data-bs-theme-value]').forEach(element => {
    element.classList.remove('active-theme');
  });

  btnsToActive.forEach(element => {
    element.classList.add('active-theme');
  });
};

const initThemeSwitcher = () => {
  setTheme(getPreferredTheme());

  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
    const storedTheme = getStoredTheme();
    if (storedTheme !== 'light' && storedTheme !== 'dark') {
      setTheme(getPreferredTheme());
    }
  });

  showActiveTheme(getPreferredTheme());

  document.querySelectorAll('[data-bs-theme-value]')
    .forEach(toggle => {
      toggle.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        const theme = toggle.getAttribute('data-bs-theme-value');
        setStoredTheme(theme);
        setTheme(theme);
        showActiveTheme(theme);
      });
    });
};

document.addEventListener('turbo:load', initThemeSwitcher);
document.addEventListener('turbo:render', initThemeSwitcher);

initThemeSwitcher();

export {
  getPreferredTheme,
};

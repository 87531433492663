import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import TimeAgo from '../shared/TimeAgo';

const DueBadge = ({ todo, style }) => {
  if (!todo.active || !todo.due || todo.complete) return null;

  let statusClass = 'todo-badge-success';

  if (Date.parse(todo.due) < new Date()) {
    statusClass = 'todo-badge-danger';
  }

  return (
    <div className={`todo-badge ${statusClass}`} style={style}>
      <Icon name="clock" /> <TimeAgo datetime={todo.due} locale="de" />
    </div>
  );
};

DueBadge.propTypes = {
  todo: PropTypes.object,
  style: PropTypes.object,
};

export default DueBadge;

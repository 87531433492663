import React from 'react';
import PropTypes from 'prop-types';

import EmailTemplateSelector from '../../EmailTemplates/EmailTemplateSelector';

class EmailEffectForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleTemplateChange = this.handleTemplateChange.bind(this);
  }

  handleTemplateChange(newTemplate) {
    console.log(newTemplate);
    this.props.onChange(Object.assign({}, this.props.record, {
      email_template: newTemplate,
      email_template_id: newTemplate && newTemplate.id,
    }));
  }

  render() {
    return (
      <div className="mb-3">
        <label className="form-label">Email Vorlage auswählen</label>
        <EmailTemplateSelector
          optionsUrl={`/${this.props.organizationSlug}/email_templates.json`}
          value={this.props.record.email_template}
          onChange={this.handleTemplateChange}
          autoFocus={false}
        />
      </div>
    );
  }
}

EmailEffectForm.propTypes = {
  record: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default EmailEffectForm;

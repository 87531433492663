import React from 'react';
import PropTypes from 'prop-types';

import AssignmentSelector from '../../Assignments/AssignmentSelector';

class AssignWorkflowEffectForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(assignment) {
    this.props.onChange(Object.assign({}, this.props.record, {
      assignment,
      assignment_id: assignment && assignment.id,
    }));
  }

  render() {
    return (
      <div className="mb-3">
        <label className="form-label">Workflow-Zuweisung auswählen</label>
        <AssignmentSelector
          optionsUrl={`/${this.props.organizationSlug}/assignments.json?assignment_type=Assignments::WorkflowAssignment`}
          assigneeType="Assignments::WorkflowAssignment"
          value={this.props.record.assignment}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

AssignWorkflowEffectForm.propTypes = {
  record: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default AssignWorkflowEffectForm;

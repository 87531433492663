import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ContactNoteTypeSelector from './ContactNoteTypeSelector';
import DatePicker from "../DatePickers/DatePickerComponent";
import { postAsync } from '../../helpers/rails_helper';

class ContactNoteForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focus: false,
      noteType: '',
      body: '',
      noteAt: null,
    };
  }

  isValid = () => {
    return this.state.body.trim();
  }

  handleFocus = () => {
    this.setState({ focus: true });
  }

  handleNoteTypeChange = (noteType) => {
    console.log(noteType);
    this.setState({ noteType });
  }

  handleBodyChange = (e) => {
    this.setState({ body: e.target.value });
  }

  handleNoteAtChange = (value) => {
    this.setState({
      noteAt: value,
    });
  }

  handleSaveClick = (e) => {
    e.preventDefault();

    postAsync(`/contacts/${this.props.contactId}/contact_notes.json`, {
      contact_note: {
        note_type: this.state.noteType,
        body: this.state.body,
        note_at: this.state.noteAt,
      },
    }).then((result) => {
      console.log(result);
      this.props.onAdded(result.contact_note);
      this.setState({
        body: '',
        focus: false,
      });
    });
  }

  renderAdditionalFields() {
    if (!this.state.focus) return null;

    return (
      <div className="row gutter-xs contact-item-row contact-note-input-row">
        <div className="col-xs-6 col-sm-5">
          <ContactNoteTypeSelector
            value={this.state.noteType}
            onChange={this.handleNoteTypeChange}
          />
        </div>
        <div className="col-xs-6 col-sm-4">
          <DatePicker value={this.state.noteAt} onChange={this.handleNoteAtChange} />
        </div>
        <div className="col-xs-12 col-sm-3">
          <button
            className="btn btn-primary btn-sm btn-block"
            disabled={!this.isValid()}
            onClick={this.handleSaveClick}>
            Speichern
          </button>
        </div>

      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="row gutter-xs contact-item-row">
          <div className="col-sm-12">
            <textarea
              className="form-control"
              placeholder="Neue Notiz"
              value={this.state.body}
              onFocus={this.handleFocus}
              onChange={this.handleBodyChange}
            />
          </div>
        </div>
        {this.renderAdditionalFields()}
      </div>
    );
  }
}

ContactNoteForm.propTypes = {
  contactId: PropTypes.number.isRequired,
  onAdded: PropTypes.func.isRequired,
};

export default ContactNoteForm;

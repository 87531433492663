import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { subscribe, unsubscribe } from '../../helpers/actioncable_helper';

import WaveformIcon from '../shared/WaveformIcon';

class ActiveVoiceAgents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeVoiceAgentsCount: props.activeVoiceAgentsCount,
    };
  }

  componentDidMount() {
    this.channel = subscribe({
      channel: 'ProjectActiveVoiceSessionsChangesChannel',
      project_id: this.props.projectId,
    }, ({ active_voice_agents_count }) => {
      this.setState({
        activeVoiceAgentsCount: active_voice_agents_count,
      });
    });
  }

  componentWillUnmount() {
    unsubscribe(this.channel);
  }

  render() {
    if (this.state.activeVoiceAgentsCount === 0) return null;

    const text = 'Telefonagent aktiv';

    return (
      <span className="additional-project-info d-print-none">
        <span className="trigger-indicator-container">
          <WaveformIcon />
          <span className="trigger-indicator-text">
            {text}
          </span>
        </span>
      </span>
    );
  }
}

ActiveVoiceAgents.propTypes = {
  activeVoiceAgentsCount: PropTypes.number.isRequired,
  projectId: PropTypes.number.isRequired,
};

export default ActiveVoiceAgents;

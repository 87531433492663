import React from 'react';
import PropTypes from 'prop-types';

import NoDataPointDisplay from './NoDataPointDisplay';

const MultiStringChoiceDataPointDisplay = ({ dataPoint }) => {
  if (!dataPoint
    || !dataPoint.data_point_choices
    || !dataPoint.data_point_choices.length
  ) return <NoDataPointDisplay />;

  return dataPoint.data_point_choices.map((c) => c.data_point_option.string_value).join(', ');
};

MultiStringChoiceDataPointDisplay.propTypes = {
  dataPoint: PropTypes.object,
};

export default MultiStringChoiceDataPointDisplay;

import React from 'react';
import PropTypes from 'prop-types';
import EmailTemplateSelector from './EmailTemplateSelector';
import HiddenInputWrapper from '../shared/HiddenInputWrapper';

const EmailTemplateSelect = (props) => {
  const { value, name, ...rest } = props;

  return (
    <HiddenInputWrapper
      {...rest}
      comp={EmailTemplateSelector}
      name={name}
      value={value}
      placeholder="Vorlage auswählen"
      transformValue={v => v?.id}
    />
  );
};

EmailTemplateSelect.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  optionsUrl: PropTypes.string.isRequired,
};

EmailTemplateSelector.defaultProps = {
  value: '',
};

export default EmailTemplateSelect;

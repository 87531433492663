import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../shared/Icon';

const AssignWorkflowEffectForm = ({ effect }) => (
  <div className="fire-effect-item">
    <div className="fire-effect-icon">
      <Icon name="tasks" />
    </div>
    <div className="fire-effect-content">
      <div className="fire-effect-title">
        Workflow zuweisen: {effect.assignment.name}
      </div>
    </div>
  </div>
);

AssignWorkflowEffectForm.propTypes = {
  effect: PropTypes.object.isRequired,
};

export default AssignWorkflowEffectForm;

/* global document */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import NewComment from './NewComment';
import NewEmail from './NewEmail';
import NewShortMessage from './NewShortMessage';

import Icon from '../shared/Icon';

const ComposeMenuItem = ({ icon, title, active, onClick, available }) => {
  const handleClick = (e) => {
    e.preventDefault();
    onClick();
  };

  if (!available) return null;

  return (
    <a href="#" className={active ? 'active' : ''} onClick={handleClick}>
      <Icon name={icon} /> {title}
    </a>
  );
};

ComposeMenuItem.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  available: PropTypes.bool.isRequired,
};

const ComposeMenu = (props) => {
  return (
    <div className="compose-menu">
      <ComposeMenuItem
        title="Kommentar"
        icon="comment"
        onClick={() => props.onChange('comment')}
        available
        active={props.active === 'comment'} />
      <ComposeMenuItem
        title="Email"
        icon="envelope"
        onClick={() => props.onChange('email')}
        available={!!props.contact_id}
        active={props.active === 'email'} />
      <ComposeMenuItem
        title="SMS"
        icon="mobile"
        onClick={() => props.onChange('short_message')}
        available={!!props.contact_id}
        active={props.active === 'short_message'} />
    </div>
  );
};

ComposeMenu.propTypes = {
  active: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  contact_id: PropTypes.number,
};

const ComposeComponent = (props) => {
  if (props.active === 'email') {
    return (
      <NewEmail {...props} />
    );
  }

  if (props.active === 'short_message') {
    return (
      <NewShortMessage {...props} />
    );
  }

  return (
    <NewComment
      {...props}
      commentable_id={props.todo_id}
      commentable_type="Todo"
    />
  );
};

class Compose extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: 'comment',
      loading: false,
      focus: false,
      shouldAutoFocus: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.active === 'email' && prevState.active !== 'email') {
      document.getElementById('compose').scrollIntoView();
    }
  }

  setActive = (active) => {
    this.setState({ active, shouldAutoFocus: true });
  }

  handleFocus= () => {
    this.setState({ focus: true });
  }

  handleBlur = () => {
    this.setState({ focus: false });
  }

  handleSend = () => {
    this.setState({ loading: true });
  }

  handleSent = () => {
    this.setState({
      active: 'comment',
      loading: false,
      focus: false,
    });
  }

  handleError = () => {
    this.setState({
      loading: false,
    });
  }

  render() {
    const classes = ['compose'];

    if (this.state.loading) {
      classes.push('box-loading');
    } else if (this.state.focus) {
      classes.push('compose-focus');
    }

    return (
      <div id="compose">
        <ComposeMenu
          {...this.props}
          active={this.state.active}
          onChange={a => this.setActive(a)}
        />
        <div className={classes.join(' ')}>
          <div className="compose-component">
            <ComposeComponent
              {...this.props}
              focus={this.state.focus}
              setFocus={this.setFocus}
              active={this.state.active}
              shouldAutoFocus={this.state.shouldAutoFocus}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onError={this.handleError}
              onSend={this.handleSend}
              onSent={this.handleSent} />
          </div>
        </div>
      </div>
    );
  }
}

Compose.propTypes = {
  todo_id: PropTypes.number.isRequired,
  contact_id: PropTypes.number,
  shortMessageTemplatesOptionsUrl: PropTypes.string.isRequired,
  emailTemplatesOptionsUrl: PropTypes.string.isRequired,
  phone_numbers: PropTypes.array,
};

export default Compose;

/* global Image */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Icon from '../shared/Icon';

class File extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageLoading: true,
    };

    // this.image = new Image();
    this.onload = this.handleImageLoad.bind(this);
    // this.image.src = props.thumb;
    this.handleImageLoad = this.handleImageLoad.bind(this);
  }

  handleImageLoad() {
    this.setState({ imageLoading: false });
  }

  renderDate() {
    return moment(this.props.document.created_at).format('DD.MM.YYYY');
  }

  renderAvatar() {
    if (this.props.owner?.avatar?.thumb_url) {
      return (
        <span className="avatar-wrapper avatar-wrapper-mini">
          <img
            className="avatar avatar-mini"
            src={this.props.owner?.avatar?.thumb_url}
            alt={this.props.owner?.fullname} />
        </span>
      );
    }

    return null;
  }

  renderOwner() {
    return (
      <span className="fullname_with_avatar">
        <span className="avatar-link-with-fullname">
          {this.renderAvatar()}
        </span>
        {this.props.owner?.fullname}
      </span>
    );
  }

  render() {
    let imageClass = '';
    let previewClass = '';

    if (!this.state.imageLoading) {
      imageClass = 'image-visible';
    }

    if (this.props.has_preview) {
      previewClass = 'object-preview-border';
    }

    const selectableClass = this.props.selectable ? 'file-object-selectable' : '';
    const selectedClass = (this.props.selectable && this.props.selected) ? 'file-object-selected' : '';

    return (
      <div
        className={`file-object ${selectedClass} ${selectableClass}`}
        onClick={this.props.onClick}
      >
        <div className="object-name">
          <div className={`object-preview ${previewClass}`}>
            <img
              className={imageClass}
              src={this.props.document.thumb}
              onLoad={this.handleImageLoad}
              alt={this.props.document.name} />
          </div>
          <div className="object-title">
            {this.props.document.name}
          </div>
          {this.props.document.has_data_point_attachments ? (
            <div className="object-data-points">
              <span className="data-point-indicator" data-hovercard-url={`/documents/${this.props.document.id}/data_points_hovercard`}>
                <Icon name="database" />
              </span>
            </div>
          ) : ''}
        </div>
        <div className="object-owner d-none d-sm-block">
          {this.renderOwner()}
        </div>
        <div className="object-date d-none d-sm-block">
          {this.renderDate()}
        </div>
      </div>
    );
  }
}

File.propTypes = {
  document: PropTypes.object.isRequired,
  updated_at: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  selectable: PropTypes.bool,
  has_preview: PropTypes.bool,
};

export default File;

import React from 'react';

import PropTypes from 'prop-types';

import OperatorSelector from '../../OperatorSelector';
import SegmentSelectorAsync from '../../../Segments/SegmentSelectorAsync';
import BaseCondition from '../BaseCondition';

class ProjectSegmentCondition extends React.Component {
  handleChangeOperator = ({ value }) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      operator: value,
    }));
  };

  handleSegmentChange = (_, segment) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      integer_operand_1: segment.id,
      segment,
    }));
  };

  renderOperand1() {
    return (
      <div style={{ flexBasis: 200, marginLeft: 8 }}>
        <SegmentSelectorAsync
          value={this.props.condition.segment?.id}
          onChange={this.handleSegmentChange}
          optionsUrl={`/${this.props.organizationSlug}/segments.json`}
          size="small"
          isClearable={false}
        />
      </div>
    );
  }

  renderOperator() {
    return (
      <div style={{ flexBasis: 200, marginLeft: 8 }}>
        <OperatorSelector
          operators={this.props.condition.filter_condition_type.operators}
          onChange={this.handleChangeOperator}
          value={this.props.condition.operator}
        />
      </div>
    );
  }

  render() {
    return (
      <BaseCondition {...this.props}>
        {this.renderOperator()}
        {this.renderOperand1()}
      </BaseCondition>
    );
  }
}

ProjectSegmentCondition.propTypes = {
  condition: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default ProjectSegmentCondition;

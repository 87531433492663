import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModelSelect from '../shared/ModelSelect';

function InstallationAppointmentSelect(props) {
  const [craft, setCraft] = useState(props.craftAppointmentType?.craft);
  const [craftAppointmentType, setCraftAppointmentType] = useState(props.craftAppointmentType);

  const handleCraftChange = (c) => {
    setCraft(c);
    setCraftAppointmentType(null);
    props.onChange(null);
  };

  return (
    <div>
      <div>
        <ModelSelect
          model="crafts"
          optionsUrl={`/${props.organizationSlug}/crafts.json`}
          placeholder="Gewerk"
          value={craft}
          onChange={handleCraftChange}
        />
      </div>
      <div>
        <ModelSelect
          model="craft_appointment_types"
          optionsUrl={craft ? `/crafts/${craft.value}/craft_appointment_types.json` : ''}
          placeholder="Termintyp"
          value={craftAppointmentType}
          onChange={props.onChange}
          disabled={!craft}
        />
      </div>
    </div>
  );
}

InstallationAppointmentSelect.propTypes = {
  organizationSlug: PropTypes.string.isRequired,
  craftAppointmentType: PropTypes.object,
  onChange: PropTypes.func,
};

export default InstallationAppointmentSelect;

import React from 'react';
import PropTypes from 'prop-types';

import DurationSelector from './DurationSelector';

class TriggerForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      eventTypes: [],
      webhooks: [],
    };
  }

  componentDidMount() {
    this.loadEventTypes();
    this.loadWebhooks();
  }

  loadEventTypes = () => {
    return fetch('/event_types.json', {
      credentials: 'include',
    }).then((response) => {
      return response.json();
    }).then(({ event_types }) => {
      this.setState({
        eventTypes: event_types,
      });
    });
  };

  loadWebhooks = () => {
    return fetch(`/${this.props.organizationSlug}/webhooks.json`, {
      credentials: 'include',
    }).then((response) => {
      return response.json();
    }).then(({ webhooks }) => {
      this.setState({ webhooks });
    });
  };

  handleTriggerChange = (newTrigger) => {
    this.props.onChange(newTrigger);
  };

  handleTypeChange = (e) => {
    const newTrigger = Object.assign({}, this.props.record, {
      type: e.target.value,
      integer_value_1: '',
    });

    this.handleTriggerChange(newTrigger);
  };

  handleDurationChange = (newValue) => {
    const newTrigger = Object.assign({}, this.props.record, {
      integer_value_1: newValue,
    });

    this.handleTriggerChange(newTrigger);
  };

  handleEventChange = (e) => {
    const newTrigger = Object.assign({}, this.props.record, {
      string_value_1: e.target.value,
    });

    this.handleTriggerChange(newTrigger);
  };

  handleWebhookChange = (e) => {
    const newTrigger = Object.assign({}, this.props.record, {
      integer_value_2: e.target.value,
    });

    this.handleTriggerChange(newTrigger);
  };


  handleBizChange = (e) => {
    const newTrigger = Object.assign({}, this.props.record, {
      bool_value_1: e.target.checked,
    });

    this.handleTriggerChange(newTrigger);
  };

  renderTimeInputs() {
    if (this.props.record.type !== 'Triggers::TimeTrigger') return null;

    return (
      <div className="col-sm-6 mt-1 mb-1">
        <DurationSelector
          placeholder="Bitte eingeben"
          value={this.props.record.integer_value_1 || null}
          onChange={this.handleDurationChange}
        />
      </div>
    );
  }

  renderDueInputs() {
    if (this.props.record.type !== 'Triggers::DueTrigger') return null;

    return (
      <div className="col-sm-9 mt-1 mb-1">
        <DurationSelector
          value={this.props.record.integer_value_1 || null}
          onChange={this.handleDurationChange}
          placeholder="Bitte angeben"
          forwardText="nach Fälligkeit"
          backwardText="vor Fälligkeit"
          directionEnabled
        />
      </div>
    );
  }

  renderBizInput() {
    if (this.props.record.type !== 'Triggers::TimeTrigger'
       && this.props.record.type !== 'Triggers::DueTrigger') return null;

    return (
      <div className="form-check mb-3">
        <input
          className="form-check-input"
          id="trigger-biz"
          name="trigger-type"
          type="checkbox"
          checked={this.props.record.bool_value_1 || false}
          onChange={this.handleBizChange}
        />
        <label className="form-check-label" htmlFor="trigger-biz">
          Arbeitszeit brücksichtigen
        </label><br />
        <small className="text-muted">
          Führt die Aktion frühestens zur nächsten Arbeitszeit aus. Hierdurch kannst du
          vermeiden, dass die Aktion am Wochenende oder in der Nacht ausgelöst wird.
        </small>
      </div>
    );
  }

  renderEventInputs() {
    if (this.props.record.type !== 'Triggers::EventTrigger') return null;

    return (
      <div className="col-sm-8 mt-1 mb-1">
        <select value={this.props.record.string_value_1} onChange={this.handleEventChange} className="form-select">
          <option value="">Bitte auswählen</option>
          {this.state.eventTypes.map((type) => (
            <option key={type.name} value={type.name}>{type.title}</option>
          ))}
        </select>
      </div>
    );
  }

  renderWebhookInputs() {
    if (this.props.record.type !== 'Triggers::WebhookTrigger') return null;

    return (
      <div className="col-sm-8 mt-1 mb-1">
        <select value={this.props.record.string_value_1} onChange={this.handleWebhookChange} className="form-select">
          <option value="">Bitte auswählen</option>
          {this.state.webhooks.map((webhook) => (
            <option key={webhook.id} value={webhook.id}>{webhook.title}</option>
          ))}
        </select>
      </div>
    );
  }

  render() {
    if (!this.props.triggersAllowed) {
      return (
        <div className="alert alert-warning" role="alert">
          Du kannst zu dieser Aktion keine automatischen Trigger hinzufügen.
          Trigger sind nur möglich, wenn keine Unteraufgaben vorhanden sind und 
          für die Aktion nur Effekte konfiguriert sind, die keine manuelle Eingabe erfordern.
        </div>
      );
    }

    return (
      <div className="mb-3">
        <p>
          Mit Triggern kannst du deinen Workflow automatisieren. Sobald eine
          bestimmte Zeit verstrichen ist oder ein bestimmtes Ereignis im Projekt
          auftritt, kannst du die Aktion mit allen Effekten automatisch auslösen.
        </p>
        <p>
          Trigger sind immer dann aktiv, wenn auch die Aufgabe aktiv ist. Aktive
          Trigger werden dir im Projekt angezeigt.
        </p>

        <hr />
        <div className="form-check mb-2">
          <input className="form-check-input" id="trigger-type-event" name="trigger-type" type="radio" value="Triggers::EventTrigger" onChange={this.handleTypeChange} />
          <label className="form-check-label" htmlFor="trigger-type-event">
            Ereignis im Projekt
          </label><br />
          <small className="text-muted">
            Wähle diesen Trigger aus, um die Aktion bei Eintreten eines
            Events im Projekt automatisch auszulösen.
          </small>
          {this.renderEventInputs()}
        </div>

        <div className="form-check mb-2">
          <input className="form-check-input" id="trigger-type-webhook" name="trigger-type" type="radio" value="Triggers::WebhookTrigger" onChange={this.handleTypeChange} />
          <label className="form-check-label" htmlFor="trigger-type-webhook">
            Webhook
          </label><br />
          <small className="text-muted">
            Wähle diesen Trigger aus, um die Aktion auszulösen, sobald ein Webhook aufgerufen wird.
          </small>
          {this.renderWebhookInputs()}
        </div>

        <div className="form-check mb-2">
          <input className="form-check-input" id="trigger-type-time" name="trigger-type" type="radio" value="Triggers::TimeTrigger" onChange={this.handleTypeChange} />
          <label className="form-check-label" htmlFor="trigger-type-time">
            Zeit nach Aktivierung
          </label>
          <br />
          <small className="text-muted">
            Mit diesem Trigger kannst du die Aktion mit einer definierten Verzögerung
            nach der Aktivierung der Aufgabe automatisch auslösen.
          </small>
          {this.renderTimeInputs()}
        </div>

        <div className="form-check mb-3">
          <input className="form-check-input" id="trigger-type-due" name="trigger-type" type="radio" value="Triggers::DueTrigger" onChange={this.handleTypeChange} />
          <label className="form-check-label" htmlFor="trigger-type-due">
            Zeit vor/nach Fälligkeit
          </label>
          <br />
          <small className="text-muted">
            Dieser Trigger löst die Aktion vor bzw. nach
            der Fälligkeit der Aufgabe automatisch aus. Hierzu muss die Aufgabe aktiv sein.
          </small>
          {this.renderDueInputs()}
        </div>

        {this.renderBizInput()}

      </div>
    );
  }
}

TriggerForm.propTypes = {
  record: PropTypes.object.isRequired,
  workflow_template_id: PropTypes.number.isRequired,
  todo_id: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  triggersAllowed: PropTypes.bool.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default TriggerForm;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';

export default function DeliveryStatusNotification(props) {
  const [deliveryStatusNotifications] = useState(
    props.awsSnsDeliveryStatusNotifications,
  );
  const [deliveryColor, setDeliveryColor] = useState('--bs-gray');
  const [messages, setMessages] = useState([]);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (!deliveryStatusNotifications) {
      return;
    }
    const undelivered = deliveryStatusNotifications.filter(
      (n) => !n.is_delivered,
    );

    const delivered = deliveryStatusNotifications.filter((n) => n.is_delivered);

    let msg = [];

    if (undelivered.length > 0) {
      setDeliveryColor('--bs-danger');
      msg = undelivered;
    }
    if (delivered.length > 0) {
      msg = [
        ...msg,
        ...delivered,
      ];
    }
    setMessages(msg);
  }, [deliveryStatusNotifications]);

  return (
    <div
      className="btn-group hidden-xs"
      onMouseEnter={() => setShowMessage(messages.length > 0)}
      onMouseLeave={() => setShowMessage(false)}
    >
      <div
        className="btn btn-xs"
        style={{ color: `var(${deliveryColor})` }}
      >
        <Icon name="envelope"/>
      </div>
      <div
        className={`card delivery-status-message ${showMessage ? 'visible' : ''}`}
      >
        <div className="card-body">
          {messages.map((m) => (
            <div className="row small" key={m.id}>
              {m.is_delivered ? (
                <>
                  <div className="col-sm">
                    {m.recipients_details.map((r) => (
                      <div>{r.emailAddress}</div>
                    ))}
                    <div className="additional">
                      {m.delivered_at_human}
                    </div>

                  </div>
                  <div className="col-sm-7"><b>Die E-Mail wurde zugestellt</b></div>
                </>

              ) : (
                <>
                  <div className="col-sm">
                    {m.recipients_details.map((r) => (
                      <div>
                        {r.emailAddress}{r.problem ? (
                          <div className="additional">{r.problem}</div>
                        ) : ''}
                      </div>
                    ))}
                    <div className="additional">
                      {m.created_at_human}
                    </div>
                  </div>
                  <div className="col-sm-7">
                    <b>{m.undelivered_reason.split(': ')[0]}</b>
                    {m.undelivered_reason.split(': ')[1] ? `: ${m.undelivered_reason.split(': ')[1]}` : ''}
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

DeliveryStatusNotification.propTypes = {
  awsSnsDeliveryStatusNotifications: PropTypes.array.isRequired,
};

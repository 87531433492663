import React, { Component } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import Icon from '../shared/Icon';
import TimeAgo from '../shared/TimeAgo';

import { subscribe, unsubscribe } from '../../helpers/actioncable_helper';

class Due extends Component {
  constructor(props) {
    super(props);
    this.state = { due: props.due };
  }

  componentDidMount() {
    this.channel = subscribe({
      channel: 'ProjectDueChannel',
      project_id: this.props.projectId,
    }, ({ due }) => {
      this.setState({ due });
    });
  }

  componentWillUnmount() {
    unsubscribe(this.channel);
  }

  render() {
    if (this.state.due) {
      const style = moment(this.state.due).isBefore() ? { color: '#d9534f' } : {};

      return (
        <span className="additional-project-info" style={style}>
          <Icon name="clock" style={{ style }} className="me-1" />
          <span>{' '}
            <TimeAgo
              datetime={this.state.due}
              locale="de"
            /> fällig
          </span>
        </span>
      );
    }

    return null;
  }
}

Due.propTypes = {
  due: PropTypes.string,
  projectId: PropTypes.number,
};

export default Due;

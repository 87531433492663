import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../shared/Dropdown';
import Icon from '../shared/Icon';

function CancelButton(props) {
  const [isOpen, setIsOpen] = useState(false);

  const renderTarget = (ref) => (
    <button
      onClick={() => setIsOpen(!isOpen)}
      className="btn btn-danger"
      ref={ref}>
      <Icon name="cancel" /> Beauftragung stornieren
    </button>
  );

  const blanketStyle = {
    pointerEvents: 'none',
  };

  return (
    <Dropdown
      isOpen={isOpen}
      target={renderTarget}
      placement="top-end"
      blanketStyle={blanketStyle}
    >
      <form method="put" action={props.cancelPath} className="p-3">
        <span>Bist du sicher, dass du die Beaufragung stornieren möchtest?</span>
        <div className="form-check mb-2">
          <label className="form-check-label me-1" htmlFor="create_draft">
            Neuen Entwurf aus stornierter Beauftragung erstellen
          </label>
          <input className="form-check-input float-end ms-0" name="create_draft" type="checkbox" id="create_draft" defaultChecked />
        </div>

        <input type="submit" className="btn btn-sm btn-primary me-1" value="Bestätigen" />
        <button type="button" className="btn btn-sm btn-link" onClick={() => setIsOpen(false)}>Abbrechen</button>
      </form>
    </Dropdown>
  );
}

CancelButton.propTypes = {
  cancelPath: PropTypes.string,
};

export default CancelButton;

/* eslint-disable quote-props */
/* global fetch */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../shared/Icon';
import Dropdown from '../shared/Dropdown';
import Select from 'react-select/async';

import ContactOption from './ContactOption';

const styles = {
  control: (provided, { isFocused }) => ({
    ...provided,
    minWidth: 240,
    margin: 5,
    borderColor: isFocused ? '#ccc' : '#ccc',
    '&:hover': { borderColor: '#ccc' },
    boxShadow: 'none',
  }),
  menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
  option: (provided, { isFocused }) => ({
    ...provided,
    padding: '3px 8px',
    backgroundColor: isFocused ? '#2f7dd5' : 'white',
    color: isFocused ? 'white' : '#333',
  }),
};

class ContactSelectorDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropDownVisible: false,
      options: [],
      inputValue: '',
    };
  }

  loadOptions = (term) => {
    this.setState({ isLoading: true });

    let params = new URLSearchParams()
    params.append('q', term);
    this.props.withInstallationCompany && params.append('ic', '1');

    return fetch(`${this.props.optionsUrl}?${params.toString()}`, {
      credentials: 'include',
    }).then(response => response.json())
      .then(result => {
        return result.contacts;
      });
  }

  handleFocus = () => {
    this.loadOptions();
  }

  handleChange = (tag) => {
    if (this.props.onAdd) {
      this.props.onAdd(tag);
    }
  }

  toggleDropDown = (e) => {
    e.preventDefault();
    this.setState({ dropDownVisible: !this.state.dropDownVisible });
  }

  handleBlur = () => {
    this.setState({ dropDownVisible: false });
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      this.toggleDropDown(e);
    }
  }

  renderAddButton = (ref) => {
    return (
      <button ref={ref} className="btn btn-default btn-label" onClick={this.toggleDropDown}>
        <Icon name="plus"/> {this.props.buttonText}
      </button>
    );
  }

  renderTarget = (ref) => {
    if (this.props.target) {
      return this.props.target(ref, this);
    }

    return this.renderAddButton(ref);
  }

  render() {
    return (
      <Dropdown
        isOpen={this.state.dropDownVisible}
        onClose={this.toggleDropDown}
        target={this.renderTarget}
        placement={this.props.placement}>
        <Select
          autoFocus
          components={{
            Option: ContactOption,
            DropdownIndicator: null,
            IndicatorSeparator: null,
          }}
          openMenuOnFocus
          clearable={false}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
          backspaceRemovesValue={false}
          blurInputOnSelect
          menuIsOpen
          onChange={this.handleChange}
          handleInputChange={this.handleInputChange}
          defaultOptions
          styles={styles}
          placeholder="Kontakt auswählen"
          loadingPlaceholder="Laden..."
          noOptionsMessage={() => 'Keine Ergebnisse'}
          loadingMessage={() => 'Laden...'}
          loadOptions={this.loadOptions}
        />
      </Dropdown>
    );
  }
}

ContactSelectorDropdown.propTypes = {
  onAdd: PropTypes.func,
  optionsUrl: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  target: PropTypes.func,
  placement: PropTypes.string,
  withInstallationCompany: PropTypes.bool,
};

ContactSelectorDropdown.defaultProps = {
  buttonText: 'Benutzer'
};

export default ContactSelectorDropdown;

import React, { Component, Fragment } from 'react';
import ContactNameForm from './ContactNameForm'
import ContactOrgDataForm from "./ContactOrgDataForm";

class ContactPersonForm extends Component {
  render() {
    const props = this.props
    return (
      <Fragment>
        <ContactOrgDataForm
          {...props}
        />
        <div className="row">
          <div className="col-sm-6">
            <ContactNameForm
              {...props}
              title='Kontaktperson'
            />
          </div>
        </div>
      </Fragment>
    )
  }
}

export default ContactPersonForm;
import React from 'react';
import PropTypes from 'prop-types';

import TodoActivationForm from './TodoActivationForm';
import RemoteFormModal from '../shared/RemoteFormModal';

import todoActivationValidator from '../../validators/todo_activation_validator';
import { saveTodoActivation } from '../../helpers/api';

const TodoActivationModal = props => (
  <RemoteFormModal {...props}
    titleNew="Folgeaufgabe hinzufügen"
    titleEdit="Folgeaufgabe bearbeiten"
    validator={todoActivationValidator}
    form={TodoActivationForm}
    initialRecord={props.initialTodoActivation}
    saveHandler={saveTodoActivation}
  />
);

TodoActivationModal.propTypes = {
  initialTodoActivation: PropTypes.object.isRequired,
};

export default TodoActivationModal;

/* global google */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Marker = (props) => {
  const [marker, setMarker] = useState();
  const [drawingComplete, setDrawingComplete] = useState(false);

  const drawOverlays = () => {
    const newMarker = new google.maps.Marker({
      position: { lat: props.lat, lng: props.lon },
      map: props.map,
      title: props.title,
    });

    if (props.onMouseOver) {
      newMarker.addListener('mouseover', () => {
        props.onMouseOver();
      });
    }

    if (props.onMouseOut) {
      newMarker.addListener('mouseout', () => {
        props.onMouseOut();
      });
    }

    setMarker(newMarker);
    setDrawingComplete(true);
  };

  useEffect(() => {
    if (props.map && !drawingComplete) {
      drawOverlays();
    }

    // Cleanup
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [props.map, drawingComplete, marker]);

  return (
    <React.Fragment>
      {props.children && marker ? (
        props.children(marker)
      ) : null}
    </React.Fragment>
  );
};

Marker.propTypes = {
  map: PropTypes.object,
  lat: PropTypes.number.isRequired,
  lon: PropTypes.number.isRequired,
  title: PropTypes.string,
  children: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
};

export default Marker;

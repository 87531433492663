import React from 'react';
import PropTypes from 'prop-types';

import RoleTemplateSelector from '../../shared/RoleTemplateSelector';

class SetRoleEffectForm extends React.Component {
  handleRoleChange = (newRole) => {
    this.props.onChange(Object.assign({}, this.props.record, {
      role_template: newRole,
      role_template_id: newRole.id,
    }));
  }

  handleOptionalClick = () => {
    this.props.onChange(Object.assign({}, this.props.record, {
      options: Object.assign({}, this.props.record.options, {
        optional: !this.props.record.options.optional,
      }),
    }));
  }

  render() {
    return (
      <div className="mb-3">
        <label className="form-label">Rolle</label>
        <RoleTemplateSelector
          optionsUrl={`/workflows/${this.props.workflow_template_id}/roles.json`}
          value={this.props.record.role_template}
          onChange={this.handleRoleChange}
        />
        <div className="form-check">
          <input
            type="checkbox"
            checked={!!this.props.record.options.optional}
            onChange={e => this.handleOptionalClick(e)}
            id="optional"
            className="form-check-input"
          />
          <label htmlFor="optional" className="form-check-label">
            Optional
            <br /><small className="text-muted">
              Wähle diese Option, wenn die Eingabe der Rolle optional sein soll.
            </small>
          </label>
        </div>
      </div>
    );
  }
}

SetRoleEffectForm.propTypes = {
  record: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  workflow_template_id: PropTypes.number.isRequired,
};

export default SetRoleEffectForm;

import { Controller } from '@hotwired/stimulus';
import * as bootstrap from 'bootstrap';

export default class extends Controller {
  static targets = ['checkbox', 'submit'];

  connect() {
    this.toggleSubmit();
  }

  toggleAppointmentType(event) {
    const checkbox = event.target;
    const titleField = checkbox.closest('tr').querySelector('.craft-appointment-type-title');

    this.toggleField(titleField, checkbox.checked);
    this.toggleSubmit();
  }

  toggleField(field, show) {
    if (!field) return;
    if (show) {
      field.removeAttribute('disabled');
    } else {
      field.setAttribute('disabled', true);
    }
  }

  toggleSubmit() {
    let allowSubmit = true;
    this.checkboxTargets.every(checkbox => {
      const titleField = checkbox.closest('tr').querySelector('.craft-appointment-type-title');
      if (checkbox.checked && titleField.value === '') {
        allowSubmit = false;
        return false;
      }
      return true;
    });

    const tooltip = this.submitTarget.closest('.tooltip-wrapper');
    if (allowSubmit) {
      this.submitTarget.removeAttribute('disabled');
      tooltip.removeAttribute('title');
    } else {
      this.submitTarget.setAttribute('disabled', true);
      tooltip.setAttribute('title', 'Allen gewählten Termintypen muss ein Titel zugewiesen werden.');
    }
    return new bootstrap.Tooltip(tooltip);
  }
}

import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';

import { smallStyles } from '../../styles/select_styles';

const DataPointOptionSelector = ({ options, value, onChange }) => {
  if (!options || !options.length) return null;

  const selectOptions = options.map((o) => {
    return {
      value: o.id,
      label: o.string_value,
    };
  });

  const currentValue = selectOptions.find(o => o.value === value) || '';

  return (
    <Select
      options={selectOptions}
      styles={smallStyles}
      value={currentValue}
      onChange={onChange}
      placeholder="Bitte auswählen"
    />
  );
};

DataPointOptionSelector.propTypes = {
  value: PropTypes.number,
  options: PropTypes.array,
  onChange: PropTypes.func,
};

export default DataPointOptionSelector;

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['startDatetime', 'submit'];

  connect() {
    this.toggleSubmit();
  }

  toggleSubmit() {
    this.submitTarget.disabled = !this.startDatetimeTarget.value;
  }
}

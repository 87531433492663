import TimeTrigger from './TimeTrigger';
import DueTrigger from './DueTrigger';
import EventTrigger from './EventTrigger';
import WebhookTrigger from './WebhookTrigger';

export default {
  'Triggers::TimeTrigger': TimeTrigger,
  'Triggers::DueTrigger': DueTrigger,
  'Triggers::EventTrigger': EventTrigger,
  'Triggers::WebhookTrigger': WebhookTrigger,
};

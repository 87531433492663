import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import Modal from '../shared/Modal';
import Button from '../shared/Button';

import { postAsync } from '../../helpers/rails_helper';
import { flashNotice } from '../../helpers/flash_helper';

const TYPES = [
  { title: 'XLSX-Datei', value: 'Exports::XlsxExport' },
  { title: 'CSV-Datei', value: 'Exports::CsvExport' },
  { title: 'Export zu Google Sheets', value: 'Exports::GoogleSheetsExport' },
];

const FREQUENCIES = [
  { title: 'Einmalig', value: 'once' },
  { title: 'Wöchentlich (montags um 5:00)', value: 'weekly' },
  { title: 'Täglich (morgens um 5:00)', value: 'daily' },
  { title: 'Stündlich', value: 'hourly' },
];

class ExportButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      type: 'Exports::XlsxExport',
      frequency: 'once',
      modalOpen: false,
      should_notify: true,
      sheet_id: '',
      sheet_name: '',
    };
  }

  handleOpenModalClick = (e) => {
    e.preventDefault();
    this.setState({
      modalOpen: true,
    });
  };

  handleModalClose = (e) => {
    e.preventDefault();
    this.setState({
      modalOpen: false,
    });
  };

  handleTypeChange = (e) => {
    this.setState({
      type: e.target.value,
    });
  };

  handleFrequencyChange = (e) => {
    this.setState({
      frequency: e.target.value,
    });
  };

  handleAlertChange = () => {
    this.setState((prevState) => ({
      should_notify: !prevState.should_notify,
    }));
  };

  handleSheetIdChange = (e) => {
    this.setState({
      sheet_id: e.target.value,
    });
  };

  handleSheetNameChange = (e) => {
    this.setState({
      sheet_name: e.target.value,
    });
  };

  handleSave = (e) => {
    e.preventDefault();
    const url = `/${this.props.organization_slug}/exports.json`;

    this.setState({
      saving: true,
    });

    postAsync(url, {
      title: this.props.title,
      exportable_type: this.props.exportable_type,
      exportable_id: this.props.exportable_id,
      association_name: this.props.association_name,
      type: this.state.type,
      frequency: this.state.frequency,
      should_notify: this.state.should_notify,
      parameters: {
        ...this.props.parameters,
        sheet_id: this.state.sheet_id,
        sheet_name: this.state.sheet_name,
      },
    }).then(() => {
      this.setState({
        modalOpen: false,
        saving: false,
      });

      let message = "Export wird erstellt."
      message += this.state.should_notify ?
        " Du wirst per Email benachrichtigt, sobald du den Export herunterladen kannst."
        : " Er erscheint in der Exportliste, sobald er fertiggestellt wurde."
      flashNotice(message);
    }).catch((response) => {
      console.log(response.error);

      this.setState({
        saving: false,
      });

      alert(response.error);
    });
  };

  renderTypes = () => {
    return (
      <select
        className="form-control"
        value={this.state.type}
        onChange={this.handleTypeChange}>
        {TYPES.map((type) => (
          <option
            key={type.value}
            value={type.value}>
            {type.title}
          </option>
        ))}
      </select>
    );
  };

  renderFrequencies = () => {
    return (
      <select
        className="form-control"
        value={this.state.frequency}
        onChange={this.handleFrequencyChange}>
        {FREQUENCIES.map((frequency) => (
          <option
            key={frequency.value}
            value={frequency.value}>
            {frequency.title}
          </option>
        ))}
      </select>
    );
  };

  renderNotify = () => {
    return (
      <div className="form-group">
        <p><strong>Email-Benachrichtigung</strong></p>
        <div className="form-check">
          <input
            type="checkbox"
            checked={this.state.should_notify}
            onChange={this.handleAlertChange}
            id="alert"
            className="form-check-input"
          />
          <label htmlFor="alert" className="form-check-label">
            Benachrichtigung aktivieren
            <br /><span className="help-block text-muted">
              <small>
                Lass dich per Email informieren, sobald der Export zur Verfügung steht.
              </small>
            </span>
          </label>
        </div>
      </div>
    );
  };

  renderParametersInfo() {
    if (!this.props.export_info) return null;

    return (
      <div className="alert alert-warning" style={{ marginTop: 15 }}>
        {this.props.export_info}
      </div>
    );
  }

  renderGoogleParameters() {
    if (this.state.type !== 'Exports::GoogleSheetsExport') return null;

    return (
      <div>
        <div className="alert alert-info" style={{ marginTop: 15 }}>
          Lege ein Google Sheet an und füge folgenden Benutzer als Mitarbeiter hinzu:<br />
          <strong>wegabot@wegatech-team.iam.gserviceaccount.com</strong>
        </div>
        <hr />
        <div className="form-group">
          <label>Google Sheet ID</label>
          <input
            type="text"
            placeholder="dUkB2wWvUPdWdUkBHAFAASgUPd"
            value={this.state.sheet_id}
            className="form-control"
            onChange={this.handleSheetIdChange}
          />
          <span className="help-block text-muted">
            <small>
              Die ID findest du in der URL des Google Sheets, z.B:
              docs.google.com/spreadsheets/d/<strong>dUkB2wWvUPdWdUkBHAFAASgUPd</strong>/edit
            </small>
          </span>
        </div>
        <div className="form-group">
          <label>Tabellenblatt</label>
          <input
            type="text"
            placeholder="Optional"
            value={this.state.sheet_name}
            className="form-control"
            onChange={this.handleSheetNameChange}
          />
          <span className="help-block text-muted">
            <small>
              Du kannst anpassen, in welches Tabellenblatt die Daten eingetragen werden.
              Standardmäßig wird das erste Tabellenblatt befüllt.
            </small>
          </span>
        </div>
      </div>
    );
  }

  renderModal() {
    return (
      <Modal visible={this.state.modalOpen} onClose={this.handleModalClose} size="medium">
        <div className="react-modal-header">
          <h4>
            Export - {this.props.title}
          </h4>
        </div>
        <div className="react-modal-content">
          <fieldset disabled={this.state.saving}>
            <div className="row">
              <div className="col-sm-6">
                <p><strong>Export-Typ</strong></p>
                {this.renderTypes()}
              </div>
              <div className="col-sm-6">
                <p><strong>Häufigkeit</strong></p>
                {this.renderFrequencies()}
              </div>
            </div>
            {this.renderParametersInfo()}
            {this.renderGoogleParameters()}
            <hr />
            {this.renderNotify()}
          </fieldset>
        </div>
        <div className="react-modal-footer">
          <div className="text-muted" />
          <div>
            <button
              disabled={this.state.saving}
              type="button"
              className="btn btn-light"
              onClick={this.handleModalClose}>Abbrechen
            </button>{' '}
            <Button
              loading={this.state.saving}
              disabled={this.state.saving}
              type="button"
              className="btn btn-success"
              onClick={this.handleSave}>Export erstellen
            </Button>{' '}
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    return (
      <>
        <Button
          onClick={this.handleOpenModalClick}
          type="button"
          className="btn btn-light h-100 me-1">
          <Icon name="cloud-download" />
          <span className="d-none d-md-inline"> {this.props.button_title}</span>
        </Button>
        {this.renderModal()}
      </>
    );
  }
}

ExportButton.propTypes = {
  organization_slug: PropTypes.string.isRequired,
  exportable_type: PropTypes.string.isRequired,
  exportable_id: PropTypes.number.isRequired,
  association_name: PropTypes.string,
  title: PropTypes.string.isRequired,
  parameters: PropTypes.object,
  export_info: PropTypes.string,
  button_title: PropTypes.string,
};

ExportButton.defaultProps = {
  association_name: '',
  parameters: {},
  export_info: '',
  button_title: 'Export',
};

export default ExportButton;

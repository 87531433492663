import React from 'react';
import PropTypes from 'prop-types';

import SearchBox from './SearchBox';
import CompanyPolygon from './CompanyPolygon';
import Polygon from './Polygon';

import BaseMap from '../GoogleMaps/BaseMap';

const CompaniesMap = (props) => {
  // Calculate Bounds for map based on the merged areas
  // of installation companies
  const getBoundsPoints = () => {
    return props.mergedPolygons.map((polygon) => {
      return polygon.map((point) => {
        return [point.lat, point.lon];
      });
    }).flat(1);
  };

  return (
    <BaseMap containerStyle={props.containerStyle} boundsPoints={getBoundsPoints()}>
      {map => (
        <React.Fragment>
          <SearchBox map={map} />
          {props.companies.map(company => (
            <CompanyPolygon key={company.id} company={company} map={map} />
          ))}
          {props.mergedPolygons.map((polygon, i) => (
            <Polygon key={polygon.toString()} id={i} polygon={polygon} map={map} />
          ))}
        </React.Fragment>
      )}
    </BaseMap>
  );
};

CompaniesMap.propTypes = {
  mergedPolygons: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  containerStyle: PropTypes.object.isRequired,
};

CompaniesMap.defaultProps = {
  companies: [],
  mergedPolygons: [],
  containerStyle: {
    height: 600,
  },
};

export default CompaniesMap;

import React from 'react';
import PropTypes from 'prop-types';

import TodoSelector from '../Todos/TodoSelector';

class TodoActivationForm extends React.Component {
  handleTodoChange = (newId) => {
    const newActivation = Object.assign({}, this.props.record, {
      todo_id: newId,
    });

    this.props.onChange(newActivation);
  };

  handleTransferPriotiryChange = (e) => {
    const newActivation = Object.assign({}, this.props.record, {
      transfer_priority: e.target.checked,
    });

    this.props.onChange(newActivation);
  };

  render() {
    const except = [...this.props.todo_activations.map(t => t.todo_id), this.props.todo_id];

    return (
      <div className="mb-3">
        <label className="form-label">Aufgabe</label>
        <TodoSelector
          optionsUrl={`/workflows/${this.props.workflow_template_id}/todos.json?except=${except}`}
          onChange={this.handleTodoChange}
        />
        <small className="text-muted">
          Wähle die Aufgabe aus, die beim Auslösen der Aktion aktiviert werden soll.
        </small>

        <div className="form-check">
          <input
            type="checkbox"
            checked={this.props.record.transfer_priority}
            onChange={this.handleTransferPriotiryChange}
            className="form-check-input"
            id="tranfer_priority"
          />
          <label htmlFor="tranfer_priority" className="form-check-label">
            Priorität übernehmen
            <br /><small className="text-muted">
              Übernimmt die Priorität der auslösenden Aufgabe für die nächste Aufgabe.
              Höhere Prioriäten werden nicht überschrieben.
            </small>
          </label>
        </div>
      </div>
    );
  }
}

TodoActivationForm.propTypes = {
  record: PropTypes.object.isRequired,
  workflow_template_id: PropTypes.number.isRequired,
  todo_id: PropTypes.number.isRequired,
  todo_activations: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TodoActivationForm;

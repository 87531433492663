import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import Modal from '../shared/Modal';

import { reorder } from '../../helpers/helpers';

class CraftMultiSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      crafts: props.crafts,
      modalOpen: false,
    };

    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleOpenAddModal = this.handleOpenAddModal.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
  }

  availableCraftOptions() {
    const ids = this.state.crafts.map(h => h.id);

    return this.props.craftOptions.filter((option) => {
      return !ids.includes(option.id);
    });
  }

  handleRemoveClick(e, i) {
    e.preventDefault();

    this.setState({
      crafts: [...this.state.crafts.slice(0, i), ...this.state.crafts.slice(i + 1)],
    });
  }

  handleOpenAddModal(e) {
    e.preventDefault();
    this.setState({
      modalOpen: true,
    });
  }

  handleModalClose(e) {
    e.preventDefault();

    this.setState({
      modalOpen: false,
    });
  }

  handleAddClick(e, craft) {
    e.preventDefault();

    this.handleModalClose(e);

    this.setState({
      crafts: [...this.state.crafts, craft],
    });
  }

  handleDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const crafts = reorder(
      this.state.crafts,
      result.source.index,
      result.destination.index,
    );

    this.setState({
      crafts,
    });
  }

  renderCrafts() {
    return this.state.crafts.map((craft, i) => (
      <div key={`craft-${craft.id}`} index={i}>
        <div
          key={craft.id}>

          <div className="craft-item">
            <div className="craft-content">
              {craft.name}
            </div>
            <div className="craft-action">
              <a href="" onClick={e => this.handleRemoveClick(e, i)}>
                <Icon name="trash" />
              </a>
            </div>
          </div>
        </div>
      </div>
    ));
  }

  renderOptions() {
    const options = this.availableCraftOptions();

    if (options.length === 0) {
      return (
        <p>
          Keine weiteren Gewerke vorhanden
        </p>
      );
    }

    return this.availableCraftOptions().map((craft) => {
      return (
        <div key={craft.id} className="craft-item">
          <div className="craft-content">
            <div>{craft.title}</div>
            {craft.name}
          </div>
          <div className="craft-action">
            <button onClick={e => this.handleAddClick(e, craft)} className="btn btn-primary btn-compact btn-sm" title="Gewerk auswählen">
              auswählen
            </button>
          </div>
        </div>
      );
    });
  }

  renderModal() {
    return (
      <Modal visible={this.state.modalOpen} onClose={this.handleModalClose} size="medium">
        <div className="react-modal-header">
          <h4 title="Gewerk hinzufügen">Gewerk hinzufügen</h4>
        </div>
        <div className="react-modal-content">
          {this.renderOptions()}
        </div>
        <div className="react-modal-footer">
          <div className="text-muted" />
          <div>
            <button
              className="btn btn-light"
              onClick={this.handleModalClose}>Abbrechen
            </button>
            {' '}
          </div>
        </div>
      </Modal>
    );
  }

  renderAddText() {
    if (this.state.crafts.length) {
      return 'Gewerk hinzufügen';
    }

    return this.props.triggerTitle;
  }

  renderBlankInputField() {
    return !this.state.crafts.length && (
      <input name={this.props.name} type="hidden" value="" />
    );
  }

  renderHiddenFields() {
    return this.state.crafts.map((craft, i) => {
      const hiddenFields = [<input name={this.props.name} type="hidden" value={craft.id} key={0} />];

      if (this.props.optionalPositionName) {
        hiddenFields.push(<input name={this.props.optionalPositionName} type="hidden" value={i} key={1} />);
      }

      return (
        <div key={i}>
          {hiddenFields}
        </div>
      );
    });
  }

  render() {
    return (
      <div>
        <div>
          {this.renderCrafts()}

        </div>
        {this.renderBlankInputField()}
        {this.renderHiddenFields()}
        <div className="craft-add">
          <a href="" onClick={this.handleOpenAddModal}>
            {this.renderAddText()}
          </a>
        </div>
        {this.renderModal()}
      </div>
    );
  }
}

CraftMultiSelect.propTypes = {
  crafts: PropTypes.array,
  craftOptions: PropTypes.array,
  name: PropTypes.string.isRequired,
  optionalPositionName: PropTypes.string,
  triggerTitle: PropTypes.string,
};

export default CraftMultiSelect;

import React from 'react';
import PropTypes from 'prop-types';

import PhoneNumber from './PhoneNumber';

const PhoneNumbers = (props) => {
  // Only show phone numbers which are not empty
  return props.phoneNumbers.filter(p => !!p.text).map(phoneNumber => (
    <PhoneNumber key={phoneNumber.id} phoneNumber={phoneNumber} {...props} />
  ));
};

PhoneNumbers.propTypes = {
  phoneNumbers: PropTypes.array.isRequired,
};

export default PhoneNumbers;

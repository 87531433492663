import React from 'react';
import NoDataPointDisplay from './NoDataPointDisplay';

export default ({ value }) => {
  if (value === true) {
    return 'Ja';
  }

  if (value === false) {
    return 'Nein';
  }

  return <NoDataPointDisplay />;
};

import React from 'react';
import PropTypes from 'prop-types';

import ProjectRoleSelector from '../ProjectRoles/ProjectRoleSelector';
import AssignmentSelector from '../Assignments/AssignmentSelector';

class ProjectRoleForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleProjectRoleChange = this.handleProjectRoleChange.bind(this);
    this.handleAssignmentChange = this.handleAssignmentChange.bind(this);
  }

  handleAssignmentChange(newAssignment) {
    const newRole = Object.assign({}, this.props.record, {
      assignment_id: newAssignment && newAssignment.id
    });

    if (this.props.onChange) {
      this.props.onChange(newRole);
    }
  }

  handleProjectRoleChange(selectedProjectRole) {
    const newRole = Object.assign({}, this.props.record, {
      name: selectedProjectRole.name,
      color: selectedProjectRole.color,
      project_role_id: selectedProjectRole.id,
    });

    if (this.props.onChange) {
      this.props.onChange(newRole);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="mb-3">
          <label className="form-label">Wähle eine Projektrolle aus</label>
          <ProjectRoleSelector
            optionsUrl={`/${this.props.organizationSlug}/project_roles.json`}
            // value={this.props.record.name}
            onChange={this.handleProjectRoleChange}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Automatische Zuteilung</label>
          <AssignmentSelector
            assigneeType="Assignments::ResponsibleAssignment"
            value={this.props.record.assignment}
            optionsUrl={`/${this.props.organizationSlug}/assignments.json?assignment_type=Assignments::ResponsibleAssignment`}
            onChange={this.handleAssignmentChange}
          />
          <small className="text-muted">
            Wähle einen Zuteilungsregelsatz aus, um die Rolle automatisch zu besetzen.
          </small>
        </div>
      </React.Fragment>
    );
  }
}

ProjectRoleForm.propTypes = {
  record: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  organizationSlug: PropTypes.string.isRequired,
};

export default ProjectRoleForm;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { updateAsync } from "../../helpers/rails_helper";


class PhoneSystem extends Component {

  render () {
    return this.props.accounts.map(accounts => (
        <a
          className="dropdown-item"
          key={accounts.id}
          href="#"
          onClick={e => this.props.onCallClick(e, accounts.id)}
        >
          Jetzt anrufen<span className="text-muted"> via {accounts.name || accounts.sip_id}</span>
        </a>
    ));
  }
}

PhoneSystem.propTypes = {
  accounts: PropTypes.array.isRequired,
  onCallClick: PropTypes.func.isRequired,
}

export default PhoneSystem;
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EmojiSelect from './EmojiSelect';
import Tooltip from '../shared/Tooltip';

import UserSelect from '../Users/UserSelect';

import DatetimePickerComponent from '../DatePickers/DatetimePickerComponent';

const suggestions = [
  { emoji: '🌴', status: 'Im Urlaub', unavailable: true },
  { emoji: '🤒', status: 'Leider krank', unavailable: true },
  { emoji: '💼', status: 'Auswärtstermin', unavailable: true },
  { emoji: '🏡', status: 'Im Homeoffice', unavailable: false },
  { emoji: '🐢', status: 'Kurzarbeit', unavailable: true },
];

class StatusForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emoji: props.emoji || '',
      status: props.status || '',
      unavailable: props.unavailable || false,
      reset_at: props.reset_at || '',
      delegate_user: props.delegate_user || null,
    };
  }

  handleDelegateUserChange = (id, user) => {
    this.setState({
      delegate_user: user,
    });
  };

  handleChooseSuggestion = (e, suggestion) => {
    e.preventDefault();
    this.setState(suggestion);
  };

  handleStatusChange = (e) => {
    this.setState({ status: e.target.value });
  };

  handleEmojiChange = (emoji) => {
    this.setState({ emoji });
  };

  handleClearStatus = (e) => {
    e.preventDefault();
    this.setState({ emoji: '', status: '', unavailable: false, reset_at: '' });
  };

  handleUnavailableChange = (e) => {
    this.setState({ unavailable: e.target.checked });
  };

  handleResetAtChange = (value) => {
    this.setState({
      reset_at: value || '',
    });
  };

  savable() {
    const { emoji, status, unavailable } = this.state;

    if (unavailable) {
      return (emoji && status);
    }
    return (emoji && status) || (!emoji && !status && !unavailable);
  }

  renderSuggestion(suggestion) {
    return (
      <span className="emoji-suggestion">
        <span className="me-1">{suggestion.emoji}</span>
        <span className="text-muted">
          <a href="" onClick={(e) => this.handleChooseSuggestion(e, suggestion)}>
            {suggestion.status}
          </a>
        </span>
      </span>
    );
  }

  renderSuggestionSection() {
    if (this.state.emoji) return null;

    return (
      <div className="mb-2 row">
        <div className="col-sm-12">
          <div className="mb-1"><strong>Vorschläge:</strong></div>

          <div className="row">
            {suggestions.map((suggestion) => (
              <div key={suggestion.status} className="col-xs-6 col-md-5 offset-md-1 mt-2">
                {this.renderSuggestion(suggestion)}
              </div>
            ))}
          </div>

        </div>
      </div>
    );
  }

  renderUnavailable() {
    return (
      <div className="mb-2 row">
        <div className="col-sm-12">
          <div className="form-check">
            <input name="user[status_unavailable]" type="hidden" value="false" />
            <input
              name="user[status_unavailable]"
              id="user_status_unavailable"
              type="checkbox"
              checked={this.state.unavailable}
              onChange={this.handleUnavailableChange}
              className="form-check-input"
            />
            <label htmlFor="user_status_unavailable" className="form-check-label">
              <strong>Nicht verfügbar</strong>
              <br /><span className="text-muted">Zeige deinem Team, dass du momentan abwesend bzw. nicht erreichbar bist.</span>
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderResetSection() {
    return (
      <div className="mb-2 row">
        <div className="col-sm-12">
          <label className="form-label">
            <strong>Automatisch zurücksetzen</strong>
          </label>
          <br />
          <input
            name="user[status_reset_at]"
            type="hidden"
            value={this.state.reset_at}
          />
          <DatetimePickerComponent
            value={this.state.reset_at}
            onChange={this.handleResetAtChange}
          />
          <div className="form-text text-muted">
            Status zu einem bestimmten Zeitpunkt automatisch zurücksetzen.
          </div>
        </div>
      </div>
    );
  }

  renderDelegateUserSection() {
    if (!this.state.unavailable) return null;

    return (
      <div className="mb-2 row">
        <div className="col-sm-12">
          <label className="form-label">
            <strong>Vertretung</strong>
          </label>
          <br />
          <input
            name="user[status_reset_at]"
            type="hidden"
            value={this.state.reset_at}
          />
          <UserSelect
            user={this.state.delegate_user}
            name="user[delegate_user_id]"
            excludeUserIds={[this.props.user_id]}
            optionsUrl="/users.json"
            suggestionContext="status"
            onChange={this.handleDelegateUserChange}
          />
          <div className="form-text text-muted">
            Wähle aus, wer dich während deiner Abwesenheit vertritt.
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="mb-2 row">
          <div className="col-sm-12">

            <div style={{ display: 'flex' }}>
              <div>
                <EmojiSelect
                  emoji={this.state.emoji}
                  onChange={this.handleEmojiChange}
                />
                <input name="user[status_emoji]" type="hidden" value={this.state.emoji} />
              </div>

              <div style={{ flexGrow: 1, marginLeft: 8 }}>
                <input
                  name="user[status_message]"
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  placeholder="Mein Status"
                  onChange={this.handleStatusChange}
                  value={this.state.status}
                />
              </div>
            </div>
          </div>
        </div>

        {this.renderSuggestionSection()}
        {this.renderUnavailable()}
        {this.renderDelegateUserSection()}
        {this.renderResetSection()}

        <div className="mt-3 row">
          <div className="col-sm-12">
            <Tooltip
              enabled={!this.savable()}
              title={this.state.unavailable ? 'Bitte Emoji, Status und Vertretung auswählen' : 'Bitte Emoji und Status auswählen'}
              placement="bottom-start">
              <button
                className="btn btn-primary"
                disabled={!this.savable()}>
                Speichern
              </button>
            </Tooltip>
            <button
              style={{ marginLeft: 10 }}
              className="btn btn-link"
              onClick={this.handleClearStatus}>
              Status entfernen
            </button>
          </div>
        </div>
      </div>
    );
  }
}

StatusForm.propTypes = {
  status: PropTypes.string,
  emoji: PropTypes.string,
  unavailable: PropTypes.bool,
  reset_at: PropTypes.string,
  user_id: PropTypes.number.isRequired,
};

export default StatusForm;

import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../shared/Icon';

const PriorityBadge = ({ todo, style }) => {
  if (!todo.active || !todo.priority || todo.complete) return null;

  const title = `Priorität: ${todo.priority_title}`;

  return (
    <span className={`todo-badge todo-badge-priority-${todo.priority}`} style={style}>
      <Icon name="exclamation-circle" title={title} /> {title}
    </span>
  );
};

PriorityBadge.propTypes = {
  todo: PropTypes.object.isRequired,
  style: PropTypes.object,
};

export default PriorityBadge;

// https://github.com/basecamp/trix

import React from 'react';
import PropTypes from 'prop-types';

class RichTextArea extends React.Component {
  constructor(props) {
    super(props);

    this.editorRef = React.createRef();
    this.inputRef = React.createRef();
    this.lastInput = '';
  }

  componentDidMount() {
    this.editorRef.current.addEventListener('trix-change', (event) => {
      if (this.props.onChange) {
        this.lastInput = this.inputRef.current.value;
        this.props.onChange(this.inputRef.current.value);
      }
    });

    this.editorRef.current.addEventListener('trix-focus', (event) => {
      if (this.props.onFocus) this.props.onFocus();
    });

    this.editorRef.current.addEventListener('trix-blur', (event) => {
      if (this.props.onBlur) this.props.onBlur();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // pass new value from props to trix editor
    // since it won't recognize changes of the value attribute
    // of the hidden input element.
    if (this.props.value != this.lastInput) {
      this.editorRef.current.editor.setSelectedRange([0, Infinity])
      this.editorRef.current.editor.insertHTML(this.props.value)
    }

    // enable/disable editor if prop changes
    this.editorRef.current.editor.element.contentEditable = !this.props.disabled
  }

  classNames = () => {
    const names = ['trix-content'];

    if (!this.props.quoteEnabled) names.push('trix-disable-quote');
    if (!this.props.codeEnabled) names.push('trix-disable-code');
    if (!this.props.headingEnabled) names.push('trix-disable-heading-1');
    if (!this.props.filesEnabled) names.push('trix-disable-file-tools');
    if (!this.props.fileWarning) names.push('trix-disable-file-tools-no-warning');
    if (!this.props.historyEnabled) names.push('trix-disable-history');

    return names.join(' ');
  }

  render() {
    return (
      <div className="trix-editor-container">
        <input
          ref={this.inputRef}
          id="x"
          value={this.props.value}
          type="hidden"
          name="content"
        />
        <trix-editor
          disabled={this.props.disabled ? 'disabled' : null}
          input="x"
          ref={this.editorRef}
          placeholder={this.props.placeholder}
          class={this.classNames()}
        />
      </div>
    );
  }
}

RichTextArea.propTypes = {
  quoteEnabled: PropTypes.bool,
  codeEnabled: PropTypes.bool,
  headingEnabled: PropTypes.bool,
  filesEnabled: PropTypes.bool,
  fileWarning: PropTypes.bool,
  historyEnabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

RichTextArea.defaultProps = {
  quoteEnabled: false,
  codeEnabled: false,
  headingEnabled: false,
  filesEnabled: false,
  fileWarning: false,
  historyEnabled: false,
  value: '',
  placeholder: 'Bitte eintragen...',
};

export default RichTextArea;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';

import LogicalConnectorSelector from './LogicalConnectorSelector';

import ConditionGroup, { getDefaultConditionGroup } from './ConditionGroup';

class ConditionSet extends Component {
  handleConditionGroupChange = (updatedConditionGroup) => {
    this.props.onChange(Object.assign({}, this.props.filter, {
      condition_groups: this.props.filter.condition_groups.map((c) => {
        return (c.id === updatedConditionGroup.id ? updatedConditionGroup : c);
      }),
    }));
  };

  handleAddConditionGroup = (e) => {
    e.preventDefault();

    this.props.onChange(Object.assign({}, this.props.filter, {
      condition_groups: [...this.props.filter.condition_groups, getDefaultConditionGroup()],
    }));
  };

  handleRemoveConditionGroup = (conditionGroup) => {
    const alteredFilter = Object.assign({}, this.props.filter, {
      condition_groups: this.props.filter.condition_groups.filter(c => c.id !== conditionGroup.id),
    });

    if (conditionGroup.persisted) {
      alteredFilter.condition_groups_to_be_removed =
        [...(this.props.filter.condition_groups_to_be_removed || []), conditionGroup];
    }

    this.props.onChange(alteredFilter);
  };

  handleLogicalConnectorChange = (newValue) => {
    this.props.onChange(Object.assign({}, this.props.filter, {
      logical_connector: newValue,
    }));
  };

  isConditionRemovable = () => {
    return (
      (this.props.filter.condition_groups.length > 1)
      || this.props.filter.condition_groups[0].conditions.length > 1
    );
  };

  renderConditionGroups() {
    return this.props.filter.condition_groups.map(conditionGroup => (
      <ConditionGroup
        key={conditionGroup.id}
        conditionGroup={conditionGroup}
        onChange={this.handleConditionGroupChange}
        conditionRemovable={this.isConditionRemovable()}
        onRemoveConditionGroup={this.handleRemoveConditionGroup}
        optionsUrl={this.props.optionsUrl}
        organizationSlug={this.props.organizationSlug}
        conditionGroupTitleMulti={this.props.conditionGroupTitleMulti}
        conditionGroupAndText={this.props.conditionGroupAndText}
        conditionGroupOrText={this.props.conditionGroupOrText}
      />
    ));
  }

  renderTitle() {
    if (!this.props.filter.condition_groups.length) return null;

    const logicalSelector = (
      <LogicalConnectorSelector
        value={this.props.filter.logical_connector}
        onChange={this.handleLogicalConnectorChange}
        andText={this.props.andText}
        orText={this.props.orText}
      />
    );

    return (
      <div style={{ color: 'var(--bs-secondary-color)', fontWeight: 500 }}>
        {this.props.filter.condition_groups.length > 1 ? (
          this.props.filterTitleMulti(logicalSelector)
        ) : this.props.filterTitleSingle}
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <div style={{ display: 'flex' }}>
            <div style={{ flexGrow: 1 }}>
              {this.renderTitle()}
            </div>
          </div>
          <div>
            {this.renderConditionGroups()}
          </div>
        </div>
        <div>
          <a href="#" onClick={this.handleAddConditionGroup} className="btn-compact btn-link">
            <Icon name="plus" /> Kriterium hinzufügen
          </a>
        </div>
      </React.Fragment>
    );
  }
}

ConditionSet.propTypes = {
  filter: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  filterTitleSingle: PropTypes.string,
  filterTitleMulti: PropTypes.func,
  andText: PropTypes.string,
  orText: PropTypes.string,
  optionsUrl: PropTypes.string.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  conditionGroupTitleMulti: PropTypes.string,
  conditionGroupAndText: PropTypes.string,
  conditionGroupOrText: PropTypes.string,
};

ConditionSet.defaultProps = {
  filterTitleSingle: 'Entspricht folgenden Kriterien',
  filterTitleMulti: logicalSelector => (
    <span>
      Entspricht {logicalSelector} der folgenden Kriterien:
    </span>
  ),
};

export default ConditionSet;

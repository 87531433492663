/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Dropzone from 'react-dropzone';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import Icon from '../shared/Icon';
import ContextMenuContainer from '../shared/ContextMenuContainer';
import ContextMenu from '../shared/ContextMenu';
import RenameObjectModal from './RenameObjectModal';
import FolderRaw from './FolderRaw';

const { fromEvent } = require('file-selector');

class Folder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      renameModalVisible: false,
    };
  }

  handleDelete = (e) => {
    e.preventDefault();

    if (confirm(`Bist du sicher, dass du den Ordner ${this.props.folder.name} löschen willst?`)) {
      this.props.onDelete(this.props.folder);
    }
  };

  handleRenameClick = (e) => {
    e.preventDefault();

    this.setState({
      renameModalVisible: true,
    });
  };

  handleRenameModalClosed = () => {
    this.setState({
      renameModalVisible: false,
    });
  };

  handleDropFiles = (acceptedFiles, rejectedFiles, event) => {
    this.props.uploadFiles(acceptedFiles, {
      attachable_typed_id: this.props.folder.typed_id,
    });

    event.stopPropagation();
    event.preventDefault();
  };

  handleFolderClick = (e) => {
    e.preventDefault();
    this.props.onFolderableSelect(this.props.folder.typed_id);
  };

  isDragDisabled = () => {
    return this.state.renameModalVisible || this.props.folder.locked || !this.props.editable;
  };

  renderDropzone() {
    return (
      <Dropzone
        disableClick
        className="folder-dropzone"
        getDataTransferItems={evt => fromEvent(evt)}
        onDrop={this.handleDropFiles}
        activeClassName="active">
        <ContextMenuContainer enabled={!this.props.folder.locked}>
          {(menu) => (
            <Fragment>
              <FolderRaw
                folder={this.props.folder}
                onClick={this.handleFolderClick}
                draggable={false}
                onDragStart={(e) => { e.preventDefault(); return false; }}
              />
              <ContextMenu menu={menu}>
                <a className="dropdown-item" href="#" onClick={this.handleRenameClick}>
                  <Icon name="pencil" /> Umbenennen
                </a>
                <hr role="separator" className="dropdown-divider" />
                <a className="dropdown-item" href="#" onClick={this.handleDelete}>
                  <Icon name="trash" fixedWidth /> Löschen
                </a>
              </ContextMenu>
            </Fragment>
          )}
        </ContextMenuContainer>
        <RenameObjectModal
          title="Ordner umbenennen"
          defaultValue={this.props.folder.name}
          objectId={this.props.folder.id}
          onClose={this.handleRenameModalClosed}
          renameObject={this.props.onRename}
          visible={this.state.renameModalVisible}
        />
      </Dropzone>
    );
  }

  renderDraggable = (provided, snapshot) => {
    const style = {
      // borderTop: '2px solid orange',
    };

    if (snapshot.isDraggingOver && snapshot.draggingOverWith !== this.props.folder.typed_id) {
      style.transform = 'translate(0px 0px)';
    }

    return (
      <Draggable
        key={this.props.folder.typed_id}
        draggableId={this.props.folder.typed_id}
        isDragDisabled={this.isDragDisabled()}
        index={0}>
        {(provided2, snapshot2) => (
          <div
            ref={provided2.innerRef}
            {...provided2.draggableProps}
            {...provided2.dragHandleProps}
            className={snapshot2.isDragging ? 'dragging' : ''}
            style={{
              ...provided2.draggableProps.style,
              ...style,
            }}>
            {this.renderDropzone()}
          </div>
        )}
      </Draggable>
    );
  };

  render() {
    const droppableId = this.props.folder.typed_id;

    return (
      <div className="col-lg-3 col-md-4 col-sm-4 col-xs-6 col-folder">
        <Droppable droppableId={droppableId} isDropDisabled={!this.props.editable}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              className={
                snapshot.isDraggingOver && snapshot.draggingOverWith !== droppableId ? 'dragging-over' : ''
            }>
              {this.renderDraggable(provided, snapshot)}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    );
  }
}

Folder.propTypes = {
  folder: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
  onFolderableSelect: PropTypes.func.isRequired,
};

export default Folder;

/* eslint-disable max-len */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

const HiddenField = ({ name, value }) => (
  <Fragment>
    {/* <p>
      <strong>{name}:</strong><br />
      {value}
    </p> */}
    <input type="hidden" name={name} value={value || ''} />
  </Fragment>
);

HiddenField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

class HiddenConditionFields extends Component {
  renderOperandFields(conditionGroup, condition) {
    return [1, 2, 3].map((i) => {
      if (!condition.filter_condition_type) return null;
      if (!condition.filter_condition_type[`operand${i}_type`]) return null;

      return ['string', 'integer', 'boolean', 'decimal', 'date'].map((type) => {
        return (
          <Fragment key={`${i}-${type}`}>
            <HiddenField
              name={`${this.props.baseName}[condition_groups_attributes][${conditionGroup.id}][conditions_attributes][${condition.id}][${type}_operand_${i}]`}
              value={condition[`${type}_operand_${i}`]}
            />
            {/* <HiddenField
              name={`${this.props.baseName}[condition_groups_attributes][${conditionGroup.id}][conditions_attributes][${condition.id}][operand${i}_type]`}
              value={condition[`operand${i}_type`]}
            /> */}
          </Fragment>
        );
      });
    });
  }

  renderConditions(conditionGroup) {
    return conditionGroup.conditions.map(c => (
      <Fragment key={c.id}>
        {c.persisted ? (
          <HiddenField
            name={`${this.props.baseName}[condition_groups_attributes][${conditionGroup.id}][conditions_attributes][${c.id}][id]`}
            value={c.persisted && c.id}
          />
        ) : null}
        <HiddenField
          name={`${this.props.baseName}[condition_groups_attributes][${conditionGroup.id}][conditions_attributes][${c.id}][operator]`}
          value={c.operator}
        />
        <HiddenField
          name={`${this.props.baseName}[condition_groups_attributes][${conditionGroup.id}][conditions_attributes][${c.id}][type]`}
          value={c.filter_condition_type && c.filter_condition_type.type}
        />
        {this.renderOperandFields(conditionGroup, c)}
      </Fragment>
    ));
  }

  renderConditionsToBeRemoved(conditionGroup) {
    return (conditionGroup.conditions_to_be_removed || []).map((c) => {
      if (!c.persisted) return null;

      return (
        <Fragment key={c.id}>
          <HiddenField
            name={`${this.props.baseName}[condition_groups_attributes][${conditionGroup.id}][conditions_attributes][${c.id}][id]`}
            value={c.id}
          />
          <HiddenField
            name={`${this.props.baseName}[condition_groups_attributes][${conditionGroup.id}][conditions_attributes][${c.id}][_destroy]`}
            value="true"
          />
        </Fragment>
      );
    });
  }

  renderConditionGroupInputs() {
    return this.props.filter.condition_groups.map((cg) => {
      return (
        <Fragment key={cg.id}>
          {cg.persisted ? (
            <HiddenField
              name={`${this.props.baseName}[condition_groups_attributes][${cg.id}][id]`}
              value={cg.id}
            />
          ) : null}
          <HiddenField
            name={`${this.props.baseName}[condition_groups_attributes][${cg.id}][logical_connector]`}
            value={cg.logical_connector}
          />
          {this.renderConditions(cg)}
          {this.renderConditionsToBeRemoved(cg)}
        </Fragment>
      );
    });
  }

  renderConditionGroupRemoveInputs() {
    return (this.props.filter.condition_groups_to_be_removed || []).map((cg) => {
      return (
        <Fragment key={cg.id}>
          <HiddenField
            name={`${this.props.baseName}[condition_groups_attributes][${cg.id}][_destroy]`}
            value="true"
          />
          {cg.persisted ? (
            <HiddenField
              name={`${this.props.baseName}[condition_groups_attributes][${cg.id}][id]`}
              value={cg.id}
            />
          ) : null}
        </Fragment>
      );
    });
  }

  renderFilterInputs() {
    return (
      <Fragment>
        <HiddenField
          name={`${this.props.baseName}[logical_connector]`}
          value={this.props.filter.logical_connector}
        />
        {this.props.filter.persisted ? (
          <HiddenField
            name={`${this.props.baseName}[id]`}
            value={this.props.filter.id}
          />
        ) : null}
      </Fragment>
    );
  }

  render() {
    return (
      <Fragment>
        {this.renderFilterInputs()}
        {this.renderConditionGroupInputs()}
        {this.renderConditionGroupRemoveInputs()}
      </Fragment>
    );
  }
}

HiddenConditionFields.propTypes = {
  filter: PropTypes.object,
  baseName: PropTypes.string,
};

export default HiddenConditionFields;

import React from 'react';
import PropTypes from 'prop-types';

class Desc extends React.Component {
  render() {
    return (
      <div dangerouslySetInnerHTML={{
        __html: this.props.desc,
      }} />
    );
  }
}

Desc.propTypes = {
  desc: PropTypes.string,
};

export default Desc;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Droppable, Draggable } from 'react-beautiful-dnd';

import Icon from '../shared/Icon';
import Tooltip from '../shared/Tooltip';
import ConfirmModal from '../shared/ConfirmModal';

import { effectTypesWithManualInput } from './EffectTypeSquares';

import EffectTemplate from './EffectTemplate';
import ActionTemplateModal from './ActionTemplateModal';
import EffectTemplateModal from './EffectTemplateModal';
import TodoActivationModal from './TodoActivationModal';
import TriggerModal from './TriggerModal';
import TodoActivation from './TodoActivation';
import TriggerTemplate from './TriggerTemplate';

import ProjectStateBadge from '../ProjectStates/ProjectStateBadge';

import { deleteAction } from '../../helpers/api';

const DeleteTarget = onClick => (
  <Tooltip title="Aktion löschen">
    <button
      type="button"
      className="btn btn-compact btn-link"
      onClick={onClick}>
      <Icon name="trash" />
    </button>
  </Tooltip>
);

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  marginTop: 9,
  marginBottom: 0,
  // padding: grid,
  // margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  // backgroundColor: isDragging ? '#ddd' : 'unset',

  // borderRadius: '4px',
  // styles we need to apply on draggables
  ...draggableStyle,
});

class ActionTemplate extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };
  }

  handleEffectAdded = (newEffect) => {
    const newAction = Object.assign({}, this.props.action, {
      effects: [...this.props.action.effects, newEffect],
    });

    this.props.onUpdate(newAction);
  };

  handleTriggerAdded = ({ trigger: newTrigger }) => {
    console.log(newTrigger);

    const newAction = Object.assign({}, this.props.action, {
      triggers: [...this.props.action.triggers, newTrigger],
    });

    this.props.onUpdate(newAction);
  };

  handleEffectDeleted = (deletedEffect) => {
    const newAction = Object.assign({}, this.props.action, {
      effects: this.props.action.effects.filter(e => e.id !== deletedEffect.id),
    });

    this.props.onUpdate(newAction);
  };

  handleTriggerDeleted = (deletedTrigger) => {
    const newAction = Object.assign({}, this.props.action, {
      triggers: this.props.action.triggers.filter(e => e.id !== deletedTrigger.id),
    });

    this.props.onUpdate(newAction);
  };

  handleEffectUpdated = (updatedEffect) => {
    const newAction = Object.assign({}, this.props.action, {
      effects: this.props.action.effects.map(e => (e.id === updatedEffect.id ? updatedEffect : e)),
    });

    this.props.onUpdate(newAction);
  };

  handleTodoActivationAdded = (data) => {
    const newAction = Object.assign({}, this.props.action, {
      todo_activations: [...this.props.action.todo_activations, data.todo_activation],
    });

    this.props.onUpdate(newAction);
  };

  handleTodoActivationDeleted = (deletedTodoActivation) => {
    const newAction = Object.assign({}, this.props.action, {
      todo_activations: this.props.action.todo_activations
        .filter(a => a.id !== deletedTodoActivation.id),
    });

    this.props.onUpdate(newAction);
  };

  renderEffectsAndTodoActivations() {
    if (!this.props.action.todo_activations.length
        && !this.props.action.effects.length) return null;

    return (
      <div className="action-template-effects">
        {this.renderTodoActivations()}
        {this.renderEffects()}
      </div>
    );
  }

  renderTodoActivations() {
    return (this.props.action.todo_activations || []).map(todoActivation => (
      <TodoActivation
        editable={this.props.editable}
        key={todoActivation.id}
        todo={todoActivation.todo}
        todo_activation={todoActivation}
        onDeleted={this.handleTodoActivationDeleted}
        onModalOpen={() => this.setState({ modalOpen: true })}
        onModalClose={() => this.setState({ modalOpen: false })}
      />
    ));
  }

  renderEffects() {
    return (
      <Droppable
        droppableId={`action-${this.props.todo_id}-${this.props.action.id}`}
        type={`action-${this.props.action.id}-effects`}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef}>
            {(this.props.action.effects || []).map((effect, i) => (
              <EffectTemplate
                index={i}
                editable={this.props.editable}
                action_id={this.props.action.id}
                organizationSlug={this.props.organizationSlug}
                workflow_template_id={this.props.workflow_template_id}
                todo_id={this.props.todo_id}
                effect={effect}
                key={effect.id}
                onDeleted={this.handleEffectDeleted}
                onUpdated={this.handleEffectUpdated}
                onModalOpen={() => this.setState({ modalOpen: true })}
                onModalClose={() => this.setState({ modalOpen: false })}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }

  hasTriggers() {
    return this.props.action.triggers && this.props.action.triggers.length;
  }

  hasEffectsWithManualInput() {
    const effectTypeCodes = effectTypesWithManualInput.map(e => e.code);

    return this.props.action.effects.some((effect) => {
      return effectTypeCodes.includes(effect.type);
    });
  }

  renderTriggers() {
    if (!this.hasTriggers()) return null;

    return (
      <div className="action-template-triggers">
        {this.props.action.triggers.map((trigger) => (
          <TriggerTemplate
            key={trigger.id}
            trigger={trigger}
            deletable={this.props.editable}
            onDeleted={this.handleTriggerDeleted}
          />
        ))}
      </div>
    );
  }

  renderMarkCompleted() {
    if (this.props.workflowType !== 'workflow') return null;

    return (
      <div className="action-complete-todo">
        <Icon name={this.props.action.complete_todo ? 'check' : 'ban'} />
        Aufgabe {this.props.action.complete_todo ? '' : 'nicht'} abschließen
      </div>
    );
  }

  render() {
    return (
      <Draggable
        key={this.props.action.id}
        draggableId={`action-${this.props.action.id}`}
        index={this.props.index}
        isDragDisabled={!this.props.editable || this.state.modalOpen}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} className="action-template"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
            )}>
            <div className="action-template-head">
              <div className="action-template-name">
                <span title={`Name ausgelöst: ${this.props.action.name_fired}`} style={{ marginRight: 5 }}>
                  <Icon
                    name={this.props.action.icon}
                    color={this.props.action.color}
                  /> {this.props.action.name}
                </span>
                <span style={{ marginRight: 5 }}>
                  {this.renderMarkCompleted()}
                </span>
                <ProjectStateBadge
                  projectState={this.props.action.next_project_state}
                  title="Neuer Projektstatus"
                />
              </div>
              <div className="action-template-buttons">
                <ActionTemplateModal
                  visible={this.props.editable}
                  initialAction={this.props.action}
                  onSaved={this.props.onUpdate}
                  onOpen={() => this.setState({ modalOpen: true })}
                  onClose={() => this.setState({ modalOpen: false })}
                  workflowType={this.props.workflowType}
                  organizationSlug={this.props.organizationSlug}>
                  {onClick => (
                    <Tooltip title="Aktion bearbeiten">
                      <button type="button" className="btn btn-compact btn-link" onClick={onClick}>
                        <Icon name="pencil" />
                      </button>
                    </Tooltip>
                  )}
                </ActionTemplateModal>
                <TodoActivationModal
                  allowOverflow
                  initialTodoActivation={{
                    action_id: this.props.action.id,
                    transfer_priority: false,
                  }}
                  visible={this.props.editable && this.props.workflowType === 'workflow'}
                  todo_activations={this.props.action.todo_activations}
                  onSaved={this.handleTodoActivationAdded}
                  onOpen={() => this.setState({ modalOpen: true })}
                  onClose={() => this.setState({ modalOpen: false })}
                  action_id={this.props.action.id}
                  organizationSlug={this.props.organizationSlug}
                  workflow_template_id={this.props.workflow_template_id}
                  todo_id={this.props.action.todo_id}>
                  {onClick => (
                    <Tooltip title="Folgeaufgabe hinzufügen">
                      <button type="button" className="btn btn-compact btn-link" onClick={onClick}>
                        <Icon name="forward" />
                      </button>
                    </Tooltip>
                  )}
                </TodoActivationModal>
                <EffectTemplateModal
                  initialEffect={{
                    action_id: this.props.action.id,
                    options: {},
                  }}
                  allowEffectTypesWithManualInput={!this.hasTriggers()}
                  onSaved={this.handleEffectAdded}
                  onOpen={() => this.setState({ modalOpen: true })}
                  onClose={() => this.setState({ modalOpen: false })}
                  visible={this.props.editable && this.props.workflowType === 'workflow'}
                  action_id={this.props.action.id}
                  organizationSlug={this.props.organizationSlug}
                  workflow_template_id={this.props.workflow_template_id}
                  todo_id={this.props.action.todo_id}>
                  {onClick => (
                    <Tooltip title="Effekt hinzufügen">
                      <button type="button" className="btn btn-compact btn-link" onClick={onClick}>
                        <Icon name="plus" />
                      </button>
                    </Tooltip>
                  )}
                </EffectTemplateModal>
                <TriggerModal
                  allowOverflow
                  initialTodoActivation={{
                    action_id: this.props.action.id,
                  }}
                  triggersAllowed={this.props.triggersAllowed && !this.hasEffectsWithManualInput()}
                  visible={this.props.editable && this.props.workflowType === 'workflow'}
                  todo_activations={this.props.action.todo_activations}
                  onSaved={this.handleTriggerAdded}
                  onOpen={() => this.setState({ modalOpen: true })}
                  onClose={() => this.setState({ modalOpen: false })}
                  action_id={this.props.action.id}
                  organizationSlug={this.props.organizationSlug}
                  workflow_template_id={this.props.workflow_template_id}
                  todo_id={this.props.action.todo_id}>
                  {onClick => (
                    <Tooltip title="Trigger hinzufügen">
                      <button type="button" className="btn btn-compact btn-link" onClick={onClick}>
                        <Icon name="timer" />
                      </button>
                    </Tooltip>
                  )}
                </TriggerModal>
                <ConfirmModal
                  target={DeleteTarget}
                  title="Aktion löschen"
                  cancelText="abbrechen"
                  confirmText="löschen"
                  confirmStyle="danger"
                  visible={this.props.editable && this.props.deletable}
                  onOpen={() => this.setState({ modalOpen: true })}
                  onClose={() => this.setState({ modalOpen: false })}
                  onConfirm={() => deleteAction(this.props.action)}
                  onConfirmed={() => this.props.onDeleted(this.props.action)}
                >
                  Bist du sicher, dass du die Aktion
                  <strong> {this.props.action.name} </strong> löschen willst?
                </ConfirmModal>
              </div>
            </div>
            {this.renderTriggers()}
            {this.renderEffectsAndTodoActivations()}
          </div>
        )}
      </Draggable>
    );
  }
}

ActionTemplate.propTypes = {
  todo_id: PropTypes.number.isRequired,
  action: PropTypes.object.isRequired,
  workflow_template_id: PropTypes.number.isRequired,
  workflowType: PropTypes.string.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  deletable: PropTypes.bool.isRequired,
  onDeleted: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
  triggersAllowed: PropTypes.bool.isRequired,
};

export default ActionTemplate;

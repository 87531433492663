import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../shared/Icon';
import Label from '../../shared/Label';

const AddTagEffectForm = ({ effect }) => (
  <div className="fire-effect-item">
    <div className="fire-effect-icon">
      <Icon name="tag" />
    </div>
    <div className="fire-effect-content">
      <div className="fire-effect-title">
        Tag <Label
          title={effect.tag.title}
          color={effect.tag.color}
          textColor={effect.tag.text_color}
        />wird hinzugefügt
      </div>
    </div>
  </div>
);

AddTagEffectForm.propTypes = {
  effect: PropTypes.object.isRequired,
};

export default AddTagEffectForm;

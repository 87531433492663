import React from 'react';
import PropTypes from 'prop-types';

import AssignmentSelector from '../../Assignments/AssignmentSelector';

class AssignEmailEffectForm extends React.Component {
  handleChange = (assignment) => {
    this.props.onChange(Object.assign({}, this.props.record, {
      email_template_assignment: assignment,
      email_template_assignment_id: assignment && assignment.id,
    }));
  }

  render() {
    return (
      <div className="mb-3">
        <label className="form-label">Email Zuteilung auswählen</label>
        <AssignmentSelector
          optionsUrl={`/${this.props.organizationSlug}/assignments.json?assignment_type=Assignments::EmailTemplateAssignment`}
          assigneeType="Assignments::EmailTemplateAssignment"
          value={this.props.record.email_template_assignment}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

AssignEmailEffectForm.propTypes = {
  record: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default AssignEmailEffectForm;

import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';

const PriorityIcon = ({ todo }) => {
  if (!todo.active || !todo.priority || todo.complete) return null;

  const title = `Priorität: ${todo.priority_title}`;
  const color = todo.priority_color;

  return (
    <Icon name="exclamation-circle" color={color} className="todo-priority-icon" title={title} />
  );
};

PriorityIcon.propTypes = {
  todo: PropTypes.object,
};

export default PriorityIcon;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { pluralize } from '../../helpers/helpers';
import Icon from '../shared/Icon';

const maxDisplayCount = 3;

class TodoPreviewSubTodos extends Component {
  shouldRender() {
    return (this.props.todo.todos && this.props.todo.todos.length);
  }

  renderTodos() {
    const todosToRender = this.props.todo.todos.slice(0, maxDisplayCount);
    
    return todosToRender.map((s, i) => (
      <div key={i} className="todo-preview-sub-todos-todo">
        <Icon
          light={!s.complete}
          name={s.complete ? 'check-circle' : 'circle'}
          color="#aaa"
        /> <span className={s.complete ? 'todo-preview-sub-todo-complete' : ''}>{s.text}</span>
      </div>
    ));
  }

  renderMoreTodos() {
    const diff = this.props.todo.todos.length - maxDisplayCount;

    if (diff <= 0) return null;

    return (
      <div className="todo-preview-sub-todos-more">
        <a href={`/todos/${this.props.todo.id}`}>
          {diff} {pluralize(diff, 'weitere Aufgabe', 'weitere Aufgaben')}
        </a>
      </div>
    );
  }

  render() {
    if (!this.shouldRender()) return null;

    return (
      <div className="todo-preview-sub-todos">
        {this.renderTodos()}
        {this.renderMoreTodos()}
      </div>
    );
  }
}

TodoPreviewSubTodos.propTypes = {
  todo: PropTypes.object.isRequired,
};

export default TodoPreviewSubTodos;

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['dropdown'];

  toggleDropdown(event) {
    this.dropdownTargets.forEach((targetDropdown) => {
      if (targetDropdown === event.currentTarget) {
        return;
      }
      Array.from(targetDropdown.children).forEach((child) => {
        child.classList.remove('show');
      });
    });
  }
}

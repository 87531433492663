import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const TrimmedText = ({ text, maxLength, moreText, moreHref, moreLinkStyle }) => {
  if (!text) return null;

  let textTrimmed = text;
  let trimmed = false;
  let moreLink = null;

  if (text.length > maxLength) {
    textTrimmed = textTrimmed.substring(0, maxLength);
    trimmed = true;

    if (moreHref) {
      moreLink = <a href={moreHref}>{moreText}</a>;
    }
  }

  return (
    <Fragment>
      {textTrimmed}
      {trimmed ? (
        <span style={moreLinkStyle}>... {moreLink}</span>
      ) : null}
    </Fragment>
  );
};


TrimmedText.propTypes = {
  text: PropTypes.string,
  maxLength: PropTypes.number,
  moreHref: PropTypes.string,
  moreText: PropTypes.string,
  moreLinkStyle: PropTypes.object,
};

TrimmedText.defaultProps = {
  maxLength: 160,
  moreText: 'mehr',
};

export default TrimmedText;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Select, { components } from 'react-select';

import { defaultStyles, smallStyles } from '../../styles/select_styles';

import { searchText } from '../../helpers/helpers';

const filterOptions = (option, searchTerm) => {
  const searchString = `${option.data.title} ${option.data.desc}`;
  return searchText(searchString, searchTerm);
};

const Option = props => (
  <components.Option {...props}>
    {props.data.title}
  </components.Option>
);


const SingleValue = props => (
  <components.SingleValue {...props}>
    {props.data.title}
  </components.SingleValue>
);

class ListItemSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      options: [],
    };
  }

  loadOptions = () => {
    this.setState({ isLoading: true });

    return fetch(`/lists/${this.props.listId}.json`, {
      credentials: 'include',
    }).then((response) => {
      return response.json();
    }).then((options) => {
      this.setState({
        options: options.list_items.filter((item) => {
          return !item.deprecated || this.props.showDeprecated;
        }),
        isLoading: false,
      });
    });
  }

  handleFocus = () => {
    this.loadOptions();
  }

  handleChange = (value) => {
    this.setState({ value });

    if (this.props.onChange) {
      if (value) {
        this.props.onChange(value);
      } else {
        this.props.onChange(null);
      }
    }
  }

  render() {
    return (
      <Select
        components={{
          Option,
          SingleValue,
          DropdownIndicator: this.state.isLoading ? null : components.DropdownIndicator,
        }}
        isClearable={this.props.clearable}
        isDisabled={this.props.disabled}
        isLoading={this.state.isLoading}
        onFocus={this.handleFocus}
        backspaceRemovesValue={false}
        blurInputOnSelect
        options={this.state.options}
        menuPlacement="auto"
        onChange={this.handleChange}
        filterOption={filterOptions}
        styles={this.props.size === 'small' ? smallStyles : defaultStyles}
        value={this.props.value}
        placeholder={this.props.placeholder || 'Eintrag auswählen'}
        loadingPlaceholder="Laden..."
        noOptionsMessage={() => 'Keine Ergebnisse'}
        loadingMessage={() => 'Laden...'}
      />
    );
  }
}

ListItemSelector.propTypes = {
  listId: PropTypes.number.isRequired,
  value: PropTypes.object,
  disabled: PropTypes.bool,
  showDeprecated: PropTypes.bool,
  clearable: PropTypes.bool,
};

export default ListItemSelector;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Select, { components } from 'react-select';

import { defaultStyles, smallStyles } from '../../styles/select_styles';

import { searchText } from '../../helpers/helpers';

const filterProjectTypes = (option, searchTerm) => {
  return searchText(option.data.title, searchTerm);
};

const Option = props => (
  <components.Option {...props}>
    {props.data.title}
  </components.Option>
);


const SingleValue = props => (
  <components.SingleValue {...props}>
    {props.data.title}
  </components.SingleValue>
);

class ListSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      options: [],
    };
  }

  loadOptions = () => {
    this.setState({ isLoading: true });

    return fetch(`/${this.props.organizationSlug}/lists.json`, {
      credentials: 'include',
    }).then((response) => {
      return response.json();
    }).then((options) => {
      this.setState({
        options: options.lists,
        isLoading: false,
      });
    });
  }

  handleFocus = () => {
    this.loadOptions();
  }

  handleChange = (value) => {
    this.setState({ value });

    if (this.props.onChange) {
      if (value) {
        this.props.onChange(value);
      } else {
        this.props.onChange(null);
      }
    }
  }

  render() {
    return (
      <Select
        components={{
          Option,
          SingleValue,
          DropdownIndicator: this.state.isLoading ? null : components.DropdownIndicator,
        }}
        isDisabled={this.props.disabled}
        isLoading={this.state.isLoading}
        onFocus={this.handleFocus}
        backspaceRemovesValue={false}
        blurInputOnSelect
        options={this.state.options}
        menuPlacement="auto"
        onChange={this.handleChange}
        filterOption={filterProjectTypes}
        styles={this.props.size === 'small' ? smallStyles : defaultStyles}
        value={this.props.value}
        placeholder={this.props.placeholder || 'Liste auswählen'}
        loadingPlaceholder="Laden..."
        noOptionsMessage={() => 'Keine Ergebnisse'}
        loadingMessage={() => 'Laden...'}
      />
    );
  }
}

ListSelector.propTypes = {
  organizationSlug: PropTypes.string.isRequired,
  value: PropTypes.object,
  disabled: PropTypes.bool,
};

export default ListSelector;

import React from 'react';

const Button = ({ loading, ...props }) => {
  let classNames = props.className || '';

  if (loading) classNames = `${classNames} btn-loading`;

  return (
    <button {...props} className={classNames} />
  );
};

export default Button;

/* global fetch */
/* eslint-disable quote-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TagSelector from './TagSelector';

import { getAsync } from '../../helpers/rails_helper';

class TagSelectorAsync extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
    };
  }

  handleOpen = () => {
    getAsync(this.props.optionsUrl).then((options) => {
      return options.tags;
    }).then((options) => {
      this.setState({ options });
    });
  }

  handleClose = () => {
    this.setState({
      options: [],
    });
  }

  render() {
    return (
      <TagSelector
        {...this.props}
        options={this.state.options}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
      />
    );
  }
}

TagSelectorAsync.propTypes = {
  onAdd: PropTypes.func,
  // excludedTagIds: PropTypes.array,
  optionsUrl: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  target: PropTypes.func,
  placeholder: PropTypes.string,
};

TagSelectorAsync.defaultProps = {
  buttonText: 'Tag',
};

export default TagSelectorAsync;

import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../shared/Icon';

const DeleteDataPointEffectForm = (props) => {
  return (
    <div className="fire-effect-item">
      <div className="fire-effect-icon">
        <Icon name="trash" />
      </div>
      <div className="fire-effect-content">
        <div className="fire-effect-title">
          Datenpunkt {props.effect.data_point_template.title} wird gelöscht
        </div>
      </div>
    </div>
  );
};

DeleteDataPointEffectForm.propTypes = {
  effect: PropTypes.object.isRequired,
};


export default DeleteDataPointEffectForm;

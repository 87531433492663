import React from 'react';
import Icon from '../shared/Icon';

import { components } from 'react-select';

const TagOption = (props) => {
  return (
    <components.Option {...props}>
      <span title={props.data.name}>
        <Icon name="circle" color={props.data.color} /> {props.data.name}
      </span>
    </components.Option>
  );
};

export default TagOption;

import React from 'react';
import PropTypes from 'prop-types';

const ResponsibleBadge = ({ todo, style }) => {
  if (!todo.responsible) return null;

  return (
    <div className="todo-badge todo-badge-default todo-badge-avatar" style={style}>
      <img alt={todo.fullname}
        src={todo.responsible.avatar.thumb.url}
        className="avatar avatar-tiny" />
      <span className="d-none d-xl-inline">
        {todo.responsible.display_name}
      </span>
    </div>
  );
};

ResponsibleBadge.propTypes = {
  todo: PropTypes.object,
  style: PropTypes.object,
};

export default ResponsibleBadge;

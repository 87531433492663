import React from 'react';
import PropTypes from 'prop-types';

const BooleanDataPointInput = ({ onChange, onSave, value, saving, autoFocus }) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && onSave) {
      onSave();
    }
  };

  let stringValue = '';

  if (typeof value === 'boolean') {
    stringValue = value.toString();
  } else if (typeof value === 'string') {
    stringValue = value;
  }

  return (
    <select
      className="form-control"
      onKeyDown={handleKeyDown}
      autoFocus={autoFocus}
      disabled={saving}
      value={stringValue}
      onChange={handleChange}>
      <option value="">Bitte auswählen</option>
      <option value="true">Ja</option>
      <option value="false">Nein</option>
    </select>
  );
};

BooleanDataPointInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  autoFocus: PropTypes.bool,
};

export default BooleanDataPointInput;

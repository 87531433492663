/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ProjectMembership from './ProjectMembership';
import NewMembership from './NewMembership';

import ProjectRole from './ProjectRole';

import CompanyRole from './CompanyRole';

import { subscribe, unsubscribe } from '../../helpers/actioncable_helper';
import { updateAsync, deleteAsync, postAsync } from '../../helpers/rails_helper';

const MAX_MEMBERSHIPS_TO_SHOW = 4;

const isOverdue = (membership) => {
  return membership.due && Date.parse(membership.due) < new Date();
};

const membershipsComparer = (a, b) => {
  if (a.responsible_for_active_todo && !b.responsible_for_active_todo) {
    return -1;
  }

  if (!a.responsible_for_active_todo && b.responsible_for_active_todo) {
    return 1;
  }

  if (a.responsible_for_active_todo && b.responsible_for_active_todo) {
    if (isOverdue(a) && !isOverdue(b)) {
      return -1;
    }

    if (!isOverdue(a) && isOverdue(b)) {
      return 1;
    }
  }
  return 0;
};

class ProjectMemberships extends Component {
  constructor(props) {
    super(props);

    this.state = {
      memberships: [],
      roles: [],
      showAll: false,
      loading: true,
    };
  }

  componentDidMount() {
    this.loadMemberships();
    this.loadRoles();

    this.membershipsChangesChannel = subscribe({
      channel: 'ProjectMembershipChangesChannel',
      project_id: this.props.projectId,
    }, ({ project_membership, action }) => {
      if (action === 'new') {
        this.handleNewMembership(project_membership);
      } else if (action === 'delete') {
        this.handleDeleteMembership(project_membership);
      } else if (action === 'update') {
        this.handleUpdateMembership(project_membership);
      }
    });
  }

  componentWillUnmount() {
    unsubscribe(this.membershipsChangesChannel);
  }

  getMembershipsWithoutVisibleProjectRoles = () => {
    return this.state.memberships.filter((membership) => (
      !membership.project_roles || membership.project_roles.every(p => p.hidden)
    ));
  };

  loadRoles = () => {
    const url = `/projects/${this.props.projectId}/project_roles.json`;

    return fetch(url, {
      credentials: 'include',
    }).then((response) => {
      return response.json();
    }).then((result) => {
      this.setState({
        roles: result.roles,
        rolesLoading: false,
        loading: this.state.membershipsLoading,
      });
    });
  };

  loadMemberships = () => {
    const url = `/projects/${this.props.projectId}/memberships.json`;

    return fetch(url, {
      credentials: 'include',
    }).then((response) => {
      return response.json();
    }).then((result) => {
      this.setState({
        memberships: result.memberships.sort(membershipsComparer),
        membershipsLoading: false,
        loading: this.state.rolesLoading,
      });
    });
  };

  handleShowAllClick = (e) => {
    e.preventDefault();

    this.setState({ showAll: true });
  };

  handleNewMembership = (newMembership) => {
    if (!this.state.memberships.some(m => m.id === newMembership.id)) {
      this.setState(prevState => ({
        memberships: [...prevState.memberships, newMembership].sort(membershipsComparer),
      }));
    }
  };

  handleDeleteMembership = (membership) => {
    this.setState(prevState => ({
      memberships: prevState.memberships.filter((m) => {
        return m.id !== membership.id;
      }).sort(membershipsComparer),
    }));
  };

  handleUpdateMembership = (membership) => {
    this.setState(prevState => ({
      memberships: prevState.memberships.map((m) => {
        if (m.id === membership.id) {
          return membership;
        }

        return m;
      }).sort(membershipsComparer),
    }));
  };

  handleDelete = (membership) => {
    deleteAsync(`/project_memberships/${membership.id}`).then(() => {
      this.handleDeleteMembership(membership);
    });
  };

  handleUpdate = (membership) => {
    updateAsync(`/project_memberships/${membership.id}`, {
      project_membership: {
        manager: membership.manager,
      },
    }).then(({ project_membership }) => {
      this.handleUpdateMembership(project_membership);
    });
  };

  handleAdd = (userId) => {
    postAsync(`/projects/${this.props.projectId}/project_memberships`, {
      project_membership: {
        user_id: userId,
      },
    }).then(({ project_membership }) => {
      this.handleNewMembership(project_membership);
    });
  };

  renderAdditionalMemberships() {
    if (this.state.showAll) return null;

    const memberships = this.getMembershipsWithoutVisibleProjectRoles();

    if (memberships.length <= MAX_MEMBERSHIPS_TO_SHOW) return null;

    const count = (memberships.length - MAX_MEMBERSHIPS_TO_SHOW) + 1;

    return (
      <a href="#" className="project-memberships-more" onClick={this.handleShowAllClick}>
        +{count}
      </a>
    );
  }

  renderMemberships() {
    let membershipsToShow;

    const memberships = this.getMembershipsWithoutVisibleProjectRoles();

    if (this.state.showAll) {
      membershipsToShow = memberships;
    } else if (memberships.length <= MAX_MEMBERSHIPS_TO_SHOW) {
      membershipsToShow = memberships;
    } else {
      membershipsToShow = memberships.slice(0, MAX_MEMBERSHIPS_TO_SHOW - 1);
    }

    return membershipsToShow.map(m => (
      <ProjectMembership
        editable={this.props.allowEdit}
        membership={m}
        key={m.id}
        onUpdate={this.handleUpdate}
        onDelete={this.handleDelete}
      />
    ));
  }

  renderProjectRoles() {
    const roles = [];
    const comps = [];

    this.state.memberships.forEach((membership) => {
      membership.project_roles.forEach((project_role) => {
        if (!project_role.hidden && !roles.find(r => r.id === project_role.id)) {
          roles.push(project_role);
          comps.push(
            <ProjectRole
              role={project_role}
              key={project_role.id}
              membership={membership}
              editable={this.props.allowEdit}
              onUpdate={this.handleUpdate}
              onDelete={this.handleDelete}
            />,
          );
        }
      });
    });

    if (roles.length === 0 && this.state.roles.length === 0) return null;

    return (
      <div
        style={{
          borderRight: '2px solid var(--bs-secondary-border-subtle)',
          paddingRight: 5,
          marginRight: 10,
          display: 'flex',
        }}>
        {comps}
        {this.renderCompanyRoles()}
      </div>
    );
  }

  renderCompanyRoles() {
    if (this.state.roles.length === 0) return;
    return this.state.roles.map(m => (
      <CompanyRole
        editable={this.props.allowEdit}
        role={m}
        key={m.id}
        onUpdate={this.handleUpdate}
        onDelete={this.handleDelete}
      />
    ));
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="box-loading" style={{ fontSize: 8, width: 25, height: 9 }} />
      );
    }

    return (
      <div className="d-flex align-items-center">
        {this.renderProjectRoles()}
        {this.renderMemberships()}
        {this.renderAdditionalMemberships()}
        <NewMembership
          organizationSlug={this.props.organizationSlug}
          visible={this.props.allowEdit}
          onAdd={this.handleAdd}
          existingMemberships={this.state.memberships}
        />
      </div>
    );
  }
}

ProjectMemberships.propTypes = {
  projectId: PropTypes.number.isRequired,
  allowEdit: PropTypes.bool.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default ProjectMemberships;

/* eslint-disable quote-props */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';

import Dropdown from '../shared/Dropdown';
import Tooltip from '../shared/Tooltip';
import Icon from '../shared/Icon';

import TagOption from './TagOption';

import { dropdownStyles } from '../../styles/select_styles';

import { searchText } from '../../helpers/helpers';

const filterOption = (option, searchTerm) => {
  let title = `${option.data.title} ${option.data.desc}`;

  if (option.data.group) {
    title += option.data.group.title
  }

  return searchText(title, searchTerm);
};

const removeTagOption = {
  title: 'Tag entfernen',
  icon: 'trash',
  id: null,
};

class TagGroupSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropDownVisible: false,
      options: [],
      loading: false,
    };
  }

  toggleDropDown = (e) => {
    e.preventDefault();
    this.setState({ dropDownVisible: !this.state.dropDownVisible });
  }

  getStyle = () => {
    if (this.props.tag) {
      return {
        fontWeight: 'bold',
        color: this.props.tag.text_color,
        border: `1px solid ${this.props.tag.color}`,
        backgroundColor: this.props.tag.color,
      };
    }
  }

  loadOptions = () => {
    this.setState({ loading: true });

    fetch(`${this.props.optionsUrl}?g=${this.props.group.id}`, {
      credentials: 'include',
    }).then(response => response.json()).then((data) => {
      const options = [];

      if (this.props.tag) {
        options.push({
          label: '',
          options: [removeTagOption],
        });
      }

      options.push({
        label: this.props.group.title,
        options: data.tags,
      });

      this.setState({ options, loading: false });
    });
  }

  handleFocus = () => {
    if (!this.state.options.length) {
      this.loadOptions();
    }
  }

  handleChange = (tag) => {
    if (!tag.id) {
      this.props.onRemoveClick(null, false);
    } else {
      this.props.onSelect(tag);
    }
  }

  // Tooltip anzeigen, wenn desc vorhanden
  // ODER gruppe vorhanden, aber versteckt
  tooltipEnabled = () => {
    return (
      !!this.props.tag
      && !this.state.dropDownVisible
      && (
        !!this.props.tag.desc
        || (this.props.tag.group && this.props.tag.group.hide_title)
      )
    );
  }

  tooltipTitle = () => {
    let title = '';

    if (this.props.tag && this.props.tag.group && this.props.tag.group.hide_title) {
      title = `${this.props.tag.group.title}: `;
    }

    if (this.props.tag && this.props.tag.desc) {
      title = `${title}${this.props.tag.desc}`;
    } else if (title) {
      title = `${title}${this.props.tag.title}`;
    }

    return title;
  }

  renderTitle() {
    if (this.props.tag) {
      return this.props.tag.full_title;
    }

    return this.props.group.title;
  }

  renderButton = (ref) => {
    return (
      <button ref={ref} onClick={this.toggleDropDown} className="btn btn-outline-secondary btn-label" style={this.getStyle()}>
        {this.renderTitle()}
        <Icon className="label-caret" name="caret-down" />
      </button>
    );
  }

  render() {
    return (
      <Tooltip enabled={this.tooltipEnabled()} title={this.tooltipTitle()} placement="top">
        <Dropdown
          isOpen={this.state.dropDownVisible}
          onClose={this.toggleDropDown}
          target={this.renderButton}>
          <Select
            autoFocus
            components={{
              Option: TagOption,
              DropdownIndicator: null,
              IndicatorSeparator: null,
            }}
            onFocus={this.handleFocus}
            openMenuOnFocus
            clearable={false}
            onBlur={this.handleBlur}
            onKeyDown={this.handleKeyDown}
            backspaceRemovesValue={false}
            blurInputOnSelect
            menuPlacement="auto"
            menuIsOpen
            filterOption={filterOption}
            onChange={this.handleChange}
            handleInputChange={this.handleInputChange}
            options={this.state.options}
            defaultOptions
            styles={dropdownStyles}
            placeholder="Tag auswählen"
            loadingPlaceholder="Laden..."
            noOptionsMessage={() => 'Keine Ergebnisse'}
            loadingMessage={() => 'Laden...'}
          />
        </Dropdown>
      </Tooltip>
    );
  }
}

TagGroupSelect.propTypes = {
  onRemoveClick: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  group: PropTypes.object.isRequired,
  optionsUrl: PropTypes.string.isRequired,
  tag: PropTypes.object,
};

export default TagGroupSelect;

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import TextArea from '../shared/TextArea';

import DatetimePicker from "../DatePickers/DatetimePickerComponent";
import QuickDelaySelector from '../shared/QuickDelaySelector';

class DelayForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      due: this.props.due,
      reason: '',
    };
  }

  handleQuickDelaySelected = (value) => {
    const due = value.date;
    this.setState({ due });
  };

  handleDueChange = (due) => {
    this.setState({ due });
  };

  handleReasonChange = (e) => {
    this.setState({ reason: e.target.value });
  };

  handleSave = (e) => {
    e.preventDefault();

    this.props.onDelay({
      due: this.state.due,
      reason: this.state.reason,
    });
  };

  renderDue() {
    return (
      <div className="fire-effect-item">
        <div className="fire-effect-icon">
          <Icon name="clock" />
        </div>
        <div className="fire-effect-content">
          <div className="fire-effect-title">
            Neue Fälligkeit
          </div>
          <div className="fire-effect-inputs">
            <div className="row">
              <div className="col-sm-6">
                <DatetimePicker
                  value={this.state.due}
                  onChange={this.handleDueChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <QuickDelaySelector onChange={this.handleQuickDelaySelected} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderComment() {
    if (!this.props.reasonRequired) return null;

    return (
      <div className="fire-effect-item">
        <div className="fire-effect-icon">
          <Icon name="comment" />
        </div>
        <div className="fire-effect-content">
          <div className="fire-effect-title">
            Grund des Vertagens
          </div>
          <div className="fire-effect-inputs">
            <TextArea
              autoSize
              value={this.state.reason}
              onChange={this.handleReasonChange}
              className="form-control"
              placeholder="Bitte eingeben"
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <div className="actions-container-section actions-container-section-delay">
          <div className="section-header">
            <div className="section-icon">
              <Icon name="clock" />
            </div>
            <div className="section-name">
              Vertagen
            </div>
          </div>
        </div>
        <div className="actions-container-section">
          <div className={this.props.saving ? 'box-loading' : ''}>
            {this.renderDue()}
            {this.renderComment()}
          </div>
        </div>
        <div className="actions-container-section">
          <button
            type="button"
            className="btn btn-primary"
            disabled={this.props.saving}
            onClick={this.handleSave}>
            bestätigen
          </button>
          <button
            type="button"
            className="btn btn-link"
            style={{ marginLeft: 10 }}
            onClick={this.props.onCancel}>
            abbrechen
          </button>
        </div>
      </>
    );
  }
}

DelayForm.propTypes = {
  reasonRequired: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onDelay: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  due: PropTypes.string
};

export default DelayForm;

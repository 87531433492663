import React from 'react';
import PropTypes from 'prop-types';
import ModelSelect from '../shared/ModelSelect';

function CraftSelector(props) {
  return (
    <ModelSelect
      model="crafts"
      optionsUrl={`/${props.organizationSlug}/crafts.json`}
      name={props.name}
      placeholder="Gewerk"
      value={props.value}
      onChange={props.onChange}
    />
  );
}

CraftSelector.propTypes = {
  value: PropTypes.object,
  name: PropTypes.string.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CraftSelector;

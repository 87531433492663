/* global document, window */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ContextMenu extends Component {
  componentDidMount() {
    window.addEventListener('click', this._handleOutsideClick);
    window.addEventListener('contextmenu', this._handleOutsideClick);
    window.addEventListener('keydown', this._handleEsc);
  }

  componentDidUpdate() {
    if (this.props.menu.visible) {
      const clickX = this.props.menu.clickX;
      const clickY = this.props.menu.clickY;
      const screenW = window.innerWidth;
      const screenH = window.innerHeight;
      const rootW = this.menu.offsetWidth;
      const rootH = this.menu.offsetHeight;

      const right = (screenW - clickX) > rootW;
      const left = !right;
      const top = (screenH - clickY) > rootH;
      const bottom = !top;

      if (right) {
        this.root.style.left = `${clickX + 5}px`;
      }

      if (left) {
        this.root.style.left = `${clickX - rootW - 5}px`;
      }

      if (top) {
        this.root.style.top = `${clickY + 5}px`;
      }

      if (bottom) {
        this.root.style.top = `${clickY - rootH - 5}px`;
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this._handleOutsideClick);
    document.removeEventListener('keydown', this._handleEsc);
  }

  _handleEsc = (event) => {
    if (event.keyCode === 27) {
      if (this.props.menu.visible) {
        this.props.menu.handleBlur();
      }
    }
  }

  _handleOutsideClick = (event) => {
    // Firefox Bug abfangen: Click Event wird auch bei contextmenu-Klick ausgelöst,
    // was dazu führt, dass das Kontextmenü sofort wieder verschwindet
    // if (event.button === 2) return;

    if (this.props.menu.visible) {
      if (!(event.target.contains === this.root)) {
        this.props.menu.handleBlur();
      }
    }
  };

  render() {
    if (this.props.menu.visible) {
      return (
        <div ref={e => (this.root = e)} className="dropdown-menu-container">
          <div ref={e => (this.menu = e)} className="dropdown-menu" style={{ display: 'inherit' }}>
            {this.props.children}
          </div>
        </div>
      );
    }

    return null;
  }
}

ContextMenu.propTypes = {
  children: PropTypes.node,
  menu: PropTypes.object.isRequired,
};

export default ContextMenu;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { subscribe, unsubscribe } from '../../helpers/actioncable_helper';

class ActiveTriggers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTriggersCount: props.activeTriggersCount
    };
  }

  componentDidMount() {
    this.channel = subscribe({
      channel: 'ProjectTriggerChangesChannel',
      project_id: this.props.projectId,
    }, ({ active_triggers_count }) => {
      this.setState({
        activeTriggersCount: active_triggers_count,
      });
    });
  }

  componentWillUnmount() {
    unsubscribe(this.channel);
  }

  render() {
    if (this.state.activeTriggersCount === 0) return null;

    let text;

    if (this.state.activeTriggersCount === 1) {
      text = 'Ein aktiver Trigger';
    } else {
      text = `${this.state.activeTriggersCount} aktive Trigger`;
    }

    return (
      <span className="additional-project-info d-print-none">
        <span className="trigger-indicator-container">
          <span className="trigger-indicator" />
          <span className="trigger-indicator-text">
            {text}
          </span>
        </span>
      </span>
    );
  }
}

ActiveTriggers.propTypes = {
  activeTriggersCount: PropTypes.number.isRequired,
  projectId: PropTypes.number.isRequired,
};

export default ActiveTriggers;

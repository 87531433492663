import { Controller } from '@hotwired/stimulus';

const isDateType = (type) => {
  return ['DataPoint::DateDataPoint', 'DataPoint::DateTimeDataPoint'].includes(type);
};

const isStringChoiceDataType = (type) => {
  return [
    'DataPoint::StringChoiceDataPoint',
    'DataPoint::MultiStringChoiceDataPoint',
  ].includes(type);
};

export default class extends Controller {
  static targets = ['autofillField', 'listSelect', 'options', 'regex'];

  type_changed(e) {
    if (isDateType(e.target.value)) {
      this.autofillFieldTarget.disabled = false;
      this.listSelectTarget.classList.add('d-none');
    } else if (e.target.value === 'DataPoint::ListDataPoint') {
      this.autofillFieldTarget.checked = false;
      this.autofillFieldTarget.disabled = true;
      this.listSelectTarget.classList.remove('d-none');
    } else {
      this.autofillFieldTarget.checked = false;
      this.autofillFieldTarget.disabled = true;
      this.listSelectTarget.classList.add('d-none');
    }

    if (isStringChoiceDataType(e.target.value)) {
      this.optionsTarget.classList.remove('d-none');
      this.regexTarget.classList.add('d-none');
    } else if (e.target.value === 'DataPoint::StringDataPoint') {
      this.regexTarget.classList.remove('d-none');
      this.optionsTarget.classList.add('d-none');
    } else {
      this.optionsTarget.classList.add('d-none');
      this.regexTarget.classList.add('d-none');
    }
  }
}

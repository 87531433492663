import React from 'react';
import PropTypes from 'prop-types';

const DocumentPreview = ({ document }) => {
  if (document.content_type) {
    if (document.content_type.startsWith('application/pdf') && window.innerWidth > 800) {
      return (
        <div className="preview-pdf">
          <embed src={document.file_url} />
        </div>
      );
    }

    if (document.has_preview) {
      return (
        <div className="preview-image">
          <img src={document.file_big_url} alt="großes Vorschaubild" />
        </div>
      );
    }

    if (document.content_type.startsWith('audio')) {
      return (
        <div className="preview-icon">
          <audio controls preload="none" style={{ maxWidth: 480 }}>
            <source src={document.file_url} />
            Keine Vorschau verfügbar
          </audio>
        </div>
      );
    }

    if (document.content_type.startsWith('video')) {
      return (
        <div className="preview-icon">
          <video controls preload="none" style={{ maxWidth: '80%', maxHeight: '80%' }}>
            <source src={document.file_url} />
            Keine Vorschau verfügbar
          </video>
        </div>
      );
    }
  }

  return (
    <div className="preview-icon">
      <div>
        <img src={document.thumb} alt="Vorschaubild" /><br />
        Keine Vorschau verfügbar
      </div>
    </div>
  );
};

DocumentPreview.propTypes = {
  document: PropTypes.object,
};

export default DocumentPreview;

import React from 'react';
import PropTypes from 'prop-types';

import ConfirmModal from '../shared/ConfirmModal';
import Icon from '../shared/Icon';
import Tooltip from '../shared/Tooltip';

import { timeInWords } from './DurationSelector';

import { deleteTrigger } from '../../helpers/api';

const displayTriggerText = (trigger) => {
  if (trigger.type === 'Triggers::TimeTrigger') {
    return `${timeInWords(trigger.integer_value_1)} Aktivierung`;
  }

  if (trigger.type === 'Triggers::DueTrigger') {
    return `${timeInWords(trigger.integer_value_1)} Fälligkeit`;
  }

  if (trigger.type === 'Triggers::EventTrigger') {
    return trigger.event.title;
  }

  if (trigger.type === 'Triggers::WebhookTrigger') {
    return trigger.webhook.title;
  }

  return '';
};

const DeleteTarget = onClick => (
  <Tooltip title="Trigger löschen">
    <button
      type="button"
      className="btn btn-compact btn-link"
      onClick={onClick}>
      <Icon name="trash" />
    </button>
  </Tooltip>
);

const TriggerTemplate = ({ trigger, onDeleted, deletable }) => {
  return (
    <div key={trigger.id} className="trigger-template">
      <div className="trigger-icon">
        <Icon name="timer" />
      </div>
      {displayTriggerText(trigger)}
      <ConfirmModal
        target={DeleteTarget}
        title="Trigger löschen"
        cancelText="abbrechen"
        confirmText="löschen"
        confirmStyle="danger"
        visible={deletable}
        onConfirm={() => deleteTrigger(trigger)}
        onConfirmed={() => onDeleted(trigger)}>
        Bist du sicher, dass du den Trigger löschen willst?
      </ConfirmModal>
    </div>
  );
};

TriggerTemplate.propTypes = {
  trigger: PropTypes.object.isRequired,
  onDeleted: PropTypes.func.isRequired,
  deletable: PropTypes.bool.isRequired,
};

export default TriggerTemplate;

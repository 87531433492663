import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { updateAsync } from '../../helpers/rails_helper';

class Normalize extends Component {
  handleNormalizeClick = (e, country) => {
    e.preventDefault();

    updateAsync(`/phone_numbers/${this.props.phoneNumber.id}`, {
      'country_id': country.id,
    }, 'PUT').then((result) => {
      if (result.error) {
        alert(result.error);
      } else {
        this.props.onChange(result.phone_number);
      }
    });
  }

  renderCountries() {
    return this.props.countries.map(country => (
      <a className="dropdown-item" href="" onClick={e => this.handleNormalizeClick(e, country)} key={country.id}>
        {country.name} (+{country.phone_number_prefix})
      </a>
    ));
  }

  render() {
    if (this.props.hidden) return null;

    return (
      <div className="btn-group" style={{ marginLeft: 8 }}>
        <button type="button" className="btn btn-xs btn-warning dropdown-toggle correct-number-dropdown" data-bs-toggle="dropdown">
          Nummer korrigieren <span className="caret" />
        </button>

        <div className="dropdown-menu">
          {this.renderCountries()}
          <div className="dropdown-divider" />
          <a href={`/contacts/${this.props.contactId}/edit`} className="dropdown-item">Manuell ändern</a>
        </div>
      </div>
    );
  }
}

Normalize.propTypes = {
  hidden: PropTypes.bool,
  countries: PropTypes.array,
  contactId: PropTypes.number.isRequired,
  phoneNumber: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Normalize;

const flashQueue = [];
let rendering = false;

const renderFlashMessage = (message, callback) => {
  rendering = true;

  const element = document.getElementById('flash-container');

  element.innerHTML = `
    <div class="container">
      <div class="row">
        <div class="col-md-5">                    
          <div class="flash flash-${message.type}">
            <div class="flash-border"></div>
            <div class="flash-content">
              ${message.text}
            </div>
          </div>
        </div>
      </div>
    </div>
  `;

  element.style.animation = 'none';
  element.offsetHeight; // trigger reflow
  element.style.animation = null;

  setTimeout(() => {
    rendering = false;
    callback();
  }, 5500);
};

const renderNextFlash = () => {
  if (rendering) return;

  const message = flashQueue.shift();

  if (message) {
    renderFlashMessage(message, renderNextFlash);
  }
};

const flashMessage = (text, options = {}) => {
  const message = {
    text,
    type: options.type || 'notice', // notice, alert, error
  };

  flashQueue.push(message);
  renderNextFlash();
};

const flashNotice = (text) => {
  flashMessage(text, { type: 'notice' });
};

const flashAlert = (text) => {
  flashMessage(text, { type: 'alert' });
};

const flashError = (text) => {
  flashMessage(text, { type: 'error' });
};

export {
  flashNotice,
  flashAlert,
  flashError,
};

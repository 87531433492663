import React from 'react';
import PropTypes from 'prop-types';
import FilterComponent from '../shared/FilterComponent';
import UserOption, { filterUserOption } from './UserOption';

const UserFilter = (props) => {
  return (<FilterComponent
    {...props}
    iconName="user"
    entitiesName="users"
    optionComponent={UserOption}
    filterOption={filterUserOption}
    placeholder="Benutzer auswählen"
  />);
};

UserFilter.propTypes = {
  optionsUrl: PropTypes.string.isRequired,
  text: PropTypes.string,
  multi: PropTypes.bool,
  queryParam: PropTypes.string,
  dropdownPlacement: PropTypes.string,
};

UserFilter.defaultProps = {
  text: '',
  multi: false,
  queryParam: 'u',
};

export default UserFilter;

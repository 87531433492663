import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';

import { smallStyles, defaultStyles } from '../../styles/select_styles';

const DataPointStringOptionSelector = (props) => {
  const optionsForSelect = props.options.map(o => ({ label: o, value: o }));

  const selectedOption = optionsForSelect.find(o => o.value === props.value);

  return (
    <Select
      value={selectedOption}
      options={optionsForSelect}
      onChange={props.onChange}
      styles={props.size === 'small' ? smallStyles : defaultStyles}
      placeholder={props.placeholder}
    />
  );
};

DataPointStringOptionSelector.propTypes = {
  options: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.string,
  placeholder: PropTypes.string,
};

DataPointStringOptionSelector.defaultProps = {
  size: 'small',
  placeholder: 'Bitte Option auswählen',
};

export default DataPointStringOptionSelector;

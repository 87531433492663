/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TimeAgo from '../shared/TimeAgo';
import Avatar from '../shared/Avatar';

import EmailMessage from '../EmailMessages/EmailMessage';
import Icon from '../shared/Icon';

class TodoEmailSentActivity extends Component {
  handleEmailMessageDeleted = (email_message) => {
    this.props.onChange(Object.assign({}, this.props.activity, {
      email_message,
    }));
  }

  renderActivityTitle() {
    return (
      <div style={{ display: 'flex', marginBottom: 6 }}>
        <div style={{ flexGrow: 1 }}>
          <a href={`/users/${this.props.activity.email_message.user.id}`} className="activity-owner">
            <span className="fullname_with_avatar">
              <Avatar
                wrapperClass="avatar-link-with-fullname"
                size="tiny"
                account={this.props.activity.email_message.user}
              />{this.props.activity.email_message.user.fullname}
            </span>
          </a> hat <TimeAgo
            datetime={this.props.activity.email_message.created_at}
            locale="de" /> eine Email geschickt
        </div>
      </div>
    );
  }

  render() {
    const animated = this.props.activity.initial ? '' : 'todo-activity-animated';

    return (
      <div className={`todo-activity-feed-item todo-activity ${animated}`}>
        <div>
          <div className="icon">
            <div className="btn-round btn-round-info-filled btn-round-outline">
              <Icon name="envelope" />
            </div>
          </div>

          <div className="line" />
          <div className="line-short" />
          <div className="content" style={{ borderBottom: 'none' }}>
            {this.renderActivityTitle()}
            <EmailMessage
              email_message={this.props.activity.email_message}
              managerOrAdmin={this.props.managerOrAdmin}
              onDeleted={this.handleEmailMessageDeleted}
              userId={this.props.userId}
              hideEmailable
            />
          </div>
        </div>
      </div>
    );
  }
}

TodoEmailSentActivity.propTypes = {
  activity: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  userId: PropTypes.number.isRequired,
  managerOrAdmin: PropTypes.bool.isRequired,
};

export default TodoEmailSentActivity;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../shared/Icon';
import OrderRequest from './OrderRequest';

class NewOrderRequests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderRequests: (props.orderRequests || []).map((o, i) => {
        return Object.assign({}, o, { key: i });
      }),
    };

    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  handleRemove(index) {
    this.setState({
      orderRequests: this.state.orderRequests.filter((o, i) => (i !== index)),
    });
  }

  handleAddClick(e) {
    e.preventDefault();

    this.setState({
      orderRequests: [...this.state.orderRequests, {
        key: (new Date()).getTime(),
        amount: 1,
      }],
    });
  }

  renderOrderRequests() {
    return this.state.orderRequests.map((orderRequest, i) => {
      return (
        <OrderRequest
          key={orderRequest.key}
          index={i}
          organizationSlug={this.props.organizationSlug}
          orderRequest={orderRequest}
          onRemove={this.handleRemove}
          deletable={this.state.orderRequests.length > 1}
        />
      );
    });
  }

  renderHeader() {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ width: '10%', paddingRight: 8 }}>Anzahl</div>
        <div style={{ width: '50%', paddingRight: 8 }}>Produkt</div>
        <div style={{ width: '20%', paddingRight: 8 }}>Vorschlag Lieferant</div>
        <div style={{ width: '15%' }}>Anlieferung</div>
        <div style={{ flexGrow: 1 }} />
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderHeader()}
        {this.renderOrderRequests()}
        <p>
          <a href="#" onClick={this.handleAddClick} className="link-with-icon">
            <Icon name="plus" />
            Zeile hinzufügen
          </a>
        </p>
      </div>
    );
  }
}

NewOrderRequests.propTypes = {
  orderRequests: PropTypes.array,
  organizationSlug: PropTypes.string.isRequired,
};

export default NewOrderRequests;

/* global window */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../shared/Tooltip';

import AttachmentPreviewer from './AttachmentPreviewer';
import Attachment from '../Attachments/Attachment';

class AttachmentGallery extends Component {
  renderAttachment(attachment, openPreview) {
    return (<Attachment
      attachment={attachment}
      key={attachment.id}
      onClick={e => openPreview(attachment)}
      size={this.props.size}
    />);
  }

  renderAttachments(openPreview) {
    if (!this.props.attachments.length) return null;

    let previews = this.props.attachments;

    if (this.props.attachments.length > this.props.maxPreviews) {
      previews = this.props.attachments.slice(0, this.props.maxPreviews - 1);
    }

    let plus = '';

    if (previews.length < this.props.attachments.length) {
      plus = (
        <button
          className={`gallery-plus gallery-plus-${this.props.size}`}
          onClick={_ => openPreview(this.props.attachments[this.props.maxPreviews - 1])}>
          <div>
            +{this.props.attachments.length - previews.length}
          </div>
        </button>
      );
    }

    return (
      <div className="comment-attachments" style={this.props.style}>
        {previews.map(attachment => (
          this.props.showTooltip ?
            (<Tooltip placement="top" title={attachment.name} key={attachment.id}>{this.renderAttachment(attachment, openPreview)}</Tooltip>)
            : this.renderAttachment(attachment, openPreview)
        ))
        }
        {plus}
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        <AttachmentPreviewer attachments={this.props.attachments}>
          {(openPreview) => this.renderAttachments(openPreview)}
        </AttachmentPreviewer>
      </Fragment>
    );
  }
}

AttachmentGallery.propTypes = {
  attachments: PropTypes.array.isRequired,
  maxPreviews: PropTypes.number,
  size: PropTypes.string,
  style: PropTypes.object,
  showTooltip: PropTypes.bool,
};

AttachmentGallery.defaultProps = {
  size: 'responsive',
  showTooltip: false,
};

export default AttachmentGallery;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../shared/Icon';

const NotSendable = ({ sendable, title }) => {
  if (sendable) return null;

  return (
    <div>
      <div style={{ color: '#d9534f' }}>
        Email <strong>{title}</strong> kann nicht versendet werden.
      </div>
      <div className="fw-normal text-secondary">
        <small>
          Hierfür muss ein Kunde mit Emailadresse verknüpft sein.
          Zudem muss die Email für den anonymen Versand konfiguriert sein
          oder die Aufgabe einem Verantwortlichen
          mit verifizierter Emailadresse zugewiesen sein.
        </small>
      </div>
    </div>
  );
};

const Sendable = ({ effect, value, onChange }) => {
  if (!effect.email_sendable) return null;

  const style = {
    display: 'flex',
  };

  if (value.do_not_send) {
    style.textDecoration = 'line-through';
    style.color = '#888';
  }

  const link = (
    <a href={`/email_templates/${effect.email_template.id}`} target="_blank" rel="noreferrer">
      {effect.email_template.title}
    </a>
  );

  return (
    <div style={style}>
      <div style={{ flexGrow: 1 }}>
        Email {link} wird an Kunden gesendet
      </div>
      <div>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            checked={!value.do_not_send}
            onClick={() => onChange({ do_not_send: !value.do_not_send })}
          />
        </div>
      </div>
    </div>
  );
};

const EmailEffectForm = ({ effect, value, onChange }) => {
  const [currentEffect, setCurrentEffect] = useState();
  const [loading, setLoading] = useState(true);

  // load most up to date effect (uncached) from server,
  // because attribute sendable may contain old value
  // invalidating the cache server side would be to complex
  useEffect(() => {
    fetch(`/effects/${effect.id}.json`).then((response) => {
      return response.json();
    }).then((eff) => {
      setCurrentEffect(eff);
      setLoading(false);
    });
  }, []);

  return (
    <div className="fire-effect-item">
      <div className="fire-effect-icon">
        <Icon name="envelope" />
      </div>
      <div className="fire-effect-content">
        <div className="fire-effect-title">
          {loading ? 'laden...' : (
            <div>
              <NotSendable
                sendable={currentEffect.email_sendable}
                title={currentEffect.email_template.title}
              />
              <Sendable
                effect={currentEffect}
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

EmailEffectForm.propTypes = {
  effect: PropTypes.object.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default EmailEffectForm;

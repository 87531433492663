import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';
import { debounce } from 'lodash';

export default class extends Controller {
  static outlets = ['body'];

  static targets = ['searchType', 'searchDistance', 'searchInstallationCompany', 'installationCompanyId', 'submit', 'loadingSpinner'];

  static values = {
    installationCompaniesPath: String,
  };

  initialize() {
    this.searchInstallationCompanies = debounce(this.searchInstallationCompanies, 200).bind(this);
  }

  connect() {
    this.toggleSubmit();
  }

  selectSearchDistance() {
    const searchTypeDistanceTarget = this.searchTypeTargets.find(searchTypeTarget => searchTypeTarget.value === 'distance');
    searchTypeDistanceTarget.checked = true;
    this.searchDistanceTarget.disabled = false;
    this.searchDistanceTarget.focus();
    this.searchInstallationCompanyTarget.disabled = true;
  }

  selectInstallationCompany() {
    const searchTypeInstallationCompanyTarget = this.searchTypeTargets.find(searchTypeTarget => searchTypeTarget.value === 'installation_company');
    searchTypeInstallationCompanyTarget.checked = true;
    this.searchInstallationCompanyTarget.disabled = false;
    this.searchInstallationCompanyTarget.focus();
    this.searchDistanceTarget.disabled = true;
  }

  searchInstallationCompanies() {
    this.toggleSubmit();

    const params = new URLSearchParams();

    this.loadingSpinnerTarget.classList.remove('d-none');

    const selectedSearchType = this.searchTypeTargets.find(searchTypeTarget => searchTypeTarget.checked);
    if (!selectedSearchType) return;
    this.searchDistanceTarget.disabled = selectedSearchType.value !== 'distance';
    this.searchInstallationCompanyTarget.disabled = selectedSearchType.value !== 'installation_company';
    params.append('search_type', selectedSearchType.value);
    if (selectedSearchType.value === 'distance') params.append('search_distance', this.searchDistanceTarget.value);
    if (selectedSearchType.value === 'installation_company') params.append('search_installation_company', this.searchInstallationCompanyTarget.value);

    this.submitTarget.disabled = true;

    get(`${this.installationCompaniesPathValue}?${params.toString()}`, {
      responseKind: 'turbo-stream',
    }).then(_response => {
      this.loadingSpinnerTarget.classList.add('d-none');
      setTimeout(() => {
        this.bodyOutlets.forEach(bodyOutlet => bodyOutlet.connect());
      }, 500);
    });
  }

  setInstallationIds(event) {
    this.appointmentIdTarget.value = event.target.dataset.appointmentId;
    this.toggleSubmit();
  }

  toggleSubmit() {
    this.submitTarget.disabled = !this.installationCompanyIdTargets.find(t => t.checked);
  }

  submit(event) {
    if (!this.installationCompanyIdTargets.find(t => t.checked)) {
      event.preventDefault();
    }
  }
}

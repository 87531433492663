/* eslint-disable quote-props */
/* global fetch */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

import Dropdown from '../shared/Dropdown';

import Icon from '../shared/Icon';

import { dropdownStyles } from '../../styles/select_styles';

import { subscribe, unsubscribe } from '../../helpers/actioncable_helper';

import { updateAsync } from '../../helpers/rails_helper';
import { searchText } from '../../helpers/helpers';

const noType = {
  title: 'Kein Typ',
  id: null,
};

const filterOption = (option, searchTerm) => {
  return searchText(option.data.title, searchTerm);
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      {props.data.title}
    </components.Option>
  );
};

class ProjectTypeButtonSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      project_type: this.props.project_type || noType,
      dropDownVisible: false,
    };
  }

  componentDidMount() {
    this.typeChangesChannel = subscribe({
      channel: 'ProjectTypeChangesChannel',
      project_id: this.props.project_id,
    }, (data) => {
      this.handleTypeChanged(data.project_type);
    });
  }

  componentWillUnmount() {
    unsubscribe(this.typeChangesChannel);
  }

  getStyle = () => {
    let color = 'var(--bs-gray-600)';
    let textColor = 'white';

    if (this.props.required && !this.state.project_type.id) {
      color = '#ffd43e';
      textColor = '#bb1420';
    }

    return {
      fontWeight: 'bold',
      color: textColor,
      border: `1px solid ${color}`,
      backgroundColor: color,
    };
  };

  loadOptions = () => {
    fetch(this.props.options_url, {
      credentials: 'include',
    }).then((response) => {
      return response.json();
    }).then((options) => {
      if (!this.props.required) {
        this.setState({
          options: [...options.project_types, {
            title: 'Kein Typ',
            id: '',
          }],
        });
      }

      this.setState({ options: options.project_types });
    });
  }

  toggleDropDown = (e) => {
    e.preventDefault();
    this.setState({ dropDownVisible: !this.state.dropDownVisible });
  }

  handleFocus = () => {
    this.loadOptions();
  }

  handleChange = (project_type) => {
    const url = `/projects/${this.props.project_id}/update_type`;
    updateAsync(url, {
      project_type_id: project_type.id,
    }, 'PUT').then(() => {
      this.handleTypeChanged(project_type);
    }).catch((err) => {
      alert(err.base.join(', '));
    });
  }

  handleTypeChanged = (project_type) => {
    this.setState({
      project_type: project_type || noType,
    });
  }

  handleBlur = () => {
    this.setState({ dropDownVisible: false });
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      this.toggleDropDown(e);
    }
  }

  renderStatic() {
    return (
      <div>
        <span className="badge" style={this.getStyle()}>
          {this.state.project_type.id ?
            `Typ: ${this.state.project_type.title}`
            : 'Kein Typ'}
        </span>
      </div>
    );
  }

  renderButton = (ref) => {
    return (
      <button ref={ref} style={this.getStyle()} className="btn btn-light btn-label" onClick={this.toggleDropDown}>
        {this.state.project_type.id ?
          `Typ: ${this.state.project_type.title}`
          : 'Kein Typ'} <Icon name="caret-down" />
      </button>
    );
  }

  renderEditable() {
    return (
      <div style={{ display: 'inline-block' }}>
        <Dropdown
          isOpen={this.state.dropDownVisible}
          onClose={this.toggleDropDown}
          target={this.renderButton}>
          <Select
            autoFocus
            components={{
              Option,
              DropdownIndicator: null,
              IndicatorSeparator: null,
            }}
            onFocus={this.handleFocus}
            openMenuOnFocus
            clearable={false}
            onBlur={this.handleBlur}
            onKeyDown={this.handleKeyDown}
            backspaceRemovesValue={false}
            blurInputOnSelect
            menuPlacement="auto"
            menuIsOpen
            filterOption={filterOption}
            onChange={this.handleChange}
            handleInputChange={this.handleInputChange}
            options={this.state.options}
            defaultOptions
            styles={dropdownStyles}
            placeholder="Typ auswählen"
            loadingPlaceholder="Laden..."
            noOptionsMessage={() => 'Keine Ergebnisse'}
            loadingMessage={() => 'Laden...'}
          />
        </Dropdown>
      </div>
    );
  }

  render() {
    if (this.props.editable) {
      return this.renderEditable();
    }

    return this.renderStatic();
  }
}

ProjectTypeButtonSelect.propTypes = {
  project_id: PropTypes.number.isRequired,
  project_type: PropTypes.object,
  options_url: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  required: PropTypes.bool,
};

export default ProjectTypeButtonSelect;

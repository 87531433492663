import { Component } from 'react';
import PropTypes from 'prop-types';

import Uploader from './Uploader';

class UploaderProvider extends Component {
  constructor(props) {
    super(props);
    this.uploader = new Uploader();
  }

  getChildContext() {
    return { uploader: this.uploader };
  }

  render() {
    return this.props.children;
  }
}

UploaderProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

UploaderProvider.childContextTypes = {
  uploader: PropTypes.object,
};

export default UploaderProvider;

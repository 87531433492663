import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Draggable } from 'react-beautiful-dnd';

import ConfirmModal from '../shared/ConfirmModal';
import Icon from '../shared/Icon';
import Tooltip from '../shared/Tooltip';

import EffectTemplateModal from './EffectTemplateModal';

import { deleteEffect } from '../../helpers/api';

import { getEffectType } from './EffectTypeSquares';

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  marginTop: 5,
  marginBottom: 0,
  // padding: grid,
  // margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  backgroundColor: isDragging ? 'var(--bs-body-bg)' : 'var(--bs-tertiary-bg)',

  // borderRadius: '4px',
  // styles we need to apply on draggables
  ...draggableStyle,
});

class EffectTemplate extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };
  }

  render() {
    return (
      <Draggable
        draggableId={`effect-${this.props.effect.id}`}
        index={this.props.index}
        isDragDisabled={!this.props.editable || this.state.modalOpen}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} className="effect-template"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
            )}>
            <div className="effect-template-title">
              {this.props.effect.type_human}
              {getEffectType(this.props.effect.type).desc ? (
                ` - ${getEffectType(this.props.effect.type).desc(this.props.effect)}`
              ) : null}
            </div>
            <div className="effect-template-buttons">
              <EffectTemplateModal
                onOpen={() => {
                  this.setState({ modalOpen: true });
                  this.props.onModalOpen();
                }}
                onClose={() => {
                  this.setState({ modalOpen: false });
                  this.props.onModalClose();
                }}
                visible={this.props.editable}
                initialEffect={this.props.effect}
                onSaved={this.props.onUpdated}
                action_id={this.props.action_id}
                organizationSlug={this.props.organizationSlug}
                workflow_template_id={this.props.workflow_template_id}
                todo_id={this.props.todo_id}>
                {onClick => (
                  <Tooltip title="Effekt bearbeiten">
                    <button className="btn btn-compact btn-link" onClick={onClick}>
                      <Icon name="pencil" />
                    </button>
                  </Tooltip>
                )}
              </EffectTemplateModal>
              <ConfirmModal
                onOpen={() => {
                  this.setState({ modalOpen: true });
                  this.props.onModalOpen();
                }}
                onClose={() => {
                  this.setState({ modalOpen: false });
                  this.props.onModalClose();
                }}
                visible={this.props.editable}
                target={onClick => (
                  <Tooltip title="Effekt löschen">
                    <button
                      type="button"
                      className="btn btn-compact btn-link"
                      onClick={onClick}>
                      <Icon name="trash" />
                    </button>
                  </Tooltip>
                )}
                title="Aktion löschen"
                cancelText="abbrechen"
                confirmText="löschen"
                confirmStyle="danger"
                onConfirm={() => deleteEffect(this.props.effect)}
                onConfirmed={() => this.props.onDeleted(this.props.effect)}>
                  Bist du sicher, dass du den Effekt löschen willst?
              </ConfirmModal>
            </div>
          </div>
        )}
      </Draggable>
    );
  }
}

EffectTemplate.propTypes = {
  action_id: PropTypes.number.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  todo_id: PropTypes.number.isRequired,
  workflow_template_id: PropTypes.number.isRequired,
  effect: PropTypes.object.isRequired,
  onDeleted: PropTypes.func.isRequired,
  onUpdated: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  onModalOpen: PropTypes.func,
  onModalClose: PropTypes.func,
};

export default EffectTemplate;

/* global document */

import $ from 'jquery';
import Cookies from 'js-cookie';

$(document).on('change', '.projects-display-options', (e) => {
  $('div.projects')
      .removeClass('project-boxes')
      .removeClass('project-list')
      .removeClass('project-table')
      .addClass(`project-${e.target.value}`)

  Cookies.set('projects_display_options', e.target.value, {
    expires: 365,
    path: '/',
    secure: false,
  });
});

const initAvatarResponsible = () => {
  $('span.avatar-responsible').each(function () {
    const due = $(this).data('due')

    if (due && new Date(due) < new Date()) {
      $(this).addClass('avatar-responsible-overdue')
    }
  })
}
document.addEventListener('turbo:load', initAvatarResponsible)
document.addEventListener('turbo:render', initAvatarResponsible)

$(document).on('change', '#delete_contact', () => {
  if ($('#delete_contact').is(':checked')) {
    $('#plan-portal-user').css('display', 'block');
  } else {
    $('#plan-portal-user').css('display', 'none');
  }
});

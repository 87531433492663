/* global fetch */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AsyncSelect from 'react-select/async';
import { components } from 'react-select';

import { defaultStyles } from '../../styles/select_styles';

import { searchText } from '../../helpers/helpers';

const filterOption = (option, searchTerm) => {
  const text = `${option.data.label}`;
  return searchText(text, searchTerm);
};

const Option = props => (
  <components.Option {...props}>
    <span title={props.data.label}>
      {props.data.label}
    </span>
  </components.Option>
);

const SingleValue = props => (
  <components.SingleValue {...props}>
    <span title={props.data.label}>
      {props.data.label}
    </span>
  </components.SingleValue>
);

class TodoTemplateSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: null,
    };
  }

  getOptions = () => {
    return (
      fetch(this.props.optionsUrl, {
        credentials: 'include',
      }).then(response => response.json()).then((options) => {
        this.setState({ value: options.filter(o => (o.value === this.props.value?.id)) });
        return options;
      })
    );
  };

  handleChange = (data) => {
    this.setState({
      value: data,
    });

    this.props.onChange(data);
  };

  render() {
    return (
      <AsyncSelect
        components={{
          Option,
          SingleValue,
        }}
        clearable={false}
        value={this.state.value}
        openMenuOnFocus
        backspaceRemovesValue={false}
        onChange={this.handleChange}
        loadOptions={this.getOptions}
        defaultOptions
        placeholder="Bitte Aufgabe auswählen"
        loadingPlaceholder="Laden..."
        noOptionsMessage={() => 'Keine Ergebnisse'}
        loadingMessage={() => 'Laden...'}
        styles={defaultStyles}
        filterOption={filterOption}
      />
    );
  }
}

TodoTemplateSelector.propTypes = {
  optionsUrl: PropTypes.string.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default TodoTemplateSelector;

import React from 'react';
import PropTypes from 'prop-types';

import ShortMessageTemplateSelector from '../../ShortMessages/ShortMessageTemplateSelector';

class ShortMessageEffectForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleTemplateChange = this.handleTemplateChange.bind(this);
  }

  handleTemplateChange(newTemplate) {
    console.log(newTemplate);
    this.props.onChange(Object.assign({}, this.props.record, {
      short_message_template: newTemplate,
      short_message_template_id: newTemplate && newTemplate.id,
    }));
  }

  render() {
    return (
      <div className="mb-3">
        <label className="form-label">Email Vorlage auswählen</label>
        <ShortMessageTemplateSelector
          optionsUrl={`/${this.props.organizationSlug}/short_message_templates.json`}
          value={this.props.record.short_message_template}
          onChange={this.handleTemplateChange}
          autoFocus={false}
        />
      </div>
    );
  }
}

ShortMessageEffectForm.propTypes = {
  record: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default ShortMessageEffectForm;

import React from 'react';
import PropTypes from 'prop-types';

import NumericInput from '../../shared/NumericInput';

import { autoNumericOptionsEuro } from '../../../config/auto_numeric_options';

const MoneyDataPointInput = ({ onChange, onSave, value, saving, autoFocus }) => {
  const handleChange = (e, v) => {
    onChange(v);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && onSave) {
      onSave();
    }
  };

  return (
    <div className="input-group">
      <NumericInput
        className="form-control"
        onKeyDown={handleKeyDown}
        options={autoNumericOptionsEuro}
        outputFormat="string"
        autoFocus={autoFocus}
        disabled={saving}
        value={value}
        onChange={handleChange}
        placeholder="Bitte eingeben"
      />
      <span className="input-group-text">EUR</span>
    </div>
  );
};

MoneyDataPointInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  saving: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

export default MoneyDataPointInput;

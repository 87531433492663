import React from 'react';

import PropTypes from 'prop-types';

import Select from 'react-select';

import DataPointTemplateSelector from '../../../shared/DataPointTemplateSelector';
import DurationSelector from '../../../shared/DurationSelector';

import BaseCondition from '../BaseCondition';

import { smallStyles } from '../../../../styles/select_styles';

const operatorOptions = [
  { value: 'gt_after', label: 'mehr als' },
  { value: 'lt_after', label: 'weniger als' },
];

class DataPointTimeDiffCondition extends React.Component {
  handleOperatorChange = ({ value }) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      operator: value,
    }));
  };

  handleDataPointTemplateChange = (template) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      integer_operand_1: template.id,
    }));
  };

  handleDataPointTemplate2Change = (template) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      integer_operand_3: template.id,
    }));
  };

  handleDurationChange = (duration) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      integer_operand_2: duration,
    }));
  };

  renderOperand1() {
    return (
      <div style={{ flexBasis: 200, marginLeft: 8 }}>
        <DataPointTemplateSelector
          value={this.props.condition.data_point_template}
          optionsUrl={`/${this.props.organizationSlug}/data_point_template_options.json`}
          onChange={this.handleDataPointTemplateChange}
          isClearable={false}
          allowedTypes={[
            'DataPoint::DateDataPoint',
            'DataPoint::DateTimeDataPoint',
          ]}
          size="small"
        />
      </div>
    );
  }

  renderOperand3() {
    return (
      <div style={{ flexBasis: 200, marginLeft: 8 }}>
        <DataPointTemplateSelector
          value={this.props.condition.data_point_template_2}
          optionsUrl={`/${this.props.organizationSlug}/data_point_template_options.json`}
          onChange={this.handleDataPointTemplate2Change}
          isClearable={false}
          allowedTypes={[
            'DataPoint::DateDataPoint',
            'DataPoint::DateTimeDataPoint',
          ]}
          size="small"
        />
      </div>
    );
  }

  renderOperand2() {
    return (
      <div style={{ flexBasis: 200, marginLeft: 8 }}>
        <DurationSelector
          useGenitivum={false}
          size="small"
          value={this.props.condition.integer_operand_2}
          onChange={this.handleDurationChange}
        />
      </div>
    );
  }

  renderOperator() {
    return (
      <div className="d-flex" style={{ flexBasis: 140, marginLeft: 8 }}>
        <div className="flex-grow-1">
          <Select
            value={operatorOptions.find(o => o.value === this.props.condition.operator)}
            onChange={this.handleOperatorChange}
            placeholder="Bitte auswählen"
            options={operatorOptions}
            styles={smallStyles}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <BaseCondition {...this.props}>
        <div className="flex-grow-1">
          <div className="d-flex">
            {this.renderOperand1()}
            <div className="d-flex align-items-center ms-1 me-1">
              liegt
            </div>
            {this.renderOperator()}
          </div>
          <div className="d-flex mt-1">
            {this.renderOperand2()}
            <div className="d-flex align-items-center ms-1 me-1">
              nach
            </div>
            {this.renderOperand3()}
          </div>
        </div>
      </BaseCondition>
    );
  }
}

DataPointTimeDiffCondition.propTypes = {
  condition: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default DataPointTimeDiffCondition;

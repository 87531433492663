import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CheckTodos from './CheckTodos';
import WorkflowTodos from './WorkflowTodos';
import TaskList from './TaskList';

import Roles from './Roles';

import { getAsync } from '../../helpers/rails_helper';

class WorkflowContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      showLoading: false,
      workflow: null,
      permissions: null,
    };
  }

  componentDidMount() {
    this.loadingTimer = setTimeout(() => {
      this.setState({
        showLoading: true,
      });
    }, 500);

    getAsync(`/workflows/${this.props.workflowId}.json`).then(({ workflow, permissions }) => {
      this.setState({
        workflow, permissions, loading: false, showLoading: false,
      });

      clearTimeout(this.loadingTimer);
    });
  }

  renderComp() {
    let Comp = TaskList;

    if (this.state.workflow.workflow_type === 'workflow') {
      Comp = WorkflowTodos;
    } else if (this.state.workflow.workflow_type === 'checklist') {
      Comp = CheckTodos;
    }

    return (
      <Comp
        {...this.props}
        {...this.state.permissions}
        todos={this.state.workflow.todos}
        roles={this.state.workflow.roles}
      />
    );
  }

  render() {
    if (this.state.loading) {
      return (
        <div
          className="box-loading d-inline-block"
          style={{
            fontSize: 12,
            marginLeft: 20,
            opacity: this.state.showLoading ? 1 : 0,
          }}
        />
      );
    }

    return (
      <div className="row">
        <div className="col-md-8 col-xl-9">
          {this.renderComp()}
        </div>
        <div className="col-md-4 col-xl-3">
          <Roles
            {...this.props}
            {...this.state.permissions}
            todos={this.state.workflow.todos}
            roles={this.state.workflow.roles}
            workflowId={this.state.workflow.id}
          />
        </div>
      </div>
    );
  }
}

WorkflowContainer.propTypes = {
  workflowId: PropTypes.number.isRequired,
};

export default WorkflowContainer;

import Validator from './validator';

const triggerValidator = new Validator({
  rules: [
    {
      field: 'type',
      validator: 'presence',
      message: 'Bitte Typ des Triggers auswählen',
    },
    {
      field: 'string_value_1',
      validator: 'presence',
      message: 'Bitte Ereignis auswählen',
      conditions: [{
        field: 'type', is: 'Triggers::EventTrigger',
      }],
    },
    {
      field: 'integer_value_1',
      validator: 'presence',
      message: 'Bitte Zeitverzögerung eingeben',
      conditions: [{
        field: 'type', is: 'Triggers::TimeTrigger',
      }],
    },
    {
      field: 'integer_value_2',
      validator: 'presence',
      message: 'Bitte Webhook auswählen',
      conditions: [{
        field: 'type', is: 'Triggers::WebhookTrigger',
      }],
    },
  ],
});

export default triggerValidator;

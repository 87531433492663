import React from 'react';
import PropTypes from 'prop-types';

const RoleBadge = ({ todo, style }) => {
  if (!todo.role) return null;

  const badgeStyle = {
    ...style,
    color: todo.role.text_color,
    backgroundColor: todo.role.color,
  };

  const title = `Rolle: ${todo.role.name}`;

  return (
    <span className="todo-badge todo-badge-sm todo-badge-default todo-badge-role d-none d-lg-inline" style={badgeStyle} title={title}>
      {todo.role.name}
    </span>
  );
};

RoleBadge.propTypes = {
  todo: PropTypes.object,
  style: PropTypes.object,
};

RoleBadge.defaultProps = {
  style: {},
};

export default RoleBadge;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EffectTypeSquares, { getEffectType } from './EffectTypeSquares';

import Forms from './EffectTemplateForms/Forms';

class EffectTemplateForm extends Component {
  constructor(props) {
    super(props);

    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.resetType = this.resetType.bind(this);
  }

  handleTypeChange(type) {
    this.props.onChange(Object.assign({}, this.props.record, { type }));
  }

  resetType(e) {
    e.preventDefault();
    this.handleTypeChange('');
  }

  renderForm() {
    const effectType = getEffectType(this.props.record.type);
    const Comp = Forms[effectType.form];

    if (!Comp) return `React-Komponente für ${this.props.record.type} nicht gefunden`;

    return <Comp {...this.props} />;
  }

  renderTriggerHint() {
    if (this.props.allowEffectTypesWithManualInput) return null;

    return (
      <div className="alert alert-warning" role="alert">
        Hinweis: Da für die Aktion automatische Trigger konfiguriert sind,
        kannst du nur Effekte hinzufügen, die keine manuelle Eingabe benötigen.
      </div>
    );
  }

  render() {
    if (!this.props.record.type) {
      return (
        <div>
          <div style={{ flexGrow: 1, marginBottom: 10 }}>
            <EffectTypeSquares
              value={this.props.record.type}
              onChange={this.handleTypeChange}
              allowEffectTypesWithManualInput={this.props.allowEffectTypesWithManualInput}
            />
          </div>
          {this.renderTriggerHint()}
        </div>
      );
    }

    return (
      <div>
        <p>
          <strong>Typ:</strong> {getEffectType(this.props.record.type).title}{' '}
          <a href="#" onClick={this.resetType}>
            ändern
          </a>
        </p>
        {this.renderForm()}
      </div>
    );
  }
}

EffectTemplateForm.propTypes = {
  record: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  organizationSlug: PropTypes.string.isRequired,
  allowEffectTypesWithManualInput: PropTypes.bool,
};

EffectTemplateForm.defaultProps = {
  allowEffectTypesWithManualInput: true,
};

export default EffectTemplateForm;

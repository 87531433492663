import React from 'react';

import PropTypes from 'prop-types';

import OperatorSelector from '../../OperatorSelector';

import DurationSelector from '../../../shared/DurationSelector';

import BaseCondition from '../BaseCondition';

const durationOperators = ['until_ago', 'until_in', 'from_ago', 'from_in'];

class ArchivedCondition extends React.Component {
  handleChangeOperator = ({ value }) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      operator: value,
    }));
  };

  handleDurationChange = (duration) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      integer_operand_2: duration,
    }));
  };

  isDurationOperator = () => {
    return durationOperators.includes(this.props.condition.operator);
  };

  renderOperator() {
    return (
      <div style={{ flexBasis: 200, marginLeft: 8 }}>
        <OperatorSelector
          operators={this.props.condition.filter_condition_type.operators}
          onChange={this.handleChangeOperator}
          value={this.props.condition.operator}
        />
      </div>
    );
  }

  renderOperand2() {
    if (this.isDurationOperator()) {
      return (
        <div style={{ flexBasis: 200, marginLeft: 8 }}>
          <DurationSelector
            size="small"
            value={this.props.condition.integer_operand_2}
            onChange={this.handleDurationChange}
          />
        </div>
      );
    }

    return null;
  }

  render() {
    return (
      <BaseCondition {...this.props}>
        {this.renderOperator()}
        {this.renderOperand2()}
      </BaseCondition>
    );
  }
}

ArchivedCondition.propTypes = {
  condition: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default ArchivedCondition;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { smallStyles, defaultStyles } from '../../styles/select_styles';

import Select, { components } from 'react-select';

import { searchText } from '../../helpers/helpers';

const filterOption = (option, searchTerm) => {
  const text = option.data.fullname ? option.data.fullname : option.data.name;
  return searchText(text, searchTerm);
};

const NoComp = () => {
  return null;
};

const Option = (props) => {
  // User
  if (props.data.fullname) {
    return (
      <components.Option {...props}>
        <span className="fullname_with_avatar">
          <span className="avatar-wrapper avatar-wrapper-mini avatar-link-with-fullname">
            <img src={props.data.avatar.thumb.url} alt={props.data.fullname} className="avatar avatar-mini" />
          </span>
          {props.data.fullname}
        </span>
      </components.Option>
    );
  }

  // Team
  return (
    <components.Option {...props}>
      {props.data.name}
    </components.Option>
  );
};

const SingleValue = (props) => {
  // User
  if (props.data.fullname) {
    return (
      <components.SingleValue {...props}>
        <span className="fullname_with_avatar">
          <span className="avatar-wrapper avatar-wrapper-mini avatar-link-with-fullname">
            <img src={props.data.avatar.thumb.url} alt={props.data.fullname} className="avatar avatar-mini" />
          </span>
          {props.data.fullname}
        </span>
      </components.SingleValue>
    );
  }

  // Rolle
  return (
    <components.SingleValue {...props}>
      {props.data.name}
    </components.SingleValue>
  );
};

class AssigneeSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
    };
  }

  loadOptions() {
    return fetch(this.props.optionsUrl, {
      credentials: 'include',
    }).then((response) => {
      return response.json();
    }).then((options) => {
      const ret = [];

      if (options.teams) {
        ret.push({
          label: 'Teams',
          options: options.teams,
        });
      }

      if (options.users) {
        ret.push({
          label: 'Benutzer',
          options: options.users.filter(u => !this.props.excludeUserIds.includes(u.id)),
        });
      }

      this.setState({ options: ret });
    });
  }

  handleFocus = (e) => {
    this.loadOptions();
  }

  render() {
    return (
      <Select
        components={{
          Option,
          SingleValue,
          DropdownIndicator: this.props.hideDropdownIndicator ? NoComp : components.DropdownIndicator,
          IndicatorSeparator: this.props.hideDropdownIndicator ? NoComp : components.IndicatorSeparator,
        }}
        isDisabled={this.props.isDisabled}
        defaultValue={this.props.value}
        isClearable
        getValue={options => options.responsible_param}
        openMenuOnFocus
        onFocus={this.handleFocus}
        onKeyDown={this.handleKeyDown}
        backspaceRemovesValue={false}
        blurInputOnSelect
        menuPlacement="auto"
        onChange={this.props.onChange}
        handleInputChange={this.handleInputChange}
        filterOption={filterOption}
        options={this.state.options}
        defaultOptions
        styles={this.props.size === 'small' ? smallStyles : defaultStyles}
        placeholder={this.props.placeholder || 'Team oder Benutzer'}
        loadingPlaceholder="Laden..."
        noOptionsMessage={() => 'Keine Ergebnisse'}
        loadingMessage={() => 'Laden...'}
      />
    );
  }
}

AssigneeSelector.propTypes = {
  onChange: PropTypes.func,
  excludeUserIds: PropTypes.array,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  isDisabled: PropTypes.bool,
  size: PropTypes.string,
  hideDropdownIndicator: PropTypes.bool,
  optionsUrl: PropTypes.string.isRequired,
};

AssigneeSelector.defaultProps = {
  excludeUserIds: [],
};

export default AssigneeSelector;

import React from 'react';
import PropTypes from 'prop-types';

const Avatar = (props) => {
  const className = [props.className || ''];
  const wrapperClass = [props.wrapperClass || ''];

  className.push(`avatar avatar-${props.size}`);
  wrapperClass.push('avatar-wrapper');
  wrapperClass.push(`avatar-wrapper avatar-wrapper-${props.size}`);

  let title;

  if (props.account.bio) {
    title = `${props.account.fullname} ${props.account.bio}`;
  } else {
    title = props.account.fullname;
  }

  return (
    <span className={wrapperClass.join(' ')} title={title}>
      <img src={props.account.avatar.thumb.url} className={className.join(' ')} style={props.style} />
    </span>
  );
};

Avatar.propTypes = {
  account: PropTypes.object.isRequired,
  size: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  wrapperClass: PropTypes.string,
};

Avatar.defaultProps = {
  size: 'small',
};

export default Avatar;

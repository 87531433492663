import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '../shared/Modal';

class RenameObjectModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      value: '',
    };
  }

  isValid = () => {
    return !!this.state.value;
  };

  handleChange = (e) => {
    this.setState({
      value: e.target.value.trim(),
    });
  };

  handleSaveObjectClick = (e) => {
    e.preventDefault();

    if (this.isValid()) {
      this.setState({ saving: true });

      this.props.renameObject(this.props.objectId, this.state.value).then((result) => {
        this.props.onClose();

        this.setState({ saving: false, value: '' });
      });
    }
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.handleSaveObjectClick(e);
    }
  };

  handleCancelClick = (e) => {
    e.preventDefault();
    this.props.onClose();

    this.setState({ value: '' });
  };

  handleClick = (e) => {
    e.stopPropagation();
  };

  renderContent() {
    return (
      <div onClick={this.handleClick}>
        <div className="react-modal-header">
          <h4>
            {this.props.title}
          </h4>
        </div>
        <div className="react-modal-content">
          <input
            onKeyDown={this.handleKeyDown}
            defaultValue={this.props.defaultValue}
            onChange={this.handleChange}
            ref={e => (this.input = e)}
            className="form-control"
            placeholder="Name eingeben"
            autoFocus
          />
        </div>
        <div className="react-modal-footer">
          <div />
          <div>
            <button
              disabled={this.state.saving}
              type="button"
              className="btn btn-link"
              onClick={this.handleCancelClick}>
              Abbrechen
            </button>{' '}
            <button disabled={!this.isValid() && !this.state.saving}
              type="button"
              className={`btn btn-primary ${this.state.saving ? 'btn-loading' : ''}`}
              onClick={this.handleSaveObjectClick}>
              Speichern
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Modal visible={this.props.visible} onClose={this.props.onClose}>
        {this.renderContent()}
      </Modal>
    );
  }
}

RenameObjectModal.propTypes = {
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  defaultValue: PropTypes.string,
  objectId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
};

export default RenameObjectModal;

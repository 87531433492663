import React from 'react';
import PropTypes from 'prop-types';

import GoogleAdsConversionSelector from '../../shared/GoogleAdsConversionSelector';

class GoogleAdsConversionEffectForm extends React.Component {
  handleChange = (conversion) => {
    this.props.onChange(Object.assign({}, this.props.record, {
      google_ads_conversion: conversion,
      google_ads_conversion_id: conversion && conversion.id,
    }));
  }


  render() {
    return (
      <div className="mb-3">
        <label className="form-label">Google Ads Conversion</label>
        <div style={{ marginBottom: 6 }}>
          <GoogleAdsConversionSelector
            value={this.props.record.google_ads_conversion}
            onChange={this.handleChange}
            optionsUrl={`/${this.props.organizationSlug}/google_ads_conversions.json`}
          />
        </div>
      </div>
    );
  }
}

GoogleAdsConversionEffectForm.propTypes = {
  record: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default GoogleAdsConversionEffectForm;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import Tooltip from '../shared/Tooltip';

const CheckDropdown = ({ todo, onFire, onReset, saving, completable }) => {
  const classNames = ['btn-checklist'];
  let disabled = false;

  if ((!todo.complete && !todo.completable) || saving || !todo.resettable || !completable) {
    disabled = true;
    classNames.push('disabled');
  }

  const handleResetClick = (e) => {
    e.preventDefault();
    onReset();
  };

  const handleFireClick = (e, action) => {
    e.preventDefault();
    onFire(action);
  };

  const button = todo.fired_action ? (
    <button disabled={disabled} type="button" className={classNames.join(' ')} data-bs-toggle="dropdown" style={{
      backgroundColor: todo.fired_action.color,
      borderColor: todo.fired_action.color,
      boxShadow: 'none',
      color: '#fff',
      opacity: (saving || !completable) ? 0.7 : 1,
    }}>
      <Tooltip title={todo.fired_action.name_fired} placement="right">
        <div className="checklist-icon-container">
          <Icon name={todo.fired_action.icon} />
        </div>
      </Tooltip>
    </button>
  ) : (
    <button disabled={disabled} type="button" className={classNames.join(' ')} data-bs-toggle="dropdown" />
  );

  const options = (todo.actions || []).map((action) => (
    <a key={action.id} href="" onClick={e => handleFireClick(e, action)} className="dropdown-item dropdown-link">
      <Icon name={action.icon} fixedWidth color={action.color} /> {action.name}
    </a>
  ));

  const reset = todo.fired_action? (
    <Fragment>
      <hr className="dropdown-divider" />
      <a href="" onClick={handleResetClick} className="dropdown-item dropdown-link">
        zurücksetzen
      </a>
    </Fragment>
  ) : null;

  return (
    <div className="dropdown" style={{ marginLeft: 11 }}>
      {button}
      <div className="dropdown-menu" role="menu">
        {options}
        {reset}
      </div>
    </div>
  );
};

CheckDropdown.propTypes = {
  todo: PropTypes.object.isRequired,
  onFire: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  resettable: PropTypes.bool,
  completable: PropTypes.bool,
  saving: PropTypes.bool,
};

export default CheckDropdown;

/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { getAsync, updateAsync } from '../../helpers/rails_helper';

import { DragDropContext } from 'react-beautiful-dnd';

import WorkflowTemplateTodos from './WorkflowTemplateTodos';
import WorkflowTemplateTodo from './WorkflowTemplateTodo';
import WorkflowTemplateRoles from './WorkflowTemplateRoles';
import WorkflowTemplateEditorships from './WorkflowTemplateEditorships';

import ConfirmModal from '../shared/ConfirmModal';

import { reorder } from '../../helpers/helpers';

class WorkflowEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      workflow: null,
      loading: true,
      selectedTodoId: null,
      editable: props.editable || false,
    };
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    getAsync(`/workflow_templates/${this.props.workflowId}.json`).then((result) => {
      this.setState({
        workflow: result.workflow_template,
        loading: false,
        workflowType: result.workflow_template.workflow_type,
      });
    });
  };

  convertTo = (type) => {
    return updateAsync(`/workflow_templates/${this.props.workflowId}/convert.json`, {
      convert: type,
    }).then((result) => {
      this.setState({
        workflowType: result.workflow_template.workflow_type,
        workflow: result.workflow_template,
        loading: false,
      });
    });
  };

  handleTodoUpdated = (updatedTodo) => {
    // Todo in der Liste der Todos aktualisieren,
    let todos = this.state.workflow.todos.map(todo => (
      (todo.id === updatedTodo.id) ? updatedTodo : todo
    ));

    // zusätzliche betroffene Todo-Aktivierungen aktualisieren
    // und Folge-Todos aktivieren/deaktivieren
    todos = todos.map(todo => Object.assign({}, todo, {
      actions: todo.actions.map(action => Object.assign({}, action, {
        todo_activations: action.todo_activations.map(t => (
          t.todo_id !== updatedTodo.id ? t : Object.assign({}, t, {
            todo: {
              text: updatedTodo.text,
              id: updatedTodo.id,
            },
          })
        )),
      })),
      active: todo.initially_active || !todos.some(t => (
        t.actions.some(action => (
          action.todo_activations.some(ta => ta.todo_id === todo.id)
        ))
      )),
    }));

    this.handleTodosChange(todos);
  };

  handleTodoSelected = (todo) => {
    this.setState(() => ({ selectedTodoId: todo.id }));
  };

  handleTodosChange = (todos, selectedTodoId) => {
    this.setState((prevState) => ({
      workflow: Object.assign({}, prevState.workflow, { todos }),
      selectedTodoId: selectedTodoId || prevState.selectedTodoId,
    }));
  };

  handleWorkflowEditorshipsChange = (workflow_editorships) => {
    this.setState((prevState) => ({
      workflow: Object.assign({}, prevState.workflow, { workflow_editorships }),
    }));
  };

  updateRolesOfTodos = (todos, roles) => {
    return todos.map((todo) => {
      const newRole = roles.find(r => r.id === todo.role_id);
      const newRoleId = newRole && newRole.id;

      return Object.assign({}, todo, {
        role: newRole,
        role_id: newRoleId,
        todos: this.updateRolesOfTodos(todo.todos, roles),
      });
    });
  };

  handleRolesChange = (roles) => {
    // Todos aktualisieren. Diese können sich durch die Rollen geändert haben
    // Aktualisisert rekursiv die Rollen der SubTodos
    const todos = this.updateRolesOfTodos(this.state.workflow.todos, roles);

    this.setState((prevState) => ({
      workflow: Object.assign({}, prevState.workflow, { roles, todos }),
    }));
  };

  handleConvertToChecklist = () => {
    return this.convertTo('checklist');
  };

  handleConvertToTodolist = () => {
    return this.convertTo('todolist');
  };

  handleConvertToWorkflow = () => {
    return this.convertTo('workflow');
  };

  handleDragEndTodos = (result) => {
    const todos = reorder(
      this.state.workflow.todos,
      result.source.index,
      result.destination.index,
    );

    this.setState({
      savingOrder: true,
      orderSaved: false,
    });

    this.handleTodosChange(todos);

    return updateAsync(`/todos/${result.draggableId}/sort.json`, {
      position: result.destination.index + 1,
    }, 'PATCH').then(() => {
      this.setState({
        savingOrder: false,
        orderSaved: true,
      });
    });
  };

  handleDragEndActions = (result) => {
    const droppableParts = result.source.droppableId.split('-');
    const draggableParts = result.draggableId.split('-');

    // Todo und Action aus Droppable ID ableiten
    const todoId = parseInt(droppableParts[1], 10);
    const actionId = parseInt(draggableParts[1], 10);

    const todo = this.state.workflow.todos.find(t => t.id === todoId);

    // Actions neu sortieren
    const actions = reorder(
      todo.actions,
      result.source.index,
      result.destination.index,
    );

    // Neues Todo mit geänderten Aktionen zusammenbauen
    const newTodos = this.state.workflow.todos.map((t) => {
      return (t.id !== todoId) ? t : Object.assign({}, t, {
        actions,
      });
    });

    this.handleTodosChange(newTodos);

    return updateAsync(`/actions/${actionId}/sort.json`, {
      position: result.destination.index + 1,
    }, 'PATCH').then(() => {
      this.setState({
        savingOrder: false,
        orderSaved: true,
      });
    });
  };

  handleDragEndSubTodos = (result) => {
    const droppableParts = result.source.droppableId.split('-');
    const draggableParts = result.draggableId.split('-');

    const todoId = parseInt(droppableParts[1], 10);
    const subTodoId = parseInt(draggableParts[1], 10);

    const todo = this.state.workflow.todos.find(t => t.id === todoId);

    // Todos neu sortieren
    const todos = reorder(
      todo.todos,
      result.source.index,
      result.destination.index,
    );

    // Neues Todo mit geänderten SubTodos zusammenbauen
    const newTodos = this.state.workflow.todos.map((t) => {
      return (t.id !== todoId) ? t : Object.assign({}, t, {
        todos,
      });
    });

    this.handleTodosChange(newTodos);

    return updateAsync(`/todos/${subTodoId}/sort.json`, {
      position: result.destination.index + 1,
    }, 'PATCH').then(() => {
      this.setState({
        savingOrder: false,
        orderSaved: true,
      });
    });
  };

  handleDragEndEffects = (result) => {
    const droppableParts = result.source.droppableId.split('-');

    // Extract effectId and new new position
    const draggableParts = result.draggableId.split('-');
    const effectId = parseInt(draggableParts[1], 10);
    const newPosition = result.destination.index + 1;

    // Todo und Action aus Droppable ID ableiten
    const todoId = parseInt(droppableParts[1], 10);
    const actionId = parseInt(droppableParts[2], 10);

    const todo = this.state.workflow.todos.find(t => t.id === todoId);
    const action = todo.actions.find(a => a.id === actionId);

    // Sort effects list
    const effects = reorder(
      action.effects,
      result.source.index,
      result.destination.index,
    );

    // Update todos in ui state with new effects list
    const newTodos = this.state.workflow.todos.map((t) => {
      return (t.id !== todoId) ? t : Object.assign({}, t, {
        actions: t.actions.map((a) => {
          return (a.id !== actionId) ? a : Object.assign({}, a, {
            effects,
          });
        }),
      });
    });

    this.handleTodosChange(newTodos);

    // Update effects positions on the server
    return updateAsync(`/effects/${effectId}/sort.json`, {
      position: newPosition,
    }, 'PATCH');
  };

  handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) return false;

    const droppableParts = result.source.droppableId.split('-');

    const parent = droppableParts[0];

    if (parent === 'workflow') {
      return this.handleDragEndTodos(result);
    }

    if (parent === 'todo') {
      return this.handleDragEndActions(result);
    }

    if (parent === 'subtodos') {
      return this.handleDragEndSubTodos(result);
    }

    if (parent === 'action') {
      return this.handleDragEndEffects(result);
    }

    return false;
  };

  renderConvertToChecklist() {
    if (this.state.workflowType === 'checklist') return null;

    return (
      <p>
        <ConfirmModal
          target={onClick => (
            <a href="" onClick={onClick}>In Check-Liste umwandeln</a>
          )}
          title="In Check-Liste umwandeln"
          cancelText="abbrechen"
          confirmText="bestätigen"
          confirmStyle="primary"
          onConfirm={this.handleConvertToChecklist}>
          Bist du sicher, dass du die Vorlage in eine Check-Liste umwandeln willst?
          Dabei gehen unter Umständen bereits konfigurierte Effekte und Trigger verloren.
        </ConfirmModal>
      </p>
    );
  }

  renderConvertToTodolist() {
    if (this.state.workflowType === 'todolist') return null;

    return (
      <p>
        <ConfirmModal
          target={onClick => (
            <a href="" onClick={onClick}>In Todo-Liste umwandeln</a>
          )}
          title="In Check-Liste umwandeln"
          cancelText="abbrechen"
          confirmText="bestätigen"
          confirmStyle="primary"
          onConfirm={this.handleConvertToTodolist}>
          Bist du sicher, dass du die Vorlage in eine Todo-Liste umwandeln willst?
          Dabei gehen unter Umständen bereits konfigurierte Antwortmöglichkeiten sowie Effekte
          und Trigger verloren, da Todo-Listen nur als 'erledigt' markiert werden können.
        </ConfirmModal>
      </p>
    );
  }

  renderConvertToWorkflow() {
    if (this.state.workflowType === 'workflow') return null;
    return (
      <p>
        <ConfirmModal
          target={onClick => (
            <a href="" onClick={onClick}>In Workflow umwandeln</a>
          )}
          title="In Workflow umwandeln"
          cancelText="abbrechen"
          confirmText="bestätigen"
          confirmStyle="primary"
          onConfirm={this.handleConvertToWorkflow}>
          Bist du sicher, dass du die Vorlage in einen Workflow umwandeln willst?
        </ConfirmModal>
      </p>
    );
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="box-loading" style={{ height: 250 }} />
      );
    }

    return (
      <DragDropContext onDragEnd={this.handleDragEnd}>
        <div className="row">
          <div className="col-sm-4 col-lg-3" style={{ borderRight: '1px solid var(--bs-border-color)' }}>
            <WorkflowTemplateRoles
              organizationSlug={this.props.organizationSlug}
              roles={this.state.workflow.roles}
              workflowId={this.props.workflowId}
              onChange={this.handleRolesChange}
              editable={this.state.editable}
            />
            <hr />
            <WorkflowTemplateEditorships
              workflow_editorships={this.state.workflow.workflow_editorships}
              creatorId={this.state.workflow.creator_id}
              editorIds={this.state.workflow.workflow_editorships.map(e => e.user_id)}
              workflowId={this.props.workflowId}
              organizationSlug={this.props.organizationSlug}
              editable={this.state.editable}
              onChange={this.handleWorkflowEditorshipsChange}
            />
            <hr />
            {this.renderConvertToChecklist()}
            {this.renderConvertToTodolist()}
            {this.renderConvertToWorkflow()}
          </div>
          <div className="col-sm-4 col-lg-4">
            <WorkflowTemplateTodos
              organizationSlug={this.props.organizationSlug}
              selectedTodoId={this.state.selectedTodoId}
              workflowType={this.state.workflowType}
              todos={this.state.workflow.todos}
              workflowId={this.props.workflowId}
              onChange={this.handleTodosChange}
              onTodoSelected={this.handleTodoSelected}
              onTodoUpdated={this.handleTodoUpdated}
              editable={this.state.editable}
            />
          </div>
          {this.state.workflow.todos.length ? (
            <div className="col-sm-4 col-lg-5" style={{ borderLeft: '1px solid var(--bs-border-color)' }}>
              <WorkflowTemplateTodo
                organizationSlug={this.props.organizationSlug}
                todo={this.state.workflow.todos.find((t) => t.id === this.state.selectedTodoId)}
                workflowType={this.state.workflowType}
                onTodoUpdated={this.handleTodoUpdated}
                editable={this.state.editable}
              />
            </div>
          ) : null}
        </div>
      </DragDropContext>
    );
  }
}

WorkflowEditor.propTypes = {
  workflowId: PropTypes.number.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  admin: PropTypes.bool,
};

WorkflowEditor.defaultProps = {
  editable: false,
  admin: false,
};

export default WorkflowEditor;

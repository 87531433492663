import React, { Component } from 'react';
import PropTypes from 'prop-types';

import UserSelector from '../../Users/UserSelector';

class UserDataPointInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.value,
      user: props.dataPoint && props.dataPoint.user,
    };
  }

  handleChange = (option) => {
    console.log(option);
    this.props.onChange(option && option.id);
    this.setState({
      id: option && option.id,
      user: option,
    });
  }

  render() {
    const alteredValue = this.props.value && {
      id: this.props.value,
      title: this.state.user.fullname,
    };

    return (
      <UserSelector
        value={this.state.user}
        onChange={this.handleChange}
        optionsUrl={`/${this.props.organizationSlug}/users.json`}
        suggestionContext={`user-data-point-template-id-${this.props.dataPoint.data_point_template_id}`}
      />
    );
  }
}

UserDataPointInput.propTypes = {
  organizationSlug: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  saving: PropTypes.bool,
  value: PropTypes.number,
  dataPoint: PropTypes.object,
};

export default UserDataPointInput;

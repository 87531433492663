import React from 'react';
import PropTypes from 'prop-types';

import AnalyticsHitSelector from '../../shared/AnalyticsHitSelector';

class AnalyticsHitEffectForm extends React.Component {
  handleChange = (hit) => {
    this.props.onChange(Object.assign({}, this.props.record, {
      analytics_hit: hit,
      analytics_hit_id: hit && hit.id,
    }));
  }


  render() {
    return (
      <div className="mb-3">
        <label className="form-label">Analytics-Hit</label>
        <div style={{ marginBottom: 6 }}>
          <AnalyticsHitSelector
            value={this.props.record.analytics_hit}
            onChange={this.handleChange}
            optionsUrl={`/${this.props.organizationSlug}/analytics_hits.json`}
          />
        </div>
      </div>
    );
  }
}

AnalyticsHitEffectForm.propTypes = {
  record: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default AnalyticsHitEffectForm;

import React from 'react';
import PropTypes from 'prop-types';
import TextArea from '../../shared/TextArea';

const TextDataPointInput = ({ onChange, onSave, value, saving, autoFocus }) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  const handleKeyDown = (e) => {
    // cmd + enter
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      onSave();
    }
  };

  return (
    <TextArea
      className="form-control"
      autoFocus={autoFocus}
      onKeyDown={handleKeyDown}
      disabled={saving}
      value={value}
      onChange={handleChange}
      autoSize
    />
  );
};

TextDataPointInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
};

export default TextDataPointInput;

import React from 'react';
import PropTypes from 'prop-types';

import TodoTemplateForm from './TodoTemplateForm';
import RemoteFormModal from '../shared/RemoteFormModal';

import todoTemplateValidator from '../../validators/todo_template_validator';
import { saveTodo } from '../../helpers/api';

const TodoTemplateModal = props => (
  <RemoteFormModal
    {...props}
    titleNew="Neue Aufgabe"
    titleEdit="Aufgabe bearbeiten"
    validator={todoTemplateValidator}
    form={TodoTemplateForm}
    initialRecord={props.initialTodo}
    saveHandler={saveTodo}
  />
);

TodoTemplateModal.propTypes = {
  initialTodo: PropTypes.object.isRequired,
};

export default TodoTemplateModal;

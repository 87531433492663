import React from 'react';
import PropTypes from 'prop-types';

import ComposeAttachment from './ComposeAttachment';
import AttachmentPreviewer from '../Attachments/AttachmentPreviewer';

const ComposeAttachments = ({ attachments, onRemove, style, className }) => {
  if (!attachments.length) return null;

  return (
    <AttachmentPreviewer attachments={attachments}>
      {openPreview => (
        <div style={style} className={className}>
          {attachments.map((attachment) => {
            return (
              <ComposeAttachment
                document={attachment.document}
                key={`attachment-${attachment.document_id}`}
                onDeleteClick={() => onRemove(attachment)}
                onPreviewClick={() => openPreview(attachment)}
              />
            );
          })}
        </div>
      )}
    </AttachmentPreviewer>
  );
};

ComposeAttachments.propTypes = {
  attachments: PropTypes.array.isRequired,
  onRemove: PropTypes.func.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
};

ComposeAttachments.defaultProps = {
  style: {},
};

export default ComposeAttachments;

/* global fetch */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AsyncSelect from 'react-select/async';

import { components } from 'react-select';

import { smallStyles, defaultStyles } from '../../styles/select_styles';

const Option = (props) => (
  <components.Option {...props}>
    <span title={props.data.name}>
      {props.data.name}
    </span>
  </components.Option>
);

const SingleValue = (props) => (
  <components.SingleValue {...props}>
    <span title={props.data.name}>
      {props.data.name}
    </span>
  </components.SingleValue>
);

class CatalogSelector extends Component {
  getOptions = () => {
    return fetch(this.props.optionsUrl, {
      credentials: 'include',
    }).then((response) => {
      return response.json();
    }).then((options) => {
      return options.catalogs;
    });
  }

  handleBlur = () => {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  render() {
    return (
      <AsyncSelect
        components={{
          Option,
          SingleValue,
        }}
        defaultValue={this.props.value}
        openMenuOnFocus
        onBlur={this.handleBlur}
        onKeyDown={this.handleKeyDown}
        backspaceRemovesValue={false}
        blurInputOnSelect
        menuPlacement="auto"
        onChange={this.props.onChange}
        handleInputChange={this.handleInputChange}
        loadOptions={this.getOptions}
        defaultOptions
        styles={this.props.size === 'small' ? smallStyles : defaultStyles}
        placeholder={this.props.placeholder}
        loadingPlaceholder="Laden..."
        noOptionsMessage={() => 'Keine Ergebnisse'}
        loadingMessage={() => 'Laden...'}
      />
    );
  }
}

CatalogSelector.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  size: PropTypes.string,
  optionsUrl: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
};

CatalogSelector.defaultProps = {
  placeholder: 'Katalog auswählen',
};

export default CatalogSelector;

/* eslint-disable no-multi-spaces */
import React from 'react';
import PropTypes from 'prop-types';

import { defaultStyles, smallStyles } from '../../styles/select_styles';

import { getAsync } from '../../helpers/rails_helper';

import Select from 'react-select';

const filterOption = (option, inputValue) => {
  if (!inputValue) return true;
  return inputValue.replace(/ +(?= )/g, '').split(' ').every(term => (
    option.label.toLowerCase().includes(term.toLowerCase())
  ));
};

class OfferDateSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      optionsLoaded: false,
    };
  }

  loadOptions = () => {
    getAsync(this.props.optionsUrl).then((result) => {
      this.setState({
        options: result.offer_date_options.map(option => ({
          value: option.attribute,
          label: option.title,
          type: option.type,
          data: option,
        })),
        optionsLoaded: true,
      });
    });
  }

  handleChange = (option) => {
    this.props.onChange(option && option.data);
  }

  handleFocus = () => {
    if (!this.state.optionsLoaded) {
      this.loadOptions();
    }
  }

  render() {
    const value = this.props.value && {
      value: this.props.value.attribute,
      label: this.props.value.title,
    };

    return (
      <Select
        isClearable
        openMenuOnFocus
        value={value}
        onChange={this.handleChange}
        options={this.state.options}
        onFocus={this.handleFocus}
        filterOption={filterOption}
        placeholder="Bitte auswählen"
        loadingPlaceholder="Laden..."
        noOptionsMessage={() => 'Keine Ergebnisse'}
        onBlur={this.handleBlur}
        onKeyDown={this.handleKeyDown}
        backspaceRemovesValue={false}
        blurInputOnSelect
        menuPlacement="auto"
        styles={this.props.size === 'small' ? smallStyles : defaultStyles}
        onInputChange={this.handleInputChange}
        defaultOptions
      />
    );
  }
}

OfferDateSelector.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  optionsUrl: PropTypes.string.isRequired,
  size: PropTypes.string,
};

export default OfferDateSelector;

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['editShow', 'editHide'];
  edit = false;

  toggleEdit() {
    this.edit = !this.edit;

    if (this.edit) {
      this.editShowTargets.forEach(target => target.classList.remove('d-none'));
      this.editHideTargets.forEach(target => target.classList.add('d-none'));
    } else {
      this.editShowTargets.forEach(target => target.classList.add('d-none'));
      this.editHideTargets.forEach(target => target.classList.remove('d-none'));
    }
  }
}

/* global document */
import Trix from 'trix';

require('@rails/actiontext');

// add underline attribute to trix editor
Trix.config.textAttributes.underline = {
  style: { textDecoration: 'underline' },
  parser: (element) => {
    return element.style.textDecoration === 'underline';
  },
  inheritable: 1,
};

document.addEventListener('trix-initialize', (event) => {
  // Adds an additional save button to top right of the trix toolbar
  if (event.target.classList.contains('trix-save-button')) {
    const buttonHTML = '<button type="submit" class="btn btn-success btn-sm btn-compact" style="height: 25px; margin-left: 8px" data-disable-with="Wird gespeichert...">Speichern</button>'
    event.target.toolbarElement
      .querySelector('.trix-button-group--history-tools')
      .insertAdjacentHTML('afterend', buttonHTML);
  }

  if (event.target.classList.contains('trix-disable-file-tools')) {
    event.target.toolbarElement.querySelector('.trix-button-group--file-tools').remove();

    // Disable file attachments via drag and drop
    event.target.addEventListener("trix-file-accept", function(event) {
      event.preventDefault()
      event.target.classList.contains('trix-disable-file-tools-no-warning') || alert("Dateianhänge sind nicht erlaubt.")
    });
  }

  if (event.target.classList.contains('trix-disable-code')) {
    event.target.toolbarElement.querySelector('.trix-button--icon-code').remove();
  }

  if (event.target.classList.contains('trix-disable-quote')) {
    event.target.toolbarElement.querySelector('.trix-button--icon-quote').remove();
  }

  if (event.target.classList.contains('trix-disable-heading-1')) {
    event.target.toolbarElement.querySelector('.trix-button--icon-heading-1').remove();
  }

  if (event.target.classList.contains('trix-disable-history')) {
    event.target.toolbarElement.querySelector('.trix-button-group--history-tools').remove();
  }

  // allow editor to be disabled
  const isDisabled = event.target.hasAttribute('disabled') && event.target.getAttribute('disabled') === 'disabled'

  if (isDisabled) {
    event.target.editor.element.contentEditable = false;
  }

  // Add underline button to trix toolbar
  const buttonHTML = '<button type="button" data-trix-attribute="underline" class="trix-button trix-button--icon trix-button--icon-underline title="Unterstrichen" tabindex="-1">Underline</button>';
  event.target.toolbarElement
    .querySelector('.trix-button--icon-italic')
    .insertAdjacentHTML('afterend', buttonHTML);
});

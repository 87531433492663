import { get } from 'lodash';

// Prüft, ob ein Satz von Bedingungen (conditions)
// durch das übergebene Objekt erfüllt ist.
export const conditionsMet = (rule, object) => {
  return (rule.conditions || []).every((condition) => {
    if (condition.is !== undefined && get(object, condition.field) !== condition.is) {
      return false;
    }

    return true;
  });
};

const validators = {
  presence(object, rule) {
    const value = get(object, rule.field);

    if (!value || !value.toString().trim()) {
      return {
        field: rule.field,
        error: 'presence',
        message: rule.message || 'Bitte ausfüllen',
      };
    }
    return null;
  },
};

class Validator {
  constructor(options) {
    this.rules = options.rules;
  }

  validate(object) {
    const errors = [];

    this.rules.forEach((rule) => {
      if (conditionsMet(rule, object)) {
        errors.push(validators[rule.validator](object, rule));
      }
    });

    // Leere Array Elemente entfernen
    return errors.filter(e => e);
  }
}

export default Validator;

/* eslint-disable no-multi-spaces */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { getAsync } from '../../helpers/rails_helper';
import { defaultStyles, smallStyles } from '../../styles/select_styles';

function ModelSelect(props) {
  const [options, setOptions] = useState([]);

  const loadOptions = () => {
    if (!props.optionsUrl) return;
    getAsync(props.optionsUrl).then((result) => {
      setOptions(result[props.model]);
    });
  };

  useEffect(() => {
    loadOptions();
  }, [props.optionsUrl]);

  const handleChange = (v) => {
    if (props.onChange) props.onChange(v);
  };

  return (
    <Select
      name={props.name}
      isClearable={props.isClearable}
      openMenuOnFocus
      value={props.value}
      isDisabled={props.disabled}
      onChange={handleChange}
      options={options}
      placeholder={props.placeholder}
      loadingPlaceholder="Laden..."
      noOptionsMessage={() => 'Keine Ergebnisse'}
      backspaceRemovesValue={false}
      blurInputOnSelect
      menuPlacement="auto"
      styles={props.size === 'small' ? smallStyles : defaultStyles}
      defaultOptions
    />
  );
}

ModelSelect.defaultProps = {
  optionsUrl: null,
  isClearable: false,
  placeholder: 'Bitte auswählen',
  disabled: false,
  value: null,
  initialValue: '',
  size: 'default',
};

ModelSelect.propTypes = {
  model: PropTypes.string.isRequired,
  optionsUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.string,
};

export default ModelSelect;

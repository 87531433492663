import React from 'react';
import PropTypes from 'prop-types';

import NumericInput from '../../shared/NumericInput';
import { autoNumericOptionsFloat } from '../../../config/auto_numeric_options';

const FloatDataPointInput = ({ onChange, onSave, value, saving, autoFocus }) => {
  const handleChange = (e, v) => {
    onChange(v);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && onSave) {
      onSave();
    }
  };

  return (
    <NumericInput
      className="form-control"
      onKeyDown={handleKeyDown}
      options={autoNumericOptionsFloat}
      autoFocus={autoFocus}
      disabled={saving}
      value={value}
      onChange={handleChange}
    />
  );
};

FloatDataPointInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  autoFocus: PropTypes.bool,
};

export default FloatDataPointInput;

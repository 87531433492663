import React from 'react';
import PropTypes from 'prop-types';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import DataviewSectionModal from './DataviewSectionModal';
import DataviewSection from './DataviewSection';

import Icon from '../shared/Icon';

import { reorder } from '../../helpers/helpers';
import { flashNotice } from '../../helpers/flash_helper';

import { updateAsync } from '../../helpers/rails_helper';

const DataviewSections = (props) => {
  if (!props.dataview) return null;

  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) return false;

    const sections = reorder(
      props.dataview.dataview_sections,
      result.source.index,
      result.destination.index,
    );

    const newDataview = Object.assign({}, props.dataview, {
      dataview_sections: sections,
    });

    props.onChange(newDataview);

    return updateAsync(`/dataview_sections/${result.draggableId}/sort.json`, {
      position: result.destination.index + 1,
    }, 'PATCH').then(() => {
      flashNotice('Reihenfolge aktualisiert.');
    });
  };

  const handleSectionSelected = (e, section) => {
    e.preventDefault();

    props.onSelect(section);
  };

  const handleSectionAdded = (data) => {
    props.onAdded(data.dataview_section);
  };

  const handleSectionDeleted = (section) => {
    const newDataview = Object.assign({}, props.dataview, {
      dataview_sections: props.dataview.dataview_sections.filter(s => s.id !== section.id),
    });

    props.onChange(newDataview);
  };

  const handleSectionUpdated = (data) => {
    const newDataview = Object.assign({}, props.dataview, {
      dataview_sections: props.dataview.dataview_sections.map((s) => {
        return (s.id === data.dataview_section.id) ? data.dataview_section : s;
      }),
    });

    props.onChange(newDataview);
  };

  const renderSections = () => {
    return props.dataview.dataview_sections.map((section, i) => (
      <Draggable key={section.id} draggableId={section.id.toString()} index={i}>
        {(provided, snapshot) => (
          <DataviewSection
            provided={provided}
            snapshot={snapshot}
            section={section}
            dataview={props.dataview}
            onSectionDeleted={handleSectionDeleted}
            onSectionUpdated={handleSectionUpdated}
            onSectionSelected={handleSectionSelected}
            isSelected={props.selectedSection?.id === section.id}
          />
        )}
      </Draggable>
    ));
  };

  return (
    <div>
      <h4>Abschnitte</h4>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {renderSections()}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <DataviewSectionModal
        visible
        title="Neuer Abschnitt"
        onSaved={handleSectionAdded}
        initialSection={{
          title: '',
          dataview_id: props.dataview.id,
        }}>
        {onClick => (
          <button className="btn btn-link" onClick={onClick}>
            <Icon name="plus" fixedWidth />
            Abschnitt hinzufügen
          </button>
        )}
      </DataviewSectionModal>

    </div>
  );
};

DataviewSections.propTypes = {
  dataview: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onAdded: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedSection: PropTypes.object,
};

export default DataviewSections;

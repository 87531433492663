/* global document, google */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { getPreferredTheme } from '../../initializers/theme_switcher';

const darkStyles = [
  { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
  { elementType: 'labels.text.stroke', stylers: [{ color: '#2e353e' }] },
  { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#24393b' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#6b9a76' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#3f454e' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#282d35' }],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9ca5b3' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ color: '#746855' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#272d35' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#f3d19c' }],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [{ color: '#383f49' }],
  },
  {
    featureType: 'transit.station',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#232c3a' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#515c6d' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#242d3b' }],
  },
];

const BaseMap = (props) => {
  const [map, setMap] = useState(null);

  const theme = getPreferredTheme();

  const updateBounds = () => {
    if (map) {
      const bounds = new google.maps.LatLngBounds();

      props.boundsPoints.forEach((point) => {
        bounds.extend(new google.maps.LatLng(point[0], point[1]));
      });

      if (!bounds.isEmpty()) {
        map.fitBounds(bounds);
      }
    }
  };

  const initMap = () => {
    const newMap = new google.maps.Map(document.getElementById('map'), {
      center: { lat: 51, lng: 10.2 },
      zoom: 6,
      mapTypeControl: true,
      scaleControl: false,
      streetViewControl: false,
      overviewMapControl: false,
      styles: theme === 'dark' ? darkStyles : null,
    });

    setMap(newMap);
  };

  useEffect(() => {
    if (typeof google !== 'undefined') {
      initMap();
    } else {
      document.addEventListener('google_maps_api_loaded', () => {
        initMap();
      }, false);
    }
  }, []);

  useEffect(() => {
    updateBounds();
  }, [map, props.boundsPoints]);

  return (
    <React.Fragment>
      {props.children(map)}
      <div id="map" style={props.containerStyle} />
    </React.Fragment>
  );
};

BaseMap.propTypes = {
  containerStyle: PropTypes.object.isRequired,
  children: PropTypes.func,
  boundsPoints: PropTypes.array,
};

BaseMap.defaultProps = {
  boundsPoints: [],
  containerStyle: {
    height: 600,
  },
};

export default BaseMap;

/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import TagSelectorAsync from './TagSelectorAsync';
import TagGroupSelect from './TagGroupSelect';

import Tag from './Tag';

import { subscribe, unsubscribe } from '../../helpers/actioncable_helper';

import { updateAsync } from '../../helpers/rails_helper.js';

class ProjectTags extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: props.tags,
      tag_groups: props.tag_groups,
      editable: props.editable,
    };
  }

  componentDidMount() {
    this.changesChannel = subscribe({
      channel: 'ProjectTagChangesChannel',
      project_id: this.props.project_id,
    }, ({ tag, action }) => {
      if (!this.silenceSubscriptions) {
        if (action === 'new') {
          this.handleTagAdded(tag);
        } else if (action === 'delete') {
          this.handleTagRemoved(tag);
        }
      }
    });

    this.typeChangesChannel = subscribe({
      channel: 'ProjectTypeChangesChannel',
      project_id: this.props.project_id,
    }, (data) => {
      this.handleTypeChanged(data.project_type);
    });
  }

  componentWillUnmount() {
    unsubscribe(this.changesChannel);
    unsubscribe(this.typeChangesChannel);
  }

  handleTypeChanged = (project_type) => {
    this.setState({
      tag_groups: (project_type ? project_type.tag_groups : []),
    });
  }

  handleRemove = (tag) => {
    const url = `/projects/${this.props.project_id}/tags/remove`;
    updateAsync(url, {
      tag_id: tag.id,
    }, 'PATCH').then(() => {
      this.handleTagRemoved(tag);
    });
  }

  handleAdd = (tag) => {
    const url = `/projects/${this.props.project_id}/tags/add`;
    updateAsync(url, {
      tag_id: tag.id,
    }, 'PATCH').then(() => {
      this.handleTagAdded(tag);
    });
  }

  handleReplace = (oldTag, newTag) => {
    this.silenceSubscriptions = true;

    const url = `/projects/${this.props.project_id}/tags/replace`;

    // optimistically update UI
    this.handleTagReplaced(oldTag, newTag);

    updateAsync(url, {
      old_tag_id: oldTag.id,
      new_tag_id: newTag.id,
    }, 'PATCH').then(() => {
      this.handleTagReplaced(oldTag, newTag);
      this.silenceSubscriptions = false;
    }).catch(() => {
      // in case of error, revert to old state
      this.handleTagReplaced(newTag, oldTag);
    });
  }

  handleTagRemoved = (tag) => {
    this.setState({
      tags: this.state.tags.filter(t => (t.id !== tag.id)),
    });
  }

  handleTagAdded = (tag) => {
    if (!this.state.tags.some(t => t.id === tag.id)) {
      this.setState({
        tags: [...this.state.tags, tag].sort((a, b) => a.position - b.position),
      });
    }
  }

  handleTagReplaced = (oldTag, newTag) => {
    const index = this.state.tags.findIndex(t => (t.id === oldTag.id));

    if (index >= 0) {
      const newTags = [...this.state.tags];
      newTags[index] = newTag;

      this.setState({
        tags: newTags,
      });
    }
  }

  renderTags() {
    return this.state.tags.map(tag => (
      <Tag
        key={tag.id}
        tag={tag}
        editable={this.state.editable}
        onRemove={this.handleRemove}
        optionsUrl={`/projects/${this.props.project_id}/tags/options.json`}
        onReplace={this.handleReplace}
      />
    ));
  }

  renderSeperator = () => {
    if (this.state.editable && this.state.tags.length) {
      return (
        <span className="seperator">|</span>
      );
    }
    return null;
  }

  renderMissingTagGroups = () => {
    if (!this.state.editable) return null;

    const missingTagGroups = this.state.tag_groups.filter((g) => {
      return !this.state.tags.some(t => (t.tag_group_id === g.id));
    });

    return missingTagGroups.map(group => (
      <TagGroupSelect
        group={group}
        key={group.id}
        onSelect={this.handleAdd}
        optionsUrl={`/projects/${this.props.project_id}/tags/options.json`}
      />
    ));
  }

  renderSelector() {
    if (this.state.editable) {
      return (
        <TagSelectorAsync
          buttonText={this.state.tags.length ? 'Tag' : 'Tag hinzufügen'}
          onAdd={this.handleAdd}
          optionsUrl={`/projects/${this.props.project_id}/tags/options.json`}
          excludedTagIds={this.state.tags.map(t => t.id)} />
      );
    }

    return null;
  }

  render() {
    return (
      <Fragment>
        {this.renderTags()}
        {this.renderMissingTagGroups()}
        {this.renderSeperator()}
        {this.renderSelector()}
      </Fragment>
    );
  }
}

ProjectTags.propTypes = {
  project_id: PropTypes.number.isRequired,
  tags: PropTypes.array.isRequired,
  tag_groups: PropTypes.array.isRequired,
  editable: PropTypes.bool,
};

export default ProjectTags;

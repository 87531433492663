import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';

import { smallStyles } from '../../styles/select_styles';

const OperatorSelector = ({ operators, value, onChange }) => {
  if (!operators || !operators.length) return null;

  const options = operators.map((o) => {
    return {
      value: o.key,
      label: o.title,
    };
  });

  const currentValue = options.find(o => o.value === value) || '';

  return (
    <Select
      options={options}
      styles={smallStyles}
      value={currentValue}
      onChange={onChange}
      placeholder="Bitte auswählen"
    />
  );
};

OperatorSelector.propTypes = {
  value: PropTypes.string,
  operators: PropTypes.array,
  onChange: PropTypes.func,
};

export default OperatorSelector;

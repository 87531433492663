import React from 'react';
import PropTypes from 'prop-types';

import HiddenInputWrapper from '../shared/HiddenInputWrapper';
import DataviewSelector from './DataviewSelector';

const transformValue = (rawValue) => {
  return (rawValue ? rawValue.id : '');
};

const DataviewSelect = (props) => {
  const { value, name, ...rest } = props;

  return (
    <HiddenInputWrapper
      {...rest}
      comp={DataviewSelector}
      name={name}
      value={value}
      transformValue={transformValue}
    />
  );
};

DataviewSelect.propTypes = {
  value: PropTypes.number,
  name: PropTypes.string,
};

export default DataviewSelect;

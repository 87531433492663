import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../shared/Icon';
import TextArea from '../../shared/TextArea';

class AddProjectNoteEffectForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      note_name: this.props.effect.default_name,
      note_body: '',
    };
  }

  handleChange = () => {
    this.props.onChange({
      note_name: this.state.note_name,
      note_body: this.state.note_body,
    });
  }

  handleNameChange = (e) => {
    this.setState({
      note_name: e.target.value,
    }, this.handleChange);
  }

  handleBodyChange = (e) => {
    this.setState({
      note_body: e.target.value,
    }, this.handleChange);
  }

  render() {
    return (
      <div className="fire-effect-item">
        <div className="fire-effect-icon">
          <Icon name="comment" />
        </div>
        <div className="fire-effect-content">
          <div className="fire-effect-title">
            Notiz hinzufügen
          </div>
          <div className="fire-effect-inputs">
            <div className="compact-input-group">
              <input
                className="form-control"
                onChange={this.handleNameChange}
                value={this.state.note_name}
                placeholder="Name der Notiz"
              />
              <TextArea
                autoSize
                rows={2}
                placeholder="Bitte ausfüllen"
                className="form-control"
                onChange={this.handleBodyChange}
                value={this.state.note_body}
              />
            </div>
            {this.props.effect.optional ? (
              <small className="text-muted">
                optional
              </small>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

AddProjectNoteEffectForm.propTypes = {
  effect: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AddProjectNoteEffectForm;

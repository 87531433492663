/* eslint-disable no-multi-spaces */
import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';

const workflowTemplateTitle = (workflow_template) => {
  if (workflow_template.version) {
    return `${workflow_template.title} v${workflow_template.version}`;
  }
  return workflow_template.title;
};

const effectTypes = [
  { code: 'Effects::SetDataPointEffect',             title: 'Datenpunkt setzen',              icon: 'database',                       needsManualInput: true,     desc(e) { return e.title; } },
  { code: 'Effects::SetAutomaticDataPointEffect',    title: 'Datenpunkt automatisch setzen',  icon: 'database',                       needsManualInput: false,    desc(e) { return e.title; } },
  { code: 'Effects::DeleteDataPointEffect',          title: 'Datenpunkt leeren',              icon: 'trash',                          needsManualInput: false,    desc(e) { return e.title; } },
  { code: 'Effects::AddTagEffect',                   title: 'Tag hinzufügen',                 icon: 'tag',                            needsManualInput: false,    desc(e) { return e.tag.title; } },
  { code: 'Effects::RemoveTagEffect',                title: 'Tag entfernen',                  icon: 'tag',                            needsManualInput: false,    desc(e) { return e.tag.title; } },
  { code: 'Effects::AddProjectNoteEffect',           title: 'Notiz hinzufügen',               icon: 'pencil',                         needsManualInput: true,     desc(e) { return e.default_name; } },
  { code: 'Effects::DelayEffect',                    title: 'Aufgabe vertagen',               icon: 'clock',                          needsManualInput: true },
  { code: 'Effects::SetDueEffect',                   title: 'Fälligkeit der Folgeaufgaben',   icon: 'alarm-clock',                    needsManualInput: true },
  { code: 'Effects::SetRoleEffect',                  title: 'Rolle setzen',                   icon: 'user',                           needsManualInput: true,     desc(e) { return e.role_template.name; } },
  { code: 'Effects::AddCommentEffect',               title: 'Kommentar hinzufügen',           icon: 'comment',                        needsManualInput: true,     desc(e) { return e.title; }  },
  { code: 'Effects::AddWorkflowEffect',              title: 'Workflow hinzufügen',            icon: 'tasks',                          needsManualInput: false,    desc(e) { return workflowTemplateTitle(e.workflow_template); } },
  { code: 'Effects::AssignWorkflowEffect',           title: 'Workflow zuweisen',              icon: 'project-diagram',                needsManualInput: false,    desc(e) { return e.assignment.name; } },
  { code: 'Effects::AnalyticsHitEffect',             title: 'Analytics Event senden',         icon: 'chart-pie',                      needsManualInput: false,    desc(e) { return e.analytics_hit && e.analytics_hit.title; } },
  { code: 'Effects::GoogleAdsConversionEffect',      title: 'Google Ads Conv. senden',        icon: 'cart-plus',                      needsManualInput: false,    desc(e) { return e.google_ads_conversion && e.google_ads_conversion.title; } },
  { code: 'Effects::AffiliateSaleEffect',            title: 'Affiliate Sale senden',          icon: 'hand-holding-dollar',            needsManualInput: false,    desc(e) { return e.affiliate_sale && e.affiliate_sale.title; } },
  { code: 'Effects::ArchiveProjectEffect',           title: 'Projekt archivieren',            icon: 'archive',                        needsManualInput: false },
  { code: 'Effects::EmailEffect',                    title: 'Email versenden',                icon: 'envelope',                       needsManualInput: false,    desc(e) { return e.email_template.title; } },
  { code: 'Effects::AssignEmailEffect',              title: 'Email zuteilen',                 icon: 'envelope-circle-check',          needsManualInput: false,    desc(e) { return e.email_template_assignment.name; } },
  { code: 'Effects::ShortMessageEffect',             title: 'SMS versenden',                  icon: 'mobile',                         needsManualInput: false,    desc(e) { return e.short_message_template.title; } },
  { code: 'Effects::RenameWorkflowEffect',           title: 'Workflow umbennenen',            icon: 'text',                           needsManualInput: false,    desc(e) { return e.title; } },
  { code: 'Effects::StartVoiceAgentEffect',          title: 'Telefonagent starten',           icon: 'waveform-lines',                   needsManualInput: false,    desc(e) { return e.title; } },
  { code: 'Effects::StopAllVoiceAgentsEffect',       title: 'Telefonagenten stoppen',         icon: 'phone-slash',                    needsManualInput: false },

].map(type => ({
  ...type,
  form: `${type.code.split('::')[1]}Form`,
}));

const effectTypesWithManualInput = effectTypes.filter(e => e.needsManualInput);

const getEffectType = (code) => {
  return effectTypes.find(e => e.code === code);
};

const EffectTypeSquares = ({ onChange, allowEffectTypesWithManualInput }) => {
  const handleSelect = (e, effectType) => {
    e.preventDefault();
    if (onChange) {
      onChange(effectType.code);
    }
  };

  let activeEffectTypes;

  if (allowEffectTypesWithManualInput) {
    activeEffectTypes = effectTypes;
  } else {
    activeEffectTypes = effectTypes.filter(e => !e.needsManualInput);
  }

  return activeEffectTypes.map((effectType) => {
    return (
      <button
        type="button"
        key={effectType.code}
        className="effect-type-square"
        onClick={e => handleSelect(e, effectType)}>
        <Icon name={effectType.icon} className="effect-type-square-icon" />
        <span className="effect-type-square-title">
          {effectType.title}
        </span>
      </button>
    );
  });
};

EffectTypeSquares.propTypes = {
  onChange: PropTypes.func,
  allowEffectTypesWithManualInput: PropTypes.bool.isRequired,
};

export default EffectTypeSquares;

export {
  effectTypes,
  effectTypesWithManualInput,
  getEffectType,
};

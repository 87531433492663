import React, { Component } from 'react';
import PropTypes from 'prop-types';

const genders = [
  { name: 'male', salutation: 'Herr' },
  { name: 'female', salutation: 'Frau' },
  { name: 'non-binary', salutation: 'Divers' },
]

class ContactNameForm extends Component {
  contactFieldName(attribute, fieldAddition = this.props.fieldAddition) {
    return `${this.props.objectName}[${this.fieldName(attribute, fieldAddition)}]`
  }

  fieldName(attribute, fieldAddition = this.props.fieldAddition) {
    return `${attribute}${fieldAddition}`
  }

  contactAttribute(attribute, contact = this.props.contact, fieldAddition = this.props.fieldAddition) {
    return contact[this.fieldName(attribute, fieldAddition)] || ''
  }

  render() {
    const genderCheckboxes = genders.map((gender, key) => {
      const id = `gender_${gender.name}_${this.props.fieldAddition}`
      return (
        <div className="form-check form-check-inline" key={key}>
          <input
            type="radio"
            name={this.contactFieldName('gender')}
            id={id}
            value={gender.name}
            checked={this.contactAttribute('gender') === gender.name}
            onChange={(e) => this.props.onChange(this.fieldName('gender'), e)}
            className="form-check-input"
          />
          <label className="form-check-label" htmlFor={id}>{gender.salutation}</label>
        </div>
      )
    });

    return (
      <div style={{ paddingRight: this.props.wide ? 15 : -15 }}>
        {this.props.title ? (<h6 className="text-muted">{this.props.title}:</h6>) : ''}
        <div className="mb-3 gutter-xs">
          {genderCheckboxes}
        </div>

        <div className="row mb-3 gutter-xs">
          <div className="compact-input-group">
            <input
              type="text"
              name={this.contactFieldName('name_prefix')}
              className="form-control"
              placeholder="Titel"
              value={this.contactAttribute('name_prefix')}
              onChange={(e) => this.props.onChange(this.fieldName('name_prefix'), e)}
            />
            <input
              type="text"
              name={this.contactFieldName('firstname')}
              className="form-control"
              placeholder="Vorname"
              value={this.contactAttribute('firstname')}
              onChange={(e) => this.props.onChange(this.fieldName('firstname'), e)}
            />
            <input
              type="text"
              name={this.contactFieldName('additional_name')}
              className="form-control"
              placeholder="Zweiter Vorname"
              value={this.contactAttribute('additional_name')}
              onChange={(e) => this.props.onChange(this.fieldName('additional_name'), e)}
            />
            <input
              type="text"
              name={this.contactFieldName('lastname')}
              className="form-control"
              placeholder="Nachname"
              value={this.contactAttribute('lastname')}
              onChange={(e) => this.props.onChange(this.fieldName('lastname'), e)}
            />
            <input
              type="text"
              name={this.contactFieldName('name_suffix')}
              className="form-control"
              placeholder="Zusatz"
              value={this.contactAttribute('name_suffix')}
              onChange={(e) => this.props.onChange(this.fieldName('name_suffix'), e)}
            />
          </div>
        </div>
      </div>
    )
  }
}

ContactNameForm.propTypes = {
  contact: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  wide: PropTypes.bool,
  fieldAddition: PropTypes.string,
  title: PropTypes.string,
  objectName: PropTypes.string,
}

ContactNameForm.defaultProps = {
  wide: true,
  fieldAddition: "",
  title: ""
}

export default ContactNameForm;

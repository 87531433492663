import React from 'react';
import PropTypes from 'prop-types';

import TodoTemplateModal from './TodoTemplateModal';

const AddTodoButton = ({ className, text, workflowId, onSaved, visible, workflowType, organizationSlug }) => (
  <TodoTemplateModal
    visible={visible}
    workflowType={workflowType}
    organizationSlug={organizationSlug}
    title="Neue Aufgabe"
    initialTodo={{
      text: '',
      desc: '',
      workflow_id: workflowId,
      hours_to_due: null,
    }}
    onSaved={onSaved}>
    {onClick => (
      <button className={className} onClick={onClick}>
        {text}
      </button>
    )}
  </TodoTemplateModal>
);

AddTodoButton.propTypes = {
  organizationSlug: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string,
  onSaved: PropTypes.func,
  workflowId: PropTypes.number.isRequired,
  workflowType: PropTypes.string.isRequired,
};

AddTodoButton.defaultProps = {
  visible: true,
  text: 'Neue Aufgabe',
  className: 'btn btn-success btn-compact',
};

export default AddTodoButton;

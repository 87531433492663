import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class IntegerInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      stringValue: (props.value ? props.value.toString() : ''),
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.validate = this.validate.bind(this);
  }

  validate() {
    const stringVal = this.state.stringValue;
    const numberVal = parseInt(stringVal, 10);

    if (!this.props.onChange) return;

    if (stringVal === numberVal.toString().trim()
      && (typeof this.props.minValue !== 'number' || numberVal >= this.props.minValue)
    ) {
      this.setState({
        value: numberVal,
      }, () => {
        this.props.onChange(numberVal);
      });
    } else {
      this.setState({
        value: undefined,
        stringValue: '',
      }, () => {
        this.props.onChange(undefined);
      });
    }
  }

  handleKeyDown(e) {
    const reg = /[0-9]/;

    const allowedKeys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Tab', '-'];

    if (e.key === '-' && this.state.stringValue !== '') {
      e.preventDefault();
    } else if (!reg.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  }

  handleChange(e) {
    this.setState({
      stringValue: e.target.value.trim(),
    });
  }

  handleBlur() {
    this.validate();
  }

  render() {
    /* eslint-disable no-unused-vars, prefer-const */
    let { onChange, allowNegatives, value, minValue, ...rest } = this.props;
    /* eslint-enable */

    if (typeof value !== 'number') {
      value = '';
    }

    return (
      <input
        {...rest}
        onKeyDown={this.handleKeyDown}
        type="text"
        onBlur={this.handleBlur}
        onKeyUp={this.handleKeyUp}
        value={this.state.stringValue}
        onChange={e => this.handleChange(e)}
      />
    );
  }
}

IntegerInput.propTypes = {
  minValue: PropTypes.number,
  value: PropTypes.number,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

IntegerInput.defaultProps = {
  // minValue: -5,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';

class Upload extends Component {
  renderStatus() {
    if (this.props.upload.status === 'stored') {
      return <Icon name="check-circle" />;
    }

    if (this.props.upload.status === 'uploading') {
      return <Icon name="circle-notch" spin />;
    }

    if (this.props.upload.status === 'processing') {
      return <Icon name="circle-notch" spin />;
    }

    if (this.props.upload.status === 'failed') {
      return <Icon name="exclamation-circle" />;
    }

    return <Icon name="clock" />; // waiting
  }

  renderProgress() {
    const width = `${this.props.upload.progress * 100}%`;

    return (
      <div className="upload-progress-bar">
        <div className="upload-current-progress" style={{ width }} />
      </div>
    );
  }

  render() {
    const classNames = `upload upload-${this.props.upload.status}`;

    return (
      <div className={classNames} key={this.props.upload.id}>
        <div className="upload-title">
          {this.props.upload.name}
        </div>
        <div className="upload-progress">
          {this.renderProgress()}
        </div>
        <div className="upload-status">
          {this.renderStatus()}
        </div>
      </div>
    );
  }
}

Upload.propTypes = {
  upload: PropTypes.object.isRequired,
};

export default Upload;

import React from 'react';
import PropTypes from 'prop-types';

import TodoDesc from '../Workflows/TodoDesc';
import TodoStatusBadge from '../Workflows/TodoStatusBadge';
import TodoPreviewComments from './TodoPreviewComments';
import TodoPreviewSubTodos from './TodoPreviewSubTodos';
import PriorityBadge from '../Workflows/PriorityBadge';
import TodoPreviewFacts from './TodoPreviewFacts';

const TodoPreview = ({ todo, onFinish, onEdit, onNewCommentSaved, commentable }) => {
  if (!todo) return null;

  return (
    <div className="todo-preview">
      <div className="todo-preview-text">
        <a href={`/todos/${todo.id}`}>{todo.text}</a><br />
      </div>

      <div className="todo-preview-status">
        <TodoStatusBadge todo={todo} size="small" />
        <PriorityBadge todo={todo} />
      </div>

      <TodoDesc desc={todo.desc} moreHref={`/todos/${todo.id}`} />
      <TodoPreviewSubTodos todo={todo} />
      <TodoPreviewComments
        todo={todo}
        commentable={commentable}
        onEdit={onEdit}
        onFinish={onFinish}
        onNewCommentSaved={onNewCommentSaved}
      />
      <TodoPreviewFacts todo={todo} commentable={commentable} />
    </div>
  );
};

TodoPreview.propTypes = {
  todo: PropTypes.object,
  onFinish: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onNewCommentSaved: PropTypes.func.isRequired,
  commentable: PropTypes.bool.isRequired,
};

export default TodoPreview;


import Validator from './validator';

const actionTemplateValidator = new Validator({
  rules: [
    { field: 'name', validator: 'presence', message: 'Bitte Name ausfüllen' },
    { field: 'name_fired', validator: 'presence', message: 'Bitte Name (ausgelöst) ausfüllen' },
    { field: 'icon', validator: 'presence', message: 'Bitte Symbol auswählen' },
    { field: 'color', validator: 'presence', message: 'Bitte Farbe auswählen' },
  ],
});

export default actionTemplateValidator;

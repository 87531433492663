/* global fetch */
/* eslint-disable quote-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

import { getAsync } from '../../helpers/rails_helper';
import Dropdown from '../shared/Dropdown';

import { Turbo } from "@hotwired/turbo-rails"
import Icon from '../shared/Icon';

import { dropdownStyles } from '../../styles/select_styles';

import { searchText } from '../../helpers/helpers';

const filterOptions = (option, searchTerm) => {
  return searchText(`${option.data.title} ${option.data.desc}`, searchTerm);
};

const styles = dropdownStyles;

const Option = (props) => {
  return (
    <components.Option {...props}>
      {props.data.title}
    </components.Option>
  );
};

class SegmentSelectorDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      dropDownVisible: false,
    };
  }

  handleOpen = () => {
    getAsync(this.props.optionsUrl).then((options) => {
      console.log(options);
      return options.segments;
    }).then((options) => {
      this.setState({ options });
    });
  };

  handleChange = (segment) => {
    const url = `${this.props.baseUrl}?seg=${segment.id}`;
    Turbo.visit(url);
  };

  handleClearClick = (e) => {
    e.preventDefault();

    const url = `${this.props.baseUrl}`;
    Turbo.visit(url);
  };

  handleClose = () => {
    this.setState({
      options: [],
    });
  };

  toggleDropDown = (e) => {
    e.preventDefault();

    const visible = !this.state.dropDownVisible;

    if (visible) {
      this.handleOpen();
    }

    this.setState({ dropDownVisible: visible });
  };

  handleBlur = () => {
    this.setState({ dropDownVisible: false });
  };

  renderButton = (ref, instance) => {
    if (this.props.initialSegment) {
      return (
        <div className="btn-group">
          <button ref={ref} className="btn btn-warning" onClick={this.toggleDropDown}>
            Segment: {this.props.initialSegment.title} <span className="caret" />
          </button>
          <button className="btn btn-warning" onClick={this.handleClearClick}>
            <Icon name="times" />
          </button>
        </div>
      );
    }
    return (
      <button ref={ref} className="btn btn-light" onClick={this.toggleDropDown}>
        Segment <span className="caret" />
      </button>
    );
  };

  render() {
    return (
      <Dropdown
        placement="bottom-end"
        ref={n => (this.dropdown = n)}
        isOpen={this.state.dropDownVisible}
        onClose={this.toggleDropDown}
        target={this.renderButton}>
        <Select
          autoFocus
          components={{
            Option,
            DropdownIndicator: null,
            IndicatorSeparator: null,
          }}
          openMenuOnFocus
          clearable={false}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
          backspaceRemovesValue={false}
          blurInputOnSelect
          menuPlacement="auto"
          menuIsOpen
          filterOption={filterOptions}
          onChange={this.handleChange}
          options={this.state.options}
          defaultOptions
          styles={styles}
          placeholder="Segment auswählen"
          noOptionsMessage={() => 'Keine Ergebnisse'}
        />
      </Dropdown>

    );
  }
}

SegmentSelectorDropdown.propTypes = {
  optionsUrl: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
  initialSegment: PropTypes.object,
};

export default SegmentSelectorDropdown;

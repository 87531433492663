import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';

const UploadButton = ({ visible, title, onClick }) => {
  if (!visible) return null;

  return (
    <button
      className="upload-button-desktop"
      type="button"
      onClick={onClick}>
      <Icon name="cloud-upload" /><span className="d-none d-sm-inline"> {title}</span>
    </button>
  );
};

UploadButton.propTypes = {
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

UploadButton.defaultProps = {
  title: 'Hochladen',
};

export default UploadButton;

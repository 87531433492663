import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';

import FolderTreeSection from './FolderTreeSection';
import FileSize from './FileSize';

class FolderTree extends Component {
  renderInfo() {
    return (
      <div className="file-size-info">
        <div>
          <Icon name="server" />
        </div>
        <div>
          <div>Speicherplatz</div>
          <div>
            <FileSize bytes={this.props.totalFileSize} /> belegt
          </div>
        </div>
      </div>
    );
  }

  render() {
    const rootFolderable = this.props.getFolderable(this.props.rootFolderableTypedId);

    if (!rootFolderable) return null;

    return (
      <div className="folder-tree sticky-top" style={{ top: this.props.stickyTopSpace }}>
        <div style={{ marginBottom: 8, marginTop: 2 }}>
          <FolderTreeSection
            selectedFolderableTypedId={this.props.selectedFolderableTypedId}
            onFolderableSelect={this.props.onFolderableSelect}
            folderable={rootFolderable}
            getSubFolderables={this.props.getSubFolderables}
            foldersVisible
            loadChildren={this.props.loadChildren}
            depth={0}
          />
        </div>
        {this.renderInfo()}
      </div>
    );
  }
}

FolderTree.propTypes = {
  getFolderable: PropTypes.func.isRequired,
  getSubFolderables: PropTypes.func.isRequired,
  onFolderableSelect: PropTypes.func.isRequired,
  totalFileSize: PropTypes.number.isRequired,
  selectedFolderableTypedId: PropTypes.string.isRequired,
  rootFolderableTypedId: PropTypes.string.isRequired,
  loadChildren: PropTypes.func.isRequired,
  stickyTopSpace: PropTypes.string.isRequired,
};

export default FolderTree;

import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../shared/Icon';
import TextArea from '../../shared/TextArea';

class AddCommentEffectForm extends React.Component {
  handleChange = (e) => {
    this.props.onChange({
      comment_body: e.target.value,
    });
  }

  render() {
    return (
      <div className="fire-effect-item">
        <div className="fire-effect-icon">
          <Icon name="comment" />
        </div>
        <div className="fire-effect-content">
          <div className="fire-effect-title">
            {this.props.effect.title || 'Kommentar'}
          </div>
          <div className="fire-effect-inputs">
            <TextArea
              autoSize
              rows={2}
              placeholder="Bitte ausfüllen"
              className="form-control"
              onChange={this.handleChange}
            />
            {this.props.effect.optional ? (
              <small className="text-muted">
                optional
              </small>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

AddCommentEffectForm.propTypes = {
  effect: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AddCommentEffectForm;

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import Tooltip from '../shared/Tooltip';

import { updateAsync } from '../../helpers/rails_helper';

class CallAll extends Component {
  handleCallClick = (e, account_id, phoneNumberId) => {
    e.preventDefault();

    updateAsync(`/phone_numbers/${phoneNumberId}/call`, {
      account_id
    }, 'PUT').then((result) => {
      if (result.error) {
        alert(result.error);
      }
    });
  }

  renderNumbers(accounts) {
    return this.props.phoneNumbers.filter(p => p.text_normalized).map(phoneNumber => (accounts.map(account => (
      <a
        className="dropdown-item"
        key={account.id}
        href="#"
        onClick={e => this.handleCallClick(e, account.id, phoneNumber.id)}
      >
        {phoneNumber.text}
        <span className="text-muted"> via {account.name || account.sip_id}</span>
      </a>
    ))));
  }

  renderPhoneSystems = () => {
    if (this.props.phoneIds && Object.keys(this.props.phoneIds).length > 0) {
      return (<div className="dropdown-menu dropdown-menu-end">
          {Object.entries(this.props.phoneIds).map(([phone_system, accounts], i) => {
            return (<Fragment key={phone_system}>
                {i > 1 && <hr className="dropdown-divider"/>}
                <span className="dropdown-header">Kunden mit {phone_system} anrufen</span>
                {this.renderNumbers(accounts)}
              </Fragment>)
          })}
        </div>)
    } else {
      return (<div className="dropdown-menu p-2 text-muted" style={{ minWidth: "12rem" }}>
          <p className="mb-0">
            Bitte füge deinem Profil ein Telefon hinzu.&nbsp;
            {this.props.phoneCreationPath && <a href={this.props.phoneCreationPath}>
              <Icon name="external-link"/>
            </a>}
          </p>
        </div>)
    }
  }

  render() {
    if (this.props.phoneNumbers.filter(p => p.text_normalized).length === 0) return null;

    return (
      <Tooltip title="Kunden anrufen" placement="top-end">
        <div className="btn-group d-none d-sm-block project-call">
          <button type="button" className="btn btn-compact" data-bs-toggle="dropdown">
            <Icon name="phone" light />
          </button>
          {this.renderPhoneSystems()}
        </div>
      </Tooltip>
    );
  }
}

CallAll.propTypes = {
  phoneIds: PropTypes.object.isRequired,
  phoneNumbers: PropTypes.array.isRequired,
  phoneCreationPath: PropTypes.string,
};

CallAll.defaultProps = {
  phoneNumbers: [],
};

export default CallAll;

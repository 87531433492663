import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ColorSelector from './ColorSelector';

class ColorSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  handleChange = (color) => {
    this.setState({ value: color });
  }

  render() {
    return (
      <div>
        <ColorSelector value={this.state.value} onChange={this.handleChange} />
        <input type="hidden" name={this.props.name} value={this.state.value} />
      </div>
    );
  }
}

ColorSelect.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default ColorSelect;

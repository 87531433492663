import React, { Component, Fragment } from 'react';
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';

import styles from '../../styles/select_styles';

class GroupMultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: props.group_options.split(',', -1).map(o => o.trim()).map(o => ({
        value: o,
        label: o,
      })),
      value: props.value.split(',', -1).map(o => o.trim()).map(o => ({
        value: o,
        label: o,
      })),
    };

    if (!props.group_options) {
      this.state.options = [];
    }

    if (!props.value) {
      this.state.value = [];
    }
  }

  handleChange = (newValue, actionMeta) => {
    console.group('Value Changed');
    console.log(newValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
    this.setState({ value: newValue });
    this.props.onChange(newValue.map(v => v.value).join(','));
  };

  formatCreateLabel(value) {
    return (
      <Fragment>
        Gruppe <strong>{value}</strong> erstellen
      </Fragment>
    );
  }

  render() {
    return (
      <CreatableSelect
        isMulti
        placeholder={this.props.placeholder || 'Gruppen auswählen'}
        onChange={this.handleChange}
        options={this.state.options}
        value={this.state.value}
        styles={styles.defaultStyles}
        noOptionsMessage={() => 'Keine Optionen'}
        formatCreateLabel={this.formatCreateLabel}
      />
    );
  }
}

GroupMultiSelect.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  group_options: PropTypes.string,
  onChange: PropTypes.func,
};

GroupMultiSelect.defaultProps = {
  value: '',
  group_options: '',
};

export default GroupMultiSelect;

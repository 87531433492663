import React from 'react';
import PropTypes from 'prop-types';

import DataPointTemplateSelector from '../../shared/DataPointTemplateSelector';
import DataPointOptionSelector from '../DataPointOptionSelector';
import DataPointStringOptionSelector from '../DataPointStringOptionSelector';

class SetDataPointEffectForm extends React.Component {
  handleAutoSetOptionChange = () => {
    this.props.onChange(Object.assign({}, this.props.record, {
      options: Object.assign({}, this.props.record.options, {
        set_auto_value: !this.props.record.options.set_auto_value,
      }),
    }));
  };

  handleBooleanAutoFillChange = (e) => {
    const auto_value = e.target.value;

    this.props.onChange(Object.assign({}, this.props.record, {
      options: Object.assign({}, this.props.record.options, {
        auto_value,
        set_auto_value: auto_value !== 'manual',
      }),
    }));
  };

  handleCurrentDatetimeChange = () => {
    if (this.props.record.options.content === 'current_datetime') {
      const { content, ...options } = this.props.record.options
      this.props.onChange(Object.assign({}, this.props.record, { options }));
    } else {
      this.props.onChange(Object.assign({}, this.props.record, {
        options: Object.assign({}, this.props.record.options, {
          content: 'current_datetime',
        }),
      }));
    }
  };

  handleSetNextTodosDue = () => {
    if (this.props.record.options.set_next_todos_due) {
      const { set_next_todos_due, ...options } = this.props.record.options
      this.props.onChange(Object.assign({}, this.props.record, { options }));
    } else {
      this.props.onChange(Object.assign({}, this.props.record, {
        options: Object.assign({}, this.props.record.options, {
          set_next_todos_due: true,
        }),
      }));
    }
  };

  handleOptionalClick = () => {
    if (this.props.record.options.optional) {
      const { optional, ...options } = this.props.record.options
      this.props.onChange(Object.assign({}, this.props.record, { options }));
    } else {
      this.props.onChange(Object.assign({}, this.props.record, {
        options: Object.assign({}, this.props.record.options, {
          optional: true,
        }),
      }));
    }
  };

  handleDataPointTemplateChange = (newDataPointTemplate) => {
    this.props.onChange(Object.assign({}, this.props.record, {
      data_point_template_id: newDataPointTemplate.id,
      data_point_template: newDataPointTemplate,
    }));
  };

  handleAutoOptionChange = (option) => {
    this.props.onChange(Object.assign({}, this.props.record, {
      options: Object.assign({}, this.props.record.options, {
        auto_value: option.value,
      }),
    }));
  };

  isDateField = () => (
    this.props.record.data_point_template && (
      this.props.record.data_point_template.data_point_type === 'DataPoint::DateTimeDataPoint'
      || this.props.record.data_point_template.data_point_type === 'DataPoint::DateDataPoint'
    )
  );

  isStringChoiceField = () => (
    this.props.record.data_point_template
      && this.props.record.data_point_template.data_point_type === 'DataPoint::StringChoiceDataPoint'
  );

  isBooleanField = () => (
    this.props.record.data_point_template
      && this.props.record.data_point_template.data_point_type === 'DataPoint::BooleanDataPoint'
  );

  renderStringOptionSelector() {
    if (!this.props.record.options.set_auto_value) return null;

    return (
      <DataPointStringOptionSelector
        value={this.props.record.options.auto_value}
        options={this.props.record.data_point_template.options}
        onChange={this.handleAutoOptionChange}
        size="small"
      />
    );
  }

  renderStringChoiceOptionSelector() {
    if (!this.props.record.options.set_auto_value) return null;

    return (
      <DataPointOptionSelector
        value={this.props.record.options.auto_value}
        options={this.props.record.data_point_template.options}
        onChange={this.handleAutoOptionChange}
        size="small"
      />
    );
  }

  renderStringChoiceOptions() {
    if (!this.isStringChoiceField()) return null;

    if (this.props.record.data_point_template && this.props.record.data_point_template.options) {
      return (
        <>
          <div className="form-check">
            <input
              type="checkbox"
              disabled={this.props.record.options.optional}
              checked={!!this.props.record.options.set_auto_value}
              onChange={(e) => this.handleAutoSetOptionChange(e)}
              id="auto-fill"
              className="form-check-input"
            />
            <label htmlFor="auto-fill" className="form-check-label">
              Feld automatisch füllen
              <br />
              <small className="text-muted">
                Das Feld wird automatisch mit der ausgewählten Option befüllt.
              </small>
            </label>
          </div>
          {this.renderStringChoiceOptionSelector()}
        </>
      );
    }

    return null;    
  }

  renderBooleanOptions() {
    if (!this.isBooleanField()) return null;

    return (
      <>
        <div className="form-check">
          <input
            type="radio"
            value="manual"
            name="boolean-data-point-prefill"
            id="boolean_data_point_prefill_manual"
            disabled={this.props.record.options.optional}
            checked={this.props.record.options.auto_value === 'manual'}
            onChange={(e) => this.handleBooleanAutoFillChange(e)}
            className="form-check-input"
          />
          <label htmlFor="boolean_data_point_prefill_manual" className="form-check-label">
            Manuell ausfüllen
            <br /><small className="text-muted">
              Das Feld muss manuell ausgefüllt werden.
            </small>
          </label>
        </div>
        <div className="form-check">
          <input
            type="radio"
            value="true"
            name="boolean-data-point-prefill"
            id="boolean_data_point_prefill_true"
            disabled={this.props.record.options.optional}
            checked={this.props.record.options.auto_value === 'true'}
            onChange={(e) => this.handleBooleanAutoFillChange(e)}
            className="form-check-input"
          />
          <label htmlFor="boolean_data_point_prefill_true" className="form-check-label">
            Mit 'Ja' ausfüllen
            <br /><small className="text-muted">
              Das Feld wird automatisch mit 'Ja' befüllt.
            </small>
          </label>
        </div>
        <div className="form-check">
          <input
            type="radio"
            value="false"
            name="boolean-data-point-prefill"
            id="boolean_data_point_prefill_false"
            disabled={this.props.record.options.optional}
            checked={this.props.record.options.auto_value === 'false'}
            onChange={(e) => this.handleBooleanAutoFillChange(e)}
            className="form-check-input"
          />
          <label htmlFor="boolean_data_point_prefill_false" className="form-check-label">
            Mit 'Nein' ausfüllen
            <br /><small className="text-muted">
              Das Feld wird automatisch mit 'Nein' befüllt.
            </small>
          </label>
        </div>
      </>
    );
  }

  renderDateOptions() {
    if (!this.isDateField()) return null;

    return (
      <>
        <div className="form-check">

          <input
            type="checkbox"
            checked={this.props.record.options.content === 'current_datetime'}
            onChange={e => this.handleCurrentDatetimeChange(e)}
            id="current_datetime"
            className="form-check-input"
          />
          <label htmlFor="current_datetime" className="form-check-label">
            Automatisch das aktuelle Datum setzen
            <br />
            <small className="text-muted">
              Das Feld wird mit dem Zeitpunkt der Aktionsauslösung befüllt.
            </small>
          </label>
        </div>
        <div className="form-check">
          <input
            type="checkbox"
            checked={!!this.props.record.options.set_next_todos_due}
            onChange={(e) => this.handleSetNextTodosDue(e)}
            id="set_next_todos_due"
            className="form-check-input"
          />
          <label htmlFor="set_next_todos_due" className="form-check-label">
            Als Fälligkeit für die Folgeaufgabe verwenden
            <br />
            <small className="text-muted">
              Sinnvoll, um manuell die nächste Fälligkeit (z.B. für einen Termin) zu setzen
            </small>
          </label>
        </div>
      </>
    );
  }

  render() {
    return (
      <>
        <div className="mb-3">
          <label className="form-label">Datenpunkt</label>
          <DataPointTemplateSelector
            value={this.props.record.data_point_template}
            onChange={this.handleDataPointTemplateChange}
            optionsUrl={`/${this.props.organizationSlug}/data_point_template_options.json`}
          />
        </div>
        <div className="form-check">
          <input
            type="checkbox"
            disabled={this.props.record.options.set_auto_value}
            checked={this.props.record.options.optional}
            onChange={(e) => this.handleOptionalClick(e)}
            id="optional"
            className="form-check-input"
          />
          <label htmlFor="optional" className="form-check-label">
            Optional
            <br />
            <small className="text-muted">
              Wähle diese Option, wenn die Eingabe des Feldes optional sein soll
            </small>
          </label>
        </div>
        {this.renderDateOptions()}
        {this.renderStringChoiceOptions()}
        {this.renderBooleanOptions()}
      </>
    );
  }
}

SetDataPointEffectForm.propTypes = {
  record: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default SetDataPointEffectForm;

import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import SearchResultLink from './SearchResultLink';

const SearchResultOffer = (props) => {
  return (
    <SearchResultLink {...props}>
      <div>
        <Icon name="file-alt" />
      </div>
      <div>
        <div>
          <div>
            <div style={{ display: 'flex' }}>
              <div style={{ flexGrow: 1 }}>
                <span className="text-monospace">{props.offer_number} </span>
                {props.title}
              </div>
            </div>
          </div>
        </div>
      </div>
    </SearchResultLink>
  );
};

SearchResultOffer.propTypes = {
  title: PropTypes.string.isRequired,
  offer_number: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default SearchResultOffer;

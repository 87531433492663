import React from 'react';
import PropTypes from 'prop-types';

import TrimmedText from '../shared/TrimmedText';

const TodoDesc = ({ desc, maxLength, moreText, moreHref }) => {
  if (!desc) return null;

  return (
    <div className="todo-preview-desc">
      <div style={{ color: '#777', lineHeight: '1.5em', fontSize: 13, padding: '4px 0' }}>
        <TrimmedText
          text={desc}
          maxLength={maxLength}
          moreText={moreText}
          moreHref={moreHref}
        />
      </div>
    </div>
  );
};

TodoDesc.propTypes = {
  desc: PropTypes.string,
  maxLength: PropTypes.number,
  moreHref: PropTypes.string,
  moreText: PropTypes.string,
};

TodoDesc.defaultProps = {
  maxLength: 160,
  moreText: 'mehr',
};

export default TodoDesc;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

const TypeOptions = [
  { value: 'note', label: 'Notiz' },
  { value: 'comment', label: 'Kommentar' },
  { value: 'personal', label: 'Persönlich' },
  { value: 'incoming_call', label: 'Hat angerufen' },
  { value: 'outgoing_call', label: 'Wurde angerufen' },
  { value: 'incoming_email', label: 'Eingehende Email' },
  { value: 'outgoing_email', label: 'Ausgehende Email' },
  { value: 'incoming_letter', label: 'Brief eingegangen' },
  { value: 'outgoing_letter', label: 'Brief gesendet' },
];

class ContactNoteTypeSelector extends Component {
  handleChange = (e) => {
    if (this.props.onChange) {
      this.props.onChange(e.target.value);
    }
  }

  renderOptions() {
    return TypeOptions.map(t => (
      <option key={t.value} value={t.value}>{t.label}</option>
    ));
  }

  render() {
    return (
      <select
        className="form-control"
        value={this.props.value}
        onChange={this.handleChange}>
        <option>Typ auswählen</option>
        {this.renderOptions()}
      </select>
    );
  }
}

ContactNoteTypeSelector.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default ContactNoteTypeSelector;

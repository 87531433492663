import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getEffectType } from '../WorkflowTemplates/EffectTypeSquares';

import Icon from '../shared/Icon';
import Label from '../shared/Label';
import Forms from './EffectForms/Forms';

import UploaderProvider from '../Uploader/UploaderProvider';

class FireActionForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      effectsValid: {},
    };
  }

  handleEffectParamChange = (effect, value, isValid = true) => {
    this.props.onChange(Object.assign({}, this.props.params, {
      effects_attributes: Object.assign({}, this.props.params.effects_attributes, {
        [effect.id]: value,
      }),
    }));

    this.setState((prevState) => ({
      effectsValid: {
        ...prevState.effectsValid,
        [effect.id]: isValid,
      },
    }));
  };

  isEverythingValid = () => {
    const keys = Object.keys(this.state.effectsValid);

    return keys.every(key => !!this.state.effectsValid[key]);
  };

  renderNextProjectState() {
    if (!this.props.action.next_project_state) return null;

    return (
      <div className="fire-effect-item">
        <div className="fire-effect-icon">
          <Icon name="road" />
        </div>
        <div className="fire-effect-content">
          <div className="fire-effect-title">
            Projektstatus wird geändert zu <Label
              title={this.props.action.next_project_state.state}
              color={this.props.action.next_project_state.color}
              textColor={this.props.action.next_project_state.text_color}
            />
          </div>
        </div>
      </div>
    );
  }

  renderComplete() {
    if (this.props.action.complete_todo) {
      return (
        <div className="fire-effect-item">
          <div className="fire-effect-icon">
            <Icon name="check" />
          </div>
          <div className="fire-effect-content">
            <div className="fire-effect-title">
              Aufgabe wird abgeschlossen
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="fire-effect-item">
        <div className="fire-effect-icon">
          <Icon name="ban" />
        </div>
        <div className="fire-effect-content">
          <div className="fire-effect-title">
            Aufgabe wird <strong>nicht</strong> abgeschlossen
          </div>
        </div>
      </div>
    );
  }

  renderNextTodos() {
    if (!this.props.action.todo_activations.length) return null;

    return this.props.action.todo_activations.map(activation => (
      <div key={activation.todo_id} className="fire-effect-item">
        <div className="fire-effect-icon">
          <Icon name="power-off" />
        </div>
        <div className="fire-effect-content">
          <div className="fire-effect-title">
            Aufgabe <a href={`/todos/${activation.todo_id}`}>
              <strong>{activation.todo.text}</strong>
            </a> wird aktiviert
          </div>
        </div>
      </div>
    ));
  }

  renderEffects() {
    if (!this.props.action.effects.length) return null;

    return this.props.action.effects.map((effect) => {
      const effectType = getEffectType(effect.type);
      const Comp = Forms[effectType.form];

      if (!Comp) return `React-Komponente für ${effect.type} nicht gefunden`;

      return (
        <Comp
          dataHolderId={this.props.projectId}
          dataHolderType="Project"
          key={effect.id}
          organizationSlug={this.props.organizationSlug}
          roles={this.props.roles}
          effect={effect}
          // eslint-disable-next-line max-len
          onChange={(v, isValid) => this.handleEffectParamChange(effect, v, isValid)}
          value={this.props.params.effects_attributes
            && this.props.params.effects_attributes[effect.id]} />
      );
    });
  }

  render() {
    return (
      <UploaderProvider>
        <div className="actions-container-section">
          <span className="section-header">
            <div className="section-icon" style={{ backgroundColor: this.props.action.color }}>
              <Icon name={this.props.action.icon} />
            </div>
            <div className="section-name">
              {this.props.action.name}
            </div>
          </span>
        </div>
        <div className="actions-container-section">
          <div className={this.props.saving ? 'box-loading' : ''}>
            {this.renderNextProjectState()}
            {this.renderComplete()}
            {this.renderNextTodos()}
            {this.renderEffects()}
          </div>
        </div>
        <div className="actions-container-section actions-container-section-confirm">
          <button
            className="btn btn-primary"
            type="button"
            disabled={this.props.saving || !this.isEverythingValid()}
            onClick={this.props.onFire}>
            bestätigen
          </button>
          <button
            className="btn btn-link"
            type="button"
            style={{ marginLeft: 10 }}
            onClick={this.props.onCancel}>
            abbrechen
          </button>
        </div>
      </UploaderProvider>
    );
  }
}

FireActionForm.propTypes = {
  projectId: PropTypes.number.isRequired,
  action: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onFire: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  saving: PropTypes.bool,
  roles: PropTypes.array.isRequired,
};

export default FireActionForm;

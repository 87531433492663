import React from 'react';

import PropTypes from 'prop-types';
import { get } from 'lodash';

import OperatorSelector from '../../OperatorSelector';

// eslint-disable-next-line import/no-named-as-default-member, import/no-named-as-default
import OfferDateSelector from '../../../shared/OfferDateSelector';
import NumericInput from '../../../shared/NumericInput';

// eslint-disable-next-line import/no-named-as-default-member, import/no-named-as-default
import BaseCondition from '../BaseCondition';
import { autoNumericOptionsEuro, autoNumericOptionsDuration } from '../../../../config/auto_numeric_options';
import StringOperand2Selector from '../../StringOperand2Selector';


const comparisonOperators = ['eq', 'neq', 'gt', 'gte', 'lt', 'lte'];

class OfferDateCondition extends React.Component {
  constructor(props) {
    super(props);

    this.handleStringOperand2Change = this.handleStringOperand2Change.bind(this);
    this.handleChangeOperator = this.handleChangeOperator.bind(this);
    this.handleOfferDateChange = this.handleOfferDateChange.bind(this);
    this.handleIntegerChange = this.handleIntegerChange.bind(this);
    this.handleDecimalChange = this.handleDecimalChange.bind(this);
  }

  handleChangeOperator({ value })  {
    this.props.onChange(Object.assign({}, this.props.condition, {
      operator: value,
    }));
  }

  handleOfferDateChange(offer_date)  {
    this.props.onChange(Object.assign({}, this.props.condition, {
      string_operand_1: offer_date.path,
      // Wenn sich das Feld der Configuration ändert, wird sich wohl auch der Wert ändern. Wenn es überhaupt einen
      // operand_2 geben wird. Also alle "löschen"
      string_operand_2: '',
      decimal_operand_2: null,
      integer_operand_2: null,
      boolean_operand_2: null,
      offer_date,
    }));
  }

  handleIntegerChange(e, rawValue) {
    let value = rawValue;

    if (this.props.condition.string_operand_1 === 'meta.plan.pv.limitedPeakPerformance') {
      // Anzeige in kWp, speichern in Wp
      value *= 1000;
    }

    this.props.onChange(Object.assign({}, this.props.condition, {
      integer_operand_2: Math.round(value),
      // und die anderen "löschen"
      string_operand_2: '',
      decimal_operand_2: null,
      boolean_operand_2: null,
    }));
  }

  handleDecimalChange(e, rawValue) {
    this.props.onChange(Object.assign({}, this.props.condition, {
      decimal_operand_2: rawValue,
      // und die anderen "löschen"
      string_operand_2: '',
      integer_operand_2: null,
      boolean_operand_2: null,
    }));
  }

  handleStringOperand2Change(value) {
    this.props.onChange(Object.assign({}, this.props.condition, {
      string_operand_2: value.value,
      // und die anderen "löschen"
      decimal_operand_2: null,
      integer_operand_2: null,
      boolean_operand_2: null,
    }));
  }

  isComparisonOperator() {
    return comparisonOperators.includes(this.props.condition.operator);
  }

  renderOperator() {
    if (this.props.condition.offer_date) {
      const filteredOperators = this.props.condition.filter_condition_type.operators.filter((operator) => {
        return this.props.condition.offer_date.operators.indexOf(operator.key) !== -1;
      });

      return (
        <div style={{ flexBasis: 200, marginLeft: 8 }}>
          <OperatorSelector
            operators={filteredOperators}
            onChange={this.handleChangeOperator}
            value={this.props.condition.operator}
          />
        </div>
      );
    }

    return null;
  }

  renderOperand1() {
    return (
      <div style={{ flexBasis: 300, marginLeft: 8 }}>
        <OfferDateSelector
          value={this.props.condition.offer_date}
          optionsUrl={`/${this.props.organizationSlug}/offer_date_options.json`}
          onChange={this.handleOfferDateChange}
          size="small"
        />
      </div>
    );
  }

  renderOperand2() {
    if (this.isComparisonOperator()) {
      // Die Art des Operanden wird hier genauso eroiert, wie in wegaplan dependencyFilter.js. Das heißt
      // Änderungen müssen auf beiden Seiten realisiert werden.
      const operand2Options = get(this.props, 'condition.offer_date.operand_2_options');
      if (typeof get(operand2Options, '[0]key') === 'string') {
        return (
          <div style={{ flexBasis: 200, marginLeft: 8 }}>
            <StringOperand2Selector
              value={this.props.condition.string_operand_2}
              onFocus={this.handleFocus}
              operand2Options={operand2Options}
              onChange={this.handleStringOperand2Change}
              size="small"
            />
          </div>
        );
      }

      if (this.props.condition.string_operand_1 === 'meta.plan.pv.limitedPeakPerformance' ||
        this.props.condition.string_operand_1 === 'meta.plan.pv.converter_id' ||
        this.props.condition.string_operand_1 === 'meta.plan.pv.converter_ids'||
        this.props.condition.string_operand_1 === 'meta.plan.pv.converter_ids.length' ||
        this.props.condition.string_operand_1 === 'meta.plan.pv.moduleCount') {
        let value;
        let options;
        if (this.props.condition.string_operand_1 === 'meta.plan.pv.limitedPeakPerformance') {
          // Gespeichert in Wp, Anzeige in kWp
          value = this.props.condition.integer_operand_2 / 1000;
          options = autoNumericOptionsEuro;
        } else {
          value = this.props.condition.integer_operand_2;
          options = autoNumericOptionsDuration;
        }

        return (
          <div style={{ flexBasis: 200, marginLeft: 8 }}>
            <NumericInput
              size="small"
              type="text"
              value={value || 0}
              className="form-control input-sm"
              style={{ width: 60 }}
              onChange={this.handleIntegerChange}
              options={options}
            />
          </div>
        );
      }

      return (
        <div style={{ flexBasis: 200, marginLeft: 8 }}>
          <NumericInput
            size="small"
            type="text"
            value={this.props.condition.decimal_operand_2 || 0}
            className="form-control input-sm"
            style={{ width: 60 }}
            onChange={this.handleDecimalChange}
            options={autoNumericOptionsEuro}
          />
        </div>
      );
    }

    return null;
  }

  render() {
    return (
      <BaseCondition {...this.props}>
        {this.renderOperand1()}
        {this.renderOperator()}
        {this.renderOperand2()}
      </BaseCondition>
    );
  }
}

OfferDateCondition.propTypes = {
  condition: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default OfferDateCondition;

/* global fetch */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';

import { defaultStyles } from '../../styles/select_styles';

import RoleOption from '../Roles/RoleOption';
import SingleRoleValue from '../Roles/SingleRoleValue';

import { searchText } from '../../helpers/helpers';

const filterOption = (option, term) => {
  return searchText(option.data.name, term);
};

class RoleTemplateSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      options: [],
    };
  }

  loadOptions = () => {
    fetch(this.props.optionsUrl, {
      credentials: 'include',
    }).then(response => response.json()).then((result) => {
      console.log(result);
      this.setState({
        options: result.roles,
      });
    });
  }

  handleFocus = () => {
    this.loadOptions();
  }

  handleChange = (data) => {
    this.setState({ value: data });
    this.props.onChange(data);
  }

  render() {
    return (
      <Select
        components={{
          Option: RoleOption,
          SingleValue: SingleRoleValue,
        }}
        isClearable
        value={this.state.value}
        openMenuOnFocus
        backspaceRemovesValue={false}
        onChange={this.handleChange}
        options={this.state.options}
        defaultOptions
        onFocus={this.handleFocus}
        filterOption={filterOption}
        styles={defaultStyles}
        placeholder="Bitte Rolle auswählen"
        loadingPlaceholder="Laden..."
        noOptionsMessage={() => 'Keine Ergebnisse'}
        loadingMessage={() => 'Laden...'}
      />
    );
  }
}

RoleTemplateSelector.propTypes = {
  optionsUrl: PropTypes.string.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default RoleTemplateSelector;

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { defaultStyles } from '../../styles/select_styles';

import Select, { components } from 'react-select';

import { getAsync } from '../../helpers/rails_helper';

const Option = props => (
  <components.Option {...props}>
    <span title={props.data.label}>
      {props.data.label}
    </span>
  </components.Option>
);

const SingleValue = props => (
  <components.SingleValue {...props}>
    <span title={props.data.label}>
      {props.data.label}
    </span>
  </components.SingleValue>
);

class ColumnTypeSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.loadOptions();
  }

  loadOptions = () => {
    getAsync(`/viewer_options.json?viewer_class_name=${this.props.viewerClassName}`).then((result) => {
      this.setState({
        loading: false,
        options: [
          ...result.viewable_elements.map((a) => ({
            ...a, value: a.name,
          })),
        ],
      });
    });
  };

  render() {
    return (
      <Select
        components={{
          Option,
          SingleValue,
        }}
        menuPlacement="auto"
        clearable={false}
        value={this.state.options.filter(({ value }) => value === this.props.value)}
        onChange={o => this.props.onChange(o)}
        options={this.state.options}
        placeholder="Bitte Typ auswählen"
        isLoading={this.state.loading}
        loadingPlaceholder="Laden..."
        styles={defaultStyles}
      />
    );
  }
}

ColumnTypeSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  viewerClassName: PropTypes.string.isRequired,
};

export default ColumnTypeSelector;

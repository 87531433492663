import React from 'react';
import PropTypes from 'prop-types';

import ProjectRoleForm from './ProjectRoleForm';
import RemoteFormModal from '../shared/RemoteFormModal';

import roleTemplateValidator from '../../validators/role_template_validator';
import { saveRole } from '../../helpers/api';

const ProjectRoleModal = props => (
  <RemoteFormModal {...props}
    allowOverflow
    titleNew="Projektrolle hinzufügen"
    titleEdit="Rolle bearbeiten"
    validator={roleTemplateValidator}
    form={ProjectRoleForm}
    initialRecord={props.initialRole}
    saveHandler={saveRole}
    organizationSlug={props.organizationSlug}
  />
);

ProjectRoleModal.propTypes = {
  initialRole: PropTypes.object.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default ProjectRoleModal;

import React from 'react';
import PropTypes from 'prop-types';

const ShareNotice = ({ selectedFolderableTypedId, getFolderable }) => {
  const selectedFolderable = getFolderable(selectedFolderableTypedId);

  const isSharedWith = (folderable, group) => {
    if (!folderable) return false;

    const shared = folderable && folderable.shared_with && folderable.shared_with.includes(group);
    if (shared) return true;

    if (folderable.parent_typed_id) {
      return isSharedWith(getFolderable(folderable.parent_typed_id, group));
    }
    return false;
  };

  const sharedWithClient = isSharedWith(selectedFolderable, 'client');
  const sharedWithPartner = isSharedWith(selectedFolderable, 'partner');

  if (!sharedWithClient && !sharedWithPartner) return null;

  return (
    <div className="alert alert-warning file-browser-share-notice">
      <p>
        <strong>
          Ordner für {`
            ${sharedWithClient ? 'Kunden' : ''} 
            ${sharedWithClient && sharedWithPartner ? ' und ' : ''} 
            ${sharedWithPartner ? 'Fachpartner' : ''}
          `} sichtbar
        </strong>
      </p>
      <p>
        Die Inhalte dieses Ordners und aller Unterordner sind für {`
            ${sharedWithClient ? 'den Kunden im Kundenportal' : ''} 
            ${sharedWithClient && sharedWithPartner ? ' und ' : ''} 
            ${sharedWithPartner ? 'Fachpartner im Partnerportal' : ''}
          `} sichtbar.
      </p>
    </div>
  );
};

ShareNotice.propTypes = {
  selectedFolderableTypedId: PropTypes.string.isRequired,
  getFolderable: PropTypes.func.isRequired,
};

export default ShareNotice;

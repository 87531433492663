import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ContactNote from './ContactNote';
import ContactNoteForm from './ContactNoteForm';

import { getAsync } from '../../helpers/rails_helper';

class ContactNotes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contactNotes: [],
    };
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    getAsync(`/contacts/${this.props.contactId}/contact_notes.json`).then((result) => {
      this.setState({
        contactNotes: result.contact_notes,
      });
    });
  }

  handleDeleted = (contactNote) => {
    this.setState({
      contactNotes: this.state.contactNotes.filter(c => c.id !== contactNote.id),
    });
  }

  handleAdded = (contactNote) => {
    this.setState({
      contactNotes: [...this.state.contactNotes, contactNote],
    });
  }

  renderContactNotes() {
    if (!this.state.contactNotes.length) return null;

    return this.state.contactNotes.map(contactNote => (
      <ContactNote
        contactNote={contactNote}
        key={contactNote.id}
        deletable={this.props.editable}
        onDeleted={this.handleDeleted}
      />
    ));
  }

  render() {
    return (
      <div>
        <ContactNoteForm
          contactId={this.props.contactId}
          onAdded={this.handleAdded}
        />
        {this.renderContactNotes()}
      </div>
    );
  }
}

ContactNotes.propTypes = {
  contactId: PropTypes.number.isRequired,
  editable: PropTypes.bool.isRequired,
};

export default ContactNotes;

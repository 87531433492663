import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Uploads from '../Uploader/Uploads';

class ComposeDropzone extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploads: [],
      queueDone: true,
      uploadsFailed: false,
      uploadQueueHidden: true,
    };

    this.handleUploadsChanged = this.handleUploadsChanged.bind(this);
    this.handleUploadsDone = this.handleUploadsDone.bind(this);
  }

  componentDidMount() {
    this.uploadQueue = this.context.uploader.createQueue();
    this.uploadQueue.addListener('onUploadsChanged', this.handleUploadsChanged);
    this.uploadQueue.addListener('onUploadDone', this.handleUploadsDone);
  }

  componentWillUnmount() {
    this.uploadQueue.removeListener('onUploadsChanged', this.handleUploadsChanged);
    this.uploadQueue.addListener('onUploadDone', this.handleUploadsDone);
  }

  handleUploadsChanged(uploads) {
    this.setState({ uploads });
    this.props.onUploadsFinished(
      uploads.filter(u => u.status === 'stored').map(u => Object.assign({}, u)),
    );
  }

  handleUploadsDone() {
    this.setState({
      queueDone: true,
      uploadsFailed: this.state.uploads.some(u => u.status === 'failed'),
    });

    if (this.state.uploads.every(u => u.status === 'stored')) {
      this.doneTimer = setTimeout(() => {
        this.setState({
          queueDone: true,
          uploadQueueHidden: true,
        });
        setTimeout(() => {
          this.uploadQueue.clearQueue();
          this.setState({
            uploadsFailed: false,
          });
        }, 500);
      }, 2000);
    }
  }

  handleFileAdded = (acceptedFiles, rejectedFiles) => {
    this.uploadQueue.addFiles(acceptedFiles, {
      url: '/documents',
      fileField: 'document[file]',
    });

    this.setState({
      queueDone: false,
      uploadQueueHidden: false,
    });
  };

  handleHideUploadQueue = () => {
    this.setState({
      uploadQueueHidden: true,
      uploadsFailed: false,
    });
  };

  render() {
    return (
      <Dropzone
        className="dropzone"
        activeClassName="active"
        multiple
        disableClick
        onDrop={this.handleFileAdded}
        ref={node => this.props.getDropzoneRef(node)}>
        <div>
          {this.props.children}
        </div>
        <Uploads
          uploads={this.state.uploads}
          onHide={this.handleHideUploadQueue}
          hideQueueAvailable={this.state.queueDone && this.state.uploadsFailed}
          hidden={this.state.uploadQueueHidden}
        />
      </Dropzone>
    );
  }
}

ComposeDropzone.contextTypes = {
  uploader: PropTypes.object,
};

ComposeDropzone.propTypes = {
  getDropzoneRef: PropTypes.func.isRequired,
  children: PropTypes.node,
  onUploadsFinished: PropTypes.func.isRequired,
};

export default ComposeDropzone;

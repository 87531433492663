import React from 'react';
import PropTypes from 'prop-types';

import NumericInput from '../../shared/NumericInput';
import { autoNumericOptionsDecimal } from '../../../config/auto_numeric_options';

const DecimalDataPointInput = ({ onChange, onSave, value, saving }) => {
  const handleChange = (e, v) => {
    onChange(v);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && onSave) {
      onSave();
    }
  };

  return (
    <NumericInput
      className="form-control"
      onKeyDown={handleKeyDown}
      options={autoNumericOptionsDecimal}
      autoFocus
      disabled={saving}
      value={value}
      onChange={handleChange}
    />
  );
};

DecimalDataPointInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  saving: PropTypes.bool,
};

export default DecimalDataPointInput;

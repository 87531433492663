import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import Tooltip from '../shared/Tooltip';

import { pluralize } from '../../helpers/helpers';

const SubTodosBadge = ({ todo, style }) => {
  if (!todo.todos_count) return null;

  let statusClass = 'todo-badge-default';

  if (!todo.todos_count_incomplete) {
    statusClass = 'todo-badge-success';
  } else if (todo.all_todos_overdue_count) {
    statusClass = 'todo-badge-danger';
  } else if (todo.all_todos_with_due_count) {
    statusClass = 'todo-badge-warning';
  }

  const completed = todo.todos_count_completed;
  const total = todo.todos_count;
  const open = total - completed;

  let title;

  if (open === 0) {
    title = `${total} erledigte ${pluralize(total, 'Aufgabe', 'Aufgaben')}`;
  } else {
    title = `${open} offene ${pluralize(open, 'Aufgabe', 'Aufgaben')}`;
  }

  return (
    <Tooltip title={title} placement="top">
      <div className={`todo-badge ${statusClass}`} title={title} style={style}>
        <Icon name="check" /> {completed}/{total}
      </div>
    </Tooltip>
  );
};

SubTodosBadge.propTypes = {
  todo: PropTypes.object,
  style: PropTypes.object,
};

export default SubTodosBadge;

/* eslint-disable camelcase */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { DragDropContext } from 'react-beautiful-dnd';

import Cookies from 'js-cookie';

import UploaderProvider from '../Uploader/UploaderProvider';
import UploadManager from './UploadManager';

import FileBrowser from './FileBrowser';
import FolderTree from './FolderTree';

import { getAsync, updateAsync } from '../../helpers/rails_helper';

import { flashError } from '../../helpers/flash_helper';

import { subscribe, unsubscribe } from '../../helpers/actioncable_helper';

const getFolderableId = (gid) => {
  return parseInt(gid.split('-')[0], 10);
};

const getFolderableType = (gid) => {
  return gid.split('-')[1];
};

const getFolderablePath = (gid) => {
  return `/folderables/${gid}`;
};

class FileBrowserContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      folderables: {},
      attachments: [],
      selectedAttachments: [],
      loadingAttachments: false,
      selectedFolderableTypedId: props.folderableTypedId,
      isDragging: false,
      layout: Cookies.get('file-browser-layout') || 'list',
    };
  }

  componentDidMount() {
    this.loadFolderable(this.props.rootFolderableTypedId);
    this.loadChildren(this.props.rootFolderableTypedId);
    this.loadAttachments(true);
    this.subscribeToFileChanges();

    if (this.props.rootFolderableTypedId !== this.props.folderableTypedId) {
      this.loadFolderable(this.props.folderableTypedId);
      this.loadChildren(this.props.folderableTypedId);
      this.loadParents(this.props.folderableTypedId);
    }

    window.history.replaceState({
      selectedFolderableTypedId: this.state.selectedFolderableTypedId,
    }, null, `/folderables/${this.state.selectedFolderableTypedId}`);

    window.addEventListener('popstate', this.handlePopState);
    window.addEventListener('click', this.handleWindowClick);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handlePopState);
    window.removeEventListener('click', this.handleWindowClick);
  }

  // Vorerst keine Folder-Updates
  // Evtl. später wieder aktivieren
  //
  // subscribeToFolderChanges = () => {
  //   if (this.folderChangesChannel) {
  //     unsubscribe(this.folderChangesChannel);
  //   }

  //   this.folderChangesChannel = subscribe({
  //     channel: 'FolderableFolderChangesChannel',
  //     folderable_type: this.props.attachable_type,
  //     folderable_id: this.props.attachable_id,
  //     organization_id: this.props.organization_id,
  //   }, (data) => {
  //     if (data.action === 'new') {
  //       this.handleNewFolder(JSON.parse(data.folder));
  //     } else if (data.action === 'delete') {
  //       this.handleDeleteFolder(data.id);
  //     } else if (data.action === 'update') {
  //       this.handleUpdateFolder(JSON.parse(data.folder));
  //     }
  //   });
  // };

  subscribeToFileChanges = () => {
    if (this.fileChangesChannel) {
      unsubscribe(this.fileChangesChannel);
    }

    this.fileChangesChannel = subscribe({
      channel: 'AttachableFileChangesChannel',
      attachable_typed_id: this.state.selectedFolderableTypedId,
    }, (data) => {
      if (data.action === 'new') {
        this.handleFileAdded({ attachment: JSON.parse(data.attachment) });
      } else if (data.action === 'delete') {
        this.handleFilesDeleted([data.id]);
      } else if (data.action === 'update') {
        this.handleFileUpdated(JSON.parse(data.attachment));
      }
    });
  };

  handleLayoutSwitch = () => {
    const newLayout = (this.state.layout === 'list' ? 'grid' : 'list');

    this.setState((prevSate) => ({
      layout: newLayout,
    }));

    Cookies.set('file-browser-layout', newLayout, {
      expires: 365,
      path: '/',
      secure: false,
    });
  };

  handleWindowClick = (e) => {
    if (e.defaultPrevented) {
      return;
    }

    this.selectRangeOfAttachments('none');
  };

  handleFolderAdded = (newFolder) => {
    this.setState(prevState => ({
      folderables: {
        ...prevState.folderables,
        [newFolder.typed_id]: newFolder,
      },
    }));
  };

  handleFolderUpdated = (updatedFolder) => {
    this.setState(prevState => ({
      folderables: {
        ...prevState.folderables,
        [updatedFolder.typed_id]: updatedFolder,
      },
    }));
  };

  handleFolderDeleted = (folderableTypedId) => {
    this.setState(prevState => {
      const { [folderableTypedId]: deletedFolderable, ...rest } = prevState.folderables;

      return {
        folderables: rest,
      };
    });
  };

  handleFileAdded = (newFile) => {
    if (`${newFile.attachment.attachable_id}-${newFile.attachment.attachable_type}` === this.state.selectedFolderableTypedId) {
      this.setState((prevState) => {
        if (!prevState.attachments.some((f) => f.id === newFile.attachment.id)) {
          return {
            attachments: [...prevState.attachments, newFile.attachment],
          };
        }

        return prevState.attachments;
      });
    }
  };

  handleFileUpdated = (newAttachment) => {
    this.setState(prevState => ({
      attachments: prevState.attachments.map((attachment) => {
        if (attachment.id === newAttachment.id) {
          return newAttachment;
        }
        return attachment;
      }),
    }));
  };

  handleFilesDeleted = (ids) => {
    this.setState(prevState => ({
      attachments: prevState.attachments.filter(f => !ids.includes(f.id)),
    }));
  };

  setSelectedFolderableTypedId = (selectedFolderableTypedId) => {
    this.loadChildren(selectedFolderableTypedId);

    this.setState({
      selectedFolderableTypedId,
    }, () => {
      this.loadAttachments(true);
      this.subscribeToFileChanges();
    });
  };

  loadFolderable = (folderableTypedId) => {
    getAsync(getFolderablePath(folderableTypedId)).then((result) => {
      this.setState((prevState) => ({
        folderables: {
          ...prevState.folderables,
          [result.folderable.typed_id]: result.folderable,
        },
      }));
    });
  };

  loadChildren = (folderableTypedId) => {
    getAsync(`${getFolderablePath(folderableTypedId)}/folders`).then((result) => {
      this.setState((prevState) => {
        const newFolderables = {
          ...prevState.folderables,
          ...Object.fromEntries(result.folders.map((f) => [f.typed_id, f])),
        };

        return ({
          folderables: newFolderables,
        });
      });
    });
  };

  loadParents = (folderableTypedId) => {
    getAsync(`${getFolderablePath(folderableTypedId)}/parent_folders`).then((result) => {
      this.setState((prevState) => {
        const newFolderables = {
          ...prevState.folderables,
          ...Object.fromEntries(result.folders.map((f) => [f.typed_id, f])),
        };

        return ({
          folderables: newFolderables,
        });
      });
    });
  };

  loadAttachments = (showLoadingIndicator = false) => {
    this.setState({
      selectedAttachments: [],
      loadingAttachments: showLoadingIndicator,
    });

    const storedGid = this.state.selectedFolderableTypedId;

    getAsync(`/folderables/${storedGid}/attachments`).then((result) => {
      if (this.state.selectedFolderableTypedId === storedGid) {
        this.setState({
          attachments: result.attachments,
          loadingAttachments: false,
          selectedAttachments: [],
        });
      }
    });
  };

  getFolderable = (folderableTypedId) => {
    return this.state.folderables[folderableTypedId];
  };

  getSubFolderables = (parentFolderableTypedId) => {
    const id = getFolderableId(parentFolderableTypedId);
    const type = getFolderableType(parentFolderableTypedId);

    return Object.values(this.state.folderables)
      .filter(f => (f.folderable_type === type && f.folderable_id === id));
  };

  handlePopState = (e) => {
    if (e.state && e.state.selectedFolderableTypedId) {
      this.setSelectedFolderableTypedId(e.state.selectedFolderableTypedId);
    }
  };

  handleFolderableSelect = (folderableTypedId) => {
    this.setSelectedFolderableTypedId(folderableTypedId);
    window.history.pushState({ selectedFolderableTypedId: folderableTypedId }, null, `/folderables/${folderableTypedId}`);
  };

  handleDragEndFolder = ({
    draggableId,
    draggableType,
    droppableId,
    droppableType,
  }) => {
    const oldFolder = { ...this.getFolderable(`${draggableId}-${draggableType}`) };

    const newFolder = {
      ...oldFolder,
      folderable_type: droppableType,
      folderable_id: droppableId,
    };

    this.handleFolderUpdated(newFolder);

    updateAsync(`/folders/${draggableId}`, {
      folder: newFolder,
    }).then((updatedFolder) => {
      this.handleFolderUpdated(updatedFolder);
    }).catch((error) => {
      // Revert changes to state
      this.handleFolderUpdated(oldFolder);
      flashError(error.errors.join(','));
    });
  };

  handleDragEndAttachment = async ({
    draggableId,
    droppableId,
    droppableType,
  }) => {
    for(const file of this.state.selectedAttachments) {
      if (file.attachable_id === Number(droppableId)) {
        // same folder… skip
        continue;
      }

      const storedAttachment = this.state.attachments.find(a => a.id === file.id);

      try {
        await updateAsync(`/attachments/${file.id}`, {
          attachment: {
            attachable_type: droppableType,
            attachable_id: droppableId,
          },
        });

        // just update view if file is included in the current attachments 
        if (storedAttachment && this.state.attachments.filter(f => f.id === storedAttachment.id).length > 0) {
          // remove file from view
          this.setState(prevState => ({
            attachments: prevState.attachments.filter(f => (f && storedAttachment && f.id !== storedAttachment.id)),
          }));
        }
      } catch (error) {
        // Revert changes to state
        this.setState(prevState => ({
          attachments: [...prevState.attachments, storedAttachment],
        }));

        flashError(error.errors?.join(',') || error.message);
      }
    }

    this.setState({
      selectedAttachments: [],
    });

    this.loadChildren(this.props.folderableTypedId);
    this.loadChildren(this.props.rootFolderableTypedId);

    // if file(s) are moved and instantly during the folder is changed,
    // the files are not in sync anymore -> reload files
    this.loadAttachments();
  };

  handleDragEnd = (result) => {
    this.setState({
      isDragging: false,
    })
    
    if (!result.destination) {
      return;
    }

    if (result.draggableId === result.destination.droppableId) {
      return;
    }

    if (result.destination.droppableId === 'file-area') {
      return;
    }

    const draggable = result.draggableId.split('-');
    const droppable = result.destination.droppableId.split('-');

    const dragInfo = {
      draggableId: draggable[0],
      draggableType: draggable[1],
      droppableId: droppable[0],
      droppableType: droppable[1],
    };

    if (dragInfo.draggableType === dragInfo.droppableType
      && dragInfo.droppableId === dragInfo.draggableId) {
      return;
    }

    if (dragInfo.draggableType === 'Folder') {
      this.handleDragEndFolder(dragInfo);
    } else if (dragInfo.draggableType === 'File') {
      this.handleDragEndAttachment(dragInfo);
    }
  };

  handleDragStart = (ev) => {
    // select the dragging file of no specific file is selected
    if (this.state.selectedAttachments.length === 0) {
      let selectedAttachment = this.state.attachments.filter(f => f.id === Number(ev.draggableId.replace('-File', '')))[0]

      if (selectedAttachment) {
        this.setState({
          selectedAttachments: [selectedAttachment],
        })
      }
    }

    this.setState({
      isDragging: true,
    })
  }

  selectRangeOfAttachments = (mode = 'only', file = null) => {
    if (!['all', 'none', 'toggle', 'between', 'only'].includes(mode)) {
      throw Error(`mode is not defined`);
    }

    let selectedAttachments;
    
    if (mode === 'none') {
      selectedAttachments = [];
    }

    if (mode === 'all') {
      selectedAttachments = [...this.state.attachments];
    }

    if (mode === 'only') {
      if (!file) return;
      selectedAttachments = [file];
    }

    if (mode === 'toggle') {
      if (!file) return;

      if (!this.state.selectedAttachments.find(f => f.id === file.id)) {
        selectedAttachments = this.state.attachments.filter((a) => {
          return (a.id === file.id || this.state.selectedAttachments.find(f => f.id === a.id));
        });
      } else {
        selectedAttachments = this.state.selectedAttachments.filter(f => f.id !== file.id);
      }
    }

    if (mode === 'between') {
      if (!file) return;

      if (!this.state.selectedAttachments.length) {
        selectedAttachments = [file];
      } else {
        let lastSelectedAttachment = this.state.selectedAttachments.slice(-1)[0];
        let firstSelectedAttachment = this.state.selectedAttachments[0];
        let indexOfLastSelectedAttachment = this.state.attachments.findIndex(f => f.id === lastSelectedAttachment.id);
        let indexOfFirstSelectedAttachment = this.state.attachments.findIndex(f => f.id === firstSelectedAttachment.id);
        let indexOfClickedFile = this.state.attachments.findIndex(f => f.id === file.id);
  
        selectedAttachments = this.state.attachments.filter((a, i) => {
          if (this.state.selectedAttachments.find((f => f.id === a.id))) {
            return true;
          }
  
          if (i > indexOfLastSelectedAttachment && i <= indexOfClickedFile) {
            return true;
          }

          if (i < indexOfFirstSelectedAttachment && i >= indexOfClickedFile) {
            return true;
          }
        });
      }
    }

    this.setState({ selectedAttachments });
  }

  render() {
    return (
      <UploaderProvider>
        <UploadManager onFileAdded={this.handleFileAdded}>
          <DragDropContext onDragEnd={this.handleDragEnd} onDragStart={this.handleDragStart}>
            <div className="file-browser-layout" onMouseMove={this.handleMouseMove}>
              <div className="folder-tree-column">
                <FolderTree
                  totalFileSize={this.props.totalFileSize}
                  rootFolderableTypedId={this.props.rootFolderableTypedId}
                  selectedFolderableTypedId={this.state.selectedFolderableTypedId}
                  getFolderable={this.getFolderable}
                  getSubFolderables={this.getSubFolderables}
                  loadChildren={this.loadChildren}
                  onFolderableSelect={this.handleFolderableSelect}
                  stickyTopSpace={this.props.stickyTreeTopSpace}
                />
              </div>
              <div className={['folderable-content-column', this.state.isDragging ? 'dragging' : ''].filter(v => !!v).join(' ')}>
                <FileBrowser
                  selectedFolderableTypedId={this.state.selectedFolderableTypedId}
                  selectedAttachments={this.state.selectedAttachments}
                  attachments={this.state.attachments}
                  loadingAttachments={this.state.loadingAttachments}
                  getFolderable={this.getFolderable}
                  editable={this.props.editable}
                  isDragging={this.state.isDragging}
                  getSubFolderables={this.getSubFolderables}
                  onFolderableSelect={this.handleFolderableSelect}
                  onFolderAdded={this.handleFolderAdded}
                  onFolderUpdated={this.handleFolderUpdated}
                  onFolderDeleted={this.handleFolderDeleted}
                  onFileUpdated={this.handleFileUpdated}
                  onFilesDeleted={this.handleFilesDeleted}
                  onLayoutSwitch={this.handleLayoutSwitch}
                  selectRangeOfAttachments={this.selectRangeOfAttachments}
                  onFileAdded={this.handleFileAdded}
                  layout={this.state.layout}
                />
              </div>
            </div>
          </DragDropContext>
        </UploadManager>
      </UploaderProvider>
    );
  }
}

FileBrowserContainer.propTypes = {
  folderableTypedId: PropTypes.string.isRequired,
  rootFolderableTypedId: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  totalFileSize: PropTypes.number.isRequired,
  stickyTreeTopSpace: PropTypes.string,

};

FileBrowserContainer.contextTypes = {
  uploader: PropTypes.object,
};

FileBrowserContainer.defaultProps = {
  stickyTreeTopSpace: "0",
};

export default FileBrowserContainer;

import { Controller } from "@hotwired/stimulus"
import { flashAlert, flashError, flashNotice } from '../helpers/flash_helper';

export default class extends Controller {

  static values = {
    notice: String,
    error: String,
    alert: String,
  }

  connect() {
    if (this.noticeValue) flashNotice(this.noticeValue)
    if (this.errorValue) flashAlert(this.errorValue)
    if (this.alertValue) flashError(this.alertValue)
  }
}

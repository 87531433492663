import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getAsync } from '../../helpers/rails_helper';

import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import emailSelectStyles from '../../styles/email_select_styles';

import { searchText } from '../../helpers/helpers';

const filterOption = (option, searchTerm) => {
  const text = `${option.data.value} ${option.data.fullname} ${option.data.address} ${option.data.label_to_show}`;
  return searchText(text, searchTerm);
};

const NoComp = () => {
  return null;
};

const Control = props => (
  <components.Control {...props}>
    <span style={{ color: '#777', padding: '6px 0', display: 'inline-block' }}>
      {props.selectProps.preText}
    </span>
    {props.children}
  </components.Control>
);

const Option = (props) => {
  // Benutzer
  if (props.data.recipient_type === 'User') {
    return (
      <components.Option {...props}>
        <span className="fullname_with_avatar">
          <span className="avatar-wrapper avatar-wrapper-mini avatar-link-with-fullname">
            <img src={props.data.avatar.thumb.url} alt={props.data.fullname} className="avatar avatar-mini" />
          </span>
          {props.data.fullname}
        </span>
      </components.Option>
    );
  }

  // Kontakt
  if (props.data.recipient_type === 'Contact') {
    return (
      <components.Option {...props}>
        {props.data.address} {props.data.label_to_show ? `(${props.data.label_to_show})` : ''}
      </components.Option>
    );
  }

  // Manuelle Email-Adresse (creatable)
  return (
    <components.Option {...props}>
      {props.data.value}
    </components.Option>
  );
};

const MultiValue = (props) => {
  // Benutzer
  if (props.data.recipient_type === 'User') {
    return (
      <components.MultiValue {...props}>
        <span className="fullname_with_avatar">
          <span className="avatar-wrapper avatar-wrapper-mini avatar-link-with-fullname">
            <img src={props.data.avatar.thumb.url} alt={props.data.fullname} className="avatar avatar-mini" />
          </span>
          {props.data.fullname}
        </span>
      </components.MultiValue>
    );
  }

  // Kontakt
  if (props.data.recipient_type === 'Contact') {
    return (
      <components.MultiValue {...props}>
        {props.data.address}
      </components.MultiValue>
    );
  }

  // Manuelle Email-Adresse (creatable)
  return (
    <components.MultiValue {...props}>
      {props.data.value}
    </components.MultiValue>
  );
};

class EmailMultiSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      client: null,
      users: [],
      loading: true,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.loadOptions();
  }

  loadOptions() {
    this.setState({ loading: true });

    getAsync(this.props.optionsUrl).then((result) => {
      const ret = [];

      if (result.client && result.client.emails.length) {
        ret.push({
          label: `Kunde (${result.client.fullname})`,
          options: result.client.emails.map(e => ({
            address: e.address,
            value: e.address,
            label_to_show: e.label_to_show,
            id: e.id,
            email: e.address,
            recipient_id: result.client.id,
            recipient_type: 'Contact',
            preferred_for_sending: e.preferred_for_sending,
          })),
        });
      }

      if (result.users.length) {
        ret.push({
          label: 'Benutzer',
          options: result.users.map(u => ({
            fullname: u.fullname,
            avatar: u.avatar,
            id: u.id,
            value: u.email,
            email: u.email,
            recipient_id: u.id,
            recipient_type: 'User',
          })),
        });
      }

      this.setState({
        options: ret,
        loading: false,
      });

      if (this.props.preselectPreferredClientEmail && result.client && result.client.emails.length) {
        const preferredAddress = ret[0].options.find(option => option.preferred_for_sending);

        if (preferredAddress) {
          this.props.onChange([preferredAddress]);
        } else {
          this.props.onChange([ret[0].options[0]]);
        }
      }
    });
  }

  handleChange(selected) {
    this.props.onChange(selected || []);
  }

  handleCreate = (inputValue) => {
    const { options } = this.state;
    const newOption = {
      value: inputValue,
      label: inputValue,
      email: inputValue,
    };

    this.setState({
      options: [...options, newOption],
    });

    this.props.onChange([...this.props.value, newOption]);
  }

  render() {
    return (
      <CreatableSelect
        value={this.props.value}
        isMulti
        isLoading={this.state.loading}
        isClearable={false}
        filterOption={filterOption}
        onCreateOption={this.handleCreate}
        components={{
          Control,
          Option,
          MultiValue,
          IndicatorSeparator: NoComp,
          DropdownIndicator: NoComp,
        }}
        styles={emailSelectStyles}
        options={this.state.options}
        onChange={this.handleChange}
        className="basic-multi-select"
        classNamePrefix="select"
        preText={this.props.preText}
        placeholder=""
        loadingPlaceholder="Laden..."
        noOptionsMessage={() => 'Keine Ergebnisse'}
        loadingMessage={() => 'Laden...'}
      />
    );
  }
}

EmailMultiSelect.propTypes = {
  value: PropTypes.array.isRequired,
  preText: PropTypes.string,
  optionsUrl: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  noResultText: PropTypes.string,
  preselectFirstClientEmail: PropTypes.bool,
};

export default EmailMultiSelect;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Droppable } from 'react-beautiful-dnd';

class FolderBreadcrumbs extends Component {
  handleClick = (e, breadcrumb) => {
    e.preventDefault();
    this.props.onFolderableSelect(breadcrumb.typed_id);
  };

  getParentFolderables = (gid) => {
    const folderable = this.props.getFolderable(gid);

    if (folderable) {
      const parentFolderables = this.getParentFolderables(folderable.parent_typed_id);
      return [].concat(...[folderable, parentFolderables]);
    }

    return [];
  };

  getBreadcrumbs = () => {
    if (this.props.selectedFolderableTypedId) {
      return this.getParentFolderables(this.props.selectedFolderableTypedId).reverse();
    }

    return [];
  };

  renderList(breadcrumbs) {
    const style = {
      maxWidth: `${100 / breadcrumbs.length}%`,
    };

    return breadcrumbs.map((breadcrumb, i) => {
      const key = breadcrumb.typed_id

      return (
        <div style={style} className="folder-breadcrumb" key={key}>
          <Droppable
            droppableId={key}
            isDropDisabled={breadcrumbs.length === i + 1}>
            {(provided, snapshot) => (
              <a
                href="#"
                onClick={(e) => this.handleClick(e, breadcrumb)}
                ref={provided.innerRef}
                className={snapshot.isDraggingOver ? 'dragging-over' : ''}>
                {breadcrumb.name}
              </a>
            )}
          </Droppable>
        </div>
      );
    });
  }

  renderBreadcrumbs() {
    const breadcrumbs = this.getBreadcrumbs();

    if (breadcrumbs.length > 1) {
      return this.renderList(breadcrumbs);
    }

    if (!breadcrumbs[0]) {
      return null;
    }

    return (
      <div className="folder-breadcrumb">
        {breadcrumbs[0].name}
      </div>
    );
  }

  render() {
    return (
      <div className="folder-breadcrumbs">
        {this.renderBreadcrumbs()}
      </div>
    );
  }
}

FolderBreadcrumbs.propTypes = {
  getFolderable: PropTypes.func.isRequired,
  selectedFolderableTypedId: PropTypes.string.isRequired,
  onFolderableSelect: PropTypes.func.isRequired,
};

export default FolderBreadcrumbs;

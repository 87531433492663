import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TodoPreviewComment from './TodoPreviewComment';
import TodoPreviewCommentForm from './TodoPreviewCommentForm';

import { pluralize } from '../../helpers/helpers';

class TodoPreviewComments extends Component {
  renderComments() {
    return this.props.todo.recent_comments.map((comment, i) => (
      <TodoPreviewComment
        onEdit={this.props.onEdit}
        todoId={this.props.todo.id}
        comment={comment}
        key={i}
      />
    ));
  }

  renderOlderComments() {
    const diff = this.props.todo.comments_count - this.props.todo.recent_comments.length;

    if (diff <= 0) return null;

    return (
      <a href={`/todos/${this.props.todo.id}`}>
        {diff} {pluralize(diff, 'älterer Kommentar', 'ältere Kommentare')}
      </a>
    );
  }

  render() {
    if (!this.props.commentable && !this.props.todo.recent_comments.length) return null;

    return (
      <div className="todo-preview-comments">
        {this.renderOlderComments()}
        {this.renderComments()}
        <TodoPreviewCommentForm {...this.props} visible={this.props.commentable} />
      </div>
    );
  }
}

TodoPreviewComments.propTypes = {
  todo: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  commentable: PropTypes.bool.isRequired,
};

export default TodoPreviewComments;

import React from 'react';
import PropTypes from 'prop-types';

const SearchResultLink = (props) => {
  const selectedClass = props.selected ? 'search-result-selected' : '';

  const handleMouseOver = (e) => {
    props.onMouseOver(props.index);
  };

  return (
    <a className={`search-result ${selectedClass}`}
      href={props.href} onMouseOver={handleMouseOver}>
      {props.children}
    </a>
  );
};

SearchResultLink.propTypes = {
  href: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  children: PropTypes.node,
  onMouseOver: PropTypes.func,
  index: PropTypes.number,
};

export default SearchResultLink;

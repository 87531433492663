import React, { Component } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

const LOCALE = 'de';

const getLastWeekInYear = () => {
  let last = moment().endOf('year').locale(LOCALE).week();

  // Falls nach deutscher Zeitrechung die letzte Woche bereits
  // die erste des neuen Jahres ist, eine Woche zurückgehen und
  // den Wert zurückgeben
  if (last === 1) {
    last = moment().endOf('year').subtract(1, 'week').locale(LOCALE).week();
  }

  return last;
};

const getCurrentWeek = () => {
  const currentYear = moment().year();
  const currentWeek = moment().locale(LOCALE).week() + 1;
  return `${currentYear}-${currentWeek}`;
};

class WeekSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.initialValue,
    };
  }

  handleChange = (e) => {
    if (this.props.onChange) {
      this.setState({ value: e.target.value });
      this.props.onChange(e.target.value);
    }
  };

  handleCurrentWeekClick = (e) => {
    e.preventDefault();

    const value = getCurrentWeek();

    this.setState({ value });
    this.props.onChange(value);
  };

  renderEmptyOption() {
    return (
      <option value="">{this.props.placeholder}</option>
    );
  }

  renderOptions() {
    const firstYear = this.props.firstYear || moment().year() - 1;

    return Array(this.props.years).fill().map((v, y) => {
      const weeks = getLastWeekInYear();
      return (
        <optgroup key={`week-group-${y}`} label={firstYear + y}>
          {Array(weeks).fill().map((v2, w) => {
            return (
              <option key={`week-${y}-${w}`} value={`${firstYear + y}-${w + 1}`}>
                KW {w + 1} / {firstYear + y}
              </option>
            );
          })}
        </optgroup>
      );
    });
  }

  render() {
    /* eslint-disable no-unused-vars, prefer-const */
    const { initialValue, placeholder, ...rest } = this.props;
    /* eslint-enable */

    return (
      <div>
        <div>
          <select
            {...rest}
            defaultValue={this.state.value}
            onChange={this.handleChange}>
            {this.renderEmptyOption()}
            {this.renderOptions()}
          </select>
        </div>
        <div className="current-week-quick-select">
          <a href="#" onClick={this.handleCurrentWeekClick}>Aktuelle Woche</a>
        </div>
      </div>
    );
  }
}

WeekSelector.propTypes = {
  years: PropTypes.number,
  firstYear: PropTypes.number,
  onChange: PropTypes.func,
  initialValue: PropTypes.string,
  placeholder: PropTypes.string,
};

WeekSelector.defaultProps = {
  years: 3,
};

export default WeekSelector;

import React from 'react';
import Icon from '../shared/Icon';

import { components } from 'react-select';

const ProjectStateOption = (props) => {
  return (
    <components.Option {...props}>
      <Icon name="circle" color={props.data.color} /> {props.data.state}
      {props.data.deprecated  ? (
        <span style={{ marginLeft: 6 }} className="badge bg-secondary">veraltet</span>
      ) : null}
    </components.Option>
  );
};

export default ProjectStateOption;

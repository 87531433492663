import React, { Component, Fragment } from 'react';

import PropTypes from 'prop-types';

import Label from '../shared/Label';
import TagSelector from './TagSelector';

import HiddenInputWrapper from '../shared/HiddenInputWrapper';

class TagMultiSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: this.props.tags,
    };
  }

  handleAdd = (tag) => {
    this.setState({
      tags: [...this.state.tags, tag],
    });
  }

  handleRemove = (tag) => {
    this.setState({
      tags: this.state.tags.filter(t => (t.id !== tag.id)),
    });
  }

  renderTags() {
    return this.state.tags.map(tag => (
      <HiddenInputWrapper
        name={`${this.props.name}[]`}
        title={tag.title}
        desc={tag.desc}
        color={tag.color}
        textColor={tag.text_color}
        data={tag}
        value={tag.id}
        comp={Label}
        key={tag.id}
        removable
        onRemove={this.handleRemove} />
    ));
  }

  renderEmptyHiddenInput() {
    return (
      <input type="hidden" name={`${this.props.name}[]`} value="" />
    );
  }

  renderSeperator = () => {
    if (this.state.tags.length) {
      return (
        <span className="seperator">|</span>
      );
    }
    return null;
  }

  render() {
    const selectedTagIds = this.state.tags.map(t => t.id);
    const tagOptions = this.props.tagOptions.filter(t => !selectedTagIds.includes(t.id));

    return (
      <Fragment>
        {this.renderEmptyHiddenInput()}
        {this.renderTags()}
        {this.renderSeperator()}
        <TagSelector
          excludedTagIds={this.state.tags.map(t => t.id)}
          buttonText={this.state.tags.length ? 'Tag' : 'Tag hinzufügen'}
          onAdd={this.handleAdd}
          options={tagOptions} />
      </Fragment>
    );
  }
}

TagMultiSelect.propTypes = {
  tags: PropTypes.array,
  tagOptions: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
};

export default TagMultiSelect;

import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../shared/Icon';

const MembershipItem = (props) => {
  return (
    <div style={{ display: 'flex', padding: '3px 0' }}>
      {props.icon ? (
        <div style={{ width: 24, textAlign: 'center' }}>
          <Icon name={props.icon} />
        </div>
      ) : null}
      <div style={{ flex: 1, flexGrow: 1 }}>
        {props.children}
      </div>
    </div>
  );
};

MembershipItem.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.node,
};

MembershipItem.defaultProps = {
  icon: null,
  children: null,
};

export default MembershipItem;

import React, { Component, Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { Turbo } from "@hotwired/turbo-rails"

import WorkflowTemplateSelector from '../WorkflowTemplates/WorkflowTemplateSelector';

import { getAsync, postAsync } from '../../helpers/rails_helper';

class NewWorkflowForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedType: 'empty',
      loading: true,
      workflowTemplates: [],
      saving: false,
      title: '',
    };
  }

  componentDidMount() {
    getAsync(`/${this.props.organizationSlug}/workflow_templates.json?addable_by_me=1`).then((result) => {
      this.setState({
        loading: false,
        workflowTemplates: result.workflow_templates,
      });
    });
  }

  createWorkflow = (template) => {
    this.setState({
      saving: true,
    });

    postAsync(`/projects/${this.props.projectId}/workflows`, {
      workflow: {
        workflow_template_id: this.state.selectedTemplate && this.state.selectedTemplate.id,
        title: (template && template.title) || 'Todo-Liste',
        workflow_type: (this.state.selectedTemplate && this.state.selectedTemplate.workflow_type) || 'todolist',
      },
    }).then(() => {
      Turbo.visit(`/projects/${this.props.projectId}/workflows`);
    }).catch((err) => {
      this.setState({
        saving: false,
      });
      alert(err);
    });
  }

  handleInputChange = (e) => {
    this.setState({
      title: e.target.value,
    });
  }

  handleTemplateChange = (selectedTemplate) => {
    this.setState({ selectedTemplate });
  }

  handleSaveClick = (e) => {
    e.preventDefault();
    if (this.state.title.trim()) {
      this.setState({ selectedTemplate: null }, () => {
        this.createWorkflow({ title: this.state.title.trim() });
      });
    }
  }

  handleSaveTemplateClick = (e) => {
    e.preventDefault();
    if (this.state.selectedTemplate) {
      this.createWorkflow();
    }
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.handleSaveClick(e);
    }
  };

  handleClick = (template) => {
    this.createWorkflow(template);
  }

  render() {
    return (
      <div className={`new-workflow-modal ${this.state.saving ? 'box-loading' : ''}`}>
        <div>
          <label className="form-label">Todo Liste</label>
        </div>
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            autoFocus
            placeholder="Name der Liste"
            onChange={this.handleInputChange}
            value={this.state.title}
            onKeyDown={this.handleKeyDown}
          />
          <button
            className="btn btn-success"
            disabled={!this.state.title.trim()}
            onClick={this.handleSaveClick}>
            Erstellen
          </button>
        </div>

        <div style={{ borderTop: '1px solid var(--bs-border-color)', marginTop: 20, marginLeft: -10, marginRight: -10 }}>
          <div style={{ margin: 10 }}>
            <label className="form-label">Workflow von Vorlage</label>
            <div style={{ display: 'flex' }}>
              <div style={{ flexGrow: 1, marginRight: 10 }}>
                <WorkflowTemplateSelector
                  placeholder="Bitte Vorlage auswählen"
                  optionsUrl={`/${this.props.organizationSlug}/workflow_templates.json?addable_by_me=1`}
                  onChange={this.handleTemplateChange}
                />
              </div>
              <div>
                <button
                  className="btn btn-success"
                  disabled={!this.state.selectedTemplate}
                  onClick={this.handleSaveTemplateClick}>
                  Hinzufügen
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

NewWorkflowForm.propTypes = {
  userId: PropTypes.number.isRequired,
  projectId: PropTypes.number.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default NewWorkflowForm;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';

class ActionDropdown extends Component {
  handleClick = (e, action) => {
    e.preventDefault();
    this.props.onChange(action);
  };

  renderUnavailable = () => {
    return (
      <a className="btn btn-outline-dark btn-md disabled" disabled>
        <Icon name="times" fixedWidth /> nicht verfügbar
      </a>
    );
  };

  renderSingleButton() {
    const action = this.props.actions[0];

    const classes = ['btn', 'btn-success', 'btn-md'];

    if (this.props.loadingEffects) {
      classes.push("btn-loading");
    }

    return (
      <a href="#" className={classes.join(' ')} onClick={e => this.handleClick(e, action)}>
        <Icon name={action.icon} fixedWidth /> {action.name}
      </a>
    );
  }

  renderDropdownItems() {
    return this.props.actions.map(action => (
      <li key={action.id}>
        <a href="#" className="dropdown-item" onClick={e => this.handleClick(e, action)}>
          <Icon name={action.icon} color={action.color} fixedWidth /> {action.name}
        </a>
      </li>
    ));
  }

  renderDropdown() {
    const classes = ['btn', 'btn-md', 'dropdown-toggle', this.props.className];

    if (this.props.loadingEffects) {
      classes.push("btn-loading");
    }

    return (
      <div className="dropdown" style={{ display: 'inline-block' }}>
        <button className={classes.join(' ')} type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
          <span className="action-button-title" id="action-button-title-none">
            Aktion wählen
          </span> <span className="caret" />
        </button>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
          {this.renderDropdownItems()}
        </ul>
      </div>
    );
  }

  render() {
    if (this.props.actions.length === 0) return this.renderUnavailable();
    if (this.props.actions.length === 1) return this.renderSingleButton();

    return this.renderDropdown();
  }
}

ActionDropdown.propTypes = {
  actions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  loadingEffects: PropTypes.bool.isRequired,
  className: PropTypes.string
};

ActionDropdown.defaultProps = {
  className: '',
};

export default ActionDropdown;

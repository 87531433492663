import React from 'react';
import PropTypes from 'prop-types';

import TagSelectorDropdown from '../../Tags/TagSelectorDropdown';

class RemoveTagEffectForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleTagChange = this.handleTagChange.bind(this);
  }

  handleTagChange(newTag) {
    this.props.onChange(Object.assign({}, this.props.record, {
      tag: newTag,
      tag_id: newTag && newTag.id,
    }));
  }

  render() {
    return (
      <div className="mb-3">
        <label className="form-label">Tag entfernen</label>
        <TagSelectorDropdown
          optionsUrl={`/${this.props.organizationSlug}/tags.json`}
          value={this.props.record.tag}
          onChange={this.handleTagChange}
        />
      </div>
    );
  }
}

RemoveTagEffectForm.propTypes = {
  record: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default RemoveTagEffectForm;

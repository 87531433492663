/* global document, CustomEvent */
import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '../shared/Icon';
import UploaderProvider from '../Uploader/UploaderProvider';

import ComposeDropzone from '../Todos/ComposeDropzone';
import ComposeAttachments from '../Todos/ComposeAttachments';

import Tooltip from '../shared/Tooltip';

import MiniFileBrowser from '../FileBrowser/MiniFileBrowser';

class AttachmentMultiUploadField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      attachments: props.initial_attachments || [], // selected existing files from mini browser
      attachmentsToBeDeleted: [], // attachments to be deleted
      fileBrowserOpen: false,
    };
  }

  getDropzoneRef = (node) => {
    this.dropzoneRef = node;
  }

  handleUploadsFinished = (uploads) => {
    // Nur Uploads hinzufügen, die noch nicht in der Liste sind
    this.setState((prevState) => ({
      attachments: [
        ...prevState.attachments,
        ...uploads.filter(u => !prevState.attachments.find(u2 => u2.id === u.id)),
      ],
    }));
  }

  handleOpenFileBrowser = (e) => {
    e.preventDefault();
    this.setState({ fileBrowserOpen: true });
  }

  handleCloseFileBrowser = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ fileBrowserOpen: false });
  }

  handleChooseFileClick = (e) => {
    e.preventDefault();
    this.dropzoneRef.open();
  }

  handleSelectAttachments = (attachments) => {
    // filter out attachments already present in the list
    // create new object with empty id, so that rails creates new records
    this.setState((prevState) => ({
      fileBrowserOpen: false,
      attachments: [
        ...prevState.attachments,
        ...attachments
          .filter(f => !prevState.attachments.find(f2 => f2.document_id === f.document_id))
          .map((a) => Object.assign({}, { ...a }, { id: null }))
      ],
    }));
  }

  handleRemoveAttachment = (attachment) => {
    this.setState((prevState) => ({
      attachments: prevState.attachments.filter(a => a.document_id !== attachment.document_id),
    }));

    if (attachment.id) {
      this.setState((prevState) => ({
        attachmentsToBeDeleted: [...prevState.attachmentsToBeDeleted, attachment],
      }));
    }
  }

  renderHiddenFields() {
    return (
      <>
        {this.state.attachments.map((attachment) => (
          <>
            <input
              key={attachment.document_id}
              type="hidden"
              value={attachment.id || ''}
              name={`${this.props.name}[][id]`}
            />
            <input
              key={attachment.sgid}
              type="hidden"
              value={attachment.sgid}
              name={`${this.props.name}[][document_sgid]`}
            />
          </>
        ))}
        {this.state.attachmentsToBeDeleted.map((attachment) => (
          <>
            <input
              key={attachment.document_id}
              type="hidden"
              value="true"
              name={`${this.props.name}[][_destroy]`}
            />
            <input
              key={attachment.id}
              type="hidden"
              value={attachment.id}
              name={`${this.props.name}[][id]`}
            />
          </>
        ))}
      </>
    )

    return ;
  }

  render() {
    return (
      <>
        {this.renderHiddenFields()}
        <div className="attachment-multi-upload-field">
          <UploaderProvider>
            <ComposeDropzone onUploadsFinished={this.handleUploadsFinished} getDropzoneRef={this.getDropzoneRef}>
              <div>
                <ComposeAttachments
                  onRemove={this.handleRemoveAttachment}
                  attachments={this.state.attachments}
                  style={{ borderBottom: '1px dashed var(--bs-border-color)', padding: 4 }}
                  />
                <div className="compose-footer">
                  <div className="compose-footer-info">
                    <div style={{ flexGrow: 1 }}>
                      <span className="d-none d-lg-inline">Dateien hierher ziehen oder </span>
                      <a href="#" onClick={this.handleChooseFileClick}>
                        <span className="d-none d-lg-inline">hochladen</span>
                        <span className="d-lg-none">Dateien hochladen</span>
                      </a>
                    </div>
                    <div className="d-flex">
                      <Tooltip title="Dateien hochladen" placement="top">
                        <a href="" className="compose-footer-icon-button" onClick={this.handleChooseFileClick}>
                          <Icon name="cloud-upload" />
                        </a>
                      </Tooltip>
                      {this.props.project_id ? (
                        <>
                          <Tooltip title="Dokumente einfügen" placement="top">
                            <a href="" className="compose-footer-icon-button" onClick={this.handleOpenFileBrowser}>
                              <Icon name="folder" />
                            </a>
                          </Tooltip>
                          <MiniFileBrowser
                            project_id={this.props.project_id}
                            visible={this.state.fileBrowserOpen}
                            onClose={this.handleCloseFileBrowser}
                            onSelect={this.handleSelectAttachments}
                          />
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </ComposeDropzone>
          </UploaderProvider>
        </div>
      </>
    );
  }
}

AttachmentMultiUploadField.propTypes = {
  project_id: PropTypes.number,
  name: PropTypes.string.isRequired,
};

export default AttachmentMultiUploadField;

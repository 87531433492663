import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Label from '../shared/Label';
import TagGroupSelect from './TagGroupSelect';

import Tooltip from '../shared/Tooltip';

class Tag extends Component {
  handleRemove = (e, shouldConfirm = true) => {
    if (e) e.preventDefault();

    const confirmRemoveMessage = `Bist du sicher, dass du den Tag '${this.props.tag.title}' entfernen möchtest?`;

    if (shouldConfirm) {
      if (confirm(confirmRemoveMessage)) {
        this.props.onRemove(this.props.tag);
      }
    } else {
      this.props.onRemove(this.props.tag);
    }
  }

  renderLabel() {
    return (
      <Label
        data={this.props.tag}
        removable={this.props.editable}
        onRemove={this.handleRemove}
        title={this.props.tag.full_title}
        desc={this.props.tag.desc}
        color={this.props.tag.color}
        textColor={this.props.tag.text_color}
      />
    );
  }

  renderButton() {
    const style = {
      fontWeight: 'bold',
      color: this.props.tag.text_color,
      border: `1px solid ${this.props.tag.color}`,
      backgroundColor: this.props.tag.color,
      marginRight: 5,
    };

    return (
      <Tooltip enabled={!!this.props.tag.desc} title={this.props.tag.desc} placement="top">
        <button onClick={this.handleRemove} className="btn btn-light btn-label" style={style}>
          {this.props.tag.full_title}
          <span className="label-x">×</span>
        </button>
      </Tooltip>
    );
  }

  renderGroup() {
    return (
      <TagGroupSelect
        onRemoveClick={this.handleRemove}
        tag={this.props.tag}
        group={this.props.tag.group}
        optionsUrl={this.props.optionsUrl}
        onSelect={newTag => this.props.onReplace(this.props.tag, newTag)}
      />
    );
  }

  render() {
    if (this.props.editable && this.props.tag.group) {
      return this.renderGroup();
    } else if (this.props.editable) {
      return this.renderButton();
    }

    return this.renderLabel();
  }
}

Tag.propTypes = {
  tag: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  onRemove: PropTypes.func,
  onReplace: PropTypes.func,
  optionsUrl: PropTypes.string.isRequired,
};

export default Tag;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconSelector from '../shared/IconSelector';
import ColorSelector from '../shared/ColorSelector';
import ProjectStateSelectAsync from '../ProjectStates/ProjectStateSelectAsync';

class ActionTemplateForm extends Component {
  handleColorChange = (color) => {
    this.props.onChange(
      Object.assign({}, this.props.record, { color }),
    );
  }

  handleIconChange = (icon) => {
    this.props.onChange(
      Object.assign({}, this.props.record, { icon }),
    );
  }

  handleNameChange = (e) => {
    const newRecord = Object.assign({}, this.props.record, { name: e.target.value });

    if (this.props.workflowType === 'checklist') {
      newRecord.name_fired = newRecord.name;
    }

    this.props.onChange(newRecord);
  }

  handleNameFiredChange = (e) => {
    this.props.onChange(
      Object.assign({}, this.props.record, { name_fired: e.target.value }),
    );
  }

  handleCompleteChange = (e) => {
    this.props.onChange(
      Object.assign({}, this.props.record, { complete_todo: !this.props.record.complete_todo }),
    );
  }

  handleProjectStateChange = (projectState) => {
    this.props.onChange(
      Object.assign({}, this.props.record, {
        next_project_state: projectState,
        next_project_state_id: projectState && projectState.id,
      }),
    );
  }

  handleKeyDown = (e) => {
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) { // CMD + Enter
      if (this.props.onSave) {
        this.props.onSave(e);
      }
    }
  }

  renderProjectState() {
    if (this.props.workflowType !== 'workflow') return null;

    return (
      <div className="mb-3">
        <label className="form-label">Neuer Projektstatus</label>
        <ProjectStateSelectAsync
          options_url={`/${this.props.organizationSlug}/project_states.json?only_active=1`}
          placeholder="Status auswählen"
          value={this.props.record.next_project_state}
          onChange={this.handleProjectStateChange}
        />
      </div>
    );
  }

  renderCompleteTodo() {
    if (this.props.workflowType !== 'workflow') return null;

    return (
      <div className="form-check">
        <input
          type="checkbox"
          checked={this.props.record.complete_todo}
          onChange={this.handleCompleteChange}
          id="complete"
          className="form-check-input"
        />
        <label htmlFor="complete" className="form-check-label">
          Aufgabe abschließen
          <br /><small className="text-muted">Deaktiviere diese Funktion, wenn die Aufgabe beim Auslösen der Aktion nicht als erledigt markiert werden soll.</small>
        </label>
      </div>
    );
  }

  renderNameFired() {
    if (this.props.workflowType === 'checklist') return null;

    return (
      <div className="mb-3">
        <label className="form-label">Name, wenn Aktion ausgelöst wurde</label>
        <input
          className="form-control"
          placeholder="Bitte eingeben"
          onChange={this.handleNameFiredChange}
          value={this.props.record.name_fired || ''}
          onKeyDown={this.handleKeyDown}
        />
      </div>
    );
  }

  renderActionWord() {
    return this.props.workflowType === 'checklist' ? 'Antwort' : 'Aktion';
  }

  render() {
    return (
      <div>
        <div className="mb-3">
          <label className="form-label">Symbol</label>
          <IconSelector
            value={this.props.record.icon}
            onChange={this.handleIconChange}
            color={this.props.record.color}
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Farbe</label>
          <ColorSelector
            value={this.props.record.color}
            onChange={this.handleColorChange}
            colorSet="small"
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Name der {this.renderActionWord()}</label>
          <input
            autoFocus
            className="form-control"
            placeholder="Bitte eingeben"
            onChange={this.handleNameChange}
            value={this.props.record.name || ''}
            onKeyDown={this.handleKeyDown}
          />
        </div>

        {this.renderNameFired()}

        {this.renderProjectState()}
        {this.renderCompleteTodo()}

      </div>
    );
  }
}

ActionTemplateForm.propTypes = {
  record: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  organizationSlug: PropTypes.string.isRequired,
  workflowType: PropTypes.string.isRequired,
};

export default ActionTemplateForm;

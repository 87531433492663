import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

export default class extends Controller {
  static targets = ['startDatetime', 'endDatetime', 'appointmentTypeId', 'create', 'startDate', 'endDate', 'appointmentId', 'allocate', 'loadingSpinner', 'datetimeChoice', 'appointmentChoice'];

  static classes = ['focused'];

  static values = { freeInstallationAppointmentsPath: String };

  connect() {
    this.toggleCreate();
    this.toggleAllocate();
  }

  searchFreeAppointments() {
    this.loadingSpinnerTarget.classList.remove('d-none');

    this.toggleCreate();

    const params = new URLSearchParams();

    params.append('start_date', this.startDateTarget.value);
    params.append('end_date', this.endDateTarget.value);

    get(`${this.freeInstallationAppointmentsPathValue}?${params.toString()}`, {
      responseKind: 'turbo-stream',
    }).then(_response => {
      this.loadingSpinnerTarget.classList.add('d-none');
    });
  }

  startDatetimeChanged() {
    if (this.startDatetimeTarget.value) {
      this.endDatetimeTarget.min = this.startDatetimeTarget.value;
      if (!this.endDatetimeTarget.value || this.startDatetimeTarget.value > this.endDatetimeTarget.value) {
        this.endDatetimeTarget.value = this.startDatetimeTarget.value;
      }
    } else {
      this.endDatetimeTarget.value = null;
      this.endDatetimeTarget.min = null;
    }
    this.focusDatetimeChoice();
    this.clearAppointment();
    this.toggleCreate();
  }

  endDatetimeChanged() {
    this.focusDatetimeChoice();
    this.clearAppointment();
    this.toggleCreate();
  }

  appointmentChanged() {
    this.clearDatetimes();
    this.focusAppointmentChoice();
    this.toggleAllocate();
  }

  selectAppointmentType() {
    this.toggleCreate();
    this.toggleAllocate();
  }

  toggleCreate() {
    const selectedAppointmentType = this.appointmentTypeIdTargets.find(appointmentTypeBox => appointmentTypeBox.checked);
    this.createTarget.disabled = !selectedAppointmentType || !this.startDatetimeTarget.value || !this.endDatetimeTarget.value;
  }

  toggleAllocate() {
    const selectedAppointmentType = this.appointmentTypeIdTargets.find(appointmentTypeBox => appointmentTypeBox.checked);
    const selectedAppointment = this.appointmentIdTargets.find(appointmentBox => appointmentBox.checked);
    this.allocateTarget.disabled = !selectedAppointmentType || !selectedAppointment;
  }

  clearDatetimes() {
    this.endDatetimeTarget.value = null;
    this.endDatetimeTarget.min = null;
    this.startDatetimeTarget.value = null;
    this.startDatetimeTarget.min = null;
    this.toggleCreate();
  }

  clearAppointment() {
    this.appointmentIdTargets.forEach(appointmentBox => appointmentBox.checked = false);
    this.toggleAllocate();
  }

  focusDatetimeChoice() {
    this.datetimeChoiceTarget.classList.add(this.focusedClass);
    this.appointmentChoiceTarget.classList.remove(this.focusedClass);
  }

  focusAppointmentChoice() {
    this.appointmentChoiceTarget.classList.add(this.focusedClass);
    this.datetimeChoiceTarget.classList.remove(this.focusedClass);
  }
}

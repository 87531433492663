import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';

const CheckButton = ({ todo, onComplete, onReset, saving, completable }) => {
  const classNames = ['check-sub-todo'];
  let disabled = false;

  if (todo.complete) {
    classNames.push('checked');
  }

  if (!todo.active) {
    disabled = true;
    classNames.push('disabled');
  }

  if ((!completable || !todo.complete && !todo.completable) || saving || !todo.resettable) {
    disabled = true;
    classNames.push('disabled');
  }

  const handleClick = (e) => {
    e.preventDefault();
    if (todo.complete) {
      onReset();
    } else {
      onComplete();
    }
  };

  return (
    <button className={classNames.join(' ')} disabled={disabled} onClick={handleClick}>
      <Icon name="check" className="icon" />
    </button>
  );
};

CheckButton.propTypes = {
  todo: PropTypes.object.isRequired,
  completable: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  resettable: PropTypes.bool,
  saving: PropTypes.bool,
};

export default CheckButton;

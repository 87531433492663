import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import SearchResultLink from './SearchResultLink';

const SearchResultGlobal = (props) => {
  let top = null;

  if (props.resultsCount > 1) {
    top = (
      <div className="search-top-results">
        Toptreffer
      </div>
    );
  }

  return (
    <Fragment>
      <SearchResultLink {...props}>
        <div>
          <Icon name="search" />
        </div>
        <div>
          <div>
            <strong>{props.term}</strong> auf Wegateam suchen
          </div>
        </div>
      </SearchResultLink>
      {top}
    </Fragment>
  );
};

SearchResultGlobal.propTypes = {
  href: PropTypes.string.isRequired,
  selected: PropTypes.bool,
};

export default SearchResultGlobal;

import React from 'react';

const StopAllVoiceAgentsEffect = () => (
  <div className="mb-3">
    <label className="form-label">Telefonagenten stoppen</label>
    <p>
      Stoppt alle aktiven Telefonagenten.
    </p>
  </div>
);

export default StopAllVoiceAgentsEffect;

import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';

const WebhookTrigger = ({ action, trigger }) => {
  const desc = `wird ausgelöst, wenn ${trigger.webhook.sentence}`;

  return (
    <div className="trigger-details">
      <div className="trigger-details-icon">
        <Icon name={action.icon} />
      </div>
      <div className="trigger-details-text">
        <span className="trigger-details-action">{action.name}</span> {desc}
      </div>
    </div>
  );
};

WebhookTrigger.propTypes = {
  action: PropTypes.object.isRequired,
  trigger: PropTypes.object.isRequired,
};

export default WebhookTrigger;

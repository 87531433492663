/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import Avatar from '../shared/Avatar';
import AttachmentGallery from '../Attachments/AttachmentGallery';

import Icon from '../shared/Icon';
import ConfirmModal from '../shared/ConfirmModal';
import EmailRecipient from './EmailRecipient';
import { deleteEmailMessage } from '../../helpers/api';
import DeliveryStatusNotifications from './DeliveryStatusNotifications';

class EmailMessage extends Component {
  handleDeleteEmailMessage = () => {
    deleteEmailMessage(this.props.email_message)
      .then(({ email_message }) => {
        this.props.onDeleted(email_message);
      });
  };

  mayEditOrDelete = () => {
    return (
      this.props.managerOrAdmin
      || (this.props.email_message.user_id === this.props.userId && this.props.userId)
      || (this.props.email_message.owner_id === this.props.userId && this.props.userId)
    );
  };

  toRecipients = () => {
    return this.props.email_message.email_message_recipients.filter(r => (r.email_field === 'to'));
  };

  ccRecipients = () => {
    return this.props.email_message.email_message_recipients.filter(r => (r.email_field === 'cc'));
  };

  renderDeleteButton() {
    return (
      <div className="btn-group hidden-xs">
        <ConfirmModal
          target={onClick => (
            <a href="#" className="btn btn-light btn-xs" onClick={onClick}>
              <Icon name="trash"/>
            </a>
          )}
          title="Email löschen"
          cancelText="abbrechen"
          confirmText="löschen"
          confirmStyle="danger"
          onConfirmed={this.handleDeleteEmailMessage}>
          Bist du sicher, dass du die Email löschen willst?
        </ConfirmModal>
      </div>
    );
  }

  renderButtons() {
    return (
      <div className="email-message-buttons">
        <DeliveryStatusNotifications
          awsSnsDeliveryStatusNotifications={this.props.email_message.aws_sns_delivery_status_notifications}></DeliveryStatusNotifications>
        {!this.props.email_message.deleted && this.mayEditOrDelete() && this.renderDeleteButton()}
      </div>
    );
  }

  renderRecipients = (preText, recipients) => {
    if (!recipients.length) return null;

    return (
      <div className="text-muted" style={{ padding: '2px 0' }}>
        <span style={{ color: 'var(--bs-tertiary-color)' }}>{preText}:</span> {recipients.map(r => (
        <EmailRecipient recipient={r}/>
      ))
        .reduce((prev, curr) => [prev, ', ', curr])}
      </div>
    );
  };

  renderToRecipients() {
    return this.renderRecipients('An', this.toRecipients());
  }

  renderCcRecipients() {
    return this.renderRecipients('Kopie', this.ccRecipients());
  }

  renderFromName() {
    if (this.props.email_message.from_name) {
      return this.props.email_message.from_name;
    }

    return (
      <>
        <Avatar account={this.props.email_message.user}
                size="mini"/> {this.props.email_message.user.fullname}
      </>
    );
  }

  renderFrom() {
    return (
      <div className="text-muted" style={{ padding: '2px 0' }}>
        <span style={{ color: 'var(--bs-tertiary-color)' }}>Von: </span>
        <span title={this.props.email_message.from}>
          {this.renderFromName()}
        </span>
      </div>
    );
  }

  renderSubject() {
    return (
      <div style={{ fontWeight: 500, padding: '2px 0' }}>
        {this.props.email_message.subject.trim() ? (
          this.props.email_message.subject
        ) : (
          '(Kein Betreff)'
        )}
      </div>
    );
  }

  renderBody() {
    return (
      <div className="email-message-body trix-content">
        <div dangerouslySetInnerHTML={{
          __html: this.props.email_message.body_formatted,
        }}/>
      </div>
    );
  }

  renderEmailable() {
    if (this.props.email_message.deleted) return null;
    if (this.props.hideEmailable) return null;
    if (!this.props.emailable) return null;
    if (this.props.email_message.emailable_type === 'Todo') {
      return (
        <div style={{ marginTop: 10, paddingTop: 10, borderTop: '1px solid var(--bs-border-color);', fontSize: 13 }}>
          <span className="text-muted">Gesendet in: </span>
          <a href={`/todos/${this.props.email_message.emailable_id}`}>
            {this.props.email_message.emailable.text}
          </a>
        </div>
      );
    }

    return null;
  }

  renderDate() {
    return moment(this.props.email_message.created_at)
      .format('DD.MM.YYYY [um] HH:mm');
  }

  render() {
    return (
      <div className="email-message">
        {this.props.email_message.deleted ? (
          <div className="comment-body">
            <span style={{ color: 'var(--bs-tertiary-color' }}><i>Email wurde gelöscht.</i></span>
          </div>
        ) : (
          <div className="dont-break-out">
            <div style={{ display: 'flex' }}>
              <div style={{ flexGrow: 1 }}>
                {this.renderSubject()}
              </div>
              <div style={{ display: 'flex' }}>
                {this.renderButtons()}
              </div>
            </div>

            {this.renderToRecipients()}
            {this.renderCcRecipients()}

            <div style={{ display: 'flex' }}>
              <div style={{ flexGrow: 1 }}>
                {this.renderFrom()}
              </div>
              <div style={{ padding: '2px 0px', fontSize: 13, color: 'var(--bs-tertiary-color)' }}>
                {this.renderDate()}
              </div>
            </div>

            {this.renderBody()}
          </div>
        )}
        <AttachmentGallery
          attachments={this.props.email_message.attachments}
        />
        {this.renderEmailable()}
      </div>
    );
  }
}

EmailMessage.propTypes = {
  email_message: PropTypes.object.isRequired,
  onDeleted: PropTypes.func.isRequired,
  managerOrAdmin: PropTypes.bool,
  userId: PropTypes.number,
  hideEmailable: PropTypes.bool,
};

export default EmailMessage;

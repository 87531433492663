import React from 'react';
import PropTypes from 'prop-types';

import TodoCommentActivity from '../Activities/TodoCommentActivity';
import TodoEmailSentActivity from '../Activities/TodoEmailSentActivity';

import { renderAllInNode } from '../../helpers/timeago';

const Comps = {
  'todo.comment': TodoCommentActivity,
  'todo.email_sent': TodoEmailSentActivity,
};

class TodoActivity extends React.Component {
  constructor(props) {
    super(props);
    this.domNode = React.createRef();
  }

  componentDidMount() {
    if (this.domNode.current) {
      renderAllInNode(this.domNode.current);
    }
  }

  renderComp() {
    const Comp = Comps[this.props.activity.key];

    return <Comp {...this.props} />;
  }

  renderHtmlFallback() {
    const animated = this.props.activity.initial ? '' : 'todo-activity-animated';

    return (
      <div
        ref={this.domNode}
        className={`todo-activity-feed-item todo-activity ${animated}`}
        dangerouslySetInnerHTML={{ __html: this.props.activity.html }}
      />
    );
  }

  render() {
    if (Comps[this.props.activity.key]) {
      return this.renderComp();
    }

    return this.renderHtmlFallback();
  }
}

TodoActivity.propTypes = {
  activity: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

export default TodoActivity;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Droppable } from 'react-beautiful-dnd';

import ActionTemplate from './ActionTemplate';
import ActionTemplateModal from './ActionTemplateModal';

import SubTodoTemplateModal from './SubTodoTemplateModal';
import SubTodoTemplate from './SubTodoTemplate';

import { timeInWords } from './DueSelector';

import Icon from '../shared/Icon';

import TodoTemplatePriority from './TodoTemplatePriority';

import { durationString } from '../../helpers/helpers';

class WorkflowTemplateTodo extends Component {

  // Subtodos can only be added if no triggers are present
  subTodosAllowed = () => {
    return !this.props.todo.actions.some(action => action.triggers.length);
  };

  triggersAllowed = () => {
    return !this.props.todo.todos.length;
  };

  handleActionAdded = (newAction) => {
    const actions = [...this.props.todo.actions, newAction];
    const newTodo = Object.assign({}, this.props.todo, { actions });

    this.props.onTodoUpdated(newTodo);
  };

  handleActionDeleted = (deletedAction) => {
    const actions = this.props.todo.actions.filter((action) => {
      return action.id !== deletedAction.id;
    });

    const newTodo = Object.assign({}, this.props.todo, { actions });

    this.props.onTodoUpdated(newTodo);
  };

  handleActionUpdated = (newAction) => {
    const actions = [...this.props.todo.actions].map((action) => {
      return (action.id === newAction.id) ? newAction : action;
    });

    const newTodo = Object.assign({}, this.props.todo, { actions });

    this.props.onTodoUpdated(newTodo);
  };

  handleSubTodoAdded = (result) => {
    const newSubTodo = result.todo;
    const todos = [...this.props.todo.todos, newSubTodo];
    const newTodo = Object.assign({}, this.props.todo, { todos });

    this.props.onTodoUpdated(newTodo);
  };

  handleSubTodoDeleted = (deletedSubTodo) => {
    const todos = this.props.todo.todos.filter((todo) => {
      return todo.id !== deletedSubTodo.id;
    });

    const newTodo = Object.assign({}, this.props.todo, { todos });

    this.props.onTodoUpdated(newTodo);
  };

  handleSubTodoUpdated = (newSubTodo) => {
    const todos = [...this.props.todo.todos].map((todo) => {
      return (todo.id === newSubTodo.id) ? newSubTodo : todo;
    });

    const newTodo = Object.assign({}, this.props.todo, { todos });

    this.props.onTodoUpdated(newTodo);
  };

  renderRole() {
    if (this.props.todo.role) {
      return (
        <div className="workflow-template-todo-info">
          <Icon name="circle" color={this.props.todo.role.color} /> {this.props.todo.role.name}{' '}
        </div>
      );
    }

    return null;
  }

  renderDue() {
    if (this.props.todo.seconds_to_due !== null) {
      return (
        <div className="workflow-template-todo-info">
          <Icon name="clock" /> {timeInWords(this.props.todo.seconds_to_due / (60 * 60))}
        </div>
      );
    }

    return null;
  }


  renderAllowDelay() {
    if (this.props.todo.allow_delay) {
      return (
        <div className="workflow-template-todo-info">
          <Icon name="alarm-clock" /> Vertagen erlaubt
        </div>
      );
    }

    return null;
  }

  renderDelayReasonRequired() {
    if (this.props.todo.allow_delay && this.props.todo.delay_reason_required) {
      return (
        <div className="workflow-template-todo-info">
          <Icon name="comment" /> Grund erforderlich
        </div>
      );
    }

    return null;
  }

  renderId() {
    if (!this.props.admin) return null;

    return (
      <div className="workflow-template-todo-info" title="ID der Vorlage in der Datenbank">
        <Icon name="database" /> {this.props.todo.id}
      </div>
    );
  }

  renderSynchronize() {
    if (this.props.todo.synchronize_todos) {
      return (
        <div className="workflow-template-todo-info">
          <Icon name="random" /> Synchronisieren
        </div>
      );
    }

    return null;
  }

  renderInfos() {
    if (!this.props.todo) return null;

    return (
      <div className="todo-template-infos">
        {this.renderRole()}
        {this.renderDue()}
        {this.renderAllowDelay()}
        {this.renderDelayReasonRequired()}
        {this.renderSynchronize()}
        {this.renderId()}
        <TodoTemplatePriority todo={this.props.todo} />
      </div>
    );
  }

  renderSelectHint() {
    if (this.props.todo) return null;

    return (
      <div className="text-center mt-5">
        <Icon name="arrow-circle-left" style={{ fontSize: 40 }} color="#ccc" />
        <p className="text-muted mt-3">Wähle eine Aufgabe zum Bearbeiten aus</p>
      </div>
    );
  }

  renderSubTodos() {
    return (
      <Droppable
        droppableId={`subtodos-${this.props.todo.id}`}
        type={`todo-${this.props.todo.id}-subtodos`}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef}>
            {this.props.todo.todos.map((subTodo, i) => (
              <SubTodoTemplate
                onTodoDeleted={this.handleSubTodoDeleted}
                todo={subTodo}
                organizationSlug={this.props.organizationSlug}
                editable={this.props.editable}
                workflowType={this.props.workflowType}
                onTodoUpdated={this.handleSubTodoUpdated}
                key={subTodo.id}
                index={i}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }

  renderAddSubTodo() {
    return (
      <SubTodoTemplateModal
        visible={this.props.editable}
        initialSubTodo={{
          todo_id: this.props.todo.id,
          workflow_id: this.props.todo.workflow_id,
          parent_todo_id: this.props.todo.id,
          text: '',
        }}
        workflowType={this.props.workflowType}
        onSaved={this.handleSubTodoAdded}
        organizationSlug={this.props.organizationSlug}>
        {onClick => (
          <div style={{ marginTop: 9, marginBottom: 9 }}>
            <button onClick={onClick} className="btn btn-small btn-compact btn-link">
              Unteraufgabe hinzufügen
            </button>
          </div>
        )}
      </SubTodoTemplateModal>
    );
  }

  renderSubTodoSection() {
    if (!this.props.todo) return null;
    if (!this.subTodosAllowed()) return null;

    return (
      <div className="todo-template-sub-todos-section">
        {this.renderSubTodos()}
        {this.renderAddSubTodo()}
      </div>
    );
  }

  renderActions() {
    return (
      <Droppable
        droppableId={`todo-${this.props.todo.id}`}
        type={`todo-${this.props.todo.id}-actions`}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef}>
            {this.props.todo.actions.map((action, index) => (
              <ActionTemplate
                editable={this.props.editable}
                onUpdate={this.handleActionUpdated}
                index={index}
                key={action.id}
                todo_id={this.props.todo.id}
                action={action}
                triggersAllowed={this.triggersAllowed()}
                deletable={this.props.todo.actions.length > 1}
                onDeleted={this.handleActionDeleted}
                organizationSlug={this.props.organizationSlug}
                workflow_template_id={this.props.todo.workflow_id}
                workflowType={this.props.workflowType}
              />
            ))}
            {provided.placeholder}
            <ActionTemplateModal
              visible={this.props.editable}
              initialAction={{
                todo_id: this.props.todo.id,
                color: '#a2cc21',
                icon: 'check',
                complete_todo: true,
              }}
              workflowType={this.props.workflowType}
              onSaved={this.handleActionAdded}
              organizationSlug={this.props.organizationSlug}>
              {onClick => (
                <div style={{ marginTop: 7, marginBottom: 2 }}>
                  <button onClick={onClick} className="btn btn-compact btn-link">
                    {this.props.workflowType === 'checklist' ? 'Antwort' : 'Aktion'} hinzufügen
                  </button>
                </div>
              )}
            </ActionTemplateModal>
          </div>
        )}
      </Droppable>
    );
  }
  
  renderActionsSection() {
    if (!this.props.todo || this.props.workflowType === 'todolist') return null;

    return (
      <div className="todo-template-actions-section">
        {this.renderActions()}
      </div>
    );
  }

  render() {
    return (
      <div className="row">
        <div className="col-sm-12">
          <h3 className="mb-3">{this.props.todo?.text}</h3>
          {this.renderSelectHint()}
          {this.renderInfos()}
          {this.renderSubTodoSection()}
          {this.renderActionsSection()}
        </div>
      </div>
    );
  }
}

WorkflowTemplateTodo.propTypes = {
  onTodoUpdated: PropTypes.func.isRequired,
  workflowType: PropTypes.string.isRequired,
};

export default WorkflowTemplateTodo;

import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';

class FolderRaw extends React.Component {
  isShared() {
    return this.props.folder.shared_with && this.props.folder.shared_with.length;
  }

  renderBadge() {
    if (this.props.folder.deep_attachments_count > 0) {
      return (
        <span className="badge rounded-pill">
          {this.props.folder.deep_attachments_count}
        </span>
      );
    }

    return null;
  }

  renderIcon() {
    if (this.isShared()) {
      return <Icon name="user" className="folder-icon" />;
    }

    if (this.props.folder.locked) {
      return <Icon name="lock" className="folder-icon" />;
    }

    return null;
  }

  render() {
    return (
      <a
        onClick={this.props.onClick}
        draggable={false}
        onDragStart={this.props.onDragStart}
        onContextMenu={this.props.onContextMenu}
        className="folder">
        <Icon name="folder" color={this.props.folder.color} />
        {this.renderIcon()}
        <span title={this.props.folder.name}>
          {this.props.folder.name}
        </span>
        {this.renderBadge()}
      </a>
    );
  }
}

FolderRaw.propTypes = {
  folder: PropTypes.object.isRequired,
};

export default FolderRaw;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Folder from './Folder';

class FolderArea extends Component {
  renderFolders() {
    return this.props.folders.map((folder) => (
      <Folder
        folder={folder}
        key={folder.id}
        onRename={this.props.onRename}
        onDelete={this.props.onDelete}
        uploadFiles={this.props.uploadFiles}
        onFolderableSelect={this.props.onFolderableSelect}
        editable={this.props.editable}
      />
    ));
  }

  render() {
    return (
      <div className="row row-folder-area">
        {this.renderFolders()}
      </div>
    );
  }
}

FolderArea.propTypes = {
  folders: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDelete: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  onFolderableSelect: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
};

export default FolderArea;

import React from 'react';
import NoDataPointDisplay from './NoDataPointDisplay';
import moment from 'moment';

export default ({ value }) => {
  if (!value) {
    return <NoDataPointDisplay />;
  }

  return moment(value).format('DD.MM.YYYY');
};

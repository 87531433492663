const ReactRailsUJS = require('react_ujs')

// Support component names relative to this directory:
const componentRequireContext = require.context('components', true)

ReactRailsUJS.useContext(componentRequireContext)
ReactRailsUJS.detectEvents()

document.addEventListener("turbo:before-render", ReactRailsUJS.handleUnmount)

document.addEventListener("turbo:load", ReactRailsUJS.handleMount)
document.addEventListener("turbo:render", ReactRailsUJS.handleMount) // Can be called multiple times

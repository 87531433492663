import React from 'react';
import PropTypes from 'prop-types';

import ColorSelector from '../shared/ColorSelector';

import AssignmentSelector from '../Assignments/AssignmentSelector';

class RoleTemplateForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleAssignmentChange = this.handleAssignmentChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleChange(newRole) {
    if (this.props.onChange) {
      this.props.onChange(newRole);
    }
  }

  handleNameChange(e) {
    e.preventDefault();

    this.handleChange(
      Object.assign({}, this.props.record, { name: e.target.value }),
    );
  }

  handleColorChange(newColor) {
    this.handleChange(
      Object.assign({}, this.props.record, { color: newColor }),
    );
  }

  handleAssignmentChange(newAssignment) {
    this.handleChange(
      Object.assign({}, this.props.record, { assignment_id: newAssignment && newAssignment.id }),
    );
  }

  handleKeyDown(e) {
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) { // CMD + Enter
      if (this.props.onSave) {
        this.props.onSave(e);
      }
    }
  }

  renderNameInput() {
    if (this.props.record.project_role_id) return null;

    return (
      <div className="mb-3">
        <label className="form-label">Name</label>
        <input
          autoFocus
          className="form-control"
          placeholder="Bitte eingeben"
          autoComplete="off"
          type="text"
          value={this.props.record.name}
          onChange={this.handleNameChange}
          onKeyDown={this.handleKeyDown}
        />
      </div>
    );
  }

  renderColorInput() {
    if (this.props.record.project_role_id) return null;

    return (
      <div className="mb-3">
        <label className="form-label">Farbe</label>
        <ColorSelector
          value={this.props.record.color}
          onChange={this.handleColorChange}
          colorSet="full"
        />
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderNameInput()}
        <div className="mb-3">
          <label className="form-label">Automatische Zuteilung</label>
          <AssignmentSelector
            assigneeType="Assignments::ResponsibleAssignment"
            value={this.props.record.assignment}
            optionsUrl={`/${this.props.organizationSlug}/assignments.json?assignment_type=Assignments::ResponsibleAssignment`}
            onChange={this.handleAssignmentChange}
          />
          <small className="text-muted">
            Wähle einen Zuteilungsregelsatz aus, um die Rolle automatisch zu besetzen.
          </small>
        </div>
        {this.renderColorInput()}
      </React.Fragment>
    );
  }
}

RoleTemplateForm.propTypes = {
  record: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
};

export default RoleTemplateForm;

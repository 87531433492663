import React from 'react';

import PropTypes from 'prop-types';

import OperatorSelector from '../../OperatorSelector';

import ProjectRoleSelector from '../../../ProjectRoles/ProjectRoleSelector';
import ResponsibleSelector from '../../../shared/ResponsibleSelector';

import BaseCondition from '../BaseCondition';

class ProjectRoleCondition extends React.Component {
  handleChangeOperator = ({ value }) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      operator: value,
      integer_operand_2: null,
      responsible: null,
    }));
  };

  handleProjectRoleChange = (project_role) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      integer_operand_1: project_role.id,
      project_role,
    }));
  };

  handleResponsibleChange = (responsible) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      responsible_gid: responsible?.gid,
      integer_operand_2: responsible?.id,
      string_operand_1: responsible?.responsible_options_type_class,
      responsible,
    }));
  };

  needsResponsible = () => {
    return (this.props.condition.operator === 'occupied_by'
      || this.props.condition.operator === 'not_occupied_by');
  };

  renderOperand1() {
    return (
      <div style={{ flexBasis: 200, marginLeft: 8 }}>
        <ProjectRoleSelector
          value={this.props.condition.project_role}
          optionsUrl={`/${this.props.organizationSlug}/project_roles.json`}
          onChange={this.handleProjectRoleChange}
          size="small"
        />
      </div>
    );
  }

  renderOperator() {
    if (!this.props.condition.project_role) return null;

    return (
      <div style={{ flexBasis: 200, marginLeft: 8 }}>
        <OperatorSelector
          operators={this.props.condition.filter_condition_type.operators}
          onChange={this.handleChangeOperator}
          value={this.props.condition.operator}
        />
      </div>
    );
  }

  renderOperand2() {
    if (this.needsResponsible()) {
      return (
        <div style={{ flexBasis: 200, marginLeft: 8 }}>
          <ResponsibleSelector
            size="small"
            value={this.props.condition.responsible}
            optionsUrl={`/${this.props.organizationSlug}/responsible_options.json`}
            onChange={this.handleResponsibleChange}
          />
        </div>
      );
    }

    return null;
  }

  render() {
    return (
      <BaseCondition {...this.props}>
        {this.renderOperand1()}
        {this.renderOperator()}
        {this.renderOperand2()}
      </BaseCondition>
    );
  }
}

ProjectRoleCondition.propTypes = {
  condition: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default ProjectRoleCondition;

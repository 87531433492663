import { Controller } from '@hotwired/stimulus';

import { flashNotice } from '../helpers/flash_helper';

export default class extends Controller {
  static targets = ['source'];

  static classes = ['supported'];

  connect() {
    navigator.permissions.query({ name: 'clipboard-write' }).then(result => {
      if (result.state === 'granted') {
        this.element.classList.add(this.supportedClass);
      }
    });
  }

  copy(e) {
    e.preventDefault();
    navigator.clipboard.writeText(this.sourceTarget.value);
    flashNotice('In die Zwischenablage kopiert.');
  }
}

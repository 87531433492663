import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from "../../DatePickers/DatePickerComponent";
import moment from 'moment';


const DateDataPointInput = ({ onChange, value, saving }) => {
  const handleChange = (date) => {
    onChange(date);
  };

  return (
    <DatePicker
      value={value}
      onChange={handleChange}
      placeholder="Datum auswählen"
    />
  );
};

DateDataPointInput.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default DateDataPointInput;

import React from 'react';
import NoDataPointDisplay from './NoDataPointDisplay';

export default ({ value }) => {
  if (value !== '' && value !== null) {
    return value.toLocaleString('de', {
      maximumFractionDigits: 10,
    });
  }

  return <NoDataPointDisplay />;
};

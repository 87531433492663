import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';

const priorities = [
  { value: 3, color: '#d9534f', title: 'hoch', title_long: 'Hohe Priorität' },
  { value: 2, color: '#ffcb17', title: 'mittel', title_long: 'Mittlere Priorität' },
  { value: 1, color: '#a2cc21', title: 'niedrig', title_long: 'Niedrige Priorität' },
  { value: 0, color: '#ccc', title: 'keine', title_long: 'Keine Priorität' },
];

const getOption = (value) => {
  return priorities.find(p => p.value === (value || 0));
};

const getTitle = value => getOption(value).title;

const getColor = value => getOption(value).color;

class PrioritySelect extends Component {
  handlePriorityChange(e, priority) {
    e.preventDefault();
    if (this.props.onChange) {
      this.props.onChange(priority);
    }
  }

  render() {
    const className = this.props.className || 'btn btn-light';

    const options = priorities.map((prio) => {
      return (
        <li className={'dropdown-item'} key={prio.value}>
          <a href="" onClick={e => this.handlePriorityChange(e, prio.value)} className={'dropdown-item'}>
            <Icon name="exclamation-circle" color={prio.color} style={{ marginRight: 4 }} />
            {prio.title}
          </a>
        </li>
      );
    });

    const title = getTitle(this.props.value);
    const color = getColor(this.props.value);

    return (
      <div className="priority-selector">
        <div className="btn-group">
          <button className={`${className} dropdown-toggle`} data-bs-toggle="dropdown">
            <Icon name="exclamation-circle" color={color} className="selected-priority-icon" />
            {' '} {this.props.iconOnly ? '' : title}
            <span className="caret" style={{ marginLeft: 4 }} />
          </button>
          <ul className="dropdown-menu dropdown-menu-end">
            <li className="dropdown-header">Priorität</li>
            <li className="divider" />
            {options}
          </ul>
        </div>
      </div>
    );
  }
}

PrioritySelect.propTypes = {
  value: PropTypes.number,
  className: PropTypes.string,
  iconOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

export default PrioritySelect;

import React from 'react';
import PropTypes from 'prop-types';

const EmailRecipient = ({ recipient }) => {
  if (recipient.recipient && recipient.recipient_type === 'Contact') {
    return (
      <span className="activity-email-recipient" title={recipient.email}>
        <a href={`/contacts/${recipient.recipient_id}`}>
          {recipient.recipient.fullname}
        </a>
      </span>
    );
  }

  if (recipient.recipient && recipient.recipient_type === 'User') {
    return (
      <span className="activity-email-recipient" title={recipient.email}>
        <a href={`/users/${recipient.recipient_id}`}>
          {recipient.recipient.fullname}
        </a>
      </span>
    );
  }

  return (
    <span className="text-muted">
      {recipient.email}
    </span>
  );
};

EmailRecipient.propTypes = {
  recipient: PropTypes.object.isRequired,
};

export default EmailRecipient;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import NumericInput from '../shared/NumericInput';

const timeIntervals = [
  { code: 'hours', title: 'Stunden', titleSingle: 'Stunde', hoursFactor: 1 },
  { code: 'days', title: 'Tage', titleSingle: 'Tag', hoursFactor: 24 },
  { code: 'weeks', title: 'Wochen', titleSingle: 'Woche', hoursFactor: 24 * 7 },
  { code: 'months', title: 'Monate', titleSingle: 'Monat', hoursFactor: 24 * 30 },
];

const getIntervalByHoursValue = (hours) => {
  if (!hours) return timeIntervals[0];

  const hoursAbs = Math.abs(hours);

  for (let i = timeIntervals.length - 1; i > 0; i -= 1) {
    if (!(hoursAbs % timeIntervals[i].hoursFactor)) {
      return timeIntervals[i];
    }
  }

  return timeIntervals[0];
};

const getInputValueByHoursValue = (hours) => {
  if (hours === null) return null;

  const hoursAbs = Math.abs(hours);

  const interval = getIntervalByHoursValue(hoursAbs);

  if (interval) {
    return hoursAbs / interval.hoursFactor;
  }

  return null;
};

const timeInWords = (hours) => {
  const interval = getIntervalByHoursValue(hours);

  const value = hours / interval.hoursFactor;

  return `${value} ${(value === 1) ? interval.titleSingle : interval.title}`;
};

// Ein- und Ausgabe dieser Komponente ist immer in Stunden
// Die anderen Intervalle (Tage, Woche, Monate) werden nur intern verwendet
class DueSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeInterval: getIntervalByHoursValue(props.value),
      inputValue: getInputValueByHoursValue(props.value), // Stunden
      direction: (props.value && props.value < 0) ? 'backward' : 'forward',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangeTimeInterval = this.handleChangeTimeInterval.bind(this);
  }

  handleChange() {
    let newHoursToDue = null;

    const factor = this.state.direction === 'forward' ? 1 : -1;

    if (this.state.inputValue !== null) {
      newHoursToDue = factor * this.state.inputValue * this.state.timeInterval.hoursFactor;
    }

    this.props.onChange(newHoursToDue);
  }

  handleInputChange(e, value) {
    this.setState({ inputValue: value }, () => {
      this.handleChange();
    });
  }

  handleChangeTimeInterval(e, timeInterval) {
    e.preventDefault();
    this.setState({ timeInterval }, () => {
      this.handleChange();
    });
  }

  handleDirectionChange(e, direction) {
    e.preventDefault();
    this.setState({ direction }, () => {
      this.handleChange();
    });
  }

  renderDirection() {
    if (!this.props.directionEnabled) return null;

    return (
      <>
        <button style={{ marginLeft: -1 }} type="button" className="btn btn-light dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {this.state.direction === 'forward' ? this.props.forwardText : this.props.backwardText} <span className="caret" />
        </button>
        <div className="dropdown-menu dropdown-menu-end">
          <a href="#" onClick={e => this.handleDirectionChange(e, 'forward')} className="dropdown-item">{this.props.forwardText}</a>
          <a href="#" onClick={e => this.handleDirectionChange(e, 'backward')} className="dropdown-item">{this.props.backwardText}</a>
        </div>
      </>
    );
  }

  render() {
    const buttonStyle = {};

    if (this.props.directionEnabled) {
      buttonStyle.borderRadius = 0;
      buttonStyle.marginLeft = -1;
    }

    return (
      <div className="input-group">
        <NumericInput
          className="form-control"
          placeholder={this.props.placeholder}
          value={this.state.inputValue || null}
          onChange={this.handleInputChange}
          options={{
            minimumValue: 0,
            decimalPlacesRawValue: 0,
            decimalPlaces: 0,
            digitGroupSeparator: '',
            emptyInputBehavior: 'null',
          }}
        />
        <button style={buttonStyle} type="button" className="btn btn-light dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {this.state.timeInterval.title} <span className="caret" />
        </button>
        <div className="dropdown-menu dropdown-menu-end">
          {timeIntervals.map(t => (
            <a key={t.title} href="#" onClick={e => this.handleChangeTimeInterval(e, t)} className="dropdown-item">{t.title}</a>
          ))}
        </div>
        {this.renderDirection()}
      </div>
    );
  }
}

DueSelector.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  dueChoice: PropTypes.string,
  placeholder: PropTypes.string,
  forwardText: PropTypes.string,
  backwardText: PropTypes.string,
  directionEnabled: PropTypes.bool,
};

DueSelector.defaultProps = {
  placeholder: 'Nach Aktivierung',
  forwardText: 'nach Datenpunkt',
  backwardText: 'vor Datenpunkt',
  directionEnabled: false,
  dueChoice: 'default'
};

export default DueSelector;
export { timeInWords };

import React from 'react';
import NoDataPointDisplay from './NoDataPointDisplay';

export default ({ value }) => {
  if (!value) {
    return <NoDataPointDisplay />;
  }

  const parts = value.split('-');

  return `KW ${parts[1]} / ${parts[0]}`;
};

import React from 'react';
import PropTypes from 'prop-types';

import HiddenInputWrapper from '../shared/HiddenInputWrapper';
import SegmentSelectorAsync from './SegmentSelectorAsync';

const SegmentSelect = (props) => {
  const { value, name, ...rest } = props;

  return (
    <HiddenInputWrapper
      {...rest}
      comp={SegmentSelectorAsync}
      name={name}
      value={value}
    />
  );
};

SegmentSelect.propTypes = {
  value: PropTypes.number,
  name: PropTypes.string,
};

export default SegmentSelect;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Picker from 'rc-picker';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import deDE from 'rc-picker/lib/locale/de_DE';
import moment from 'moment';

class DatePickerComponent extends Component {
  constructor(props) {
    super(props);

    // Don't know a way to get the RC-Picker input other than by DOM ID
    const elementId =
      this.props.id || `date-picker-${Math.random().toString(36).replace(/[^a-z]+/g, '')}`;
    this.state = { elementId };
  }

  handleChange = (date) => {
    const dateString = date && date.format();
    this.props.onChange && this.props.onChange(dateString);

    // Value is not changed until next tick
    setTimeout(() => {
      // Don't know a way to get the RC-Picker input other than by DOM ID
      const element = document.getElementById(this.state.elementId);
      // Explicitly trigger `input` on the RC-Picker input, because somehow it's not triggered by the React component
      if (element) element.dispatchEvent(new Event('input'));
    }, 0);
  };

  render() {
    const isBlockedDay = (date) => {
      return this.props.blockedDays.includes(date.day());
    };

    const disabledDate = (date) => {
      return (this.props.minDate && date < moment(this.props.minDate))
        || (this.props.maxDate && date > moment(this.props.maxDate))
        || isBlockedDay(date);
    };

    return (
      <Picker
        picker="date"
        generateConfig={momentGenerateConfig}
        locale={deDE}
        format="DD.MM.YYYY"
        allowClear
        className={`date-picker ${this.props.small ? 'date-picker-sm' : ''} ${this.props.className ? this.props.className : ''}`}
        placeholder={this.props.placeholder}
        name={this.props.name}
        value={this.props.value && moment(this.props.value)}
        onChange={this.handleChange}
        clearIcon=" "
        disabledDate={disabledDate}
        disabled={this.props.disabled}
        defaultPickerValue={this.props.defaultPickerValue}
      />
    );
  }
}

DatePickerComponent.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  small: PropTypes.bool,
  className: PropTypes.string,
  minDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  maxDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  blockedDays: PropTypes.array,
  disabled: PropTypes.bool,
  defaultPickerValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  data: PropTypes.object,
};

DatePickerComponent.defaultProps = {
  placeholder: 'Datum eintragen',
  small: false,
  className: '',
  minDate: '',
  maxDate: '',
  blockedDays: [],
  data: {},
  disabled: false,
};

export default DatePickerComponent;

import React from 'react';
import NoDataPointDisplay from './NoDataPointDisplay';
import moment from 'moment';

export default ({ value }) => {
  if (value) {
    return moment(value).format('DD.MM.YYYY HH:mm [Uhr]');
  }

  return <NoDataPointDisplay />;
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '../shared/Icon';

const ComposeAttachment = ({ onDeleteClick, onPreviewClick, document }) => {
  const [imageLoading, setImageLoading] = useState(true);

  const handleDeleteClick = (e) => {
    e.preventDefault();
    onDeleteClick();
  };

  const handlePreviewClick = (e) => {
    e.preventDefault();
    onPreviewClick();
  };

  return (
    <div className="compose-attachment">
      <div className="compose-attachment-filename">
        <img src={document.thumb} onLoad={() => setImageLoading(false)} alt={name} />
        {document.name}
      </div>
      <button onClick={handlePreviewClick} title="Vorschau">
        <Icon name="eye" />
      </button>
      <button onClick={handleDeleteClick} title="Entfernen">
        <Icon name="trash" />
      </button>
    </div>
  );
};

ComposeAttachment.propTypes = {
  document: PropTypes.object.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onPreviewClick: PropTypes.func,
};

export default ComposeAttachment;

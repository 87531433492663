import React from 'react';
import PropTypes from 'prop-types';

import SearchBox from './SearchBox';
import CompanyPolygon from './CompanyPolygon';

import Marker from '../GoogleMaps/Marker';
import Directions from '../GoogleMaps/Directions';
import InfoWindow from '../GoogleMaps/InfoWindow';

import BaseMap from '../GoogleMaps/BaseMap';

const CompanyPostalAddressMap = (props) => {
  // Calculate Bounds for map based on the given company addresses
  // an postal_address
  const getBoundsPoints = () => {
    const points = [];
    if (props.company) {
      props.company.areas.forEach((area) => {
        area.polygon.forEach((point) => {
          points.push([point.lat, point.lon]);
        });
      });
    }

    return points;
  };

  return (
    <BaseMap containerStyle={props.containerStyle} boundsPoints={getBoundsPoints()}>
      {map => (
        <React.Fragment>
          <SearchBox map={map} />
          {props.company && (
            <CompanyPolygon company={props.company} map={map} alwaysVisible />
          )}
          {props.postal_address.lat && props.postal_address.lon ? (
            <Marker
              map={map}
              lat={props.postal_address.lat}
              lon={props.postal_address.lon}>
              {marker => (
                <InfoWindow map={map} marker={marker} isOpen>
                  <div>
                    <h4>{props.clientName}</h4>
                    <p className="text-muted">
                      Kunde
                    </p>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          ) : null}
          {props.company && props.company.contact && props.company.contact.postal_addresses ? (
            <Directions
              map={map}
              origin={{
                lat: props.company.contact.postal_addresses[0].lat,
                lon: props.company.contact.postal_addresses[0].lon,
              }}
              destination={{
                lat: props.postal_address.lat,
                lon: props.postal_address.lon,
              }}
            />
          ) : null}
        </React.Fragment>
      )}
    </BaseMap>
  );
};

CompanyPostalAddressMap.propTypes = {
  company: PropTypes.object,
  postal_address: PropTypes.object.isRequired,
  containerStyle: PropTypes.object.isRequired,
  clientName: PropTypes.string,
};

CompanyPostalAddressMap.defaultProps = {
  mergedPolygons: [],
  containerStyle: {
    height: 600,
  },
};

export default CompanyPostalAddressMap;

/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import EmailMessage from './EmailMessage';

class ProjectEmailMessages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email_messages: props.email_messages,
    };
  }

  handleDeleted = (email_message) => {
    this.setState({
      email_messages: this.state.email_messages.map((e) => {
        return (e.id === email_message.id) ? email_message : e;
      }),
    });
  }

  render() {
    const style = {
      marginBottom: 20,
    };

    return this.state.email_messages.map((email_message) => {
      return (
        <div style={style} key={email_message.id}>
          <EmailMessage
            email_message={email_message}
            userId={this.props.userId}
            onDeleted={this.handleDeleted}
            managerOrAdmin={this.props.managerOrAdmin}
          />
        </div>
      );
    });
  }
}

ProjectEmailMessages.propTypes = {
  email_messages: PropTypes.array,
  userId: PropTypes.number.isRequired,
  managerOrAdmin: PropTypes.bool,
};

export default ProjectEmailMessages;

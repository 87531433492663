/* global document */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TodoActivity from './TodoActivity';

import { subscribe, unsubscribe } from '../../helpers/actioncable_helper';

class TodoActivities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activities: this.props.activities || [],
    };
  }

  componentDidMount() {
    this.newActivityChannel = subscribe({
      channel: 'TrackableActivityChannel',
      trackable_id: this.props.todoId,
      trackable_type: 'Todo',
    }, ({ activity }) => {
      this.addActivities([activity]);
    });

    // EventListener auf document erstellen, damit anderen Komponenten
    // neue Aktivitäten teilen können. (Z.B. NewCommentForm)
    document.addEventListener('todo:activities:new', this.handleNewActivities);
  }

  componentWillUnmount() {
    if (this.newActivityChannel) {
      unsubscribe(this.newActivityChannel);
    }

    document.removeEventListener('todo:activities:new', this.handleNewActivities);
  }

  // Fügt Aktivitäten zu der dargestellten Liste hinzu.
  // Berücksichtigt dabei bereits vorhandene, identische Aktivitäten.
  // Dies ist nötig, da neue Aktivitäten auf verschiedenen Wegen
  // (direkt oder per Websocket) ankommen können.
  addActivities = (activities) => {
    const newActivities = [...this.state.activities, ...activities.filter((a) => {
      return !this.state.activities.some((a2) => {
        return a2.id === a.id;
      });
    })];

    this.setState({
      activities: newActivities,
    });
  }

  handleNewActivities = (e) => {
    if (e.detail.todoId === this.props.todoId) {
      this.addActivities(e.detail.activities);
    }
  }


  handleChange = (activity) => {
    this.setState({
      activities: this.state.activities.map(a => (a.id === activity.id ? activity : a)),
    });
  }

  render() {
    return (
      <div>
        {this.state.activities.map(activity => (
          <TodoActivity
            key={activity.id}
            activity={activity}
            onChange={this.handleChange}
            userId={this.props.userId}
            managerOrAdmin={this.props.managerOrAdmin}
          />
        ))}
      </div>
    );
  }
}

TodoActivities.propTypes = {
  userId: PropTypes.number.isRequired,
  managerOrAdmin: PropTypes.bool.isRequired,
  todoId: PropTypes.number.isRequired,
  activities: PropTypes.array.isRequired,
};

export default TodoActivities;

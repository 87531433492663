import React, { useState } from 'react';
import DatePickerComponent from '../DatePickers/DatePickerComponent';

function DateConfirmer() {
  const [completedAt, setCompletedAt] = useState('');

  return (
    <div className="dropdown" style={{ minWidth: '25em' }}>
      <button
        className="col btn btn-success mb-3"
        type="button"
        value="Installation abschließen"
        title="Die Installation ist vollständig fertiggestellt"
        data-body-target="tooltip"
        data-bs-toggle="dropdown"
        data-bs-auto-close="false">
        <span className="fas fa-check" /> Installation abschließen
      </button>

      <div className="dropdown-menu dropdown-menu-end dropdown-menu-lg-start p-3">
        <DatePickerComponent
          className="mb-2"
          placeholder="Abschlussdatum auswählen"
          name="completed_at"
          value={completedAt}
          onChange={setCompletedAt}
          small
        />
        <input
          className="col btn btn-success btn-sm mt-1"
          type="submit"
          value="Installation abschließen"
          disabled={!completedAt}
        />
      </div>
    </div>
  );
}

export default DateConfirmer;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import TodoBreadCrumbs from '../Todos/TodoBreadCrumbs';
import TodoStatusBadge from './TodoStatusBadge';
import TodoStatusText from './TodoStatusText';
import DueBadge from './DueBadge';
import SubTodosBadge from './SubTodosBadge';
import TodoCommentsBadge from './TodoCommentsBadge';
import TodoStickyHeader from './TodoStickyHeader';
import PriorityBadge from './PriorityBadge';

import { subscribe, unsubscribe } from '../../helpers/actioncable_helper';

class TodoHeader extends Component {
  constructor(props) {
    super(props);

    this.state = { todo: props.initialTodo };
  }

  componentDidMount() {
    this.channel = subscribe({
      channel: 'TodoChangesChannel',
      todo_id: this.state.todo.id,
    }, ({ action, todo }) => {
      if (action === 'update') {
        this.setState({ todo });
      }
    });
  }

  componentWillUnmount() {
    if (this.channel) {
      unsubscribe(this.channel);
    }
  }

  renderButtons() {
    return (
      <div className="btn-group">
        {this.props.mayEdit ? (
          <a className="btn btn-light" href={`/todos/${this.state.todo.id}/edit`}>
            <Icon name="pencil" /> <span className="d-none d-sm-inline">Bearbeiten</span>
          </a>
        ) : null}
        {this.props.mayDestroy ? (
          <a className="btn btn-light"
            data-confirm="Bist du sicher, dass du die Aufgabe löschen willst? Alle enthaltenen Unteraufgaben werden ebenfalls gelöscht."
            data-method="delete"
            href={`/todos/${this.state.todo.id}`}>
            <Icon name="trash" /> <span className="d-none d-sm-inline">Löschen</span>
          </a>
        ) : null}
      </div>
    );
  }

  render() {
    const badgeStyle = { marginRight: 4 };

    return (
      <div className="todo-header">
        <TodoStickyHeader todo={this.state.todo} />
        <div className="todo-new">
          {this.renderButtons()}
        </div>
        <div className="todo-breadcrumbs">
          <TodoBreadCrumbs breadcrumbs={this.props.breadcrumbs} />
        </div>
        <div className="todo-text">
          {this.state.todo.text}
        </div>

        <div className="todo-status">
          <TodoStatusBadge todo={this.state.todo} />
          <TodoStatusText todo={this.state.todo} style={badgeStyle} />
          <DueBadge todo={this.state.todo} style={badgeStyle} />
          <PriorityBadge todo={this.state.todo} style={badgeStyle} />
          <SubTodosBadge todo={this.state.todo} style={badgeStyle} />
          <TodoCommentsBadge todo={this.state.todo} style={badgeStyle} />
        </div>
      </div>
    );
  }
}

TodoHeader.propTypes = {
  initialTodo: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array,
  mayEdit: PropTypes.bool,
  mayDestroy: PropTypes.bool,
};

export default TodoHeader;

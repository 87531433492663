import React from 'react';

import PropTypes from 'prop-types';

import OperatorSelector from '../../OperatorSelector';

import DataPointTemplateSelector from '../../../shared/DataPointTemplateSelector';
import DurationSelector from '../../../shared/DurationSelector';
import DatePicker from '../../../DatePickers/DatePickerComponent';

import BaseCondition from '../BaseCondition';

const durationOperators = ['until_ago', 'until_in', 'from_ago', 'from_in'];
const dateOperators = ['lteq_date', 'gteq_date', 'lt_date', 'gt_date'];

class DateTimeDataPointCondition extends React.Component {
  handleChangeOperator = ({ value }) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      operator: value,
    }));
  };

  handleDataPointTemplateChange = (template) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      integer_operand_1: template.id,
      integer_operand_2: null,
      operator: null,
      data_point_template: template,
    }));
  };

  handleDurationChange = (duration) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      integer_operand_2: duration,
    }));
  };

  handleDateChange = (date) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      date_operand_2: date && date.toString(),
    }));
  };

  shouldRenderDurationSelector = () => {
    return durationOperators.includes(this.props.condition.operator);
  };

  shouldRenderDatePicker = () => {
    return dateOperators.includes(this.props.condition.operator);
  };

  renderOperand1() {
    return (
      <div style={{ flexBasis: 200, marginLeft: 8 }}>
        <DataPointTemplateSelector
          value={this.props.condition.data_point_template}
          optionsUrl={`/${this.props.organizationSlug}/data_point_template_options.json`}
          onChange={this.handleDataPointTemplateChange}
          allowedTypes={[
            'DataPoint::DateTimeDataPoint',
          ]}
          size="small"
        />
      </div>
    );
  }

  renderOperator() {
    if (!this.props.condition.data_point_template) return null;

    return (
      <div style={{ flexBasis: 200, marginLeft: 8 }}>
        <OperatorSelector
          operators={this.props.condition.filter_condition_type.operators}
          onChange={this.handleChangeOperator}
          value={this.props.condition.operator}
        />
      </div>
    );
  }

  renderOperand2() {
    if (this.shouldRenderDurationSelector()) {
      return (
        <div style={{ flexBasis: 200, marginLeft: 8 }}>
          <DurationSelector
            size="small"
            value={this.props.condition.integer_operand_2}
            onChange={this.handleDurationChange}
          />
        </div>
      );
    } else if (this.shouldRenderDatePicker()) {
      return (
        <div style={{ flexBasis: 200, marginLeft: 8 }}>
          <DatePicker
            small
            value={this.props.condition.date_operand_2}
            onChange={this.handleDateChange}
          />
        </div>
      );
    }

    return null;
  }

  render() {
    return (
      <BaseCondition {...this.props}>
        {this.renderOperand1()}
        {this.renderOperator()}
        {this.renderOperand2()}
      </BaseCondition>
    );
  }
}

DateTimeDataPointCondition.propTypes = {
  condition: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default DateTimeDataPointCondition;

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../shared/Tooltip';
import Icon from '../shared/Icon';

class TodoBreadCrumbs extends Component {
  renderBreadCrumb = (breadcrumb) => {
    if (breadcrumb.title === breadcrumb.truncated) {
      return (
        <a href={breadcrumb.url}>
          {breadcrumb.truncated}
        </a>
      );
    }

    return (
      <Tooltip title={breadcrumb.title} placement="top">
        <a href={breadcrumb.url}>
          {breadcrumb.truncated}
        </a>
      </Tooltip>
    );
  }

  render() {
    return this.props.breadcrumbs.map((breadcrumb, i) => {
      return (
        <Fragment key={`breadcrumb-${i}`}>
          {this.renderBreadCrumb(breadcrumb)}
          {i < this.props.breadcrumbs.length - 1 ? (
            <Icon name="chevron-right" />
          ) : null}
        </Fragment>
      );
    });
  }
}

TodoBreadCrumbs.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
};

export default TodoBreadCrumbs;

import React from 'react';
import Icon from '../shared/Icon';
import PropTypes from 'prop-types';

import { components } from 'react-select';

const TagOption = (props) => {
  const icon = props.data.icon || 'circle';

  let title = "";

  if (props.data.group && !props.data.group.hide_title) {
    title += `${props.data.group.title}: `; 
  }

  title += props.data.title;

  if (props.data.desc) {
    title += ` (${props.data.desc})`;
  }

  return (
    <components.Option {...props}>
      <Icon name={icon} color={props.data.color} style={{ marginRight: 5 }} />
      {title}
    </components.Option>
  );
};

TagOption.propTypes = {
  data: PropTypes.object,
};

export default TagOption;

import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import Modal from '../shared/Modal';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const grid = 4;

import { reorder } from '../../helpers/helpers';

const getListStyle = isDraggingOver => ({
  // background: '#eee',
  // padding: grid,
  // borderRadius: 6,
  // width: '100%',
});

class HintMultiSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hints: props.hints,
      modalOpen: false,
    };

    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleOpenAddModal = this.handleOpenAddModal.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  availableHintOptions() {
    const ids = this.state.hints.map(h => h.id);

    return this.props.hintOptions.filter((option) => {
      return !ids.includes(option.id);
    });
  }

  handleDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const hints = reorder(
      this.state.hints,
      result.source.index,
      result.destination.index,
    );

    this.setState({ hints });
  }


  handleRemoveClick(e, i) {
    e.preventDefault();

    this.setState({
      hints: [...this.state.hints.slice(0, i), ...this.state.hints.slice(i + 1)],
    });
  }


  handleOpenAddModal(e) {
    e.preventDefault();
    this.setState({
      modalOpen: true,
    });
  }

  handleModalClose(e) {
    e.preventDefault();

    this.setState({
      modalOpen: false,
    });
  }

  handleAddClick(e, hint) {
    e.preventDefault();

    this.handleModalClose(e);

    this.setState({
      hints: [...this.state.hints, hint],
    });
  }

  renderHints() {
    return this.state.hints.map((hint, i) => (
      <Draggable key={hint.id} draggableId={hint.id.toString()} index={i}>
        {(provided, snapshot) => (
          <div key={hint.id} {...provided.draggableProps}>
            <div ref={provided.innerRef} className="hint-item" {...provided.dragHandleProps}>
              <div className="hint-content">
                {hint.text}
              </div>
              <div className="hint-action">
                <a href="" onClick={e => this.handleRemoveClick(e, i)}>
                  <Icon name="trash" />
                </a>
              </div>
            </div>
            {provided.placeholder}
          </div>
        )}
      </Draggable>
    ));
  }

  renderOptions() {
    const options = this.availableHintOptions();

    if (options.length === 0) {
      return (
        <p>
          Keine weiteren Hinweise vorhanden
        </p>
      );
    }

    return this.availableHintOptions().map((hint) => {
      return (
        <div key={hint.id} className="hint-item">
          <div className="hint-content">
            <div style={{ marginBottom: 5, fontWeight: 'bolder' }}>{hint.title}</div>
            {hint.text}
          </div>
          <div className="hint-action">
            <a href="" onClick={e => this.handleAddClick(e, hint)} className="btn btn-primary btn-compact btn-sm">
              auswählen
            </a>
          </div>
        </div>
      );
    });
  }

  renderModal() {
    return (
      <Modal visible={this.state.modalOpen} onClose={this.handleModalClose} size="large">
        <div className="react-modal-header">
          <h4>
            Hinweis hinzufügen
          </h4>
        </div>
        <div className="react-modal-content">
          {this.renderOptions()}
        </div>
        <div className="react-modal-footer">
          <div className="text-muted" />
          <div>
            <button
              className="btn btn-light"
              onClick={this.handleModalClose}>Abbrechen</button>{' '}
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    return (
      <div>
        <DragDropContext onDragEnd={this.handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {this.renderHints()}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <input name={this.props.name} type="hidden" value={this.state.hints.map(h => h.id).join(',')} />
        <p>
          <a href="" onClick={this.handleOpenAddModal}>Hinweis hinzufügen</a>
        </p>
        {this.renderModal()}
      </div>
    );
  }
}

HintMultiSelect.propTypes = {
  hints: PropTypes.array,
  hintOptions: PropTypes.array,
  name: PropTypes.string.isRequired,
};

export default HintMultiSelect;

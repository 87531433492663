import React from 'react';
import PropTypes from 'prop-types';

import NoDataPointDisplay from './NoDataPointDisplay';

import Icon from '../../shared/Icon';

const ListDataPointDisplay = ({ dataPoint }) => {
  if (!dataPoint || !dataPoint.list_item) return <NoDataPointDisplay />;

  return (
    <span>
      {dataPoint.list_item.title} <a href={`/list_items/${dataPoint.list_item.id}`} target="_blank">
        <Icon name="arrow-up-right-from-square" />
      </a>
    </span>
  );
};

ListDataPointDisplay.propTypes = {
  dataPoint: PropTypes.object,
};

export default ListDataPointDisplay;

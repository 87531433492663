import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../shared/Tooltip';
import Dropdown from '../shared/Dropdown';
import Icon from '../shared/Icon';
import MembershipDetails from './MembershipDetails';

class ProjectRole extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
    };
  }

  handleClick = (e) => {
    e.preventDefault();
    this.setState({ dropdownOpen: true });
  };

  handleClose = () => {
    this.setState({ dropdownOpen: false });
  };

  handleUpdate = (membership) => {
    this.props.onUpdate(membership);
  };

  renderTarget = (ref) => {
    const style = {
      // backgroundColor: this.props.role.color,
      borderRadius: '50%',
      borderColor: this.props.role.color,
      marginRight: 0,
    };

    const imageStyle = {
      display: 'inline-block',
      borderRadius: '50%',
      backgroundColor: '#fff',
      width: 28,
      height: 28,
    };

    let image;

    if (this.props.membership) {
      image = (
        <img
          alt={'test'}
          src={this.props.membership.user.avatar.thumb.url}
          style={imageStyle}
        />
      );
    }

    const linkStyle = {
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      // marginLeft: 1,
    };

    const circleStyle = {
      display: 'inline-flex',
      height: 36,
      width: 36,
      borderRadius: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: this.props.role.color,
      marginLeft: 2,
    };

    const circleStyle2 = {
      display: 'inline-flex',
      height: 32,
      width: 32,
      borderRadius: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'var(--bs-body-bg)',
    };

    const link = (
      <a href="" ref={ref} onClick={this.handleClick} style={linkStyle}>
        {/* <Icon name="plus" /> */}
        {image}
      </a>
    );

    let title = `${this.props.role.name} auswählen`;

    if (this.props.membership) {
      title = `${this.props.role.name}: ${this.props.membership.user.fullname}`;
    }

    const responsibleClassNames = [];

    if (this.props.membership.responsible_for_active_todo) {
      responsibleClassNames.push('avatar-responsible-project-role');
    }

    if (this.props.membership.due && Date.parse(this.props.membership.due) < new Date()) {
      responsibleClassNames.push('avatar-responsible-overdue');
    }

    return (
      <Tooltip title={title} placement="top">
        <span className={responsibleClassNames.join(' ')}>
          <span style={circleStyle}>
            <span style={circleStyle2}>
              <span className="project-role-wrapper" style={style}>
                {link}
              </span>
            </span>
          </span>
        </span>
      </Tooltip>
    );
  };

  render() {
    return (
      <Dropdown
        target={this.renderTarget}
        placement="bottom-end"
        isOpen={this.state.dropdownOpen}
        onClose={this.handleClose}>
        <MembershipDetails
          editable={this.props.editable}
          membership={this.props.membership}
          onDelete={this.props.onDelete}
          onUpdate={this.handleUpdate} />
      </Dropdown>
    );
  }
}

ProjectRole.propTypes = {
  role: PropTypes.object.isRequired,
  membership: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
};

export default ProjectRole;

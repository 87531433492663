/* global google */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '../shared/Icon';

const Directions = (props) => {
  const [drawingComplete, setDrawingComplete] = useState(false);
  const [directionsDisplay, setDirectionsDisplay] = useState();
  const [response, setResponse] = useState();

  useEffect(() => {
    if (props.map && !drawingComplete) {
      const directionsService = new google.maps.DirectionsService();
      const newDirectionsDisplay = new google.maps.DirectionsRenderer({
        map: props.map,
        suppressMarkers: true,
      });

      const request = {
        origin: new google.maps.LatLng(props.origin.lat, props.origin.lon),
        destination: new google.maps.LatLng(props.destination.lat, props.destination.lon),
        travelMode: google.maps.TravelMode.DRIVING,
      };

      directionsService.route(request, (res, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          newDirectionsDisplay.setDirections(res);

          setResponse(res);
        }
      });

      setDirectionsDisplay(newDirectionsDisplay);
      setDrawingComplete(true);
    }

    // Cleanup
    return () => {
      if (directionsDisplay) {
        directionsDisplay.setMap(null);
      }
    };
  }, [props.map, drawingComplete]);

  return (
    <React.Fragment>
      {response ? (
        <div className="directions-overlay">
          <h4>Route: Fachpartner <Icon name="arrow-right" /> Kunde</h4>
          <p>
            {response.routes[0].legs[0].distance.text}
          </p>
          <p>
            {response.routes[0].legs[0].duration.text}
          </p>

        </div>
      ) : null}

    </React.Fragment>
  );
};

Directions.propTypes = {
  origin: PropTypes.object,
  destination: PropTypes.object,
  map: PropTypes.object,
};

export default Directions;

import React from 'react';
import TaskList from './TaskList';

const SubTodos = (props) => (
  <div className="sub-todos-container">
    <TaskList {...props} />
  </div>
);

export default SubTodos;

import IntegerDataPointInput from './DataPointInput/IntegerDataPointInput';
import BooleanDataPointInput from './DataPointInput/BooleanDataPointInput';
import FloatDataPointInput from './DataPointInput/FloatDataPointInput';
import StringDataPointInput from './DataPointInput/StringDataPointInput';
import StringChoiceDataPointInput from './DataPointInput/StringChoiceDataPointInput';
import MultiStringChoiceDataPointInput from './DataPointInput/MultiStringChoiceDataPointInput';
import TextDataPointInput from './DataPointInput/TextDataPointInput';
import DecimalDataPointInput from './DataPointInput/DecimalDataPointInput';
import MoneyDataPointInput from './DataPointInput/MoneyDataPointInput';
import DateTimeDataPointInput from './DataPointInput/DateTimeDataPointInput';
import DateDataPointInput from './DataPointInput/DateDataPointInput';
import WeekDataPointInput from './DataPointInput/WeekDataPointInput';
import MonthDataPointInput from './DataPointInput/MonthDataPointInput';
import ListDataPointInput from './DataPointInput/ListDataPointInput';
import DocumentDataPointInput from './DataPointInput/DocumentDataPointInput';
import UserDataPointInput from './DataPointInput/UserDataPointInput';

export default {
  IntegerDataPointInput,
  BooleanDataPointInput,
  FloatDataPointInput,
  StringDataPointInput,
  StringChoiceDataPointInput,
  MultiStringChoiceDataPointInput,
  TextDataPointInput,
  DecimalDataPointInput,
  MoneyDataPointInput,
  DateTimeDataPointInput,
  DateDataPointInput,
  WeekDataPointInput,
  MonthDataPointInput,
  ListDataPointInput,
  DocumentDataPointInput,
  UserDataPointInput,
};

import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../shared/Icon';

const AffiliateSaleEffectForm = () => (
  <div className="fire-effect-item">
    <div className="fire-effect-icon">
      <Icon name="chart-pie" />
    </div>
    <div className="fire-effect-content">
      <div className="fire-effect-title">
        Affiliate Sale senden
      </div>
    </div>
  </div>
);

AffiliateSaleEffectForm.propTypes = {
  effect: PropTypes.object.isRequired,
};

export default AffiliateSaleEffectForm;

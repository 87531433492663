import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../shared/Tooltip';
import TimeAgo from '../shared/TimeAgo';
import Icon from '../shared/Icon';
import FullnameWithAvatar from '../shared/FullNameWithAvatar';

import RoleBadge from '../Workflows/RoleBadge';

const renderResponsible = (responsible) => {
  return (
    <a href={responsible.path} className="todo-status-user-link">
      <FullnameWithAvatar model={responsible} />
    </a>
  );
};

const renderFiredText = (todo) => {
  if (todo.fired_action && todo.fired_action.fired_by_user) {
    const user = todo.fired_action.fired_by_user;
    return (
      <div className="todo-status-text">
        von {renderResponsible(user)} <TimeAgo datetime={todo.fired_action.fired_at} locale="de"/>
      </div>
    );
  }

  return null;
};

const renderText = (todo) => {
  const responsible = todo.role || todo.responsible
  if (responsible) {
    switch (responsible.responsible_options_type) {
      case 'Role':
        return (
          <div className="todo-status-text">
            <RoleBadge todo={todo} style={{ marginRight: 4 }}/>
            {todo.responsible ? (
              renderResponsible(todo.responsible)
            ) : (
              <Tooltip title="Dieser Rolle ist kein Benutzer zugeordnet" placement="top">
                <Icon name="exclamation-circle" color="#f0ad4e"/>
              </Tooltip>
            )}
            {' '}ist verantwortlich für diese Aufgabe
          </div>
        );
      case 'InstallationCompany':
      case 'User':
        return (
          <div className="todo-status-text">
            {renderResponsible(todo.responsible)} ist verantwortlich für diese Aufgabe
          </div>
        );
    }
  }

  return null;
};


const TodoStatusText = ({ todo }) => {
  if (todo.complete) {
    return renderFiredText(todo);
  }

  return renderText(todo);
};

TodoStatusText.propTypes = {
  todo: PropTypes.object,
};

export default TodoStatusText;

/* global document, window */
import React from 'react';

import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

const transitionDuration = 200; // Millisekungen

class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.targetRef = React.createRef();
    this.targetElement = null;

    this.state = {
      visible: props.visible,
    };

    this.handleAfterOpen = this.handleAfterOpen.bind(this);
  }

  componentDidMount() {
    this.targetElement = this.targetRef.current;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.visible && prevProps.visible) {
      this.enableScroll();
    }

    if (!this.props.visible && prevProps.visible) {
      this.timer = setTimeout(() => {
        this.setState({ visible: false });
        if (this.props.onClosed) {
          this.props.onClosed();
        }
      }, transitionDuration);
    }
  }

  componentWillUnmount() {
    this.enableScroll();
    clearTimeout(this.timer);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.visible) {
      state.visible = true;
    }
    return state;
  }

  disableScroll() {
    // Scrollposition speichern für später, wenn Scroll wieder aktiviert werden soll
    this.scrollY = window.pageYOffset;

    const height = Math.max(
      document.documentElement.clientHeight,
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
    );

    // Achtung: Reihenfolge wichtig!
    document.body.style.top = `${this.scrollY * -1}px`;
    document.body.style.height = `${height}px`;
    document.body.style.marginBottom = '115px';
    document.body.style.position = 'fixed';
  }

  enableScroll() {
    document.body.style.position = 'initial';
    document.body.style.top = undefined;
    document.documentElement.scrollTop = document.body.scrollTop = this.scrollY;
    document.body.style.height = 'auto';
  }

  handleAfterOpen() {
    this.disableScroll();
  }

  handleClose() {
    this.props.onClose();
  }

  render() {
    let sizeClass = '';
    let mobileSizeClass = '';
    let overflowClass = '';

    if (this.props.size === 'small') {
      sizeClass = 'react-modal-sm';
    } else if (this.props.size === 'medium') {
      sizeClass = 'react-modal-md';
    } else if (this.props.size === 'large') {
      sizeClass = 'react-modal-lg';
    } else if (this.props.size === 'fluid') {
      sizeClass = 'react-modal-fluid';
    } else if (this.props.size === 'full') {
      sizeClass = 'react-modal-full';
    }

    if (this.props.mobileSize === 'fluid') {
      mobileSizeClass = 'react-modal-mobile-fluid';
    } else if (this.props.mobileSize === 'full') {
      mobileSizeClass = 'react-modal-mobile-full';
    }

    if (!this.state.visible) {
      return null;
    }

    if (this.props.allowOverflow) {
      overflowClass = 'react-modal-overflow';
    }

    return (
      <ReactModal
        style={this.props.style}
        ref={this.targetElement}
        ariaHideApp={false}
        isOpen={this.props.visible}
        className={`react-modal ${sizeClass} ${mobileSizeClass} ${overflowClass}`}
        overlayClassName="react-modal-overlay"
        contentLabel="Dokumente"
        onAfterOpen={this.handleAfterOpen}
        onRequestClose={this.props.onClose}
        shouldCloseOnOverlayClick
        closeTimeoutMS={transitionDuration}
      >
        {this.props.children}
      </ReactModal>
    );
  }
}

Modal.propTypes = {
  onClose: PropTypes.func,
  onClosed: PropTypes.func,
  visible: PropTypes.bool,
  children: PropTypes.node,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'fluid', 'full']),
  mobileSize: PropTypes.oneOf(['small', 'medium', 'large', 'fluid', 'full']),
  allowOverflow: PropTypes.bool,
  style: PropTypes.object,
};

Modal.defaultProps = {
  size: 'medium',
  mobileSize: 'small',
};

export default Modal;

import React from 'react';
import PropTypes from 'prop-types';

import DataviewItemForm from './DataviewItemForm';
import RemoteFormModal from '../shared/RemoteFormModal';

// import dataviewSectionValidator from '../../validators/dataview_section_validator';
import { saveDataviewItem } from '../../helpers/api';

const DataviewItemModal = props => (
  <RemoteFormModal
    {...props}
    titleNew="Neues Element"
    titleEdit="Element bearbeiten"
    // validator={dataviewSectionValidator}
    form={DataviewItemForm}
    initialRecord={props.initialItem}
    saveHandler={saveDataviewItem}
    allowOverflow
  />
);

DataviewItemModal.propTypes = {
  initialItem: PropTypes.object.isRequired,
};

export default DataviewItemModal;

import React from 'react';

import PropTypes from 'prop-types';

import OperatorSelector from '../../OperatorSelector';

import DataPointTemplateSelector from '../../../shared/DataPointTemplateSelector';

import BaseCondition from '../BaseCondition';

import ListItemSelector from '../../../Lists/ListItemSelector';

class ListDataPointCondition extends React.Component {
  handleChangeOperator = ({ value }) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      operator: value,
    }));
  };

  handleDataPointTemplateChange = (template) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      integer_operand_1: template.id,
      integer_operand_2: null,
      operator: null,
      data_point_template: template,
    }));
  };

  handleListItemChange = (list_item) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      integer_operand_2: list_item.id,
      list_item,
    }));
  };

  shouldRenderListItemSelector = () => {
    return ['is', 'is_not'].includes(this.props.condition.operator);
  };

  renderOperand1() {
    return (
      <div style={{ flexBasis: 200, marginLeft: 8 }}>
        <DataPointTemplateSelector
          value={this.props.condition.data_point_template}
          optionsUrl={`/${this.props.organizationSlug}/data_point_template_options.json`}
          onChange={this.handleDataPointTemplateChange}
          allowedTypes={['DataPoint::ListDataPoint']}
          size="small"
        />
      </div>
    );
  }

  renderOperator() {
    if (!this.props.condition.data_point_template) return null;

    return (
      <div style={{ flexBasis: 200, marginLeft: 8 }}>
        <OperatorSelector
          operators={this.props.condition.filter_condition_type.operators}
          onChange={this.handleChangeOperator}
          value={this.props.condition.operator}
        />
      </div>
    );
  }

  renderOperand2() {
    if (this.shouldRenderListItemSelector()) {
      return (
        <div style={{ flexBasis: 200, marginLeft: 8 }}>
          <ListItemSelector
            size="small"
            value={this.props.condition.list_item}
            onChange={this.handleListItemChange}
            listId={this.props.condition.data_point_template.list_id}
          />
        </div>
      );
    }

    return null;
  }

  render() {
    return (
      <BaseCondition {...this.props}>
        {this.renderOperand1()}
        {this.renderOperator()}
        {this.renderOperand2()}
      </BaseCondition>
    );
  }
}

ListDataPointCondition.propTypes = {
  condition: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default ListDataPointCondition;

/* global window */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import AttachmentModal from './AttachmentModal';

import ImagePreloader from '../../helpers/image_preloader';

class AttachmentPreviewer extends Component {
  constructor(props) {
    super(props);

    this.preloader = new ImagePreloader();

    this.state = {
      previewVisible: false,
      attachmentForPreview: null,
    };
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentDidUpdate() {
    this.preloadNext();
    this.preloadPrevious();
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  getCurrentIndex = () => {
    if (!this.state.attachmentForPreview) return -1;

    return this.props.attachments.findIndex((a) => {
      return a.id === this.state.attachmentForPreview.id;
    });
  };

  preloadNext() {
    if (this.state.previewVisible && this.state.attachmentForPreview) {
      this.preloadIndex(this.getCurrentIndex() + 1);
    }
  }

  preloadPrevious() {
    if (this.state.previewVisible && this.state.attachmentForPreview) {
      this.preloadIndex(this.getCurrentIndex() - 1);
    }
  }

  preloadIndex(index) {
    if (this.props.attachments[index]) {
      this.preloader.preloadImage(this.props.attachments[index].file_big_url);
    }
  }

  selectPrevious = () => {
    const currentIndex = this.getCurrentIndex();

    if (currentIndex <= 0) return;

    this.setState({
      attachmentForPreview: this.props.attachments[currentIndex - 1],
    });
  };

  selectNext = () => {
    const currentIndex = this.getCurrentIndex();

    if (currentIndex >= this.props.attachments.length - 1) return;

    this.setState({
      attachmentForPreview: this.props.attachments[currentIndex + 1],
    });
  };

  handleKeyDown = (e) => {
    if (!this.state.previewVisible) return;

    if (e.keyCode === 37) {
      this.selectPrevious();
    } else if (e.keyCode === 39) {
      this.selectNext();
    }
  };

  handleOpenClick = (attachment) => {
    this.setState({
      attachmentForPreview: attachment,
      previewVisible: true,
    });
  };

  handleClosePreview = () => {
    this.setState({
      previewVisible: false,
    });
  };

  handlePreviewClosed = () => {
    this.setState({
      attachmentForPreview: null,
    });
  };

  render() {
    return (
      <Fragment>
        <AttachmentModal
          visible={this.state.previewVisible}
          attachment={this.state.attachmentForPreview}
          onClose={this.handleClosePreview}
          onClosed={this.handlePreviewClosed}
          onDeleted={this.handleDeleted}
          onNext={this.selectNext}
          onPrevious={this.selectPrevious}
          currentPosition={this.getCurrentIndex()}
          totalCount={this.props.attachments.length}
        />
        {this.props.children(this.handleOpenClick)}
      </Fragment>
    );
  }
}

AttachmentPreviewer.propTypes = {
  attachments: PropTypes.array.isRequired,
  children: PropTypes.func,
};

export default AttachmentPreviewer;

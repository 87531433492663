import React from 'react';
import PropTypes from 'prop-types';

import DataPoint from './DataPoint';

import UploaderProvider from '../Uploader/UploaderProvider';

class DataSchema extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataPoints: props.initialDataPoints,
    };
  }

  render() {
    if (this.state.deleted) return null;

    return (
      <UploaderProvider>
        <div className="dataset-card">
          <div className="dataset-card-header">
            <div>{this.props.dataSchema.title}</div>
            <div />
          </div>
          <div className="dataset-card-body">
            {this.state.dataPoints.map((dataPoint) => (
              <DataPoint
                editable={this.props.editable && this.props.allow_edit}
                dataHolderId={this.props.dataHolderId}
                dataHolderType={this.props.dataHolderType}
                key={dataPoint.key}
                index={dataPoint.key}
                initialDataPoint={dataPoint}
                organizationSlug={this.props.organizationSlug}
              />
            ))}
          </div>
        </div>
      </UploaderProvider>
    );
  }
}

DataSchema.propTypes = {
  dataSchema: PropTypes.object.isRequired,
  dataHolderType: PropTypes.string.isRequired,
  dataHolderId: PropTypes.number.isRequired,
  initialDataPoints: PropTypes.array.isRequired,
  editable: PropTypes.bool.isRequired,
  allow_edit: PropTypes.bool.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default DataSchema;

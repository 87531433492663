import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import PrioritySelect from './PrioritySelect';
import TextArea from '../shared/TextArea';
import ResponsibleSelector from '../shared/ResponsibleSelector';
import DatePicker from "../DatePickers/DatePickerComponent";

class SubTodoForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      todo: this.props.todo,
    };
  }

  componentDidMount() {
    this.textInputRef.focus();
  }

  handlePriorityChange = (priority) => {
    this.setState({
      todo: Object.assign({}, this.state.todo, { priority }),
    });
  }

  handleTextChange = (e) => {
    this.setState({
      todo: Object.assign({}, this.state.todo, { text: e.target.value }),
    });
  }

  handleDescChange = (e) => {
    this.setState({
      todo: Object.assign({}, this.state.todo, { desc: e.target.value }),
    });
  }

  handleDueChange = (momentDate) => {
    this.setState({
      todo: Object.assign({}, this.state.todo, { due: momentDate }),
    });
  }

  handleSaveClick = (e) => {
    e.preventDefault();
    if (this.state.todo.text.trim()) {
      this.props.onSave(this.state.todo);
    }
  }

  handleTextKeyDown = (e) => {
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) { // CMD + Enter
      this.handleSaveClick(e);
    } else if (e.keyCode === 27 && !this.props.saving) { // Escape
      e.preventDefault();
      this.props.onCancel();
    } else if (e.keyCode === 13) { // Enter
      e.preventDefault();
      this.descInputRef.focus();
    }
  }

  handleDescKeyDown = (e) => {
    if (e.keyCode === 27 && !this.props.saving) { // Escape
      this.props.onCancel();
    } else if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) { // CMD + Enter
      this.handleSaveClick(e);
    }
  }

  handleResponsibleChange = (newValue) => {
    this.setState({
      todo: Object.assign({}, this.state.todo, { responsible: newValue }),
    });
  }

  renderSaveButton() {
    const disabled = !this.state.todo.text.trim() || this.props.saving;

    const classNames = ['save-sub-todo form-control-sm'];

    if (disabled) {
      classNames.push('disabled');
    }

    if (this.props.saving) {
      classNames.push('btn-loading');
    }

    return (
      <button className={classNames.join(' ')} disabled={disabled} onClick={this.handleSaveClick}>
        <span className="save-icon d-md-none"><Icon name="save" /></span>
        <span className="save d-none d-md-inline">Speichern</span>
      </button>
    );
  }

  renderCancelButton() {
    if (this.props.saving) return null;

    return (
      <button
        className="btn btn-sm btn-link close-sub-todo-form"
        onClick={this.props.onCancel}>
        abbrechen
      </button>
    );
  }

  render() {
    return (
      <div className="edit-sub-todo">
        <div className="sub-todo-form-header">
          <div style={{ paddingTop: 2 }}>
            <button className="check-sub-todo disabled" disabled>
              <Icon name="check" className="icon" />
            </button>
          </div>
          <div className="sub-todo-form-text-container">
            <TextArea
              value={this.state.todo.text}
              onChange={this.handleTextChange}
              className="new-sub-todo-text"
              placeholder="Titel"
              onKeyDown={this.handleTextKeyDown}
              ref={e => (this.textInputRef = e)}
              disabled={this.props.saving}
              rows="1" />
            <TextArea
              value={this.state.todo.desc}
              onChange={this.handleDescChange}
              className="new-sub-todo-desc"
              placeholder="Beschreibung"
              onKeyDown={this.handleDescKeyDown}
              ref={e => (this.descInputRef = e)}
              disabled={this.props.saving}
              autoSize
              rows="1" />
          </div>
        </div>
        <div className="sub-todo-form-footer">
          <div className="sub-todo-form-meta" style={{ display: 'flex' }}>
            <div className="sub-todo-responsible-container">
              <div className="sub-todo-responsible">
                <ResponsibleSelector
                  onChange={this.handleResponsibleChange}
                  placeholder="Verantwortlich"
                  size="small"
                  hideDropdownIndicator
                  optionsUrl={this.props.responsibleOptionsPath}
                  value={this.state.todo.responsible} />
              </div>
            </div>

            <div className="sub-todo-form-due">
              <DatePicker
                onChange={this.handleDueChange}
                placeholder="Fälligkeit"
                value={this.state.todo.due}
                small
              />
            </div>

            <div className="sub-todo-form-priority">
              <PrioritySelect
                value={this.state.todo.priority}
                onChange={this.handlePriorityChange}
                className="btn btn-light btn-sm form-control-sm"
                iconOnly />
            </div>

          </div>
          <div className="sub-todo-form-actions">
            {this.renderSaveButton()}
            {this.renderCancelButton()}
          </div>

        </div>
      </div>
    );
  }
}

SubTodoForm.propTypes = {
  todo: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  responsibleOptionsPath: PropTypes.string.isRequired,
};

export default SubTodoForm;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DataviewSectionDetails from './DataviewSectionDetails';

import { getAsync } from '../../helpers/rails_helper';
import DataviewSections from './DataviewSections';

class DataviewEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      dataview: null,
      removedSections: [],
    };
  }

  componentDidMount() {
    this.fetch();
  }

  fetch() {
    getAsync(`/dataviews/${this.props.dataview_id}.json`).then((result) => {
      this.setState({
        dataview: result.dataview,
        selectedSection: result.dataview.dataview_sections[0],
        loading: false,
      });
    });
  }

  handleSectionSelect = (section) => {
    this.setState(() => ({
      selectedSection: section,
    }));
  };

  handleDataviewChange = (dataview) => {
    this.setState({ dataview });
  };

  handleSectionChange = (updatedSection) => {
    this.setState((prevState) => ({
      dataview: Object.assign({}, prevState.dataview, {
        dataview_sections: prevState.dataview.dataview_sections.map((s) => {
          return (s.id === updatedSection.id) ? updatedSection : s;
        }),
      }),
      selectedSection: updatedSection,
    }));
  };

  handleSectionAdded = (newSection) => {
    this.setState((prevState) => ({
      dataview: Object.assign({}, prevState.dataview, {
        dataview_sections: [...prevState.dataview.dataview_sections, newSection],
      }),
      selectedSection: newSection,
    }));
  };

  handleRemoveClick = (e, i) => {
    e.preventDefault();

    const section = { ...this.state.dataview.dataview_sections[i] };

    this.setState((prevState) => ({
      dataview: Object.assign({}, this.state.dataview, {
        dataview_sections: [
          ...prevState.dataview.dataview_sections.slice(0, i),
          ...prevState.dataview.dataview_sections.slice(i + 1),
        ],
      }),
    }));

    if (!section.new) {
      this.setState((prevState) => ({
        removedSections: [...prevState.removedSections, section],
      }));
    }
  };

  render() {
    if (this.state.loading) {
      return (
        <div className="box-loading" style={{ height: 250 }} />
      );
    }

    return (
      <div className="row">
        <div className="col-sm-3">
          <DataviewSections
            selectedSection={this.state.selectedSection}
            dataview={this.state.dataview}
            onChange={this.handleDataviewChange}
            onAdded={this.handleSectionAdded}
            onSelect={this.handleSectionSelect}
          />
        </div>
        <div className="col-sm-9">
          <DataviewSectionDetails
            section={this.state.selectedSection}
            onChange={this.handleSectionChange}
            base_viewer_class_name={this.state.dataview?.viewer_class_name}
            organizationSlug={this.props.organizationSlug}
          />
        </div>
      </div>
    );
  }
}

DataviewEditor.propTypes = {
  dataview_id: PropTypes.number,
  organizationSlug: PropTypes.string.isRequired,
};

export default DataviewEditor;

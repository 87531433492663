import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Modal from './Modal';

class RemoteFormModal extends React.Component {
  constructor(props) {
    super(props);

    this.handleOpenModalClick = this.handleOpenModalClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleModalClosed = this.handleModalClosed.bind(this);
    this.handleRecordChange = this.handleRecordChange.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);

    this.state = {
      modalVisible: false,
      errors: [],
      record: this.props.initialRecord,
    };
  }

  handleOpenModalClick(e) {
    e.preventDefault();

    this.setState((prevState) => ({
      modalVisible: true,
      errors: this.validate(prevState),
      record: this.props.initialRecord,
    }));

    if (this.props.onOpen) this.props.onOpen();
  }

  handleCancelClick() {
    this.setState({ modalVisible: false });
  }

  handleModalClosed() {
    this.setState({
      record: this.props.initialRecord,
      saving: false,
      errors: this.validate(this.props.initialRecord),
    });

    if (this.props.onClose) this.props.onClose();
  }

  handleRecordChange(record) {
    this.setState({
      record,
      errors: this.validate(record),
    });
  }

  handleSaveClick(e) {
    e.preventDefault();

    if (this.state.saving) return null;

    if (this.state.errors.length) return null;

    this.setState({
      saving: true,
    });

    return this.props.saveHandler(this.state.record).then((result) => {
      this.setState({ modalVisible: false });
      if (this.props.onSaved) {
        this.props.onSaved(result);
      }
    }).catch((error) => {
      this.setState({ saving: false });
      console.log(error);
    });
  }

  validate = (record) => {
    if (this.props.validator) {
      return this.props.validator.validate(record);
    }

    return [];
  };

  isNew() {
    return this.props.initialRecord && !this.props.initialRecord.id;
  }

  renderSaveButton() {
    if (!this.props.saveButtonVisible) return null;

    return (
      <button
        type="button"
        disabled={this.state.errors.length}
        className={[
          'btn',
          (this.isNew() ? 'btn-success' : 'btn-primary'),
          (this.state.saving ? 'btn-loading' : ''),
        ].join(' ')}
        onClick={this.handleSaveClick}>
        {this.props.saveButtonText}
      </button>
    );
  }

  render() {
    if (!this.props.visible) return null;

    const FormComp = this.props.form;

    return (
      <>
        {this.props.children(this.handleOpenModalClick)}
        <Modal
          allowOverflow={this.props.allowOverflow}
          visible={this.state.modalVisible}
          onClose={this.handleCancelClick}
          onClosed={this.handleModalClosed}>
          <div className="react-modal-header">
            <h4>{this.isNew() ? this.props.titleNew : this.props.titleEdit}</h4>
          </div>
          <div className="react-modal-content">
            <FormComp
              {...this.props}
              errors={this.state.errors}
              record={this.state.record}
              onChange={this.handleRecordChange}
              onSave={this.handleSaveClick}
            />
          </div>

          <div className="react-modal-footer">
            <div className="text-muted" />
            <div>
              <button
                type="button"
                className="btn btn-light"
                onClick={this.handleCancelClick}
                disabled={this.state.saving}>
                Abbrechen
              </button>{' '}
              {this.renderSaveButton()}
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

RemoteFormModal.propTypes = {
  titleNew: PropTypes.string,
  titleEdit: PropTypes.string,
  saveButtonText: PropTypes.string,
  children: PropTypes.func,
  form: PropTypes.func,
  initialRecord: PropTypes.object.isRequired,
  saveHandler: PropTypes.func,
  onSaved: PropTypes.func,
  validator: PropTypes.object,
  visible: PropTypes.bool,
  saveButtonVisible: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  allowOverflow: PropTypes.bool,
};

RemoteFormModal.defaultProps = {
  visible: true,
  titleNew: 'Neu',
  titleEdit: 'Bearbeiten',
  saveButtonText: 'Speichern',
  saveButtonVisible: true,
  allowOverflow: false,
};

export default RemoteFormModal;

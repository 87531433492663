/* eslint-disable quote-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

import { defaultStyles } from '../../styles/select_styles';

import Icon from '../shared/Icon';

import Dropdown from '../shared/Dropdown';

const selectStyles = {
  control: (provided, { isFocused }) => ({
    ...provided,
    minWidth: 240,
    margin: 5,
    borderColor: isFocused ? '#ccc' : '#ccc',
    '&:hover': { borderColor: '#ccc' },
    boxShadow: 'none',
  }),
  menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
  option: (provided, { isFocused }) => ({
    ...provided,
    padding: '3px 8px',
    backgroundColor: isFocused ? '#2f7dd5' : 'white',
    color: isFocused ? 'white' : '#333',
  }),
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <Icon name="circle" color={props.data.color} /> {props.data.state}
    </components.Option>
  );
};

const filterOption = (option, searchTerm) => {
  if (!searchTerm) return true;

  return searchTerm.replace(/ +(?= )/g,'').split(' ').every((term) => {
    const str = `${option.data.state}`;
    return str.toLowerCase().includes(term.toLowerCase());
  });
};

class ProjectStateSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropDownVisible: false,
      inputValue: '',
    };
  }

  handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, '');
    this.setState({ inputValue });
    return inputValue;
  };

  handleChange = (tag) => {
    if (this.props.onAdd) {
      this.props.onAdd(tag);
    }
  }

  toggleDropDown = (e) => {
    e.preventDefault();
    this.setState({ dropDownVisible: !this.state.dropDownVisible });
  }

  handleBlur = () => {
    this.setState({ dropDownVisible: false });
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      this.toggleDropDown(e);
    }
  }

  renderAddButton = (ref) => {
    return (
      <button ref={ref} className="btn btn-light btn-label" onClick={this.toggleDropDown}>
        <Icon name="plus" /> {this.props.buttonText}
      </button>
    );
  }

  renderTarget = (ref) => {
    if (this.props.target) {
      return this.props.target(ref, this);
    }

    return this.renderAddButton(ref);
  }

  render() {
    return (
      <Dropdown
        isOpen={this.state.dropDownVisible}
        onClose={this.toggleDropDown}
        target={this.renderTarget}>
        <Select
          autoFocus
          components={{
            Option,
            DropdownIndicator: this.props.value ? null : components.DropdownIndicator,
            IndicatorSeparator: null,
          }}
          openMenuOnFocus
          clearable={false}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
          backspaceRemovesValue={false}
          blurInputOnSelect
          menuPlacement="auto"
          menuIsOpen
          onChange={this.handleChange}
          handleInputChange={this.handleInputChange}
          options={this.props.options}
          filterOption={filterOption}
          defaultOptions
          styles={defaultStyles}
          placeholder="Status hinzufügen"
          noOptionsMessage={() => 'Keine Ergebnisse'}
        />
      </Dropdown>
    );
  }
}

ProjectStateSelector.propTypes = {
  onAdd: PropTypes.func,
  target: PropTypes.func,
  options: PropTypes.array.isRequired,
  buttonText: PropTypes.string,
};

ProjectStateSelector.defaultProps = {
  buttonText: 'Status',
};

export default ProjectStateSelector;

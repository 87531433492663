import React from 'react';
import PropTypes from 'prop-types';
import ModelSelect from '../shared/ModelSelect';

function CraftAppointmentTypeSelector(props) {
  return (
    <ModelSelect
      model="craft_appointment_types"
      optionsUrl={props.craftId && `/crafts/${props.craftId}/craft_appointment_types.json`}
      name={props.name}
      placeholder="Termintyp"
      value={props.value}
      onChange={props.onChange}
      disabled={props.craftId === null}
    />
  );
}

CraftAppointmentTypeSelector.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  name: PropTypes.string.isRequired,
  craftId: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

CraftAppointmentTypeSelector.defaultProps = {
  value: '',
  craftId: null,
};

export default CraftAppointmentTypeSelector;

/* global Image */
const preloadImage = (url, onLoadCallback) => {
  const img = new Image();

  img.src = url;

  if (onLoadCallback) {
    img.onload = onLoadCallback;
  }

  return img;
};

class ImagePreloader {
  constructor() {
    this.images = [];
    this.preloadImage = this.preloadImage.bind(this);
  }

  preloadImage(url, callback) {
    if (!url) return;

    const img = preloadImage(url, callback);

    // Referenz in Array speichern, da sonst einige Browser
    // da Bild nach kurzer Zeit wieder löschen.
    this.images.push(img);
  }
}

export default ImagePreloader;

export {
  preloadImage,
};

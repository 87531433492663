import React from 'react';
import PropTypes from 'prop-types';

import RemoteFormModal from '../shared/RemoteFormModal';
import NewWorkflowForm from './NewWorkflowForm';

const AddWorkflowModal = props => (
  <RemoteFormModal
    allowOverflow
    userId={props.userId}
    projectId={props.projectId}
    organizationSlug={props.organizationSlug}
    titleNew="Liste hinzufügen"
    saveButtonText="Hinzufügen"
    saveButtonVisible={false}
    initialRecord={{}}
    form={NewWorkflowForm}>
    {onClick => (
      <button className={props.className} onClick={onClick}>
        {props.btnText}
      </button>
    )}
  </RemoteFormModal>
);

AddWorkflowModal.propTypes = {
  btnText: PropTypes.string,
  userId: PropTypes.number.isRequired,
  projectId: PropTypes.number.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  className: PropTypes.string,
};

AddWorkflowModal.defaultProps = {
  style: 'small',
  className: 'btn btn-xs',
};

export default AddWorkflowModal;

/* eslint-disable no-console */
// import { Accounts } from 'meteor/accounts-base';
import UploadQueue from './UploadQueue';

export default function Uploader() {
  this.allQueuesDone = true;
  this.status = 'idle'; // ['running', 'idle']
  this.queues = [];

  const self = this;

  const eventListeners = {
    onUploadsChanged: [],
    onFileAdded: [],
    onFileRemoved: [],
    onAllQueuesDone: [],
  };

  this.createQueue = (ref) => {
    const queue = new UploadQueue(ref);

    self.queues.push(queue);

    queue.addListener('onQueueDone', () => {
      self.startNextQueue();
    });

    queue.addListener('onFileAdded', () => {
      if (self.status !== 'running') self.startNextQueue();
    });

    return queue;
  };

  const runHandlers = (eventName, payload) => {
    eventListeners[eventName].forEach((handler) => {
      handler(payload);
    });
  };

  this.addListener = (eventName, handler) => {
    eventListeners[eventName].push(handler);
  };

  this.removeListener = (eventName, handler) => {
    const index = eventListeners[eventName].indexOf(handler);

    if (index > -1) {
      eventListeners[eventName].splice(index, 1);
    }
  };

  this.startNextQueue = () => {
    const nextQueue = self.queues.find(queue => !queue.done);

    if (nextQueue) {
      self.status = 'running';
      nextQueue.startNext();
    } else {
      self.status = 'idle';
      self.allQueuesDone = true;
      runHandlers('onAllQueuesDone');
    }
  };

  this.areAllQueuesDone = () => {
    return self.queues.some(queue => queue.done);
  };

  return this;
}

/* global document, google, KeyboardEvent */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Override default enter event on input.
// Simulate arrow down (select first result) and then enter
// otherwise the result of the google places search will be lost
const enableEnterKey = (input) => {
  /* Store original event listener */
  const _addEventListener = input.addEventListener;

  const addEventListenerWrapper = (type, listener) => {
    if (type === 'keydown') {
      /* Store existing listener function */
      const _listener = listener;

      listener = (event) => {
        /* Simulate a 'down arrow' keypress if no address has been selected */
        const suggestionSelected = document.getElementsByClassName('pac-item-selected').length;

        if (event.key === 'Enter' && !suggestionSelected) {
          const e = new KeyboardEvent('keydown', {
            key: 'ArrowDown',
            code: 'ArrowDown',
            keyCode: 40,
          });

          _listener.apply(input, [e]);
        }
        _listener.apply(input, [event]);
      };
    }
    _addEventListener.apply(input, [type, listener]);
  };

  input.addEventListener = addEventListenerWrapper;
};

const SearchBox = ({ map }) => {
  const initSearchBox = () => {
    const input = document.getElementById('searchInput');
    enableEnterKey(input);
    const autocomplete = new google.maps.places.Autocomplete(input, {
      componentRestrictions: { country: 'de' },
      types: ['geocode'],
    });

    autocomplete.bindTo('bounds', map);
    // autocomplete.setTypes(['address']);

    const infowindow = new google.maps.InfoWindow();

    const marker = new google.maps.Marker({
      map,
      anchorPoint: new google.maps.Point(0, -29),
    });

    autocomplete.addListener('place_changed', () => {
      infowindow.close();
      marker.setVisible(false);
      const place = autocomplete.getPlace();

      if (!place.geometry) {
        // window.alert("Autocomplete's returned place contains no geometry");
        return;
      }

      // If the place has a geometry, then present it on a map.
      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(17);
      }

      marker.setIcon(({
        url: place.icon,
        size: new google.maps.Size(71, 71),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(17, 34),
        scaledSize: new google.maps.Size(35, 35),
      }));

      marker.setPosition(place.geometry.location);
      marker.setVisible(true);

      let address = '';

      if (place.address_components) {
        address = [
          ((place.address_components[0] && place.address_components[0].short_name) || ''),
          ((place.address_components[1] && place.address_components[1].short_name) || ''),
          ((place.address_components[2] && place.address_components[2].short_name) || ''),
        ].join(' ');
      }

      infowindow.setContent(`<div><strong>${place.name}</strong><br>${address}`);
      infowindow.open(map, marker);
    });
  };

  useEffect(() => {
    if (map) {
      initSearchBox();
    }
  }, [map]);

  if (!map) return null;

  return (
    <input id="searchInput" className="maps-search-box" type="text" placeholder="Adresse oder Ort eingeben" />
  );
};

SearchBox.propTypes = {
  map: PropTypes.object,
};

export default SearchBox;

/* eslint-disable camelcase, max-len */
import { updateAsync, postAsync, deleteAsync } from './rails_helper';

const updateTodo = todo => updateAsync(`/todos/${todo.id}`, { todo });
const createTodo = todo => postAsync(`/workflows/${todo.workflow_id}/todos`, { todo });
const deleteTodo = todo => deleteAsync(`/todos/${todo.id}`);
const saveTodo = todo => (todo.id ? updateTodo(todo) : createTodo(todo));

const createRole = role => postAsync(`/workflows/${role.workflow_id}/roles`, { role });
const updateRole = role => updateAsync(`/roles/${role.id}`, { role });
const deleteRole = role => deleteAsync(`/roles/${role.id}`);
const saveRole = role => (role.id ? updateRole(role) : createRole(role));

const createAction = action => postAsync(`/todos/${action.todo_id}/actions`, { action_params: action });
const updateAction = action => updateAsync(`/actions/${action.id}`, { action_params: action });
const saveAction = action => (action.id ? updateAction(action) : createAction(action));
const deleteAction = action => deleteAsync(`/actions/${action.id}`);

const createTodoActivation = todo_activation => postAsync(`/actions/${todo_activation.action_id}/todo_activations`, { todo_activation });
const updateTodoActivation = todo_activation => updateAsync(`/todo_activations/${todo_activation.id}`, { todo_activation });
const saveTodoActivation = todo_activation => (todo_activation.id ? updateTodoActivation(todo_activation) : createTodoActivation(todo_activation));
const deleteTodoActivation = todo_activation => deleteAsync(`/todo_activations/${todo_activation.id}`);

const createTrigger = trigger => postAsync(`/actions/${trigger.action_id}/triggers`, { trigger });
const updateTrigger = trigger => updateAsync(`/triggers/${trigger.id}`, { trigger });
const saveTrigger = trigger => (trigger.id ? updateTrigger(trigger) : createTrigger(trigger));
const deleteTrigger = trigger => deleteAsync(`/triggers/${trigger.id}`);

const createEffect = effect => postAsync(`/actions/${effect.action_id}/effects`, { effect });
const updateEffect = effect => updateAsync(`/effects/${effect.id}`, { effect });
const saveEffect = effect => (effect.id ? updateEffect(effect) : createEffect(effect));
const deleteEffect = effect => deleteAsync(`/effects/${effect.id}`);

const createWorkflowEditorship = workflow_editorship => postAsync(`/workflows/${workflow_editorship.workflow_id}/workflow_editorships`, { workflow_editorship });
const updateWorkflowEditorship = workflow_editorship => updateAsync(`/workflow_editorships/${workflow_editorship.id}`, { workflow_editorship });
const saveWorkflowEditorship = workflow_editorship => (workflow_editorship.id ? updateWorkflowEditorship(workflow_editorship) : createWorkflowEditorship(workflow_editorship));
const deleteWorkflowEditorship = workflow_editorship => deleteAsync(`/workflow_editorships/${workflow_editorship.id}`);

const updateComment = comment => updateAsync(`/comments/${comment.id}`, { comment });
const deleteComment = comment => deleteAsync(`/comments/${comment.id}`);

const deleteAttachment = attachment => deleteAsync(`/attachments/${attachment.id}`);

const deleteEmailMessage = email_message => deleteAsync(`/email_messages/${email_message.id}`);

const createDataviewSection = section => postAsync(`/dataviews/${section.dataview_id}/dataview_sections`, { dataview_section: section });
const updateDataviewSection = section => updateAsync(`/dataview_sections/${section.id}`, { dataview_section: section });
const deleteDataviewSection = section => deleteAsync(`/dataview_sections/${section.id}`);
const saveDataviewSection = section => (section.id ? updateDataviewSection(section) : createDataviewSection(section));

const createDataviewItem = item => postAsync(`/dataview_sections/${item.dataview_section_id}/dataview_items`, { dataview_item: item });
const updateDataviewItem = item => updateAsync(`/dataview_items/${item.id}`, { dataview_item: item });
const deleteDataviewItem = item => deleteAsync(`/dataview_items/${item.id}`);
const saveDataviewItem = item => (item.id ? updateDataviewItem(item) : createDataviewItem(item));

export {
  createTodo, updateTodo, saveTodo, deleteTodo,
  createRole, updateRole, saveRole, deleteRole,
  createAction, updateAction, saveAction, deleteAction,
  createTodoActivation, updateTodoActivation, saveTodoActivation, deleteTodoActivation,
  createTrigger, updateTrigger, saveTrigger, deleteTrigger,
  createEffect, updateEffect, saveEffect, deleteEffect,
  createWorkflowEditorship, updateWorkflowEditorship, saveWorkflowEditorship, deleteWorkflowEditorship,
  updateComment, deleteComment,
  deleteAttachment,
  deleteEmailMessage,
  createDataviewSection, updateDataviewSection, deleteDataviewSection, saveDataviewSection,
  createDataviewItem, updateDataviewItem, deleteDataviewItem, saveDataviewItem,
};

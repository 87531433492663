import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getAsync } from '../../../helpers/rails_helper';

import DataPointOptionMultiSelector from '../DataPointOptionMultiSelector';

const MultiStringChoiceDataPointInput = ({ dataPoint, onChange, value }) => {
  const [options, setOptions] = useState([]);
  const [choices, setChoices] = useState(dataPoint.data_point_choices);
  const [choicesToBeDeleted, setChoicesToBeDeleted] = useState([]);

  useEffect(() => {
    getAsync(`/data_point_templates/${dataPoint.data_point_template_id}/data_point_options.json`).then(({ data_point_options }) => {
      setOptions(data_point_options);
    });
  }, [dataPoint.data_point_template_id]);

  const handleChange = (choicesToBeStored, choicesToBeRemoved) => {
    // build attachments_attributes (via nested attributes)
    // for attachments association of DataPoint::DocumentDataPoint
    const choicesAttributes = [
      ...choicesToBeStored.map((choice) => ({
        id: choice.id || '',
        data_point_option_id: choice.data_point_option_id,
      })),
      ...choicesToBeRemoved.map((choice) => ({
        id: choice.id,
        _destroy: true,
      })),
    ];

    onChange(choicesAttributes);
  };

  const handleAddChoice = (newChoice) => {
    // filter out choices already present in the list
    const newChoices = [...choices];

    if (!choices.find(d => d.data_point_option_id === newChoice.data_point_option_id)) {
      newChoices.push(newChoice);
      setChoices(newChoices);
      handleChange(newChoices, choicesToBeDeleted);
    }
  };

  const handleRemoveChoice = (choice) => {
    // remove choice from list
    const newChoices = choices.filter(d => d.data_point_option_id !== choice.data_point_option_id);
    setChoices(newChoices);

    // mark choice to be deleted if it was already persisted
    const newChoicesToBeDeleted = [...choicesToBeDeleted];

    if (choice.id) {
      newChoicesToBeDeleted.push(choice);
      setChoicesToBeDeleted(newChoicesToBeDeleted);
    }

    handleChange(newChoices, newChoicesToBeDeleted);
  };

  return (
    <div style={{ marginTop: 5 }}>
      <DataPointOptionMultiSelector
        name="Option"
        value={value || []}
        onAdd={handleAddChoice}
        onRemove={handleRemoveChoice}
        options={options}
        data_point_choices={choices}
      />
    </div>
  );
};

MultiStringChoiceDataPointInput.propTypes = {
  dataPoint: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array,
};

export default MultiStringChoiceDataPointInput;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TodoText from './TodoText';
import ResponsibleBadge from './ResponsibleBadge';
import RoleBadge from './RoleBadge';
import DueBadge from './DueBadge';
import PriorityIcon from './PriorityIcon';
import SubTodosBadge from './SubTodosBadge';
import CheckDropdown from './CheckDropdown';
import TodoCommentsBadge from './TodoCommentsBadge';

import { updateAsync } from '../../helpers/rails_helper.js';
import { subscribe, unsubscribe } from '../../helpers/actioncable_helper';

class CheckTodo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      todo: props.initialTodo,
      editing: false,
      saving: false,
    };
  }

  componentDidMount() {
    this.channel = subscribe({
      channel: 'TodoChangesChannel',
      todo_id: this.state.todo.id,
    }, ({ action, todo }) => {
      if (action === 'update') {
        this.setState({ todo });
      }
    });
  }

  componentWillUnmount() {
    if (this.channel) {
      unsubscribe(this.channel);
    }
  }

  handleUpdateTodo = (todo) => {
    this.setState({ todo });
  }

  handleFire = (action) => {
    this.setState({
      todo: Object.assign({}, this.state.todo, {
        complete: true,
        fired_action: action,
      }),
      saving: true,
    });

    updateAsync(`/actions/${action.id}/fire.json`, {
      action_params: {},
    }, 'PATCH').then((result) => {
      this.setState({ saving: false });
      this.handleUpdateTodo(result.todo);
    });
  }

  handleReset = () => {
    this.setState({
      todo: Object.assign({}, this.state.todo, {
        complete: false,
        fired_action: null,
      }),
      saving: true,
    });

    updateAsync(`/todos/${this.state.todo.id}/reopen`).then((result) => {
      this.setState({ saving: false });
      this.handleUpdateTodo(result.todo);
    });
  }

  mayCompleteTodo = () => {
    return this.props.completable || this.state.todo.user_id === this.props.currentUserId;
  }

  renderTodo() {
    let completeClass = '';

    const todo = this.state.todo;

    if (todo.complete) {
      completeClass = 'sub-todo-complete';
    }

    const badgeStyle = { marginRight: 4 };

    return (
      <div className={`todo-sub-todo ${completeClass}`}>
        <div className="sub-todo-hover-extension" />
        <div className="sub-todo-actions-container d-none d-sm-block">
          <div className="sub-todo-actions" />
        </div>

        <div className="sub-todo-check-button-container">
          <CheckDropdown
            todo={todo}
            completable={this.mayCompleteTodo()}
            onFire={this.handleFire}
            onReset={this.handleReset}
            saving={this.state.saving} />
        </div>

        <TodoText {...todo} commentable={this.props.commentable} />

        <div className="todo-meta todo-badges-sm">
          <ResponsibleBadge todo={todo} style={badgeStyle} />
          <RoleBadge todo={todo} style={badgeStyle} />
          <DueBadge todo={todo} style={badgeStyle} />
          <SubTodosBadge todo={todo} style={badgeStyle} />
          <TodoCommentsBadge todo={todo} style={badgeStyle} />
          <PriorityIcon todo={todo} />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="sub-todo-container">
        {this.renderTodo()}
      </div>
    );
  }
}

CheckTodo.propTypes = {
  initialTodo: PropTypes.object.isRequired,
  completable: PropTypes.bool.isRequired,
  commentable: PropTypes.bool.isRequired,
  currentUserId: PropTypes.number.isRequired,
};

export default CheckTodo;

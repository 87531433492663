import { Controller } from '@hotwired/stimulus';
import * as bootstrap from 'bootstrap';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  static targets = ['tooltip', 'popover', 'link'];

  connect() {
    this.tooltipTargets.forEach(el => {
      new bootstrap.Tooltip(el, {
        container: 'body',
      });
    });

    this.popoverTargets.forEach(el => {
      new bootstrap.Popover(el);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  linkTargetConnected(el) {
    el.addEventListener('click', (event) => {
      event.stopPropagation();
      Turbo.visit(el.dataset.link);
    });
    el.querySelectorAll('a, button, input').forEach(element => {
      element.addEventListener('click', (event) => {
        event.stopPropagation();
      });
    });
  }
}

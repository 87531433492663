import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import Tooltip from '../shared/Tooltip';

import EmailTemplateSelector from '../EmailTemplates/EmailTemplateSelector';

class NewEmailTemplateSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      templateSelectorOpen: false,
    };
  }

  handleTemplateClick = (e) => {
    e.preventDefault();
    this.setState({
      templateSelectorOpen: true,
    });
  };

  handleTemplateSelectorBlur = () => {
    this.setState({
      templateSelectorOpen: false,
    });
  };

  renderTemplateHint() {
    if (this.props.sender_type === 'anonymous') return null;

    return (
      <div className="compose-template-section">
        {this.renderTemplateSelector()}
      </div>
    );
  }

  renderAnonymousHint() {
    if (this.props.sender_type !== 'anonymous') return null;

    return (
      <div className="compose-template-section compose-template-section-warning">
        <div>
          Diese Vorlage kann nicht bearbeitet werden.
        </div>
        <div>
          <Tooltip title="Vorlage entfernen" placement="top">
            <button type="button" className="btn btn-link btn-compact" onClick={this.props.handleClearTemplate}>
              <Icon name="trash" />
            </button>
          </Tooltip>
        </div>
      </div>
    );
  }

  renderTemplateSelector() {
    if (this.state.templateSelectorOpen) {
      return (
        <div className="text-start">
          <EmailTemplateSelector
            hideDropdownIndicator
            optionsUrl={this.props.emailTemplatesOptionsUrl}
            contactId={this.props.contact_id}
            projectId={this.props.project_id}
            onBlur={this.handleTemplateSelectorBlur}
            onChange={this.props.handleTemplateChange}
            size="small"
          />
        </div>
      );
    }

    return (
      <button type="button" onClick={this.handleTemplateClick} className="btn compose-side-button">
        <Icon name="note" /> Vorlage auswählen
      </button>
    );
  }

  render() {
    return (
      <div>
        {this.renderTemplateHint()}
        {this.renderAnonymousHint()}
      </div>
    );
  }
}

NewEmailTemplateSection.propTypes = {
  handleTemplateChange: PropTypes.func.isRequired,
  handleClearTemplate: PropTypes.func.isRequired,
  emailTemplatesOptionsUrl: PropTypes.string.isRequired,
  contact_id: PropTypes.number.isRequired,
  project_id: PropTypes.number.isRequired,
  sender_type: PropTypes.string.isRequired,
};

export default NewEmailTemplateSection;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ListItemSelector from '../../Lists/ListItemSelector';

class ListDataPointInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.value,
      list_item: props.dataPoint && props.dataPoint.list_item,
    };
  }

  handleChange = (o) => {
    console.log(o);
    this.props.onChange(o && o.id);
    this.setState({
      id: o && o.id,
      list_item: o,
    });
  }

  render() {
    const alteredValue = this.props.value && {
      id: this.props.value,
      title: this.state.list_item.title,
    };

    return (
      <ListItemSelector
        value={alteredValue}
        clearable
        onChange={this.handleChange}
        listId={this.props.dataPoint.list_id}
      />
    );
  }
}

ListDataPointInput.propTypes = {
  organizationSlug: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  saving: PropTypes.bool,
  value: PropTypes.number,
  dataPoint: PropTypes.object,
};

export default ListDataPointInput;

import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';

const TodoStatusBadge = ({ todo, size }) => {
  const baseClass = (size === 'small') ? 'todo-status-badge-small rounded-pill' : 'todo-status-badge rounded-pill';

  if (todo.complete && todo.fired_action) {
    return (
      <div className={baseClass} style={{
        backgroundColor: todo.fired_action.color,
        color: todo.fired_action.text_color,
      }}>
        <Icon name={todo.fired_action.icon} /> {todo.fired_action.name_fired}
      </div>
    );
  }

  if (todo.active) {
    return (
      <div className={`${baseClass} todo-status-badge-primary`}>
        <Icon name="exclamation-circle" /> Offen
      </div>
    );
  }

  return (
    <div className={`${baseClass} todo-status-badge-default`}>
      <Icon name="power-off" /> Inaktiv
    </div>
  );
};

TodoStatusBadge.propTypes = {
  todo: PropTypes.object,
  size: PropTypes.string,
};

TodoStatusBadge.defaultProps = {
  size: 'medium',
};

export default TodoStatusBadge;

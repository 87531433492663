/* global fetch */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { defaultStyles, smallStyles } from '../../styles/select_styles';

import Select, { components } from 'react-select';

const filterOption = (option, searchTerm) => {
  if (!searchTerm) return true;

  return searchTerm.replace(/ +(?= )/g,'').split(' ').every((term) => {
    return option.data.title.toLowerCase().includes(term.toLowerCase());
  });
};

const Version = ({ version }) => {
  if (!version) return null;

  return (
    <span style={{ color: '#aaa' }}>
      v{version}
    </span>
  );
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      {props.data.title} <Version version={props.data.version} />
    </components.Option>
  );
};

const SingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      {props.data.title} <Version version={props.data.version} />
    </components.SingleValue>
  );
};

class WorkflowTemplateSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      options: [],
    };
  }

  loadOptions = () => {
    fetch(this.props.optionsUrl, {
      credentials: 'include',
    }).then(response => response.json()).then((result) => {
      return result.workflow_templates;
    }).then((options) => {
      this.setState({
        options,
      });
    });
  }

  handleFocus = () => {
    this.loadOptions();
  }

  handleChange = (data) => {
    this.setState({
      value: data,
    });

    if (this.props.onChange) {
      this.props.onChange(data);
    }
  }

  render() {
    return (
      <Select
        components={{
          Option,
          SingleValue,
        }}
        isClearable
        value={this.state.value}
        openMenuOnFocus
        backspaceRemovesValue={false}
        onChange={this.handleChange}
        options={this.state.options}
        onFocus={this.handleFocus}
        filterOption={filterOption}
        defaultOptions
        placeholder={this.props.placeholder}
        loadingPlaceholder="Laden..."
        noOptionsMessage={() => 'Keine Ergebnisse'}
        loadingMessage={() => 'Laden...'}
        styles={this.props.size === 'small' ? smallStyles : defaultStyles}
      />
    );
  }
}

WorkflowTemplateSelector.propTypes = {
  optionsUrl: PropTypes.string.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  size: PropTypes.string,
};

WorkflowTemplateSelector.defaultProps = {
  placeholder: 'Bitte Workflow auswählen',
};

export default WorkflowTemplateSelector;

/* eslint-disable no-multi-spaces */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import NumericInput from './NumericInput';
import Select from 'react-select';

import { autoNumericOptionsDuration } from '../../config/auto_numeric_options';

import { defaultStyles, smallStyles } from '../../styles/select_styles';

const units = ['minute', 'hour', 'day', 'month', 'year'];
const unitNames = ['Minuten', 'Stunden', 'Tage', 'Monate', 'Jahre'];
const unitNamesGenitivum = ['Minuten', 'Stunden', 'Tagen', 'Monaten', 'Jahren'];
const secondsFactor = [60, 60, 24, 31, 12];

const options = units.map((u, i) => ({
  label: unitNames[i],
  value: units[i],
}));

const optionsGenitivum = units.map((u, i) => ({
  label: unitNamesGenitivum[i],
  value: units[i],
}));

function getSeconds(amount, unit) {
  for (let i = 0; i < units.length; i += 1) {
    if (unit.startsWith(units[i])) {
      return amount * secondsFactor.slice(0, i + 1).reduce((a, x) => a * x, 1);
    }
  }

  return 0;
}

function getDurationInUnit(diff, unitIndex) {
  return (diff / secondsFactor.slice(0, unitIndex + 1).reduce((a, x) => a * x, 1));
}

function getFullUnitIndex(diffAbs) {
  for (let i = units.length - 1; i >= 0; i -= 1) {
    const v = getDurationInUnit(diffAbs, i);

    if (v >= 1 && Number.isInteger(v)) {
      return i;
    }
  }

  return 0;
}

class DurationSelector extends Component {
  constructor(props) {
    super(props);

    const unitIndex = getFullUnitIndex(props.value);

    this.options = props.useGenitivum ? optionsGenitivum : options;

    this.state = {
      amount: props.value ? getDurationInUnit(props.value, unitIndex) : '',
      optionSelected: this.options[unitIndex],
    };
  }

  handleChange = () => {
    const unitIndex = this.options.indexOf(this.state.optionSelected);

    const seconds = getSeconds(this.state.amount, units[unitIndex]);

    if (this.props.onChange) {
      this.props.onChange(seconds);
    }
  }

  handleUnitChange = (value) => {
    this.setState({
      optionSelected: value,
    }, () => {
      this.handleChange();
    });
  }

  handleAmountChange = (e, v) => {
    this.setState({
      amount: v,
    }, () => {
      this.handleChange();
    });
  }

  render() {
    return (
      <div style={{ display: 'flex' }}>
        <NumericInput
          value={this.state.amount}
          onChange={this.handleAmountChange}
          className="form-control form-control-sm"
          style={{ width: 60 }}
          options={autoNumericOptionsDuration}
        />
        <div style={{ flexGrow: 1, marginLeft: 8 }}>
          <Select
            value={this.state.optionSelected}
            onChange={this.handleUnitChange}
            placeholder="Bitte auswählen"
            options={this.options}
            styles={this.props.size === 'small' ? smallStyles : defaultStyles}
          />
        </div>
      </div>
    );
  }
}

DurationSelector.propTypes = {
  value: PropTypes.number,
  size: PropTypes.string,
  onChange: PropTypes.func,
  useGenitivum: PropTypes.bool,
};

DurationSelector.defaultProps = {
  useGenitivum: true,
};

export default DurationSelector;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../shared/Icon';
import ConditionTypeSelector from '../ConditionTypeSelector';

class BaseComponent extends Component {
  handleChangeType = (value) => {
    this.props.onReplaceCondition(this.props.condition, {
      filter_condition_type: value,
      type: value.type,
      operator: '',
    });
  }

  handleRemoveClick = (e) => {
    e.preventDefault();
    this.props.onRemove(this.props.condition);
  }

  handleAddClick = (e) => {
    e.preventDefault();
    this.props.onAddCondition();
  }

  renderRemoveButton() {
    return (
      <button className="btn btn-xs btn-light" onClick={this.handleRemoveClick} style={{ fontWeight: 500, color: '#aaa' }} title="Kriterium entfernen">
        <Icon name="trash" />
      </button>
    );
  }

  render() {
    return (
      <div style={{
        display: 'flex',
        // marginLeft: 4,
        marginTop: this.props.isSingle ? 0 : 3,
        paddingTop: this.props.isSingle ? 0 : 3 }}>
        <div style={{ flexGrow: 1, display: 'flex' }}>
          <div style={{ flexBasis: 200 }}>
            <ConditionTypeSelector
              value={this.props.condition.filter_condition_type}
              onChange={this.handleChangeType}
              optionsUrl={this.props.optionsUrl}
            />
          </div>
          {this.props.children}
        </div>
        <div className="ms-2">
          <div className="btn-group">
            {this.renderRemoveButton()}
            <button className="btn btn-xs btn-light" onClick={this.handleAddClick} style={{ fontWeight: 500, color: '#aaa' }} title="Unterkriterium hinzufügen">
              <Icon name="plus" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

BaseComponent.propTypes = {
  children: PropTypes.node,
  condition: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onAddCondition: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onReplaceCondition: PropTypes.func.isRequired,
  isSingle: PropTypes.bool.isRequired,
  optionsUrl: PropTypes.string.isRequired,
};

export default BaseComponent;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import inputComponents from './DataPointInputs';
import displayComponents from './DataPointDisplay';

import Tooltip from '../shared/Tooltip';
import Icon from '../shared/Icon';

import { postAsync, updateAsync } from '../../helpers/rails_helper';

import { flashNotice, flashError } from '../../helpers/flash_helper';
import Dropdown from '../shared/Dropdown';
import DataPointHistory from './DataPointHistory';
import DataPointIconConfirmed from './DataPointIconConfirmed';
import DataPointIconHistory from './DataPointIconHistory';

class DataPoint extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activities: [],
      activitiesLoaded: false,
      editing: false,
      saving: false,
      value: props.initialDataPoint.value,
      status: props.initialDataPoint.status,
      dataPoint: props.initialDataPoint,
      dropdownOpen: false,
    };

    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleCancelEditClick = this.handleCancelEditClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleConfirmClick = this.handleConfirmClick.bind(this);
    this.toggleConfirmed = this.toggleConfirmed.bind(this);
    this.toggleConfirmed = this.toggleConfirmed.bind(this);
    this.handleDataPointSaved = this.handleDataPointSaved.bind(this);
    this.handleSavingError = this.handleSavingError.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
  }

  handleChange(newValue, isValid) {
    this.setState({
      value: newValue,
      status: 'unconfirmed',
      isValid,
    });
  }

  handleEditClick(e) {
    e.preventDefault();
    this.setState({
      editing: true,
      dropdownOpen: false,
    });
  }

  handleConfirmClick(_e) {
    if (this.state.dataPoint.confirmable) {
      this.setState({ saving: true }, () => {
        updateAsync(`/data_points/${this.state.dataPoint.id}`, {
          data_point: {
            status: 'confirmed',
          },
        }).then(this.handleDataPointSaved).catch(this.handleSavingError);
      });
    }
  }

  toggleConfirmed() {
    if (this.state.dataPoint.confirmable) {
      if (this.state.status === 'confirmed') {
        this.setState({ status: 'unconfirmed' });
      } else {
        this.setState({ status: 'confirmed' });
      }
    }
  }

  handleCancelEditClick(e) {
    e.preventDefault();
    this.setState({
      editing: false,
      value: this.state.dataPoint.value,
      status: this.state.dataPoint.status,
    });
  }

  handleDataPointSaved({ data_point }) {
    this.setState({
      editing: false,
      saving: false,
      dataPoint: data_point,
      value: data_point.value,
      status: data_point.status,
      activities: null,
      activitiesLoaded: false,
    });

    flashNotice('Änderung wurde gespeichert.');
  }

  handleSavingError(error) {
    this.setState({
      saving: false,
    });

    flashError(error.error);
  }

  handleUpdate() {
    this.setState({ saving: true }, () => {
      updateAsync(`/data_points/${this.state.dataPoint.id}`, {
        data_point: {
          value: this.state.value,
          status: this.state.status,
          data_point_template_id: this.props.initialDataPoint.data_point_template_id,
        },
      }).then(this.handleDataPointSaved).catch(this.handleSavingError);
    });
  }

  handleCreate() {
    this.setState({ saving: true }, () => {
      postAsync('/data_points', {
        data_point: {
          data_holder_type: this.props.dataHolderType,
          data_holder_id: this.props.dataHolderId,
          value: this.state.value,
          status: this.state.status,
          data_point_template_id: this.state.dataPoint.data_point_template_id,
        },
      }).then(this.handleDataPointSaved).catch(this.handleSavingError);
    });
  }

  handleSaveClick(e) {
    if (e) e.preventDefault();

    if (this.state.dataPoint.id) {
      this.handleUpdate();
    } else {
      this.handleCreate();
    }
  }


  renderConfirmedIcon() {
    return (<DataPointIconConfirmed dataPoint={this.state.dataPoint} />);
  }

  renderValue() {
    const Comp = displayComponents[`${this.state.dataPoint.type.split('::').pop()}Display`];

    return (
      <div style={{ padding: '6px 0' }}>
        <Comp
          value={this.state.dataPoint.value}
          dataPoint={this.state.dataPoint}
        />
      </div>
    );
  }

  renderConfirmCheckbox() {
    if (this.state.dataPoint.confirmable) {
      return (
        <div className="form-check">
          <input
            disabled={this.state.value === null}
            type="checkbox"
            checked={this.state.status === 'confirmed'}
            onChange={() => this.toggleConfirmed()}
            id={`confirmed-${this.props.index}`}
            className="form-check-input" />
          <label htmlFor={`confirmed-${this.props.index}`} className="form-check-label">
            Bestätigt
          </label>
        </div>
      );
    }

    return null;
  }

  renderInput() {
    const Comp = inputComponents[`${this.state.dataPoint.type.split('::').pop()}Input`];

    return (
      <>
        <Comp
          value={this.state.value}
          dataPoint={this.state.dataPoint}
          onChange={this.handleChange}
          onSave={this.handleSaveClick}
          saving={this.state.saving}
          editing={this.state.editing}
          dataHolderType={this.props.dataHolderType}
          dataHolderId={this.props.dataHolderId}
          autoFocus
          organizationSlug={this.props.organizationSlug} />
        {this.renderConfirmCheckbox()}
      </>
    );
  }

  renderEditButton() {
    if (!this.props.editable) return null;

    return (
      <button type="button" className="btn btn-link" onClick={this.handleEditClick}>
        <span className="d-none d-md-inline">bearbeiten</span><i className="fa-solid fa-edit d-md-none" />
      </button>
    );
  }

  renderDeprecatedIcon() {
    if (!this.state.dataPoint.is_deprecated) return null;

    return (
      <span className="ms-1">
        <Tooltip title="Dieser Datenpunkt ist veraltet und sollte nicht mehr verwendet werden." placement="top">
          <Icon name="warning" color="orange" />
        </Tooltip>
      </span>
    );
  }

  renderDescIcon() {
    if (!this.state.dataPoint.desc) return null;

    return (
      <span className="ms-1">
        <Tooltip
          placement="top"
          enabled={Boolean(this.state.dataPoint.desc)}
          title={(
            <div style={{ width: '300px', textAlign: 'left' }}>
              {this.state.dataPoint.desc}
            </div>
          )}
        >
          <Icon name="circle-info" color="var(--bs-tertiary-color)" />
        </Tooltip>
      </span>
    );
  }

  renderConfirmButton() {
    if (
      this.state.status !== 'confirmed'
      && !this.state.editing
      && this.state.dataPoint.confirmable
    ) {
      return (
        <button
          type="button"
          className="btn btn-link me-2"
          onClick={this.handleConfirmClick}
          disabled={this.state.value === null || this.state.value?.length === 0}
        >
          <span className="d-none d-md-inline">bestätigen</span><i className="fa-solid fa-check d-md-none" />
        </button>
      );
    }

    return null;
  }

  renderHistory() {
    return (<DataPointIconHistory dataPoint={this.state.dataPoint} />);
  }

  render() {
    return (
      <div className="datapoint">
        <div className={classNames({ 'datapoint-title': true, display: !this.state.editing })}>
          <span className={classNames({ 'datapoint-title-text': true, display: !this.state.editing })} title={this.state.dataPoint.title}>
            {this.state.dataPoint.title}
          </span>

          {this.renderDescIcon()}
          {this.renderConfirmedIcon()}
          {this.renderDeprecatedIcon()}
        </div>

        <div className="datapoint-value">
          {this.state.editing ? (
            this.renderInput()
          ) : (
            this.renderValue()
          )}
        </div>

        <div
          className="datapoint-actions"
        >
          {this.state.editing ? (
            <div>
              <button
                type="button"
                className={`btn btn-success ${this.state.saving ? 'btn-loading' : ''}`}
                onClick={this.handleSaveClick}
                disabled={this.state.saving || this.state.isValid === false}>
                speichern
              </button>
              {' '}
              <button
                type="button"
                className="btn btn-link"
                onClick={this.handleCancelEditClick}
                disabled={this.state.saving}>
                abbrechen
              </button>
            </div>
          ) : (
            <div className="">
              <div className="datapoint-actions-hover me-2">
                <div className="btn-group">
                  {this.renderConfirmButton()}
                  {this.renderEditButton()}
                </div>
              </div>
              <div className={`datapoint-actions-hover ${this.state.dropdownOpen ? 'd-inline-block' : ''}`}>
                {this.renderHistory()}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

DataPoint.propTypes = {
  initialDataPoint: PropTypes.object.isRequired,
  dataHolderType: PropTypes.string.isRequired,
  dataHolderId: PropTypes.number.isRequired,
  editable: PropTypes.bool.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default DataPoint;

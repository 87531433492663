import React from 'react';
import Icon from '../../shared/Icon';

const ArchvieProjectEffectForm = () => (
  <div className="fire-effect-item">
    <div className="fire-effect-icon">
      <Icon name="archive" />
    </div>
    <div className="fire-effect-content">
      <div className="fire-effect-title">
        Projekt wird archiviert
      </div>
    </div>
  </div>
);

export default ArchvieProjectEffectForm;

import React from 'react';

import BaseCondition from './BaseCondition';

class NullCondition extends React.Component {
  render() {
    return (
      <BaseCondition {...this.props} />
    );
  }
}

export default NullCondition;

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import TodoTemplate from './TodoTemplate';
import AddTodoButton from './AddTodoButton';

import { Droppable } from 'react-beautiful-dnd';

const getListStyle = isDraggingOver => ({
  // background: '#fff',
  // padding: grid,
  // borderRadius: 6,
  // width: '100%',
});

class WorkflowTemplateTodos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sorting: false,
      savingOrder: false,
      orderSaved: false,
    };

    this.handleSortClick = this.handleSortClick.bind(this);
    this.handleSaveSortClick = this.handleSaveSortClick.bind(this);
  }

  handleTodoSelected = (todo) => {
    this.props.onTodoSelected(todo);
  };

  handleTodoDeleted = (deletedTodo) => {
    // Todo aus der Liste der Todos entfernen,
    // zusätzlich betroffene Todo-Aktivierungen entfernen
    let todos = this.props.todos.filter(todo => (
      (todo.id !== deletedTodo.id)
    ));

    todos = todos.map(todo => Object.assign({}, todo, {
      actions: todo.actions.map(action => Object.assign({}, action, {
        todo_activations: action.todo_activations.filter(t => t.todo_id !== deletedTodo.id),
      })),
      active: todo.initially_active || !todos.some(t => (
        t.actions.some(action => (
          action.todo_activations.some(ta => ta.todo_id === todo.id)
        ))
      )),
    }));

    this.props.onChange(todos);
  };

  handleTodoAdded = (data) => {
    const todos = [...this.props.todos, data.todo];
    this.props.onChange(todos, data.todo.id);
  };

  handleSortClick(e) {
    e.preventDefault();
    this.setState({ sorting: true });
  }

  handleSaveSortClick(e) {
    e.preventDefault();
    this.setState({
      sorting: false,
      savingOrder: false,
      orderSaved: false,
    });
  }

  renderTodos() {
    return this.props.todos.map((todo, i) => (
      <TodoTemplate
        key={todo.id}
        selected={todo.id === this.props.selectedTodoId}
        index={i}
        editable={this.props.editable}
        todo={todo}
        onTodoUpdated={this.props.onTodoUpdated}
        onTodoDeleted={this.handleTodoDeleted}
        onTodoSelected={this.handleTodoSelected}
        organizationSlug={this.props.organizationSlug}
        workflowType={this.props.workflowType}
      />
    ));
  }

  // renderSortingButtons() {
  //   return (
  //     <div style={{ display: 'inline-block', marginLeft: 4 }}>
  //       {this.state.sorting ? (
  //         <button
  //           onClick={this.handleSaveSortClick}
  //           className="btn btn-sm btn-compact btn-success">
  //           Fertig
  //         </button>
  //       ) : null}
  //       {this.props.editable && !this.state.sorting && this.props.todos.length ? (
  //         <button
  //           onClick={this.handleSortClick}
  //           className="btn btn-sm btn-compact btn-light">
  //           Aufgaben sortieren
  //         </button>
  //       ) : null}
  //       <small className="text-muted fs-5">
  //         {this.state.savingOrder ? ' speichern...' : null }
  //         {this.state.orderSaved ? ' gespeichert' : null }
  //       </small>
  //     </div>
  //   );
  // }

  renderDesc() {
    if (this.props.todos.length) return null;

    return (
      <div>
        <p className="text-muted">
          Beginne, indem du Aufgaben hinzufügst. Die Reihenfolge kannst du später noch ändern.
        </p>
        <p>
          <AddTodoButton
            organizationSlug={this.props.organizationSlug}
            workflowId={this.props.workflowId}
            workflowType={this.props.workflowType}
            onSaved={this.handleTodoAdded}
            className="btn btn-success btn-lg"
            text="Aufgabe hinzufügen"
          />
        </p>
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        <h3 className="mb-3">
          Aufgaben
        </h3>

        <Droppable droppableId="workflow">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}>
              {this.renderTodos()}
              {provided.placeholder}
            </div>
          )}
        </Droppable>

        {this.renderDesc()}

        <AddTodoButton
          organizationSlug={this.props.organizationSlug}
          visible={this.props.editable && !this.state.sorting && !!this.props.todos.length}
          workflowId={this.props.workflowId}
          workflowType={this.props.workflowType}
          onSaved={this.handleTodoAdded}
        />
      </Fragment>
    );
  }
}

WorkflowTemplateTodos.propTypes = {
  organizationSlug: PropTypes.string.isRequired,
  selectedTodoId: PropTypes.number,
  workflowId: PropTypes.number.isRequired,
  workflowType: PropTypes.string.isRequired,
  todos: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onTodoSelected: PropTypes.func.isRequired,
  editable: PropTypes.bool,
};

export default WorkflowTemplateTodos;

/* eslint-disable quote-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import { dropdownStyles } from '../../styles/select_styles';

import Dropdown from '../shared/Dropdown';
import Icon from '../shared/Icon';

import { subscribe, unsubscribe } from '../../helpers/actioncable_helper';

import { updateAsync } from '../../helpers/rails_helper';

const noState = {
  state: 'Kein Projektstatus',
  id: null,
  state_ungrouped: 'Kein Projektstatus',
  color: 'var(--bs-gray-600)',
  text_color: 'white',
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <Icon name="circle" color={props.data.color} /> {props.data.state_ungrouped}
    </components.Option>
  );
};

class ProjectState extends Component {
  constructor(props) {
    super(props);

    this.state = {
      project_state: this.props.project_state,
      dropDownVisible: false,
    };
  }

  componentDidMount() {
    this.stateChangesChannel = subscribe({
      channel: 'ProjectStateChangesChannel',
      project_id: this.props.project_id,
    }, ({ project_state }) => {
      this.handleStateChanged(project_state);
    });
  }

  componentWillUnmount() {
    unsubscribe(this.stateChangesChannel);
  }

  getOptions = (searchTerm) => {
    const url = `${this.props.options_url}?grouped=1&only_active=1&q=${encodeURIComponent(searchTerm)}`;

    return fetch(url, {
      credentials: 'include',
    }).then((response) => {
      return response.json();
    }).then((options) => {
      if (options.project_state_groups.length) {
        return [{
          label: '',
          options: [noState],
        }, ...options.project_state_groups];
      }

      return [];
    });
  };

  getStyle = () => {
    const color = this.state.project_state ? this.state.project_state.color : noState.color;
    const textColor = this.state.project_state ? this.state.project_state.text_color : noState.text_color;

    return {
      fontWeight: 'bold',
      color: textColor,
      border: `1px solid ${color}`,
      backgroundColor: color,
      marginRight: 5,
    };
  };

  toggleDropDown = (e) => {
    e.preventDefault();
    this.setState({ dropDownVisible: !this.state.dropDownVisible });
  };

  handleChange = (project_state) => {
    const url = `/projects/${this.props.project_id}/update_state`;
    updateAsync(url, {
      state_id: project_state.id,
    }, 'PUT').then(() => {
      this.handleStateChanged(project_state);
    });
  };

  handleStateChanged = (project_state) => {
    this.setState({
      project_state: project_state?.id ? project_state : noState,
    });
  };

  handleBlur = () => {
    this.setState({ dropDownVisible: false });
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      this.toggleDropDown(e);
    }
  };

  renderStatic() {
    return (
      <div>
        <span className="badge" style={this.getStyle()}>
          {this.state.project_state?.id ?
            `Status: ${this.state.project_state.state}`
            : 'Kein Status'}
        </span>
      </div>
    );
  }

  renderButton = (ref) => {
    return (
      <button ref={ref} style={this.getStyle()} className="btn btn-light btn-label" onClick={this.toggleDropDown}>
        {this.state.project_state?.id ?
          `Status: ${this.state.project_state.state}`
          : 'Kein Status'} <Icon name="caret-down" />
      </button>
    );
  };

  renderEditable() {
    return (
      <Dropdown
        isOpen={this.state.dropDownVisible}
        onClose={this.toggleDropDown}
        target={this.renderButton}>
        <AsyncSelect
          autoFocus
          components={{
            Option,
            DropdownIndicator: null,
            IndicatorSeparator: null,
          }}
          openMenuOnFocus
          clearable={false}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
          backspaceRemovesValue={false}
          blurInputOnSelect
          menuPlacement="auto"
          menuIsOpen
          onChange={this.handleChange}
          handleInputChange={this.handleInputChange}
          loadOptions={this.getOptions}
          defaultOptions
          styles={dropdownStyles}
          placeholder="Status auswählen"
          loadingPlaceholder="Laden..."
          noOptionsMessage={() => 'Keine Ergebnisse'}
          loadingMessage={() => 'Laden...'}
        />
      </Dropdown>
    );
  }

  render() {
    if (this.props.editable) {
      return this.renderEditable();
    }

    return this.renderStatic();
  }
}

ProjectState.propTypes = {
  project_id: PropTypes.number.isRequired,
  project_state: PropTypes.object,
  options_url: PropTypes.string.isRequired,
  editable: PropTypes.bool,
};

export default ProjectState;

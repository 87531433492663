/* global Rails */

/* global XMLHttpRequest, FormData */

/*
  Der Upload-Prozess hat verschiedene Stadien:
  - waiting: Warten auf Beginn des Upload (z.B. wenn noch ein anderer Upload läuft)
  - uploading: Während des Uploads
  - processing: Upload abgeschlossen, Server verarbeitet Dateien
  - stored: Datei wurde erfolgreich gespeichert
  - failed: Upload ist fehlgeschlagen
*/

import csrf_token from '../../initializers/csrf_token';
import { generateId } from '../../helpers/helpers';

const dummyImages = [
  '/dummy/dummy-01.jpg',
  '/dummy/dummy-02.jpg',
  '/dummy/dummy-03.jpg',
  '/dummy/dummy-04.jpg',
  '/dummy/dummy-05.jpg',
];

function DummyUpload(options) {
  this.status = 'waiting';
  this.name = 'Dummy File';
  this.progress = 0;
  this.progressProcessing = 0;

  this.isDummy = true;

  this.start = () => {
    this.interval = setInterval(() => {
      if (this.status === 'waiting') {
        this.status = 'uploading';
        if (options.onStatusChanged) options.onStatusChanged(this.status);
      } else if (this.status === 'uploading') {
        if (this.progress < 1) {
          this.progress = this.progress + 0.04;
          if (options.onProgress) options.onProgress();
        } else {
          this.status = 'processing';
        }
        if (options.onStatusChanged) options.onStatusChanged(this.status);
      } else if (this.status === 'processing') {
        if (this.progressProcessing < 1) {
          this.progressProcessing = this.progressProcessing + 0.05;
        } else {
          this.status = 'stored';
          this.thumb = dummyImages[Math.floor(Math.random() * dummyImages.length)];
          if (options.onStatusChanged) options.onStatusChanged(this.status);
        }
      } else {
        clearInterval(this.interval);
      }
    }, 100);
  };

  this.delete = () => {
    console.log('deleting dummy file');
  };

  return this;
}

function Upload(file, options = {}) {
  this.file = file;
  this.temp_id = generateId();
  this.status = 'waiting';
  this.name = file.name;
  this.progress = 0;

  const xhr = new XMLHttpRequest();
  const formData = new FormData();

  const url = options.url || '/attachments';
  const fileField = options.fileField || 'attachment[document_attributes][file]';

  this.isUploadedOrHasFailed = () => {
    return ['stored', 'processing', 'failed'].includes(this.status);
  };

  formData.append(fileField, file);

  if (options.fields) {
    Object.entries(options.fields).forEach((data) => {
      formData.append(`attachment[${data[0]}]`, data[1]);
    });
  }

  this.start = () => {
    xhr.open('POST', url);

    xhr.setRequestHeader('X-CSRF-Token', csrf_token());
    xhr.setRequestHeader('Accept', 'application/json');

    xhr.onload = (event) => {
      if (options.onload) options.onload(event);
      if (options.onStatusChanged) options.onStatusChanged(this.status);
    };

    xhr.upload.onprogress = (event) => {
      this.progress = event.loaded / event.total;
      if (options.onProgress) options.onProgress(event.loaded);
      if (event.loaded / event.total === 1) {
        this.status = 'processing';
        if (options.onStatusChanged) options.onStatusChanged(this.status);
      }
    };

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200 || xhr.status === 201) {
          this.status = 'stored';
          const jsonResponse = JSON.parse(xhr.responseText);

          this.document = jsonResponse.document;
          this.sgid = jsonResponse.document.sgid;
          this.thumb = jsonResponse.document.thumb;
          this.document_id = jsonResponse.document.id;

          if (options.onDone) options.onDone(jsonResponse);
          if (options.onStatusChanged) options.onStatusChanged(this.status);
          if (options.onDone) options.onDone(jsonResponse);
        } else {
          this.status = 'failed';

          if (options.onStatusChanged) options.onStatusChanged(this.status);
          if (options.onerror) options.onerror(xhr.statusText, xhr.responseText);

          console.log('Error', xhr.statusText, xhr.responseText);
        }
      }
    };

    xhr.send(formData);

    this.status = 'uploading';

    if (options.onStatusChanged) options.onStatusChanged(this.status);
  };

  return this;
}

export default function UploadQueue(ref) {
  const self = this;

  this.done = true;
  this.name = ref;
  this.status = 'idle';
  this.uploads = [];

  const eventListeners = {
    onUploadsChanged: [],
    onFileAdded: [],
    onFileRemoved: [],
    onQueueDone: [],
    onUploadRemoved: [],
    onUploadDone: [],
  };

  const runHandlers = (eventName, payload) => {
    eventListeners[eventName].forEach((handler) => {
      handler(payload);
    });
  };

  this.startNext = () => {
    if (!self.uploads.map(u => u.status).includes('uploading')) {
      const waitingUploads = self.uploads.filter(u => u.status === 'waiting');
      if (waitingUploads.length) {
        waitingUploads[0].start();
        self.status = 'running';
      }
    }
  };

  this.addListener = (eventName, handler) => {
    eventListeners[eventName].push(handler);
  };

  this.removeListener = (eventName, handler) => {
    const index = eventListeners[eventName].indexOf(handler);

    if (index > -1) {
      eventListeners[eventName].splice(index, 1);
    }
  };

  this.setQueueDone = () => {
    if (!this.done && !self.uploads.some(u => ['waiting', 'uploading', 'processing'].includes(u.status))) {
      self.done = true;
      self.status = 'idle';
      runHandlers('onQueueDone');
    }
  };

  this.addFile = (file, options = {}) => {
    const upload = new Upload(file, {
      fields: options.fields,
      url: options.url,
      fileField: options.fileField,
      ref,
      onStatusChanged() {
        runHandlers('onUploadsChanged', self.uploads);
        self.setQueueDone();
        self.startNext();
      },
      onProgress() {
        runHandlers('onUploadsChanged', self.uploads);
      },
      onDone(newFile) {
        runHandlers('onUploadDone', newFile);
      },
    });

    self.uploads.push(upload);
    self.done = false;

    runHandlers('onUploadsChanged', self.uploads);
    runHandlers('onFileAdded');
  };

  this.addFiles = (files, options) => {
    files.forEach(file => self.addFile(file, options));
  };

  this.addDummyFile = () => {
    const upload = new DummyUpload({
      onStatusChanged() {
        runHandlers('onUploadsChanged', self.uploads);
        self.setQueueDone();
        self.startNext();
      },
      onProgress() {
        runHandlers('onUploadsChanged', self.uploads);
      },
    });

    self.uploads.push(upload);
    self.done = false;

    runHandlers('onUploadsChanged', self.uploads);
    runHandlers('onFileAdded');
  };

  this.clearQueue = () => {
    this.uploads = [];
    runHandlers('onUploadsChanged', self.uploads);
  };

  return this;
}

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Icon from '../../shared/Icon';

import inputComponents from './../../DataPoints/DataPointInputs';

import Tooltip from '../../shared/Tooltip';

class SetDataPointEffectForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleConfirmedChangeClick = this.handleConfirmedChangeClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value, isValid) {
    this.setState({ isValid }, () => {
      this.props.onChange({ content: value, status: 'unconfirmed' }, this.state.isValid);
    });
  }

  handleConfirmedChangeClick(e) {
    this.props.onChange(
      { status: e.target.checked ? 'confirmed' : 'unconfirmed', content: this.props.value.content },
      this.state.isValid,
    );
  }

  renderConfirmationCheckbox() {
    let updatedAt;
    let updatedBy;

    if (this.props.effect.data_point.confirmable) {
      if (
        this.props.effect.data_point.status_updated_at
        && this.props.effect.data_point.status_updated_by_user_id
      ) {
        updatedAt = moment(this.props.effect.data_point.status_updated_at).locale('de').format('DD. MMMM YYYY kk:mm');
        updatedBy = this.props.effect.data_point.status_updated_by_user_fullname;
      }

      return (
        <div className="form-check">
          <Tooltip
            enabled={Boolean(updatedAt && updatedBy)}
            title={
              (<div>
                bestätigt von {updatedBy}<br />
                am {updatedAt} Uhr
              </div>)
            }>
            <input
              disabled={this.props.value?.content === null}
              type="checkbox"
              checked={this.props.value.status === 'confirmed'}
              onChange={v => this.handleConfirmedChangeClick(v)}
              id={`confirmed-${this.props.effect.id}`}
              className="form-check-input" />
            <label htmlFor={`confirmed-${this.props.effect.id}`} className="form-check-label">
              Bestätigt
            </label>
          </Tooltip>
        </div>
      );
    }

    return null;
  }

  renderInput() {
    if (this.props.effect.options.content === 'current_datetime') {
      return (
        <div className="text-muted">Wird automatisch gesetzt</div>
      );
    }

    if (this.props.effect.options.set_auto_value && this.props.effect.options.auto_value) {
      return (
        <div className="text-muted">Wird geändert zu: {this.renderAutoValue()}</div>
      );
    }

    const Comp = inputComponents[`${this.props.effect.data_point_template.data_point_type.split('::').pop()}Input`];

    return (
      <>
        <Comp
          value={this.props.value?.content}
          dataPoint={this.props.effect.data_point}
          onChange={this.handleChange}
          onSave={this.handleSaveClick}
          organizationSlug={this.props.organizationSlug}
          dataHolderType={this.props.dataHolderType}
          dataHolderId={this.props.dataHolderId}
          editing
          dataPointEffectIsOptional={!!this.props.effect.options.optional}
        />
        {this.renderConfirmationCheckbox()}
      </>
    );
  }

  renderAutoValue() {
    if (this.props.effect.options.auto_value === 'true') {
      return 'Ja';
    }

    if (this.props.effect.options.auto_value === 'false') {
      return 'Nein';
    }

    if (this.props.effect.data_point_template.data_point_type === 'DataPoint::StringChoiceDataPoint') {
      return this.props.effect.auto_value_record?.string_value;
    }

    return this.props.effect.options.auto_value;
  }

  renderMandatory() {
    if (this.props.effect.options.optional) return null;

    return (
      <span className="fire-effect-mandatory" title="Pflichtangabe">*</span>
    );
  }

  render() {
    return (
      <div className="fire-effect-item">
        <div className="fire-effect-icon">
          <Icon name="database" />
        </div>
        <div className="fire-effect-content">
          <div className="fire-effect-title">
            {this.props.effect.data_point_template.title}
            {this.renderMandatory()}
          </div>
          <div className="fire-effect-inputs">
            {this.renderInput()}
          </div>
        </div>
      </div>
    );
  }
}

SetDataPointEffectForm.propTypes = {
  dataHolderType: PropTypes.string.isRequired,
  dataHolderId: PropTypes.number.isRequired,
  effect: PropTypes.object.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};


export default SetDataPointEffectForm;

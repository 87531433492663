import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import Tooltip from '../shared/Tooltip';

import { postAsync, deleteAsync } from '../../helpers/rails_helper';

const PinBadge = (props) => {
  const [isPinnedForMe, setIsPinnedForMe] = useState(props.pinnedForMe);
  const [isPinnedForEveryone, setIsPinnedForEveryone] = useState(props.pinnedForEveryone);

  const isPinned = isPinnedForMe || isPinnedForEveryone;

  const handlePinForMeClick = () => {
    postAsync('/pins', {
      pin_params: {
        pinnable_sgid: props.pinnableSgid,
      },
    }).then(() => {
      setIsPinnedForMe(true);
      document.dispatchEvent(new CustomEvent('project:pinned_notes:changed'));
    });
  };

  const handlePinForEveryoneClick = () => {
    postAsync('/pins', {
      pin_params: {
        pinnable_sgid: props.pinnableSgid,
        pinned_for_everyone: true,
      },
    }).then(() => {
      setIsPinnedForEveryone(true);
      document.dispatchEvent(new CustomEvent('project:pinned_notes:changed'));
    });
  };

  const handleUnpinClick = () => {
    deleteAsync(`/pins/${props.pinnableSgid}`).then(() => {
      setIsPinnedForMe(false);
      setIsPinnedForEveryone(false);
      document.dispatchEvent(new CustomEvent('project:pinned_notes:changed'));
    });
  };

  const pinForMe = isPinned ? null : (
    <li>
      <button className="dropdown-item" onClick={handlePinForMeClick}>
        <Icon name="user" fixedWidth /> Für mich anpinnen
      </button>
    </li>
  );

  const pinForEveryone = isPinned ? null : (
    <li>
      <button className="dropdown-item" onClick={handlePinForEveryoneClick}>
        <Icon name="users" fixedWidth /> Für alle anpinnen
      </button>
    </li>
  );

  const unpin = !isPinned ? null : (
    <li>
      <button className="dropdown-item" onClick={handleUnpinClick}>
        <Icon name="trash" fixedWidth /> Nicht mehr anpinnen
      </button>
    </li>
  );

  const buttonStyle = {
    padding: '0 8px',
    fontSize: 13,
  };

  const button = isPinned ? (
    <button className="btn btn-danger rounded-pill" style={buttonStyle} type="button" data-bs-toggle="dropdown" aria-expanded="false">
      <Icon name="location-exclamation" /> {isPinnedForEveryone ? 'für alle angepinnt' : 'für mich angepinnt'}
    </button>
  ) : (
    <button className="btn btn-link btn-compact" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      <Icon name="location-exclamation" />
    </button>
  );

  return (
    <Tooltip title="Notiz anpinnen" placement="top" enabled={!isPinned}>
      <div className="btn-group d-inline">
        {button}
        <ul className="dropdown-menu">
          {pinForMe}
          {pinForEveryone}
          {unpin}
        </ul>
      </div>
    </Tooltip>
  );
};

PinBadge.propTypes = {
  pinnableSgid: PropTypes.string.isRequired,
  pinnedForMe: PropTypes.bool.isRequired,
  pinnedForEveryone: PropTypes.bool.isRequired,
};

export default PinBadge;

import React from 'react';
import PropTypes from 'prop-types';
import OfferSelector from './OfferSelector';
import HiddenInputWrapper from '../shared/HiddenInputWrapper';

const OfferSelect = (props) => {
  const { value, name, ...rest } = props;

  return (
    <HiddenInputWrapper
      {...rest}
      comp={OfferSelector}
      name={name}
      value={value}
      placeholder="Angebot auswählen"
      transformValue={v => v?.id}
      autoFocus={false}
    />
  );
};

OfferSelect.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  status: PropTypes.string,
  optionsUrl: PropTypes.string.isRequired,
};

OfferSelector.defaultProps = {
  value: '',
};

export default OfferSelect;

import Validator from './validator';

const effectTemplateValidator = new Validator({
  rules: [
    {
      field: 'role_template_id',
      validator: 'presence',
      message: 'Bitte Name der Rolle ausfüllen',
      conditions: [{
        field: 'type', is: 'Effects::SetRoleEffect',
      }],
    },
    {
      field: 'analytics_hit_id',
      validator: 'presence',
      message: 'Bitte Analytics Hit ausfüllen',
      conditions: [{
        field: 'type', is: 'Effects::AnalyticsHitEffect',
      }],
    },
    {
      field: 'google_ads_conversion_id',
      validator: 'presence',
      message: 'Bitte Google Ads Conversion ausfüllen',
      conditions: [{
        field: 'type', is: 'Effects::GoogleAdsConversionEffect',
      }],
    },
    {
      field: 'affiliate_sale_id',
      validator: 'presence',
      message: 'Bitte Affiliate Sale ausfüllen',
      conditions: [{
        field: 'type', is: 'Effects::AffiliateSaleEffect',
      }],
    },
    {
      field: 'data_point_template_id',
      validator: 'presence',
      message: 'Bitte Datenpunkt auswählen',
      conditions: [{
        field: 'type', is: 'Effects::SetDataPointEffect',
      }],
    },
    {
      field: 'data_point_template_id',
      validator: 'presence',
      message: 'Bitte Datenpunkt auswählen',
      conditions: [{
        field: 'type', is: 'Effects::DeleteDataPointEffect',
      }],
    },
    {
      field: 'tag_id',
      validator: 'presence',
      message: 'Bitte Tag auswählen',
      conditions: [{
        field: 'type', is: 'Effects::AddTagEffect',
      }],
    },
    {
      field: 'tag_id',
      validator: 'presence',
      message: 'Bitte Tag auswählen',
      conditions: [{
        field: 'type', is: 'Effects::RemoveTagEffect',
      }],
    },
    {
      field: 'workflow_template_id',
      validator: 'presence',
      message: 'Bitte Workflow auswählen',
      conditions: [{
        field: 'type', is: 'Effects::AddWorkflowEffect',
      }],
    },
    {
      field: 'title',
      validator: 'presence',
      message: 'Bitte Name angeben',
      conditions: [{
        field: 'type', is: 'Effects::RenameWorkflowEffect',
      }],
    },
    {
      field: 'voice_agent_type',
      validator: 'presence',
      message: 'Bitte Agent auswählen',
      conditions: [{
        field: 'type', is: 'Effects::StartVoiceAgentEffect',
      }],
    },
  ],
});

export default effectTemplateValidator;

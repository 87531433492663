import React from 'react';
import PropTypes from 'prop-types';

import WorkflowEditorshipForm from './WorkflowEditorshipForm';
import RemoteFormModal from '../shared/RemoteFormModal';

import workflowEditorshipValidator from '../../validators/workflow_editorship_validator';
import { saveWorkflowEditorship } from '../../helpers/api';

const EditorModal = props => (
  <RemoteFormModal {...props}
    allowOverflow
    titleNew="Benutzer hinzufügen"
    validator={workflowEditorshipValidator}
    form={WorkflowEditorshipForm}
    initialRecord={props.initialWorkflowEditorship}
    saveHandler={saveWorkflowEditorship}
  />
);

EditorModal.propTypes = {
  initialWorkflowEditorship: PropTypes.object.isRequired,
};

export default EditorModal;

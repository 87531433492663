/* global fetch */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Select, { components } from 'react-select';

import { smallStyles } from '../../styles/select_styles';
import { searchText } from '../../helpers/helpers';

const getOptions = (optionsUrl, callback) => {
  return fetch(optionsUrl, {
    credentials: 'include',
  }).then((response) => {
    return response.json();
  }).then((options) => {
    callback(options.condition_types);
  });
};

const filterOption = (option, searchTerm) => {
  return searchText(option.data.title, searchTerm);
};

const Option = props => (
  <components.Option {...props}>
    <span title={props.data.title}>
      {props.data.title}
    </span>
  </components.Option>
);

const SingleValue = props => (
  <components.SingleValue {...props}>
    <span title={props.data.title}>
      {props.data.title}
    </span>
  </components.SingleValue>
);

class ConditionTypeSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      options: [],
    };
  }

  loadOptions = () => {
    this.setState({ isLoading: true });

    getOptions(this.props.optionsUrl, (options) => {
      this.setState({
        options, isLoading: false,
      });
    });
  };

  handleFocus = () => {
    this.loadOptions();
  };

  handleChange = (value) => {
    this.props.onChange(value);
  };

  render() {
    return (
      <Select
        components={{
          Option,
          SingleValue,
          ClearIndicator: null,
          LoadingIndicator: null,
        }}
        isLoading={this.state.isLoading}
        onFocus={this.handleFocus}
        isClearable
        backspaceRemovesValue={false}
        blurInputOnSelect
        options={this.state.options}
        menuPlacement="auto"
        onChange={this.handleChange}
        filterOption={filterOption}
        styles={smallStyles}
        value={this.props.value}
        placeholder={this.props.placeholder || 'Filter auswählen'}
        loadingPlaceholder="Laden..."
        noOptionsMessage={() => 'Keine Ergebnisse'}
        loadingMessage={() => 'Laden...'}
      />
    );
  }
}

ConditionTypeSelector.propTypes = {
  onChange: PropTypes.func,
  optionsUrl: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.object,
};


export default ConditionTypeSelector;

import { Controller } from '@hotwired/stimulus';
import ReactRailsUJS from 'react_ujs';
import 'regenerator-runtime/runtime';

export default class extends Controller {
  static targets = [
    'deliveryDateStatus',
    'deliveryTypeId',
    'submit',
    'checkbox',
    'field',
    'submitWrapper',
  ];

  connect() {
    ReactRailsUJS.handleMount();
    document.addEventListener('changedConfirmationRequestedDeliveryAt', this.#changeRequestedDeliveryAt);
    this.orderRequestsChecked = false;
    this.requestedDeliveryAt = this.data.get('deliveryAt');
    this.#toggleOrderRequests();
    this.enableForm();
  }

  // eslint-disable-next-line class-methods-use-this
  disconnect() {
    ReactRailsUJS.handleUnmount();
  }

  handleCheckboxChange() {
    this.#toggleOrderRequests();
    this.enableForm();
  }

  enableForm() {
    if (!this.orderRequestsChecked) {
      this.submitTarget.disabled = true;
      this.submitWrapperTarget.title = 'Eine Bestellanforderung muss ausgewählt sein';
    } else if (!this.requestedDeliveryAt) {
      this.submitTarget.disabled = true;
      this.submitWrapperTarget.title = 'Ein Anlieferdatum muss ausgewählt sein';
    } else if (!this.deliveryDateStatusTarget.value) {
      this.submitTarget.disabled = true;
      this.submitWrapperTarget.title = 'Ein Status für das Anlieferdatum muss ausgewählt sein';
    } else if (!this.deliveryTypeIdTarget.value) {
      this.submitTarget.disabled = true;
      this.submitWrapperTarget.title = 'Eine Lieferart muss ausgewählt sein';
    } else {
      this.submitTarget.disabled = false;
      this.submitWrapperTarget.title = '';
    }
  }

  #toggleOrderRequests() {
    const checkedIds = new Set(
      this.checkboxTargets
        .filter(target => target.checked)
        .map(checkbox => checkbox.dataset.orderRequestId),
    );

    this.fieldTargets.forEach(field => {
      // eslint-disable-next-line no-param-reassign
      field.disabled = !checkedIds.has(field.value);
    });

    this.orderRequestsChecked = checkedIds.size > 0;
  }

  #changeRequestedDeliveryAt = (event) => {
    this.requestedDeliveryAt = event.detail.value;
    this.enableForm();
  };
}

import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';

import Tooltip from '../shared/Tooltip';
import EmailSignatureSelector from '../EmailSignatures/EmailSignatureSelector';

import { getAsync } from '../../helpers/rails_helper';

class NewEmailSignature extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectorOpen: false,
      options: [],
    };
  }

  componentDidMount() {
    this.loadOptions();
  }

  loadOptions = () => {
    getAsync(this.props.optionsUrl).then((result) => {
      this.setState({
        options: result.email_signatures,
      });

      if (result.email_signatures && result.email_signatures.length) {
        this.props.onChange(result.email_signatures[0].id);
      } else {
        this.props.onChange(null);
      }
    });
  };

  handleClearClick = (e) => {
    e.preventDefault();
    this.props.onChange(null);
  };

  handleAddClick = (e) => {
    e.preventDefault();
    this.setState({ selectorOpen: true });
  };

  handleEmailSignaureChange = (newId) => {
    this.props.onChange(newId);
  };

  handleCancelSelect = () => {
    this.setState({ selectorOpen: false });
  };

  renderSignature() {
    if (!this.props.value) return null;

    const signature = this.state.options.find(s => s.id === this.props.value);

    if (signature) {
      return (
        <div className="compose-signature-content" dangerouslySetInnerHTML={{ __html: signature.html }} />
      );
    }
    return null;
  }

  renderRemoveButton() {
    if (!this.props.editable) return null;
    if (this.state.selectorOpen) return null;
    if (!this.props.value) return null;

    return (
      <Tooltip title="Signatur entfernen" placement="left">
        <a href="#" onClick={this.handleClearClick} className="compose-side-button">
          <Icon name="trash" />
        </a>
      </Tooltip>
    );
  }

  renderAddButton() {
    if (!this.props.editable) return null;
    if (this.state.selectorOpen) return null;
    if (this.props.value) return null;

    return (
      <a href="#" onClick={this.handleAddClick} className="compose-side-button">
        <Icon name="plus" /> {this.props.placeholder}
      </a>
    );
  }

  renderSelector() {
    if (!this.state.selectorOpen) return null;

    return (
      <div className="compose-signature-selector">
        <EmailSignatureSelector
          options={this.state.options}
          onCancel={this.handleCancelSelect}
          onChange={this.handleEmailSignaureChange}
        />
      </div>
    );
  }

  render() {
    if (!this.props.visible) return null;

    return (
      <div className="compose-signature">
        <div>{this.renderSignature()}</div>
        <div>
          {this.renderSelector()}
          {this.renderRemoveButton()}
          {this.renderAddButton()}
        </div>
      </div>
    );
  }
}

NewEmailSignature.propTypes = {
  value: PropTypes.number,
  optionsUrl: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  editable: PropTypes.bool.isRequired,
};

NewEmailSignature.defaultProps = {
  value: null,
};

export default NewEmailSignature;

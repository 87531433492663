import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import WorkflowTemplateSelector from '../WorkflowTemplateSelector';

import Icon from '../../shared/Icon';

import { getAsync } from '../../../helpers/rails_helper';

class AddWorkflowEffectForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.loadRoleTemplates = this.loadRoleTemplates.bind(this);
    this.handleSetAllRolesClick = this.handleSetAllRolesClick.bind(this);
    this.handleSetSpecificRolesClick = this.handleSetSpecificRolesClick.bind(this);
    this.handleRoleToggle = this.handleRoleToggle.bind(this);

    this.state = {
      roleTemplates: [],
    };
  }

  componentDidMount() {
    this.loadRoleTemplates();
  }

  componentDidUpdate(prevProps) {
    if (this.props.record.workflow_template_id !== prevProps.record.workflow_template_id) {
      this.loadRoleTemplates();

      this.props.onChange(Object.assign({}, this.props.record, {
        options: Object.assign({}, this.props.record.options, {
          role_template_ids: null,
        }),
      }));
    }
  }

  loadRoleTemplates() {
    if (this.props.record.workflow_template_id) {
      getAsync(`/workflows/${this.props.record.workflow_template_id}/roles.json`).then((result) => {
        this.setState({
          roleTemplates: result.roles,
        });
      });
    } else {
      this.setState({
        roleTemplates: [],
      });
    }
  }

  handleChange(workflowTemplate) {
    this.props.onChange(Object.assign({}, this.props.record, {
      workflow_template: workflowTemplate,
      workflow_template_id: workflowTemplate && workflowTemplate.id,
    }));
  }

  handleSetAllRolesClick(e) {
    this.props.onChange(Object.assign({}, this.props.record, {
      options: Object.assign({}, this.props.record.options, {
        role_template_ids: null,
      }),
    }));
  }

  handleSetSpecificRolesClick(e) {
    this.props.onChange(Object.assign({}, this.props.record, {
      options: Object.assign({}, this.props.record.options, {
        role_template_ids: this.state.roleTemplates.map(r => r.id),
      }),
    }));
  }

  handleRoleToggle(e, roleId) {
    let newRoleTemplateIds = [];

    if (this.props.record.options.role_template_ids.includes(roleId)) {
      newRoleTemplateIds = this.props.record.options.role_template_ids.filter(r => (r !== roleId));
    } else {
      newRoleTemplateIds = [...this.props.record.options.role_template_ids, roleId];
    }

    this.props.onChange(Object.assign({}, this.props.record, {
      options: Object.assign({}, this.props.record.options, {
        role_template_ids: newRoleTemplateIds,
      }),
    }));
  }

  renderRoleCheckboxes() {
    if (!this.state.roleTemplates.length) return null;

    if (this.state.roleTemplates) {
      return (
        <Fragment>
          <div className="form-check">
            <input
              type="radio"
              checked={!this.props.record.options.role_template_ids}
              onChange={this.handleSetAllRolesClick}
              id="set_all_roles"
              className="form-check-input"
            />
            <label htmlFor="set_all_roles" className="form-check-label">Alle Rolle besetzen</label>
          </div>
          <div className="form-check">
            <input
              type="radio"
              checked={!!this.props.record.options.role_template_ids}
              onChange={this.handleSetSpecificRolesClick}
              id="set_specific_roles"
              className="form-check-input"
            />
            <label htmlFor="set_specific_roles" className="form-check-label">Bestimmte Rollen besetzen</label>
          </div>
          {this.state.roleTemplates.map(roleTemplate => (
            <div className="form-check" key={roleTemplate.id}>
              <input
                type="checkbox"
                disabled={!this.props.record.options.role_template_ids}
                checked={!this.props.record.options.role_template_ids || this.props.record.options.role_template_ids.includes(roleTemplate.id)}
                onChange={e => this.handleRoleToggle(e, roleTemplate.id)}
                id={`role_toggle_${roleTemplate.name}`}
                className="form-check-input"
              />
              <label htmlFor={`role_toggle_${roleTemplate.name}`} className="form-check-label"><Icon name="circle" color={roleTemplate.color} /> {roleTemplate.name}</label>
            </div>
          ))}
        </Fragment>
      );
    }
  }

  render() {
    return (
      <Fragment>
        <div className="mb-3">
          <label className="form-label">Workflow hinzufügen</label>
          <WorkflowTemplateSelector
            optionsUrl={`/${this.props.organizationSlug}/workflow_templates.json`}
            value={this.props.record.workflow_template}
            onChange={this.handleChange}
          />
        </div>
        {this.renderRoleCheckboxes()}
      </Fragment>
    );
  }
}

AddWorkflowEffectForm.propTypes = {
  record: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  workflow_template_id: PropTypes.number.isRequired,
};

export default AddWorkflowEffectForm;

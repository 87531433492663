import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getAsync } from '../../helpers/rails_helper';

class PhoneNumberSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phoneNumbers: [],
      loading: true,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    getAsync(this.props.optionsUrl).then((result) => {
      this.setState({
        phoneNumbers: result.phone_numbers,
        loading: false,
      });

      if (result.phone_numbers && result.phone_numbers.length) {
        this.props.onChange(result.phone_numbers[0].text);
      } else {
        this.props.onChange('');
      }
    });
  }

  handleChange(e) {
    e.preventDefault();
    this.props.onChange(e.target.value);
  }

  render() {
    if (this.state.loading) return 'laden...';

    const options = this.state.phoneNumbers.map((p) => {
      return (
        <option key={`phonenumber-${p.id}`} value={p.text}>{p.text}</option>
      );
    });

    return (
      <span className="compose-phone-number-select">
        <select onChange={this.handleChange}>{options}</select>
      </span>
    );
  }
}

PhoneNumberSelect.propTypes = {
  optionsUrl: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PhoneNumberSelect;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../shared/Tooltip';
import Icon from '../../shared/Icon';

const StringDataPointInput = ({
  dataPoint, onChange, onSave, value,
  saving, editing, autoFocus, dataPointEffectIsOptional,
}) => {
  const valueValid = (testValue) => {
    if (
      !dataPoint.regex
      || (dataPointEffectIsOptional && !testValue)
    ) {
      return true;
    }

    const regex =
      new RegExp(dataPoint.regex, dataPoint.regex_flags);

    // if testValue is null or undefined, "null" or "undefined" would be tested.
    return regex.test(testValue || '');
  };

  useEffect(() => {
    // Simulate a value change to trigger regex validation
    // on mount and send result to parent components
    onChange(value, valueValid(value));
  }, []);

  const handleChange = (e) => {
    onChange(e.target.value, valueValid(e.target.value));
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && onSave) {
      onSave();
    }
  };

  const renderRegexDescIcon = () => {
    if (dataPoint.regex_desc && editing && value) {
      const valid = valueValid(value);

      return (
        <div className="datapoint-regex-desc">
          <Tooltip
            placement="top"
            enabled
            title={(
              <div style={{ width: '300px', textAlign: 'left' }}>
                {dataPoint.regex_desc}
              </div>
            )}>
            <Icon name={valid ? 'check-circle' : 'exclamation-circle'} className={!valid ? 'not-valid' : ''} />
          </Tooltip>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <input
        className="form-control"
        onKeyDown={handleKeyDown}
        autoFocus={autoFocus}
        disabled={saving}
        value={value || ''}
        onChange={handleChange}
        style={dataPoint.type === 'DataPoint::StringDataPoint' && { width: '80%', display: 'inline-block' }}
      />
      &nbsp;
      {renderRegexDescIcon()}
    </>
  );
};

StringDataPointInput.propTypes = {
  dataPoint: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  autoFocus: PropTypes.bool,
  value: PropTypes.string,
  saving: PropTypes.bool,
  editing: PropTypes.bool,
  dataPointEffectIsOptional: PropTypes.bool,
};

export default StringDataPointInput;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ConditionSet from './ConditionSet';
import HiddenConditionFields from './HiddenConditionFields';

import { generateId } from '../../helpers/helpers';

// Als Funktion, um bei jedem Aufruf eine neue
const getDefaultSet = () => {
  return {
    id: generateId(),
    include: true,
    condition_groups: [],
    condition_groups_to_be_removed: [],
    logical_connector: 'and',
  };
};

class ConditionSetEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: props.initialConditionSet || getDefaultSet(),
    };
  }

  getFilterTitleMultiFunction = () => {
    const parts = this.props.filterTitleMulti.split('%');

    return logicalSelector => (
      <span>
        {parts[0]} {logicalSelector} {parts[2]}
      </span>
    );
  };

  handleFilterChange = (updatedFilter) => {
    this.setState({
      filter: updatedFilter,
    });
  };

  render() {
    return (
      <div>
        <ConditionSet
          optionsUrl={this.props.optionsUrl}
          organizationSlug={this.props.organizationSlug}
          onChange={this.handleFilterChange}
          filter={this.state.filter}
          filterTitleSingle={this.props.filterTitleSingle}
          filterTitleMulti={this.getFilterTitleMultiFunction()}
          andText={this.props.andText}
          orText={this.props.orText}
          conditionGroupTitleMulti={this.props.conditionGroupTitleMulti}
          conditionGroupAndText={this.props.conditionGroupAndText}
          conditionGroupOrText={this.props.conditionGroupOrText}
        />
        <HiddenConditionFields
          filter={this.state.filter}
          baseName={`${this.props.baseName}[${this.props.conditionSetName}_attributes]`}
        />
      </div>
    );
  }
}

ConditionSetEditor.propTypes = {
  initialConditionSet: PropTypes.object,
  optionsUrl: PropTypes.string.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  baseName: PropTypes.string.isRequired,
  conditionSetName: PropTypes.string.isRequired,
  filterTitleSingle: PropTypes.string.isRequired,
  filterTitleMulti: PropTypes.string.isRequired,
  andText: PropTypes.string,
  orText: PropTypes.string,
  conditionGroupTitleMulti: PropTypes.string,
  conditionGroupAndText: PropTypes.string,
  conditionGroupOrText: PropTypes.string,
};

export default ConditionSetEditor;

/* global window, fetch */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import CompaniesMap from './CompaniesMap';
import Icon from '../shared/Icon';

const MapContainer = (props) => {
  const [currentStatuses, setCurrentStatuses] = useState(props.initial_statuses);
  const [currentCraftIds, setCurrentCraftIds] = useState(props.initial_craft_ids);
  const [installationCompanies, setInstallationCompanies] = useState([]);
  const [mergedPolygons, setMergedPolygons] = useState([]);

  const getQueryString = () => {
    const parts = [];
    currentStatuses.forEach(status => {
      parts.push(`s[]=${status}`);
    });

    parts.push(`c=${currentCraftIds.join('.')}`);

    return `?${parts.join('&')}`;
  };

  const getUrl = () => {
    const url = new URL(window.location.href);

    url.searchParams.delete('s[]');
    currentStatuses.forEach(status => {
      url.searchParams.append('s[]', status);
    })
    url.searchParams.set('c', currentCraftIds.join('.'));

    return url.href;
  };

  useEffect(() => {
    window.history.replaceState('', '', getUrl());
  }, [currentStatuses, currentCraftIds]);

  useEffect(() => {
    const dataUrl = `/${props.organizationSlug}/installation_companies.json${getQueryString()}`;

    fetch(dataUrl, {
      credentials: 'include',
    }).then((response) => {
      return response.json();
    }).then((result) => {
      setMergedPolygons(result.polygons);
      setInstallationCompanies(result.installation_companies);
    });
  }, [currentStatuses, currentCraftIds]);

  const handleStatusClick = (e, status) => {
    e.preventDefault();
    const name = status.name
    if(currentStatuses.includes(name)) {
      setCurrentStatuses(old => old.filter(item => item !== name))
    }
    else {
      setCurrentStatuses(old => [...old, name]);
    }
  };

  const handleAllClick = (e) => {
    e.preventDefault();
    setCurrentCraftIds([]);
  };

  const handleCraftClick = (e, craft) => {
    e.preventDefault();

    let newCraftIds;

    if (currentCraftIds.includes(craft.id)) {
      newCraftIds = currentCraftIds.filter(c => c !== craft.id);
    } else {
      newCraftIds = [...currentCraftIds, craft.id];
    }

    setCurrentCraftIds(newCraftIds);
  };

  const statuses = props.statuses.map((status) => {
    const activeClass = (currentStatuses.includes(status.name)) ? 'active' : '';

    return (
      <a
        href="#"
        className={`list-group-item ${activeClass}`}
        key={status.name}
        onClick={(e) => handleStatusClick(e, status) }>
        {status.title}
        <span className="badge float-end rounded-pill">
          {status.count}
        </span>
      </a>
    );
  });

  const crafts = props.crafts.map((craft) => {
    const active = currentCraftIds.includes(craft.id);

    return (
      <div key={craft.id} style={{ margin: '8px 0' }}>
        <div style={{ display: 'inline-block', width: 17 }}>
          {active || currentCraftIds.length === 0 ? (
            <Icon name="check" color="#2f7dd5" />
          ) : ''}
        </div>
        <a href="#" onClick={e => handleCraftClick(e, craft)} style={{ color: active ? '#2f7dd5' : '#aaa' }}>
          {craft.name}
        </a>
      </div>
    );
  });

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <div style={{ flexBasis: 250, padding: 20, paddingTop: 0 }}>
        <div style={{ marginBottom: 20 }}>
          <h4 className="mt-3">Fachpartner</h4>
        </div>
        <div className="btn-group w-100 " style={{ marginBottom: 20 }}>
          <div className="btn-group w-50">
            <a href={`/${props.organizationSlug}/installation_companies${getQueryString()}`}
              className="btn btn-light">
              <Icon name="list" /> Liste
            </a>
          </div>
          <div className="btn-group w-50">
            <a href="#" className="btn btn-primary" onClick={e => e.preventDefault()}>
              <Icon name="map" /> Karte
            </a>
          </div>
        </div>

        <div className="list-group mb-3">
          {statuses}
        </div>

        <span>
          <strong>Gewerke</strong>
          {currentCraftIds.length === 0 ? null : (
            <span className="float-end">
              <a href="#" onClick={handleAllClick}>Alle anzeigen</a>
            </span>
          )}
        </span>
        {crafts}
      </div>
      <div style={{ flexGrow: 1, position: 'relative' }}>
        <CompaniesMap
          companies={installationCompanies}
          mergedPolygons={mergedPolygons}
          areas={installationCompanies.map((i) => {
            return i.areas;
          }).flat()}
          containerStyle={{ height: '100%' }}
        />
      </div>
    </div>
  );
};

MapContainer.propTypes = {
  statuses: PropTypes.array.isRequired,
  initial_statuses: PropTypes.array.isRequired,
  crafts: PropTypes.array.isRequired,
  initial_craft_ids: PropTypes.array.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default MapContainer;


// https://github.com/mkg0/react-numeric

import React from 'react';
import PropTypes from 'prop-types';
import AutoNumeric from 'autonumeric';

class NumericInput extends React.Component {
  constructor(props) {
    super(props);
    this.getValue = this.getValue.bind(this);
    this.callEventHandler = this.callEventHandler.bind(this);
  }

  componentDidMount() {
    this.autonumeric = new AutoNumeric(this.input, this.props.value || (this.props.value === 0 ? 0 : ''), {
      ...this.props.options,
      watchExternalChanges: false,
    });
  }

  componentWillReceiveProps(newProps) {
    const isOptionsChanged = JSON.stringify({ ...this.props.options }) !== JSON.stringify({ ...newProps.options });
    const isValueChanged = this.props.value !== newProps.value && this.getValue() !== newProps.value;

    if (isValueChanged) {
      this.autonumeric.set(newProps.value);
    }

    if (isOptionsChanged) {
      this.autonumeric.update({
        ...newProps.options,
        watchExternalChanges: false,
      });
    }
  }

  getValue() {
    if (!this.autonumeric) return;

    const valueMapper = {
      string: numeric => numeric.getNumericString(),
      number: numeric => numeric.getNumber(),
    };

    return valueMapper[this.props.outputFormat](this.autonumeric);
  }

  callEventHandler(event, eventName) {
    if (!this.props[eventName]) return;
    this.props[eventName](event, this.getValue());
  }

  render() {
    const inputProps = {};
    [
      'placeholder',
      'className',
      'style',
      'disabled',
      'type',
      'name',
      'tabIndex',
      'unselectable',
      'autoFocus',
    ].forEach(prop => (inputProps[prop] = this.props[prop]));

    return (
      <input
        ref={ref => (this.input = ref)}
        onChange={e => this.callEventHandler(e, 'onChange')}
        onFocus={e => this.callEventHandler(e, 'onFocus')}
        onBlur={e => this.callEventHandler(e, 'onBlur')}
        onKeyPress={e => this.callEventHandler(e, 'onKeyPress')}
        onKeyUp={e => this.callEventHandler(e, 'onKeyUp')}
        onKeyDown={e => this.callEventHandler(e, 'onKeyDown')}
        {...inputProps}
      />
    );
  }
}

NumericInput.propTypes = {
  type: PropTypes.oneOf(['text', 'tel', 'hidden']),
  className: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  tabIndex: PropTypes.number,
  unselectable: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
  options: PropTypes.object,
};

NumericInput.defaultProps = {
  type: 'text',
  outputFormat: 'number',
  options: {},
};

export default NumericInput;

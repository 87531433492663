/* global fetch */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { defaultStyles, smallStyles } from '../../styles/select_styles';

import Select, { components } from 'react-select';

import { searchText } from '../../helpers/helpers';

const filterTags = (option, searchTerm) => {
  let title = `${option.data.title} ${option.data.desc}`;

  if (option.data.group) {
    title += option.data.group.title;
  }

  return searchText(title, searchTerm);
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      {props.data.title}
    </components.Option>
  );
};

const SingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      {props.data.title}
    </components.SingleValue>
  );
};

class TagGroupSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      options: [],
      loading: false,
    };
  }

  loadOptions = () => {
    this.setState({ loading: true });

    fetch(this.props.optionsUrl, {
      credentials: 'include',
    }).then(response => response.json()).then((data) => {
      this.setState({
        options: data.tag_groups,
        loading: false,
      });
    });
  }

  handleFocus = () => {
    if (!this.state.options.length) {
      this.loadOptions();
    }
  }

  handleChange = (data) => {
    this.setState({
      value: data,
    });

    if (this.props.onChange) {
      this.props.onChange(data);
    }
  }

  render() {
    return (
      <Select
        isClearable={this.props.isClearable}
        components={{
          Option,
          SingleValue,
          DropdownIndicator: (this.props.value || this.state.loading) ? null : components.DropdownIndicator,
        }}
        value={this.state.value}
        openMenuOnFocus
        backspaceRemovesValue={false}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        filterOption={filterTags}
        defaultOptions
        options={this.state.options}
        isLoading={this.state.loading}
        placeholder="Bitte Gruppe auswählen"
        loadingPlaceholder="Laden..."
        noOptionsMessage={() => 'Keine Ergebnisse'}
        loadingMessage={() => 'Laden...'}
        styles={this.props.size === 'small' ? smallStyles : defaultStyles}
      />
    );
  }
}

TagGroupSelector.propTypes = {
  optionsUrl: PropTypes.string.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func,
  size: PropTypes.string,
  isClearable: PropTypes.bool,
};

TagGroupSelector.defaultProps = {
  isClearable: true,
};

export default TagGroupSelector;

import SetDataPointEffectForm from './SetDataPointEffectForm';
import SetAutomaticDataPointEffectForm from './SetAutomaticDataPointEffectForm';
import DeleteDataPointEffectForm from './DeleteDataPointEffectForm';
import AddTagEffectForm from './AddTagEffectForm';
import AddProjectNoteEffectForm from './AddProjectNoteEffectForm';
import RemoveTagEffectForm from './RemoveTagEffectForm';
import DelayEffectForm from './DelayEffectForm';
import SetDueEffectForm from './SetDueEffectForm';
import SetRoleEffectForm from './SetRoleEffectForm';
import AddCommentEffectForm from './AddCommentEffectForm';
import AddWorkflowEffectForm from './AddWorkflowEffectForm';
import AssignWorkflowEffectForm from './AssignWorkflowEffectForm';
import AnalyticsHitEffectForm from './AnalyticsHitEffectForm';
import GoogleAdsConversionEffectForm from './GoogleAdsConversionEffectForm';
import AffiliateSaleEffectForm from './AffiliateSaleEffectForm';
import ArchiveProjectEffectForm from './ArchiveProjectEffectForm';
import EmailEffectForm from './EmailEffectForm';
import ShortMessageEffectForm from './ShortMessageEffectForm';
import AssignEmailEffectForm from './AssignEmailEffectForm';
import RenameWorkflowEffectForm from './RenameWorkflowEffectForm';
import StartVoiceAgentEffectForm from './StartVoiceAgentEffectForm';
import StopAllVoiceAgentsEffectForm from './StopAllVoiceAgentsEffectForm';

export default {
  SetDataPointEffectForm,
  SetAutomaticDataPointEffectForm,
  DeleteDataPointEffectForm,
  AddTagEffectForm,
  RemoveTagEffectForm,
  AddProjectNoteEffectForm,
  DelayEffectForm,
  SetDueEffectForm,
  SetRoleEffectForm,
  AddCommentEffectForm,
  AddWorkflowEffectForm,
  AssignWorkflowEffectForm,
  AnalyticsHitEffectForm,
  GoogleAdsConversionEffectForm,
  AffiliateSaleEffectForm,
  ArchiveProjectEffectForm,
  EmailEffectForm,
  ShortMessageEffectForm,
  AssignEmailEffectForm,
  RenameWorkflowEffectForm,
  StartVoiceAgentEffectForm,
  StopAllVoiceAgentsEffectForm,
};

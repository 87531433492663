import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '../shared/Avatar';
import TrimmedText from '../shared/TrimmedText';

import TimeAgo from '../shared/TimeAgo';

import AttachmentGallery from '../Attachments/AttachmentGallery';

const TodoPreviewComment = ({ comment, todoId, onEdit }) => {
  return (
    <div className="todo-preview-comment">
      <div>
        <Avatar account={comment.user} />
      </div>
      <div className="todo-preview-comment-content">
        <div className="todo-preview-comment-title">
          <a href={`/users/${comment.user.id}`}>
            {comment.user.fullname}
          </a> <TimeAgo datetime={comment.created_at} locale="de" />
        </div>
        <div className="todo-preview-comment-text">
          <TrimmedText text={comment.body} moreHref={`/todos/${todoId}`} />
        </div>
        <AttachmentGallery
          attachments={comment.attachments}
          size="tiny" maxPreviews={5}
          onClick={onEdit}
          style={{ marginTop: 5 }}
        />
      </div>
    </div>
  );
};

TodoPreviewComment.propTypes = {
  comment: PropTypes.object.isRequired,
  todoId: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default TodoPreviewComment;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import HiddenInputWrapper from '../shared/HiddenInputWrapper';
import ProductSelector from './ProductSelector';

class ProductSelect extends Component {
  handleChange = (value) => {
    const { eventName } = this.props;
    if (eventName) {
      document.dispatchEvent(new CustomEvent(
        eventName, { detail: { id: value } },
      ));
    }
  };

  render() {
    const { value, name, ...rest } = this.props;
    return (
      <HiddenInputWrapper
        {...rest}
        comp={ProductSelector}
        name={name}
        value={value}
        onChange={this.handleChange}
      />
    );
  }
}

ProductSelect.propTypes = {
  value: PropTypes.number,
  name: PropTypes.string,
  eventName: PropTypes.string,
};

export default ProductSelect;

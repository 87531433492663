import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import Trigger from './Trigger';

const ActiveTriggers = ({ actions, triggers }) => {
  const [detailsVisible, setDetailsVisible] = useState(false);

  if (!triggers.length) return null;

  const toggleDetails = (e) => {
    e.preventDefault();
    setDetailsVisible(!detailsVisible);
  };

  const details = triggers.map(trigger => (
    <Trigger
      key={trigger.id}
      trigger={trigger}
      action={actions.find(a => a.id === trigger.action_id)}
    />
  ));

  let activeTriggerText = '';

  if (triggers.length === 1) {
    activeTriggerText = 'Ein aktiver Trigger';
  } else if (triggers.length > 1) {
    activeTriggerText = `${triggers.length} aktive Trigger`;
  }

  return (
    <>
      <div className="actions-container-section actions-container-section-triggers">
        <div className="todo-active-triggers-head">
          <div className="trigger-header-container">
            <span className="trigger-indicator trigger-indicator-large">
              <Icon name="timer" />
            </span>
            <span className="trigger-text">
              <span className="trigger-title">{activeTriggerText}</span>
              <span className="trigger-desc">Aktionen werden automatisch ausgelöst.</span>
            </span>
          </div>
          <div className="todo-trigger-details-toggle">
            <button type="button" className="btn btn-link btn-compact" onClick={toggleDetails}>
              Details <span className="d-none d-lg-inline">anzeigen</span> <Icon name={detailsVisible ? 'chevron-up' : 'chevron-down'} />
            </button>
          </div>
        </div>
      </div>
      {detailsVisible ? (
        <div className="actions-container-section">
          <div className="trigger-list">
            {details}
          </div>
        </div>
      ) : null}
    </>
  );
};

ActiveTriggers.propTypes = {
  actions: PropTypes.array.isRequired,
  triggers: PropTypes.array.isRequired,
};

export default ActiveTriggers;

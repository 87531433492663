import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import SearchResultLink from './SearchResultLink';


const SearchResultInstallationCompany = (props) => {
  return (
    <SearchResultLink {...props}>
      <div>
        <Icon name="wrench" />
      </div>
        <div className="search-result-installation-company">
          <div>
            {props.name}
          </div>
        </div>
    </SearchResultLink>
  );
};

SearchResultInstallationCompany.propTypes = {
  name: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  selected: PropTypes.bool,
};

export default SearchResultInstallationCompany;

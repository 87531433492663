import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AsyncSelect from 'react-select/async';

import { components } from 'react-select';

import { defaultStyles, smallStyles } from '../../styles/select_styles';

const NoComp = () => {
  return null;
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      {props.data.title}
    </components.Option>
  );
};

const SingleValue = (props) => (
  <components.SingleValue {...props}>
    {props.data.title}
  </components.SingleValue>
);

class EmailTemplateSelector extends Component {
  constructor(props) {
    super(props);

    this.getOptions = this.getOptions.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  getOptions(searchTerm) {
    const url = `${this.props.optionsUrl}?q=${encodeURIComponent(searchTerm)}`;

    return fetch(url, {
      credentials: 'include',
    }).then((response) => {
      return response.json();
    }).then((options) => {
      return options.email_templates;
    });
  }

  handleChange = (emailTemplate) => {
    if (emailTemplate && this.props.contactId && this.props.projectId) {
      const url = `/email_templates/${emailTemplate.id}/interpolated.json?contact_id=${this.props.contactId}&project_id=${this.props.projectId}`;

      return fetch(url, {
        credentials: 'include',
      }).then((response) => {
        return response.json();
      }).then((interpolatedTemplate) => {
        this.props.onChange(interpolatedTemplate);
      });
    }

    return this.props.onChange(emailTemplate);
  };

  handleBlur() {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  render() {
    return (
      <AsyncSelect
        components={{
          Option,
          SingleValue,
          DropdownIndicator: this.props.hideDropdownIndicator ?
            NoComp : components.DropdownIndicator,
          IndicatorSeparator: this.props.hideDropdownIndicator ?
            NoComp : components.IndicatorSeparator,
        }}
        defaultValue={this.props.value}
        openMenuOnFocus
        onBlur={this.handleBlur}
        onKeyDown={this.handleKeyDown}
        backspaceRemovesValue={false}
        blurInputOnSelect
        menuPlacement="auto"
        onChange={this.handleChange}
        handleInputChange={this.handleInputChange}
        loadOptions={this.getOptions}
        defaultOptions
        autoFocus={this.props.autoFocus}
        styles={this.props.size === 'small' ? smallStyles : defaultStyles}
        placeholder={this.props.placeholder}
        loadingPlaceholder="Laden..."
        noOptionsMessage={() => 'Keine Ergebnisse'}
        loadingMessage={() => 'Laden...'}
        isClearable
      />
    );
  }
}

EmailTemplateSelector.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  size: PropTypes.string,
  hideDropdownIndicator: PropTypes.bool,
  optionsUrl: PropTypes.string.isRequired,
  contactId: PropTypes.number,
  projectId: PropTypes.number,
  onBlur: PropTypes.func,
  autoFocus: PropTypes.bool.isRequired,
};

EmailTemplateSelector.defaultProps = {
  placeholder: 'Vorlage auswählen',
  autoFocus: true,
};

export default EmailTemplateSelector;

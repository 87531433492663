import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Tooltip from './Tooltip';

const confirmDefaultMessage = 'Bist du sicher?';

class Label extends Component {
  handleRemove = (e) => {
    e.preventDefault();

    if (this.props.confirmRemove) {
      if (confirm(this.props.confirmRemoveMessage || confirmDefaultMessage)) {
        this.props.onRemove(this.props.data);
      }
    } else {
      this.props.onRemove(this.props.data);
    }
  }

  render() {
    const style = {
      fontWeight: 'bold',
      color: this.props.textColor,
      border: `1px solid ${this.props.color}`,
      backgroundColor: this.props.color,
      marginRight: 5,
    };

    if (this.props.removable) {
      return (
        <Tooltip enabled={!!this.props.desc} title={this.props.desc} placement="top">
          <button onClick={this.handleRemove} className="btn btn-light btn-label" style={style}>
            {this.props.title}
            <span className="label-x">×</span>
          </button>
        </Tooltip>
      );
    }

    return (
      <Tooltip enabled={!!this.props.desc} title={this.props.desc} placement="top">
        <span className="badge" style={style}>
          {this.props.title}
        </span>
      </Tooltip>
    );
  }
}

Label.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string,
  color: PropTypes.string,
  textColor: PropTypes.string,
  removable: PropTypes.bool,
  onRemove: PropTypes.func,
  confirmRemove: PropTypes.bool,
  confirmRemoveMessage: PropTypes.string,
};

export default Label;
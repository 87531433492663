import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['startDate', 'endDate', 'appointmentStatus'];

  connect() {
    this.endDateTarget.min = this.startDateTarget.value;
  }

  startSelected() {
    this.endDateTarget.min = this.startDateTarget.value;
    if (this.startDateTarget.value > this.endDateTarget.value) {
      this.endDateTarget.value = this.startDateTarget.value;
    }
    this.appointmentStatusTarget.value = 'booked';
  }
}

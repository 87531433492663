/* global $ */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';

class NewFolderButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      valid: false,
    };

    this.handleSaveFolderClick = this.handleSaveFolderClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    $(this.modal).on('shown.bs.modal', () => {
      $(this.input).focus();
    });
  }

  handleChange() {
    const value = this.input.value.trim();
    this.setState({
      valid: !!value,
    });
  }

  handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      this.handleSaveFolderClick();
    }
  };

  handleSaveFolderClick() {
    if (this.state.valid) {
      this.setState({ saving: true });

      this.props.createFolder(this.input.value.trim()).then(() => {
        $(this.modal).modal('hide');

        this.setState({ saving: false, valid: false });
        this.input.value = '';
      });
    }
  }

  render() {
    if (!this.props.visible) return null;

    return (
      <>
        <button
          className="new-folder-button-desktop"
          type="button"
          title="Neuer Ordner"
          data-bs-toggle="modal"
          data-bs-target="#modal"
        >
          <Icon fixedWidth name="folder-plus" />
        </button>

        <div
            className="modal fade"
            ref={e => (this.modal = e)}
            id="modal" tabIndex="-1"
            role="dialog"
            data-bs-backdrop="static"
            data-bs-keyboard="false">
          <div className="modal-dialog w-100" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel">Neuer Ordner</h4>
                <button type="button" className="close btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <input onKeyDown={this.handleKeyDown} onChange={this.handleChange} ref={e => (this.input = e)} id="new-folder-input" className="form-control" placeholder="Name des Ordners" autoFocus />
              </div>
              <div className="modal-footer">
                <button disabled={this.state.saving} type="button" className="btn btn-light" data-bs-dismiss="modal">Abbrechen</button>
                <button disabled={!this.state.valid || this.state.saving} type="button" className="btn btn-primary" onClick={this.handleSaveFolderClick}>Speichern</button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

NewFolderButton.propTypes = {
  createFolder: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default NewFolderButton;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ContextMenuContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      clickX: null,
      clickY: null,
    }

    this.id = (new Date()).getMilliseconds();
  }

  componentDidMount() {
    document.addEventListener('context:menu:show', this.handleBlur);
  }

  componentWillUnmount() {
    document.removeEventListener('context:menu:show', this.handleBlur);
  }

  handleBlur = (customEvent) => {
    // if blur comes from CustomEvent (context:menu:show)
    // ignore if this is the context menu that triggered it
    if (customEvent?.detail?.id === this.id) return;

    this.setState(() => ({
      visible: false,
    }));
  };

  handleContextMenu = (event) => {
    if (this.props.handleContextMenu) {
      this.props.handleContextMenu(event);
    }

    this.setState({
      visible: this.props.enabled,
      clickX: event.clientX,
      clickY: event.clientY,
    });

    const customEvent = new CustomEvent('context:menu:show', {
      detail: {
        id: this.id,
      },
    });

    document.dispatchEvent(customEvent);

    event.stopPropagation();
    event.preventDefault();
  };

  render() {
    const menu = {
      ...this.state,
      handleBlur: this.handleBlur,
    };

    return (
      <div onContextMenu={this.handleContextMenu}>
        {this.props.children(menu)}
      </div>
    );
  }
}

ContextMenuContainer.propTypes = {
  children: PropTypes.func.isRequired,
  enabled: PropTypes.bool.isRequired,
};

ContextMenuContainer.defaultProps = {
  enabled: true,
};

export default ContextMenuContainer;

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import Tooltip from '../shared/Tooltip';
import Icon from '../shared/Icon';

function DataPointIconConfirmed(props) {
  const render = () => {
    if (props.dataPoint.status === 'confirmed') {
      let updatedAt;
      let updatedBy;

      if (props.dataPoint.status_updated_by_user_id) {
        updatedAt = moment(props.dataPoint.status_updated_at).locale('de').format('DD. MMMM YYYY kk:mm');
        updatedBy = props.dataPoint.status_updated_by_user_fullname;
      }

      return (
        <span className="ms-1">
          <Tooltip
            placement="top"
            enabled={Boolean(updatedAt && updatedBy)}
            title={(
              <div>
                bestätigt von {updatedBy}<br />
                am {updatedAt} Uhr
              </div>
            )}>
            <Icon name="badge-check" color="#a2cc21" />
          </Tooltip>
        </span>
      );
    }

    if (props.dataPoint.confirmable) {
      return (
        <span className="ms-1">
          <Tooltip placement="top" enabled title="Nicht bestätigt">
            <Icon name="badge-check" regular color="#ccc" />
          </Tooltip>
        </span>
      );
    }

    return null;
  };

  return render();
}

DataPointIconConfirmed.propTypes = {
  dataPoint: PropTypes.object.isRequired,
};

export default DataPointIconConfirmed;

import React from 'react';
import PropTypes from 'prop-types';

import UserSelector from '../Users/UserSelector';

class WorkflowEditorshipForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
  }

  handleChange(newWorkflowEditorship) {
    if (this.props.onChange) {
      this.props.onChange(newWorkflowEditorship);
    }
  }

  handleUserChange(user) {
    this.handleChange(
      Object.assign({}, this.props.record, {
        user_id: user && user.id,
      }),
    );
  }

  render() {
    const excludedIds = [...this.props.editorIds, this.props.creatorId];

    return (
      <React.Fragment>
        <div className="mb-3">
          <p>
            Wähle einen Benutzer aus, den du als Bearbeiter hinzufügen möchtest:
          </p>
          <UserSelector
            optionsUrl={`/${this.props.organizationSlug}/users.json`}
            onChange={this.handleUserChange}
            excludeUserIds={excludedIds}
          />
        </div>
        <p className="text-muted">
          Der Benutzer wird automatisch benachtichtigt und erhält Zugriff auf die Vorlage und kann sie bearbeiten.
        </p>

        <p className="text-muted">
          Darüber hinaus kannst du die Vorlage mit deinem Team teilen, indem du in den Einstellungen die Option
          <strong> Anderen Teammitgliedern erlauben diese Vorlage in Projekten verwenden</strong> aktivierst.
        </p>
      </React.Fragment>
    );
  }
}

WorkflowEditorshipForm.propTypes = {
  record: PropTypes.object.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  creatorId: PropTypes.number,
  editorIds: PropTypes.array,
};

export default WorkflowEditorshipForm;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Picker from 'rc-picker';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import deDE from 'rc-picker/lib/locale/de_DE';
import moment from 'moment';

class DatetimePickerComponent extends Component {
  constructor(props) {
    super(props);
  }

  handleChange = (date) => {
    this.props.onChange && this.props.onChange(date && date.format());
  }

  render() {
    const datetime = this.props.value ? moment(this.props.value) : null;
    return(
      <Picker
        generateConfig={momentGenerateConfig}
        locale={deDE}
        format="DD.MM.YYYY HH:mm"
        showSecond={false}
        hideDisabledOptions
        allowClear
        className="date-picker"
        placeholder="Datum eintragen"
        name={this.props.name}
        value={datetime}
        onChange={this.handleChange}
        clearIcon=' '
        defaultPickerValue={datetime || moment().add(1, 'days').startOf('day')}
        onSelect={this.handleChange}
        showTime={{
          showSecond: false,
          hideDisabledOptions: true,
          format: 'HH:mm',
          defaultValue: moment().startOf('day'),
          minuteStep: 5,
        }}
        dropdownClassName="datetime-picker-dropdown"
      />
    )
  }
}

DatetimePickerComponent.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
};

export default DatetimePickerComponent;

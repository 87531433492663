import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../shared/Icon';
import QuickDelaySelector from '../../shared/QuickDelaySelector';

import DatetimePicker from "../../DatePickers/DatetimePickerComponent";

const DelayEffectForm = ({ value, onChange }) => {
  const handleQuickSelect = (v) => {
    onChange({ due: v.date });
  };

  return (
    <div className="fire-effect-item">
      <div className="fire-effect-icon">
        <Icon name="clock" />
      </div>
      <div className="fire-effect-content">
        <div className="fire-effect-title">
          Aufgabe vertagen
        </div>
        <div className="fire-effect-inputs">
          <div className="row">
            <div className="col-sm-6">
              <DatetimePicker
                onChange={v => onChange({ due: v })}
                value={value && value.due && value.due}
              />
              <QuickDelaySelector onChange={handleQuickSelect} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DelayEffectForm.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default DelayEffectForm;

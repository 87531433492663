import React from 'react';
import PropTypes from 'prop-types';

import HiddenInputWrapper from '../shared/HiddenInputWrapper';
import WorkflowTemplateSelector from './WorkflowTemplateSelector';

const transformValue = (rawValue) => {
  return (rawValue ? rawValue.id : '');
};

const WorkflowTemplateSelect = (props) => {
  const { value, name, ...rest } = props;

  return (
    <HiddenInputWrapper
      {...rest}
      comp={WorkflowTemplateSelector}
      name={name}
      value={value}
      transformValue={transformValue}
    />
  );
};

WorkflowTemplateSelect.propTypes = {
  value: PropTypes.number,
  name: PropTypes.string,
};

export default WorkflowTemplateSelect;

import React from 'react';
import PropTypes from 'prop-types';

import TriggerComponents from './TriggerComponents';

const Trigger = ({ action, trigger }) => {
  const Comp = TriggerComponents[trigger.type];
  return <Comp action={action} trigger={trigger} />;
};

Trigger.propTypes = {
  action: PropTypes.object.isRequired,
  trigger: PropTypes.object.isRequired,
};

export default Trigger;

/* global document */

import $ from 'jquery';
import ReactRailsUJS from 'react_ujs';

$(document).on('click', 'form .add_fields', function (event) {
  event.preventDefault();

  const time = new Date().getTime();
  const regexp = new RegExp($(this).data('id'), 'g');
  $(this).before($(this).data('fields').replace(regexp, time));

  const focus = $(this).data('focus');

  if (focus !== undefined && focus !== '') {
    $(this).prev('fieldset').find(`input[name$='[${focus}]']`).focus();
  }

  $(document).trigger('fields_added', $(this));

  ReactRailsUJS.mountComponents();
});


$(document).on('click', 'form .remove_fields', function (event) {
  $(this).closest('fieldset').find('input.destroy-field').val('true');
  $(this).closest('fieldset').addClass('d-none');

  $(document).trigger('fields_removed', $(this));

  event.preventDefault();
});

/* global document, CustomEvent */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TextArea from '../shared/TextArea';
import Icon from '../shared/Icon';
import Tooltip from '../shared/Tooltip';
import ShortMessageTemplateSelector from '../ShortMessages/ShortMessageTemplateSelector';
import { postAsync } from '../../helpers/rails_helper';

import PhoneNumberSelect from '../PhoneNumbers/PhoneNumberSelect';

const maxChars = 320;

class NewShortMessage extends Component {
  constructor(props) {
    super(props);

    const initialTo = '';

    this.state = {
      message: '',
      to: initialTo,
      errors: [],
    };

    this.handleSendClick = this.handleSendClick.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handleTemplateClick = this.handleTemplateClick.bind(this);
    this.handleTemplateChange = this.handleTemplateChange.bind(this);
    this.handleTemplateSelectorBlur = this.handleTemplateSelectorBlur.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
  }

  handleSendClick(e) {
    e.preventDefault();

    this.props.onSend();

    postAsync('/short_messages/', {
      short_message: {
        to: this.state.to,
        message: this.state.message,
        contact_id: this.props.contact_id,
        todo_id: this.props.todo_id,
      },
    }).then(({ errors, activities }) => {
      if (errors) {
        this.setState({ errors });
        this.props.onError();
        console.log(errors);
      } else {
        this.props.onSent();

        const event = new CustomEvent('todo:activities:new', {
          detail: {
            todoId: this.props.todo_id,
            activities,
          },
        });

        document.dispatchEvent(event);
      }
    }).catch(({ errors }) => {
      this.setState({ errors });
      this.props.onError();
    });
  }

  handleMessageChange(e) {
    const message = e.target.value.substring(0, maxChars);

    this.setState({ message });
  }

  handlePhoneNumberChange(value) {
    this.setState({ to: value });
  }

  handleTemplateClick(e) {
    e.preventDefault();
    this.setState({
      templateSelectorOpen: true,
    });
  }

  handleTemplateSelectorBlur() {
    this.setState({
      templateSelectorOpen: false,
    });
  }

  handleTemplateChange({ message }) {
    this.setState({ message });
  }

  charsLeft() {
    return maxChars - this.state.message.length;
  }

  canSend() {
    return this.state.message.trim().length && this.state.to.length;
  }

  renderTemplateSelector() {
    if (this.state.templateSelectorOpen) {
      return (
        <div style={{ position: 'absolute', right: 8, width: 200, marginTop: 8 }}>
          <ShortMessageTemplateSelector
            hideDropdownIndicator
            optionsUrl={this.props.shortMessageTemplatesOptionsUrl}
            contactId={this.props.contact_id}
            projectId={this.props.project_id}
            onBlur={this.handleTemplateSelectorBlur}
            onChange={this.handleTemplateChange}
            size="small" />
        </div>
      );
    }

    return (
      <div style={{ marginRight: 8, marginTop: 8 }}>
        <Tooltip title="Vorlage auswählen" placement="left">
          <a href="#" onClick={this.handleTemplateClick} className="compose-side-button">
            <Icon name="note" />
          </a>
        </Tooltip>
      </div>
    );
  }

  renderErrors() {
    if (!this.state.errors || this.state.errors.length === 0) return null;

    const errors = this.state.errors.map((e) => (
      <div className="compose-error">{e}</div>
    ));

    return (
      <div className="compose-errors">
        {errors}
      </div>
    );
  }

  render() {
    return (
      <div>
        <div style={{
          width: '100%',
          border: 'none',
          padding: '0 8px',
        }}>
          <div style={{
            borderBottom: '1px solid var(--bs-border-color);',
            padding: '7px 0',
          }}>
            <span style={{ color: '#777' }}>An</span> <PhoneNumberSelect optionsUrl={this.props.phoneNumbersUrl} onChange={this.handlePhoneNumberChange} />
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ flexGrow: 1 }}>
            <TextArea
              rows={3}
              autoFocus={this.props.shouldAutoFocus}
              onFocus={() => this.props.onFocus()}
              onBlur={() => this.props.onBlur()}
              placeholder="Nachrichtentext"
              onChange={this.handleMessageChange}
              value={this.state.message}
              autoSize />
          </div>
          <div>
            {this.renderTemplateSelector()}
          </div>
        </div>
        {this.renderErrors()}
        <div className="compose-footer">
          <div className="compose-footer-info">
            noch {this.charsLeft()}/{maxChars} Zeichen
          </div>
          <div className="compose-footer-action">
            <button
              disabled={!this.canSend()}
              onClick={this.handleSendClick}
              className="btn btn-sm btn-success">
              Senden
            </button>
          </div>
        </div>
      </div>
    );
  }
}

NewShortMessage.propTypes = {
  shortMessageTemplatesOptionsUrl: PropTypes.string.isRequired,
  phoneNumbersUrl: PropTypes.string.isRequired,
  contact_id: PropTypes.number,
  todo_id: PropTypes.number.isRequired,
  project_id: PropTypes.number.isRequired,
  shouldAutoFocus: PropTypes.bool.isRequired,
  onSend: PropTypes.func.isRequired,
  onSent: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

export default NewShortMessage;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

import AsyncSelect from 'react-select/async';
import Icon from '../shared/Icon';

import { smallStyles, defaultStyles } from '../../styles/select_styles';


const NoComp = () => {
  return null;
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <Icon name="circle" color={props.data.color} /> {props.data.name}
    </components.Option>
  );
};

const SingleValue = (props) => (
  <components.SingleValue {...props}>
    <Icon name="circle" color={props.data.color} /> {props.data.name}
  </components.SingleValue>
);

class ProjectRoleSelector extends Component {
  constructor(props) {
    super(props);

    this.getOptions = this.getOptions.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  getOptions(searchTerm) {
    const url = `${this.props.optionsUrl}?q=${encodeURIComponent(searchTerm)}`;

    return fetch(url, {
      credentials: 'include',
    }).then((response) => {
      return response.json();
    }).then((options) => {
      return options.project_roles;
    });
  }

  handleBlur() {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  render() {
    return (
      <AsyncSelect
        components={{
          Option,
          SingleValue,
          DropdownIndicator: this.props.hideDropdownIndicator ? NoComp : components.DropdownIndicator,
          IndicatorSeparator: this.props.hideDropdownIndicator ? NoComp : components.IndicatorSeparator,
        }}
        defaultValue={this.props.value}
        openMenuOnFocus
        onBlur={this.handleBlur}
        onKeyDown={this.handleKeyDown}
        backspaceRemovesValue={false}
        blurInputOnSelect
        menuPlacement="auto"
        onChange={this.props.onChange}
        handleInputChange={this.handleInputChange}
        loadOptions={this.getOptions}
        defaultOptions
        styles={this.props.size === 'small' ? smallStyles : defaultStyles}
        placeholder={this.props.placeholder}
        loadingPlaceholder="Laden..."
        noOptionsMessage={() => 'Keine Ergebnisse'}
        loadingMessage={() => 'Laden...'}
      />
    );
  }
}

ProjectRoleSelector.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  size: PropTypes.string,
  hideDropdownIndicator: PropTypes.bool,
  optionsUrl: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
};

ProjectRoleSelector.defaultProps = {
  placeholder: 'Projektrolle auswählen',
};

export default ProjectRoleSelector;

import consumer from "../initializers/action_cable"

let subscriptions = {};

export function unsubscribeAll() {
  Object.keys(subscriptions).forEach((key) => {
    subscriptions[key].unsubscribe();
  });
  subscriptions = {};
}

// Unsubscribe all Subscriptions at Turbo Click
document.addEventListener('turbo:click', unsubscribeAll);

export function subscribe(channel, callback) {
  const sub = consumer.subscriptions.create(channel, {
    received(data) {
      callback(data);
    },
  });

  subscriptions[sub.identifier] = sub;

  return sub;
}

export function unsubscribe(channel) {
  if (subscriptions[channel.identifier]) {
    delete subscriptions[channel.identifier];
  }
}

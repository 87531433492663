/* global document, window */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Turbo } from "@hotwired/turbo-rails"

import Popper from 'popper.js';
import SearchResult from './SearchResult';

class SearchSuggestions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: -1,
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.selectNext = this.selectNext.bind(this);
    this.selectPrevious = this.selectPrevious.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
  }

  componentDidMount() {
    this.popperInstance = new Popper(document.getElementById('search-input'), this.popper, {
      placement: 'bottom-start',
      modifiers: {
        flip: {
          enabled: false,
        },
      },
    });
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.popperInstance) {
      this.popperInstance.scheduleUpdate();
    }

    if (this.state.selected > this.props.results.length - 1) {
      this.state.selected = -1;
    }
  }

  componentWillUnmount() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
    }
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown(e) {
    if (this.props.visible) {
      if (e.keyCode === 38) { // Arrow up
        e.preventDefault();
        this.selectPrevious();
      }

      if (e.keyCode === 40) { // Arrow down
        e.preventDefault();
        this.selectNext();
      }

      if (e.keyCode === 13) { // Arrow down
        if (this.state.selected !== -1) {
          e.preventDefault();
          if (this.props.results[this.state.selected]) {
            Turbo.visit(this.props.results[this.state.selected].href);
          }
        }
      }
    }
  }

  handleMouseOver(index) {
    this.setState({ selected: index });
  }

  selectNext() {
    if (this.state.selected < this.props.results.length - 1) {
      this.setState({ selected: this.state.selected + 1 });
    }
  }

  selectPrevious() {
    if (this.state.selected > 0) {
      this.setState({ selected: this.state.selected - 1 });
    }
  }

  renderResults() {
    return this.props.results.map((result, i) => (
      <SearchResult
        resultsCount={this.props.results.length}
        index={i}
        selected={this.state.selected === i}
        key={`search-result-${result.search_result_type}-${result.id}`}
        onMouseOver={this.handleMouseOver}
        result={result} />
    ));
  }

  render() {
    const popperStyle = {
      opacity: this.props.visible ? 1 : 0,
    };

    if (!this.props.visible) {
      popperStyle.display = 'none';
    }

    return (
      <span ref={e => (this.popper = e)} className="search-suggestions" style={popperStyle}>
        {this.renderResults()}
      </span>
    );
  }
}

SearchSuggestions.propTypes = {
  results: PropTypes.array,
  visible: PropTypes.bool,
};

SearchSuggestions.defaultProps = {
  visible: false,
};

export default SearchSuggestions;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RangePicker } from 'rc-picker';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import deDE from 'rc-picker/lib/locale/de_DE';
import moment from 'moment';

class RangePickerComponent extends Component {
  constructor(props) {
    super(props);
  }

  getRange() {
    const start = this.props.range && this.props.range[0] && moment(this.props.range[0]);
    const end = this.props.range && this.props.range[1] && moment(this.props.range[1]);
    return [start, end];
  }

  renderExtraFooter = () => {
    return (
      this.props.onOkClick ?
        <button onClick={this.props.onOkClick} className="btn btn-success px-2 mt-auto mb-0">Anwenden</button>
        : null
    )
  }

  render() {
    return(
      <RangePicker
        generateConfig={momentGenerateConfig}
        locale={deDE}
        format="DD.MM.YYYY"
        allowClear
        className={`range-picker ${this.props.classNames}`}
        placeholder={this.props.placeholders}
        ranges={this.props.ranges}
        name={this.props.name}
        value={this.getRange()}
        onChange= {this.props.onChange}
        separator="-"
        clearIcon=' '
        style={this.props.style}
        renderExtraFooter={this.renderExtraFooter}
        open={this.props.open}
        onBlur={this.props.onBlur}
      />
    )
  }
}

RangePickerComponent.propTypes = {
  range: PropTypes.array,
  ranges: PropTypes.object,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onOkClick: PropTypes.func,
  classNames: PropTypes.string,
  placeholders: PropTypes.array,
  style: PropTypes.object,
  open: PropTypes.bool,
};

RangePickerComponent.defaultProps = {
  classNames: "",
  placeholders: ["Startdatum", "Enddatum"],
};

export default RangePickerComponent;
